import './styles.sass'
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Address as AddressFetched,
  addressCreate,
  addressUpdate,
  addressGet,
  AddressForUpdate,
  AddressStatus,
  AddressWorkSchedule
} from 'api/address'
import schemaAddress from 'validation/Address'

import { useAuthContext } from 'AuthContext'
import InfoCard from 'ui/InfoCard'
// import { Autocomplete, Box, Button, Stack, Typography, TextField as MUITextField } from '@mui/material'
import { Box, Button, Stack, Typography } from '@mui/material'
import TextField from 'ui/TextField'
import SaveIcon from '@mui/icons-material/Save'
import ModalMap from 'ui/ModalMap'
import NavigatePanel from 'ui/NavigatePanel'
import { useMainRoutes } from 'routes'
import { isId } from 'api/Id'
import useValidate from 'validation/validate'
import { Interface } from 'api/Interface'
import AntSwitch from 'ui/AntSwitch'
import { toKilogram, toTon } from 'util/weight'
import valueMethod, { STUB } from 'util/valueMethod'
import metaAddress from 'entity/Address'
import History from 'ui/History'
// import { ConsignorConsigneeSearchResult, consignorConsigneeSearch } from 'api/consignorConsignee'
import { checkMaxLoad, checkScaleLength } from 'util/checkWeighingValue'
import WorkScheduleField from 'ui/WorkScheduleField'
import { isTime } from 'util/formatWorkSchedule'
import Select from 'ui/Select'
import { BidMethod, BidMethodType, bidMethodList as apiMethodList } from 'api/bid'
// import { debounce } from '@mui/material/utils'

interface DataMisc {
  rating?: number
  addressOrig?: string
}

type UpdateParams = Omit<AddressForUpdate, 'id'>
// type OrgSearch = ConsignorConsigneeSearchResult

export default function Address () {
  const { id } = useParams()

  const [isNew, setIsNew] = useState(true)
  // const [title, setTitle] = useState<string>()
  const [data, setData] = useState<UpdateParams>()
  const [dataMisc, setDataMisc] = useState<DataMisc>({})

  // const [orgSearchSelected, setOrgSearchSelected] = useState<OrgSearch | null>(null)
  // const [orgSearchQuery, setOrgSearchQuery] = useState('')
  // const [orgSearchFound, setOrgSearchFound] = useState<OrgSearch[]>([])

  // const [consignorConsignee, setConsignorConsignee] = useState<OrgSearch[]>([])

  const { check, errors } = useValidate(schemaAddress)
  const [disabled, setDisabled] = useState<boolean>(false)

  const { handleResponseFailure, handleResponseSuccess } = useAuthContext()
  const [openModalMap, setOpenModalMap] = useState<boolean>(false)
  const navigate = useNavigate()
  const { links, routesMap } = useMainRoutes()
  const [schedule, setSchedule] = useState<Partial<AddressWorkSchedule>>()
  const [scheduleError, setScheduleError] = useState<string>()
  const [methodList, setMethodList] = useState<BidMethod[]>([])

  const { currentInterface } = useAuthContext()

  useEffect(() => {
    setDisabled(currentInterface ? [Interface.chief_forwarder, Interface.forwarder].includes(currentInterface) : false)
  }, [currentInterface])

  const init = useCallback(async () => {
    apiMethodList().then(setMethodList)
    if (id === 'add') {
      setData({})
      setDataMisc({})
      // setTitle('Добавить элеватор/хозяйство')
      return
    }

    setIsNew(false)

    const result = isId(id) ? await addressGet(id) : null

    if (result !== null) {
      const { id, consignorConsignee, status, bidIds, routeIds, rating, addressOrig, pointType, schedule, ...data } = result
      setData({
        ...data,
        consignorConsigneeIds: consignorConsignee?.map(item => item.id)
      })

      // if (consignorConsignee) {
      //   setConsignorConsignee(consignorConsignee.map(({ id, fullName }) => ({ id, fullName })))
      // }
      setSchedule(schedule)
      setDataMisc({ rating, addressOrig })

      // const { city = '', address = '' } = result
      // setTitle(city === '' ? address : city)
      setDisabled(result.status === AddressStatus.archive)
    } else {
      // setTitle('Адрес не найден')
      handleResponseFailure('Адрес не найден')
    }
  }, [id, handleResponseFailure])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { init() }, [])

  // const orgSearchFetch = useMemo(() => debounce(
  //   (query: string, callback: (result: OrgSearch[]) => void) => consignorConsigneeSearch({ query }).then(callback),
  //   400
  // ), [])

  // useEffect(() => {
  //   let active = true

  //   if (orgSearchQuery === '') {
  //     setOrgSearchFound(orgSearchSelected ? [orgSearchSelected] : [])
  //     return undefined
  //   }

  //   orgSearchFetch(
  //     orgSearchQuery,
  //     (result) => active && setOrgSearchFound(orgSearchSelected ? [orgSearchSelected, ...result] : result)
  //   )

  //   return () => {
  //     active = false
  //   }
  // }, [orgSearchSelected, orgSearchQuery, orgSearchFetch])

  const updateString = (key: keyof UpdateParams) => {
    return (event: ChangeEvent<HTMLInputElement>) => {
      if (data) {
        const { target: { value } } = event
        setData({ ...data, [key]: value === '' ? undefined : value })
      }
    }
  }

  const updateWeight = (key: keyof UpdateParams) => {
    return (event: ChangeEvent<HTMLInputElement>) => {
      if (data) {
        const { target: { value } } = event
        setData({ ...data, [key]: value === '' ? undefined : toKilogram(Number(value)) })
      }
    }
  }

  const updateNumber = (key: keyof UpdateParams) => {
    return (event: ChangeEvent<HTMLInputElement>) => {
      if (data) {
        const { target: { value } } = event
        setData({ ...data, [key]: value === '' ? undefined : Number(value) })
      }
    }
  }

  const checkSchedule = (data?: Partial<AddressWorkSchedule>): data is AddressWorkSchedule => {
    if (data === undefined) {
      setScheduleError('Обязательное поле')
      return false
    }

    const { from, to } = data

    if (from === undefined && to === undefined) {
      setScheduleError('Обязательное поле')
      return false
    }

    if (from === undefined) {
      setScheduleError('Необходимо указать время начала работы')
      return false
    }

    if (to === undefined) {
      setScheduleError('Необходимо указать время окончания работы')
      return false
    }

    if (from.hours === to.hours && from.minutes === to.minutes) {
      setScheduleError('Поля не могут содержать одинаковые значения')
      return false
    }

    if (!isTime(from) || !isTime(to)) {
      setScheduleError('Некорректное значение')
      return false
    }

    setScheduleError(undefined)
    return true
  }

  const saveAddressData = async () => {
    if (disabled) {
      return
    }

    const checkData = check(data)
    const checkScheduleData = checkSchedule(schedule)
    if (!checkData || !checkScheduleData) {
      return
    }

    if (isNew) {
      const result = await addressCreate({ ...data, schedule })

      if (result !== null) {
        handleResponseSuccess('Адрес создан')
        navigate(-1)
      }
    } else if (isId(id)) {
      const result = await addressUpdate({ id, ...data, schedule })

      if (result) {
        handleResponseSuccess('Данные изменены')
        navigate(-1)
      }
    }
  }

  const updateSelect = (key: keyof UpdateParams) => {
    return (slug?: string) => {
      setData({ ...data, [key]: slug })
    }
  }

  const pathName = useMemo(
    () => {
      if (isNew) {
        return 'Новый элеватор/хозяйство'
      }

      return `Элеватор/хозяйство/${data?.name}`
    },
    [data?.name, isNew]
  )

  const renderCoordinates = (point?: AddressFetched['coordinates']) =>
    point === undefined ? '' : `${Math.round(point.lat * 1000000) / 1000000}, ${Math.round(point.lon * 1000000) / 1000000}`

  if (data === undefined) {
    return <></>
  }

  return (
    <>
      <ModalMap
        title='Выбрать на карте'
        open={openModalMap}
        onClose={() => setOpenModalMap(false)}
        coordinates={data.coordinates}
        getAddress={(address, coordinates) => setData({
          ...data,
          address: address.display_name,
          region: address.address.state,
          district: address.address.county,
          city: address.address.city || address.address.town || address.address.village || address.address.municipality,
          street: address.address.road,
          building: address.address.house_number,
          coordinates
        }) }
      />
      <div className='address'>
        <NavigatePanel
          title={pathName}
          breadcrumbs={{
            items: [{ title: data.name || '' }],
            defaultItems: routesMap.getBreadcrumbs(links.REGISTRY_POINTS_ADDRESS_PAGE)
          }}
          actions={
            <Stack direction='row' spacing={2} justifyContent='end'>
              <Button variant='outlined' color='secondary' size='small' onClick={() => navigate(-1)}>
                {disabled ? 'Закрыть' : 'Отменить' }
              </Button>
              { disabled
                ? <></>
                : <Button variant='contained' color='success' size='small' onClick={saveAddressData}>
                  Сохранить <SaveIcon sx={{ width: '15px', height: '15px', ml: '10px' }}/>
                </Button>
              }
            </Stack>
          }
        />
        <div className='address__body'>
          <div className='address__content'>
              <InfoCard
                title='Общая информация'
              >
                <>
                  <Stack direction='row' spacing={2}>
                    <TextField
                      name='name'
                      label={metaAddress.title('name')}
                      disabled={disabled}
                      width='33.33%'
                      value={data.name}
                      onChange={updateString('name')}
                      errors={errors}
                    />
                    <Stack width='68%' direction='row' alignItems='center' gap='10px'>
                      <AntSwitch
                        checked={data.isPart ?? false}
                        onChange={(e) => data && setData({ ...data, isPart: e.target.checked }) }
                        inputProps={{ 'aria-label': 'ant design' }}
                        disabled={disabled}
                      />
                      <Typography onClick={() => {}} >{metaAddress.title('isPart')}</Typography>
                    </Stack>
                  </Stack>
                  <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} sx={{ mb: 2 }}>
                    <TextField
                      name='city'
                      label={metaAddress.title('city')}
                      disabled={disabled}
                      width='33.33%'
                      placeholder='Укажите населенный пункт'
                      value={data.city}
                      onChange={updateString('city')}
                      errors={errors}
                    />
                    <TextField
                      name='district'
                      label={metaAddress.title('district')}
                      disabled={disabled}
                      width='33.33%'
                      placeholder='Укажите район пункт (не обязателен)'
                      hint={disabled ? undefined : 'Не обязательно'}
                      value={disabled ? data.district || '-' : data.district}
                      onChange={updateString('district')}
                      errors={errors}
                    />
                    <TextField
                      name='region'
                      label={metaAddress.title('region')}
                      disabled={disabled}
                      width='33.33%'
                      placeholder='Укажите область'
                      value={data.region}
                      onChange={updateString('region')}
                      errors={errors}
                    />
                  </Stack>
                  <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} sx={{ mb: 2 }}>
                    <TextField
                      name='street'
                      label={metaAddress.title('street')}
                      disabled={disabled}
                      width='33.33%'
                      placeholder='Не обязательна при заполнении поля Комментарий'
                      value={disabled ? data.street || '-' : data.street}
                      onChange={updateString('street')}
                      errors={errors}
                    />
                    <TextField
                      name='building'
                      label={metaAddress.title('building')}
                      disabled={disabled}
                      width='33.33%'
                      placeholder='Не обязателен при заполнении поля Комментарий'
                      value={disabled ? data.building || '-' : data.building}
                      onChange={updateString('building')}
                      errors={errors}
                    />
                    <TextField
                      name='comment'
                      label={metaAddress.title('comment')}
                      disabled={disabled}
                      width='33.33%'
                      placeholder='Не обязателен при заполнении полей Улица и Дом'
                      value={disabled ? data.comment || '-' : data.comment ?? ''}
                      onChange={updateString('comment')}
                      errors={errors}
                    />
                  </Stack>
                  <Stack direction='row' justifyContent='space-between' spacing={2}>
                    <TextField
                      name='weighingScaleLength'
                      label={metaAddress.title('weighingScaleLength')}
                      typeNumber
                      precision={0}
                      disabled={disabled}
                      width='33.33%'
                      placeholder='Не обязателен при заполнении поля Комментарий'
                      value={valueMethod(data.weighingScaleLength)(STUB)}
                      onChange={updateNumber('weighingScaleLength')}
                      errors={errors}
                      errorMessage={checkScaleLength(data.weighingScaleLength)}
                    />
                    <TextField
                      name='weighingScaleMaxLoad'
                      label={metaAddress.title('weighingScaleMaxLoad')}
                      disabled={disabled}
                      width='33.33%'
                      placeholder='Не обязателен при заполнении полей Улица и Дом'
                      typeNumber
                      precision={0}
                      value={valueMethod(data.weighingScaleMaxLoad)(toTon)}
                      onChange={updateWeight('weighingScaleMaxLoad')}
                      errors={errors}
                      errorMessage={checkMaxLoad(data.weighingScaleMaxLoad)}
                    />
                    <Box width='33.33%' />
                  </Stack>
                  { !disabled && <Stack direction='row' spacing={2}>

                    <TextField
                      name='externalId'
                      label={metaAddress.title('externalId')}
                      disabled={disabled}
                      width='33%'
                      value={data.externalId}
                      onChange={updateString('externalId')}
                      errors={errors}
                    />
                    <TextField
                      name='rating'
                      label='Рейтинг'
                      disabled={disabled}
                      width='33%'
                      value={dataMisc.rating}
                    />
                    <Box width='33%'></Box>
                  </Stack>}
                  { !disabled && <Stack direction='row' spacing={2}>
                    <TextField
                      name='addressOrig'
                      label='Адрес, полученный из КИС'
                      disabled={disabled}
                      width='100%'
                      value={dataMisc.addressOrig}
                    />
                  </Stack>}
                  <Stack direction='row' spacing={2}>
                    <WorkScheduleField
                      label='Режим работы'
                      value={schedule}
                      onChange={setSchedule}
                      errorMessage={scheduleError}
                    />
                    <Select
                      name='loadingMethod'
                      label='Способ погрузки по умолчанию'
                      placeholder='Выберите способ погрузки'
                      options={methodList.filter(({ type }) => type === BidMethodType.loading).map(({ slug, name }) => ({ value: slug, name }))}
                      value={data.loadingMethod}
                      onChange={updateSelect('loadingMethod')}
                      disabled={disabled}
                      errors={errors}
                      width='20%'
                    />
                    <Select
                      name='unloadingMethod'
                      label='Способ разгрузки по умолчанию'
                      placeholder='Выберите способ разгрузки'
                      options={methodList.filter(({ type }) => type === BidMethodType.unloading).map(({ slug, name }) => ({ value: slug, name }))}
                      value={data.unloadingMethod}
                      onChange={updateSelect('unloadingMethod')}
                      disabled={disabled}
                      errors={errors}
                      width='20%'
                    />
                  </Stack>
                </>
              </InfoCard>
              {/* { !disabled && <InfoCard
                title='Организации'
              ><>
                <Stack direction='row' alignItems='stretch' gap={2}>
                  <Autocomplete
                    sx={{ width: '100%' }}
                    getOptionLabel={(option) => typeof option === 'string' ? option : option.fullName }
                    filterOptions={(x) => x}
                    options={orgSearchFound}
                    autoComplete
                    includeInputInList
                    filterSelectedOptions
                    value={orgSearchSelected}
                    noOptionsText={<Box>Поиск организации</Box>}
                    componentsProps = {{
                      paper: {
                        sx: { border: '1px solid #EBEBEB' }
                      }
                    }}
                    onChange={(_event, value) => {
                      setOrgSearchFound(value ? [value, ...orgSearchFound] : orgSearchFound)
                      setOrgSearchSelected(value)
                    }}
                    onInputChange={(_event, query) => setOrgSearchQuery(query)}
                    renderInput={(params) =>
                      <MUITextField {...params} size='small'/>
                    }
                    renderOption={(props, option) =>
                      <li {...props} style={{
                        border: '1px solid #EBEBEB'
                      }}>
                        {option.fullName}
                      </li>
                    }
                  />
                  <Button
                    variant='contained'
                    color='primary'
                    disabled={!orgSearchSelected}
                    sx={{ width: '10em' }}
                    onClick={() => {
                      if (!orgSearchSelected || !data) {
                        return
                      }

                      setData({
                        ...data,
                        consignorConsigneeIds: [...data?.consignorConsigneeIds ?? [], orgSearchSelected.id]
                      })
                      setConsignorConsignee([...consignorConsignee, orgSearchSelected])
                    }}>Добавить</Button>
                </Stack>
                {consignorConsignee.map(({ id, fullName }, idx) => <>
                  <Stack key={idx} direction='row' gap={1} alignItems='center' sx={{ mt: 2, mx: 1 }}>
                    <Button
                      sx={{ minWidth: '40px' }}
                      onClick={() => {
                        if (!data) {
                          return
                        }

                        setData({
                          ...data,
                          consignorConsigneeIds: (data?.consignorConsigneeIds ?? []).filter((itemId) => itemId !== id)
                        })
                        setConsignorConsignee(consignorConsignee.filter((item) => item.id !== id))
                      }}
                    ><DeleteIcon color='error' /></Button>
                    <Typography>{fullName}</Typography>
                  </Stack>
                </>)}
              </></InfoCard>} */}
              { !disabled && <InfoCard
                title='Пункт на карте'
              >
                <>
                  <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
                    <TextField
                      name='address'
                      label={metaAddress.title('address')}
                      value={data.address}
                      onChange={updateString('address')}
                      width='100%'
                      placeholder='Выберите на карте'
                      errors={errors}
                      endAdornment={
                        <Button
                          sx={{
                            height: '100% !important',
                            background: '#ffffff !important',
                            padding: '0 3em !important',
                            textTransform: 'none !important',
                            whiteSpace: 'nowrap !important',
                            fontWeight: '500 !important',
                            fontSize: '14px !important',
                            lineHeight: '17px !important',
                            letterSpacing: '-0.01em !important',
                            color: '#111111 !important',
                            borderLeft: '1px solid #EBEBEB !important'
                          }}
                          onClick={() => setOpenModalMap(true)}
                        >Выбрать на карте</Button>
                      }
                    />
                    <TextField
                      label='Координаты'
                      disabled={true}
                      width='14em'
                      value={renderCoordinates(data.coordinates)}
                    />
                  </Stack>
                </>
              </InfoCard>}
              { !disabled && isId(id) && <InfoCard
                title='История изменений'
              >
                <History filter={{
                  col: ['main.addresses'],
                  id: [id]
                }} onUndo={init}/>
              </InfoCard>
              }
            </div>
          </div>
      </div>
    </>
  )
}
