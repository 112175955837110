import './styles.sass'
import { useState, useEffect, useRef, useMemo } from 'react'
import { Box, CircularProgress, Typography, Stack } from '@mui/material'
import { StepProps } from 'ui/ModalSigning'
import Alert from '@mui/material/Alert'
import { ActDispatcherWizardData } from './index'
import { Act, actAllSign } from 'api/act'
import { diadocGetDocuments, diadocSignPack, ErrorResponseStatus, SignPackParams } from 'api/diadoc'
import { canAsyncCadesplugin, NewSigner, Signature } from 'ui/Diadoc/lib/tools'
import { DiadocUnauthorized } from 'ui/Diadoc/error/DiadocUnauthorized'
import { Certificate } from 'crypto-pro'

export default function Signing ({ isActive, data, handleNext, handleToStep }: StepProps) {
  const [sent, setSent] = useState<number>(0)
  const [total, setTotal] = useState<number>(0)
  const [error, setError] = useState('')
  const once = useRef(true)

  const d = data as ActDispatcherWizardData

  const acts: Act[] = useMemo(() => {
    return d.acts.filter(item => item.bid.customer.diadocBoxId === d.boxId)
  }, [d.boxId, d.acts])

  useEffect(() => {
    if (!isActive) {
      return
    }

    if (!once.current) {
      return
    }
    once.current = false

    if (!d.boxId) {
      setError('Компания для подписания документов не выбрана')
      return
    }

    if (!d.certificate) {
      setError('Сертификат для подписания документов не выбран')
      return
    }

    setTotal(acts.length)

    signer(d.certificate)
      .then((signer) => {
        const promises = acts.map((act) => {
          setSent((sent) => sent + 1)
          return getDocumentContent(d.boxId, act)
            .then(async ({ documents }) => {
              const signatures: SignPackParams['recipientTitles'] = []
              console.log(documents)

              for (const [key, data] of Object.entries(documents)) {
                signatures.push({ ParentEntityId: key, SignedContent: { Content: data, Signature: await signature(signer, data) } })
              }

              return signatures
            })
            // .then((contentBase64) => signature(signer, contentBase64))
            .then((signatures) => signPack(d.boxId, act, undefined, signatures))
            // .then((signature) => sign(d.boxId, act, signature))
            .then(() => actAllSign([act.id]))
        })
        Promise.all(promises)
          .then(() => handleNext())
          .catch((err) => {
            if (err instanceof DiadocUnauthorized) {
              d.onAuthorized(false)
              handleToStep(d.authFirstStep)
            } else {
              setError(err.message)
            }
          })
      })
      .catch((err) => setError(err.message))
  }, [isActive, d, acts, handleNext, handleToStep])

  if (!isActive) {
    return <></>
  }

  return (<>
    <Typography fontSize='14px' fontWeight={400} color='#B2B2B2' mb='2em'>
    Пожалуйста, не закрывайте это окно и дождитесь окончания отправки запроса на подписания.
    </Typography>
    <Box sx={{ display: 'flex', minWidth: '500px', minHeight: '250px', alignItems: 'center', justifyContent: 'center' }}>
      <Stack display='flex' flexDirection='column' alignItems='center'>
        <CircularProgress />
        <Typography>Подписание ({sent}/{total})</Typography>
        { error && <Alert severity="error">{error}</Alert> }
      </Stack>
    </Box>
  </>)
}

async function signer (c: Certificate) {
  // @ts-ignore
  const plugin = window.cadesplugin
  if (!canAsyncCadesplugin(plugin)) {
    throw new Error('async cadesplugin unavailable')
  }
  return NewSigner(plugin, c._cadesCertificate)
}

function getDocumentContent (boxId: string, act: Act) {
  return diadocGetDocuments({
    boxId,
    actId: act.id
  })
    .then((response) => {
      switch (response.status) {
        case ErrorResponseStatus.tokenRequired:
          throw new DiadocUnauthorized('Нет доступа для авторизации в Diadoc')
        case ErrorResponseStatus.err:
          throw new Error(response.error !== undefined ? response.error : 'Неизвестная ошибка')
      }
      return response
    })
}

function signPack (boxId: string, act: Act, signatures?: SignPackParams['signatures'], recipientTitles?: SignPackParams['recipientTitles']) {
  return diadocSignPack({
    boxId,
    actId: act.id,
    signatures,
    recipientTitles
  })
    .then((response) => {
      switch (response.status) {
        case ErrorResponseStatus.tokenRequired:
          throw new DiadocUnauthorized('Нет доступа для авторизации в Diadoc')
        case ErrorResponseStatus.err:
          throw new Error(response.error !== undefined ? response.error : 'Неизвестная ошибка')
      }
      return response
    })
}

// function sign (boxId: string, act: Act, signature: string) {
//   return diadocSign({
//     boxId,
//     actId: act.id,
//     signature
//   })
//     .then((response) => {
//       switch (response.status) {
//         case ErrorResponseStatus.tokenRequired:
//           throw new DiadocUnauthorized('Нет доступа для авторизации в Diadoc')
//         case ErrorResponseStatus.err:
//           throw new Error(response.error !== undefined ? response.error : 'Неизвестная ошибка')
//       }
//       return response
//     })
// }

async function signature (signer: CAdESCOM.CPSignerAsync, contentBase64: string) {
  // @ts-ignore
  const plugin = window.cadesplugin
  if (!canAsyncCadesplugin(plugin)) {
    throw new Error('async cadesplugin unavailable')
  }

  if (contentBase64 === '') {
    throw new Error('Подписываемое сообщение пустое')
  }

  return Signature(plugin, signer, contentBase64)
}
