import { useEffect, useState } from 'react'
import './styles.sass'
import { StepProps } from 'ui/ModalSigning'
import Alert from '@mui/material/Alert'
import { ActWizardData, skipDiadoc } from './index'
import { Box, CircularProgress, Stack, Typography } from '@mui/material'
import { Certificate } from 'crypto-pro'
import { diadocAuth, diadocAuthConfirm, OkResponseStatus, ErrorResponseStatus } from 'api/diadoc'
import 'ui/Diadoc/lib/cadesplugin_api'
import { DiadocUnauthorized } from 'ui/Diadoc/error/DiadocUnauthorized'

export default function AuthProcess ({ isActive, data, handleNext, handleToStep }: StepProps) {
  const [error, setError] = useState('')

  const d = data as ActWizardData

  useEffect(() => {
    if (!isActive) {
      return
    }

    if (skipDiadoc() || d.authorized) {
      handleNext()
      return
    }

    if (d.certificate === undefined) {
      setError('Ошибка! Сертификат не выбран')
      return
    }

    process(d.certificate)
      .then(() => {
        d.onAuthorized(true)
        handleNext()
      })
      .catch((err) => {
        if (err instanceof DiadocUnauthorized) {
          d.onAuthorized(false)
          handleToStep(d.authFirstStep)
        } else {
          setError(err.message)
        }
      })
  }, [isActive, d, handleNext, handleToStep])

  if (!isActive) {
    return <></>
  }

  return (
    <Box sx={{ display: 'flex', minWidth: '500px', minHeight: '250px', alignItems: 'center', justifyContent: 'center' }}>
      <Stack display='flex' flexDirection='column' alignItems='center'>
        <CircularProgress />
        <Typography>Авторизация в Diadoc</Typography>
        { error && <Alert severity="error">{error}</Alert> }
      </Stack>
    </Box>
  )
}

function process (certificate: Certificate) {
  return auth(certificate)
    .then((response) => {
      switch (response.status) {
        case ErrorResponseStatus.tokenRequired:
          throw new DiadocUnauthorized('Нет доступа для авторизации в Diadoc')

        case ErrorResponseStatus.err:
          throw new Error(response.error !== undefined ? response.error : 'Неизвестная ошибка')

        case OkResponseStatus.ok:
          // if (response.data === undefined) {
          //   throw new Error('Сервер не вернул токен для авторизации')
          // }
          return response.data
      }
    })
    .then(decrypt)
    .then((token1) => authConfirm(certificate, token1))
    .then((response) => {
      switch (response.status) {
        case ErrorResponseStatus.tokenRequired:
          throw new DiadocUnauthorized('Нет доступа для подтверждения авторизации в Diadoc')

        case ErrorResponseStatus.err:
          throw new Error(response.error !== undefined ? response.error : 'Неизвестная ошибка')

        case OkResponseStatus.ok:
          break
      }
    })
}

async function auth (certificate: Certificate) {
  const base64 = await certificate.exportBase64()
  return diadocAuth(base64)
}

async function decrypt (b64: string) {
  // @ts-ignore
  const plugin = window.cadesplugin
  const CPEnvelopedData = await plugin.CreateObjectAsync('CAdESCOM.CPEnvelopedData')
  await CPEnvelopedData.propset_ContentEncoding(plugin.CADESCOM_BASE64_TO_BINARY)
  await CPEnvelopedData.Decrypt(b64)
  const codeDecrypt: string = await CPEnvelopedData.Content
  return codeDecrypt
}

async function authConfirm (certificate: Certificate, token: string) {
  // @ts-ignore
  const thumbprint = certificate.thumbprint
  return diadocAuthConfirm({ thumbprint, token })
}
