import React, { useEffect, useState } from 'react'
import './styles.sass'
import { StepProps } from 'ui/ModalSigning'
import Alert from '@mui/material/Alert'
import { ActWizardData, skipDiadoc } from './index'
import { Box, Typography } from '@mui/material'
import CertificateSelect from 'ui/Diadoc/CertificateSelect'

export default function AuthCertificateSelect ({ isActive, data, handleNext }: StepProps) {
  const [error, setError] = useState('')
  const d = data as ActWizardData

  useEffect(() => {
    if (!isActive) {
      return
    }
    if (skipDiadoc() || (d.authorized && d.certificate)) {
      handleNext()
    }
  }, [isActive, d.authorized, d.certificate, handleNext])

  if (!isActive) {
    return <></>
  }

  return (
    <Box sx={{ minWidth: '500px', minHeight: '250px' }}>
      <Typography fontSize='14px' fontWeight={400} color='#B2B2B2' mb='2em'>
        Выберите сертификат ЭЦП для подписания. Чтобы продолжить, вставьте носитель ключа ЭП в USB-разъем.
      </Typography>

      <CertificateSelect
        onSelect={d.onCertificateSelected}
        onError={setError}/>

      { error && <Alert severity="error">{error}</Alert> }
    </Box>
  )
}
