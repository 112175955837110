import { CarNumberType as Type } from 'api/CarNumberType'
import { Mask } from 'react-text-mask'

export const RU_SYMBOL = /[АВЕКМНОРСТУХ]/i
export const EN_SYMBOL = /[A-Z]/i

export function CarNumberRegionMask (type?: Type): Mask {
  switch (type) {
    case Type.rus:
      return [/\d/, /\d/, /\d/]
    case Type.ua:
      return [EN_SYMBOL, EN_SYMBOL]
    case Type.dpr:
      return [/Д/, /Н/, /Р/]
    case Type.lpr:
      return [/Л/, /Н/, /Р/]
    default:
      return false
  }
}

export function CarNumberIsRegionFirst (type?: Type): boolean {
  return type === Type.ua
}
