import { memo, CSSProperties, ReactNode } from 'react'
import './styles.sass'
import clsx from 'clsx'

export interface InfoCellProps {
  label?: ReactNode
  value?: ReactNode
  style?: CSSProperties
  className?: string
  separator?: boolean
  minWidth?: string | number
}

const InfoCell = memo(({ label, value, style, className, separator, minWidth }: InfoCellProps) => {
  return (
    <div className={clsx('infoCell', className)} style={{ minWidth, ...style }}>
      <div className='infoCell_value'>{ value ?? '' }</div>
      { label === undefined ? '' : <div className='infoCell_label'>{ label }</div> }
      { separator === false ? <></> : <div className='infoCell_separator'></div> }
    </div>
  )
})

export default InfoCell
