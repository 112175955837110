import { mainService } from 'api/service'
import { RequireOnly } from 'util/type/RequireOnly'
import { Id } from 'api/Id'
import { Address } from 'api/address'

export enum RouteStatus {
  active = 'active',
  archive = 'archive',
}

export interface Route {
  status: RouteStatus
  id: Id
  loadingAddress: Address
  unloadingAddress: Address
  distance: number
  comment: string

  archiveDate?: number
  archiveComment?: string
}

export type RouteFindResult = Pick<Route, 'distance' | 'comment'>

type ForUpdate = Omit<Route, 'archiveDate' | 'archiveComment' | 'loadingAddress' | 'unloadingAddress' | 'status'> & {
  loadingAddressId: Id
  unloadingAddressId: Id
}

export type RouteForCreate = Omit<ForUpdate, 'id'>
export type RouteForUpdate = RequireOnly<ForUpdate, 'id'>

export interface RouteArchiveParams {
  id: Id
  date: number
  comment?: string
}

export interface RouteListParams {
  filters?: {
    status?: RouteStatus
  }
}

const request = (method: string, params: object) => mainService.request(`route.${method}`, params)
export const routeGet = (id: Id) => request('get', { id }) as Promise<Route | null>
export const routeList = (params: RouteListParams = {}) => request('list', params) as Promise<Route[]>
export const routeCreate = (params: RouteForCreate) => request('create', params) as Promise<Id | null>
export const routeUpdate = (params: RouteForUpdate) => request('update', params) as Promise<boolean>
export const routeDelete = (id: Id) => request('delete', { id }) as Promise<boolean>
export const routeFind = (params: { loadingAddressId: Id, unloadingAddressId: Id }) => request('find', params) as Promise<RouteFindResult[]>
export const routeArchive = (params: RouteArchiveParams) => request('archive', params) as Promise<boolean>
