// passportSeries: { title: 'Серия паспорта' },
// passportNum: { title: 'Номер паспорта' },
// departmentCode: { title: 'Код подразделения' },

export const PASSPORT_SERIES_MASK = [/\d/, /\d/, ' ', /\d/, /\d/]
export const PASSPORT_ID_MASK = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/]

export const PASSPORT_DEPARTAMENT_CODE_MASK = (value: string) => {
  const num = value.replace(/\D/g, '')
  if (num.length <= 3) {
    return [/\d/, /\d/, /\d/]
  } else {
    return [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]
  }
}

export function passportSeriesMaskClean (value: string): string {
  return value.replace(/\D/g, '')
}
