import { makeJsonSchema, SimpleSchema } from 'validation/validate'
import metaConsignor from 'entity/ConsignorConsignee'
import { ConsignorConsigneeForCreate } from 'api/consignorConsignee'
import { STRING, ADDRESS_REGEXP } from './Organization'
import { TypeLegalPerson } from 'api/organization'

type Parties = Omit<ConsignorConsigneeForCreate, 'phones' | 'emails'>

export type OrgLtd = Omit<Parties, 'kpp'> & { kpp: string }
export type OrgSt = Omit<Parties, 'kpp'>

const simpleSchemaLtd: SimpleSchema<OrgLtd> = {
  type: 'object',
  properties: {
    typeLegalPerson: { type: 'string', enum: [TypeLegalPerson.organization] },
    fullName: { type: 'string', pattern: STRING, minLength: 5, errorMessage: 'Некорректно заполнено поле' },
    shortName: { type: 'string', pattern: STRING, minLength: 5, errorMessage: 'Некорректно заполнено поле' },
    inn: { type: 'string', pattern: '^\\d{10}$', errorMessage: 'Некорректно заполнен ИНН' },
    kpp: { type: 'string', pattern: '^\\d{9}$', errorMessage: 'Некорректно заполнен КПП' },
    okpo: { type: 'string', pattern: '^\\d{8}$', errorMessage: 'Некорректно заполнен ОКПО' },
    ogrn: { type: 'string', pattern: '^\\d{13}$', errorMessage: 'Некорректно заполнен ОГРН' },
    correspondentAccountNumber: { type: 'string', pattern: '^\\d{20}$', errorMessage: 'Некорректно заполнен номер корреспондентского счета' },
    checkingAccountNumber: { type: 'string', pattern: '^\\d{20}$', errorMessage: 'Некорректно заполнен номер расчетного счета' },
    bik: { type: 'string', pattern: '^\\d{9}$', errorMessage: 'Некорректно заполнен БИК' },
    legalAddress: { type: 'string', pattern: ADDRESS_REGEXP, minLength: 10, errorMessage: 'Некорректно заполнено поле' },
    bankName: { type: 'string', minLength: 5, errorMessage: 'Некорректно заполнено поле' },
    externalId: { type: 'string', optional: true, errorMessage: 'Некорректно заполнено поле' }
  }
}

const simpleSchemaSt: SimpleSchema<OrgSt> = {
  type: 'object',
  properties: {
    typeLegalPerson: { type: 'string', enum: [TypeLegalPerson.st] },
    fullName: { type: 'string', pattern: '^[а-яА-ЯёЁ \\d]+', minLength: 5, errorMessage: 'Некорректно заполнено поле' },
    shortName: { type: 'string', pattern: '^[а-яА-ЯёЁ \\d]+', minLength: 5, errorMessage: 'Некорректно заполнено поле' },
    inn: { type: 'string', pattern: '^\\d{12}$', errorMessage: 'Некорректно заполнен ИНН' },
    okpo: { type: 'string', pattern: '^\\d{10}$', errorMessage: 'Некорректно заполнен ОКПО' },
    ogrn: { type: 'string', pattern: '^\\d{15}$', errorMessage: 'Некорректно заполнено ОГРН' },
    correspondentAccountNumber: { type: 'string', pattern: '^\\d{20}$', errorMessage: 'Некорректно заполнен номер корреспондентского счета' },
    checkingAccountNumber: { type: 'string', pattern: '^\\d{20}$', errorMessage: 'Некорректно заполнен номер расчетного счета' },
    bik: { type: 'string', pattern: '^\\d{9}$', errorMessage: 'Некорректно заполнен БИК' },
    legalAddress: { type: 'string', pattern: ADDRESS_REGEXP, minLength: 10, errorMessage: 'Некорректно заполнено поле' },
    bankName: { type: 'string', minLength: 5, errorMessage: 'Некорректно заполнено поле' },
    externalId: { type: 'string', optional: true, errorMessage: 'Некорректно заполнено поле' }
  }
}

export const schemaLtd = makeJsonSchema<OrgLtd>(simpleSchemaLtd)
export const schemaSt = makeJsonSchema<OrgSt>(simpleSchemaSt)

export function clear (data: Partial<Parties> = {}): Partial<Parties> {
  const cleanMask = (key: keyof Omit<Parties, 'type'>, value?: string): string | undefined =>
    value !== undefined ? metaConsignor.cleanMask(key, value) : value

  const result = {
    ...data,
    correspondentAccountNumber: cleanMask('correspondentAccountNumber', data.correspondentAccountNumber),
    checkingAccountNumber: cleanMask('checkingAccountNumber', data.checkingAccountNumber),
    bik: cleanMask('bik', data.bik),
    inn: cleanMask('inn', data.inn),
    okpo: cleanMask('okpo', data.okpo),
    ogrn: cleanMask('ogrn', data.ogrn),
    kpp: cleanMask('kpp', data.kpp)
  }

  return result
}
