import { Grid } from '@mui/material'
import ConfirmationRequests from './ConfirmationRequests'
import NearestTrips from './NearestTrips'
import NewActs from './NewActs'
import './styles.sass'

export default function DispatcherHome () {
  return (<div className='dispatcherHome'>
    <div className='dispatcherHome__body'>
      <Grid container spacing='1em' rowSpacing='0.5em' p='1.5em'>
        <Grid item lg={6} md={12} sm={12} display='flex'><NearestTrips /></Grid>
        <Grid item lg={6} md={12} sm={12} display='flex'><ConfirmationRequests /></Grid>
        <Grid item lg={12} md={12} sm={12} display='flex'><NewActs /></Grid>
      </Grid>
    </div>
  </div>)
}
