import { Visibility } from '@mui/icons-material'
import { Button, Chip, Stack, Typography } from '@mui/material'
import { Profile, ProfileAll, ProfileContractStatus } from 'api/profile'
import InfoCard from 'ui/InfoCard'
import TsToFormatDate from 'util/TsToFormatDate'
import CarrierContractWizard, { Customer } from 'ui/CarrierContractWizard'
import { useState } from 'react'
import download from 'util/download'
import { useAuthContext } from 'AuthContext'
import SquareButton from 'ui/SquareButton'
import None from 'ui/None'

export interface Params {
  profile?: Profile | ProfileAll
  signing?: boolean
}

export default function CrrierContracts ({ profile, signing }: Params) {
  const [contractModal, setContractModal] = useState<Customer>()
  const { profileRefresh } = useAuthContext()

  if (profile === undefined) {
    return (<></>)
  }

  const { contracts = [], newCustomers = [] } = profile
  const isEmpty = contracts.length === 0 && newCustomers.length === 0

  return (<>
    {signing && <CarrierContractWizard
      open={contractModal !== undefined}
      customer={contractModal}
      onClose={() => {
        setContractModal(undefined)
        profileRefresh()
      }}
      signing
    />}
    <InfoCard title='Договора'>
      <Stack direction='column' spacing={2}>
      { contracts.length > 0 && <>
        { contracts.map((item, idx) => (<Stack direction='row' spacing={2} alignItems='center' key={`contract-${idx}`}>
          <Typography>Согласие отправлено {TsToFormatDate(item.createTs, 'dd.MM.yyyy')} в {item.customer.shortName ?? item.customer.fullName}</Typography>
          {item.customer.scanCarrierOffer && <Button
            variant='outlined'
            color='secondary'
            onClick={() => download(item.customer.scanCarrierOffer)}
            startIcon={<Visibility />}
          >Договор оферты</Button>}
          {signing
            ? <>
              { item.filename !== undefined && <Button
                  variant='outlined'
                  color='secondary'
                  onClick={() => download(item.filename as string)}
                  startIcon={<Visibility />}
                >Ваше заявление</Button> }
              { item.status === ProfileContractStatus.new && <Button size='small' color='primary' variant='contained' onClick={() => setContractModal(item.customer)}>Подписать</Button> }
              { item.status === ProfileContractStatus.verification && <Chip label="На проверке" color="primary" variant='outlined' size='small' />}
            </>
            : <>
              { item.filename !== undefined && <SquareButton size='small' variant='outlined' color='secondary' onClick={() => download(item.filename as string)} ><Visibility sx={{ color: '#EBEBEB' }} /></SquareButton> }
              { item.status === ProfileContractStatus.new && <Chip label="Не подписан" color="secondary" variant='outlined' size='small' /> }
              { item.status === ProfileContractStatus.signed && <Chip label="Подписан" color="primary" variant='outlined' size='small' />}
              { item.status === ProfileContractStatus.verification && <Chip label="Ожидает проверки" color="primary" variant='outlined' size='small' />}
            </>}
            { item.status === ProfileContractStatus.rejected && <>
                <Chip label="Отклонено" color="error" variant='outlined' size='small' />
                <Typography>{TsToFormatDate(item.createTs, 'dd.MM.yyyy hh:mm')}</Typography>
                <Typography>{item.rejectionComment}</Typography>
              </>
            }
        </Stack>))}
      </> }
      { newCustomers.length > 0 && <>
      { newCustomers.map((item, idx) => (<Stack direction='row' spacing={2} alignItems='center' key={`new-customer-${idx}`}>
        <Typography>{item.shortName ?? item.fullName}</Typography>
        <Button size='small' color='primary' variant='contained' onClick={() => setContractModal(item)}>Отправить заявление</Button>
      </Stack>)) }
      </> }
      { isEmpty && <Stack minHeight='50px'>
        <None desc="Договора отсутствуют" />
      </Stack> }
      </Stack>
    </InfoCard>
  </>)
}
