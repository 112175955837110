import { NotifyEvent } from '.'
import { useEffect } from 'react'

export interface Data {
  driverName: string
  carrierName: string
  message: string
}

const SLUG = 'drivers_add_driver_event'

export default function DriverAdd ({ slug, data, toast } :NotifyEvent) {
  useEffect(() => {
    if (slug !== SLUG) {
      return
    }

    if (toast === undefined) {
      return
    }

    if (data === undefined) {
      return
    }

    const { message } = data as Data

    toast(message, 'info')
  }, [slug, data, toast])

  return (<></>)
}
