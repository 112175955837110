import './styles.sass'
import { useEffect, useState } from 'react'
import { shippingNearest as apiShippingNearest, ShippingTruckRunExtBid } from 'api/shipping'
import Trip from 'pages/CarrierHome/NearestTrips/Trip'
import OsmBid, { Point } from 'ui/Osm'
import { useMainRoutes } from 'routes'
import Head from '../Head'
import { NavLink } from 'react-router-dom'

export default function NearestTrips () {
  const [list, setList] = useState<ShippingTruckRunExtBid[]>([])
  const [markers, setMarkers] = useState<{ point: Point }[]>([])
  const { links } = useMainRoutes()

  // init list
  useEffect(() => {
    apiShippingNearest()
      .then(result => {
        setList(result)
        setMarkers(result.map(item => ({ point: item.bid.loadingAddress.coordinates })))
      })
  }, [])

  return (
    <div className='nearestTrips'>
      <div className='nearestTrips__body'>
        <div className='nearestTrips__body_head'>
          <Head headText='Ближайшие рейсы' linkRef={links.CARRIER_TRIPS_PAGE} linkText='Все рейсы' />
        </div>
        <div className='nearestTrips__body_items'>
        { list.length === 0 && <div className='nearestTrips__body_items_none'>
            <div className='nearestTrips__body_items_none_desc'>
            Для отображения информации отправьте отклик на перевозку в разделе "<NavLink to={links.CARRIER_BIDS_PAGE}>Заявки</NavLink>".
            </div>
          </div>}
        { list.map(({ bid, ...truckRun }) => <Trip bid={bid} truckRun={truckRun} key={truckRun.id} />) }
        </div>
      </div>
      <div className='nearestTrips__map'>
        <OsmBid staticMap markers={markers} />
      </div>
    </div>
  )
}
