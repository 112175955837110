import { mainService } from 'api/service'

export interface CBRBankAccount {
  correspondentAccountNumber: string
  dateIn: number
}

export interface CBRBank {
  bik: string
  name: string
  uid: string
  dateIn: number
  accounts: CBRBankAccount[]
}

export type CBRFindBankResult = {
  success: boolean
  result: CBRBank | null
  conflicts?: {
    bikNotFound?: boolean
    accountNotFound?: boolean
  }
}

const request = (method: string, params: object) => mainService.request(`cbr.${method}`, params)

export const cbrFindBank = (bik: string, correspondentAccountNumber?: string) =>
  request('find-bank', { bik, correspondentAccountNumber }) as Promise<CBRFindBankResult>
