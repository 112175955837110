import { Alert, Box, Button, Stack, Typography } from '@mui/material'
import { Id, isId } from 'api/Id'
import { Address } from 'api/address'
import { AstonTrafficReservedSlot, astonTrafficReserveCancel } from 'api/aston-traffic'
import { Bid } from 'api/bid'
import { useState } from 'react'
import Modal from 'ui/Modal'
import TsToFormatDate from 'util/TsToFormatDate'
import shortAddress from 'util/shortAddress'
import { useEnqueueSnackbar } from 'util/useEnqueueSnackbar'

export interface Params <T>{
  truckRun?: T
  conflictSlot?: AstonTrafficReservedSlot
  onClose: () => void
  onCancel: () => void
}

export default function AstonTrafficReserveCancelModal <T extends {
  id: Id
  bid: Omit<Bid, 'freeSpace'>
  reservedUnloadingSlot?: AstonTrafficReservedSlot
  unloadingAddress?: Address
}> ({ truckRun, conflictSlot, onClose, onCancel }: Params<T>) {
  const [error, setError] = useState<string>()

  const { toast } = useEnqueueSnackbar()

  const cancel = () => {
    const id = truckRun?.id
    if (!isId(id)) {
      return
    }

    const slot = truckRun?.reservedUnloadingSlot
    if (!slot) {
      return
    }

    const { reservedId, from, to } = slot
    astonTrafficReserveCancel(id, reservedId)
      .then(result => {
        const { success, conflicts } = result

        if (!success) {
          if (conflicts && conflicts.astonMessage) {
            setError(`ЭО Астон-Трафик: ${conflicts.astonMessage}`)
            return
          }

          setError('В данный момент невозможно отменить запись, попробуйте позже или свяжитесь с Диспетчером')
          return
        }

        toast(`Запись ${TsToFormatDate(from, 'HH:mm')}-${TsToFormatDate(to, 'HH:mm')} на ${TsToFormatDate(from, 'dd.MM')} отменена`, 'success')
        onCancel()
      })
  }

  const handleClose = () => {
    setError(undefined)
    onClose()
  }

  if (truckRun === undefined) {
    return (<></>)
  }

  return (<>
    <Modal
      maxWidth='lg'
      open={truckRun !== undefined}
      onClose={handleClose}
      title='Отмена записи в электронную очередь'
      content={<>
        <Box minWidth='500px' maxWidth='500px'>
          { !error
            ? !conflictSlot
                ? <Typography>
                Вы уверены, что хотите отменить запись на электронную очередь?
              </Typography>
                : conflictSlot && <Typography>
                  Вы записаны на разгрузку в пункт выгрузки {shortAddress(truckRun.unloadingAddress ?? truckRun.bid.unloadingAddress)}, слот {TsToFormatDate(conflictSlot.from, 'HH:mm')}-{TsToFormatDate(conflictSlot.to, 'HH:mm')} на {TsToFormatDate(conflictSlot.from, 'dd.MM.yyyy')}.<br />
                  Для того чтобы отозвать отклик, необходимо отменить запись!
                </Typography>
            : <Alert color='error' >
              <Typography>{error}</Typography>
            </Alert>}
        </Box>
      </>}
      actions={<>
        <Stack spacing={2} justifyContent='end' direction='row'>
          <Button variant='outlined' size='small' onClick={handleClose}>
            Отменить
          </Button>
          <Button
            variant='contained'
            color='error'
            size='small'
            onClick={cancel}
            disabled={!!error}
          >
            Отменить запись
          </Button>
        </Stack>
      </>}
    />
  </>)
}
