import { Cancel, CheckCircle, Info, MoreHoriz, Warning, AssignmentTurnedIn, Assignment, AssignmentLate } from '@mui/icons-material'
import { ReactNode, useEffect, useMemo, useState } from 'react'
import { Box, Stack, Typography } from '@mui/material'
// import ActionsMenu from 'ui/ActionsMenu'

import { today as getDay } from 'util/date'
import { DAY } from 'constants/Time'
import TsToFormatDate from 'util/TsToFormatDate'
import { DriverType, NotificationEvent, notificationList } from 'api/notify'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { useMainRoutes } from 'routes'
import SquareButton from 'ui/SquareButton'
import { useAuthContext } from 'AuthContext'
import { Interface } from 'api/Interface'
import { SEARCH_PARAMS as DispatcherBidSP, Tabs as DispatcherBidTab } from 'pages/DispatcherBid'

const today = getDay()

export enum NotificationVariant {
  success = 'success',
  info = 'info',
  warning = 'warning',
  error = 'error'
}

export interface Notification {
  type: NotificationVariant
  slug: string
  message: string
  createTs: number
  data: unknown
}

const getIcon = (type: NotificationVariant, slug: string) => {
  switch (type) {
    case NotificationVariant.success:
      if (slug === 'documents_signed_by_customer_event') {
        return <AssignmentTurnedIn sx={{ color: '#6DCD45' }} />
      }
      return <CheckCircle sx={{ color: '#6DCD45' }} />
    case NotificationVariant.error:
      if (['documents_refused_event', 'documents_canceled_event'].includes(slug)) {
        return <AssignmentLate sx={{ color: 'red' }} />
      }
      return <Cancel sx={{ color: 'red' }} />
    case NotificationVariant.info:
      if (slug === 'documents_waiting_signing_event') {
        return <Assignment sx={{ color: '#0A5DFF' }} />
      }
      return <Info sx={{ color: '#0A5DFF' }} />
    case NotificationVariant.warning:
      return <Warning sx={{ color: '#FFA800' }} />
    default:
      return <></>
  }
}

const getLinkButton = ({ slug, data }: Notification, navigate: NavigateFunction, links: {}, isDispatcher?: boolean) => {
  if (![
    'truck_run_long_to_way_event',
    'truck_run_new_carrier_comment_event',
    'truck_run_refuse_event',
    'aston_traffic_slot_reservation_event',
    'aston_traffic_reservation_cancel_event',
    'aston_traffic_slot_reservation_for_carrier_event',
    'aston_traffic_reservation_cancel_for_carrier_event',
    'carriers_new_carrier_event',
    'carriers_update_carrier_event',
    'truck_run_long_confirmed_event',
    'bids_bid_published_in_region'
  ].includes(slug)) {
    return <></>
  }

  if (['carriers_new_carrier_event', 'carriers_update_carrier_event'].includes(slug) &&
    typeof data === 'object' &&
    data &&
    'carrierId' in data &&
    'REGISTRY_CARRIER_PAGE' in links) {
    return (<SquareButton variant='contained' color='primary' onClick={() => navigate(`${links.REGISTRY_CARRIER_PAGE}/${data.carrierId}`)}>
      <MoreHoriz sx={{ color: '#ffffff', width: '16px' }} />
    </SquareButton>)
  }

  if (['bids_bid_published_in_region'].includes(slug) &&
    typeof data === 'object' &&
    data &&
    'bidId' in data &&
    'CARRIER_BIDS_PAGE' in links) {
    return (<SquareButton variant='contained' color='primary' onClick={() => navigate(`${links.CARRIER_BIDS_PAGE}/${data.bidId}`)}>
      <MoreHoriz sx={{ color: '#ffffff', width: '16px' }} />
    </SquareButton>)
  }

  if (slug !== 'truck_run_refuse_event' &&
    typeof data === 'object' &&
    data &&
    'truckRunId' in data &&
    'CARRIER_TRIPS_PAGE' in links) {
    return (<SquareButton variant='contained' color='primary' onClick={() => navigate(`${links.CARRIER_TRIPS_PAGE}/${data.truckRunId}`)}>
      <MoreHoriz sx={{ color: '#ffffff', width: '16px' }} />
    </SquareButton>)
  }

  if (isDispatcher &&
    slug === 'truck_run_refuse_event' &&
    typeof data === 'object' &&
    data &&
    'truckRunId' in data &&
    'bidId' in data &&
    'DISPATCHER_BID_PAGE' in links) {
    let to = `${links.DISPATCHER_BID_PAGE}/${data.bidId}?`
    to += `${DispatcherBidSP.tab}=${DispatcherBidTab.truckRunsRefused}&`
    to += `${DispatcherBidSP.truckRunId}=${data.truckRunId}`

    if (!data.bidId) {
      return (<></>)
    }

    return (<SquareButton
      variant='contained'
      color='primary'
      onClick={
        () => navigate(to)
      }>
      <MoreHoriz sx={{ color: '#ffffff', width: '16px' }} />
    </SquareButton>)
  }

  return <></>
}

const Row = ({ data, actions }: {data: Notification, actions?: ReactNode}) => {
  const { type, message, slug } = data
  return (<Stack
    direction='row'
    justifyContent='space-between'
    alignItems='center'
    spacing={2}
    border='1px solid #EBEBEB'
    borderRadius='4px'
    p='1em 1.5em'
    mb='0.5em'
    sx={{ background: '#FFFFFF' }}
  >
    <Box width='30px'>{getIcon(type, slug)}</Box>
    <Box flexGrow={3} fontWeight={500} fontSize='14px'>{message}</Box>
    <Box>
      {actions}
    </Box>
    {/* <Box>
      <ActionsMenu actions = {[
        {
          caption: 'Просмотреть',
          onClick: () => {},
          icon: <Visibility />
        },
        {
          caption: 'Не показывать такие уведомления',
          onClick: () => {},
          icon: <Cancel />
        }
      ]} />
    </Box> */}
  </Stack>)
}

export const getNotifyVariant = (slug: string): NotificationVariant => {
  switch (slug) {
    case 'truck_run_reject_event':
    case 'documents_refused_event':
    case 'documents_canceled_event':
    case 'drivers_blocked_driver_event':
    case 'vehicles_blocked_vehicle_event':
      return NotificationVariant.error
    case 'truck_run_confirmed_event':
    case 'documents_signed_by_customer_event':
    case 'drivers_driver_confirmed_event':
    case 'vehicles_vehicle_confirmed_event':
    case 'truck_run_arrived_event':
    case 'aston_traffic_slot_reservation_event':
    case 'aston_traffic_slot_reservation_for_carrier_event':
      return NotificationVariant.success
    case 'truck_run_long_to_way_event':
    case 'truck_run_long_confirmed_event':
    case 'truck_run_refuse_event':
    case 'aston_traffic_reservation_cancel_event':
    case 'aston_traffic_reservation_cancel_for_carrier_event':
      return NotificationVariant.warning
    case 'drivers_carrier_arhive_driver_event':
    case 'drivers_dispatcher_arhive_driver_event':
    case 'trailers_add_trailer_event':
    case 'trailers_update_trailer_event':
    case 'trailers_carrier_arhive_trailer_event':
    case 'trailers_dispatcher_arhive_trailer_event':
    case 'vehicles_add_vehicle_event':
    case 'vehicles_update_vehicle_event':
    case 'vehicles_carrier_arhive_vehicle_event':
    case 'vehicles_dispatcher_arhive_vehicle_event':
    case 'carriers_new_carrier_event':
    case 'truck_run_new_load_forwarder_event':
    case 'forwarders_forwarder_attach_event':
    case 'truck_run_new_response_event':
    case 'truck_run_load_carrier_event':
    case 'truck_run_redirect_event':
    case 'truck_run_new_carrier_comment_event':
    case 'documents_waiting_signing_event':
    case 'drivers_add_driver_event':
    case 'drivers_update_driver_event':
    default:
      return NotificationVariant.info
  }
}

export const map = (origin: NotificationEvent[]): Map<number, Notification[]> => {
  const result = origin.map(item => ({
    type: getNotifyVariant(item.slug),
    slug: item.slug,
    createTs: item.createTs,
    message: item.body ?? item.title ?? item.slug,
    data: item.data
  }))
  const byDay = new Map<number, Notification[]>()

  for (const item of result) {
    const { createTs } = item
    const day = createTs - (createTs % DAY)
    const notifyItems = byDay.get(day) ?? []
    notifyItems.push(item)
    byDay.set(day, notifyItems)
  }

  return byDay
}

export default function Notifications () {
  const [list, setList] = useState<Map<number, Notification[]>>()
  const navigate = useNavigate()
  const { links } = useMainRoutes()
  const { currentInterface } = useAuthContext()

  const isDispatcher = useMemo(() => !!currentInterface && [Interface.dispatcher, Interface.chief_dispatcher].includes(currentInterface), [currentInterface])

  useEffect(() => {
    notificationList({ filters: { types: [DriverType.system] } })
      .then(map)
      .then(setList)
  }, [])

  if (list === undefined) {
    return <></>
  }

  return (<div>{ [...list].length > 0
    ? [...list].map(([day, list]) => <Box key={day} sx={{ marginBottom: '1em' }}>
        <Typography sx={{
          fontWeight: '500',
          fontSize: '13px',
          lineHeight: '16px',
          letterSpacing: '-0.01em',
          color: '#B2B2B2',
          marginBottom: '1em'
        }}>
          {day === today ? 'Сегодня' : day === today - DAY ? 'Вчера' : TsToFormatDate(day, 'd MMMM')}
        </Typography>
        {[...list].map((item, idx) => <Row data={item} key={idx} actions={getLinkButton(item, navigate, links, isDispatcher)} />)}
      </Box>)
    : <></>
    }</div>)
}
