import './styles.sass'
import { StepProps } from 'ui/ModalSigning'
import { CarrierContractWizardType } from './index'
import { Stack, Typography } from '@mui/material'
import { useEffect, useRef } from 'react'
import download from 'util/download'
import SquareButton from 'ui/SquareButton'
import { Visibility } from '@mui/icons-material'

export default function Info ({ isActive, data, handleNext }: StepProps) {
  const once = useRef(true)
  const d = data as CarrierContractWizardType

  useEffect(() => {
    if (!isActive) {
      return
    }

    if (!once.current) {
      return
    }
    once.current = false

    if (d.signing) {
      handleNext()
    }
  }, [isActive, d.signing, handleNext])

  if (!isActive) {
    return <></>
  }

  return (<>
    <Typography>Для того чтобы откликнуться на данную заявку, вам необходимо заключить договор с {d.customer.fullName}</Typography>
    <Stack direction='row' gap={1} alignItems='center'>
      <Typography>Ознакомьтесь с договором оферты</Typography>
      { d.customer.scanCarrierOffer && <SquareButton variant='outlined' color='secondary' onClick={() => download(d.customer.scanCarrierOffer as string)}>
        <Visibility sx={{ color: '#b2b2b2' }} />
      </SquareButton> }
    </Stack>
  </>)
}
