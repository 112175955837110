const Badge = ({ count }: { count?: number }) => {
  return count
    ? <span style={{
      height: '16px',
      width: '16px',
      borderRadius: '50%',
      background: '#EE6A5F',
      color: '#FFFFFF',
      fontSize: '12px',
      fontWeight: 400,
      padding: '1px'
    }}>{count}</span>
    : <></>
}

export default Badge
