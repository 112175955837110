import { createTheme } from '@mui/material/styles'
import shadows, { Shadows } from '@mui/material/styles/shadows'

export const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: 'Inter',
      letterSpacing: '-0.02em'
    }
  },
  shadows: shadows.map(() => 'none') as Shadows,
  palette: {
    primary: {
      main: '#0A5DFF',
      contrastText: '#FFFFFF'
    },
    secondary: {
      main: '#EBEBEB',
      contrastText: '#111111'
    },
    success: {
      main: '#6DCD45',
      contrastText: '#FFFFFF'
    },
    error: {
      main: '#EE6A5F',
      contrastText: '#FFFFFF'
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        sizeLarge: {
          fontWeight: 600
        },
        root: {
          textTransform: 'none',
          fontSize: '14px',
          fontWeight: 500
        },
        outlinedSecondary: {
          color: '#111111'
        }
      }
    }
  }
})
