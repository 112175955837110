import './styles.sass'
import { Button, Container, Stack, Typography } from '@mui/material'
import { useState } from 'react'
import { useAuthContext } from '../../AuthContext'
import metaRegistration from 'entity/PreRegistration'
import schema from 'validation/PreRegistration'
import useValidate from 'validation/validate'
import TextField from 'ui/TextField'
import InfoCard from 'ui/InfoCard'
import LinkButton from 'ui/LinkButton'
import PasswordField from 'ui/PasswordField'

export default function Registration () {
  const { register, handleResponseFailure, endRegistration } = useAuthContext()
  const [phone, setPhone] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [password2, setPassword2] = useState<string>('')
  const { check, errors, cleanErrors } = useValidate(schema)
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [emptyPhone, setEmptyPhone] = useState<string>()

  const submitHandler = () => {
    cleanErrors()
    setEmptyPhone(undefined)

    const cleanPhone = metaRegistration.cleanMask('phone', phone)
    const isEmptyPhone = cleanPhone === ''

    const checked = check({ phone: cleanPhone, password, password2 })

    if (isEmptyPhone) {
      setEmptyPhone('Введите номер телефона')
      return
    }

    if (!checked) {
      return
    }

    if (password === password2) {
      register({ phone: cleanPhone, password }).then(success => {
        if (!success) {
          handleResponseFailure('Ошибка регистрации')
        }
      })
    } else {
      handleResponseFailure('Пароли не совпадают')
    }
  }

  return (
    <Container maxWidth='sm' sx={{ height: '30%' }}>
      <InfoCard>
        <>
          <Typography fontWeight={700} fontSize='21px'>Регистрация</Typography>
          <Stack mt='1.5em'>
            <TextField
              label={metaRegistration.title('phone')}
              name='phone'
              placeholder='+7 (999) 999-99-99'
              onChange={(e) => setPhone(e.target.value)}
              maskParams={{
                mask: metaRegistration.mask('phone')
              }}
              errors={errors}
              errorMessage={emptyPhone}
            />
          </Stack>
          <Stack mt='1.5em'>
            <PasswordField
              label={metaRegistration.title('password')}
              name='password'
              type='password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              errors={errors}
              showPassword={showPassword}
              onShowPassword={() => setShowPassword(prev => !prev)}
            />
          </Stack>
          <Stack mt='1.5em'>
            <PasswordField
              label={metaRegistration.title('password2')}
              name='password2'
              type='password'
              value={password2}
              onChange={(e) => setPassword2(e.target.value)}
              errors={errors}
              showPassword={showPassword}
              onShowPassword={() => setShowPassword(prev => !prev)}
            />
          </Stack>
          <Stack mt='2em'>
            <Button size='large' color='primary' variant='contained' onClick={ submitHandler }>Войти</Button>
          </Stack>
          <Stack direction='row' justifyContent='space-between' spacing={2} mt='1.5em' >
            <LinkButton fullWidth variant='text' color='inherit' onClick={() => endRegistration()} >Вернуться ко входу</LinkButton>
          </Stack>
        </>
      </InfoCard>
    </Container>
  )
}
