import './styles.sass'
import { useMemo } from 'react'
import { Alert, Typography, Box } from '@mui/material'
import { StepProps } from 'ui/ModalSigning'
import { ActWizardData } from './index'
import SignatureDetails from 'ui/ActWizard/SignatureDetails'
import ActPreview from 'pages/ActPreview'

export default function Sign ({ isActive, data, handleToStep }: StepProps) {
  const d = data as ActWizardData
  const acts = useMemo(() => d === undefined || d.newActList === undefined ? [] : d.newActList, [d])

  if (!isActive) {
    return <></>
  }

  if (acts.length === 0) {
    return <Alert severity='info'>Нет документов для подписания</Alert>
  }

  return (<Box sx={{ minWidth: '500px', minHeight: '250px' }}>
    <Typography fontSize='14px' fontWeight={400} color='#B2B2B2' mb='2em'>
    Проверьте сфомированные документы. далее выберите сертификат ЭЦП для подписания и проверьте сформированные УПД. Чтобы продолжить вставьте носитель ключа ЭП в USB-разъем, далее нажмите ‘Подписать и отправить’.
    </Typography>
    <SignatureDetails
      data={d}
      onRenew={() => {
        d.onAuthorized(false)
        handleToStep(d.authFirstStep)
      }}/>

    <ActPreview acts={acts} />
  </Box>)
}
