import './styles.sass'
import { Breadcrumbs as MUIBreadcrumbs, Stack, styled, Typography } from '@mui/material'
import { memo } from 'react'
import { NavLink } from 'react-router-dom'
import { BreadcrumbsItem } from 'routes/types'
import { ChevronRight } from '@mui/icons-material'

export interface Params {
  items: BreadcrumbsItem[]
  defaultItems?: BreadcrumbsItem[]
}

const StyledCrumbs = styled(MUIBreadcrumbs)(({ theme }) => ({
  '& .MuiBreadcrumbs-separator': {
    margin: 0
  },
  '& .MuiBreadcrumbs-li, & p': {
    fontSize: '14px',
    fontWeight: 500
  }
}))

const Breadcrumbs = memo(({ items, defaultItems = [] }: Params) => {
  const crumbs = defaultItems.concat(items)
  return (
    <Stack spacing={1}>
      <StyledCrumbs
        separator={<ChevronRight sx={{ color: '#B2B2B2', width: '18px' }} />}
      >
        { crumbs.map(({ title, to }, idx) => to !== undefined && (idx + 1) !== crumbs.length
          ? <NavLink to={to} key={idx} style={{ color: '#111111', textDecoration: 'none' }} >{title}</NavLink>
          : <Typography key={idx} color="#B2B2B2">
              {title}
            </Typography>)
        }
      </StyledCrumbs>
   </Stack>
  )
})

export default Breadcrumbs
