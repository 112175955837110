import { MoreHoriz, NearMe } from '@mui/icons-material'
import { Button } from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMainRoutes } from 'routes'
import InfoCard from 'ui/InfoCard'
import './styles.sass'
import { ShippingAllNearest, shippingAllNearest } from 'api/shipping'
import Row from '../Row'
import InfoCell from 'ui/InfoCell'
import { SEARCH_PARAMS } from 'pages/DispatcherBids'
import None from 'ui/None'
import addressName from 'util/addressName'

export default function NearestTrips () {
  const { links } = useMainRoutes()
  const navigate = useNavigate()
  const [list, setList] = useState<ShippingAllNearest[]>([])

  useEffect(() => {
    shippingAllNearest().then(setList)
  }, [])

  return (
    <InfoCard
      title='Новые отклики'
      actions={ <Button
        size='small'
        variant='text'
        sx={{ fontSize: '14px', textDecoration: 'underline' }}
        endIcon={<MoreHoriz />}
        onClick={() => navigate(links.DISPATCHER_BIDS_PAGE)}
      >Все заявки</Button>}
      className='dispatcherHome__infoCard'
    >
      <>
      { list.length === 0
        ? <None desc='Нет новых откликов' />
        : list.map(item => <Row
          icon={<NearMe sx={{ color: '#B2B2B2' }} />}
          info={<>
            <InfoCell label='Заявка на перевозку' value={`${addressName(item.bid.loadingAddress, true)} - ${addressName(item.unloadingAddress ?? item.bid.unloadingAddress, true)}`} />
            <InfoCell label='Перевозчик' value={item.carrierName} />
          </>}
          key={item.id}
          onClick={() => {
            let params = `${SEARCH_PARAMS.bidId}=${item.bid.id}`
            params += `&${SEARCH_PARAMS.shipmentTs}=${item.loadingTs}`

            navigate(`${links.DISPATCHER_BIDS_PAGE}?${params}`)
          }}
        />) }
      </>
    </InfoCard>
  )
}
