import { makeJsonSchema, SimpleSchema } from './validate'
import { ShippingTruckRun } from 'api/shipping'
import { Optional } from 'util/type/Optional'

export type TruckRunUpdate = Optional<Required<Pick<ShippingTruckRun, 'billNumber' | 'billTs' |
  'weight' | 'finalWeight' | 'wasEdited' | 'incorrectScanBills' >>, 'wasEdited' | 'incorrectScanBills'>

const simpleSchema: SimpleSchema<TruckRunUpdate> = {
  type: 'object',
  properties: {
    billNumber: { type: 'string' },
    billTs: { type: 'number' },
    weight: { type: 'number', exclusiveMinimum: 0, errorMessage: 'Некорректно заполнен вес погрузки' },
    finalWeight: { type: 'number', exclusiveMinimum: 0, errorMessage: 'Некорректно заполнен вес разгрузки' },

    wasEdited: { type: 'boolean', optional: true },
    incorrectScanBills: { type: 'boolean', optional: true }
  }
}

const schema = makeJsonSchema<TruckRunUpdate>(simpleSchema)

export default schema
