import NavigatePanel from 'ui/NavigatePanel'
import './styles.sass'
import { Button, Stack } from '@mui/material'
import AddressFilter from 'ui/AddressFilter'
import { useMainRoutes } from 'routes'
import { useCallback, useEffect, useState } from 'react'
import { Id } from 'api/Id'
import { ShippingAll, shippingAllList, ShippingTruckRunStatus as Status } from 'api/shipping'
import TripsCard from 'ui/TripsCard'
import formatCargo from 'util/formatCargo'
import rub from 'util/formatRub'
import { toKilometers } from 'util/distance'
import { useNavigate } from 'react-router-dom'
import ChipTruckRunStatusForwarder from 'common/ChipTruckRunStatus'
import None from 'ui/None'
import DatePicker, { RangeDate } from 'ui/DatePicker'
import SquareButton from 'ui/SquareButton'
import { Clear } from '@mui/icons-material'
import ForwarderDriverListModel from 'ui/ForwarderDriverListModel'
import ForwarderLoadingReportsModel from 'ui/ForwarderLoadingReportsModel'

export default function ForwarderAllLoads () {
  const { links, routesMap } = useMainRoutes()
  const [filterAddress, setFilterAddress] = useState<Id[]>()
  const [list, setList] = useState<ShippingAll[]>([])
  const [dateRange, setDateRange] = useState<RangeDate>()

  const [driverListModel, setDriverListModel] = useState<boolean>(false)
  const [loadingReportsModel, setLoadingReportsModel] = useState<boolean>(false)

  const navigate = useNavigate()

  const init = useCallback(() => {
    shippingAllList({
      filters: {
        truckRunStatus: [Status.confirmed, Status.way, Status.archiveOutdated],
        loadingAddressIds: filterAddress,
        loadingTsFrom: dateRange?.startTs,
        loadingTsTo: dateRange?.endTs,
        excludeAutoFill: true
      }
    }).then(setList)
  }, [filterAddress, dateRange])

  useEffect(() => {
    init()
  }, [init])

  return (<>
    <div className='forwarderAllLoads'>
      <NavigatePanel
        title='Погрузки'
        breadcrumbs={{
          items: routesMap.getBreadcrumbs(links.FORWARDER_ALL_LOADS_PAGE)
        }}
        actions={<Stack direction='row' spacing={1} justifyContent='flex-end'>
          <Button variant='contained' onClick={() => setLoadingReportsModel(true)}>Отчет о погрузке</Button>
          <Button variant='contained' onClick={() => setDriverListModel(true)}>Скачать реестр ТС</Button>
        </Stack>}
      />
      <Stack direction='row' spacing={2} p='15px 1.5em' borderBottom='1px solid #E2E8F0' sx={{ background: '#FFFFFF' }}>
        <Stack width='20%'>
          <AddressFilter onChangeFilter={ids => {
            setFilterAddress(ids.length > 0 ? ids : undefined)
          }} selectAddress={filterAddress ?? []} />
        </Stack>
        <DatePicker
          width='20%'
          style={{ marginTop: '-8px' }}
          placeholder='Выберите даты погрузки'
          range={dateRange}
          onChange={(startTs, endTs) => {
            if (startTs !== undefined && endTs !== undefined) {
              setDateRange(startTs < endTs ? { startTs, endTs } : { startTs: endTs, endTs: startTs })
            }
          }}
          rangeDate
        />
        { (dateRange || filterAddress) && <SquareButton
          widthHeight='40px'
          variant='contained'
          color='inherit'
          onClick={() => {
            setFilterAddress(undefined)
            setDateRange(undefined)
          }}
          sx={{ mb: '0.5em', ml: '5px' }}
        ><Clear sx={{ color: '#ffffff' }} /></SquareButton>}
      </Stack>
      <div className='forwarderAllLoads__content'>
        <div className='forwarderAllLoads__content__items'>
          { list.length === 0 && <None
              desc='В настоящее время отсутствуют рейсы заданным параметрам сортировки.'
              p={1}
            />}
          { list.length > 0 && <Stack flexDirection='column' gap={2}>
          { list.map((item) => <TripsCard
              data={item}
              descriptions={[
                { label: 'Груз', value: formatCargo(item.bid.cargo) },
                { label: 'Стоимость тн.', value: rub(item.bid.costTon) },
                { label: 'Расстояние', value: `${toKilometers(item.bid.distance)} км` }
              ]}
              key={item.bid.id}
              onOpenTruckRun={tr => navigate(`${links.FORWARDER_ALL_LOADS_PAGE}/${tr.id}`)}
              statusView={ChipTruckRunStatusForwarder}
            />) }
          </Stack>}
        </div>
      </div>
    </div>
    <ForwarderDriverListModel
      open={driverListModel}
      onClose={() => setDriverListModel(false)}
    />
    <ForwarderLoadingReportsModel
      open={loadingReportsModel}
      onClose={() => setLoadingReportsModel(false)}
    />
  </>)
}
