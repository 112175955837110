import { Button, Stack } from '@mui/material'
import Modal from 'ui/Modal'

interface Params {
  actNum?: string
  onClose: () => void
}

export default function CheckedSuccessModal ({ actNum, onClose }: Params) {
  return <Modal
    title={`Проверка УПД ${actNum ?? ''} завершена`}
    open={actNum !== undefined}
    onClose = {onClose}
    content= {<Stack sx={{ minWidth: '500px' }}>
      Данные по рейсам проверены, УПД и сопутствующие документы готовы к подписанию.
    </Stack>}
    actions={<>
      <Button variant="contained" onClick={onClose}>Хорошо</Button>
    </>}
  />
}
