import './styles.sass'
import NavigatePanel from 'ui/NavigatePanel'
import { Button, Stack, Box, Typography } from '@mui/material'
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useMainRoutes } from 'routes'
import { useNavigate } from 'react-router-dom'
import SaveIcon from '@mui/icons-material/Save'
import LogoutIcon from '@mui/icons-material/Logout'
import { useAuthContext } from 'AuthContext'
import InfoCard from 'ui/InfoCard'
import { ProfileForUpdate, profileUpdate, profileGet } from 'api/profile'
import TextField from 'ui/TextField'
import PasswordField from 'ui/PasswordField'
import { userUpdatePass } from 'api/user'
import { Interface } from 'api/Interface'
import { UserStatus } from 'api/UserStatus'
import formatDate from 'util/FormatDate'
import { EmailMask } from 'common/Mask/EmailMask'
import schemaProfile from 'validation/ProfileCreate'
import useValidate from 'validation/validate'
import onlyFields from 'util/onlyFields'

interface Passwords {
  passwordOld: string
  passwordNew1: string
  passwordNew2: string
}

export default function ForwarderProfile () {
  const { logout, handleResponseSuccess, handleResponseFailure, profileRefresh, currentInterface, phone, userStatus, profile } = useAuthContext()
  const { links, routesMap } = useMainRoutes()
  const navigate = useNavigate()
  const [data, setData] = useState<ProfileForUpdate>({})
  const [dataPassword, setDataPassword] = useState<Passwords>({
    passwordOld: '',
    passwordNew1: '',
    passwordNew2: ''
  })
  const [showPassword, setShowPassword] = useState(false)
  const { check: checkProfile, errors: errorsProfile } = useValidate(schemaProfile)

  const isEdit = useMemo(
    () => userStatus === UserStatus.active,
    [userStatus]
  )

  useEffect(() => {
    profileGet().then(result => {
      if (result !== null) {
        setData({
          email: result.email,
          firstName: result.firstName,
          familyName: result.familyName,
          secondName: result.secondName
        })
      }
    })
  }, [])

  const updateDataString = (key: keyof ProfileForUpdate) => (e: ChangeEvent<HTMLInputElement>) => {
    setData(prev => ({ ...prev, [key]: e.target.value }))
  }

  const updatePasswordString = (key: keyof Passwords) => (e: ChangeEvent<HTMLInputElement>) => {
    setDataPassword(prev => ({ ...prev, [key]: e.target.value }))
  }

  const saveData = useCallback(() => {
    const clear = onlyFields(data, 'email', 'firstName', 'familyName', 'secondName')
    if (!checkProfile(clear)) {
      return
    }

    if (isEdit) {
      profileUpdate(data).then(result => {
        if (result) {
          profileRefresh()
          handleResponseSuccess('Данные сохранены')
        }
      })
    }
  }, [data, handleResponseSuccess, profileRefresh, isEdit, checkProfile])

  const changePassword = useCallback(() => {
    if (dataPassword.passwordNew1 === '' && dataPassword.passwordNew2 === '') {
      return
    }

    if (dataPassword.passwordNew1 !== dataPassword.passwordNew2) {
      handleResponseFailure('Пароли не совпадает')
      return
    }

    userUpdatePass(dataPassword.passwordOld, dataPassword.passwordNew1).then(result => {
      if (result) {
        handleResponseSuccess('Пароль изменен')
      } else {
        handleResponseFailure('Старый пароль не подходит')
      }
    })
  }, [dataPassword, handleResponseFailure, handleResponseSuccess])

  const handleSave = () => {
    saveData()
    changePassword()
  }

  return (
    <>
      <div className='forwarderProfile'>
        <NavigatePanel
          title={currentInterface === Interface.forwarder ? 'Профиль экспедитора' : 'Профиль старшего экспедитора'}
          breadcrumbs={{
            items: [{ title: 'Профиль' }],
            defaultItems: routesMap.getBreadcrumbs(links.PROFILE_PAGE)
          }}
          actions={
            <Stack direction='row' spacing={2} justifyContent='end'>
              <Button variant='contained' color='error' size='small' onClick={() => logout()}>
                Выйти из профиля <LogoutIcon sx={{ width: '15px', height: '15px', ml: '10px' }}/>
              </Button>
              <Button variant='outlined' size='small' onClick={() => navigate(-1)}>
                Отменить
              </Button>
              <Button variant='contained' onClick={handleSave} size='small' sx={{ background: '#6DCD45' }} >
                Сохранить <SaveIcon sx={{ width: '15px', height: '15px', ml: '10px' }}/>
              </Button>
            </Stack>
          }
        />
        <div className='forwarderProfile__body'>
          <div className='forwarderProfile__content'>
            { userStatus === UserStatus.archive && <Box sx={{
              mt: '10px',
              background: '#EBEBEB',
              borderRadius: '8px',
              padding: '32px 24px',
              color: '#111'
            }}>
              <Typography sx={{
                fontWeight: '600',
                fontSize: '18px',
                lineHeight: '22px',
                letterSpacing: '-0.02em'
              }}>
                Аккаунт был архивирован {profile?.archiveTs ? formatDate(new Date(profile.archiveTs * 1000)) : ''}
              </Typography>
              {profile?.archiveComment && <Typography sx={{ mt: 2, whiteSpace: 'pre-wrap' }}>
                {profile?.archiveComment}
              </Typography>}
            </Box>}
            <InfoCard title='Данные профиля'>
              <Stack direction='row' justifyContent='space-between' spacing={2}>
              <TextField
                  name='email'
                  width='20%'
                  label='Email'
                  value={data.email}
                  onChange={updateDataString('email')}
                  maskParams={{
                    mask: EmailMask,
                    guide: false
                  }}
                  errors={errorsProfile}
                />
                <TextField
                  name='familyName'
                  width='20%'
                  label='Фамилия'
                  value={data.familyName}
                  onChange={updateDataString('familyName')}
                  errors={errorsProfile}
                />
                <TextField
                  name='firstName'
                  width='20%'
                  label='Имя'
                  value={data.firstName}
                  onChange={updateDataString('firstName')}
                  errors={errorsProfile}
                />
                <TextField
                  name='secondName'
                  width='20%'
                  label='Отчество'
                  value={data.secondName}
                  onChange={updateDataString('secondName')}
                  errors={errorsProfile}
                />
                <TextField
                  width='20%'
                  disabled={true}
                  label='Телефон'
                  value={phone}
                />
              </Stack>
            </InfoCard>
            <InfoCard title='Изменение пароля'>
              <Stack direction='row' justifyContent='space-between' spacing={2}>
                <PasswordField
                  width='33%'
                  label='Старый пароль'
                  value={dataPassword.passwordOld}
                  onChange={updatePasswordString('passwordOld')}
                  showPassword={showPassword}
                  onShowPassword={() => setShowPassword(prev => !prev)}
                />
                <PasswordField
                  width='33%'
                  label='Новый пароль'
                  value={dataPassword.passwordNew1}
                  onChange={updatePasswordString('passwordNew1')}
                  showPassword={showPassword}
                  onShowPassword={() => setShowPassword(prev => !prev)}
                />
                <PasswordField
                  width='33%'
                  label='Повторите новый пароль'
                  value={dataPassword.passwordNew2}
                  onChange={updatePasswordString('passwordNew2')}
                  showPassword={showPassword}
                  onShowPassword={() => setShowPassword(prev => !prev)}
                />
              </Stack>
            </InfoCard>
          </div>
        </div>
      </div>
    </>
  )
}
