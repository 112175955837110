import './styles.sass'
import { Box, Button, Stack, Typography } from '@mui/material'
import { Add, Archive, Edit, Visibility } from '@mui/icons-material'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import DataTable from 'ui/DataTable'
import { Driver, driverList, DriverStatus, driverArchive, DriverArchiveParams } from 'api/driver'
import ActionsMenu from 'ui/ActionsMenu'
import { Person, personName } from 'util/personName'
import NavigatePanel from 'ui/NavigatePanel'
import { useMainRoutes } from 'routes'
import { useNavigate } from 'react-router-dom'
import { Id } from 'api/Id'
import Modal from 'ui/Modal'
import DatePicker from 'ui/DatePicker'
import TextField from 'ui/TextField'
import formatPhone from 'util/formatPhone'
import { ChipDriverStatus } from 'common/ChipDriverStatus'
import { useAuthContext } from 'AuthContext'
import AntSwitch from 'ui/AntSwitch'
import Upload from 'ui/Upload'
import { ProfileRequestSlugs, ProfileRequestStatus, profileRequestAddDrivers } from 'api/profile'
import { Information, informationGet } from 'api/information'
import download from 'util/download'

export default function CarrierDrivers () {
  const { links, routesMap } = useMainRoutes()
  const { profile, handleResponseFailure, handleResponseSuccess, profileRefresh } = useAuthContext()

  const [rows, setRows] = useState<Driver[]>([])
  const [searched, setSearched] = useState<(number|string)[]>()
  const [archiveData, setArchiveData] = useState<DriverArchiveParams | null>()
  const [allowedToCreateModal, setAllowedToCreateModal] = useState(false)
  const [loadReportEFS, setLoadReportEFS] = useState(false)
  const [scanInsuredPersons, setScanInsuredPersons] = useState<string>()
  const [scanReportEFS, setScanReportEFS] = useState<string>()
  const [requestExist, setRequestExist] = useState(false)
  const [manningTable, setManningTable] = useState<string>()
  const [info, setInfo] = useState<Information>({})

  const navigate = useNavigate()

  const init = useCallback(() => {
    driverList().then(setRows)
    informationGet().then(setInfo)
  }, [])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { init() }, [])

  const openArchiveModal = (id?: Id) => {
    if (id === undefined) {
      return
    }

    setArchiveData({ id, date: Math.floor(Date.now() / 1000) })
  }

  const updateArchiveString = (key: keyof Pick<DriverArchiveParams, 'comment'>) => {
    return (e: ChangeEvent<HTMLElement & {value: string}>) => {
      if (!archiveData) {
        return
      }

      setArchiveData({ ...archiveData, [key]: e.target.value })
    }
  }

  const updateArchiveDate = (key: keyof DriverArchiveParams) => {
    return (value: number) => {
      if (!archiveData) {
        return
      }

      setArchiveData({ ...archiveData, [key]: value })
    }
  }

  const archive = useCallback(async () => {
    if (!archiveData) {
      return
    }

    await driverArchive(archiveData)
    setArchiveData(undefined)
    init()
  }, [archiveData, init])

  const createDriver = () => {
    const isFirstDriver = rows.filter(item => [DriverStatus.new, DriverStatus.active].includes(item.status)).length === 0

    if (profile?.hasHiredDrivers) {
      navigate(`${links.CARRIER_DRIVERS_PAGE}/add`)
    } else if (!profile?.hasHiredDrivers && isFirstDriver) {
      navigate(`${links.CARRIER_DRIVERS_PAGE}/add`, { state: { onlyOneDriver: true } })
    } else if (!profile?.hasHiredDrivers && !isFirstDriver) {
      profileRefresh()
      setRequestExist((profile?.requests ?? []).filter(item => item.status === ProfileRequestStatus.new && item.slug === ProfileRequestSlugs.addDrivers).length > 0)
      setAllowedToCreateModal(true)
    }
  }

  const allowedToCreateCancel = () => {
    setAllowedToCreateModal(false)
    setScanInsuredPersons(undefined)
    setScanReportEFS(undefined)
    setManningTable(undefined)
    setLoadReportEFS(false)
  }

  const allowedToCreateSend = () => {
    profileRequestAddDrivers({ scanInsuredPersons, scanReportEFS, manningTable })
      .then(({ success }) => {
        if (success) {
          handleResponseSuccess('Запрос отправлен')
          profileRefresh()
        } else {
          handleResponseFailure('Запрос небыл отправлен')
        }
      })
      .finally(allowedToCreateCancel)
  }

  return (
    <>
      <div className='carrierDrivers'>
        <NavigatePanel
          search={{
            rows: rows === undefined ? [] : rows,
            fields: [
              'phone',
              'firstName',
              'secondName',
              'familyName'
            ],
            onChange: setSearched
          }}
          title='Водители'
          breadcrumbs={{
            items: routesMap.getBreadcrumbs(links.CARRIER_DRIVERS_PAGE)
          }}
          actions={
            <Button
              variant="contained"
              size="small"
              endIcon={<Add />}
              onClick={createDriver}
              sx={{ textTransform: 'none' }}
            >
              Добавить водителя
            </Button>
          }
        />
        <div className='carrierDrivers__content'>
          <div className='carrierDrivers__content_table'>
            <DataTable
              columns = { [
                {
                  id: ['firstName', 'secondName', 'familyName'],
                  label: 'ФИО',
                  minWidth: 100,
                  format: (firstName, secondName, familyName) => <>{personName({ firstName, secondName, familyName } as Person)}</>
                },
                {
                  id: 'phone',
                  label: 'Номер телефона',
                  minWidth: 100,
                  format: (phone) => <>{ formatPhone(phone as string) }</>
                },
                {
                  id: ['status', 'truckRuns'],
                  label: 'Статус',
                  minWidth: 100,
                  format: (status, truckRuns) => ChipDriverStatus({ status: status as DriverStatus, truckRuns: truckRuns as Id[] | undefined })
                },
                {
                  id: ['id', 'status', 'truckRuns'],
                  label: '',
                  align: 'center',
                  minWidth: 10,
                  format: (id, status, truckRuns) => <ActionsMenu key={`${id}`} actions = {
                    (status !== DriverStatus.new && status !== DriverStatus.active) || (Array.isArray(truckRuns) && truckRuns.length > 0)
                      ? [
                          {
                            caption: 'Посмотреть',
                            onClick: () => navigate(`${links.CARRIER_DRIVERS_PAGE}/${id}`),
                            icon: <Visibility />
                          }
                        ]
                      : [
                          {
                            caption: status === DriverStatus.new ? 'Посмотреть' : 'Редактировать',
                            onClick: () => navigate(`${links.CARRIER_DRIVERS_PAGE}/${id}`),
                            icon: status === DriverStatus.new ? <Visibility /> : <Edit />
                          },
                          {
                            caption: 'Архивировать',
                            onClick: () => openArchiveModal(id as Id),
                            icon: <Archive />
                          }
                        ]
                  } />
                }
              ] }
              rows = { (start, end) => rows.filter(row => searched === undefined ? true : searched.includes(row.id)).slice(start, end) }
              rowsCount = { searched === undefined ? rows.length : searched.length }
            />
            <Modal
              title='Архивировать водителя'
              open={archiveData !== undefined}
              onClose={() => setArchiveData(undefined)}
              content={archiveData
                ? <Stack sx={{ minWidth: '500px' }} >
                    <Box sx={{ mb: 2 }}>
                      <DatePicker
                        label='Дата архивации'
                        value={archiveData.date}
                        onChange={updateArchiveDate('date')}
                        disabled
                      />
                    </Box>
                    <Box>
                      <TextField
                        rows={6}
                        label="Комментарий"
                        width='100%'
                        value={archiveData.comment}
                        onChange={updateArchiveString('comment')}
                        placeholder="Добавьте комментарий, например причину увольнения"
                      />
                    </Box>
                  </Stack>
                : <></>
              }
              actions={ archiveData
                ? <>
                  <Button color="secondary" variant="outlined" onClick={() => setArchiveData(undefined)}>Отменить</Button>
                  <Button variant="contained" onClick={ () => { archive() } }>Архивировать</Button>
                </>
                : <></>
              }
            />
            <Modal
              title='Разрешить добавление водителей?'
              maxWidth='lg'
              open={allowedToCreateModal}
              onClose={allowedToCreateCancel}
              content={!requestExist
                ? <Stack direction='column' sx={{ minWidth: '500px' }} >
                  <Typography fontSize='14px' fontWeight={400} color='#B2B2B2' mb={2}>Перевозчик был зарегистрирован в системе без возможности добавления наемных водителей. Для включения данной возможности необходимо отправить следующие документы:</Typography>
                  <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} sx={{ mb: 2 }}>
                    <Typography width='67%' >Сведения о застрахованных лицах Формы КНД 1151162 «Персонифицированные сведения о физических лицах», утвержденный Приказом ФНС России от 29.09.2022 N ЕД-7-11/878@ (в части сведений о водителях, направляемых для оказания услуги по Договору, с закрытыми на копии/скан-копии данными СНИЛС, ИНН и Сведениями о сумме выплат и иных вознаграждений, начисленных в пользу физического лица его Работодателем)</Typography>
                    <Upload
                      name="scanInsuredPersons"
                      value={scanInsuredPersons}
                      onChange={setScanInsuredPersons}
                      sx={{ width: '33%' }}
                    />
                  </Stack>
                  <Box>
                    <Stack direction='row' alignItems='center' width='67%' spacing={2}>
                      <AntSwitch
                        checked={loadReportEFS}
                        onChange={({ target: { checked } }) => setLoadReportEFS(checked)}
                        inputProps={{ 'aria-label': 'ant design' }}
                      />
                      <Typography >Данные о водителе в Форме КНД 1151162 отсутствуют</Typography>
                    </Stack>
                    { loadReportEFS && <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} mt={2} >
                      <Typography width='67%'>Удостоверенная уполномоченным лицом копия отчета ЕФС-1, подраздел 1.1 с титульным листом утвержденная Постановлением Правления ПФ РФ от 31.10.2022 N 245п (в части соответствующего физического лица) с отметкой о его принятии Фондом пенсионного и социального страхования РФ.)</Typography>
                      <Upload
                        name="scanReportEFS"
                        value={scanReportEFS}
                        onChange={setScanReportEFS}
                        sx={{ width: '33%' }}
                      />
                    </Stack> }
                    <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} sx={{ mb: 2 }}>
                      <Typography width='67%' >
                        Выписка из штатной расстановки {info.sampleStaffing !== undefined && <span
                          style={{ textDecoration: 'underline', cursor: 'pointer' }}
                          onClick={() => download(info.sampleStaffing as string)}>(скачать образец)</span>}
                      </Typography>
                      <Upload
                        name='manningTable'
                        value={manningTable}
                        onChange={setManningTable}
                        sx={{ width: '33%' }}
                      />
                    </Stack>
                  </Box>
                </Stack>
                : <Stack sx={{ minWidth: '500px', minHeight: '200px' }} alignItems='center' justifyContent='center'>
                  <Typography>Ваш запрос находится на рассмотрении</Typography>
                </Stack>
              }
              actions={ !requestExist && <>
                  <Button color="secondary" variant="outlined" onClick={allowedToCreateCancel}>Отменить</Button>
                  <Button variant="contained" onClick={allowedToCreateSend} disabled={(!scanInsuredPersons && !scanReportEFS) || !manningTable}>Отправить</Button>
                </>
              }
            />
          </div>
        </div>
      </div>
    </>
  )
}
