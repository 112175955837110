import './styles.sass'
import { Box, Button, ButtonGroup, SxProps } from '@mui/material'
import { ChangeEvent, useMemo, useState } from 'react'
import { mediaService } from 'api/media'
import { useAuthContext } from 'AuthContext'
import { Visibility, NoteAdd } from '@mui/icons-material'
import { ValidateError } from 'validation/validate'

export interface Params {
  label?: string
  name?: string
  hint?: string
  sx?: SxProps
  value?: string
  disabled?: boolean
  onChange?: (filename: string) => void
  errors?: ValidateError
  errorMessage?: string
}

const MAX_FILE_SIZE_MB = 25

export default function Upload ({ label, name, hint, sx, value, onChange, disabled, errorMessage, errors }: Params) {
  const { handleResponseFailure, handleResponseSuccess } = useAuthContext()
  const [isSizeLarge, setIsSizeLarge] = useState(false)

  const eMessage = useMemo(() => {
    if (errorMessage !== undefined) {
      return errorMessage
    }

    const required = errors?.find(({ keyword, params }) => keyword === 'required' && params.missingProperty === name) !== undefined

    if (required) {
      return 'Поле обязательно к заполнению'
    }
    if (isSizeLarge) {
      return `Ограничение на размер загружаемого файла: ${MAX_FILE_SIZE_MB}МБ`
    }

    const error = errors?.find(item => name && item.instancePath.includes(name))

    return error?.message
  }, [errors, name, errorMessage, isSizeLarge])

  const onDownload = () => {
    if (value === undefined) {
      return
    }

    mediaService.download(value).then(blobby => {
      const objectUrl = window.URL.createObjectURL(blobby)
      window.open(objectUrl, '_blank')
      window.URL.revokeObjectURL(objectUrl)
    })
  }

  const onUpload = (event: ChangeEvent<HTMLInputElement>) => {
    if (isSizeLarge) setIsSizeLarge(false)
    const files = event.currentTarget.files

    if (files instanceof FileList && files.length > 0) {
      const file = files[0]
      if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
        setIsSizeLarge(true)
        return
      }
      const data = new FormData()
      data.append('file', file)
      mediaService.upload(data).then(({ result, error }) => {
        event.target.value = ''

        if (typeof error === 'string') {
          handleResponseFailure(error)
          return
        }

        if (result !== undefined) {
          if (onChange !== undefined) {
            onChange(result)
          }

          handleResponseSuccess('Файл загружен')
        }
      })
    }
  }

  const onDelete = () => {
    if (onChange !== undefined) {
      onChange('')
    }
  }

  const buttonSx = {
    padding: '10px, 12px, 10px, 12px',
    border: eMessage ? '1px solid red' : '1px solid #EBEBEB',
    background: '#F7F7F7',
    color: '#111',
    height: '40px',
    '&:hover': {
      background: '#F7F7F7',
      filter: 'brightness(96%)',
      border: eMessage ? '1px solid red' : '1px solid #EBEBEB'
    }
  }

  const disableLeftBorderSx = (props: SxProps): SxProps => ({
    ...props,
    borderLeft: 'none',
    '&:hover': {
      ...(props as any)['&:hover' as any],
      borderLeft: 'none'
    }
  })

  return (
    <Box sx={{ ...sx }}>
      { label && <Box style={{
        fontWeight: '500',
        fontSize: '13px',
        lineHeight: '16px',
        letterSpacing: '-0.02em',
        color: '#111111',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '8px'
      }}>
        <Box sx={{ whiteSpace: 'nowrap' }}>{label}</Box>
        { hint && <Box sx={{ whiteSpace: 'nowrap', color: '#B2B2B2' }}>{hint}</Box> }
      </Box>}
      { value
        ? <ButtonGroup variant="outlined" component="label" sx={{ width: '100%' }}>
          <Button
            onClick={() => { onDownload() }}
            sx={{ width: '100%', ...buttonSx }}
          >
            <Visibility sx={{ fontSize: '15px', color: '#B2B2B2' }} /><Box sx={{ display: 'inline-block', pl: 2 }}>Открыть файл</Box>
          </Button>
          { disabled
            ? <></>
            : <Button
              onClick={() => { onDelete() }}
              sx={disableLeftBorderSx(buttonSx)}
              disabled={disabled}
            >x</Button>
          }
        </ButtonGroup>
        : <Button
          variant="outlined"
          component="label"
          sx={{ width: '100%', ...buttonSx }}
          disabled={disabled}
        >
          { disabled
            ? <Box sx={{ display: 'inline-block', pl: 2 }}>Файл не загружен</Box>
            : <>
              <NoteAdd sx={{ fontSize: '15px', color: '#B2B2B2' }}/><Box sx={{ display: 'inline-block', pl: 2 }}>Добавить файл</Box>
              <input
                type="file"
                hidden
                onChange={onUpload}
              />
          </>}
        </Button>
      }
      <div style={{ color: 'red', fontSize: '10px', marginTop: '0.5em', height: '10px' }}>{eMessage}</div>
    </Box>
  )
}
