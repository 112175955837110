import './styles.sass'
import DataTable from 'ui/DataTable'
import { historyList, historyUpdate, HistoryResult, HistoryFilter, History as HistoryInterface } from 'api/history'
import { useCallback, useEffect, useState } from 'react'
import { Button, Stack, Typography } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import meta from 'entity'
import Modal from 'ui/Modal'
import TextField from 'ui/TextField'
import { useAuthContext } from 'AuthContext'
import { Id } from 'api/Id'
import { asObject } from 'util/asObject'
import FormatDate from 'util/FormatDate'
import { getOffsetTZ } from 'util/date'

export interface Params {
  filter?: HistoryFilter
  limit?: number
  onUndo?: () => void
}

interface UndoItem {
  col: string,
  id: Id,
  key: string,
  value: unknown,
  current?: unknown,
}

export default function History ({ filter, limit = 10, onUndo = () => {} }: Params = {}): JSX.Element {
  const [data, setData] = useState<HistoryResult>()
  const [undoItem, setUndoItem] = useState<UndoItem>()
  const [offset, setOffset] = useState<number>(0)
  const { handleResponseSuccess } = useAuthContext()

  const loadList = useCallback((offset: number) => {
    historyList(filter, limit, offset).then(setData)
  }, [filter, limit])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => loadList(offset), [offset])

  const undo = async (item: UndoItem): Promise<void> => {
    const { col, id, key, value } = item
    const result = await historyUpdate({ col, id, key, value })
    setUndoItem(undefined)

    if (result) {
      handleResponseSuccess('Изменения отменены')
      loadList(offset)
      onUndo()
    }
  }

  const colTitle = (col: string): string => {
    const { title = col } = col in meta ? meta[col] : {}
    return title
  }

  const objectPresentation = (col: string, value: unknown): string => {
    const { format = ({ _id }: { _id: string }) => _id } = col in meta ? meta[col] : {}
    return typeof value === 'object' && value !== null && '_id' in value ? format(value as { _id: string }) : ''
  }

  const columnTitle = (col: string, key: string): string => {
    const { meta: colMeta = undefined } = col in meta ? meta[col] : {}
    return colMeta && colMeta.isKey(key) ? colMeta.title(key) : key
  }

  const valuePresentation = (col: string, key: string, value: unknown): string => {
    const { meta: colMeta = undefined } = col in meta ? meta[col] : {}
    return colMeta && colMeta.isKey(key) ? colMeta.format(key, value) : `${value}`
  }

  if (data === undefined) {
    return <></>
  }

  return <>
    <Modal
      title='Отменить изменение'
      open={undoItem !== undefined}
      onClose={() => setUndoItem(undefined)}
      content={ undoItem === undefined
        ? <></>
        : <Stack justifyContent='space-between' spacing={2} width='500px' >
            <TextField disabled={true} label="Категория сущности" width='100%' value={colTitle(undoItem.col)} />
            <TextField disabled={true} label="Название сущности" width='100%' value={objectPresentation(undoItem.col, undoItem.current)} />
            <TextField disabled={true} label="Название поля" width='100%' value={columnTitle(undoItem.col, undoItem.key)} />
            <TextField disabled={true} label="Текущее значение" width='100%' value={valuePresentation(undoItem.col, undoItem.key, asObject(undoItem.current)[undoItem.key])} />
            <TextField disabled={true} label="Восстановить значение" width='100%' value={valuePresentation(undoItem.col, undoItem.key, undoItem.value)} />
        </Stack>
      }
      actions={<>
        <Button variant="outlined" onClick={() => setUndoItem(undefined)}>Отменить</Button>
        <Button variant="contained" onClick={ () => { undoItem && undo(undoItem) }}>Восстановить</Button>
      </>}
    />
    <DataTable
      columns={[
        {
          id: 'date',
          label: 'Дата',
          minWidth: 170,
          format: date => <>{ FormatDate(new Date((date as number) + getOffsetTZ()), 'dd.MM.yyyy в HH:mm:ss') }</>
        },
        {
          id: 'userPresentation',
          label: 'Автор изменения',
          minWidth: 170
        },
        {
          id: 'col',
          label: 'Объект',
          minWidth: 170,
          format: (col) => <Typography sx={{
            color: '#B2B2B2'
          }}>{colTitle(col as string)}</Typography>
        },
        {
          id: ['col', 'current'],
          label: '',
          minWidth: 170,
          format: (col, current) => <>{objectPresentation(col as string, current)}</>
        },
        {
          id: ['col', 'diff'],
          label: 'Поле',
          minWidth: 170,
          format: (col, diff) => <>{columnTitle(col as string, (diff as HistoryInterface['diff']).key)}</>
        },
        {
          id: ['col', 'diff'],
          label: 'Старое значение',
          minWidth: 170,
          format: (col, diff) => {
            const { key, from } = diff as HistoryInterface['diff']
            return <>{valuePresentation(col as string, key, from)}</>
          }
        },
        {
          id: ['col', 'diff'],
          label: 'Новое значение',
          minWidth: 170,
          format: (col, diff) => {
            const { key, to } = diff as HistoryInterface['diff']
            return <>{valuePresentation(col as string, key, to)}</>
          }
        },
        {
          id: ['col', 'id', 'diff', 'current'],
          label: '',
          align: 'center',
          minWidth: 10,
          format: (col, id, diff, current) => <Button
            sx={{
              minWidth: '2em',
              maxWidth: '2em',
              minHeight: '2em',
              maxHeight: '2em',
              borderRadius: '2px',
              padding: 0,
              color: '#B2B2B2'
            }}
            onClick={() => {
              const { key, from: value } = diff as HistoryInterface['diff']
              setUndoItem({ col: col as string, id: id as Id, key, value, current })
            }}
          ><MoreVertIcon /></Button>
        }
      ]}
      rowsPage = {limit}
      rows = { (offset) => {
        setOffset(offset)
        return data.list
      } }
      rowsCount = { data.count }
    />
  </>
}
