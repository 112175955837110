import { BidSlotForUpsert } from 'api/bid'
import { idPattern } from 'api/Id'
import { makeJsonSchema, SimpleSchema } from './validate'

const simpleSchema: SimpleSchema<BidSlotForUpsert> = {
  type: 'object',
  properties: {
    bidId: { type: 'string', pattern: idPattern },
    slots: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          shipmentTs: { type: 'number' },
          seats: { type: 'number' }
        }
      }
    }
  }
}

const schema = makeJsonSchema<BidSlotForUpsert>(simpleSchema)

export default schema
