import { NotifyEvent } from '.'
import { useEffect } from 'react'

export interface Data {
  driverName: string
  carrierName?: string
  message: string
}

const SLUGS = ['vehicles_dispatcher_blacklisted_vehicle_event', 'vehicles_carrier_blacklisted_vehicle_event']

export default function VehicleBlacklisted ({ slug, data, toast } :NotifyEvent) {
  useEffect(() => {
    if (slug === undefined) {
      return
    }

    if (!SLUGS.includes(slug)) {
      return
    }

    if (toast === undefined) {
      return
    }

    if (data === undefined) {
      return
    }

    const { message } = data as Data

    toast(message, 'error')
  }, [slug, data, toast])

  return (<></>)
}
