import './styles.sass'
import { Button, Stack, Typography } from '@mui/material'
import Modal from 'ui/Modal'
import { Address } from 'api/address'
import { useEffect, useMemo, useRef, useState } from 'react'
import { MapContainer, Marker, TileLayer, useMap, useMapEvents } from 'react-leaflet'
import { DEFAULT_COORDINATES } from 'constants/map'
import L from 'leaflet'
import { OpenStreetMapProvider, GeoSearchControl } from 'leaflet-geosearch'
import 'leaflet-geosearch/dist/geosearch.css'

export interface AddressDesc {
  display_name: string
  address: {
    county: string
    state: string
    region: string
    postcode: string
    country: string
    country_code: string

    village?: string
    town?: string
    municipality?: string
    house_number?: string
    road?: string
    suburb?: string
    city_district?: string
    city?: string
  }
}
export interface Props {
  title: string
  open: boolean
  coordinates?: Address['coordinates']
  onClose: () => void
  getAddress: (address: AddressDesc, coordinates?: Address['coordinates']) => void
}

type Coordinates = Address['coordinates']

const NOMINATIM_REVERS = 'https://nominatim.openstreetmap.org/reverse'
async function getAddressByCoordinates ({ lat, lon }: Coordinates): Promise<AddressDesc> {
  const result = await fetch(`${NOMINATIM_REVERS}?lat=${lat}&lon=${lon}&format=json`)
  const desc = await result.json() as AddressDesc

  return desc
}

function Point ({ setCoordinates }: { setCoordinates: (coordinates: Coordinates) => void }) {
  useMapEvents({
    click: (e) => {
      const { lat, lng: lon } = e.latlng
      setCoordinates({ lat, lon })
    }
  })

  return <></>
}

function DraggableMarker ({ position, setPosition }: { position: Coordinates, setPosition: (coordinates: Coordinates) => void }) {
  const markerRef = useRef<L.Marker>(null)
  const map = useMap()

  const eventHandlers = useMemo(
    () => ({
      dragend () {
        const marker = markerRef.current
        if (marker != null) {
          const { lat, lng: lon } = marker.getLatLng()
          setPosition({ lat, lon })
        }
      }
    }),
    [setPosition]
  )

  useEffect(() => {
    map.panTo({ lat: position.lat, lng: position.lon })
  }, [map, position])

  return (
    <Marker
      draggable={true}
      eventHandlers={eventHandlers}
      position={{ lat: position.lat, lng: position.lon }}
      ref={markerRef}
    >
    </Marker>
  )
}

function Search ({ onShowlocation }: { onShowlocation: (coordinates: Coordinates) => void }) {
  // @ts-ignore
  const search = new GeoSearchControl({
    provider: new OpenStreetMapProvider(),
    style: 'bar',
    searchLabel: 'Введите адрес для поиска',
    showMarker: false,
    showPopup: false
  })

  const map = useMap()
  useEffect(() => {
    map.addControl(search)

    map.on('geosearch/showlocation', e => {
      // @ts-ignore
      onShowlocation({ lat: Number(e.location.raw.lat), lon: Number(e.location.raw.lon) })
    })
    return () => {
      map.removeControl(search)
    }
  // eslint-disable-next-line
  }, [])

  return <></>
}

export default function ModalMap ({ title, open, onClose, getAddress, ...params }: Props) {
  const [address, setAddress] = useState<string>('')
  const [addressDesc, setAddressDesc] = useState<AddressDesc>()
  const [coordinates, setCoordinates] = useState<Coordinates | undefined>(params.coordinates)
  const mapRef = useRef<L.Map>(null)

  useEffect(() => {
    if (coordinates !== undefined) {
      getAddressByCoordinates(coordinates).then(result => {
        setAddress(result.display_name)
        setAddressDesc(result)
      })
    }
  }, [coordinates])

  const save = () => {
    if (addressDesc !== undefined) {
      getAddress(addressDesc, coordinates)
    }
    onClose()
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={title}
      content={<>
        <div className='modalMap'>
          <div className='modalMap__row'>
            <div className='modalMap__row_map'>
              <MapContainer
                center={[DEFAULT_COORDINATES.lat, DEFAULT_COORDINATES.lon]} zoom={15} scrollWheelZoom={true}
                style={{
                  height: '100%',
                  width: '100%'
                }}
                attributionControl={false}
                ref={mapRef}
              >
                <TileLayer
                  url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                />
                <Search onShowlocation={location => setCoordinates(location)} />
                <Point setCoordinates={point => setCoordinates(point)} />
                { coordinates && <DraggableMarker position={coordinates} setPosition={point => setCoordinates(point)} /> }
              </MapContainer>
            </div>
          </div>
          <div className='modalMap__row'>
            <Typography><b>Адрес:</b> {address}</Typography>
          </div>
        </div>
      </>}
      actions={<>
        <Stack spacing={2} justifyContent='end' direction='row'>
          <Button variant='outlined' size='small' className='modalMap__btn' onClick={onClose}>
            Отменить
          </Button>
          <Button variant='contained' size='small' className='modalMap__btn' onClick={save} >
            Cохранить
          </Button>
        </Stack>
      </>}
  />
  )
}
