import './styles.sass'
import { InputBase, InputAdornment } from '@mui/material'
import { Search as SearchIcon } from '@mui/icons-material'
import { Row } from 'ui/DataTable'
import { Search as JsSearch, AllSubstringsIndexStrategy } from 'js-search'
import { ChangeEvent } from 'react'

export interface Params {
  rows: Row[]
  fields: (string | string[])[]
  onChange: (ids: Row['id'][] | undefined) => void
  borderNone?: boolean
}

export default function Search ({ rows, fields, onChange, borderNone }: Params) {
  const search = new JsSearch('id')
  search.indexStrategy = new AllSubstringsIndexStrategy()

  for (const field of fields) {
    search.addIndex(field)
  }

  search.addDocuments(rows)

  const onChangeInput = (e: ChangeEvent<{value: string}>) => {
    const searchString = e.target.value
    if (searchString === '') {
      onChange(undefined)
    } else {
      const result = search.search(e.target.value) as Row[]
      onChange(result.map(item => item.id))
    }
  }

  return (
    <InputBase
      sx={{ border: borderNone ? 'none' : '1px solid #E2E8F0', pl: borderNone ? 0 : '1em', pr: borderNone ? 0 : '1em', width: '100%' }}
      placeholder='Поиск'
      startAdornment={
        <InputAdornment position='start'>
          <SearchIcon />
        </InputAdornment>
      }
      onChange={onChangeInput}
    />
  )
}
