import './styles.sass'
import { useState } from 'react'
import SendIcon from '@mui/icons-material/Send'
import { Certificate } from 'crypto-pro'
import ModalSigning from 'ui/ModalSigning'
import { Shipping } from 'api/shipping'
import { Act, ActForCreateNumberingFailureErrors, ActForCreateNumberingItem } from 'api/act'
import ShippingList from './ShippingList'
import Creating from './Creating'
import Sign from './Sign'
import Signing from './Signing'
import Exit from './Exit'
import GetOrganizationList from './GetOrganizationList'
import AuthCertificateSelect from './AuthCertificateSelect'
import AuthProcess from './AuthProcess'
import OrganizationSelect from './OrganizationSelect'
import { Organization } from 'api/diadoc'
import SetNumbering from './SetNumbering'
import { Id } from 'api/Id'

export interface Props {
  open: boolean
  truckRunIds?: Id[]
  onClose: () => void
}

export type Numbering = ActForCreateNumberingItem & {
  errorMessage?: string
  error?: ActForCreateNumberingFailureErrors
  disabled?: boolean
}

export interface ActWizardData {
  authorized: boolean
  authFirstStep: number
  newActList: Act[] | undefined
  organizationList: Organization[] | undefined
  shippingList: Shipping[] | undefined
  certificate: Certificate | undefined
  boxId: string
  numbering: Numbering[]
  onlyTruckRunIds?: Id[]
  isDispatcher?: boolean
  onAuthorized: (c: boolean) => void
  onOrganizationListLoaded: (c: Organization[] | undefined) => void
  onCertificateSelected: (c: Certificate | undefined) => void
  onBoxIdSelected: (c: string) => void
  onCreateActs: (acts: Act[] | undefined) => void
  onLoad: (shippingList: Shipping[]) => void
  onNumbering: (numbering: Numbering[]) => void
}

export function skipDiadoc (): boolean {
  return process.env.REACT_APP_DISABLE_DIADOC_EXCHANGE === 'true'
}

export default function ActWizard ({ open, onClose, truckRunIds }: Props) {
  const [authorized, setAuthorized] = useState(false) // has a Diadoc token
  const [organizationList, setOrganizationList] = useState<Organization[]>()
  const [completedList, setCompletedList] = useState<Shipping[]>()
  const [certificate, setCertificate] = useState<Certificate>()
  const [boxId, setBoxId] = useState('')
  const [newActList, setActList] = useState<Act[]>()
  const [numbering, setNumbering] = useState<Numbering[]>([])

  const count = completedList === undefined ? 0 : completedList.length

  return (
    <>
      { open && <ModalSigning
        open={open}
        onClose={onClose}
        data={{
          authFirstStep: 2,
          authorized,
          newActList,
          organizationList,
          shippingList: completedList,
          certificate,
          boxId,
          numbering,
          onlyTruckRunIds: truckRunIds,
          onAuthorized: (b: boolean) => {
            setAuthorized(b)
            if (!b) {
              setOrganizationList(undefined)
              setCertificate(undefined)
              setBoxId('')
              setActList(undefined)
            }
          },
          onOrganizationListLoaded: setOrganizationList,
          onCertificateSelected: setCertificate,
          onBoxIdSelected: setBoxId,
          onCreateActs: setActList,
          onLoad: setCompletedList,
          onNumbering: setNumbering
        }}
        steps={[
          {
            title: count === 0 ? 'Отсутствуют рейсы для добавления в УПД' : 'Добавить завершенные рейсы в УПД',
            fn: ShippingList,
            cancel: count === 0 ? 'Закрыть' : 'Отменить',
            next: count === 0 ? undefined : 'Далее'
          },
          {
            title: 'Загружаем список организаций',
            fn: GetOrganizationList
          },
          {
            title: 'Выбор сертификата',
            fn: AuthCertificateSelect,
            cancel: 'Отменить',
            next: certificate ? 'Далее' : null
          },
          {
            title: 'Авторизация в Diadoc',
            fn: AuthProcess
          },
          {
            title: 'Загружаем список организаций',
            fn: GetOrganizationList
          },
          {
            title: 'Выбор организации',
            fn: OrganizationSelect,
            cancel: 'Отменить',
            next: boxId ? 'Далее' : null
          },
          {
            title: 'Введите номера документов',
            fn: SetNumbering,
            cancel: 'Отменить',
            next: 'Сформировать документы'
          },
          {
            title: 'Создание документов',
            fn: Creating
          },
          {
            title: 'Подписание и отправка',
            fn: Sign,
            cancel: 'Отменить',
            next: <>Подписать и отправить <SendIcon sx={{ width: '16px', ml: '10px' }} /></>
          },
          {
            title: 'Подписание и отправка',
            fn: Signing
          },
          {
            title: 'УПД успешно подписаны и отправлены',
            fn: Exit
          }
        ]}
      /> }
    </>
  )
}
