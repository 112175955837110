import { styled } from '@mui/material/styles'
import LinearProgress, { LinearProgressProps, linearProgressClasses } from '@mui/material/LinearProgress'
import './styles.sass'

const CustomProgress = styled(LinearProgress)(({ theme }) => ({
  height: 32,
  borderRadius: 4,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800]
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 4
    // backgroundColor: theme.palette.mode === 'light' ? '#0A5DFF' : '#F3F7FB'
  }
}))

export interface ProgressBarParams {
  info?: string
  value?: number
  color?: LinearProgressProps['color']
}

export default function ProgressBar ({ value, info, color }: ProgressBarParams) {
  return (
    <div className='progressBar'>
      { info && <div className='progressBar_info'>{info}</div> }
      <CustomProgress variant='determinate' value={value} className='progressBar_bar' color={color} />
    </div>
  )
}
