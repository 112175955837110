import './styles.sass'
import clsx from 'clsx'
import { Collapse, Typography } from '@mui/material'
import { useMemo, useState } from 'react'

export interface CollapseBoxParams <T>{
  className?: string
  collapse?: boolean
  previewItems?: number
  list?: T[]
  element: ((p: T) => JSX.Element)
}

export default function CollapseList <In, > ({ className, list, element: Element, previewItems = 0, collapse = true }: CollapseBoxParams<In>) {
  const [isCollapse, setCollapse] = useState<boolean>(collapse)
  const preview = useMemo(() => list?.slice(0, previewItems), [list, previewItems])
  const content = useMemo(() => list?.slice(previewItems), [list, previewItems])

  return (
    <div className={clsx('collapseList', className)} >
      { list && <>
        <div className='collapseList_content'>
          <div className='collapseList_content_preview'>
            { preview && <div className='collapseList_content_preview_list'>
            { preview.map((item, idx) => (<div key={`list-preview-item-${idx}`} className='collapseList_content_preview_list_item'><Element {...item} key={`list-preview-item-${idx}`} /></div>))}
            </div>}
          </div>
          { content && <Collapse in={!isCollapse} timeout='auto' unmountOnExit className='collapseList_content_collapse'>
            <div className='collapseList_content_collapse_list'>
            { content.map((item, idx) => (<div key={`list-item-${idx}`} className='collapseList_content_collapse_list_item'><Element {...item} key={`list-item-element-${idx}`} /></div>))}
            </div>
          </Collapse>}
        </div>
        { content !== undefined && content.length > 0 && <div className='collapseList_button' onClick={() => setCollapse(prev => !prev)}>
          <Typography>+{content.length}</Typography>
        </div> }
      </> }
    </div>
  )
}
