import { TS_IN_SECONDS, DAY } from 'constants/Time'

export function getOffsetTZ (): number {
  return new Date().getTimezoneOffset() * 60
}

export function clear (ts: number) {
  return Math.floor(ts / TS_IN_SECONDS) - getOffsetTZ()
}

export function now (): number {
  return clear(Date.now())
}

export function dayStart (n: number): number {
  return n - (n % DAY)
}

export function today (): number {
  return dayStart(now())
}

export function toDate (ts: number): Date {
  return new Date((ts + getOffsetTZ()) * TS_IN_SECONDS)
}

export function getFirstDayOfMonth (ts: number) {
  const current = toDate(ts)
  const n = new Date(current.getFullYear(), current.getMonth(), 1)
  return dayStart(clear(n.getTime()))
}

export function getLastDayOfMonth (ts: number) {
  const current = toDate(ts)
  const n = new Date(current.getFullYear(), current.getMonth() + 1, 0)
  return dayStart(clear(n.getTime()))
}
