import { Sync as SyncIcon } from '@mui/icons-material'
import { Button } from '@mui/material'
import { useEventContext } from 'EventContext'
import { ActAllDiadocSyncState as SyncState } from 'api/act'
import { useEffect, useState } from 'react'
import { skipDiadoc } from 'ui/ActWizard'
import DispatcherDiadocSyncWizard from 'ui/DispatcherDiadocSyncWizard'
import TsToFormatDate from 'util/TsToFormatDate'

interface DiadocSyncEventData {
  state: SyncState
  syncTs?: number
}

interface DiadocSyncEvent {
  slug: string
  data: DiadocSyncEventData
}

export const DIADOC_SYNC_STATE_SLUG = 'diadoc_sync_state'

const isDiadocSyncEvent = (x: unknown): x is DiadocSyncEvent => {
  return typeof x === 'object' && x !== null && 'slug' in x && x.slug === DIADOC_SYNC_STATE_SLUG
}

interface SyncButtonProps {
  syncState: SyncState
  syncTs?: number
  onOpenAuthModal: () => void
}

const SyncButton = ({ syncState, syncTs, onOpenAuthModal }: SyncButtonProps) => {
  if (syncState === SyncState.notAuth) {
    return <Button color='primary' variant='contained' onClick={onOpenAuthModal}>Авторизируйтесь</Button>
  }

  if (syncState === SyncState.auth) {
    return <Button color='primary' variant='contained' disabled>Ожидает синхронизации</Button>
  }

  if (syncState === SyncState.sync) {
    return <Button color='primary' variant='contained' endIcon={<SyncIcon />} disabled>
    { syncTs ? `Синхронизировано ${TsToFormatDate(syncTs, 'dd.MM.yyyy HH:mm')}` : 'Синхронизировано'}
    </Button>
  }

  return <></>
}

export interface DiadocSyncParams {
  syncState?: SyncState
}

export default function DiadocSync ({ syncState: startSyncState }: DiadocSyncParams) {
  const [syncState, setSyncState] = useState<SyncState>(SyncState.notAuth)
  const [open, setOpen] = useState(false)
  const [syncTs, setSyncTs] = useState<number>()

  const { currentEvent } = useEventContext()

  useEffect(() => {
    if (startSyncState === undefined) {
      return
    }

    if (startSyncState === SyncState.notAuth) {
      setSyncState(SyncState.notAuth)
      return
    }

    if (startSyncState === SyncState.auth && syncState === SyncState.sync) {
      return
    }

    setSyncState(SyncState.auth)
  }, [startSyncState, syncState])

  useEffect(() => {
    if (currentEvent === undefined) {
      return
    }

    if (!isDiadocSyncEvent(currentEvent)) {
      return
    }

    const { data: { state, syncTs } } = currentEvent

    setSyncState(state)
    setSyncTs(syncTs)
  }, [currentEvent])

  const onAuthorized = () => {
    setSyncState(SyncState.auth)
  }

  const onClose = () => {
    setOpen(false)
  }

  if (skipDiadoc()) {
    return (<></>)
  }

  return (<>
    <SyncButton syncState={syncState} onOpenAuthModal={() => setOpen(true)} syncTs={syncTs} />
    { syncState === SyncState.notAuth && <DispatcherDiadocSyncWizard open={open} onClose={onClose} onAuthorized={onAuthorized} /> }
  </>)
}
