import Breadcrumbs, { Params as BreadcrumbsParams } from 'ui/Breadcrumbs'
import Search, { Params as SearchParams } from 'ui/Search'
import './styles.sass'
import TabsRow, { Params as TabsRowParams } from './TabsRow'
import clsx from 'clsx'
import { ReactNode } from 'react'

export interface Params {
  search?: SearchParams
  breadcrumbs: BreadcrumbsParams
  title: ReactNode
  actions?: JSX.Element
  info?: ReactNode
  tabs?: TabsRowParams & { control?: ReactNode }
  className?: string
}

export default function NavigatePanel ({ search, breadcrumbs, title, actions, info, tabs, className }: Params) {
  return (
    <div className={clsx('navigatePanel', className)}>
      { search && <div className='navigatePanel__find'>
        <Search {...search} borderNone/>
      </div>}
      <div className='navigatePanel__row'>
        <div className='navigatePanel__row_left'>
          <Breadcrumbs {...breadcrumbs} defaultItems={[{ title: 'Главная', to: '/' }, ...breadcrumbs.defaultItems ?? []]} />
          <h1>{title}</h1>
        </div>
        <div className='navigatePanel__row_right'>
          {actions}
        </div>
      </div>
      { info && <div>{info}</div> }
      { tabs && <div className='navigatePanel__tabs'>
        <TabsRow {...tabs} />
      { tabs.control && <div className='navigatePanel__tabs_control'>{tabs.control}</div>}
      </div>}
    </div>
  )
}
