import { ShippingTruckRunStatus } from 'api/shipping'

export const truckRunStatusMap: Map<ShippingTruckRunStatus, string> = new Map()

truckRunStatusMap.set(ShippingTruckRunStatus.new, 'На рассмотрении')
truckRunStatusMap.set(ShippingTruckRunStatus.confirmed, 'Запланирован')
truckRunStatusMap.set(ShippingTruckRunStatus.way, 'В пути')
truckRunStatusMap.set(ShippingTruckRunStatus.arrived, 'Выгружен')
truckRunStatusMap.set(ShippingTruckRunStatus.completed, 'Завершен')
truckRunStatusMap.set(ShippingTruckRunStatus.archiveReject, 'Отклонен')
truckRunStatusMap.set(ShippingTruckRunStatus.archiveAct, 'Архив')
truckRunStatusMap.set(ShippingTruckRunStatus.archiveRefuse, 'Отозван')
truckRunStatusMap.set(ShippingTruckRunStatus.archiveOutdated, 'Просрочен')
truckRunStatusMap.set(ShippingTruckRunStatus.archiveProblem, 'Завершен диспетчером')
