import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { AuthContextProvider } from 'AuthContext'
import { BrowserRouter } from 'react-router-dom'
import { theme } from './theme'
import App from './App'
import { LicenseInfo } from '@mui/x-license-pro'
import { ThemeProvider } from '@mui/material'
import { SnackbarProvider } from 'notistack'

const container = document.getElementById('root')
const root = createRoot(container!)
LicenseInfo.setLicenseKey('389cee4514c3ba75a9414b8d0cea3a4aTz00OTc1MCxFPTE2OTMwNzc3NDUxNTIsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=')

root.render(
  <StrictMode>
    <ThemeProvider theme={theme}>
      <SnackbarProvider autoHideDuration={5000} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} maxSnack={10} preventDuplicate={true}>
        <AuthContextProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </AuthContextProvider>
      </SnackbarProvider>
    </ThemeProvider>
  </StrictMode>
)
