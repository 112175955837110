import './styles.sass'
import { Grid, IconButton, InputAdornment } from '@mui/material'
import { ChangeEvent, useState } from 'react'
import InfoCard from 'ui/InfoCard'
import TextField from 'ui/TextField'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { SectionParams } from 'pages/CarrierProfile/index'
import useValidateParent, { makeJsonSchema } from 'validation/validate'

export interface Data {
  passwordOld?: string
  passwordNew?: string
  passwordNewConfirm?: string
}

const schema = makeJsonSchema<Data>({
  type: 'object',
  properties: {
    passwordOld: { type: 'string', errorMessage: 'Недопустимый пароль', optional: true },
    passwordNew: { type: 'string', minLength: 3, errorMessage: 'Недопустимый пароль', optional: true },
    passwordNewConfirm: { type: 'string', minLength: 3, errorMessage: 'Недопустимый пароль', optional: true }
  }
})

export function useValidate () {
  const parent = useValidateParent(schema)

  return {
    ...parent,
    check: (x: unknown): x is Data => {
      if (!parent.check(x)) {
        return false
      }

      if (!x.passwordNew && !x.passwordNewConfirm) {
        return true
      }

      const errors = []

      if (!x.passwordOld) {
        errors.push({
          instancePath: '/passwordOld',
          keyword: 'required',
          params: { missingProperty: 'passwordOld' },
          schemaPath: ''
        })
      }

      if (x.passwordNew !== x.passwordNewConfirm) {
        errors.push({
          instancePath: '/passwordNewConfirm',
          message: 'Пароли не совпадают',
          keyword: '',
          params: {},
          schemaPath: ''
        })
      }

      const valid = errors.length === 0
      !valid && parent.apiParamsError(errors)

      return valid
    }
  }
}

export default function PasswordCarrierProfile ({ data, setData, disabled, errors, onBlur }: SectionParams<Data>) {
  const [showPassword, setShowPassword] = useState(false)

  const updateString = (key: keyof Data) =>
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
      setData((data) => ({ ...data, [key]: value === '' ? undefined : value }))

  const visibilityAdornment = (
    <InputAdornment position="end">
      <IconButton
        onClick={() => setShowPassword((show) => !show)}
        onMouseDown={ (event) => event.preventDefault() }
        edge="end"
      >
        {showPassword ? <VisibilityOff /> : <Visibility />}
      </IconButton>
    </InputAdornment>
  )

  return (
    <InfoCard title="Изменение пароля">
      <Grid container spacing={2}>
        <Grid item sm={4}>
          <TextField
            name='passwordOld'
            label='Старый пароль'
            type={showPassword ? 'text' : 'password'}
            endAdornment={visibilityAdornment}
            value={data.passwordOld}
            onChange={updateString('passwordOld')}
            disabled={disabled}
            errors={errors}
            onBlur={onBlur && onBlur('passwordOld')}
          />
        </Grid>
        <Grid item sm={4}>
          <TextField
            name='passwordNew'
            label='Новый пароль'
            type={showPassword ? 'text' : 'password'}
            endAdornment={visibilityAdornment}
            value={data.passwordNew}
            onChange={updateString('passwordNew')}
            disabled={disabled}
            errors={errors}
            onBlur={onBlur && onBlur('passwordNew')}
          />
        </Grid>
        <Grid item sm={4}>
          <TextField
            name='passwordNewConfirm'
            label='Повторите новый пароль'
            type={showPassword ? 'text' : 'password'}
            endAdornment={visibilityAdornment}
            value={data.passwordNewConfirm}
            onChange={updateString('passwordNewConfirm')}
            disabled={disabled}
            errors={errors}
            onBlur={onBlur && onBlur('passwordNewConfirm')}
          />
        </Grid>
      </Grid>
    </InfoCard>
  )
}
