import { Box, Button, Stack, Typography } from '@mui/material'
import { Id, isId } from 'api/Id'
import { AstonTrafficSlot, DischargeType, astonTrafficReserveSlot, astonTrafficSlotsList } from 'api/aston-traffic'
import { ShippingTruckRun } from 'api/shipping'
import { useState } from 'react'
import DatePicker from 'ui/DatePicker'
import Modal from 'ui/Modal'
import Select from 'ui/Select'
import TsToFormatDate from 'util/TsToFormatDate'
import { clear, today } from 'util/date'
import { useEnqueueSnackbar } from 'util/useEnqueueSnackbar'

const dischargeTypeMap = new Map<DischargeType, string>()
dischargeTypeMap.set(DischargeType.aside, 'В бок')
dischargeTypeMap.set(DischargeType.back, 'Назад')

const DISCHARGE_TYPE_ERROR_MSG = 'Поле обязательно к заполнению'

export interface Params {
  truckRun?: ShippingTruckRun
  onClose: () => void
  onReserved: () => void
}

export default function AstonTrafficReserveSlotModal ({ truckRun, onClose, onReserved }: Params) {
  const [date, setDate] = useState<number>()
  const [slots, setSlots] = useState<AstonTrafficSlot[]>([])
  const [error, setError] = useState<string>()
  const [reserveSlot, setReserveSlot] = useState<AstonTrafficSlot>()
  const [dischargeType, setDischargeType] = useState<DischargeType>()
  const [dischargeTypeError, setDischargeTypeError] = useState<string>()

  const isReserved = (id: Id) => id === reserveSlot?.id

  const { toast, snack } = useEnqueueSnackbar()

  const changeDate = (date: number) => {
    const id = truckRun?.id
    if (!isId(id)) {
      return
    }

    astonTrafficSlotsList(id, date)
      .then(({ success, result, conflicts }) => {
        if (!success) {
          if (conflicts && conflicts.astonMessage) {
            setError(`ЭО Астон-Трафик: ${conflicts.astonMessage}`)
          }

          if (conflicts && conflicts.astonTrafficError) {
            setError('Данные не были получены из ЭО Астон-Трафик')
          }

          setDate(undefined)
          setReserveSlot(undefined)
          setSlots([])
          return
        }

        setSlots(result)
        setDate(date)
        setError(undefined)
        setReserveSlot(undefined)
      })
      .catch(() => setDate(undefined))
  }

  const handleSlot = (slot: AstonTrafficSlot) => () => {
    if (!dischargeType) {
      setDischargeTypeError(DISCHARGE_TYPE_ERROR_MSG)
    }

    setReserveSlot(slot)
  }

  const reserve = () => {
    const id = truckRun?.id
    if (!isId(id)) {
      return
    }

    if (dischargeType === undefined) {
      setDischargeTypeError(DISCHARGE_TYPE_ERROR_MSG)
      return
    }

    setDischargeTypeError(undefined)

    if (reserveSlot === undefined) {
      return
    }

    astonTrafficReserveSlot(id, { ...reserveSlot, dischargeType })
      .then(result => {
        const { success, conflicts } = result

        if (!success) {
          if (conflicts && conflicts.astonMessage) {
            snack({
              title: 'ЭО Астон-Трафик',
              content: <>
                <Typography>{conflicts.astonMessage}</Typography>
              </>,
              color: 'error'
            })
          }

          toast('Слот не был забронирован, попробуйте позже или свяжитесь с Диспетчером', 'error')

          return
        }

        snack({
          title: 'Запись на разгрузку',
          content: <>
            <Typography>
            Слот {TsToFormatDate(reserveSlot.from, 'HH:mm')}-{TsToFormatDate(reserveSlot.to, 'HH:mm')} на {TsToFormatDate(reserveSlot.from, 'dd.MM')} забронирован
            </Typography>
          </>,
          color: 'success'
        })

        onReserved()
        setReserveSlot(undefined)
      })
  }

  const handleClose = () => {
    setDate(undefined)
    setSlots([])
    setError(undefined)
    setReserveSlot(undefined)
    onClose()
  }

  return (<>
    <Modal
      maxWidth='lg'
      open={truckRun !== undefined}
      onClose={handleClose}
      title='Запись в электронную очередь'
      content={<>
        <Box minWidth='500px'>
          <Stack direction='column' gap={1}>
            <Stack flexDirection='row' gap={1} sx={{ borderBottom: '1px solid #EBEBEB', pb: '5px', mb: '15px' }}>
              <DatePicker
                width='20em'
                label='Дата разгрузки'
                value={date}
                onChange={changeDate}
                shouldDisableDate={day => {
                  const cd = clear(day.getTime())
                  return cd < today() || cd <= clear(truckRun?.actualLoadingTs ?? truckRun?.loadingTs ?? 0)
                }}
                errorMessage={error}
              />
              <Select
                label='Способ выгрузки'
                placeholder='Выберите cпособ выгрузки'
                options={[...dischargeTypeMap].map(([value, name]) => ({ value, name }))}
                value={dischargeType}
                onChange={setDischargeType}
                width='20em'
                errorMessage={dischargeTypeError}
              />
            </Stack>
            { slots.length > 0 && <Stack
              direction='row'
              flexWrap='wrap'
              gap={1}
              >
              { slots.map(item => (<Button
                key={item.id}
                variant={'outlined'}
                color={isReserved(item.id) ? 'primary' : 'secondary'}
                sx={{ width: '11em' }}
                onClick={handleSlot(item)}
              >
                {TsToFormatDate(item.from, 'HH:mm')} — {TsToFormatDate(item.to, 'HH:mm')}
              </Button>)) }
              </Stack>}
          </Stack>
        </Box>
      </>}
      actions={<>
        <Stack spacing={2} justifyContent='end' direction='row'>
          <Button variant='outlined' size='small' onClick={handleClose}>
            Отменить
          </Button>
          <Button
            variant='contained'
            size='small'
            onClick={reserve}
            disabled={!reserveSlot || !dischargeType}
          >
            Записать
          </Button>
        </Stack>
      </>}
    />
  </>)
}
