import './styles.sass'
import { Id, isId } from 'api/Id'
import { trailerCreate, TrailerForUpdate, trailerGet, trailerUpdate, TrailerStatus } from 'api/trailer'
import { VehicleAxles, vehicleAxlesList as apiVehicleAxlesList } from 'api/vehicle'
import { useAuthContext } from 'AuthContext'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import useValidate from 'validation/validate'
import schema, { clear } from 'validation/Trailer'
import { useMainRoutes } from 'routes'
import NavigatePanel from 'ui/NavigatePanel'
import { Box, Button, Stack, Typography } from '@mui/material'
import SaveIcon from '@mui/icons-material/Save'
import InfoCard from 'ui/InfoCard'
import TextField from 'ui/TextField'
import formatDate from 'util/FormatDate'
import Select from 'ui/Select'
import Upload from 'ui/Upload'
import { ChipTrailerStatus } from 'common/ChipTrailerStatus'
import onlyFields from 'util/onlyFields'
import { CarNumberType } from 'api/CarNumberType'
import { TrailerNumberMask } from 'common/Mask/TrailerNumberMask'
import { CarNumberRegionMask, CarNumberIsRegionFirst } from 'common/Mask/CarNumberRegionMask'
import getRegionNumber from 'common/getRegionNumber'
import DownloadZipButton, { slug } from 'ui/DownloadZipButton'
import vehicleAndTrailerPack, { Result as Pack } from 'common/map/vehicleAndTrailerPack'
import trailerName from 'util/trailerName'
import { regionCodesUA } from '../../constants/regionCodesUA'
import { validateTrailerNumber } from 'util/validateTrailerNumber'

type UpdateParams = Omit<TrailerForUpdate, 'id'>

interface DataMisc {
  status: TrailerStatus
  archiveDate?: number
  archiveComment?: string
  truckRuns?: Id[]
}

export default function CarrierTrailer () {
  const { id } = useParams()

  const [data, setData] = useState<UpdateParams>()
  const [disabled, setDisabled] = useState<boolean>(false)
  const [disabledRegion, setDisabledRegion] = useState<boolean>(false)
  const [dataMisc, setDataMisc] = useState<DataMisc>()
  const [isNew, setIsNew] = useState(true)
  const [doubleError, setDoubleError] = useState(false)
  const [pack, setPack] = useState<Pack>()
  const [numberError, setNumberError] = useState(false)
  const navigate = useNavigate()
  const { check, errors } = useValidate(schema)
  const { links, routesMap } = useMainRoutes()
  const [vehicleAxlesList, setVehicleAxlesList] = useState<VehicleAxles[]>([])

  const { handleResponseFailure, handleResponseSuccess } = useAuthContext()

  const save = useCallback(async () => {
    const clearData = clear(data)

    if (!check(clearData)) {
      return
    }
    if (!validateTrailerNumber(data?.numberType, data?.number)) {
      setNumberError(true)
      return
    }
    if (isId(id)) {
      const result = await trailerUpdate({ id, ...clearData })

      if (result) {
        handleResponseSuccess('Данные прицепа изменены')
        navigate(links.CARRIER_TRAILERS_PAGE)
      }
    } else {
      const { success, id, conflicts } = await trailerCreate(clearData)

      if (!success && conflicts?.double) {
        handleResponseFailure('Нельзя создать прицеп. В системе уже есть прицеп с указанным регистрационным номером')
        setDoubleError(true)
        return
      }

      if (!success || !isId(id)) {
        handleResponseFailure('Прицеп не был создан')
        return
      }

      handleResponseSuccess('Прицеп создан')
      navigate(links.CARRIER_TRAILERS_PAGE)
    }
  }, [check, data, id, handleResponseSuccess, handleResponseFailure, navigate, links.CARRIER_TRAILERS_PAGE])

  const init = useCallback(async () => {
    apiVehicleAxlesList().then(setVehicleAxlesList)

    if (id === 'add') {
      setData({})
      return
    }

    const result = isId(id) ? await trailerGet(id) : null

    if (result !== null) {
      const { id, status, archiveDate, archiveComment, axles, truckRuns = [], ...data } = result
      const onlyChangeableFields = onlyFields(data,
        'brand', 'model', 'number',
        'numberRegion', 'color', 'certificate',
        'certificateIndex', 'scanPassport',
        'scanRegistration', 'numberType')
      setDataMisc({ status, archiveDate, archiveComment, truckRuns })
      setDisabled(status !== TrailerStatus.active || truckRuns.length > 0)
      setData({ ...onlyChangeableFields, axlesSlug: axles.slug !== '' ? axles.slug : undefined })
      setIsNew(false)
      setDisabledRegion([CarNumberType.dpr, CarNumberType.lpr].includes(onlyChangeableFields.numberType))
      setPack(vehicleAndTrailerPack(data))
    } else {
      handleResponseFailure('Прицеп не найден')
    }
  }, [handleResponseFailure, id])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { init() }, [])

  const updateString = (key: keyof UpdateParams) => {
    return (event: ChangeEvent<HTMLInputElement>) => {
      const { target: { value } } = event
      setData({ ...data, [key]: value === '' ? undefined : value })
    }
  }
  const updateUARegion = (value?:string) => {
    setData({ ...data, numberRegion: value || undefined })
  }

  const updateUpload = (key: keyof UpdateParams) => {
    return (filename: string | undefined) => {
      setData({ ...data, [key]: filename })
    }
  }

  const presentation = ({ brand = '', model = '', ...doc }: UpdateParams) => `${trailerName(doc)} - ${brand} ${model}`

  const changeNumberType = (numberType?: CarNumberType) => {
    const numberRegion = getRegionNumber(numberType)

    setDisabledRegion(!!numberRegion)
    setData((data) => ({
      ...data,
      numberType,
      numberRegion: numberRegion ?? ''
    }))
  }

  if (data === undefined) {
    return <></>
  }

  return (
    <div className='carrierTrailer'>
      <NavigatePanel
        title={isNew
          ? 'Новый прицеп'
          : <Box sx={{ display: 'flex', gap: '1em', alignItems: 'center' }}>
              <Box>{presentation(data)}</Box>
              { dataMisc
                ? <ChipTrailerStatus status={dataMisc.status} truckRuns={dataMisc.truckRuns}/>
                : <></>
              }
          </Box>
        }
        breadcrumbs={{
          items: [
            { title: isNew ? 'Добавить прицеп' : presentation(data) }
          ],
          defaultItems: routesMap.getBreadcrumbs(links.CARRIER_TRAILERS_PAGE)
        }}
        actions={
          <Stack direction='row' spacing={2} justifyContent='end'>
            { pack && <DownloadZipButton pack={pack} archiveName={slug(`прицеп_${trailerName(data).replaceAll(' ', '_')}`)} /> }
            <Button variant='outlined' color='secondary' size='small' onClick={() => navigate(-1)}>
              { disabled ? 'Закрыть' : 'Отменить' }
            </Button>
            { disabled
              ? <></>
              : <Button variant='contained' color='success' size='small' onClick={save}>
                Сохранить <SaveIcon sx={{ width: '15px', height: '15px', ml: '10px' }}/>
              </Button>
            }
          </Stack>
        }
      />
      <div className='carrierTrailer__body'>
        <div className='carrierTrailer__content'>
          {dataMisc?.status === TrailerStatus.archive && <Box sx={{
            mt: '10px',
            background: '#EBEBEB',
            borderRadius: '8px',
            padding: '32px 24px',
            color: '#111'
          }}>
            <Typography sx={{
              fontWeight: '600',
              fontSize: '18px',
              lineHeight: '22px',
              letterSpacing: '-0.02em'
            }}>
              Прицеп был архивирован {dataMisc.archiveDate ? formatDate(new Date(dataMisc.archiveDate * 1000)) : ''}
            </Typography>
            {dataMisc.archiveComment && <Typography sx={{ marginTop: '0.5em', whiteSpace: 'pre-wrap' }}>
              {dataMisc.archiveComment}
            </Typography>}
          </Box>}
          <InfoCard
            title='Общая информация'
          >
              <>
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} sx={{ mb: 2 }}>
                  <TextField name='brand' disabled={disabled} label="Марка" placeholder='Укажите марку прицепа' width='33.33%' value={data.brand} onChange={updateString('brand')} errors={errors} />
                  <TextField name='model' disabled={disabled} label="Модель" placeholder='Укажите модель прицепа' width='33.33%' value={data.model} onChange={updateString('model')} errors={errors} />
                  <TextField name='color' disabled={disabled} label="Цвет" placeholder='Укажите цвет прицепа' width='33.33%' value={data.color} onChange={updateString('color')} errors={errors} />
                  <Select
                    name='axlesSlug'
                    label='Количество осей'
                    placeholder='Укажите количество осей'
                    options={vehicleAxlesList.map(({ slug, name }) => ({ value: slug, name }))}
                    value={data.axlesSlug}
                    onChange={(axlesSlug) => setData((data) => ({ ...data, axlesSlug }))}
                    disabled={disabled}
                    errors={errors}
                    width='25%'
                  />
                </Stack>

                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                  <Select
                    name='numberType'
                    label='Тип номера'
                    placeholder='Выбирите тип номера'
                    options={Object.values(CarNumberType).map((value) => ({ value, name: value }))}
                    value={data.numberType}
                    onChange={changeNumberType}
                    disabled={disabled}
                    errors={errors}
                    width='33%'
                  />
                  <Stack direction={CarNumberIsRegionFirst(data.numberType) ? 'row-reverse' : 'row'} width='33%' spacing={2}>
                    <TextField
                      name='number'
                      disabled={disabled}
                      label="Гос.номер"
                      placeholder='Гос. номер регистрации'
                      width='50%'
                      value={data.number}
                      onChange={updateString('number')}
                      errors={errors}
                      maskParams={{
                        mask: TrailerNumberMask(data.numberType)
                      }}
                      errorMessage={numberError ? 'Неправильно заполненное поле ' : doubleError ? 'Используется в системе' : undefined}
                      tabIndex={CarNumberIsRegionFirst(data.numberType) ? 2 : 1}
                    />
                    {
                      data.numberType === CarNumberType.ua
                        ? <Select
                      options={regionCodesUA}
                      name='numberRegion'
                      label='Регион' placeholder='Укажите регион регистрации'
                      disabled={disabled || disabledRegion}
                      width='50%'
                      value={data.numberRegion || ''}
                      onChange={updateUARegion}
                      errors={errors}
                      tabIndex={CarNumberIsRegionFirst(data.numberType) ? 1 : 2}
                      />
                        : <TextField
                          name='numberRegion'
                          disabled={disabled || disabledRegion}
                          label="Регион"
                          placeholder='Регион регистрации'
                          width='50%'
                          value={data.numberRegion}
                          onChange={updateString('numberRegion')}
                          errors={errors}
                          maskParams={{
                            mask: CarNumberRegionMask(data.numberType)
                          }}
                          errorMessage={doubleError ? 'Используется в системе' : undefined}
                          tabIndex={CarNumberIsRegionFirst(data.numberType) ? 1 : 2}
                        />
                    }
                  </Stack>
                  <TextField
                      name='certificateIndex'
                      label="Серия СРТС"
                      placeholder='Укажите серию СРТС'
                      disabled={disabled}
                      width='33%'
                      value={data.certificateIndex}
                      onChange={updateString('certificateIndex')}
                      errors={errors}
                      maskParams={{
                        mask: [/\d/, /\d/, ' ', /[\dА-Я]/i, /[\dА-Я]/i]
                      }}
                    />
                    <TextField
                      name='certificate'
                      label="Номер СРТС"
                      placeholder='Укажите номер СРТС'
                      disabled={disabled}
                      width='25%'
                      value={data.certificate}
                      onChange={updateString('certificate')}
                      errors={errors}
                      maskParams={{
                        mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
                      }}
                    />
                </Stack>
              </>
            </InfoCard>
            <InfoCard
                title='Документы'
              >
                <Stack direction="row" justifyContent="space-between" spacing={2}>
                  <Upload
                    name='scanPassport'
                    disabled={disabled}
                    sx={{ width: '50%' }}
                    label="ПТС"
                    hint='В цветном формате'
                    value={data.scanPassport}
                    onChange={updateUpload('scanPassport')}
                    errors={errors}
                  />
                  <Upload
                    name='scanRegistration'
                    disabled={disabled}
                    sx={{ width: '50%' }}
                    label="Свидетельство о гос.регистрации"
                    hint='В цветном формате'
                    value={data.scanRegistration}
                    onChange={updateUpload('scanRegistration')}
                    errors={errors}
                  />
                </Stack>
              </InfoCard>
          </div>
        </div>
      </div>
  )
}
