import './styles.sass'
import NavigatePanel from 'ui/NavigatePanel'
import DataTable, { Column } from 'ui/DataTable'
import { useMainRoutes } from 'routes'
import { useCallback, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import VisibilityIcon from '@mui/icons-material/Visibility'
import ActionsMenu from 'ui/ActionsMenu'
import { Vehicle, VehicleAll, VehicleAllFilter, vehicleAllList, VehicleStatus } from 'api/vehicle'
import { isEnum } from 'util/isEnum'
import vehicleName from 'util/vehicleName'

const statusMap = new Map<VehicleStatus | '', string>()
  .set('', 'Все')
  .set(VehicleStatus.active, 'Подтвержденные')
  .set(VehicleStatus.archive, ' Архив')

const RegistryVehicles = () => {
  const { links, routesMap } = useMainRoutes()
  const [rows, setRows] = useState<VehicleAll[]>()
  const [searched, setSearched] = useState<(number|string)[]>()
  const navigate = useNavigate()

  const { status } = useParams()
  const filterStatus = isEnum(VehicleStatus)(status) ? status : ''

  const init = useCallback(async () => {
    const filterQuery: VehicleAllFilter = { includeAdditionalData: true }
    if (filterStatus.length !== 0) {
      filterQuery.status = filterStatus !== '' ? filterStatus : undefined
    }

    const rows = await vehicleAllList(filterQuery)

    setRows(rows.map(({ additionalData, ...item }) => ({ ...item, ...additionalData })))
  }, [filterStatus])

  useEffect(() => { init() }, [init])

  const columns: Column[] = []

  columns.push({
    id: ['brand', 'model'],
    label: 'Марка и модель',
    format: (brand, model) => <>{brand} - {model}</>
  })

  columns.push({
    id: ['number', 'numberRegion', 'numberType'],
    label: 'Гос. номер и регион',
    format: (number, numberRegion, numberType) => <>{vehicleName({ number, numberRegion, numberType } as Vehicle, 'short')}</>
  })

  columns.push({
    id: ['certificate', 'certificateIndex'],
    label: 'Серия и номер СРТС'
  })

  columns.push({
    id: ['orgShortName', 'ownerId'],
    label: 'Перевозчик',
    format: (orgShortName, ownerId) => {
      return <Link to={`${links.REGISTRY_CARRIER_PAGE}/${ownerId}`}>{orgShortName as string}</Link>
    }
  })

  columns.push({
    id: ['id', 'ownerId'],
    label: '',
    align: 'right',
    format: (id, ownerId) => <ActionsMenu key={`${id}`} actions={
      [
        {
          caption: 'Посмотреть',
          onClick: () => navigate(`${links.REGISTRY_CARRIER_PAGE}/${ownerId}/vehicles/${id}`),
          icon: <VisibilityIcon />
        }
      ]
    } />
  })
  return <>
    <div className='registryVehicles'>
      <NavigatePanel
        search={{
          rows: rows === undefined ? [] : rows,
          fields: [
            'brand',
            'model',
            'number',
            'numberRegion',
            'certificate',
            'certificateIndex',
            'orgShortName'
          ],
          onChange: setSearched
        }}
        title='Транспорт'
        breadcrumbs={{
          items: routesMap.getBreadcrumbs(links.REGISTRY_VEHICLES_PAGE)
        }}
        tabs={{
          items: [...statusMap].map(([value, label]) => ({ label, value })),
          value: filterStatus,
          onChange: value => navigate(links.REGISTRY_VEHICLES_PAGE + (value === '' ? '' : `/${value}`))
        }}
      />
      <div className='registryVehicles__content'>
        <div className='registryVehicles__content_table'>
          <DataTable
            columns={columns}
            rows = { (start, end) => rows === undefined
              ? []
              : rows
                .filter(row => searched === undefined ? true : searched.includes(row.id)).slice(start, end) }
            rowsCount = { rows === undefined ? 0 : (searched === undefined ? rows.length : searched.length) }
          />
        </div>
      </div>
    </div>
  </>
}

export default RegistryVehicles
