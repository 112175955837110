// one - И.п. ед.ч.
// two - Р.п. ед.ч.
// five - Р.п мн.ч.
export function Pieces (number: number, one: string, two: string, five: string): string {
  if (Math.floor(number % 100 / 10) !== 1) {
    switch (number % 10) {
      case 1:
        return one
      case 2:
      case 3:
      case 4:
        return two
    }
  }

  return five
}
