import { idPattern } from 'api/Id'
import { makeJsonSchema, SimpleSchema } from './validate'
import { ProfileAllPositionPutParams } from 'api/profile'

export type ProfilePositionUpdate = ProfileAllPositionPutParams

const simpleSchema: SimpleSchema<ProfilePositionUpdate> = {
  type: 'object',
  properties: {
    customerId: { type: 'string', pattern: idPattern },
    position: { type: 'string', pattern: '^[а-яА-ЯёЁ\\- ]+$', minLength: 3, errorMessage: 'Некорректно заполнена должность' },
    startTs: { type: 'number' },
    endTs: { type: 'number' }
  }
}

const schema = makeJsonSchema<ProfilePositionUpdate>(simpleSchema)

export default schema
