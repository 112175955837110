import './styles.sass'
import { Box, Container } from '@mui/material'

export function PostRegistrationLayout ({ content }: { content: JSX.Element}) {
  return (<>
    <Container
      maxWidth='lg'
      sx={{
        background: '#ffffff',
        border: '1px solid #EBEBEB',
        minHeight: '100vh'
      }}
    >
    <Box display='flex' flexDirection='column' minHeight='100vh' >
      <Box display='flex' flexDirection='row' justifyContent='center' m='3em' flexGrow={1}>
        <img src={process.env.PUBLIC_URL + '/logo_full.png'} alt='Астон' height='48px' />
      </Box>
      <Box flexGrow={3} display='flex'>{ content }</Box>
    </Box>
    </Container>
  </>)
}
