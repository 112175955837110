import { useEffect, useState } from 'react'
import AddressFilter from 'ui/AddressFilter'
import SelectFilter from './SelectFilter'
import CostRange from './CostRange'
import './styles.sass'
import { SelectOptions } from 'common/SelectOptionsInterface'
import { InitialData, useFiltersContext, FiltersContextProvider } from './FiltersContext'
import AdditionalFilters from './AdditionalFilters'
import { Grid } from '@mui/material'
import { vehicleTypeList } from 'api/vehicle'
import { Stack } from '@mui/system'
import SquareButton from 'ui/SquareButton'
import { Clear } from '@mui/icons-material'

export type SelectValue = string[]

interface FiltersParams {
  initData: InitialData
  onChangeFilters: (data: InitialData) => void
}

export default function Filters ({ initData, onChangeFilters }: FiltersParams) {
  return (
    <FiltersContextProvider>
      <FiltersMain initData={initData} onChangeFilters={onChangeFilters} />
    </FiltersContextProvider>
  )
}

function FiltersMain ({ initData, onChangeFilters }: FiltersParams) {
  const {
    setData,
    data,
    filterLoadingAddress,
    filterUnloadingAddress,
    filterCargo,
    filterTypeVehicle,
    getCargolist,
    setFilterTypeVehicle,
    setFilterCargo,
    setFilterLoadingAddress,
    setFilterUnloadingAddress,
    clear
  } = useFiltersContext()
  const [typeVehicleOptionsList, setTypeVehicOptionsleList] = useState<SelectOptions[]>()
  const [cargoList, setCargoList] = useState<SelectOptions[]>()

  useEffect(() => {
    setData(initData)
  }, [initData, setData])

  useEffect(() => onChangeFilters(data), [data, onChangeFilters])

  useEffect(() => {
    if (typeVehicleOptionsList === undefined) {
      vehicleTypeList().then(result => setTypeVehicOptionsleList(result.map(({ slug, name }) => ({ value: slug, name }))))
    }
  }, [typeVehicleOptionsList])

  useEffect(() => {
    if (cargoList === undefined || cargoList.length === 0) {
      setCargoList(getCargolist().map(v => ({ value: v, name: v })))
    }
  }, [
    cargoList,
    getCargolist
  ])

  return (
    <>
      <Grid container className='bidsFilters' rowSpacing={1} columnSpacing={{ xl: 1, sm: 1, md: 1 }}>
        <Grid item sm={6} md={4} xl={2}>
          <AddressFilter onChangeFilter={setFilterLoadingAddress} label='Пункт погрузки' selectAddress={filterLoadingAddress ?? []} />
        </Grid>
        <Grid item sm={6} md={4} xl={2}>
          <AddressFilter onChangeFilter={setFilterUnloadingAddress} label='Пункт разгрузки' selectAddress={filterUnloadingAddress ?? []} />
        </Grid>
        <Grid item sm={6} md={4} xl={2}>
          <SelectFilter label='Тип груза' value={filterCargo ?? []} options={cargoList ?? []} changeFilter={setFilterCargo} />
        </Grid>
        <Grid item sm={6} md={4} xl={2}>
          <CostRange />
        </Grid>
        <Grid item sm={6} md={4} xl={2}>
          <SelectFilter label='Тип ТС' value={filterTypeVehicle ?? []} options={typeVehicleOptionsList ?? []} changeFilter={setFilterTypeVehicle} />
        </Grid>
        <Grid item sm={6} md={4} xl={2}>
          <Stack direction='row' spacing={1}>
            <AdditionalFilters />
            <SquareButton
              widthHeight='40px'
              variant='contained'
              color='inherit'
              onClick={clear}
              sx={{ mb: '0.5em', ml: '5px' }}
            ><Clear sx={{ color: '#ffffff' }} /></SquareButton>
          </Stack>
        </Grid>
      </Grid>
    </>
  )
}
