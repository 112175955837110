import { mainService } from 'api/service'

export enum DocumentType {
  act = 'act',
  actRegistry = 'actRegistry',
  actShortage = 'actShortage',
  invoices = 'invoices',
  forwarderLoadingReports = 'forwarder_loading_report',
  actAgreement = 'act_agreement',
  dispatcherTruckRunsRegister = 'dispatcher_truck_runs_register',
  konturFocusReport = 'kontur_focus_report',
  carrierContract = 'carrier_contract',
  carrierOffer = 'carrier_offer',
  finalBid = 'final_bid',
}

export interface DocumentTpl {
  documentType: DocumentType
  filename: string
}

const request = (method: string, params: object) => mainService.request(`document.${method}`, params)

export const documentTplEnsure = (params: DocumentTpl) => request('tpl.ensure', params) as Promise<{
  upserted: boolean
  modified: boolean
}>

export const documentTplList = () => request('tpl.list', {}) as Promise<DocumentTpl[]>
