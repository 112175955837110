import './styles.sass'
import { Bid } from 'api/bid'
import { useEffect, useMemo, useRef, useState } from 'react'
import InfoCell, { InfoCellProps } from 'ui/InfoCell'
import formatDate from 'common/CarrierBids/TsToFormatDate'
import clsx from 'clsx'
import { Box, Stack, Typography } from '@mui/material'
import { CheckCircle } from '@mui/icons-material'
import formatCargo from 'util/formatCargo'
import LimitString from 'ui/LimitString'
import addressName from 'util/addressName'
import { toKilometers } from 'util/distance'
import { toTon } from 'util/weight'
import rub from 'util/formatRub'
import LoadingDayIndicator from 'ui/LoadingDayIndicator'

const MAX_WIDTH_FREESPACE_ITEM_PX = 65

interface BidCardProps {
  data: Bid
  controls?: JSX.Element
  id?: string
  className?: string
}

export default function BidCard ({ data, controls, id, className }: BidCardProps) {
  const divFreeSpaceRowRef = useRef<HTMLDivElement>(null)
  const [maxItems, setMaxItems] = useState(0)

  const descriptions = useMemo<InfoCellProps[]>(() => [
    { label: 'Груз', value: <LimitString text={formatCargo(data.cargo)} limit={15} /> },
    { label: 'За перевозку 1 тн', value: rub(data.costTon) },
    { label: 'К вывозу', value: `${toTon(data.totalWeight)} т` },
    { label: 'Расстояние', value: `${toKilometers(data.distance)} км` },
    { label: 'Дата перевозки', value: `${formatDate(data.beginTs)} - ${formatDate(data.endTs)}` }
  ], [data])

  const freeSpaceItems = useMemo<InfoCellProps[]>(() => {
    return data.freeSpace.map(item => ({
      label: <Stack direction='row' alignItems='center' spacing={1}><div>
        { item.seats > 0
          ? `${item.seats} из ${item.totalSeats}`
          : `${item.totalSeats} из ${item.totalSeats}`
        }</div>
        <LoadingDayIndicator indicator={item.indicator} />
      </Stack>,
      value: item.seats === 0
        ? <Stack direction='row' alignItems='center' spacing={1}>
            <Typography fontSize='13px' fontWeight={600}>{ formatDate(item.shipmentTs) }</Typography>
            <CheckCircle sx={{ color: '#3EAE49', width: '18px' }} />
          </Stack>
        : formatDate(item.shipmentTs),
      className: item.seats === 0 ? 'fullFilled' : undefined
    }))
  }, [data])

  useEffect(() => {
    if (divFreeSpaceRowRef.current !== null) {
      const rowWidth = divFreeSpaceRowRef.current.clientWidth
      const mi = Math.floor(rowWidth / MAX_WIDTH_FREESPACE_ITEM_PX)
      setMaxItems(freeSpaceItems.length > mi ? mi - 1 : mi)
    }
  }, [divFreeSpaceRowRef, freeSpaceItems])

  const morePlaces = useMemo(() => freeSpaceItems.length - maxItems, [freeSpaceItems, maxItems])

  return (
    <div className={clsx('bidCard', className)} id={id}>
      <div className='bidCard__header'>
        { data.carrier && <Box bgcolor='#624CE0' borderRadius='4px' m='1em 1em 0 1em' padding={1} color='#FFFFFF'>
          Заявка передана перевозчику <b>{data.carrier.organization?.shortName}</b>
        </Box>}
        <div className='bidCard__header_panel'>
          <div className='bidCard__header_panel_title'>
            <Typography fontWeight={600} fontSize='16px'>Перевозка {data.num}</Typography>
            <Typography color='#B2B2B2' fontWeight={500} fontSize='12px'>{addressName(data.loadingAddress)} - {addressName(data.unloadingAddress)}</Typography>
          </div>
          <div className='bidCard__header_panel_control'>{controls}</div>
        </div>
        <div className='bidCard__header_description'>
        { descriptions.map((item, idx) => <InfoCell {...item} key={idx} />)}
        </div>
      </div>
      { freeSpaceItems.length > 0 && <div className='bidCard__footer'>
        <div className='bidCard__footer_freeSpace'>
          <div className='bidCard__footer_freeSpace_title'>
            Свободные машино-места
          </div>
          <div className='bidCard__footer_freeSpace_row'>
            <div className='bidCard__footer_freeSpace_row_items' ref={divFreeSpaceRowRef}>
            { freeSpaceItems.slice(0, maxItems).map((item, idx) => (
                <InfoCell
                  {...item}
                  key={idx}
                  style={{ maxWidth: MAX_WIDTH_FREESPACE_ITEM_PX }}
                />
            )) }
            </div>
            { morePlaces > 0 && <div className='bidCard__footer_freeSpace_row_counter'>
              +{morePlaces}
            </div>}
          </div>
        </div>
      </div>}
    </div>
  )
}
