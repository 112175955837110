import { idPattern } from 'api/Id'
import { makeJsonSchema, SimpleSchema } from '../validate'
import { ShippingTruckRunForCreate, ShippingTruckRunStatus as Status } from 'api/shipping'
import { Optional } from 'util/type/Optional'

export type TruckRunArrived = Optional<Required<Omit<ShippingTruckRunForCreate, 'bidId' | 'referenceId'>>, 'consigneeId' | 'unloadingAddressId' | 'costCorrection' | 'trailerId'>
export type TruckRunArrivedRelayAutoFill = Optional<TruckRunArrived, 'forwarderId' | 'driverId'>

const simpleBaseSchema: SimpleSchema<TruckRunArrived> = {
  type: 'object',
  properties: {
    status: { type: 'string', enum: Object.values(Status) },
    owner: { type: 'string' },
    loadingTs: { type: 'number' },
    unloadingTs: { type: 'number' },
    driverId: { type: 'string', pattern: idPattern },
    vehicleId: { type: 'string', pattern: idPattern },
    billNumber: { type: 'string' },
    billTs: { type: 'number' },
    weight: { type: 'number', exclusiveMinimum: 0, errorMessage: 'Некорректно заполнен вес погрузки' },
    finalWeight: { type: 'number', exclusiveMinimum: 0, errorMessage: 'Некорректно заполнен вес разгрузки' },
    forwarderId: { type: 'string', pattern: idPattern },
    employeeComment: { type: 'string' },

    trailerId: { type: 'string', pattern: idPattern, optional: true },
    consigneeId: { type: 'string', pattern: idPattern, optional: true },
    unloadingAddressId: { type: 'string', pattern: idPattern, optional: true },
    costCorrection: { type: 'number', optional: true }
  }
}

const simpleAutoFillSchema: SimpleSchema<TruckRunArrivedRelayAutoFill> = {
  type: 'object',
  properties: {
    status: { type: 'string', enum: Object.values(Status) },
    owner: { type: 'string' },
    loadingTs: { type: 'number' },
    unloadingTs: { type: 'number' },
    vehicleId: { type: 'string', pattern: idPattern },
    billNumber: { type: 'string' },
    billTs: { type: 'number' },
    weight: { type: 'number', exclusiveMinimum: 0, errorMessage: 'Некорректно заполнен вес погрузки' },
    finalWeight: { type: 'number', exclusiveMinimum: 0, errorMessage: 'Некорректно заполнен вес разгрузки' },
    employeeComment: { type: 'string' },

    forwarderId: { type: 'string', pattern: idPattern, optional: true },
    driverId: { type: 'string', pattern: idPattern, optional: true },
    trailerId: { type: 'string', pattern: idPattern, optional: true },
    consigneeId: { type: 'string', pattern: idPattern, optional: true },
    unloadingAddressId: { type: 'string', pattern: idPattern, optional: true },
    costCorrection: { type: 'number', optional: true }
  }
}

export const baseSchema = makeJsonSchema<TruckRunArrived>(simpleBaseSchema)
export const autoFillSchema = makeJsonSchema<TruckRunArrivedRelayAutoFill>(simpleAutoFillSchema)
