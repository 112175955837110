import './styles.sass'
import { useEffect, useState } from 'react'
import { logList, Log, LogData } from 'api/log'
import DataTable from 'ui/DataTable'
import TsToFormatDate from 'util/TsToFormatDate'
import Modal from 'ui/Modal'
import { Button } from '@mui/material'
import { MoreVert } from '@mui/icons-material'
import { TS_IN_SECONDS } from 'constants/Time'
import NavigatePanel from 'ui/NavigatePanel'
import { useLocation } from 'react-router-dom'
import { isPhone } from 'util/isPhone'
import { useMainRoutes } from 'routes'

type RenderActions = Record<string, (data: LogData) => string>

const renderActions: RenderActions = {
  'auth.login': () => 'Вход в систему',
  'auth.logout': () => 'Выход из системы',
  'auth.modify': (data) => `Изменение сущности: ${data.col}`,
  'main.modify': (data) => `Изменение сущности: ${data.col}`
} as const

const render = (action: unknown, data: unknown): string =>
  typeof action === 'string' && Object.keys(renderActions).includes(action)
    ? renderActions[action](data as LogData)
    : `${action}`

export default function Logs () {
  const [rows, setRows] = useState<Log[]>([])
  const [logInfo, setLogInfo] = useState<{action: string, data: LogData}>()

  const { state } = useLocation()
  const { links, routesMap } = useMainRoutes()

  useEffect(() => {
    const user = state.user
    if (!isPhone(user)) {
      return
    }

    logList().then(result => setRows(result.filter(item => item.user === user)))
  }, [state])

  return (
    <>
      <div className='logs'>
        <NavigatePanel
          title='Логи'
          breadcrumbs={{
            items: [{ title: state.user }],
            defaultItems: routesMap.getBreadcrumbs(links.LOGS_PAGE)
          }}
        />
        <div className='logs__body'>
          <div className='logs__body_table'>
            <DataTable
              columns = { [
                {
                  id: 'userPresentation',
                  label: 'Автор',
                  minWidth: 170
                },
                {
                  id: 'date',
                  label: 'Дата',
                  minWidth: 170,
                  format: date => <>{ TsToFormatDate((date as number) / TS_IN_SECONDS, 'dd.MM.yyyy HH:mm') }</>
                },
                {
                  id: ['action', 'data'],
                  label: 'Действие',
                  minWidth: 170,
                  format: (action, data) => <>{ render(action, data) }</>
                },
                {
                  id: ['action', 'data'],
                  label: '',
                  align: 'center',
                  minWidth: 10,
                  format: (action, data) => <Button
                    sx={{
                      minWidth: '2em',
                      maxWidth: '2em',
                      minHeight: '2em',
                      maxHeight: '2em',
                      borderRadius: '2px',
                      padding: 0,
                      color: '#B2B2B2'
                    }}
                    onClick={() => {
                      setLogInfo({ action: action as string, data: data as LogData })
                    }}
                  ><MoreVert /></Button>
                }
              ] }
              rows = { (start, end) => rows === undefined ? [] : rows.slice(start, end) }
              rowsCount = { rows === undefined ? 0 : rows.length }
            ></DataTable>
            <Modal
              title={logInfo === undefined ? '' : `Событие: ${render(logInfo.action, logInfo.data)}`}
              open={logInfo !== undefined}
              onClose={() => setLogInfo(undefined)}
              content={ logInfo === undefined
                ? <></>
                : <pre>{ JSON.stringify(logInfo.data, null, 2) }</pre>
              }
              actions={<>
                <Button variant="outlined" onClick={() => setLogInfo(undefined)}>ОК</Button>
              </>}
            />
          </div>
        </div>
      </div>
    </>
  )
}
