import { BidForCreate } from 'api/bid'
import { idPattern } from 'api/Id'
import { makeJsonSchema, SimpleSchema } from './validate'
import {
  MAX_LOAD_MSG,
  SCALE_LENGTH_MAX,
  SCALE_LENGTH_MIN,
  SCALE_LENGTH_MSG,
  WEIGHING_SCALE_MAX_LOAD,
  WEIGHING_SCALE_MIN_LOAD
} from 'util/checkWeighingValue'
import { HEIGHT_RESTRICTION_MAX, HEIGHT_RESTRICTION_MSG } from 'util/checkHeightRestrictionValue'
import { UNDERWEIGHT_MAX_TON, UNDERWEIGHT_MSG } from 'util/checkUnderweightValue'

type RelayFields = 'carrierId' | 'autoFill' | 'beginTs' | 'endTs'
export type BidManaged = Omit<BidForCreate, RelayFields | 'blockedManualFill' | 'blockedManualFillComment'>
export type BidRelay = BidManaged & Required<Pick<BidForCreate, RelayFields>>

const simpleSchemaManaged: SimpleSchema<BidManaged> = {
  type: 'object',
  properties: {
    loadingAddressId: { type: 'string', pattern: idPattern },
    unloadingAddressId: { type: 'string', pattern: idPattern },
    typeVehicleSlugs: { type: 'array', items: { type: 'string' } },
    customerId: { type: 'string', pattern: idPattern },
    cargo: { type: 'string' },
    totalWeight: { type: 'number' },
    distance: { type: 'number' },
    costTon: { type: 'number' },
    costCargo: { type: 'number' },
    loadingMethod: { type: 'string' },
    unloadingMethod: { type: 'string' },
    plusTen: { type: 'boolean' },
    consigneeId: { type: 'string', pattern: idPattern },
    consignorId: { type: 'string', pattern: idPattern },
    noСlaim: { type: 'boolean' },
    loadingWeighing: {
      type: 'object',
      properties: {
        weighingScaleLength: { type: 'number', minimum: SCALE_LENGTH_MIN, maximum: SCALE_LENGTH_MAX, optional: true, errorMessage: SCALE_LENGTH_MSG },
        weighingScaleMaxLoad: { type: 'number', minimum: WEIGHING_SCALE_MIN_LOAD, maximum: WEIGHING_SCALE_MAX_LOAD, optional: true, errorMessage: MAX_LOAD_MSG },
        heightRestriction: { type: 'number', optional: true, maximum: HEIGHT_RESTRICTION_MAX, errorMessage: HEIGHT_RESTRICTION_MSG }
      }
    },
    unloadingWeighing: {
      type: 'object',
      properties: {
        weighingScaleLength: { type: 'number', minimum: SCALE_LENGTH_MIN, maximum: SCALE_LENGTH_MAX, optional: true, errorMessage: SCALE_LENGTH_MSG },
        weighingScaleMaxLoad: { type: 'number', minimum: WEIGHING_SCALE_MIN_LOAD, maximum: WEIGHING_SCALE_MAX_LOAD, optional: true, errorMessage: MAX_LOAD_MSG },
        heightRestriction: { type: 'number', optional: true, maximum: HEIGHT_RESTRICTION_MAX, errorMessage: HEIGHT_RESTRICTION_MSG }
      }
    },
    underweight: { type: 'number', minimum: 0, maximum: UNDERWEIGHT_MAX_TON, optional: true, errorMessage: UNDERWEIGHT_MSG }
  }
}

const simpleSchemaRelay: SimpleSchema<BidRelay> = {
  type: 'object',
  properties: {
    ...simpleSchemaManaged.properties,
    carrierId: { type: 'string', pattern: idPattern },
    autoFill: { type: 'boolean' },
    beginTs: { type: 'number' },
    endTs: { type: 'number' }
  }
}

export const schemaBidManaged = makeJsonSchema<BidManaged>(simpleSchemaManaged)
export const schemaBidRelay = makeJsonSchema<BidRelay>(simpleSchemaRelay)
