import { CarNumberType as Type } from 'api/CarNumberType'
import { Mask } from 'react-text-mask'
import { EN_SYMBOL, RU_SYMBOL } from './CarNumberRegionMask'

export function VehicleNumberMask (type?: Type): Mask {
  switch (type) {
    case Type.rus:
    case Type.dpr:
    case Type.lpr:
      return [RU_SYMBOL, /\d/, /\d/, /\d/, RU_SYMBOL, RU_SYMBOL]
    case Type.ua:
      return [/\d/, /\d/, /\d/, /\d/, EN_SYMBOL, EN_SYMBOL]
    default:
      return false
  }
}
