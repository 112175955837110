import { Box, Button, Stack } from '@mui/material'
import { useAuthContext } from 'AuthContext'
import { isId } from 'api/Id'
import { BidCargo } from 'api/bid'
import { ShippingTruckRunLoad, ShippingTruckRunStatus, shippingTruckRunUpdate } from 'api/shipping'
import { useEffect, useState } from 'react'
import DatePicker from 'ui/DatePicker'
import Modal from 'ui/Modal'
import Select from 'ui/Select'
import TextField from 'ui/TextField'
import formatCargo from 'util/formatCargo'
import valueMethod from 'util/valueMethod'
import { toKilogram, toTon } from 'util/weight'
import schema, { LoadConfrimData as Orgin } from 'validation/LoadConfrim'
import useValidate from 'validation/validate'

export type LoadConfrimData = Partial<Orgin>
const MAX_WEIGHT = 50000
const WEIGHT_ERROR = 'Вес погрузки должен быть до 50 тонн'

export interface Params {
  truckRun?: Partial<ShippingTruckRunLoad>
  cargoList: BidCargo[]
  onClose: () => void
  onSuccess: () => void
}

export default function ForwarderLoadConfirmModal ({ truckRun, cargoList, onClose, onSuccess }: Params) {
  const { handleResponseFailure } = useAuthContext()
  const [data, setData] = useState<LoadConfrimData>()
  const [billNumberError, setBillNumberError] = useState<string>()

  const { check, errors, cleanErrors } = useValidate(schema)

  useEffect(() => {
    if (truckRun === undefined && data !== undefined) {
      setData(undefined)
      cleanErrors()
    }
  }, [truckRun, cleanErrors, data])

  const close = () => {
    setData(undefined)
    cleanErrors()
    onClose()
  }

  const confrim = async () => {
    const id = truckRun?.id
    if (!isId(id)) {
      return
    }

    const clean = data ?? {}
    if (!check(clean) || clean.weight > MAX_WEIGHT) {
      console.error(errors)
      return
    }

    const { weight, billNumber, billTs, actualCargoSlug } = clean

    const { success, conflicts } = await shippingTruckRunUpdate({
      id, status: ShippingTruckRunStatus.way, weight, billNumber, billTs, actualCargoSlug
    })

    if (!success && conflicts?.truckRunToWay) {
      handleResponseFailure(`Рейс ${conflicts.truckRunToWay.num} уже в пути`)
      return
    }

    if (!success && conflicts?.billNumberExists) {
      setBillNumberError('Номер ТТН/ТрН используется в другом рейсе')
      return
    }

    onSuccess()
  }

  return (<Modal
    maxWidth={false}
    open={isId(truckRun?.id)}
    onClose={close}
    title='Отправить рейс'
    content={<Box minWidth='300px'>
      <Select
        name='actualCargoSlug'
        label='Фактический груз'
        hint={truckRun?.cargo?.name}
        placeholder='Выберите груз'
        options={cargoList
          .sort(item => {
            if (!truckRun?.cargo?.externalId || !item.productBaseId) {
              return 0
            }

            if (truckRun?.cargo?.externalId === item.productBaseId) {
              return -1
            }

            return 1
          })
          .map(item => ({ value: item.slug, name: formatCargo(item) }))}
        value={data?.actualCargoSlug}
        onChange={actualCargoSlug => setData(prev => ({ ...prev, actualCargoSlug }))}
        errors={errors}
      />
      <DatePicker
        name='billTs'
        label='Дата ТрН/ТТН'
        value={data?.billTs}
        onChange={billTs => setData(({ ...data, billTs }))}
        errors={errors}
      />
      <TextField
        name='billNumber'
        label='Номер ТрН/ТТН'
        placeholder='Номер ТрН/ТТН'
        value={data?.billNumber}
        onChange={e => {
          const { target: { value: billNumber } } = e
          setData(({ ...data, billNumber }))
        }}
        errors={errors}
        errorMessage={billNumberError}
      />
      <TextField
        name='weight'
        label='Вес погрузки, тн'
        placeholder='Вес погрузки, тн'
        value={valueMethod(data?.weight)(toTon)}
        onChange={e => {
          const { target: { value } } = e
          setData(({ ...data, weight: value === '' ? undefined : toKilogram(Number(value)) }))
        }}
        typeNumber
        errors={errors}
        errorMessage={data?.weight && data.weight > MAX_WEIGHT ? WEIGHT_ERROR : undefined}
      />
    </Box>}
    actions={<Stack direction='row' spacing={2}>
      <Button variant='outlined' onClick={close} >Отменить</Button>
      <Button
        variant='contained'
        onClick={confrim}
      >Отправить</Button>
    </Stack>}
  />)
}
