import { mainService } from 'api/service'
import { Id } from 'api/Id'

export enum BlackListType {
  driver = 'driver',
  vehicle = 'vehicle',
}

export enum BlackListStatus {
  control = 'control',
  ban = 'ban',
}

export interface BlackListEntry {
  id: Id
  externalId: number
  type: BlackListType
  status: BlackListStatus
  object: string
  description: string
}

export interface BlackListFilter {
  type?: BlackListType
}

const request = (method: string, params: object) => mainService.request(`black-list.${method}`, params)

export const blackListList = (filter: BlackListFilter = {}) => request('list', { filter }) as Promise<BlackListEntry[]>
