import { NotifyEvent } from '.'
import { useEffect } from 'react'

export interface Data {
  truckRunNum: string
  truckRunId: string
  carrierName: string
  unloadingAddress: string
  fromTime: string
  toTime: string
  date: string
  message: string
}

const SLUGS = [
  'aston_traffic_slot_reservation_event',
  'aston_traffic_slot_reservation_for_carrier_event'
]

export default function AstonTrafficSlotReservation ({ slug, data, toast } :NotifyEvent) {
  useEffect(() => {
    if (slug && !SLUGS.includes(slug)) {
      return
    }

    if (toast === undefined) {
      return
    }

    if (data === undefined) {
      return
    }

    const { message } = data as Data

    toast(message, 'success')
  }, [slug, data, toast])

  return (<></>)
}
