export const ORGANIZATION_INN_MASK = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
export const ORGANIZATION_INN_ST_MASK = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
export const ORGANIZATION_OGRNIP_MASK = [/\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/]
export const ORGANIZATION_OKPO_MASK = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
export const ORGANIZATION_OGRN_MASK = [/\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/]
export const ORGANIZATION_CORR_ACC_NUMBER_MASK = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
export const ORGANIZATION_KPP_MASK = [/\d/, /\d/, /\d/, /\d/, /[\dA-Z]/, /[\dA-Z]/, /\d/, /\d/, /\d/]
export const ORGANIZATION_CHECK_ACC_NUMBER_MASK = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, '.', /\d/, /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
export const ORGANIZATION_BIK_MASK = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]

export function cleanMaskNum (value: string): string {
  return value.replace(/\D/g, '')
}

export function cleanMaskNumAlpha (value: string): string {
  return value.replace(/[^\dA-Z]/g, '')
}

export const ORGANIZATION_OKVAD_MASK = (value: string) => {
  const num = value.replace(/\D/g, '')
  if (num.length === 2) {
    return [/\d/, /\d/]
  } else if (num.length === 3) {
    return [/\d/, /\d/, '.', /\d/]
  } else if (num.length === 4) {
    return [/\d/, /\d/, '.', /\d/, /\d/]
  } else if (num.length === 5) {
    return [/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/]
  } else if (num.length === 6) {
    return [/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/]
  }

  return [/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/]
}

export const formatToOkved = (value: string): string => {
  const cleanedString = value.replace(/\./g, '')
  const groups = cleanedString.match(/.{1,2}/g)
  return groups ? groups.join('.') : cleanedString
}
