import './styles.sass'
import { useNavigate, useParams } from 'react-router-dom'
import { useMainRoutes } from 'routes'
import NavigatePanel from 'ui/NavigatePanel'
import { Button, IconButton, Stack } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import InfoCard from 'ui/InfoCard'
import { actGet, Act, ActStatus } from 'api/act'
import { useCallback, useEffect, useState } from 'react'
import { isId } from 'api/Id'
import TsToFormatDate from 'util/TsToFormatDate'
import addressName from 'util/addressName'
import DataTable from 'ui/DataTable'
import TextField from 'ui/TextField'
import SquareButton from 'ui/SquareButton'
import { ClearSharp, MoreHoriz } from '@mui/icons-material'
import { cellDocs, cellStatus, cellSum } from 'ui/ActRowComponents'
import { getChipsByStatus } from 'pages/CarrierActs'
import ActRevocationWizard from 'ui/ActRevocationWizard'

export default function CarrierAct () {
  const [data, setData] = useState<Act>()
  const [openRevocationModal, setOpenRevocationModal] = useState(false)
  const { links, routesMap } = useMainRoutes()
  const navigate = useNavigate()
  const { id } = useParams()

  const init = useCallback(() => isId(id) && actGet(id).then((act) => act && setData(act)), [id])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { init() }, [])

  if (!data) {
    return <></>
  }

  const title = `УПД ${data.num} от ${TsToFormatDate(data.createTs, 'dd.MM.yyyy')}`
  const shortageSum = data.truckRuns.reduce((prev, cur) => cur.shortage.cost + prev, 0)

  return (<>
    <div className='carrierAct'>
      <NavigatePanel
        breadcrumbs={{
          defaultItems: routesMap.getBreadcrumbs(links.CARRIER_DOCUMENTS_ACTS_PAGE),
          items: [{ title }]
        }}
        title={<Stack direction='row' alignItems='center' gap={2}>
          {title}
          { getChipsByStatus(data.status) }
          { data.status === ActStatus.waitingSigning && <Button
            variant='outlined'
            color='secondary'
            size='small'
            endIcon={<ClearSharp />}
            onClick={() => setOpenRevocationModal(true)}
          >Аннулировать</Button>}
        </Stack>}
        actions={<IconButton onClick={() => navigate(links.CARRIER_DOCUMENTS_ACTS_PAGE)}>
          <CloseIcon />
        </IconButton>}
      />
      <div className='carrierAct__body'>
        <div className='carrierAct__content'>
          <InfoCard title='Общая информация'><>
            <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} sx={{ mb: 2 }}>
              <TextField
                name='customer'
                label='Заказчик'
                disabled={true}
                width='33%'
                value={data.bid.customer.fullName}
              />
              <TextField
                name='sendTs'
                label='Дата отправки'
                disabled={true}
                width='33%'
                value={TsToFormatDate(data.sendTs, 'dd.MM.yyyy HH:mm')}
              />
              <TextField
                name='bid'
                label='Перевозка'
                disabled={true}
                width='33%'
                value={data.bid.num}
              />
            </Stack>
            <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} sx={{ mb: 2 }}>
              <TextField
                name='loadingAddress'
                label='Пункт погрузки'
                disabled={true}
                width='100%'
                value={addressName(data.bid.loadingAddress)}
              />
            </Stack>
            <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
              <TextField
                name='loadingAddress'
                label='Пункт разгрузки'
                disabled={true}
                width='100%'
                value={addressName(data.unloadingAddress ?? data.bid.unloadingAddress)}
              />
            </Stack>
          </></InfoCard>
          <InfoCard title='Документы'>
            <Stack direction='row' alignItems='top' spacing={2}>
              {cellDocs({
                filename: data.filename,
                shortageFilename: data.shortageFilename,
                agreementFilename: data.agreementFilename,
                invoiceFilename: data.invoiceFilename,
                registryFilename: data.registryFilename,
                finalBidFilename: data.finalBidFilename
              })}
              { cellStatus(data)(getChipsByStatus) }
              {cellSum({
                actSum: data.totalSum,
                shortageSum: data.shortageFilename !== undefined ? shortageSum : 0,
                finalSum: data.invoiceFilename !== undefined ? data.totalSum - shortageSum : undefined,
                hasFinalBid: data.finalBidFilename !== undefined
              })}
            </Stack>
          </InfoCard>
          <InfoCard title='Включенные рейсы'>
            <DataTable
              verticalAlign='top'
              columns = { [
                {
                  id: ['num', 'loadingTs'],
                  label: 'Рейс',
                  format: (num, loadingTs) => <>{`${num} от ${TsToFormatDate(loadingTs as number, 'dd.MM.yyyy')}`}</>
                },
                {
                  id: 'id',
                  label: '',
                  align: 'right',
                  format: (id) => <SquareButton variant='contained' onClick={() => navigate(`${links.CARRIER_TRIPS_PAGE}/${id}`)}><MoreHoriz /></SquareButton>
                }
              ] }
              rows = { (start, end) => data.truckRuns.slice(start, end) }
              rowsCount = { data.truckRuns.length }
            />
          </InfoCard>
        </div>
      </div>
    </div>
    <ActRevocationWizard
      open={openRevocationModal}
      actId={data.id}
      onClose={() => {
        setOpenRevocationModal(false)
        init()
      }}
    />
  </>)
}
