import './styles.sass'
import { Button, Stack, Box, Typography } from '@mui/material'
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import DataTable from 'ui/DataTable'
import {
  forwarderArchive,
  ForwarderArchiveParams,
  ForwarderAttach,
  ForwarderList,
  forwarderList,
  ForwarderPosition
} from 'api/forwarder'
import { useNavigate, useParams } from 'react-router-dom'
import ActionsMenu from 'ui/ActionsMenu'
import NavigatePanel from 'ui/NavigatePanel'
import { useMainRoutes } from 'routes'
import { Person, personName } from 'util/personName'
import formatPhone from 'util/formatPhone'
import { Id } from 'api/Id'
import Modal from 'ui/Modal'
import DatePicker from 'ui/DatePicker'
import TextField from 'ui/TextField'
import AddIcon from '@mui/icons-material/Add'
import VisibilityIcon from '@mui/icons-material/Visibility'
import ArchiveIcon from '@mui/icons-material/Archive'
import EditIcon from '@mui/icons-material/Edit'
import { UserStatus } from 'api/UserStatus'
import ChipUserStatus from 'common/ChipUserStatus/forwarder'
import { isEnum } from 'util/isEnum'
import TsToFormatDate from 'util/TsToFormatDate'
import shortAddress from 'util/shortAddress'

const statusMap = new Map<UserStatus | '', string>()
  .set('', 'Все')
  .set(UserStatus.active, 'Активен')
  .set(UserStatus.archive, 'В архиве')

export default function Forwarders () {
  const { links, routesMap } = useMainRoutes()

  // const [rows, setRows] = useState<Forwarder[]>()
  const [list, setList] = useState<ForwarderList[]>([])
  const [searched, setSearched] = useState<(number|string)[]>()
  const [archiveData, setArchiveData] = useState<ForwarderArchiveParams | null>()
  const { status } = useParams()
  const filterStatus = isEnum(UserStatus)(status) ? status : ''

  const navigate = useNavigate()

  const loadList = useCallback(() => forwarderList({
    status: filterStatus === '' ? [] : [filterStatus]
  }).then(setList), [filterStatus])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { loadList() }, [filterStatus])

  const rows = useMemo(() => {
    return list.map(({ position, attaches, ...fields }) => ({
      ...fields,
      attaches: attaches ?? [],
      position: position === ForwarderPosition.forwarder
        ? 'Экспедитор'
        : position === ForwarderPosition.chief_forwarder
          ? 'Старший экспедитор'
          : position
    }))
  }, [list])

  const openArchiveModal = (id?: Id) => {
    if (id === undefined) {
      return
    }

    setArchiveData({ id, date: Math.floor(Date.now() / 1000) })
  }

  const updateArchiveString = (key: keyof Pick<ForwarderArchiveParams, 'comment'>) => {
    return (e: ChangeEvent<HTMLElement & {value: string}>) => {
      if (!archiveData) {
        return
      }

      setArchiveData({ ...archiveData, [key]: e.target.value })
    }
  }

  const updateArchiveDate = (key: keyof ForwarderArchiveParams) => {
    return (value: number) => {
      if (!archiveData) {
        return
      }

      setArchiveData({ ...archiveData, [key]: value })
    }
  }

  const archive = useCallback(async () => {
    if (!archiveData) {
      return
    }

    await forwarderArchive(archiveData)
    setArchiveData(undefined)
    loadList()
  }, [archiveData, loadList])

  return (<>
    <Modal
      title='Архивировать экспедитора'
      open={archiveData !== undefined}
      onClose={() => setArchiveData(undefined)}
      content={archiveData
        ? <Stack sx={{ minWidth: '500px' }} >
            <Box sx={{ mb: 2 }}>
              <DatePicker
                label='Дата архивации'
                value={archiveData.date}
                onChange={updateArchiveDate('date')}
                disabled
              />
            </Box>
            <Box>
              <TextField
                rows={6}
                label="Комментарий"
                width='100%'
                value={archiveData.comment}
                onChange={updateArchiveString('comment')}
                placeholder="Добавьте комментарий, например причину увольнения"
              />
            </Box>
          </Stack>
        : <></>
      }
      actions={ archiveData
        ? <>
          <Button color="secondary" variant="outlined" onClick={() => setArchiveData(undefined)}>Отменить</Button>
          <Button variant="contained" onClick={ () => { archive() } }>Архивировать</Button>
        </>
        : <></>
      }
    />
    <div className='forwarders'>
      <NavigatePanel
        search={{
          rows: rows === undefined ? [] : rows,
          fields: [
            'firstName',
            'secondName',
            'familyName',
            'position',
            'email',
            'phone'
          ],
          onChange: setSearched
        }}
        title='Экспедиторы'
        breadcrumbs={{
          items: routesMap.getBreadcrumbs(links.REGISTRY_FORWARDERS_PAGE)
        }}
        tabs={{
          items: [...statusMap].map(([value, label]) => ({ label, value })),
          value: filterStatus,
          onChange: value => navigate(links.REGISTRY_FORWARDERS_PAGE + (value === '' ? '' : `/${value}`))
        }}
        actions={
          <Button variant="contained" size="small" endIcon={<AddIcon />} onClick={() => navigate(`${links.REGISTRY_FORWARDER_PAGE}/add`)} sx={{ textTransform: 'none' }}>
            Добавить экспедитора
          </Button>
        }
      />
      <div className='forwarders__content'>
        <div className='forwarders__content_table'>
          <DataTable
            columns={[
              {
                id: ['firstName', 'secondName', 'familyName'],
                label: 'ФИО',
                minWidth: 170,
                format: (firstName, secondName, familyName) => <>{personName({ firstName, secondName, familyName } as Person)}</>
              },
              {
                id: 'position',
                label: 'Должность',
                minWidth: 170
              },
              {
                id: 'email',
                label: 'Email',
                minWidth: 170
              },
              {
                id: 'phone',
                label: 'Номер телефона',
                minWidth: 170,
                format: (phone) => <>{ formatPhone(phone as string) }</>
              },
              {
                id: ['id', 'attaches'],
                label: 'Закрепления',
                minWidth: 170,
                format: (id, attach) => {
                  return (<>{
                    (attach as ForwarderAttach[]).map(
                      ({ beginPeriodTs, endPeriodTs, loadingAddress }, idx) =>
                        (<Typography fontSize='0.9rem' key={`attach-${id}-${idx}`}>
                          {TsToFormatDate(beginPeriodTs, 'dd.MM.yyyy')} - {TsToFormatDate(endPeriodTs, 'dd.MM.yyyy')} {shortAddress(loadingAddress)}
                        </Typography>))}</>
                  )
                }
              },
              {
                id: ['status', 'truckRuns'],
                label: 'Статус',
                format: (status, truckRuns) => <Box sx={{ width: '12em' }}><ChipUserStatus status={status as UserStatus} truckRuns={truckRuns as Id[] | undefined}/></Box>
              },
              {
                id: ['id', 'status', 'truckRuns'],
                label: '',
                align: 'center',
                minWidth: 10,
                format: (id, status, truckRuns) => <ActionsMenu key={`${id}`} actions = {
                  status === UserStatus.archive || (Array.isArray(truckRuns) && truckRuns.length > 0)
                    ? [
                        {
                          caption: status === UserStatus.archive ? 'Посмотреть' : 'Редактировать',
                          onClick: () => navigate(`${links.REGISTRY_FORWARDER_PAGE}/${id}`),
                          icon: status === UserStatus.archive ? <VisibilityIcon /> : <EditIcon />
                        }
                      ]
                    : [
                        {
                          caption: 'Редактировать',
                          onClick: () => navigate(`${links.REGISTRY_FORWARDER_PAGE}/${id}`),
                          icon: <EditIcon />
                        },
                        {
                          caption: 'Архивировать',
                          onClick: () => openArchiveModal(id as Id),
                          icon: <ArchiveIcon />
                        }
                      ]
                } />
              }
            ]}
            rows = { (start, end) => rows === undefined ? [] : rows.filter(row => searched === undefined ? true : searched.includes(row.id)).slice(start, end) }
            rowsCount = { rows === undefined ? 0 : (searched === undefined ? rows.length : searched.length) }
          />
        </div>
      </div>
    </div>
  </>)
}
