import { Button } from '@mui/material'
import InfoCard from 'ui/InfoCard'
import './styles.sass'
import { BidCargoTypeForUpdate, bidCargoTypeList, bidCargoTypePut, bidCargoTypeDelete } from 'api/bid'
import { useCallback, useEffect, useState } from 'react'
import { Box, Stack } from '@mui/system'
import SquareButton from 'ui/SquareButton'
import { Delete, Save } from '@mui/icons-material'
import TextField from 'ui/TextField'
import CollapseBox from 'ui/CollapseBox'
import { useAuthContext } from 'AuthContext'
import Upload from 'ui/Upload'

const Row = ({ data: origin, onChange }: { data: BidCargoTypeForUpdate, onChange: () => void}) => {
  const { handleResponseSuccess } = useAuthContext()
  const [data, setData] = useState<BidCargoTypeForUpdate>(origin)

  const updateString = (key: keyof BidCargoTypeForUpdate) => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      setData(prev => ({ ...prev, [key]: e.target.value }))
    }
  }

  const updateNumber = (key: keyof BidCargoTypeForUpdate) => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      const num = Number(e.target.value)
      setData(prev => ({ ...prev, [key]: num || undefined }))
    }
  }

  const uploadTpl = (filename: string) => {
    setData(prev => ({ ...prev, invoiceTplFilename: filename }))
  }

  return (<>
    <Stack direction='row' spacing={2} mb='10px' alignItems='center'>
      <TextField
        label='Название'
        width='25%'
        value={data.name}
        onChange={updateString('name')}
      />
      <TextField
        label='Разрешенная недостача, кг'
        width='25%'
        value={data.underweight}
        onChange={updateNumber('underweight')}
        precision={0}
      />
      <Upload
        sx={{ width: '25%' }}
        label='Счет (Специальный шаблон)'
        value={data.invoiceTplFilename}
        onChange={uploadTpl}
      />
      <SquareButton
        variant='contained'
        onClick={() => {
          bidCargoTypePut(data)
            .then(({ modified, upserted }) => {
              if (modified || upserted) {
                handleResponseSuccess('Данные сохранены')
                onChange()
              }
            })
        }}
      ><Save sx={{ width: '16px' }} /></SquareButton>
      { data.slug !== undefined && <SquareButton
        variant='contained'
        color='secondary'
        onClick={() => {
          if (data.slug) {
            bidCargoTypeDelete(data.slug)
              .then(result => {
                if (result) {
                  onChange()
                }
              })
          }
        }}
      ><Delete sx={{ width: '16px' }} /></SquareButton>}
    </Stack>
  </>)
}

export default function BidCargoType () {
  const [list, setList] = useState<BidCargoTypeForUpdate[]>([])
  const init = useCallback(() => bidCargoTypeList().then(setList), [])
  useEffect(() => { init() }, [init])

  return (
    <InfoCard
      title='Типы грузов'
      actions={<Button
        variant='outlined'
        size='small'
        onClick={() => {
          setList(prev => [...prev, {}])
        }}
      >Добавить</Button>}
    ><>
      <CollapseBox title='Типы грузов'><>
      { list.filter(item => item.slug !== undefined).map(item => <Row data={item} key={item.slug} onChange={() => init()}/>)}
      </></CollapseBox>
      <Box>
      { list.filter(item => item.slug === undefined).map((item, idx) => <Row data={item} key={idx} onChange={() => init()}/>)}
      </Box>
    </></InfoCard>
  )
}
