import { useCallback, useEffect, useMemo, useState } from 'react'
import './styles.sass'
import { StepProps } from 'ui/ModalSigning'
import Alert from '@mui/material/Alert'
import { ActWizardData, skipDiadoc } from './index'
import { Box, Typography } from '@mui/material'
import Select from 'ui/Select'
import { LocalStorage } from 'util/LocalStorage'
import { ErrorResponseStatus, OkResponseStatus, diadocSetOrganization } from 'api/diadoc'
import ActPreview from 'pages/ActPreview'
import { Act } from 'api/act'

const boxIdStorage: LocalStorage<string> = new LocalStorage('boxId', (x: unknown): x is string => {
  return typeof x == 'string'
})

export default function OrganizationSelect ({ isActive, data, handleNext }: StepProps) {
  const [boxId, setBoxId] = useState('')
  const [showForm, setShowForm] = useState(false)
  const [error, setError] = useState('')
  const d = data as ActWizardData & { acts?: Act[] }

  const acts: Act[] = useMemo(() => {
    if (!d.isDispatcher) {
      return []
    }

    if (d.acts === undefined) {
      return []
    }

    return d.acts.filter(item => item.bid.customer.diadocBoxId === d.boxId)
  }, [d.boxId, d.acts, d.isDispatcher])

  const set = useCallback(async (boxId?: string, fnsParticipantId?: string) => {
    if (!boxId) return

    return diadocSetOrganization({ boxId, fnsParticipantId })
      .then((response) => {
        switch (response.status) {
          case ErrorResponseStatus.err:
            throw new Error(response.error !== undefined ? response.error : 'Неизвестная ошибка')

          case OkResponseStatus.ok:
            setBoxId(boxId)
            d.onBoxIdSelected(boxId)
            boxIdStorage.set(boxId)
        }
      })
  }, [d])

  useEffect(() => {
    if (isActive) {
      if (skipDiadoc()) {
        handleNext()
      } else if (d.organizationList === undefined) {
        setError('Ошибка! Список организаций не создан')
      } else if (d.organizationList.length === 1 && !d.isDispatcher) {
        set(d.organizationList[0].boxId, d.organizationList[0].fnsParticipantId)
          .then(handleNext)
      } else {
        const defaultBoxId = boxIdStorage.get()
        const bId = defaultBoxId && d.organizationList.find((o) => o.boxId === defaultBoxId)
          ? defaultBoxId
          : d.organizationList[0].boxId

        const fnsId = d.organizationList.find((o) => o.boxId === bId)?.fnsParticipantId
        set(bId, fnsId).then(() => setShowForm(true))
      }
    }
  }, [isActive, d, handleNext, set])

  if (!isActive) {
    return <></>
  }

  return (
    <Box sx={{ minWidth: '500px', minHeight: '250px' }}>
      { showForm && d.organizationList && <>

        <Typography fontSize='14px' fontWeight={400} color='#B2B2B2' mb='2em'>
          Для отправки необходимо выбрать организацию.
        </Typography>

        <Select
          label='Организация'
          options={d.organizationList.map(({ boxId, shortName }) => ({ value: boxId, name: shortName }))}
          value={boxId}
          onChange={set}
          width='100%'
          errorMessage={d.isDispatcher && acts.length === 0 ? 'Для выбранной организации нет документов для подписания' : undefined}
        />

        { (d.isDispatcher && !error) && <>
          { acts.length > 1 && <Typography mb={2}>
            Список УПД для подписания:
          </Typography> }
          <ActPreview acts={acts} />
        </> }

        { error && <Alert severity="error">{error}</Alert> }

      </> }
    </Box>
  )
}
