import { Service } from './service'

export const MEDIA_SLUG = 'media'
export const VERSION_REST_API = 'v1'

export class Media extends Service {
  public async requestJson (slug: string, data: unknown, auth: boolean = true): Promise<unknown> {
    const response = await this.requestApi(slug, data, auth)
    return response.json()
  }

  public async requestBlob (slug: string, data: unknown, auth: boolean = true): Promise<Blob> {
    const response = await this.requestApi(slug, data, auth)
    return response.blob()
  }

  protected async requestApi (slug: string, data: unknown, auth: boolean = true) {
    const headers = await this.headers(auth)
    const response = await fetch(`${this.prefix}/${this.slug}/${VERSION_REST_API}/${slug}`, {
      method: 'POST',
      headers: { 'content-type': 'application/json', ...headers },
      body: JSON.stringify(data)
    })

    if (response.status !== 200) {
      throw new Error(response.statusText)
    }

    return response
  }
}

export const mediaService = new Media(MEDIA_SLUG)

export interface MediaZipCreateParams {
  filename: string
  toName: string
  inFolder?: string
}
export interface XLSXTableCell {
  value: string | number
  title: string
}
export interface XLSXTableRow {
  cells: XLSXTableCell[]
}
export interface XLSXTableParams {
  rowsWithTitle: XLSXTableRow[]
}
export const mediaGenerateZip = (params: MediaZipCreateParams[]) => mediaService.requestBlob('generate.zip', params) as Promise<Blob>
export const mediaGenerateXLSX = (params: XLSXTableParams) => mediaService.requestBlob('generate.xlsx', params) as Promise<Blob>
