import { Typography } from '@mui/material'
import Tooltip from 'ui/Tooltip'

export interface Params {
  text: string
  limit: number
}

export default function LimitString ({ text, limit }: Params) {
  const lenght = text.length
  if (lenght > limit) {
    return (<Tooltip title={<Typography fontSize='14px' fontWeight={600} >{text}</Typography>} arrow placement='top' view={true}>
      <span style={{ cursor: 'pointer' }}>{text.slice(0, limit)}...</span>
    </Tooltip>)
  }

  return (<span>{text}</span>)
}
