import { Typography, TypographyProps } from '@mui/material'
import { Address } from 'api/address'

export function ShortAddress ({ address: { name, addressOrig = '-' }, ...props }: TypographyProps & { address: Address }) {
  return (<Typography {...props}>
    {/* <b>
    { name ? `${name}, ${address.city},` : `${address.city},` }
    </b>
    { address.district ? ` ${address.district},` : '' }
    { ` ${address.region}.` } */}
    {`${name}, ${addressOrig}`}
  </Typography>)
}
