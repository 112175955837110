import { Button, Stack, Alert } from '@mui/material'
import { isId } from 'api/Id'
import { ProfileAll, profileAllCreate, ProfileAllForUpdate, profileAllUpdate } from 'api/profile'
import { userCreate, UserRole } from 'api/user'
import { UserStatus } from 'api/UserStatus'
import { useAuthContext } from 'AuthContext'
import { EmailMask } from 'common/Mask/EmailMask'
import { PHONE_MASK, phoneClean } from 'common/Mask/PhoneMask'
import { UserRoleMap } from 'entity/User'
import { ChangeEvent, useEffect, useState } from 'react'
import Modal from 'ui/Modal'
import PasswordField from 'ui/PasswordField'
import Select from 'ui/Select'
import TextField from 'ui/TextField'
import onlyFields from 'util/onlyFields'
import authSchema, { AuthUserCreate } from 'validation/createUser'
import schemaProfile from 'validation/ProfileCreate'
import useValidate from 'validation/validate'

export type AuthUserData = Partial<AuthUserCreate>
export type ProfileData = Partial<ProfileAllForUpdate>

export interface Params {
  profile?: Partial<ProfileAll>
  open?: boolean
  onClose: () => void
  onSuccess: () => void
}

export default function CreateModal ({ open, profile, onClose, onSuccess }:Params) {
  const { handleResponseSuccess, handleResponseFailure } = useAuthContext()

  const [authUser, setAuthUser] = useState<AuthUserData>({})
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [editProfile, setEditProfile] = useState(false)
  const [systemError, setSystemError] = useState<string>()

  const [profileData, setProfileData] = useState<ProfileData>({})

  const { check: checkAuth, errors: errorsAuth } = useValidate(authSchema)
  const { check: checkProfile, errors: errorsProfile } = useValidate(schemaProfile)

  const updateProfileString = (key: keyof ProfileData) => {
    return ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      setProfileData(prev => ({ ...prev, [key]: value !== '' ? value : undefined }))
    }
  }

  const handleClose = () => {
    setAuthUser({})
    setShowPassword(false)
    setEditProfile(false)
    setSystemError(undefined)
    setProfileData({})
    onClose()
  }

  useEffect(() => {
    if (profile === undefined) {
      return
    }

    if (profile.phone) {
      setAuthUser({ phone: profile.phone })
    }

    setProfileData(onlyFields(profile, 'id', 'email', 'firstName', 'familyName', 'secondName') as ProfileData)
    setEditProfile(true)
  }, [profile])

  const createAuthUser = () => {
    if (!checkAuth(authUser)) {
      return
    }

    const { role, ...data } = authUser

    userCreate({ ...data, roles: [role], status: UserStatus.active })
      .then(success => {
        if (success) {
          handleResponseSuccess('Пользователь создан')
          setEditProfile(true)
          return
        }

        throw new Error()
      })
      .catch(() => {
        setSystemError('При создании пользователя произошла ошибка. Попробуйте позже')
      })
  }

  const saveProfile = () => {
    const { id, ...data } = profileData

    if (!checkProfile(data)) {
      return
    }

    if (isId(id)) {
      profileAllUpdate({ id, ...data })
        .then(success => {
          if (success) {
            handleResponseSuccess('Профиль пользователя обновлен')
            onSuccess()
            onClose()
            return
          }

          handleResponseFailure('Данные профиля не были обновлены')
        })
        .catch(() => {
          setSystemError('При обновлении профиля произошла ошибка. Попробуйте позже')
        })

      return
    }

    if (authUser.phone) {
      profileAllCreate({ phone: authUser.phone, ...data })
        .then(result => {
          if (isId(result)) {
            handleResponseSuccess('Профиль пользователя создан')
            onSuccess()
            onClose()
            return
          }

          throw new Error()
        })
        .catch(() => {
          setSystemError('При создании профиля произошла ошибка. Попробуйте позже')
        })
    }
  }

  return (<>
    <Modal
      maxWidth='lg'
      title={editProfile ? 'Редактировать профиль' : 'Создать пользователя'}
      open={!!open}
      onClose={handleClose}
      content={<Stack direction='column' minWidth='20em' gap={0.5}>
        {systemError
          ? <Alert color='error'>{systemError}</Alert>
          : <>
          {!editProfile && <>
            <Select
              name='role'
              label='Роль'
              placeholder='Выберите роль'
              options={[...UserRoleMap].filter(([role]) => role && ![UserRole.root, UserRole.carrier].includes(role)).map(([value, name]) => ({ value, name }))}
              value={authUser.role}
              onChange={(role) => setAuthUser((prev) => ({ ...prev, role }))}
              width='100%'
              errors={errorsAuth}
            />
            {authUser.role && <><TextField
              name='phone'
              label='Телефон'
              value={authUser.phone}
              placeholder='+7 (999) 999-99-99'
              maskParams={{
                mask: PHONE_MASK
              }}
              width='100%'
              onChange={({ target: { value } }) => setAuthUser(prev => ({ ...prev, phone: phoneClean(value) }))}
              errors={errorsAuth}
            />
            <PasswordField
              label='Пароль'
              name='password'
              value={authUser.password}
              onChange={({ target: { value: password } }) => setAuthUser(prev => ({ ...prev, password }))}
              showPassword={showPassword}
              onShowPassword={() => setShowPassword(prev => !prev)}
              placeholder='Введите пароль'
              errors={errorsAuth}
            /></>}
          </>}
          {editProfile && <>
            {profileData?.id === undefined && <Alert color='success'>Новый пользователь: {authUser.phone}</Alert>}
            <TextField
              name='email'
              width='100%'
              label='Email'
              placeholder='Email'
              value={profileData.email}
              onChange={updateProfileString('email')}
              errors={errorsProfile}
              maskParams={{
                mask: EmailMask,
                guide: false
              }}
            />
            <TextField
              name='familyName'
              width='100%'
              label='Фамилия'
              placeholder='Фамилия'
              value={profileData.familyName}
              onChange={updateProfileString('familyName')}
              errors={errorsProfile}
            />
            <TextField
              name='firstName'
              width='100%'
              label='Имя'
              placeholder='Имя'
              value={profileData.firstName}
              onChange={updateProfileString('firstName')}
              errors={errorsProfile}
            />
            <TextField
              name='secondName'
              width='100%'
              label='Отчество'
              placeholder='Отчество'
              value={profileData.secondName}
              onChange={updateProfileString('secondName')}
              errors={errorsProfile}
            />
          </>}
        </>}
      </Stack>}
      actions={<Stack direction='row' justifyContent='space-between' width='100%'>
        <Button variant="outlined" color='secondary' onClick={handleClose}>Отмена</Button>
        {!systemError && <>
          {editProfile
            ? <Button variant='contained' onClick={saveProfile}>Сохранить профиль</Button>
            : <Button variant='contained' onClick={createAuthUser}>Создать</Button>}
        </>}
      </Stack>}
    />
  </>)
}
