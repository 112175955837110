import { Information } from 'api/information'
import { phoneClean } from 'common/Mask/PhoneMask'
import { makeJsonSchema, SimpleSchema } from 'validation/validate'

const simpleSchema: SimpleSchema<Information> = {
  type: 'object',
  properties: {
    dispatcherPhone: { type: 'string', pattern: '^\\+[1-9]{2}\\d{9}$', errorMessage: 'Номер телефона введен неверно', optional: true },
    employeePhone: { type: 'string', errorMessage: 'Номер телефона введен неверно', optional: true },
    sampleStaffing: { type: 'string', optional: true },
    sampleInformationLetter: { type: 'string', optional: true },
    sampleInformationLetterOrg: { type: 'string', optional: true },
    sampleQuestionnaire: { type: 'string', optional: true }
  }
}

const schema = makeJsonSchema<Information>(simpleSchema)

export default schema

export function clear ({
  dispatcherPhone, ...data
}: Information): Information {
  const result = {
    ...data,
    dispatcherPhone: dispatcherPhone ? phoneClean(dispatcherPhone) : undefined
  }

  return result
}
