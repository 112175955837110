import { Stack, Typography } from '@mui/material'
import TsToFormatDate from 'util/TsToFormatDate'

export interface KisRequestHistory <T, S> {
  type: T
  status: S
  requestTs: number
}

export interface Params <T extends string, S extends string> {
  data: KisRequestHistory<T, S>
  typeMap: Record<T, string>
  statusMap: Record<S, string>
}

export default function KisRequestHistoryWizard <T extends string, S extends string> ({ data: { requestTs, type, status }, typeMap, statusMap }: Params<T, S>) {
  return (<Stack direction='row' alignItems='center' spacing={1}>
    <Typography fontSize='13px' fontWeight={600} >{TsToFormatDate(requestTs, 'dd.MM.yyyy HH:mm')}</Typography>
    <Typography fontSize='13px' fontWeight={500}>{typeMap[type]}. {statusMap[status]}</Typography>
  </Stack>)
}
