import { Add, Archive, Edit, ReportProblem, Visibility } from '@mui/icons-material'
import { Alert, Box, Button, Stack, Typography } from '@mui/material'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import NavigatePanel from 'ui/NavigatePanel'
import './styles.sass'
import DataTable from 'ui/DataTable'
import ActionsMenu from 'ui/ActionsMenu'
import { Id } from 'api/Id'
import Modal from 'ui/Modal'
import DatePicker from 'ui/DatePicker'
import TextField from 'ui/TextField'
import ChipActivStatus from 'common/ChipActivStatus'
import shortAddress from 'util/shortAddress'
import {
  ConsignorConsigneeStatus,
  ConsignorConsignee,
  ConsignorConsigneeArchiveParams,
  ConsignorConsigneeIncomplete as Incomplete,
  consignorConsigneeArchive,
  consignorConsigneeList
} from 'api/consignorConsignee'
import { Address } from 'api/address'
import Tooltip from 'ui/Tooltip'
import IncompleteWizard from 'ui/ConsignorConsigneeIncomplete'

const LIMIT = 20

export default function AdminConsignorsConsigness () {
  const [rows, setRows] = useState<ConsignorConsignee[]>([])
  const [listCount, setListCount] = useState<number>(0)
  const [listOffset, setListOffset] = useState<number>(0)
  const [searched, setSearched] = useState<(number|string)[]>()
  const [archiveData, setArchiveData] = useState<ConsignorConsigneeArchiveParams | null>()
  const [archiveError, setArchiveError] = useState<Awaited<ReturnType<typeof consignorConsigneeArchive>>['conflicts']>()
  const navigate = useNavigate()

  const loadList = useCallback((offset?: number) => {
    consignorConsigneeList({ filters: {}, limit: LIMIT, offset })
      .then(result => {
        setRows(result.list)
        setListCount(result.count)
      })
  }, [])

  useEffect(() => { loadList(listOffset) }, [listOffset, loadList])

  const tablePagination = (start: number) => {
    setListOffset(start)
    return rows.filter(row => searched === undefined ? true : searched.includes(row.id))
  }

  const title = 'Организации'

  const openArchiveModal = (id?: Id) => {
    if (id === undefined) {
      return
    }

    setArchiveData({ id, date: Math.floor(Date.now() / 1000) })
    setArchiveError(undefined)
  }

  const updateArchiveString = (key: keyof Pick<ConsignorConsigneeArchiveParams, 'comment'>) => {
    return (e: ChangeEvent<HTMLElement & {value: string}>) => {
      if (!archiveData) {
        return
      }

      setArchiveData({ ...archiveData, [key]: e.target.value })
    }
  }

  const updateArchiveDate = (key: keyof ConsignorConsigneeArchiveParams) => {
    return (value: number) => {
      if (!archiveData) {
        return
      }

      setArchiveData({ ...archiveData, [key]: value })
    }
  }

  const archive = useCallback(async () => {
    if (!archiveData) {
      return
    }

    const { success, conflicts } = await consignorConsigneeArchive(archiveData)

    if (!success) {
      setArchiveError(conflicts)
      return
    }

    setArchiveData(undefined)
    loadList(listOffset)
  }, [archiveData, listOffset, loadList])

  return (<>
    <div className='adminParties'>
      <NavigatePanel
        title={title}
        breadcrumbs={{
          items: [{ title }]
        }}
        actions={
          <Button variant='contained' size='small' endIcon={<Add />} onClick={() => navigate('add')}>
            Добавить организацию
          </Button>
        }
        search={{
          rows: rows === undefined ? [] : rows,
          fields: [
            'name',
            'inn',
            'kpp'
          ],
          onChange: setSearched
        }}
      />
      <div className='adminParties__body'>
        <div className='adminParties__body_customer'>
          <DataTable
            columns = { [
              {
                id: 'shortName',
                label: 'Название'
              },
              {
                id: 'inn',
                label: 'ИНН'
              },
              {
                id: 'kpp',
                label: 'КПП'
              },
              {
                id: 'addresses',
                label: 'Элеватор',
                format: value => {
                  const addresses = value as Address[] | undefined
                  return <Typography>{(addresses && addresses.length > 0) ? addresses.map(item => item.city).join(', ') : '-'}</Typography>
                }
              },
              {
                id: 'status',
                label: 'Статус',
                format: value => <ChipActivStatus status={value as ConsignorConsigneeStatus} />
              },
              {
                id: ['id', 'status', 'incomplete'],
                label: '',
                align: 'right',
                minWidth: 10,
                format: (id, status, incomplete) => <Stack direction='row' gap={1} justifyContent='flex-end' alignItems='center'>
                  { incomplete !== undefined && <Tooltip title={<IncompleteWizard data={incomplete as Incomplete} />} arrow placement='left' view>
                    <ReportProblem sx={{ color: '#EE6A5F', fontSize: '32px' }}/>
                  </Tooltip> }
                  <ActionsMenu key={`${id}`} actions = {
                    status !== ConsignorConsigneeStatus.active
                      ? [
                          {
                            caption: 'Посмотреть',
                            onClick: () => navigate(`${id}`),
                            icon: <Visibility />
                          }
                        ]
                      : [
                          {
                            caption: 'Редактировать',
                            onClick: () => navigate(`${id}`),
                            icon: <Edit />
                          },
                          {
                            caption: 'Архивировать',
                            onClick: () => openArchiveModal(id as Id),
                            icon: <Archive />
                          }
                        ]
                  } />
                </Stack>
              }
            ] }
            rowsPage = {LIMIT}
            rows = {tablePagination}
            rowsCount = { listCount }
          />
        </div>
      </div>
    </div>
    <Modal
      title={'Архивировать'}
      open={archiveData !== undefined}
      onClose={() => setArchiveData(undefined)}
      content={ archiveData
        ? archiveError === undefined
          ? <Stack sx={{ minWidth: '500px' }} >
            <Box sx={{ mb: 2 }}>
              <DatePicker
                label='Дата архивации'
                value={archiveData.date}
                onChange={updateArchiveDate('date')}
                disabled={true}
              />
            </Box>
            <Box>
              <TextField
                rows={6}
                label='Комментарий'
                width='100%'
                value={archiveData.comment}
                onChange={updateArchiveString('comment')}
                placeholder='Добавьте комментарий'
              />
            </Box>
          </Stack>
          : <Stack sx={{ minWidth: '500px' }} >
            <Alert color='error'>
              <Typography>Нельзя заархивировать организацию!</Typography>
              <Typography>Используется в пунктах погрузки:</Typography>
              { archiveError.addresses.map(item => shortAddress(item)).join('; ') }
              <Typography>Используется в заявках:</Typography>
              { archiveError.bids.map(item => item.num).join('; ') }
            </Alert>
          </Stack>
        : <></>
      }
      actions={ archiveData
        ? <>
          <Button color='secondary' variant='outlined' onClick={() => setArchiveData(undefined)}>Отменить</Button>
          { archiveError === undefined && <Button variant='contained' onClick={ () => { archive() } }>Архивировать</Button> }
        </>
        : <></>
      }
    />
  </>)
}
