import './styles.sass'
import { memo, useCallback, useEffect, useState } from 'react'
import InfoCard from 'ui/InfoCard'
import { Box, Button, ButtonGroup } from '@mui/material'
import clsx from 'clsx'
import { ForwarderAttachStatus, forwarderAttachMyList } from 'api/forwarder'
import TsToFormatDate from 'util/TsToFormatDate'
import { DEFAULT_COORDINATES } from 'constants/map'
import NavigatePanel from 'ui/NavigatePanel'
import { useMainRoutes } from 'routes'
import { Address } from 'api/address'
import OsmBid from 'ui/Osm'
import shortAddress from 'util/shortAddress'

interface AttachItemProps {
  status: ForwarderAttachStatus
  period: string
  address: string
  coordinates: Address['coordinates']
}

const AttachItem = memo(({ status, period, address, coordinates }: AttachItemProps) => {
  return (
    <div className={clsx('forwarderAttaches__Item', {
      forwarderAttaches__Item__status_active: status === ForwarderAttachStatus.active,
      forwarderAttaches__Item__status_archival: status === ForwarderAttachStatus.archival,
      forwarderAttaches__Item__status_planned: status === ForwarderAttachStatus.planned
    })}>
      <div className='forwarderAttaches__Item__info'>
        <span className='forwarderAttaches__Item__info_period'>{period}</span>
        <span className='forwarderAttaches__Item__info_dot'>•</span>
        <span className='forwarderAttaches__Item__info_address'>{address}</span>
      </div>
      <div className='forwarderAttaches__Item__map'>
        <OsmBid markers={[{ point: coordinates }]} staticMap />
      </div>
    </div>
  )
})

export default function ForwarderAttaches () {
  const [viewArchive, setViewArchive] = useState(false)
  const [data, setData] = useState<{
    active: AttachItemProps[]
    planned: AttachItemProps[]
    archival: AttachItemProps[]
  }>()

  const { links, routesMap } = useMainRoutes()

  const init = useCallback(async () => {
    const result = await forwarderAttachMyList()
    const list = result.map(item => {
      return {
        status: item.status,
        period: `${TsToFormatDate(item.beginPeriodTs, 'dd.MM.yyyy')} - ${TsToFormatDate(item.endPeriodTs, 'dd.MM.yyyy')}`,
        address: shortAddress(item.loadingAddress),
        coordinates: item.loadingAddress.coordinates ?? DEFAULT_COORDINATES
      }
    })

    setData({
      active: list.filter(({ status }) => status === ForwarderAttachStatus.active),
      planned: list.filter(({ status }) => status === ForwarderAttachStatus.planned),
      archival: list.filter(({ status }) => status === ForwarderAttachStatus.archival)
    })
  }, [])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { init() }, [])

  if (data === undefined) {
    return <></>
  }

  return (
    <>
      <div className='forwarderAttaches'>
        <NavigatePanel
          title='Закрепления'
          breadcrumbs={{
            items: [],
            defaultItems: routesMap.getBreadcrumbs(links.FORWARDER_ATTACHES_PAGE)
          }}
        />
        <div className='forwarderAttaches__body'>
          <div className='forwarderAttaches__content'>
            <InfoCard>
              <>
                <ButtonGroup size='small' sx={{ mb: 3 }}>
                  <Button variant={!viewArchive ? 'contained' : undefined} sx={{ px: 5 }} onClick={() => setViewArchive(false)}>Все</Button>
                  <Button variant={viewArchive ? 'contained' : undefined} sx={{ px: 5 }} onClick={() => setViewArchive(true)} disabled={data.archival.length === 0}>Архив</Button>
                </ButtonGroup>
                { viewArchive
                  ? <>
                    { data.archival.map((props, idx) => <AttachItem {...props} key={idx}/>) }
                  </>
                  : data.active.length > 0
                    ? <>
                      { data.active.length > 0 && <Box className='forwarderAttaches__attachTitle'>Активные</Box> }
                      { data.active.map((props, idx) => <AttachItem {...props} key={idx}/>) }
                      { data.planned.length > 0 && <Box className='forwarderAttaches__attachTitle' sx={{ pt: data.active.length > 0 ? 3 : 0 }}>Запланированные</Box> }
                      { data.planned.map((props, idx) => <AttachItem {...props} key={idx}/>) }
                    </>
                    : <Box sx={{
                      height: '90px',
                      background: '#FAFAFA',
                      border: '1px solid #EBEBEB',
                      borderRadius: '4px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      padding: '20px',
                      fontWeight: '500',
                      fontSize: '14px',
                      lineHeight: '17px',
                      color: '#B2B2B2'
                    }}>
                      Закрепления отсутствуют
                    </Box>
                  }
                </>
            </InfoCard>
          </div>
        </div>
      </div>
    </>
  )
}
