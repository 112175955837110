import { MoreHoriz, Task } from '@mui/icons-material'
import { Button, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMainRoutes } from 'routes'
import InfoCard from 'ui/InfoCard'
import './styles.sass'
import { ActStatus, ActAll, actAllList } from 'api/act'
import Row from '../Row'
import TsToFormatDate from 'util/TsToFormatDate'
import rub from 'util/formatRub'
import { Stack } from '@mui/system'
import None from 'ui/None'
import { Interface } from 'api/Interface'
import { useAuthContext } from 'AuthContext'

export default function NewActs () {
  const { currentInterface } = useAuthContext()
  const { links } = useMainRoutes()
  const navigate = useNavigate()
  const [list, setList] = useState<ActAll[]>([])

  const isChief = currentInterface === Interface.chief_dispatcher

  useEffect(() => {
    actAllList({
      filters: {
        status: [ActStatus.waitingSigning]
      },
      limit: 6
    })
      .then(result => result.list)
      .then(setList)
  }, [])

  return (
    <InfoCard
      title='Новые УПД'
      actions={ <Button
        size='small'
        variant='text'
        sx={{ fontSize: '14px', textDecoration: 'underline' }}
        endIcon={<MoreHoriz />}
        onClick={() => navigate(links.DISPATCHER_DOCUMENTS_ACTS_PAGE)}
      >Все УПД</Button>}
      className='dispatcherHome__infoCard'
    >
      <>
      { list.length === 0
        ? <None desc='Нет новых УПД' />
        : list.map(item => <Row
          icon={<Task sx={{ color: '#B2B2B2' }} />}
          info={<Stack direction='row' justifyContent='space-between' spacing={1}>
            <Typography fontWeight={400} fontSize='14px'>
                УПД № {item.num} от {TsToFormatDate(item.createTs, 'dd.MM.yyyy')}
            </Typography>
            <Typography fontWeight={500} fontSize='14px'>{item.organization.fullName}</Typography>
            <Typography fontWeight={400} fontSize='14px'>{rub(item.totalSum)}</Typography>
          </Stack>}
          key={item.id}
          onClick={() => navigate(isChief ? `${links.DISPATCHER_DOCUMENTS_ACT_PAGE}/${item.id}` : links.DISPATCHER_DOCUMENTS_ACTS_PAGE)}
        />) }
      </>
    </InfoCard>
  )
}
