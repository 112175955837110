import { Interface } from 'api/Interface'

const UserInterfacesMap = new Map<Interface | undefined, string>()
  .set(Interface.administrator, 'Администратор')
  .set(Interface.carrier, 'Перевозчик')
  .set(Interface.chief_dispatcher, 'Старший диспетчер')
  .set(Interface.dispatcher, 'Диспетчер')
  .set(Interface.driver, 'Водитель')
  .set(Interface.forwarder, 'Экспедитор')
  .set(Interface.chief_forwarder, 'Старший экспедитор')

export function getNameByUserInterfaces (UserInterface: Interface | undefined): string {
  return UserInterfacesMap.get(UserInterface) ?? ''
}
