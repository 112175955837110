import { Add, DeleteForever } from '@mui/icons-material'
import { Button, Stack, Typography } from '@mui/material'
import { OrganizationParticipant } from 'api/organization'
import { ChangeEvent } from 'react'
import SquareButton from 'ui/SquareButton'
import TextField from 'ui/TextField'
import Upload from 'ui/Upload'
import { Optional } from 'util/type/Optional'

const EMPTY_ERROR_MESSAGE = 'Поле обязательно к заполнению'

export type ValidationsError = Map<keyof OrganizationParticipant, string>

export type ParticipantsUpdate = Partial<OrganizationParticipant> & {
  id: string
  validationsError?: ValidationsError
}

export type ParticipantsCreate = Optional<Required<ParticipantsUpdate>, 'validationsError'>
export interface Params {
  rows?: ParticipantsUpdate[]
  setRows: React.Dispatch<React.SetStateAction<ParticipantsUpdate[] | undefined>>
  disabled?: boolean
}

export const participantsMap = (origin: OrganizationParticipant[]): ParticipantsCreate[] => {
  return origin.map((item, idx) => ({ id: `participants-${idx}`, ...item }))
}

export const cleanParticipants = (origin: ParticipantsCreate[]): OrganizationParticipant[] => {
  return origin.map(({ id, validationsError, ...item }) => item)
}

export const validateParticipants = (rows: ParticipantsUpdate[] = [], setRows: React.Dispatch<React.SetStateAction<ParticipantsUpdate[] | undefined>>): rows is Optional<Required<ParticipantsUpdate>, 'validationsError'>[] => {
  setRows(rows.reduce((acc, item) => {
    const validationsError: ValidationsError = new Map()

    if (!item.position?.length) {
      validationsError.set('position', EMPTY_ERROR_MESSAGE)
    }

    if (!item.employeeName?.length) {
      validationsError.set('employeeName', EMPTY_ERROR_MESSAGE)
    }

    if (!item.appointmentDocument?.length) {
      validationsError.set('appointmentDocument', EMPTY_ERROR_MESSAGE)
    }

    if (!item.scanAppointmentDocument?.length) {
      validationsError.set('scanAppointmentDocument', EMPTY_ERROR_MESSAGE)
    }

    item.validationsError = validationsError.size ? validationsError : undefined

    return [...acc, item]
  }, [] as ParticipantsUpdate[]))

  return rows.filter(item => item?.validationsError?.size).length === 0
}

export default function OrganisationParticipants ({ rows = [], setRows, disabled }: Params) {
  const handleAdd = () => {
    setRows(([...rows, { id: `participants-${Date.now()}` }]))
  }

  const handleDelete = ({ id }: ParticipantsUpdate) => {
    return () => {
      setRows(rows.filter(item => item.id !== id))
    }
  }

  const updateField = ({ id }: ParticipantsUpdate, key: keyof OrganizationParticipant) => {
    return (value: string) => {
      setRows(rows.reduce((acc, item) => {
        if (item.id !== id) {
          return [...acc, item]
        }

        return [...acc, { ...item, [key]: value }]
      }, [] as ParticipantsUpdate[]))
    }
  }

  const updateString = (m: (value: string) => void) => {
    return ({ target: { value } }: ChangeEvent<HTMLInputElement>) => m(value)
  }

  const validationsError = ({ validationsError }: ParticipantsUpdate, key: keyof OrganizationParticipant) => {
    return validationsError?.get(key)
  }

  return (<>
    <Stack direction='column' spacing={2} mb='0.5em'>
      <Stack direction='row' spacing={2} alignItems='center' justifyContent='space-between'>
        <Typography fontWeight={600} fontSize='18px'>Информация об уполномоченных лицах участника системы</Typography>
        { !disabled && <Button variant='outlined' startIcon={<Add />} onClick={handleAdd}>Добавить</Button> }
      </Stack>
      <Stack direction='column'>
        { rows.map((row) => (<Stack direction='row' gap={2} key={row.id}>
          <TextField
            width='50%'
            name='position'
            value={row.position}
            onChange={updateString(updateField(row, 'position'))}
            label='Должность'
            placeholder='Должность'
            disabled={disabled}
            errorMessage={validationsError(row, 'position')}
          />
          <TextField
            width='50%'
            name='employeeName'
            label='ФИО'
            placeholder='ФИО'
            value={row.employeeName}
            onChange={updateString(updateField(row, 'employeeName'))}
            disabled={disabled}
            errorMessage={validationsError(row, 'employeeName')}
          />
          <TextField
            width='50%'
            name='appointmentDocument'
            label='Документ-основание'
            value={row.appointmentDocument}
            onChange={updateString(updateField(row, 'appointmentDocument'))}
            placeholder='Документ-основание'
            disabled={disabled}
            errorMessage={validationsError(row, 'appointmentDocument')}
          />
          <Upload
            label='Копия документа'
            name='scanAppointmentDocument'
            value={row.scanAppointmentDocument}
            onChange={updateField(row, 'scanAppointmentDocument')}
            disabled={disabled}
            sx={{ width: '50%' }}
            errorMessage={validationsError(row, 'scanAppointmentDocument')}
          />
          { !disabled && <SquareButton widthHeight='40px' color='error' variant='contained' sx={{ mt: '23px' }} onClick={handleDelete(row)}>
              <DeleteForever />
            </SquareButton> }
        </Stack>)) }
      </Stack>
    </Stack>
  </>)
}
