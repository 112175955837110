import { Outlet } from 'react-router-dom'
import './styles.sass'
import Sidebar from './Sidebar'

export function MainLayout () {
  return (
    <div className='mainLayout'>
      <div className='mainLayout__sidebar'>
        <Sidebar />
      </div>
      <div className='mainLayout__content'>
        <Outlet />
      </div>
    </div>
  )
}
