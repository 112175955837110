import { Box, Button, Stack, Typography } from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility'
import SendIcon from '@mui/icons-material/Send'
import Modal from 'ui/Modal'
import CertificateSelect from 'ui/Diadoc/CertificateSelect'
import { ActAll as Act } from 'api/act'
import { useEffect, useMemo, useState } from 'react'
import TextField from 'ui/TextField'
import { Id } from 'api/Id'
import formatDate from 'common/CarrierBids/TsToFormatDate'
import download from 'util/download'

interface Params {
  acts?: Act[]
  onCancel: () => void
  onSign: (ids: Id[]) => Promise<void>
  onFinish: () => void
}

interface Step {
  customer: string
  acts: Act[]
}

export default function SignModal ({ acts, onCancel, onSign, onFinish }: Params) {
  const [step, setStep] = useState<number>(0)
  const [signing, setSigning] = useState<boolean>(false)
  const open = useMemo(() => acts !== undefined, [acts])
  const steps = useMemo(() => {
    return acts === undefined
      ? []
      : acts.reduce((result, act) => {
        const customer = act.bid.customer.fullName
        const step = result.find((step) => step.customer === customer)

        if (step === undefined) {
          return [...result, { customer, acts: [act] }]
        }

        step.acts.push(act)
        return result
      }, [] as Step[])
  }, [acts])

  useEffect(() => {
    if (open) {
      setStep(0)
      setSigning(false)
    }
  }, [open])

  const sign = () => {
    // const onSign = async (ids: Id[]) => {
    //   console.info('sign', ids)
    //   return new Promise((resolve) => setTimeout(resolve, 1000))
    // }

    setSigning(true)
    onSign(steps[step].acts.map(({ id }) => id)).then(() => setStep((step) => {
      setSigning(false)
      if (steps.length > step + 1) {
        return step + 1
      } else {
        onFinish()
        return step
      }
    }))
  }

  return <Modal
    title={signing ? 'Подписание и отправка' : 'Проверка документов перед подписанием'}
    open={open}
    onClose = {onCancel}
    content= {<Stack sx={{ minWidth: '500px' }}>
      { !signing && steps.length > step && <>
        <Typography sx={{
          fontWeight: '400',
          fontSize: '14px',
          lineHeight: '21px',
          letterSpacing: '-0.02em',
          color: '#B2B2B2',
          pb: 4
        }}>
          Проверьте УПД Перевозчиков, а затем подпишите их своей ЭЦП. Чтобы продолжить, нажмите "Подписать".
        </Typography>
        <TextField disabled={true} label="Заказчик перевозки" width='100%' value={steps[step].customer} />
        <CertificateSelect onSelect={() => {}} onError={() => {}}/>
        <Box sx={{ display: 'flex', flexDirection: 'column', borderTop: '#EBEBEB solid 1px', my: 2 }}>
          { steps[step].acts.map(({ id, num, organization, filename, registryFilename, shortageFilename, createTs }) =>
            <Box key={id} sx={{ borderBottom: '#EBEBEB solid 1px', display: 'flex', justifyContent: 'space-between', py: 2 }}>
              <Stack gap={1}>
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <VisibilityIcon sx={{ fontSize: '16px', color: '#B2B2B2', cursor: 'pointer' }} onClick={ () => download(filename) } />
                  <Box>УПД № {num} от {formatDate(createTs, 'dd.MM.yyyy')}</Box>
                </Box>
                {registryFilename && <Box sx={{ display: 'flex', gap: 2 }}>
                  <VisibilityIcon sx={{ fontSize: '16px', color: '#B2B2B2', cursor: 'pointer' }} onClick={ () => download(registryFilename) } />
                  <Box>Реестр оказанных услуг</Box>
                </Box>}
                {shortageFilename && <Box sx={{ display: 'flex', gap: 2 }}>
                  <VisibilityIcon sx={{ fontSize: '16px', color: '#B2B2B2', cursor: 'pointer' }} onClick={ () => download(shortageFilename) } />
                  <Box>Отчёт по недостачам</Box>
                </Box>}
              </Stack>
              <Box>
                { organization.fullName}
              </Box>
            </Box>)
          }
        </Box>
      </>}
      { signing && <>
        <Typography sx={{
          fontWeight: '400',
          fontSize: '14px',
          lineHeight: '21px',
          letterSpacing: '-0.02em',
          color: '#B2B2B2',
          pb: 4
        }}>
          Пожалуйста, не закрывайте это окно и дождитесь окончания отправки документов.
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', my: 2, height: '10em' }}>
          Отправляем документы
        </Box>
      </>}
    </Stack>}
    actions={signing
      ? <></>
      : <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Button color="secondary" variant="outlined" onClick={onCancel}>Отменить</Button>
          <Button variant="contained" onClick={sign} endIcon={<SendIcon/>}>Подписать ({step + 1}/{steps.length})</Button>
        </Box>
    }
  />
}
