import { DiadocSettings } from 'api/diadoc'
import { makeJsonSchema, SimpleSchema } from 'validation/validate'

const simpleSchema: SimpleSchema<DiadocSettings> = {
  type: 'object',
  properties: {
    boxId: { type: 'string', optional: true },
    routeId: { type: 'string', optional: true },
    watermarkText: { type: 'string', optional: true }
  }
}

const schema = makeJsonSchema<DiadocSettings>(simpleSchema)

export default schema
