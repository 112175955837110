import { Box, Typography } from '@mui/material'
import { ProfileAll } from 'api/profile'
import { NavigateFunction } from 'react-router-dom'
import { useMainRoutes } from 'routes'

export interface Params {
  profile: ProfileAll
  navigate: NavigateFunction
}

export default function RegistryCarrierLink ({ profile, navigate }: Params) {
  const { links } = useMainRoutes()

  return (<Box>
    <Typography sx={{
      '& span': { textDecoration: 'underline', cursor: 'pointer' },
      color: '#111111',
      fontSize: '15px',
      fontWeight: 500,
      letterSpacing: '-0.01em'
    }}>
      Закреплен за перевозчиком: <span onClick={() => navigate(`${links.REGISTRY_CARRIER_PAGE}/${profile.id}`)}> {profile.organization?.shortName || ''} </span>
    </Typography>
  </Box>)
}
