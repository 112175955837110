import './styles.sass'
import { Typography, Button, Box, Grid } from '@mui/material'
import { shippingTruckRunLoads, ShippingTruckRunLoad, ShippingTruckRunStatus } from 'api/shipping'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMainRoutes } from 'routes'
import InfoCard from 'ui/InfoCard'
import InfoCell, { InfoCellProps } from 'ui/InfoCell'
import vehicleName from 'util/vehicleName'
import trailerName from 'util/trailerName'
import formatDate from 'util/FormatDate'
import { personName } from 'util/personName'
import { DAY, TS_IN_SECONDS } from 'constants/Time'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { forwarderAttachMyList, ForwarderAttachStatus } from 'api/forwarder'
import TsToFormatDate from 'util/TsToFormatDate'
import shortAddress from 'util/shortAddress'
import { MoreHoriz } from '@mui/icons-material'
import None from 'ui/None'
import addressName from 'util/addressName'
import DashBoardNotifications from 'ui/DashBoardNotifications'

const tzOffset = new Date().getTimezoneOffset() * 60
const now = Math.floor(Date.now() / TS_IN_SECONDS) - tzOffset
const today = now - (now % DAY)

interface AttachItemProps {
  period: string
  address: string
}

const TruckRunItem = ({ data }: { data: ShippingTruckRunLoad }) => {
  const navigate = useNavigate()
  const { links } = useMainRoutes()

  const left: InfoCellProps[] = [
    { label: 'Дата погрузки', value: formatDate(new Date((data.loadingTs + tzOffset) * TS_IN_SECONDS), 'dd.MM') },
    { label: 'Перевозчик', value: data.carrierName },
    { label: 'Водитель', value: `${personName(data.driver)}` },
    { label: 'Транспортное средство', value: vehicleName(data.vehicle) },
    { label: 'Прицеп', value: trailerName(data.trailer), separator: false }
  ]

  const right: InfoCellProps[] = []

  right.push({
    value: <Button
      size='small'
      variant="contained"
      onClick={() => navigate(`${links.FORWARDER_LOADS_PAGE}/${data.id}`)}
      sx={{
        minWidth: '32px',
        height: '32px',
        background: '#B2B2B2',
        borderRadius: '4px',
        color: '#fff',
        border: 'none',
        padding: 0,
        '&:hover': {
          background: '#B2B2B2',
          border: 'none',
          filter: 'brightness(96%)'
        }
      }}
    >
      <MoreHorizIcon sx={{ fontSize: '16px' }}/>
    </Button>,
    separator: false
  })

  return <Box sx={{
    '& .InfoCard': {
      background: '#F7F7F7'
    }
  }}>
    <InfoCard>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        margin: '4px 0'
      }}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row'
        }}>
        { left.map((item, idx) => <InfoCell {...item} key={idx} />) }
        </Box>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row'
        }}>
        { right.map((item, idx) => <InfoCell {...item} key={idx} />) }
        </Box>
      </Box>
    </InfoCard>
  </Box>
}

export default function ForwarderHome () {
  const [truckRuns, setTruckRuns] = useState<Map<string, ShippingTruckRunLoad[]>>()
  const [attaches, setAttaches] = useState<AttachItemProps[]>()
  const navigate = useNavigate()
  const { links } = useMainRoutes()

  const init = useCallback(async () => {
    const truckRuns = await shippingTruckRunLoads({
      filters: {
        loadingTsFrom: today,
        loadingTsTo: today,
        status: [ShippingTruckRunStatus.confirmed]
      }
    })

    const byAddress = new Map<string, ShippingTruckRunLoad[]>()

    for (const item of truckRuns.list) {
      const address = addressName(item.loadingAddress)
      const addressItems = byAddress.get(address) ?? []
      addressItems.push(item)
      byAddress.set(address, addressItems)
    }

    setTruckRuns(byAddress)

    const attaches = await forwarderAttachMyList()

    setAttaches(attaches
      .filter(({ status }) => status === ForwarderAttachStatus.active)
      .map(item => {
        return {
          period: `${TsToFormatDate(item.beginPeriodTs, 'dd.MM.yyyy')} - ${TsToFormatDate(item.endPeriodTs, 'dd.MM.yyyy')}`,
          address: shortAddress(item.loadingAddress)
        }
      })
    )
  }, [])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { init() }, [])

  if (truckRuns === undefined || attaches === undefined) {
    return <></>
  }

  return (
    <div className='forwarderHome'>
      <div className='forwarderHome__content'>
      <Grid container spacing='1em' rowSpacing='0.5em' p='1.5em' minHeight='100%'>
        <Grid item lg={12} md={12} sm={12} display='flex'>
          <InfoCard
              title='Ожидаемые погрузки на сегодня'
              actions={
                <Button
                  size='small'
                  variant='text'
                  sx={{ textTransform: 'none', color: '#0A5DFF', fontSize: '14px', textDecoration: 'underline' }}
                  endIcon={<MoreHoriz />}
                  onClick={() => navigate(links.FORWARDER_LOADS_PAGE)}
                >Все погрузки</Button>
              }
            >
            {<>
              {[...truckRuns].length > 0
                ? [...truckRuns].map(([address, list]) => <Box key={address} sx={{ marginBottom: '1em' }}>
                    <Typography sx={{
                      fontWeight: '500',
                      fontSize: '13px',
                      lineHeight: '16px',
                      letterSpacing: '-0.01em',
                      color: '#B2B2B2',
                      pt: 1
                    }}>
                      {address}
                    </Typography>
                    {list.map((item, idx) => <TruckRunItem key={idx} data={item} />)}
                  </Box>)
                : <None desc='В настоящий момент сегодняшние погрузки отсутствуют' />
              }
            </>}
            </InfoCard>
        </Grid>
        <Grid item lg={6} md={12} sm={12} display='flex'>
          <InfoCard
            title='Активные закрепления'
            actions={
              <Button
                size='small'
                variant='text'
                sx={{ textTransform: 'none', color: '#0A5DFF', fontSize: '14px', textDecoration: 'underline' }}
                endIcon={<MoreHoriz />}
                onClick={() => navigate(links.FORWARDER_ATTACHES_PAGE)}
              >Все закрепления</Button>
            }
          >
            <>
              { attaches.length > 0
                ? <div>
                  { attaches.map(({ period, address }, key) => <div key={ key } className='forwarderHome__AttachItem forwarderHome__AttachItem__status_active'>
                      <div className='forwarderHome__AttachItem__info'>
                        <span className='forwarderHome__AttachItem__info_period'>{period}</span>
                        <span className='forwarderHome__AttachItem__info_dot'>•</span>
                        <span className='forwarderHome__AttachItem__info_address'>{address}</span>
                      </div>
                    </div>
                  ) }
                </div>
                : <None desc='Активные закрепления отсутствуют' />
                }
              </>
          </InfoCard>
        </Grid>
        <Grid item xl={6} md={12} sm={12} display='flex'><DashBoardNotifications viewList /></Grid>
      </Grid>
      </div>
    </div>
  )
}
