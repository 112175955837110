import { Button, Typography } from '@mui/material'
import InfoCard from 'ui/InfoCard'
import './styles.sass'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { Stack } from '@mui/system'
import { Save } from '@mui/icons-material'
import TextField from 'ui/TextField'
import { useAuthContext } from 'AuthContext'
import { Information as InformationType, informationGet, informationPut } from 'api/information'
import { PHONE_MASK } from 'common/Mask/PhoneMask'
import schems, { clear } from 'validation/Information'
import useValidate from 'validation/validate'
import Upload from 'ui/Upload'

export default function Information () {
  const [data, setData] = useState<InformationType>({})
  const init = useCallback(() => informationGet().then(setData), [])
  useEffect(() => { init() }, [init])
  const { handleResponseSuccess } = useAuthContext()
  const { check, errors } = useValidate(schems)

  const save = () => {
    const clean = clear(data)

    console.log(errors)
    if (!check(clean)) {
      return
    }

    informationPut(clean)
      .then(r => {
        if (r.upserted || r.modified) {
          handleResponseSuccess('Данные сохранены')
        }
      })
  }

  const updateString = (key: keyof InformationType) =>
    (e: ChangeEvent<HTMLInputElement>) => {
      const { target: { value } } = e

      setData({ ...data, [key]: value })
    }

  const upload = (key: keyof InformationType) =>
    (filename: string) => {
      setData({ ...data, [key]: filename })
    }

  return (
    <InfoCard
      title='Информация о системе'
      actions={<Button
        variant='outlined'
        size='small'
        onClick={save}
      ><Save sx={{ mr: '10px' }}/>Сохранить</Button>}
    ><>
      <Stack direction='row' gap={2}>
        <TextField
          name='dispatcherPhone'
          label='Номер диспетчера'
          placeholder='+7 (999) 999-99-99'
          width='20%'
          value={data.dispatcherPhone}
          maskParams={{
            mask: PHONE_MASK
          }}
          errors={errors}
          onChange={updateString('dispatcherPhone')}
        />
        <TextField
          name='employeePhone'
          label='Номер сотрудника'
          width='20%'
          value={data.employeePhone}
          errors={errors}
          onChange={updateString('employeePhone')}
        />
      </Stack>
      <Stack direction='row' mb='1em'>
        <Typography fontWeight={600} fontSize='18px'>Образцы документов</Typography>
      </Stack>
      <Stack direction='row' spacing={2}>
        <Upload
          sx={{ width: '25%' }}
          label='Форма выписки из штатной расстановки'
          value={data.sampleStaffing}
          onChange={upload('sampleStaffing')}
        />
        <Upload
          sx={{ width: '25%' }}
          label='Информационное письмо о деятельности'
          value={data.sampleInformationLetter}
          onChange={upload('sampleInformationLetter')}
        />
        <Upload
          sx={{ width: '25%' }}
          label='Информационное письмо о деятельности ООО'
          value={data.sampleInformationLetterOrg}
          onChange={upload('sampleInformationLetterOrg')}
        />
        <Upload
          sx={{ width: '25%' }}
          label='Анкета по требованиям Хартии АПК РФ'
          value={data.sampleQuestionnaire}
          onChange={upload('sampleQuestionnaire')}
        />
      </Stack>
    </></InfoCard>
  )
}
