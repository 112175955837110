import TsToFormatDate from 'common/CarrierBids/TsToFormatDate'
import { useMemo } from 'react'
import InfoCell, { InfoCellProps } from 'ui/InfoCell'
import { personName } from 'util/personName'
import './styles.sass'
import { ShippingTruckRun } from 'api/shipping'
import { Bid } from 'api/bid'
import trailerName from 'util/trailerName'
import vehicleName from 'util/vehicleName'
import { useMainRoutes } from 'routes'
import { useNavigate } from 'react-router-dom'
import addressName from 'util/addressName'

export interface TripParams {
  bid: Omit<Bid, 'freeSpace'>
  truckRun: ShippingTruckRun
}

export default function Trip ({ bid, truckRun }: TripParams) {
  const { links } = useMainRoutes()
  const navigate = useNavigate()

  const list = useMemo<InfoCellProps[]>(() => [
    { label: 'Дата погрузки', value: TsToFormatDate(truckRun.loadingTs), style: { maxWidth: '100px' } },
    {
      label: 'Пункт погрузки',
      value: addressName(bid.loadingAddress, true) || bid.loadingAddress.address
    },
    {
      label: 'Водитель',
      value: personName(truckRun.driver)
    },
    { label: 'Транспортное средство', value: vehicleName(truckRun.vehicle) },
    { label: 'Прицеп', value: trailerName(truckRun.trailer) }
  ], [bid, truckRun])

  return (
    <div className='carrierTripRow' onClick={() => navigate(`${links.CARRIER_TRIPS_PAGE}/${truckRun.id}`)}>
      { list.map((item, idx) => <InfoCell {...item} key={idx} className='carrierTripRow__item' />)}
    </div>
  )
}
