import { Button, InputBase } from '@mui/material'
import { mediaService } from 'api/media'
import './styles.sass'
import clsx from 'clsx'

interface Props {
  label?: string | number | null
  value: string | number | undefined | null
  width?: string | number
  color?: 'grey' | 'green' | 'shortage' | 'surplus'
  files?: boolean
  endAdornment?: React.ReactNode
  placeholder?: string
  style?: React.CSSProperties
}

export default function InfoField ({ label, value, width, color, files, endAdornment, placeholder, style }: Props) {
  const onDownload = () => {
    if (!value) {
      return
    }

    mediaService.download(value as string).then(blobby => {
      const objectUrl = window.URL.createObjectURL(blobby)
      window.open(objectUrl, '_blank')
      window.URL.revokeObjectURL(objectUrl)
    })
  }

  return (
    <div className='InfoField' style={{ width, ...style }}>
      <label>{label}</label>
      { !files
        ? <InputBase
          readOnly
          className={clsx('InfoField_field', {
            InfoField_field_green: color === 'green',
            InfoField_field_shortage: color === 'shortage',
            InfoField_field_surplus: color === 'surplus'
          })}
          fullWidth
          value={value ?? ''}
          endAdornment={endAdornment}
          placeholder={placeholder}
        />
        : <Button
            disabled={!value}
            className='InfoField_files'
            fullWidth
            variant='outlined'
            onClick={onDownload}
          >Открыть файл</Button>
      }
      <div style={{ marginTop: '0.5em', height: '10px' }} />
    </div>
  )
}
