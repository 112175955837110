import { NotifyEvent } from '.'
import { useEffect } from 'react'

export interface Data {
  driverName?: string
  carrierName?: string
  message: string
}

const SLUGS = [
  'passports_driver_passport_expires_date_for_carrier_event',
  'passports_driver_passport_expires_date_for_dispatcher_event',
  'passports_carrier_passport_expires_date_for_carrier_event',
  'passports_carrier_passport_expires_date_for_dispatcher_event',
  'passports_driver_passport_has_expired_for_carrier_event',
  'passports_driver_passport_has_expired_for_dispatcher_event',
  'passports_carrier_passport_has_expired_for_carrier_event',
  'passports_carrier_passport_has_expired_for_dispatcher_event'
]

export default function PassportExpirationDate ({ slug, data, toast } :NotifyEvent) {
  useEffect(() => {
    if (slug === undefined) {
      return
    }

    if (!SLUGS.includes(slug)) {
      return
    }

    if (toast === undefined) {
      return
    }

    if (data === undefined) {
      return
    }

    const { message } = data as Data

    toast(message, 'error')
  }, [slug, data, toast])

  return (<></>)
}
