import { useEffect, useState } from 'react'
import './styles.sass'
import { StepProps } from 'ui/ModalSigning'
import { ActDispatcherRejectionWizardData } from './index'
import Alert from '@mui/material/Alert'
import { Box } from '@mui/material'
import TextField from 'ui/TextField'

export default function ActInfo ({ isActive, data, handleNext }: StepProps) {
  const [error, setError] = useState('')

  const d = data as ActDispatcherRejectionWizardData

  useEffect(() => {
    if (!isActive) {
      return
    }
    if (!d.actId) {
      setError('Идентификатор акта не определен')
    }

    // if (!d.problem) {
    //   handleNext()
    // }
  }, [isActive, d.actId, d.problem, handleNext])

  if (!isActive) {
    return <></>
  }

  return (<>
    <Box sx={{ display: 'flex', minWidth: '500px', alignItems: 'center', justifyContent: 'center' }}>
      <TextField
        label='Комментарий диспетчера'
        rows={4}
        value={d.problemComment}
        onChange={e => { d.onSetProblemComment(e.target.value) }}
        width='100%'
      />
    </Box>
    { error && <Alert severity="error">{error}</Alert> }
  </>)
}
