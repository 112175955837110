import './styles.sass'
import { useState } from 'react'
import { Certificate } from 'crypto-pro'
import ModalSigning from 'ui/ModalSigning'
import GetOrganizationList from '../ActWizard/GetOrganizationList'
import AuthCertificateSelect from '../ActWizard/AuthCertificateSelect'
import AuthProcess from '../ActWizard/AuthProcess'
import OrganizationSelect from '../ActWizard/OrganizationSelect'
import ActInfo from './ActInfo'
import RevocationConfirm from './RevocationConfirm'
import RevocationXml from './RevocationXml'
import Signing from './Signing'
import RevocationRequest from './RevocationRequest'
import Exit from './Exit'
import { Id } from 'api/Id'
import { Organization } from 'api/diadoc'
import SendIcon from '@mui/icons-material/Send'
import { ActWizardData } from 'ui/ActWizard'

export interface Props {
  open: boolean
  onClose: () => void
  actId?: Id
}

export interface ActRevocationWizardData extends ActWizardData {
  actId: Id | undefined,
  // act: Act | undefined,
  xmlBase64: string,
  signature: string,
  // onActLoaded: (a: Act | undefined) => void,
  onXmlLoaded: (c: string) => void,
  onSignatureLoaded: (c: string) => void
}

export default function ActRevocationWizard ({ open, onClose, actId }: Props) {
  const [authorized, setAuthorized] = useState(false) // has a Diadoc token
  const [organizationList, setOrganizationList] = useState<Organization[]>()
  const [certificate, setCertificate] = useState<Certificate>()
  const [boxId, setBoxId] = useState('')
  // const [act, setAct] = useState<Act>()
  const [xmlBase64, setXmlBase64] = useState('')
  const [signature, setSignature] = useState('')

  return (
    <>
      { open && <ModalSigning
        open={open}
        onClose={onClose}
        data={{
          authFirstStep: 2,
          authorized,
          organizationList,
          certificate,
          boxId,
          onAuthorized: (b: boolean) => {
            setAuthorized(b)
            if (!b) {
              setOrganizationList(undefined)
              setCertificate(undefined)
              setBoxId('')
              setXmlBase64('')
              setSignature('')
            }
          },
          onOrganizationListLoaded: setOrganizationList,
          onCertificateSelected: setCertificate,
          onBoxIdSelected: setBoxId,
          actId,
          // act,
          xmlBase64,
          signature,
          // onActLoaded: setAct,
          onXmlLoaded: setXmlBase64,
          onSignatureLoaded: setSignature
        }}
        steps={[
          {
            title: 'Аннулировать документы',
            fn: ActInfo,
            cancel: 'Отменить',
            next: 'Далее'
          },
          {
            title: 'Загружаем список организаций',
            fn: GetOrganizationList, // тут мы решаем задачу определения валиден ли токен Диадока + получаем список компаний
            cancel: 'Отменить',
            next: 'Далее'
          },
          {
            title: 'Выбор сертификата',
            fn: AuthCertificateSelect,
            cancel: 'Отменить',
            next: certificate ? 'Далее' : null
          },
          {
            title: 'Авторизация в Diadoc',
            fn: AuthProcess
          },
          {
            title: 'Загружаем список организаций',
            fn: GetOrganizationList // если на первом шаге получили список компаний, этот шаг пропускается
          },
          {
            title: 'Выбор организации',
            fn: OrganizationSelect,
            cancel: 'Отменить',
            next: boxId ? 'Далее' : null
          },
          {
            title: 'Подтвердить аннуляцию',
            fn: RevocationConfirm,
            cancel: 'Отменить',
            next: <>Аннулировать <SendIcon sx={{ width: '16px', ml: '10px' }} /></>
          },
          {
            title: 'Формируем запрос',
            fn: RevocationXml
          },
          {
            title: 'Подписываем запрос',
            fn: Signing
          },
          {
            title: 'Отправляем запрос',
            fn: RevocationRequest
          },
          {
            title: 'Аннулировано',
            fn: Exit
          }
        ]}
      /> }
    </>
  )
}
