// Проверяем на то что кор/счет является российским.
// Валидация происходит сразу при вводе
// Проверяем по первым 8 числам
const PREFIX_RUS_ACCOUNT = '30101810'
const checkCheckingRusAccount = (account: string = '') => {
  for (let i = 0; i <= account.length; i++) {
    if (PREFIX_RUS_ACCOUNT[i] !== undefined &&
        PREFIX_RUS_ACCOUNT[i] !== account[i] &&
        account[i] !== undefined
    ) { return false }
  }

  return true
}

export default checkCheckingRusAccount
