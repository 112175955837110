import './styles.sass'
import {
  Box,
  Button,
  Paper,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'
import { useEffect, useState } from 'react'

const pagerButtonSize = '2em'

export interface Column {
  id: string | string[]
  label: string
  hide?: boolean
  minWidth?: number
  align?: 'left' | 'right' | 'center'
  format?: (...values: unknown[]) => JSX.Element
}

export type Row = Record<string, any> & { cssStyle?: SxProps }

export interface Props {
  columns: readonly Column[]
  rowsPage?: number
  rows: (start: number, end: number) => Row[]
  rowsCount: number
  verticalAlign?: 'top' | 'bottom' | 'baseline' | string
  currentPage?: number
  changePage?: (page: number) => void
}

export default function DataTable ({ columns, rows, rowsCount, rowsPage, verticalAlign, currentPage, changePage }: Props) {
  const [page, setPage] = useState(0)
  // const [rowsPerPage, setRowsPerPage] = useState(15)
  const rowsPerPage = rowsPage ?? 15
  // const rowsPerPage = 15
  const [pageRows, setPageRows] = useState<Row[]>([])

  useEffect(() => {
    if (currentPage !== undefined) {
      setPage(currentPage)
    }
  }, [currentPage])

  useEffect(() => {
    setPageRows(rows(page * rowsPerPage, page * rowsPerPage + rowsPerPage))
  }, [rows, page, rowsPerPage])

  const lastPage = Math.ceil(rowsCount / rowsPerPage) - 1

  const PagerButton = ({ page: p }: { page: number }) => <Button
    variant="text"
    sx={{
      padding: 0,
      minHeight: 0,
      minWidth: 0,
      fontSize: '0.9rem',
      color: '#000',
      border: p === page ? '1px solid #000' : '1px solid transparent',
      width: pagerButtonSize,
      height: pagerButtonSize,
      lineHeight: pagerButtonSize,
      textAlign: 'center'

    }}
    onClick = { () => {
      setPage(p)
      changePage && changePage(p)
    } }
  >{p + 1}</Button>

  const PagerDevider = () => <Box
    sx={{
      fontSize: '0.9rem',
      height: pagerButtonSize,
      lineHeight: pagerButtonSize,
      textAlign: 'center',
      textTransform: 'none',
      marginLeft: '0.5em',
      marginRight: '0.5em'
    }}
  >...</Box>

  const PagerNextButton = () => <Button
    variant="contained"
    sx={{
      padding: '0 1em',
      minHeight: 0,
      minWidth: 0,
      fontSize: '0.9rem',
      height: pagerButtonSize,
      lineHeight: pagerButtonSize,
      textAlign: 'center',
      textTransform: 'none',
      marginLeft: '0.5em'
    }}
    disabled={page >= lastPage}
    onClick = { () => setPage(page + 1) }
  >Далее</Button>

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden', background: 'transparent' }}>
      <TableContainer sx={{ background: '#fff' }} >
        <Table stickyHeader aria-label="sticky table" style={{ border: '1px solid #ebebeb', borderRadius: '8px' }}>
          <TableHead>
            <TableRow>
              {columns.filter(item => item.hide !== true).map((column, idx) => (
                <TableCell
                  key={idx}
                  align={column.align}
                  style={{ minWidth: column.minWidth, background: '#f7f7f7', fontSize: '13px', fontWeight: 'bold' }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            { pageRows.map((row, idx) => <TableRow key={idx} sx={ row.cssStyle }>
                {columns.filter(item => item.hide !== true).map((column, idx) => {
                  const values = typeof column.id === 'string' ? [row[column.id]] : column.id.map(id => row[id])
                  return (
                    <TableCell key={idx} align={column.align} style={{ verticalAlign }}>
                      {column.format ? column.format(...values) : values.join(' ')}
                    </TableCell>
                  )
                })}
              </TableRow>)
            }
            { pageRows.length === 0
              ? <TableRow>
                  <TableCell colSpan={columns.length} align='center'>
                    Нет записей
                  </TableCell>
              </TableRow>
              : <></>
            }
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ display: 'flex', justifyContent: 'right', marginTop: '1em' }}>
        {lastPage > 0 && lastPage < 7 ? Array.from({ length: lastPage + 1 }).map((_ignore, idx) => <PagerButton key={idx} page={idx} />) : <></>}

        {lastPage >= 7 && (lastPage - page < 4 || page >= 4) ? <PagerButton key={0} page={0} /> : <></>}
        {lastPage >= 7 && (lastPage - page < 4 || page >= 4) ? <PagerDevider /> : <></>}

        {lastPage >= 7 && lastPage - page < 4 ? Array.from({ length: 5 }).map((_ignore, idx) => <PagerButton key={idx + lastPage - 4} page={idx + lastPage - 4} />) : <></>}
        {lastPage >= 7 && page < 4 ? Array.from({ length: 5 }).map((_ignore, idx) => <PagerButton key={idx} page={idx} />) : <></>}
        {lastPage >= 7 && page >= 4 && lastPage - page >= 4 ? Array.from({ length: 3 }).map((_ignore, idx) => <PagerButton key={idx + page - 1} page={idx + page - 1} />) : <></>}

        {lastPage >= 7 && (page < 4 || lastPage - page >= 4) ? <PagerDevider /> : <></>}
        {lastPage >= 7 && (page < 4 || lastPage - page >= 4) ? <PagerButton key={lastPage} page={lastPage}/> : <></>}

        {lastPage > 0 ? <PagerNextButton /> : <></>}
      </Box>
    </Paper>
  )
}
