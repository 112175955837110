export interface Person {
  firstName?: string
  secondName?: string
  familyName?: string
}

export function personName ({ firstName = '', secondName = '', familyName = '' }: Person, fallback: string = ''): string {
  const capitalize = (str: string) => str.length > 0 ? str.charAt(0).toUpperCase() + str.slice(1) : ''
  const short = (str: string) => str.length > 0 ? str.substring(0, 1).toUpperCase() + '.' : ''

  const name =
    familyName.length > 0
      ? `${capitalize(familyName)} ${short(firstName)} ${short(secondName)}`
      : `${capitalize(firstName)} ${capitalize(secondName)}`

  const result = name.replace(/ +(?= )/g, '')

  return result.trim().length > 0 ? result : fallback
}
