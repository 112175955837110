import { mainService } from 'api/service'
import { Id } from 'api/Id'
import { Bid, BidCargo, BidFreeSpace } from 'api/bid'
import { Driver } from './driver'
import { Vehicle } from './vehicle'
import { Trailer } from './trailer'
import { Forwarder } from './forwarder'
import { Address } from 'api/address'
import { SortOrder } from 'api/SortOrder'
import { RequireOnly } from 'util/type/RequireOnly'
import { Pagination } from './pagination'
import { Customer } from './customer'
import { BlackListEntry } from './black-list'
import { Indicator } from 'ui/LoadingDayIndicator'
import { Act } from './act'
import { ConsignorConsignee } from './consignorConsignee'
import { AstonTrafficReservedSlot } from './aston-traffic'
import { Organization } from './organization'
import { Passport } from './passport'
import { Profile } from './profile'

export enum ShippingTruckRunStatus {
  new = 'new',
  archiveRefuse = 'archive_refuse',
  archiveReject = 'archive_reject',
  confirmed = 'confirmed',
  way = 'way',
  completed = 'completed',
  arrived = 'arrived',
  archiveAct = 'archive_act',
  archiveOutdated = 'archive_outdated',
  archiveProblem = 'archive_problem'
}

export interface ShippingTruckRunCarrierComment {
  createTs: number
  comment: string
}

export enum ShippingSentToKisStatus {
  new = 'new',
  sent = 'sent',
  completed = 'completed',
}

export enum KisRequestHistoryType {
  unloading = 'orders_unloading',
}

export enum KisRequestHistoryStatus {
  success = 'success',
  noAnswer = 'no_answer',
}

export interface KisRequestHistory {
  type: KisRequestHistoryType
  status: KisRequestHistoryStatus
  requestTs: number
}

export interface ShippingTruckRun {
  id: Id
  num: string
  status: ShippingTruckRunStatus
  driver: Driver
  vehicle: Vehicle
  trailer?: Trailer
  loadingTs: number
  createTs: number
  owner: string
  shortage: {
    weight: number
    cost: number
  }
  actualLoadingTs?: number
  unloadingTs?: number
  cost?: number
  weight?: number
  weightCorrection?: number
  finalWeight?: number
  finalWeightCorrection?: number
  billTs?: number
  billNumber?: string
  scanBills?: string[]
  incorrectScanBills?: boolean
  costWeight?: number
  problemComment?: string
  wasEdited?: boolean
  isChecked?: boolean
  hasLongToWay?: boolean
  acts?: Act[]
  carrierComments?: ShippingTruckRunCarrierComment[]
  sentToKisStatus?: ShippingSentToKisStatus
  unloadingAddress?: Address
  consignee?: ConsignorConsignee
  costCorrection?: number
  reservedUnloadingSlot?: AstonTrafficReservedSlot
  actualCargo?: BidCargo

  kisRequestHistory?: KisRequestHistory[]

  reference?: ShippingTruckRun
  clone?: ShippingTruckRun
  createdByEmployee?: Profile & { phone: string }
  employeeComment?: string
}

export interface Shipping {
  bid: Omit<Bid, 'freeSpace'>
  truckRuns: ShippingTruckRun[]
  resultsByStatus?: Record<ShippingTruckRunStatus, {
    totalWeight: number
    count: number
  }>
  totalWeight?: number
  newActs?: {
    unloadingAddressId?: Id
    consigneeId?: Id
    num: string
    dateTs: number
  }[]
}

export type ShippingList = Pagination<Shipping & { loadingTs?: number }> & {
  totalWeight: number
  resultsByStatus?: Record<ShippingTruckRunStatus, {
    totalWeight: number
    count: number
  }>
}

export type ShippingTruckRunExtBid = ShippingTruckRun & {
  bid: Omit<Bid, 'freeSpace'>
  forwarder?: Forwarder
  forwarders?: Forwarder[]
  blackList?: BlackListEntry[]
}

export type ShippingTruckRunList = ShippingTruckRun & {
  carrierName: string
  forwarder?: string
  forwarders?: Forwarder[]
  blackList?: BlackListEntry[]
}

export type ShippingTruckRunLoad = ShippingTruckRunList & {
  loadingAddress: Address
  customer: Customer
  cargo: BidCargo
}

export type ShippingTruckRunLoadStatistic = Pagination<ShippingTruckRunLoad> & {
  totalWeight: number
  outdated: number
}

export interface ShippingAll {
  owner: string
  bid: Bid
  truckRuns: ShippingTruckRunList[]
  hasBlackList?: boolean
  resultsByStatus?: Record<ShippingTruckRunStatus, {
    totalWeight: number
    count: number
  }> & { remainingWeight?: number }
}

export type ShippingAllNearest = ShippingTruckRun & {
  bid: Omit<Bid, 'freeSpace'>
  carrierName: string
}

export type ShippingTruckRunAndState = ShippingTruckRunExtBid & {
  state: {
    nextStatuses: ShippingTruckRunStatus[]
  }
}

export type ShippingTruckRunForUpdate = RequireOnly<Omit<ShippingTruckRunAndState, 'num' | 'state' | 'bidId' | 'owner' | 'loadingTs' | 'createTs' | 'cost' | 'driver' | 'vehicle' | 'trailer' | 'carrierComments' | 'unloadingAddress' | 'consignee' | 'actualCargo'>, 'id'> & {
  driverId?: Id
  vehicleId?: Id
  trailerId?: Id
  forwarderId?: Id
  unloadingAddressId?: Id
  consigneeId?: Id
  actualCargoSlug?: string
}

type ShippingTruckRunForCreateOmited = 'num' | 'state' | 'createTs' | 'cost' |
              'driver' | 'vehicle' | 'trailer' | 'carrierComments' | 'unloadingAddress' |
              'consignee' | 'actualCargo' | 'acts' | 'kisRequestHistory' | 'problemComment' | 'isChecked' |
              'hasLongToWay' | 'finalWeightCorrection' | 'id' | 'wasEdited' | 'shortage' | 'weightCorrection' |
              'actualLoadingTs' | 'incorrectScanBills' | 'actualCargoSlug' | 'sentToKisStatus' |
              'reservedUnloadingSlot' | 'costWeight' | 'scanBills' | 'reference' | 'clone' | 'createdByEmployee'
export type ShippingTruckRunForCreate = Omit<ShippingTruckRun, ShippingTruckRunForCreateOmited> & {
  bidId?: Id
  driverId?: Id
  vehicleId: Id
  trailerId?: Id
  forwarderId?: Id
  unloadingAddressId?: Id
  consigneeId?: Id
  referenceId?: Id
}

type ForUpdate<T extends Shipping> = Omit<T, 'status' | 'bid' | 'createTs' | 'truckRunStatus'> & { bidId: Id }

export type ShippingForCreate = Omit<ForUpdate<Shipping>, 'id' | 'truckRuns' | 'totalWeight' | 'resultsByStatus' | 'newActs'> & {
  truckRuns: {
    driverId: Id
    vehicleId: Id
    trailerId?: Id
    loadingTs: number
  }[]
  relay?: boolean
}
export type ShippingAllForCreate = Omit<ForUpdate<ShippingAll>, 'id'>
export interface ShippingListParams {
  filters?: {
    truckRunIds?: Id[]
    truckRunStatus?: ShippingTruckRunStatus[]
    driverId?: Id
    loadingTs?: number
    loadingTsFrom?: number
    loadingTsTo?: number
    billTsFrom?: number
    billTsTo?: number
    billNumber?: string
  }
  groupBy?: {
    day: boolean
  }
  includeNewActs?: boolean
}

export interface ShippingAllListParams {
  filters?: {
    owner?: string
    truckRunStatus?: ShippingTruckRunStatus[]
    billTsFrom?: number
    billTsTo?: number
    billNumber?: string
    loadingTsFrom?: number
    loadingTsTo?: number
    loadingAddressIds?: Id[]
    excludeAutoFill?: boolean
  }
  freeSpace?: boolean
  includeBlackList?: boolean
  includeExtStatData?: boolean
}

export interface ShippingTruckRunListParams {
  filters?: {
    bidId?: Id,
    loadingTs?: number
    loadingTsFrom?: number
    loadingTsTo?: number
    status?: ShippingTruckRunStatus[]
  },
  sort?: {
    loadingTs?: SortOrder
    num?: SortOrder
    createTs?: SortOrder
  }
}

export interface ShippingTruckRunLoadsParams {
  filters?: {
    loadingTsFrom?: number
    loadingTsTo?: number
    loadingAddressIds?: Id[]
    carrier?: string
    status?: ShippingTruckRunStatus[]
  }
  sort?: {
    billTs?: SortOrder
    loadingTs?: SortOrder
  }
  limit?: number
  offset?: number
}

export interface ShippingTruckRunChangeStatusParams {
  list: {
    id: Id
    status: ShippingTruckRunStatus
    weight?: number
    billNumber?: string
    billTs?: number
    scanBills?: string[]
    finalWeight?: number
    actualCargoSlug?: string
  }[]
}

export interface ShippingAllResponseItem {
  bid: Bid
  freeSpace?: BidFreeSpace & { reserved: number }
  confirmed: ShippingTruckRunList[]
  limit: ShippingTruckRunList[]
  overLimit: ShippingTruckRunList[]
  blackList?: ShippingTruckRunList[]
}
export interface ShippingAllResponseLoadingDaysItem {
  shipmentTs: number
  indicator?: Indicator
}
export interface ShippingAllResponses {
  list: ShippingAllResponseItem[]
  loadingDays: ShippingAllResponseLoadingDaysItem[]
}

export interface ShippingAllResponsesParams {
  filters?: {
    loadingTs?: number
    onlyBlackList?: boolean
  }
}

export interface ShippingTruckRunDriverListParams {
  loadingTsFrom: number
  loadingTsTo: number
  loadingAddressId?: Id
}

export interface DispatcherTruckRunsRegisterParams {
  tsFrom: number
  tsTo: number
  pointAddressId: Id
}

export interface DispatcherTruckRunsRegisterHistoryResult {
  id: Id
  addressName: string
  tsFrom: number
  tsTo: number
  createTs: number
  loadingAddressId: Id
  filename: string
}

export interface DispatcherTruckRunsRegisterHistoryParams {
  sort?: {
    createTs?: 'asc' | 'desc'
  }
}

export interface ShippingCreateResult {
  success: boolean
  conflicts?: {
    verificationContract?: boolean
    customer: RequireOnly<Customer, 'id' | 'fullName'>
  }
}

export interface ShippingTruckRunSearchParams {
  query: string
}

export interface ShippingTruckRunSearchResult {
  id: Id
  billNumber: string
}

export interface ShippingRefuse {
  success: boolean
  conflicts?: {
    reservedSlot?: AstonTrafficReservedSlot
  }
}

export interface ShippingTruckRunDriverListResult {
  date: string
  carrier: Organization | undefined
  driver: Driver
  driverPassportData: Passport | undefined
  trailer: Trailer | undefined
  vehicle: Vehicle
}

export const isArchiveStatus = (status: ShippingTruckRunStatus) =>
  [ShippingTruckRunStatus.archiveRefuse, ShippingTruckRunStatus.archiveReject, ShippingTruckRunStatus.archiveAct, ShippingTruckRunStatus.archiveProblem, ShippingTruckRunStatus.archiveOutdated].includes(status)

const request = (method: string, params: object) => mainService.request(`shipping.${method}`, params)

export const shippingCreate = (params: ShippingForCreate) => request('create', params) as Promise<ShippingCreateResult>
export const shippingGet = (id: Id) => request('get', { id }) as Promise<ShippingTruckRunExtBid | null>
export const shippingList = (params: ShippingListParams = {}) => request('list', params) as Promise<ShippingList>
export const shippingRefuse = (truckRunId: Id) => request('refuse', { truckRunId }) as Promise<ShippingRefuse>
export const shippingComplete = (id: Id, scanBills: string[], driverId?: Id) => request('complete', { id, scanBills, driverId }) as Promise<boolean>
export const shippingConfirmRedirect = (id: Id, consigneeId: Id, costCorrection: number) => request('confirmRedirect', { id, consigneeId, costCorrection }) as Promise<{
  success: boolean
  conflicts?: {
    reservedSlot?: AstonTrafficReservedSlot
  }
}>
export const shippingNearest = () => request('nearest', {}) as Promise<ShippingTruckRunExtBid[]>
export const shippingAddComment = (id: Id, comment: string) => request('add-comment', { id, comment }) as Promise<boolean>

export const shippingAllCreate = (params: ShippingAllForCreate) => request('all.create', params) as Promise<boolean>
export const shippingAllGet = (id: Id) => request('all.get', { id }) as Promise<ShippingTruckRunExtBid | null>
export const shippingAllList = (params: ShippingAllListParams = {}) => request('all.list', params) as Promise<ShippingAll[]>
export const shippingAllDelete = (id: Id) => request('all.delete', { id }) as Promise<boolean>
export const shippingAllNearest = () => request('all.nearest', {}) as Promise<ShippingAllNearest[]>
export const shippingAllResponses = (params: ShippingAllResponsesParams = {}) =>
  request('all.responses', params) as Promise<ShippingAllResponses>

export const shippingTruckRunGet = (id: Id) => request('truck-run.get', { id }) as Promise<ShippingTruckRunAndState | null>
export const shippingTruckRunList = (params: ShippingTruckRunListParams) =>
  request('truck-run.list', params) as Promise<ShippingTruckRunList[]>
export const shippingTruckRunSearch = (params: ShippingTruckRunSearchParams) =>
  request('truck-run.search', params) as Promise<ShippingTruckRunSearchResult[]>
export const shippingTruckRunLoads = (params: ShippingTruckRunLoadsParams = {}) =>
  request('truck-run.loads', params) as Promise<ShippingTruckRunLoadStatistic>
export const shippingTruckRunChangeStatus = (params: ShippingTruckRunChangeStatusParams) =>
  request('truck-run.change-status', params) as Promise<{
    success: boolean
    conflicts?: {
      truckRun: ShippingTruckRun
      toWay?: boolean
    }
  }>
export const shippingTruckRunUpdate = (params: ShippingTruckRunForUpdate) => request('truck-run.update', params) as Promise<{
  success: boolean
  conflicts?: {
    notFound?: Id
    incorrectStatus?: ShippingTruckRunStatus
    currentStatus?: ShippingTruckRunStatus
    forwarderNotFound?: string
    reservedSlot?: AstonTrafficReservedSlot
    truckRunToWay?: ShippingTruckRun
    billNumberExists?: boolean
  }
}>

export const shippingTruckRunDriverList = (params: ShippingTruckRunDriverListParams) =>
  request('truck-run.driver-list', params) as Promise<ShippingTruckRunDriverListResult[] | null>

export const dispatcherTruckRunsRegisterDownload = (params: DispatcherTruckRunsRegisterParams) =>
  request('truck-run.register-generate', params) as Promise<string | null>

export const dispatcherTruckRunsRegisterHistory = (params: DispatcherTruckRunsRegisterHistoryParams = {}) =>
  request('truck-run.register-history', params) as Promise<DispatcherTruckRunsRegisterHistoryResult[]>

export const shippingTruckRunCreate = (params: ShippingTruckRunForCreate) => request('truck-run.create', params) as Promise<{
  success: boolean
  conflicts?: {
    bidNotFound?: Id
    vehicleNotFound?: Id
    incorrectReferenceId?: Id
    billNumberExists?: boolean
    forwarderNotFound?: string
  }
}>
