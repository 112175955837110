import './styles.sass'
import { Autocomplete, AutocompleteValue, Box, Stack, SxProps, TextField, Theme } from '@mui/material'
import { ReactNode, useMemo } from 'react'
import { ValidateError } from 'validation/validate'
import MenuItem from './MenuItem'

export type Params<T, Multiple extends boolean = false, DisableClearable extends boolean = false> = {
  name?: string
  label?: string
  hint?: ReactNode
  placeholder?: string
  options: { value: T, name: string, optionName?: ReactNode, isActive?: boolean }[]
  value?: Multiple extends true ? T[] : T
  onChange?: (value: Multiple extends true ? T[] : DisableClearable extends true ? T : (T | undefined)) => void
  onInputChange?: (query: string) => void
  hideError?: boolean
  errors?: ValidateError
  errorMessage?: string
  paper?: {
    width?: number | string
    height?: number | string
    minWidth?: number | string
    maxWidth?: number | string
    minHeight?: number | string
    maxHeight?: number | string
  }
  fullWidth?: boolean
  width?: string
  multiple?: Multiple
  disableClearable?: DisableClearable
  disabled?: boolean
  noOptionsText?: ReactNode
  tabIndex?: number,
  inputScroll? :boolean
  limitTags?: number
  renderTags?: (tags: { id: T, label: string }[]) => ReactNode
  inputSx?: SxProps<Theme>
}

export default function Select <T, Multiple extends boolean = false, DisableClearable extends boolean = false> ({
  name,
  label,
  hint,
  placeholder,
  options,
  value,
  onChange,
  onInputChange,
  hideError,
  errors,
  errorMessage,
  paper,
  fullWidth,
  width,
  multiple,
  disableClearable,
  disabled,
  noOptionsText,
  tabIndex,
  inputScroll,
  limitTags,
  renderTags,
  inputSx = {}
}: Params<T, Multiple, DisableClearable>): JSX.Element {
  const eMessage = useMemo(() => {
    if (hideError) {
      return
    }

    if (errorMessage !== undefined) {
      return errorMessage
    }

    const required = errors?.find(({ keyword, params }) => keyword === 'required' && params.missingProperty === name)

    if (required) {
      return 'Поле обязательно к заполнению'
    }

    const err = errors?.find(item => name && item.instancePath.includes(name))

    return err?.message
  }, [hideError, errorMessage, errors, name])

  const opts = useMemo(() => options.map(({ value, name }) => ({ id: value, label: name })), [options])
  const selected = useMemo(() => value === undefined
    ? []
    : opts.filter(({ id }) => (multiple ? value as T[] : [value as T]).includes(id))
  , [multiple, opts, value])

  return <Stack width={ fullWidth === true ? '100%' : width } gap={2}>
    <Box sx={{ width: '100%' }}>
      { (label || hint) && <Stack direction='row' justifyContent='space-between' sx={{
        fontWeight: '500',
        fontSize: '13px',
        lineHeight: '16px',
        letterSpacing: '-0.02em',
        whiteSpace: 'nowrap',
        marginBottom: '8px'
      }}>
          <Box sx={{ color: '#111111' }}>{label}</Box>
          <Box sx={{ color: '#B2B2B2' }}>{hint}</Box>
        </Stack>}
      <Autocomplete
        limitTags={limitTags}
        options={opts}
        value={(multiple ? selected : (selected.length > 0 ? selected[0] : null)) as AutocompleteValue<{id: T, label: string}, Multiple, DisableClearable, false>}
        multiple={multiple}
        onChange={(_e, v) => {
          if (!onChange) {
            return
          }

          const value = multiple ? ((v ?? []) as { id: T }[]).map(({ id }) => id) : ((v ?? { id: undefined }) as { id: T }).id
          onChange(value as Multiple extends true ? T[] : DisableClearable extends true ? T : (T | undefined))
        }}
        onInputChange={(_event, query) => onInputChange && onInputChange(query)}
        disableClearable={disableClearable}
        disabled={disabled}
        noOptionsText={ noOptionsText ?? 'Нет значений' }
        tabIndex={tabIndex}
        slotProps={{
          paper: {
            style: {
              padding: '12px',
              width: paper?.width,
              height: paper?.height,
              minWidth: paper?.minWidth,
              maxWidth: paper?.maxWidth,
              minHeight: paper?.minHeight,
              maxHeight: paper?.maxHeight,
              border: '1px solid #EBEBEB',
              boxShadow: '0px 4px 13px rgba(0, 0, 0, 0.1)',
              borderRadius: '4px'
            }
          }
        }}
        sx={{
          background: '#F7F7F7',
          '& .Mui-disabled': {
            background: '#EBEBEB'
          },
          '& fieldset': {
            border: 'none !important'
          }
        }}
        renderTags={renderTags}
        renderInput={(params) => <TextField
          {...params}
          name={name}
          placeholder={selected.length > 0 ? undefined : placeholder}
          sx={{
            color: '#111111 !important',
            background: 'transparent',
            minHeight: '38px',
            border: eMessage ? '1px solid red' : '1px solid #EBEBEB',
            borderRadius: '4px',
            '& .MuiOutlinedInput-root': {
              paddingTop: 0,
              paddingBottom: 0
            },
            ...(inputScroll
              ? {
                  overflowY: 'auto'
                }
              : {}),
            ...inputSx
          }}/>}
        renderOption={(props, { id }) => {
          const opt = options.find(({ value }) => value === id)
          const isDisabled = opt ? opt.isActive === false : false
          return <MenuItem { ...props } key={`${id}`} disabled={isDisabled}>{ opt ? (opt.optionName ? opt.optionName : opt.name) : `${id}` }</MenuItem>
        }}
      />
      <Box sx={{ color: 'red', fontSize: '10px', marginTop: '0.5em', height: '10px' }}>{eMessage}</Box>
    </Box>
  </Stack>
}
