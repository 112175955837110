import { Box, Button, Stack, Typography } from '@mui/material'
import { Shipping, ShippingTruckRun, ShippingTruckRunStatus } from 'api/shipping'
import { memo, useMemo } from 'react'
import InfoCell, { InfoCellProps } from 'ui/InfoCell'
import OsmBid from 'ui/Osm'
import TruckRunInfo, { Highlight, StatusView } from 'ui/TruckRunInfo'
import './styles.sass'
import { Pieces } from 'util/Pieces'
import addressName from 'util/addressName'
import { toTon } from 'util/weight'
import CollapseBoxTruckRun from 'ui/CollapseBoxTruckRun'
import ChipTruckRunStatus from 'common/ChipTruckRunStatus'
import { Bid } from 'api/bid'
import { Id } from 'api/Id'
import { useMainRoutes } from 'routes'
import { useNavigate } from 'react-router-dom'
import SquareButton from 'ui/SquareButton'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'

export type Descriptions = InfoCellProps

interface GroupTitleParams {
  status: ShippingTruckRunStatus
  count: number
  totalTon: number
  groupByStatusView?: StatusView
}
const GroupTitle = ({ status, groupByStatusView, count, totalTon }: GroupTitleParams) => {
  return <Stack direction='row' spacing={1} alignItems='center'>
    <div>{groupByStatusView ? groupByStatusView(status) : status}</div>
    {ChipTruckRunStatus(
      status,
      <Typography fontSize='13px' fontWeight={400} >{count} | {totalTon} тн</Typography>,
      {
        minHeight: '20px',
        maxHeight: '20px',
        padding: '0 0.5em'
      }
    )}
  </Stack>
}

export type GroupByStatusSort = (a: ShippingTruckRunStatus, b: ShippingTruckRunStatus) => number
interface TripsCardParams {
  data: Shipping
  highlight?: Highlight
  descriptions: Descriptions[]
  selectedIds?: Id[]
  onChange?: () => void
  statusView?: StatusView
  groupByStatus?: boolean
  groupByStatusView?: StatusView
  groupByStatusSort?: GroupByStatusSort
  shortAddress?: boolean
  onAddComment?: (data: ShippingTruckRun) => void
  onViewQRCode?: (data: ShippingTruckRun) => void
  onRefuse?: (data: ShippingTruckRun & { bid: Omit<Bid, 'freeSpace'> }) => void
  onSelect?: (data: ShippingTruckRun, checked: boolean) => void
  onOpenBid?: () => void
  onOpenTruckRun?: (data: ShippingTruckRun) => void
  onOpenComplete?: (data: ShippingTruckRun) => void
}

type TruckRunsByGroups = Map<ShippingTruckRunStatus, {
  list: Shipping['truckRuns']
  collapse: boolean
  count: number
  totalWeight: number
}>

const TripsCard = memo(({
  data, highlight, descriptions, selectedIds,
  onChange, statusView, groupByStatus, groupByStatusView,
  groupByStatusSort, shortAddress, onAddComment,
  onViewQRCode, onRefuse, onSelect, onOpenBid, onOpenTruckRun,
  onOpenComplete
}: TripsCardParams) => {
  const totalWeight = data.totalWeight !== undefined && data.totalWeight > 0 ? toTon(data.totalWeight) : 0
  const { links } = useMainRoutes()
  const navigate = useNavigate()

  const truckRunsByGroups: TruckRunsByGroups = useMemo(() => {
    const result = new Map()
    if (!groupByStatus) {
      return result
    }

    const truckRuns = data.truckRuns
    const statusesUniq = [...new Set(data.truckRuns.map(item => item.status))]

    for (const filterStatus of statusesUniq) {
      const list = truckRuns.filter(item => item.status === filterStatus)
      const { totalWeight, count } = data.resultsByStatus ? data?.resultsByStatus[filterStatus] : { totalWeight: 0, count: 0 }
      result.set(filterStatus, {
        list,
        collapse: count > 1,
        count,
        totalWeight
      })
    }

    return result
  }, [data.truckRuns, data.resultsByStatus, groupByStatus])

  return (
    <div className='tripsCard'>
      <div className='tripsCard_content'>
        <div className='tripsCard_content_header'>
          <div className='tripsCard_content_header_body'>
            { data.bid.carrier && <Box bgcolor='#624CE0' padding={1} color='#FFFFFF'>
              Назначенная перевозка
            </Box> }
            <div className='tripsCard_content_header_body_panel'>
              <div className='tripsCard_content_header_body_panel_title'>
                <Typography fontWeight={600} fontSize='16px'>Перевозка {data.bid.num}</Typography>
                <Typography color='#94A3B8' fontWeight={400} fontSize='16px'>{addressName(data.bid.loadingAddress, shortAddress)} - {addressName(data.bid.unloadingAddress, shortAddress)}</Typography>
              </div>
              { onOpenBid && <div className='tripsCard_content_header_body_panel_control'>
                <SquareButton
                  variant='contained'
                  color='primary'
                  onClick={onOpenBid}
                >
                  <MoreHorizIcon sx={{ width: '16px', height: '16px', color: '#FFFFFF' }} />
                </SquareButton>
              </div> }
            </div>
            <div className='tripsCard_content_header_body_info'>
              { descriptions.map((item, idx) => <InfoCell {...item} key={idx} />)}
            </div>
          </div>
          <div className='tripsCard_content_header_map'>
            <OsmBid zoom={10} route={{ from: data.bid.loadingAddress.coordinates, to: data.bid.unloadingAddress.coordinates }} staticMap/>
          </div>
        </div>
        <div className='tripsCard_content_trips'>
          { groupByStatus
            ? [...truckRunsByGroups]
                .sort((a, b) => groupByStatusSort ? groupByStatusSort(a[0], b[0]) : 0)
                .map(([filterStatus, { list, collapse, count, totalWeight }]) => <CollapseBoxTruckRun
              className='tripsCard_collapseBox'
              title={<GroupTitle
                status={filterStatus as ShippingTruckRunStatus}
                groupByStatusView={groupByStatusView}
                totalTon={toTon(totalWeight)}
                count={count}
              />}
              key={`${data.bid.id}-${filterStatus}`}
              collapse={collapse}
              >
                <TruckRunInfo data={list} highlight={highlight} onChange={onChange} statusView={statusView} onOpenComplete={onOpenComplete}/>
              </CollapseBoxTruckRun>)
            : <>
              { data.truckRuns.length > 0
                ? <TruckRunInfo
                  title={`Итого ${data.truckRuns.length} ${Pieces((data.truckRuns.length), 'рейс', 'рейса', 'рейсов')} на вывоз ${totalWeight} тн`}
                  data={data.truckRuns}
                  selectedIds={selectedIds}
                  highlight={highlight}
                  onChange={onChange}
                  statusView={statusView}
                  onAddComment={onAddComment}
                  onViewQRCode={onViewQRCode}
                  onRefuse={onRefuse ? (tr) => onRefuse({ ...tr, bid: data.bid }) : undefined}
                  onSelect={onSelect}
                  onOpenTruckRun={onOpenTruckRun}
                  onOpenComplete={onOpenComplete}
                />
                : data.bid.carrier && !data.bid.autoFill
                  ? <Box sx={{
                    border: '1px solid #EBEBEB',
                    padding: '1em 1.5em'
                  }}>
                    <Button
                      color='inherit'
                      variant='contained'
                      fullWidth
                      disabled={data.bid.blockedManualFill}
                      onClick={() => navigate(`${links.CARRIER_TRIPS_BID_PAGE}/${data.bid.id}?tab=truck_runs`)}
                    >Добавить рейсы</Button>
                  </Box>
                  : <></>
              }
              </>
            }
        </div>
      </div>
    </div>
  )
})

export default TripsCard
