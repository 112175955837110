import { ShippingTruckRunStatus } from 'api/shipping'
import ChipStatus from 'ui/ChipStatus'

const ChipTruckRunStatus = (status: ShippingTruckRunStatus) => {
  switch (status) {
    case ShippingTruckRunStatus.new:
      return <ChipStatus label='На рассмотрении' />
    case ShippingTruckRunStatus.confirmed:
      return <ChipStatus label='Запланирован' color='grey' />
    case ShippingTruckRunStatus.way:
      return <ChipStatus label='В пути' />
    case ShippingTruckRunStatus.arrived:
      return <ChipStatus label='Выгружен' color='yellow' />
    case ShippingTruckRunStatus.completed:
      return <ChipStatus label='Завершен' color='green' />
    case ShippingTruckRunStatus.archiveReject:
      return <ChipStatus label='Отклонен' color='red' />
    case ShippingTruckRunStatus.archiveAct:
      return <ChipStatus label='Архив' />
    case ShippingTruckRunStatus.archiveRefuse:
      return <ChipStatus label='Отозван' />
    case ShippingTruckRunStatus.archiveOutdated:
      return <ChipStatus label='Просрочен' color='red' />
    case ShippingTruckRunStatus.archiveProblem:
      return <ChipStatus label='Завершен диспетчером' color='red' />
    default:
      return <></>
  }
}

export default ChipTruckRunStatus
