import { ShippingTruckRunDriverListResult } from 'api/shipping'
import { TRUCK_RUN_DRIVER_XLSX_COLUMNS } from '../constants/truckRunDriverXLSXColumns'
import { XLSXTableParams, XLSXTableRow, XLSXTableCell } from '../api/media'
import vehicleName from './vehicleName'
import trailerName from './trailerName'

function runTruckDriversConvertToXLSXFormat (data: ShippingTruckRunDriverListResult[]): XLSXTableParams {
  const convertedData: XLSXTableRow[] = data.map((item) => {
    const { date, driver, vehicle, driverPassportData, trailer, carrier } = item
    const createCell = (value: string | number, title: string): XLSXTableCell => ({
      value,
      title
    })

    const vehicleInfo = vehicleName(vehicle)
    const trailerInfo = trailerName(trailer)
    const driverInfo = `${driver.familyName} ${driver.firstName} ${driver.secondName}`
    const driverPassportInfo = !driverPassportData ? '' : `Серия ${driverPassportData.passportSeries},номер ${driverPassportData.passportNum},выдан ${driverPassportData.issuedDepartment}`
    const carrierInfo = !carrier ? '' : `${carrier.fullName},\n ИНН ${carrier.inn}, ${carrier.legalAddress},\n тел. ${(carrier.phones || []).join(',')}`

    const cells: XLSXTableCell[] = [
      createCell(date, TRUCK_RUN_DRIVER_XLSX_COLUMNS.date),
      createCell(vehicleInfo, TRUCK_RUN_DRIVER_XLSX_COLUMNS.vehicle),
      createCell(trailerInfo, TRUCK_RUN_DRIVER_XLSX_COLUMNS.trailer),
      createCell(driverInfo, TRUCK_RUN_DRIVER_XLSX_COLUMNS.driver),
      createCell(`${driver.phone}`, TRUCK_RUN_DRIVER_XLSX_COLUMNS.phone),
      createCell(driverPassportInfo, TRUCK_RUN_DRIVER_XLSX_COLUMNS.driverPassportData),
      createCell(`${carrier?.fullName || ''}`, TRUCK_RUN_DRIVER_XLSX_COLUMNS.carrier),
      createCell(carrierInfo, TRUCK_RUN_DRIVER_XLSX_COLUMNS.carrierDetails)
    ]
    return { cells }
  })

  return {
    rowsWithTitle: convertedData
  }
}
export default runTruckDriversConvertToXLSXFormat
