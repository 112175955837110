import { FolderZip } from '@mui/icons-material'
import { Button, CircularProgress } from '@mui/material'
import { useAuthContext } from 'AuthContext'
import { MediaZipCreateParams, mediaGenerateZip } from 'api/media'
import { useState } from 'react'
import SquareButton from 'ui/SquareButton'

export interface Params {
  pack?: MediaZipCreateParams[]
  archiveName?: string
  iconOnly?: boolean
}

export const slug = (str: string): string => {
  return str
    .toLocaleLowerCase()
    .replace(/\s/g, '_')
    .replace(/[^\wа-я]/g, '')
}

export default function DownloadZipButton ({ pack, archiveName, iconOnly }: Params) {
  const [disable, setDisable] = useState(false)

  const { handleResponseFailure } = useAuthContext()

  const handleDownloadZip = async () => {
    if (!pack) {
      return
    }

    setDisable(true)
    mediaGenerateZip(pack)
      .then(blob => {
        const element = document.createElement('a')
        element.href = URL.createObjectURL(blob)
        if (archiveName) {
          element.download = archiveName
        }
        element.click()
      })
      .catch(() => handleResponseFailure('Архив не был создан'))
      .finally(() => setDisable(false))
  }

  return (<>
    {
      iconOnly
        ? <SquareButton variant='outlined' color='secondary' disabled={disable || !pack} onClick={handleDownloadZip} >
        { !disable ? <FolderZip/> : <CircularProgress size='18px' /> }
      </SquareButton>
        : <Button
          variant='outlined'
          color='secondary'
          size='small'
          endIcon={
            !disable
              ? <FolderZip />
              : <CircularProgress size='18px' />
          }
          onClick={handleDownloadZip}
          disabled={disable || !pack}
        >Скачать документы</Button>
    }
  </>)
}
