import { useEffect, useState } from 'react'
import './styles.sass'
import { StepProps } from 'ui/ModalSigning'
import Alert from '@mui/material/Alert'
import { ActWizardData, skipDiadoc } from './index'
import { diadocGetMyOrganizations, OkResponseStatus, ErrorResponseStatus } from 'api/diadoc'
import { Box, CircularProgress, Stack, Typography } from '@mui/material'

export default function GetOrganizationList ({ isActive, data, handleNext, handleToStep }: StepProps) {
  const [error, setError] = useState('')

  const d = data as ActWizardData

  useEffect(() => {
    if (!isActive) {
      return
    }

    if (skipDiadoc() || d.organizationList !== undefined) {
      handleNext()
      return
    }

    diadocGetMyOrganizations()
      .then((response) => {
        switch (response.status) {
          case ErrorResponseStatus.tokenRequired:
            d.onAuthorized(false)
            handleToStep(d.authFirstStep)
            return

          case ErrorResponseStatus.err:
            throw new Error(response.error !== undefined ? response.error : 'Неизвестная ошибка!')

          case OkResponseStatus.ok:
            break
        }

        d.onAuthorized(true)

        if (response.organizations.length === 0) {
          throw new Error('Ошибка! Загружен пустой список организаций из Diadoc. Обратитесь к администратору.')
        }

        d.onOrganizationListLoaded(response.organizations)

        handleNext()
      })
      .catch((err) => setError(err.message))
  }, [isActive, d, handleNext, handleToStep])

  if (!isActive) {
    return <></>
  }

  return (
    <Box sx={{ display: 'flex', minWidth: '500px', minHeight: '250px', alignItems: 'center', justifyContent: 'center' }}>
      <Stack display='flex' flexDirection='column' alignItems='center'>
        <CircularProgress />
        <Typography>Загружаем список компаний из Diadoc</Typography>
        { error && <Alert severity="error">{error}</Alert> }
      </Stack>
    </Box>
  )
}
