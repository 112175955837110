import { useEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Params as ParamsOsm, Point } from 'ui/Osm'
import useRouterOSM from 'util/useRoterOSM'

import { MapContainer, Marker, TileLayer } from 'react-leaflet'
import L from 'leaflet'
import 'leaflet-routing-machine'
import { DEFAULT_COORDINATES } from 'constants/map'
import { Box } from '@mui/material'

type Route = ParamsOsm['route']

const validateRouteCoordinate = (x: unknown): x is string => {
  return typeof x === 'string' ? !!x.match(/^\d{1,2}\.\d+,\d{1,2}\.\d+~\d{1,2}\.\d+,\d{1,2}\.\d+$/) : false
}

const validateMarker = (x: unknown): x is string => {
  return typeof x === 'string' ? !!x.match(/^\d{1,2}\.\d+,\d{1,2}\.\d+$/) : false
}

const parsePoint = (p: string): Point => {
  const [lat, lon] = p.split(',').map(item => parseFloat(item))

  return { lat, lon }
}

const parseRouteCoordinate = (r: string): Route => {
  const [from, to] = r.split('~')

  return {
    from: parsePoint(from),
    to: parsePoint(to)
  }
}

enum URLParams {
  route = 'r',
  marker = 'm',
  zoom = 'z',
  // width = 'w',
  // height = 'h'
}

export default function OsmMobile () {
  const [searchParams] = useSearchParams()
  const [route, setRoute] = useState<Route>()
  const [zoom, setZoom] = useState<number>()
  const [marker, setMarker] = useState<Point>()

  const mapRef = useRef<L.Map>(null)

  useEffect(() => {
    const routeStr = searchParams.get(URLParams.route)
    const zoomStr = searchParams.get(URLParams.zoom)
    const markerStr = searchParams.get(URLParams.marker)
    // const widthStr = searchParams.get(URLParams.width)
    // const heightStr = searchParams.get(URLParams.height)

    if (validateRouteCoordinate(routeStr)) {
      setRoute(parseRouteCoordinate(routeStr))
    }

    if (zoomStr?.match(/\d{1,2}/)) {
      setZoom(parseInt(zoomStr))
    }

    if (validateMarker(markerStr)) {
      setMarker(parsePoint(markerStr))
    }
  }, [searchParams])

  const [routingMachine, setRoutingMachine] = useState<L.Routing.Control>()
  const router = useRouterOSM()

  useEffect(() => {
    if (route !== undefined) {
      setRoutingMachine(L.Routing.control({
        show: false,
        router,
        addWaypoints: false,
        routeWhileDragging: false,
        // @ts-ignore
        draggableWaypoints: false,
        fitSelectedRoutes: true,
        showAlternatives: false
      }))
    }
  }, [router, route])

  useEffect(() => {
    if (route === undefined) {
      return
    }

    const { from, to } = route

    if (routingMachine === undefined) {
      return
    }

    if (mapRef.current) {
      routingMachine.addTo(mapRef.current)
      routingMachine.setWaypoints([L.latLng(from.lat, from.lon), L.latLng(to.lat, to.lon)])
      mapRef.current.fitBounds([[from.lat, from.lon], [to.lat, to.lon]])
    }
  }, [route, routingMachine, zoom])

  useEffect(() => {
    if (marker !== undefined && mapRef.current) {
      mapRef.current.fitBounds([[marker.lat, marker.lon]])
    }
  }, [marker])

  useEffect(() => {
    if (zoom !== undefined && mapRef.current) {
      mapRef.current.setMinZoom(zoom)
      mapRef.current.setMaxZoom(zoom)
    }
  }, [zoom])

  useEffect(() => {
    if (route || marker) {
      return
    }

    if (mapRef.current) {
      mapRef.current.setView([DEFAULT_COORDINATES.lat, DEFAULT_COORDINATES.lon], 10)
    }
  }, [route, marker])

  return (<Box width='100vw' height='100vh'>
    <MapContainer
      center={[DEFAULT_COORDINATES.lat, DEFAULT_COORDINATES.lon]}
      scrollWheelZoom={false}
      style={{
        height: '100vh',
        width: '100vw'
      }}
      ref={mapRef}
      attributionControl={false}
      zoomControl={false}
      boxZoom={false}
      dragging={false}
      zoom={10}
    >
      <TileLayer
        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
      />
      { marker && <Marker position={[marker.lat, marker.lon]} />}
    </MapContainer>
  </Box>)
}
