import './styles.sass'
import { useCallback, useEffect, useState } from 'react'
import NavigatePanel from 'ui/NavigatePanel'
import DataTable from 'ui/DataTable'
import { SessionListItem, sessionAllDelete, sessionAllList } from 'api/session'
import { SessionStatus } from 'api/SessionStatus'
import ChipStatus from 'ui/ChipStatus'
import ActionsMenu from 'ui/ActionsMenu'
import { Delete } from '@mui/icons-material'

export default function AdminSessions () {
  const [rows, setRows] = useState<SessionListItem[]>([])

  const loadList = useCallback(() => sessionAllList().then(setRows), [])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { loadList() }, [])

  const title = 'Сессии'

  return (<>
    <div className='adminSessions'>
      <NavigatePanel
        title={title}
        breadcrumbs={{
          items: [{ title }]
        }}
      />
      <div className='adminSessions__body'>
        <div className='adminSessions__body_customer'>
          <DataTable
            columns = { [
              {
                id: 'id',
                label: 'ID'
              },
              {
                id: 'sub',
                label: 'Телефон'
              },
              {
                id: 'status',
                label: 'Статус',
                format: (value) => {
                  const status = value as SessionStatus

                  if (status === SessionStatus.new) {
                    return <ChipStatus color='grey' label='Новая' />
                  } else if (status === SessionStatus.active) {
                    return <ChipStatus color='green' label='Активная' />
                  } else if (status === SessionStatus.recovery) {
                    return <ChipStatus color='blue' label='Восстановление пароля' />
                  }

                  return <></>
                }
              },
              {
                id: 'sms_code',
                label: 'СМС Код'
              },
              {
                id: 'id',
                label: '',
                align: 'right',
                minWidth: 10,
                format: (id) => <ActionsMenu
                  key={`${id}`}
                  actions = {[
                    {
                      caption: 'Удалить',
                      onClick: () => sessionAllDelete(id as string).then(loadList),
                      icon: <Delete />
                    }
                  ]}
                />
              }
            ] }
            rows = { (start, end) => rows.slice(start, end) }
            rowsCount = { rows.length }
          />
        </div>
      </div>
    </div>
  </>)
}
