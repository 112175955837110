import { Visibility, VisibilityOff } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import TextField, { Params } from 'ui/TextField'

export default function PasswordField ({
  showPassword = false,
  onShowPassword,
  ...params
}: Params & { showPassword?: boolean, onShowPassword?: () => void }) {
  return (<TextField
    {...params}
    type={showPassword ? 'text' : 'password'}
    endAdornment={ onShowPassword !== undefined
      ? <IconButton
      onClick={() => onShowPassword()}
      edge='start'
    >
      {showPassword ? <VisibilityOff /> : <Visibility />}
    </IconButton>
      : undefined
  }
  />)
}
