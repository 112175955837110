import getRegionNumber from 'common/getRegionNumber'
import { Vehicle } from 'api/vehicle'
import { CarNumberIsRegionFirst } from 'common/Mask/CarNumberRegionMask'

export default function vehicleName (vehicle?: Partial<Vehicle>, variant: 'short' | 'full' = 'full'): string {
  if (vehicle === undefined) {
    return ''
  }

  const numberRegion = getRegionNumber(vehicle.numberType) ?? vehicle.numberRegion

  const short = CarNumberIsRegionFirst(vehicle.numberType)
    ? `${numberRegion}${vehicle.number}`
    : `${vehicle.number} ${numberRegion}`

  return variant === 'short' || !vehicle.brand
    ? `${short}`
    : `${short} • ${vehicle.brand}`
}
