import { makeJsonSchema, SimpleSchema } from 'validation/validate'
import { Okved } from 'api/okved'

type OkvedType = Required<Omit<Okved, 'id' | 'isActive'>> & {
  id?: string
  isActive?: boolean
}
export const simpleSchema: SimpleSchema<OkvedType> = {
  type: 'object',
  properties: {
    code: { type: 'string', pattern: '^\\d{2,6}$', errorMessage: 'Некорректно заполнен ОКВЭД' },
    description: { type: 'string', pattern: '^[а-яА-ЯёЁ\\- ]+$', errorMessage: 'Описание обязательно' },
    id: { type: 'string', optional: true },
    isActive: { type: 'boolean', optional: true }
  }
}

export const schema = makeJsonSchema<Omit<Okved, 'id'>>(simpleSchema)
