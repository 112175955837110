import './styles.sass'
import { Box } from '@mui/material'
import ChipStatus from 'ui/ChipStatus'
import { TrailerStatus } from 'api/trailer'
import { Id } from 'api/Id'

export function ChipTrailerStatus ({ status, truckRuns = [] }: { status: TrailerStatus, truckRuns?: Id[]}) {
  if (status === TrailerStatus.archive) {
    return <ChipStatus color='lightGrey' label='В архиве' />
  } else if (status === TrailerStatus.active) {
    return <Box sx={{ display: 'flex', gap: '0.3em' }}>
      <ChipStatus color='green' label='Активный' />
      { Array.isArray(truckRuns) && truckRuns.length > 0
        ? <ChipStatus color='yellowBorder' label='Участвует в перевозке' />
        : <></>
      }
    </Box>
  }
  return <></>
}
