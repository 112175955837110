import { ReactNode, useEffect, useMemo, useRef, useState } from 'react'
import { consignorConsigneeGet, consignorConsigneeSearch } from 'api/consignorConsignee'
import { debounce } from '@mui/material/utils'
import { Id } from 'api/Id'
import Select from 'ui/Select'
import { ValidateError } from 'validation/validate'

interface Params {
  name?: string
  label?: string
  hint?: ReactNode
  placeholder?: string
  value?: Id
  onChange?: (value?: Id) => void
  hideError?: boolean
  errors?: ValidateError
  errorMessage?: string
  fullWidth?: boolean
  width?: string
  disabled?: boolean
}

interface Option {
  value: Id
  name: string
}

export default function SelectOrganization ({ value, onChange, ...props }: Params) {
  const [options, setOptions] = useState<Option[]>([])
  const [query, setQuery] = useState('')
  const [defaultOptions, setDefaultOptions] = useState<Option[]>([])
  const loaded = useRef(false)

  useEffect(() => {
    if (loaded.current || !value) {
      return
    }

    consignorConsigneeGet(value).then((result) => {
      if (!result) {
        return
      }

      const options = [{ value: result.id, name: result.fullName }]
      setDefaultOptions(options)
      setOptions(options)
    })

    loaded.current = true
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const orgSearchFetch = useMemo(() => debounce(
    (query: string, callback: (result: Option[]) => void) =>
      consignorConsigneeSearch({ query })
        .then((result) => callback(result.map(({ id, fullName }) => ({ value: id, name: fullName })))),
    400
  ), [])

  useEffect(() => {
    let active = true

    if (query === '') {
      setOptions(defaultOptions)
      return undefined
    }

    orgSearchFetch(query, (result) => active && setOptions([...defaultOptions, ...result]))

    return () => {
      active = false
    }
  }, [query, orgSearchFetch, defaultOptions])

  return <Select
    options={options}
    value={value}
    noOptionsText='Поиск организации'
    {...props}
    onInputChange={setQuery}
    onChange={(id) => {
      onChange && onChange(id)
      setDefaultOptions(options.filter(({ value }) => value === id))
    }}
  />
}
