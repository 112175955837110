import { Box, Button, Stack, Typography, styled } from '@mui/material'
import CollapseBoxTruckRun from 'ui/CollapseBoxTruckRun'
import { ResponseItem } from '..'
import { ShippingTruckRunList } from 'api/shipping'
import InfoCard from 'ui/InfoCard'
import { personName } from 'util/personName'
import vehicleName from 'util/vehicleName'
import InfoCell from 'ui/InfoCell'
import { ReactNode } from 'react'
import { ArrowDownward, ArrowUpward, Check, Clear, MoreHoriz, ReportProblem } from '@mui/icons-material'
import ChipStatus from 'ui/ChipStatus'
import SquareButton from 'ui/SquareButton'
import { Bid } from 'api/bid'
import { Id } from 'api/Id'
import { BlackListEntry as BlackListEntryType } from 'api/black-list'
import Tooltip from 'ui/Tooltip'
import blTypeMap from 'common/BlackList/typeMap'
import blStatusMap from 'common/BlackList/statusMap'

const BORDER = '1px solid #E2E8F0'
const RBox = styled(Box)(({ theme }) => ({
  border: BORDER,
  borderRadius: '4px',
  background: '#FFFFFF',
  display: 'flex',
  flexDirection: 'column'
}))

const BlackListEntry = ({ entry }: { entry: BlackListEntryType }) => {
  const { type, status, description } = entry
  return <Stack direction='column' className='bl_entry' borderBottom='1px solid #ffffff' pb='1em' >
    <Typography fontSize='14px' fontWeight={600} mb='4px'>
    { blTypeMap.get(type)}: { blStatusMap.get(status)}
    </Typography>
    <Typography fontSize='12px' fontWeight={400}>{description}</Typography>
  </Stack>
}

const BlackListToolTip = ({ blackList }: { blackList?: BlackListEntryType[] }) => {
  if (blackList === undefined) {
    return <></>
  }

  if (blackList.length === 0) {
    return <></>
  }

  return <Tooltip
      title={<Stack
          direction='column'
          p={1}
          gap={1}
          sx={{ '& .bl_entry:last-child': { border: 'none', pb: 0 } }}
        >{blackList.map(item => <BlackListEntry entry={item} key={`bl-entry-${item.id}`} />)}</Stack>}
      arrow
      placement='top'
      view
    >
      <ReportProblem sx={{ color: '#EE6A5F', fontSize: '24px', cursor: 'pointer' }}/>
    </Tooltip>
}

interface RowParams {
  data: ShippingTruckRunList
  control?: ReactNode
  onClick?: (id: Id) => void
}

const Row = ({ data, control, onClick }: RowParams) => {
  return (<Stack direction='row' spacing={1}>
    <InfoCard style={{ margin: 0, flexGrow: 3 }}>
      <Stack direction='row' justifyContent='space-between' spacing={2}>
        <Stack direction='row' flexGrow={3} sx={{ cursor: 'pointer' }} onClick={() => onClick && onClick(data.id)} >
          <InfoCell label='Перевозчик' value={data.carrierName} />
          <InfoCell label='Водитель' value={personName(data.driver)} />
          <InfoCell label='ТС' value={vehicleName(data.vehicle)} />
        </Stack>
        <Stack direction='row' alignItems='center'>
          <BlackListToolTip blackList={data.blackList} />
        </Stack>
      </Stack>
    </InfoCard>
    { control && <Stack direction='column' justifyContent='space-between' sx={{ mr: '0.5em !important' }}>{control}</Stack> }
  </Stack>)
}

interface RowsParams {
  rows: ShippingTruckRunList[]
  ctrl?: boolean
  approve?: (id: Id) => void
  down?: (id: Id) => void
  up?: (id: Id) => void
  cancel?: (id: Id) => void
  onClickRow?: (id: Id) => void
}
const Rows = ({ rows, ctrl, approve, down, up, cancel, onClickRow }: RowsParams) => {
  return (<Stack direction='column' gap={1} >
  { rows.map((item) => <Row
      data={item}
      key={item.id}
      onClick={onClickRow}
      control={
        ctrl
          ? <>
            { approve && <SquareButton variant='contained' size='small' color='primary' onClick={() => approve(item.id)} >
              <Check style={{ color: '#ffffff' }}/>
            </SquareButton> }
            { down && <SquareButton variant='outlined' size='small' style={{ borderColor: '#B2B2B2' }} onClick={() => down(item.id)} >
              <ArrowDownward style={{ color: '#B2B2B2' }}/>
            </SquareButton> }
            { up && <SquareButton variant='outlined' size='small' style={{ borderColor: '#B2B2B2' }} onClick={() => up(item.id)} >
              <ArrowUpward style={{ color: '#B2B2B2' }}/>
            </SquareButton> }
            { cancel && <SquareButton variant='outlined' size='small' style={{ borderColor: '#EE6A5F' }} onClick={() => cancel(item.id)} >
              <Clear style={{ color: '#EE6A5F' }}/>
            </SquareButton> }
          </>
          : undefined
      }
    />) }
  </Stack>)
}

interface CBoxTitleProps {
  title: ReactNode
  chipLabel?: ReactNode
  chipColor?: 'green' | 'grey'
}
const CBoxTitle = ({ title, chipLabel, chipColor }: CBoxTitleProps) => {
  return (<Stack direction='row' alignItems='center' spacing={1}>
    <div>{title}</div>
    { chipLabel && <ChipStatus color={chipColor} label={chipLabel} style={{ minHeight: '14px', maxHeight: '14px' }} /> }
  </Stack>)
}

export interface ResponseCardParam {
  data: ResponseItem
  onSelectBid: (bid: Bid) => void
  approve?: (truckRunId: Id) => void
  approveAll: (bidId: Id) => void
  down?: (bidId: Id, truckRunId: Id) => void
  up?: (bidId: Id, truckRunId: Id) => void
  cancel?: (truckRunId: Id) => void
  onClickTruckRun?: (id: Id) => void
  viewBlackList?: boolean
}

export default function ResponseCard ({ data, onSelectBid, approve, approveAll, down, up, cancel, onClickTruckRun, viewBlackList }: ResponseCardParam) {
  return (<RBox >
    <Stack direction='row' spacing={2} borderBottom={BORDER} justifyContent='space-between' padding='1em 1.5em' alignItems='center'>
      <Stack direction='column'>
        <Typography fontSize='16px' fontWeight={700}>{data.bid.num}</Typography>
        <Typography fontSize='12px' fontWeight={500} color='#B2B2B2'>{data.bid.loadingAddress.city}-{data.bid.unloadingAddress.city}</Typography>
      </Stack>
      <Stack direction='row' spacing={1}>
        { !viewBlackList && <Button variant='contained' color='primary' size='small' onClick={() => approveAll(data.bid.id)} disabled={data.limit.length === 0} ><Check style={{ color: '#ffffff', marginRight: '10px' }}/>Подтвердить все отклики</Button> }
        <SquareButton variant='outlined' color='secondary' size='small' onClick={() => onSelectBid(data.bid)}><MoreHoriz style={{ width: '16px' }} /></SquareButton>
      </Stack>
    </Stack>
    <Box sx={{ '& div.collapseBox_content': { padding: '0.5em' } }}>
      { viewBlackList
        ? data.blackList && <CollapseBoxTruckRun
          title={<CBoxTitle
            title='Черный список'
            chipColor='grey'
            chipLabel={data.blackList.length > 1 ? data.blackList.length : undefined}
          />}
          disabled={data.blackList.length === 0}
          collapse={data.blackList.length !== 1}
        >
          <Rows rows={data.blackList} ctrl onClickRow={onClickTruckRun} approve={approve} cancel={cancel} />
        </CollapseBoxTruckRun>
        : <>
            <CollapseBoxTruckRun
              title={<CBoxTitle
                title='Подтвержденные'
                chipLabel={data.freeSpace ? <>{data.freeSpace.reserved} / {data.freeSpace.totalSeats} </> : undefined}
                chipColor='green'
              />}
              disabled={data.confirmed.length === 0}
              collapse={data.confirmed.length !== 1}
            >
              <Rows rows={data.confirmed} onClickRow={onClickTruckRun} />
            </CollapseBoxTruckRun>
            <CollapseBoxTruckRun
              title={<CBoxTitle
                title='Лимит машино-мест'
                chipColor='grey'
                chipLabel={data.limit.length > 1 ? data.limit.length : undefined}
              />}
              disabled={data.limit.length === 0}
              collapse={data.limit.length !== 1}
            >
              <Rows rows={data.limit} ctrl approve={approve} down={(truckRunId) => down && down(data.bid.id, truckRunId)} onClickRow={onClickTruckRun} />
            </CollapseBoxTruckRun>
            <CollapseBoxTruckRun
              title={<CBoxTitle
                title='Выше лимита'
                chipColor='grey'
                chipLabel={data.overLimit.length > 1 ? data.overLimit.length : undefined}
              />}
              disabled={data.overLimit.length === 0}
              collapse={data.overLimit.length !== 1} >
              <Rows rows={data.overLimit} ctrl cancel={cancel} up={(truckRunId) => up && up(data.bid.id, truckRunId)} onClickRow={onClickTruckRun} />
            </CollapseBoxTruckRun>
          </>}
    </Box>
  </RBox>)
}
