import { NotifyEvent } from '.'
import { useEffect } from 'react'

export interface Data {
  bidNum: string,
  message: string
}

const SLUGS = ['bids_bid_blocked_manual_fill', 'bids_bid_unblocked_manual_fill']

export default function BidManualFill ({ slug, data, toast } :NotifyEvent) {
  useEffect(() => {
    if (!slug || !SLUGS.includes(slug)) {
      return
    }

    if (toast === undefined) {
      return
    }

    if (data === undefined) {
      return
    }

    const { message } = data as Data

    toast(message, 'info')
  }, [slug, data, toast])

  return (<></>)
}
