import { cleanMaskNum, cleanMaskNumAlpha, ORGANIZATION_BIK_MASK, ORGANIZATION_CHECK_ACC_NUMBER_MASK, ORGANIZATION_CORR_ACC_NUMBER_MASK, ORGANIZATION_INN_MASK, ORGANIZATION_KPP_MASK, ORGANIZATION_OGRN_MASK, ORGANIZATION_OKPO_MASK } from 'common/Mask/Organization'
import { Meta } from 'entity/Meta'
import { ConsignorConsigneeForCreate } from 'api/consignorConsignee'

const consignorConsignee = new Meta<Omit<ConsignorConsigneeForCreate, 'type'>>(
  {
    typeLegalPerson: { title: 'Тип организации' },
    phones: { title: 'Телефоны' },
    emails: { title: 'Email' },
    shortName: { title: 'Краткое ​наименование организации' },
    fullName: { title: 'Полное ​наименование организации' },
    inn: { title: 'ИНН', mask: ORGANIZATION_INN_MASK, cleanMask: cleanMaskNum },
    kpp: { title: 'КПП', mask: ORGANIZATION_KPP_MASK, cleanMask: cleanMaskNumAlpha },
    okpo: { title: '​ОКПО', mask: ORGANIZATION_OKPO_MASK, cleanMask: cleanMaskNum },
    ogrn: { title: 'ОГРН', mask: ORGANIZATION_OGRN_MASK, cleanMask: cleanMaskNum },
    correspondentAccountNumber: { title: 'Номер корреспондентского счета', mask: ORGANIZATION_CORR_ACC_NUMBER_MASK, cleanMask: cleanMaskNum },
    checkingAccountNumber: { title: 'Номер расчетного счета', mask: ORGANIZATION_CHECK_ACC_NUMBER_MASK, cleanMask: cleanMaskNum },
    bik: { title: 'БИК', mask: ORGANIZATION_BIK_MASK, cleanMask: cleanMaskNum },
    bankName: { title: '​Наименование банка' },
    legalAddress: { title: 'Юридический адрес' }
  }
)

export default consignorConsignee
