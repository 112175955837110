import { Trailer } from 'api/trailer'
import { CarNumberIsRegionFirst } from 'common/Mask/CarNumberRegionMask'
import getRegionNumber from 'common/getRegionNumber'

type Options = Partial<{
  color: boolean
  placeholder?: string
}>

export default function trailerName (trailer?: Partial<Trailer>, { color = false, placeholder = 'Без прицепа' }: Options = {}): string {
  if (!trailer) {
    return placeholder
  }

  const numberRegion = getRegionNumber(trailer.numberType) ?? trailer.numberRegion

  const short = CarNumberIsRegionFirst(trailer.numberType)
    ? `${numberRegion}${trailer.number}`
    : `${trailer.number} ${numberRegion}`

  return color && trailer.color ? `${short} ${trailer.color}` : short
}
