import './styles.sass'

export function AuthLayout ({ content }: { content: JSX.Element}) {
  return (
    <div className='authLayout'>
      <div className='authLayout__logo'><img src={process.env.PUBLIC_URL + '/logo_full.png'} alt='Астон' height='48px' /></div>
      <div className='authLayout__content'>{ content }</div>
    </div>
  )
}
