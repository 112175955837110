import { mainService } from 'api/service'
import { RequireOnly } from 'util/type/RequireOnly'
import { Id } from 'api/Id'
import { Passport, PassportForCreate } from './passport'
import { RejectionExpand } from './rejection'
import { BlackListEntry } from './black-list'
import { Optional } from 'util/type/Optional'
import { Vehicle } from './vehicle'
import { Trailer } from './trailer'

export enum DriverStatus {
  new = 'new',
  active = 'active',
  blocked = 'blocked',
  archive = 'archive',
  needsAttention = 'needs_attention',
}

export interface VehicleTrailer {
  vehicleId: Id
  trailerId?: Id
  vehicle?: Vehicle
  trailer?: Trailer
}

export interface Driver {
  id: Id
  status: DriverStatus
  familyName: string
  firstName: string
  secondName: string
  phone: string
  email: string
  consent: boolean
  permitNumber: string
  passport: Passport & { id: Id } | null
  scanPermit: string
  scanPassport1: string
  scanPassport2: string
  scanHireOrder: string
  scanContract: string
  createTs: number
  truckRuns?: Id[]
  archiveDate?: number
  archiveComment?: string
  blackList?: BlackListEntry[]
  onlyVehicleTrailer?: boolean
  vehicleTrailer?: VehicleTrailer[]
  isCarrier?: boolean
}

export type DriverAll = Driver & {
  owner: string
  rejection?: RejectionExpand
  additionalData?: {
    orgShortName: string
    passportSeriesAndNum: string
    ownerId: string
  }
}

export type PassportForDriverCreate = PassportForCreate
type Update = Omit<Driver, 'archiveDate' | 'archiveComment' | 'truckRuns' | 'blackList' | 'status' | 'createTs' | 'passport' | 'onlyVehicleTrailer'> & {
  passport?: PassportForDriverCreate
}
type UpdateAll = Omit<DriverAll, 'archiveDate' | 'archiveComment' | 'truckRuns' | 'blackList' | 'createTs' | 'passport'>

export type DriverForCreate = Optional<Omit<Update, 'id'>, 'scanHireOrder' | 'scanContract' | 'secondName'>
export type DriverForUpdate = RequireOnly<Update, 'id'>
export type DriverAllForCreate = Omit<UpdateAll, 'id'>
export type DriverAllForUpdate = RequireOnly<UpdateAll, 'id'>

export interface DriverAllFilter {
  owner?: string
  status?: DriverStatus
  loadingTs?: number
  bidId?: Id
  includeAdditionalData?: boolean
  includeVehicleTrailerData?: boolean
}

export interface DriverArchiveParams {
  id: Id
  date: number
  comment?: string
}

export type DriverListParams = {
  filters?: {
    status?: DriverStatus
    involvedInShippingAndFree?: {
      loadingTs: number
      bidId: Id
    },
  }
}

export interface DriverCreateResult {
  success: boolean
  id: Id | null
  conflicts?: {
    double?: boolean
    eqPassport?: boolean
    eqFio?: boolean
  }
}

export type DriverAllCreateResult = DriverCreateResult

const request = (method: string, params: object) => mainService.request(`driver.${method}`, params)

export const driverArchive = (params: DriverArchiveParams) => request('archive', params) as Promise<boolean>
export const driverCreate = (params: DriverForCreate) => request('create', params) as Promise<DriverCreateResult>
export const driverUpdate = (params: DriverForUpdate & { onlyVehicleTrailer?: boolean }) => request('update', params) as Promise<boolean>
export const driverGet = (id: Id) => request('get', { id }) as Promise<Driver | null>
export const driverList = (params: DriverListParams = {}) => request('list', params) as Promise<Driver[]>

export const driverAllArchive = (params: DriverArchiveParams) => request('all.archive', params) as Promise<boolean>
export const driverAllCreate = (params: DriverAllForCreate) => request('all.create', params) as Promise<DriverAllCreateResult>
export const driverAllUpdate = (params: DriverAllForUpdate) => request('all.update', params) as Promise<boolean>
export const driverAllGet = (id: Id) => request('all.get', { id }) as Promise<DriverAll | null>
export const driverAllList = (params: DriverAllFilter = {}) => request('all.list', params) as Promise<DriverAll[]>
export const driverAllDelete = (id: Id) => request('all.delete', { id }) as Promise<boolean>
