export const KOPECKS_IN_RUBLE = 100

export function toKopeck (v: number): number {
  return Math.trunc(v * KOPECKS_IN_RUBLE)
}

export function toRuble (v: number): number {
  return v / KOPECKS_IN_RUBLE
}

export function costTonKm (cost: number, distance: number): number {
  return cost / distance * 1000
}
