import './styles.sass'
import { styled, Theme, CSSObject, useTheme } from '@mui/material/styles'
import MuiDrawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import { useMemo, useState } from 'react'
import { Box, Button, Divider, Stack, Typography } from '@mui/material'
import { FirstPageOutlined, LastPageOutlined } from '@mui/icons-material'
import { useAuthContext } from 'AuthContext'
import { getNameByUserInterfaces } from 'entity/Interface'
import { personName } from 'util/personName'
import { useMainRoutes } from 'routes'
import { NavLink, useLocation } from 'react-router-dom'
import clsx from 'clsx'
import { IRoute } from 'routes/types'
import Tooltip from 'ui/Tooltip'
import Connection from 'ui/Connection'
import { UserStatus } from 'api/UserStatus'

const drawerWidth = '221px'

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`
  }
})

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  marginLeft: '15px',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar
}))

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme)
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme)
    })
  })
)

const sortItems = (a: IRoute, b: IRoute) => {
  if (a.ordering === undefined || b.ordering === undefined) {
    return 0
  }

  return b.ordering - a.ordering
}

const LinkList = ({ items, pathname, open }: {items: IRoute[], pathname: string, open: boolean}) => {
  const theme = useTheme()

  return (
    items.length > 0
      ? <List>
        { items.sort(sortItems).map(({ title, path, icon, disabled }) => {
          const active = pathname.includes(path)
          const Button = () => <ListItemButton
            disabled={disabled}
            sx={{
              minHeight: 48,
              justifyContent: open ? 'initial' : 'center',
              ml: '8px',
              mr: '8px',
              borderRadius: '3px',
              background: active ? theme.palette.primary.main : undefined,
              color: active ? theme.palette.primary.contrastText : theme.palette.secondary.contrastText,
              '&:hover': {
                background: active ? theme.palette.primary.main : undefined,
                color: active ? theme.palette.primary.contrastText : undefined
              }
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : 'auto',
                justifyContent: 'center'
              }}
              className={clsx('sidebar__icon', { sidebar__iconActive: active })}
            >
            { icon ?? <></> }
            </ListItemIcon>
            { open && <Typography fontSize='14px' fontWeight={active ? 600 : 400} >{title}</Typography> }
          </ListItemButton>
          return (
            <ListItem key={path} disablePadding sx={{ display: 'block' }}>
              <Tooltip title={<Typography fontSize='14px' fontWeight={600} >{title}</Typography>} arrow placement='right' view={!open}>
                { disabled
                  ? <Button/>
                  : <NavLink to={path} style={{ textDecoration: 'none' }}><Button/></NavLink>
                }
              </Tooltip>
            </ListItem>
          )
        })}
      </List>
      : <></>
  )
}

export default function Sidebar () {
  const { currentInterface, profile, phone, userStatus } = useAuthContext()
  const [open, setOpen] = useState(true)
  const { routesMap } = useMainRoutes()
  const { pathname } = useLocation()

  const groups = useMemo(() => {
    return routesMap.getGroupRoutesByUserInterface(currentInterface)
  }, [currentInterface, routesMap])

  const items = useMemo(() => {
    return routesMap.getLinksByUserInterface(currentInterface, userStatus !== UserStatus.active)
  }, [currentInterface, routesMap, userStatus])

  const handleDrawer = () => {
    setOpen(prev => !prev)
  }

  return (<>
      <Drawer variant='permanent' open={open} className='sidebar'>
        <Connection />
        <DrawerHeader className='sidebar__brand' style={{ marginLeft: !open ? '10px' : undefined }}>
          <>
            { open
              ? <img src={process.env.PUBLIC_URL + '/logo_full.png'} alt='' />
              : <img src={process.env.PUBLIC_URL + '/logo.png'} alt='' /> }
          </>
        </DrawerHeader>
        <Box flexGrow={3} className='sidebar__groupItems'>
          { groups.filter(item => typeof item === 'number').map((group) => <div className='sidebar__groupItems_item' key={group}>
            <LinkList items={items.filter(item => item.group === group)} pathname={pathname} open={open} />
            <Divider className='sidebar__groupItems_item_divider'/>
          </div>)}
            <LinkList items={items.filter(item => item.group === undefined)} pathname={pathname} open={open} />
        </Box>
        <Box>
          <LinkList items={items.filter(item => item.group === 'bottom')} pathname={pathname} open={open} />
        </Box>
        <Box borderTop='1px solid #E2E8F0' minHeight='9%' >
          <Stack flexDirection='row' spacing={2} justifyContent='space-between' alignItems='center' m='1em'>
            { open && <Box>
              <Typography fontSize='15px' fontWeight={500} >{ profile && personName(profile, phone) }</Typography>
              <Typography fontSize='13px' fontWeight={400} color='#B2B2B2' >{ currentInterface && getNameByUserInterfaces(currentInterface) }</Typography>
            </Box> }
            <Button
              size='small'
              variant='outlined'
              onClick={handleDrawer}
              color='secondary'
              sx={{
                borderColor: '#EBEBEB',
                borderRadius: '3px',
                background: '#F7F7F7',
                minWidth: '32px',
                maxHeight: '32px'
              }}
            >
              {open ? <FirstPageOutlined /> : <LastPageOutlined />}
            </Button>
          </Stack>
        </Box>
      </Drawer>
      </>
  )
}
