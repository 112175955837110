import './styles.sass'
import { useCallback, useEffect, useState } from 'react'
import NavigatePanel from 'ui/NavigatePanel'
import { BlackListEntry, BlackListType, BlackListStatus, blackListList } from 'api/black-list'
import DataTable from 'ui/DataTable'
import { useNavigate, useParams } from 'react-router-dom'
import { isEnum } from 'util/isEnum'
import { useMainRoutes } from 'routes'

const DEFAULT_TYPE = BlackListType.driver

const typeMap = new Map<BlackListType, string>()
  .set(BlackListType.driver, 'Водители')
  .set(BlackListType.vehicle, 'ТС')

export default function AdminBlackList () {
  const [rows, setRows] = useState<BlackListEntry[]>([])
  const { links } = useMainRoutes()
  const navigate = useNavigate()
  const { type } = useParams()
  const filterType = isEnum(BlackListType)(type) ? type : DEFAULT_TYPE

  const init = useCallback(() => {
    blackListList({ type: filterType }).then(setRows)
  }, [filterType])

  useEffect(() => { init() }, [init])

  const title = 'Черный список'

  return (<>
    <div className='adminBlackList'>
      <NavigatePanel
        title={title}
        breadcrumbs={{
          items: [{ title }]
        }}
        tabs={{
          items: [...typeMap].map(([value, label]) => ({ label, value })),
          value: filterType,
          onChange: value => navigate(links.ADMIN_BLACKLIST_PAGE + (value === DEFAULT_TYPE ? '' : `/${value}`))
        }}
      />
      <div className='adminBlackList__body'>
        <div className='adminBlackList__body_customer'>
          <DataTable
            columns = { [
              {
                id: 'externalId',
                label: 'ID'
              },
              {
                id: 'status',
                label: 'Статус',
                format: (value) => {
                  const status = value as BlackListStatus
                  if (status === BlackListStatus.ban) {
                    return <>Полный запрет на въезд</>
                  } else if (status === BlackListStatus.control) {
                    return <>На особом контроле (без запрета на въезд)</>
                  }

                  return <></>
                }
              },
              {
                id: 'object',
                label: 'Объект'
              },
              {
                id: 'description',
                label: 'Описание'
              }
            ] }
            rows = { (start, end) => rows.slice(start, end) }
            rowsCount = { rows.length }
          />
        </div>
      </div>
    </div>
  </>)
}
