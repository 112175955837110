import { AddressPointType } from 'api/address'
import Select from 'ui/Select'

interface Params {
  value?: AddressPointType
  onChange: (value: AddressPointType) => void
  width?: string
  disabled?: boolean
}

const SelectPointType = ({ value, width, disabled, onChange }: Params) => {
  return <Select
    label='Тип'
    options={[
      { value: AddressPointType.grainElevator, name: 'Элеваторы' },
      { value: AddressPointType.farming, name: 'Франко-хозяйства' }
    ]}
    value={value ?? AddressPointType.grainElevator}
    onChange={onChange}
    disabled={disabled}
    disableClearable={true}
    width={width}
  />
}

export default SelectPointType
