import './styles.sass'
import Box from '@mui/material/Box'
import Modal from 'ui/Modal'
import { useState } from 'react'
import { Button, Stack } from '@mui/material'
import { ShippingTruckRun, shippingAddComment } from 'api/shipping'
import TextField from 'ui/TextField'
import { Send } from '@mui/icons-material'
import { isId } from 'api/Id'
import { useAuthContext } from 'AuthContext'

export interface Params {
  data?: ShippingTruckRun
  onClose: () => void
  onSuccess?: () => void
}

export default function CarrierCommentModal ({ data, onClose, onSuccess }: Params) {
  const [comment, setComment] = useState<string>('')
  const [commentError, setCommentError] = useState<string>()
  const { handleResponseSuccess } = useAuthContext()

  const handleCancel = () => {
    setComment('')
    setCommentError(undefined)
    onClose()
  }

  const add = async () => {
    const { id } = data ?? {}

    if (!isId(id)) {
      return
    }

    if (comment.trim().length === 0) {
      setCommentError('Обязательно к заполнению')
      return
    }

    const success = await shippingAddComment(id, comment)

    if (success) {
      handleResponseSuccess('Комментарий добавлен')
      onSuccess && onSuccess()
      handleCancel()
    }
  }

  return (
    <Modal
      maxWidth='sm'
      title={'Добавить комментарий'}
      onClose={handleCancel}
      open={!!data}
      content={
        <Box minWidth='500px'>
          <Stack direction='column' gap={1}>
            <TextField
              label='Комментарий'
              value={comment}
              onChange={({ target: { value } }) => setComment(value)}
              rows={3}
              errorMessage={commentError}
            />
          </Stack>
        </Box>
      }
      actions={<>
        <Stack direction='row' justifyContent='space-between' spacing={2} sx={{ width: '100%' }}>
          <Button variant='outlined' color='secondary' size='small' onClick={handleCancel}>Отмена</Button>
          <Button variant='contained' size='small' onClick={add} >Отправить <Send sx={{ ml: '10px' }} /></Button>
        </Stack>
      </>}
    />
  )
}
