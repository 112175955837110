import { mainService } from 'api/service'
import { RequireOnly } from 'util/type/RequireOnly'
import { Id } from 'api/Id'
import { Address } from 'api/address'
import { VehicleType } from 'api/vehicle'
import { Forwarder } from './forwarder'
import { ShippingTruckRunList } from './shipping'
import { Customer } from './customer'
import { ConsignorConsignee } from './consignorConsignee'
import { Indicator } from 'ui/LoadingDayIndicator'
import { ProfileAll } from './profile'

export enum BidStatus {
  new = 'new',
  publish = 'publish',
  fulfilled = 'fulfilled',
  archive = 'archive',
}
export interface BidFreeSpace {
  shipmentTs: number // Дата погрузки
  seats: number // Количество мест (тип поля число должно автоматически уменьшаются при установки определенного статуса отклику водителя, необходимо выяснить на каком статусе необходимо уменьшать это число)
  totalSeats: number
  indicator?: Indicator
}

export interface BidStatItem {
  count: number
  weight: number
}

export interface BidWeighing {
  weighingScaleLength?: number
  weighingScaleMaxLoad?: number
  heightRestriction?: number
}

export interface Bid {
  id: Id
  status: BidStatus
  num: string
  loadingAddress: Address // Адреса храним в нормализованном виде от яндекса (возможно адреса необходимо вынести в отдельную сущность “маршруты” может в будущем будет несколько точек для разгрузки или чтото типо того)
  unloadingAddress: Address
  typeVehicle: VehicleType[]
  beginTs?: number // Дата начала перевозки (подразумевается что совпадает с датой начала погрузки)
  endTs?: number // Дата окончания перевозки
  cargo: BidCargo
  totalWeight: number // Общий вес груза (храним в кг)
  distance: number // Расстояние (Заполняется или высчитывается на основании маршрута?)
  costTon: number // Стоимость перевозки тонны
  costTonKm: number // Стоимость тн/км (Высчитывается на беке при создании или заполняется вручную?, какое количество знаков после запятой, всегда в рублях или нужен пересчет в тысячи и миллионы рублей?)
  costCargo: number // TODO --- Необходимо переименовать cost Cargo во что-то более приемлемое (Стоимость груза за 1 тн)
  loadingMethod: string // Способ погрузки (пока храним в виде строки, но возможно это справочник или enum)
  unloadingMethod: string // Аналогично loadingMethod
  customer: Customer
  consignee: ConsignorConsignee,
  consignor: ConsignorConsignee,
  plusTen: boolean
  freeSpace: BidFreeSpace[]
  noСlaim?: boolean
  forwarders?: Forwarder[]
  statistics?: {
    seats: number
    filledSeats: number
    confirmed: BidStatItem
    truckRuns: {
      way: BidStatItem
      arrived: BidStatItem
      outdated: BidStatItem
      completed: BidStatItem
      confirmed: BidStatItem
      archived: BidStatItem
      archiveProblem: BidStatItem
      archiveRefuse: BidStatItem
      totalSeats: number
      totalWeight: number
      totalFacticalWeight: number
      totalFacticalSeats: number
      totalRedirected: number
      remainingWeight: number
      createdByEmployee: number
    }
  }
  loadingWeighing: BidWeighing
  unloadingWeighing: BidWeighing
  underweight?: number
  carrier?: ProfileAll
  autoFill?: boolean
  blockedManualFill?: boolean
  blockedManualFillComment?: string
}

export interface BidSlot {
  shipmentTs: number
  seats: number
  filledSeats: number
}

export enum BidMethodType {
  loading = 'loading',
  unloading = 'unloading',
}

export interface BidMethod {
  type: BidMethodType
  slug: string
  name: string
}

export interface BidCargo {
  slug: string
  name: string
  shortName?: string
  num?: string
  externalId?: string
  productBaseId?: string
  averageWeight?: number
  hidden?: boolean
  cargoType?: BidCargoType
}

export interface BidCargoListParams {
  filters?: {
    hidden?: boolean
    onlyGroups?: boolean
  }
}

export type BidMethodForUpdate = Partial<BidMethod>
export type BidCargoForUpdate = Partial<Omit<BidCargo, 'cargoType'>> & {
  cargoTypeSlug?: string
}

export interface BidCargoType {
  slug: string
  name: string
  underweight?: number
  invoiceTplFilename?: string
}

export type BidCargoTypeForUpdate = Partial<BidCargoType>

type ForUpdate = Omit<Bid, 'loadingAddress' | 'unloadingAddress' | 'freeSpace' | 'typeVehicle' | 'consignee' | 'consignor' | 'forwarders' | 'status' | 'costTonKm' | 'num' | 'costWeight' | 'costFinalWeight' | 'statistics' | 'customer' | 'cargo' | 'carrier'> & {
  cargo: string
  customerId: Id,
  consigneeId: Id
  consignorId: Id
  loadingAddressId: Id
  unloadingAddressId: Id
  typeVehicleSlugs: string[]
  carrierId?: Id
}

export type BidForCreate = Omit<ForUpdate, 'id' | 'noСlaim'> & {
  noСlaim: boolean
  loadingWeighing: BidWeighing
  unloadingWeighing: BidWeighing
}

export type BidForUpdate = RequireOnly<ForUpdate, 'id'>
export type BidSlotForUpsert = { bidId: Id, slots: Omit<BidSlot, 'filledSeats'>[] }

export enum BidType {
  managed = 'managed',
  relay = 'relay',
}

export interface BidAllListParams {
  filters?: {
    type?: BidType,
    status?: BidStatus[]
    onlyFreeSpacesAndNew?: boolean
  }
}

const request = (method: string, params: object) => mainService.request(`bid.${method}`, params)
export const bidGet = (id: Id) => request('get', { id }) as Promise<Bid | null>
export const bidList = (status: BidStatus) => request('list', { status }) as Promise<Bid[]>
export const bidAllCreate = (params: BidForCreate) => request('all.create', params) as Promise<Id | null>
export const bidAllUpdate = (params: BidForUpdate) => request('all.update', params) as Promise<boolean>
export const bidAllClone = (id: Id) => request('all.clone', { id }) as Promise<Id | null>
export const bidAllClose = (id: Id) => request('all.close', { id }) as Promise<boolean>
export const bidAllGet = (id: Id) => request('all.get', { id }) as Promise<Bid | null>
export const bidAllList = (params: BidAllListParams = {}) => request('all.list', params) as Promise<Bid[]>
export const bidAllDelete = (id: Id) => request('all.delete', { id }) as Promise<{
  success: boolean
  conflicts?: ShippingTruckRunList[]
}>
export const bidSlotList = (bidId: Id) => request('slot.list', { bidId }) as Promise<BidSlot[]>
export const bidSlotUpsert = (params: BidSlotForUpsert) => request('slot.upsert', params) as Promise<boolean>
export const bidAllChangeStatus = (id: Id, status: BidStatus) => request('all.change-status', { id, status }) as Promise<void>
export const bidMethodPut = (params: BidMethodForUpdate) => request('method.put', params) as Promise<{upserted: boolean, modified: boolean}>
export const bidMethodDelete = (slug: string) => request('method.delete', { slug }) as Promise<boolean>
export const bidMethodList = (type?: BidMethodType) => request('method.list', { type }) as Promise<BidMethod[]>
export const bidCargoPut = (params: BidCargoForUpdate) => request('cargo.put', params) as Promise<{upserted: boolean, modified: boolean}>
export const bidCargoDelete = (slug: string) => request('cargo.delete', { slug }) as Promise<boolean>
export const bidCargoList = (params: BidCargoListParams = {}) => request('cargo.list', params) as Promise<BidCargo[]>

export const bidCargoTypePut = (params: BidCargoTypeForUpdate) => request('cargo-type.put', params) as Promise<{upserted: boolean, modified: boolean}>
export const bidCargoTypeDelete = (slug: string) => request('cargo-type.delete', { slug }) as Promise<boolean>
export const bidCargoTypeList = () => request('cargo-type.list', {}) as Promise<BidCargoType[]>
