import { Button } from '@mui/material'
import InfoCard from 'ui/InfoCard'
import './styles.sass'
import { vehicleAxlesPut, vehicleAxlesDelete, vehicleAxlesList, VehicleAxles as Origin } from 'api/vehicle'
import { useCallback, useEffect, useState } from 'react'
import { Box, Stack } from '@mui/system'
import SquareButton from 'ui/SquareButton'
import { Delete, Save } from '@mui/icons-material'
import TextField from 'ui/TextField'
import CollapseBox from 'ui/CollapseBox'
import { useAuthContext } from 'AuthContext'

type ForUpdate = Partial<Origin>

const Row = ({ data, onChange }: { data: ForUpdate, onChange: () => void}) => {
  const [name, setName] = useState<string>(data.name ?? '')
  const { handleResponseSuccess } = useAuthContext()

  return (<>
    <Stack direction='row' spacing={2} mb='10px' alignItems='center'>
      <TextField
        width='50%'
        value={name}
        onChange={(e) => {
          setName(e.target.value.trim())
        }}
      />
      <SquareButton
        variant='contained'
        onClick={() => {
          vehicleAxlesPut(name, data.slug)
            .then(({ modified, upserted }) => {
              if (modified || upserted) {
                handleResponseSuccess('Данные сохранены')
                onChange()
              }
            })
        }}
      ><Save sx={{ width: '16px' }} /></SquareButton>
      { data.slug !== undefined && <SquareButton
        variant='contained'
        color='secondary'
        onClick={() => {
          if (data.slug) {
            vehicleAxlesDelete(data.slug)
              .then(result => {
                if (result) {
                  onChange()
                }
              })
          }
        }}
      ><Delete sx={{ width: '16px' }} /></SquareButton>}
    </Stack>
  </>)
}

export default function VehicleAxles () {
  const [list, setList] = useState<ForUpdate[]>([])
  const init = useCallback(() => vehicleAxlesList().then(setList), [])
  useEffect(() => { init() }, [init])

  return (
    <InfoCard
      title='ТС и Прицепы - Количество осей'
      actions={<Button
        variant='outlined'
        size='small'
        onClick={() => {
          setList(prev => [...prev, {}])
        }}
      >Добавить</Button>}
    ><>
      <CollapseBox title='Количество осей'><>
      { list.filter(item => item.slug !== undefined).map(item => <Row data={item} key={item.slug} onChange={() => init()}/>)}
      </></CollapseBox>
      <Box>
      { list.filter(item => item.slug === undefined).map((item, idx) => <Row data={item} key={idx} onChange={() => init()}/>)}
      </Box>
    </></InfoCard>
  )
}
