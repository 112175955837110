import { Id } from 'api/Id'
import { useLocation, useNavigate } from 'react-router-dom'
import { useMainRoutes } from 'routes'
import { Params as TabsRowParams } from 'ui/NavigatePanel/TabsRow'

export default function useTabs (carrier?: Id, docId?: Id): { tabs: TabsRowParams } {
  const { links } = useMainRoutes()
  const { pathname } = useLocation()
  const navigate = useNavigate()

  return {
    tabs: {
      value: pathname ?? '',
      items: carrier === undefined
        ? []
        : [
            {
              value: `${links.REGISTRY_CARRIER_PAGE}/${carrier}`,
              label: 'Общая информация'
            },
            {
              value: `${links.REGISTRY_CARRIER_PAGE}/${carrier}/drivers`,
              label: 'Водители'
            },
            {
              value: `${links.REGISTRY_CARRIER_PAGE}/${carrier}/vehicles`,
              label: 'Транспорт'
            },
            {
              value: `${links.REGISTRY_CARRIER_PAGE}/${carrier}/trailers`,
              label: 'Прицепы'
            }
          ],
      onChange: to => navigate(to),
      compare: (items, value) => {
        return items.findIndex(item => {
          const str = docId ? `${item.value}/${docId}` : item.value
          return str === value
        })
      }
    }
  }
}
