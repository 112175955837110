import './styles.sass'
import { useCallback, useEffect, useState } from 'react'
import { getNameByUserRole } from 'entity/User'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import DataTable from 'ui/DataTable'
import { User, userList, UserRole } from 'api/user'
import { UserStatus } from 'api/UserStatus'
import ChipUserStatus from 'common/ChipUserStatus'
import NavigatePanel from 'ui/NavigatePanel'
import { isEnum } from 'util/isEnum'
import { useMainRoutes } from 'routes'
import CreateModal from './CreateModal'
import { Button, Stack } from '@mui/material'
import Edit from '@mui/icons-material/Edit'
import ActionsMenu from 'ui/ActionsMenu'
import { ProfileAll, profileAllGetByPhone } from 'api/profile'
import ChangePhoneModal from './ChangePhoneModal'
import SettingsPhoneIcon from '@mui/icons-material/SettingsPhone'
import { useAuthContext } from 'AuthContext'

const tabs = new Map<UserRole, string>()
  .set(UserRole.administrator, 'Администраторы')
  .set(UserRole.chief_dispatcher, 'Старшие диспетчеры')
  .set(UserRole.dispatcher, 'Диспетчеры')
  .set(UserRole.chief_forwarder, 'Старшие экспедиторы')
  .set(UserRole.forwarder, 'Экспедиторы')
  .set(UserRole.carrier, 'Перевозчики')
  // .set(UserRole.driver, 'Водители')

const DEFAULT_ROLE = UserRole.administrator

export default function Users () {
  const [rows, setRows] = useState<User[]>()
  const [openCreateModal, setOpenCreateModal] = useState(false)
  const [editProfile, setEditProfile] = useState<Partial<ProfileAll>>()
  const [editUser, setEditUser] = useState<User>()

  const { roles } = useParams()
  const filterRole = isEnum(UserRole)(roles) ? roles : DEFAULT_ROLE

  const { phone: currentUser } = useAuthContext()

  const { links } = useMainRoutes()
  const navigate = useNavigate()

  const init = useCallback(() => {
    userList({ roles: filterRole === UserRole.administrator ? [UserRole.administrator, UserRole.root] : [filterRole] }).then(setRows)
  }, [filterRole])

  useEffect(() => {
    init()
  }, [init])

  const handleEdit = (phone: string) => {
    profileAllGetByPhone(phone)
      .then(result => {
        if (result === null) {
          setEditProfile({ phone })
        } else {
          setEditProfile(result)
        }

        setOpenCreateModal(true)
      })
  }

  return (
    <>
      <div className='users'>
        <NavigatePanel
          title='Пользователи'
          breadcrumbs={{
            items: [{ title: 'Пользователи' }]
          }}
          tabs={{
            items: [...tabs].map(([value, label]) => ({ label, value })),
            value: filterRole,
            onChange: value => navigate(links.USERS_PAGE + (value === DEFAULT_ROLE ? '' : `/${value}`)),
            scrollable: true
          }}
          actions={<Stack direction='row' spacing={2} justifyContent='end'>
            <Button variant='contained' color="primary" size='small' onClick={() => setOpenCreateModal(true)} >Создать пользователя</Button>
          </Stack>}
        />
        <div className='users__body'>
          <div className='users__body_table'>
            <DataTable
              columns = { [
                {
                  id: 'phone',
                  label: 'Телефон',
                  minWidth: 100
                },
                {
                  id: 'roles',
                  label: 'Роль в системе',
                  minWidth: 170,
                  format: (value) => <>{(value as UserRole[]).map(getNameByUserRole).join(', ')}</>
                },
                {
                  id: 'status',
                  label: 'Статус',
                  minWidth: 100,
                  format: (status) => <ChipUserStatus status={status as UserStatus} />
                },
                {
                  id: 'phone',
                  label: 'Логирование',
                  align: 'center',
                  minWidth: 10,
                  format: phone => <NavLink to='/logs' state={{ user: phone }}>История логов</NavLink>
                },
                {
                  id: ['id', 'phone', 'status'],
                  label: '',
                  align: 'right',
                  minWidth: 10,
                  format: (id, phone, status) => {
                    if (currentUser === phone) {
                      return <></>
                    }

                    if (status !== UserStatus.active) {
                      return <></>
                    }

                    return <ActionsMenu key={`${id}`} actions = {[
                      {
                        caption: 'Редактировать',
                        onClick: () => handleEdit(phone as string),
                        icon: <Edit />
                      },
                      {
                        caption: 'Изменить номер телефона',
                        onClick: () => setEditUser(rows?.find(item => item.phone === phone)),
                        icon: <SettingsPhoneIcon />
                      }
                    ]} />
                  }
                }
              ] }
              rows = { (start, end) => rows === undefined ? [] : rows.slice(start, end) }
              rowsCount = { rows === undefined ? 0 : rows.length }
            />
          </div>
        </div>
      </div>
      <CreateModal
        profile={editProfile}
        open={openCreateModal}
        onClose={() => {
          setOpenCreateModal(false)
          setEditProfile(undefined)
        }}
        onSuccess={() => init()}
      />
      <ChangePhoneModal
        user={editUser}
        onClose={() => {
          setEditUser(undefined)
        }}
        onSuccess={() => init()}
      />
    </>
  )
}
