import { mainService } from 'api/service'
import { Id } from 'api/Id'

export interface AstonTrafficReservedSlot {
  reservedId: string
  slotId: string
  from: number
  to: number
  QRCode: string
}

export enum DischargeType {
  aside = 1,
  back = 2,
}

export interface AstonTrafficSlot {
  id: Id
  from: number
  to: number
}

export type AstonTrafficReserveSlot = AstonTrafficSlot & {
  dischargeType: DischargeType
}

export type AstonTrafficSlotsList = {
  success: boolean
  result: AstonTrafficSlot[]
  conflicts?: {
    astonMessage?: string
    astonTrafficError?: boolean
  }
}

export type AstonTrafficReserveSlotResult = {
  success: boolean
  conflicts?: {
    astonMessage?: string
    hasReserved?: boolean
  }
}

export type AstonTrafficReserveCancelResult = {
  success: boolean
  conflicts?: {
    astonMessage?: string
  }
}

const request = (method: string, params: object) => mainService.request(`aston-traffic.${method}`, params)

export const astonTrafficSlotsList = (truckRunId: Id, unloadingTs: number) => request('slots-list', { truckRunId, unloadingTs }) as Promise<AstonTrafficSlotsList>
export const astonTrafficReserveSlot = (truckRunId: Id, slot: AstonTrafficReserveSlot) => request('reserve-slot', { truckRunId, slot }) as Promise<AstonTrafficReserveSlotResult>
export const astonTrafficReserveCancel = (truckRunId: Id, reservedId: string) => request('reserve-cancel', { truckRunId, reservedId }) as Promise<AstonTrafficReserveCancelResult>
