import { Address } from 'api/address'

export default function FullAddress ({ address: { name, addressOrig = '-' } }: { address: Address }) {
  // return (<>
  //   { name ? `${name}, ${address.region}, ` : `${address.region}, ` }
  //   { address.district ? `${address.district}, ` : '' }
  //   <b>
  //   { `${address.city}` }
  //   { address.street ? `, ${address.street}` : '' }
  //   { address.building ? `, ${address.building}` : '' }
  //   </b>
  // </>)
  return (<>{`${name}, ${addressOrig}`}</>)
}
