import { Button } from '@mui/material'
import InfoCard from 'ui/InfoCard'
import './styles.sass'
import { BidMethodForUpdate, BidMethodType, bidMethodList, bidMethodPut, bidMethodDelete } from 'api/bid'
import { useCallback, useEffect, useState } from 'react'
import { Box, Stack } from '@mui/system'
import SquareButton from 'ui/SquareButton'
import { Delete, Save } from '@mui/icons-material'
import Select from 'ui/Select'
import TextField from 'ui/TextField'
import CollapseBox from 'ui/CollapseBox'
import { useAuthContext } from 'AuthContext'

const getTypeName = (type: BidMethodType) => {
  switch (type) {
    case BidMethodType.loading:
      return 'Способ погрузки'
    case BidMethodType.unloading:
      return 'Способ разгрузки'
  }
}

const Row = ({ data, onChange }: { data: BidMethodForUpdate, onChange: () => void}) => {
  const [name, setName] = useState<string>(data.name ?? '')
  const [type, setType] = useState<BidMethodType | undefined>(data.type)
  const { handleResponseSuccess } = useAuthContext()
  return (<>
    <Stack direction='row' spacing={2} mb='10px' alignItems='center'>
      <Select
        label='Тип'
        options={Object.values(BidMethodType).map((value) => ({ value, name: getTypeName(value) }))}
        value={type}
        onChange={setType}
        width='20%'
      />
      <TextField
        label='Название'
        width='50%'
        value={name}
        onChange={(e) => {
          setName(e.target.value.trim())
        }}
      />
      <SquareButton
        variant='contained'
        onClick={() => {
          bidMethodPut({ slug: data.slug, name, type })
            .then(({ modified, upserted }) => {
              if (modified || upserted) {
                handleResponseSuccess('Данные сохранены')
                onChange()
              }
            })
        }}
      ><Save sx={{ width: '16px' }} /></SquareButton>
      { data.slug !== undefined && <SquareButton
        variant='contained'
        color='secondary'
        onClick={() => {
          if (data.slug) {
            bidMethodDelete(data.slug)
              .then(result => {
                if (result) {
                  onChange()
                }
              })
          }
        }}
      ><Delete sx={{ width: '16px' }} /></SquareButton>}
    </Stack>
  </>)
}

export default function BidMethods () {
  const [list, setList] = useState<BidMethodForUpdate[]>([])
  const init = useCallback(() => bidMethodList().then(setList), [])
  useEffect(() => { init() }, [init])

  return (
    <InfoCard
      title='Способы погрузки/разгрузки'
      actions={<Button
        variant='outlined'
        size='small'
        onClick={() => {
          setList(prev => [...prev, {}])
        }}
      >Добавить</Button>}
    ><>
      <CollapseBox title='Способы погрузки'><>
      { list.filter(item => item.type === BidMethodType.loading).map(item => <Row data={item} key={item.slug} onChange={() => init()}/>)}
      </></CollapseBox>
      <CollapseBox title='Способы разгрузки'><>
      { list.filter(item => item.type === BidMethodType.unloading).map(item => <Row data={item} key={item.slug} onChange={() => init()}/>)}
      </></CollapseBox>
      <Box>
      { list.filter(item => item.slug === undefined).map((item, idx) => <Row data={item} key={idx} onChange={() => init()}/>)}
      </Box>
    </></InfoCard>
  )
}
