import './styles.sass'
import NavigatePanel from 'ui/NavigatePanel'
import HistoryTable from 'ui/History'

export default function History () {
  const title = 'История изменений'

  return (
    <>
      <div className='history'>
        <NavigatePanel
          title={ title }
          breadcrumbs={{
            items: [{ title }]
          }}
        />
        <div className='history__body'>
          <div className='history__body_table'>
            <HistoryTable limit={ 10 } />
          </div>
        </div>
      </div>
    </>
  )
}
