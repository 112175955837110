import { idPattern } from 'api/Id'
import { RouteForCreate } from 'api/route'
import { makeJsonSchema, SimpleSchema } from './validate'

const simpleSchema: SimpleSchema<RouteForCreate> = {
  type: 'object',
  properties: {
    loadingAddressId: { type: 'string', pattern: idPattern },
    unloadingAddressId: { type: 'string', pattern: idPattern },
    distance: { type: 'number' },
    comment: { type: 'string' }
  }
}

const schema = makeJsonSchema<RouteForCreate>(simpleSchema)

export default schema
