import './styles.sass'
import { Box, Button, Container, Stack, Typography } from '@mui/material'
import { useState } from 'react'
import { useAuthContext } from '../../AuthContext'
import schema from 'validation/RecoveryPass'
import useValidate from 'validation/validate'
import InfoCard from 'ui/InfoCard'
import LinkButton from 'ui/LinkButton'
import PasswordField from 'ui/PasswordField'
import { userRecoveryPass } from 'api/user'

export default function RecoveryPass () {
  const { handleResponseFailure, logout } = useAuthContext()
  const [password, setPassword] = useState<string>('')
  const [password2, setPassword2] = useState<string>('')
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [success, setSuccess] = useState<boolean>(false)

  const { check, errors } = useValidate(schema)

  const submitHandler = () => {
    if (!check({ password, password2 })) {
      return
    }

    if (password === password2) {
      userRecoveryPass(password).then(result => {
        if (result) {
          setSuccess(true)
        } else {
          handleResponseFailure('Пароль не был изменен')
        }
      })
    } else {
      handleResponseFailure('Пароли не совпадают')
    }
  }

  return (
    <Container maxWidth='sm' sx={{ height: '30%' }}>
      { success
        ? <InfoCard>
          <Box maxWidth='567px'>
            <Typography fontWeight={700} fontSize='21px' >Новый пароль успешно создан</Typography>
            <Typography fontWeight={400} fontSize='14px' color='#B2B2B2' mb='1.5em'>Используйте новый созданный пароль для того, чтобы войти в систему.</Typography>
            <Button size="large" variant="contained" fullWidth onClick={ () => { logout() } }>Вернуться ко входу</Button>
          </Box>
        </InfoCard>
        : <InfoCard>
        <>
          <Typography fontWeight={700} fontSize='21px'>Создание нового пароля</Typography>
          <Stack mt='1.5em'>
            <PasswordField
              label='Пароль'
              name='password'
              type='password'
              placeholder='Придумайте новый пароль'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              showPassword={showPassword}
              onShowPassword={() => setShowPassword(prev => !prev)}
              errors={errors}
            />
          </Stack>
          <Stack mt='1.5em'>
            <PasswordField
              label='Подтверждение пароля'
              name='password2'
              type='password'
              placeholder='Подтвердите новый пароль'
              value={password2}
              onChange={(e) => setPassword2(e.target.value)}
              showPassword={showPassword}
              onShowPassword={() => setShowPassword(prev => !prev)}
              errors={errors}
            />
          </Stack>
          <Stack mt='2em'>
            <Button size='large' color='primary' variant='contained' onClick={ submitHandler }>Создать пароль</Button>
          </Stack>
          <Stack direction='row' justifyContent='space-between' spacing={2} mt='1.5em' >
            <LinkButton fullWidth variant='text' color='inherit' onClick={() => logout()} >Вернуться ко входу</LinkButton>
          </Stack>
        </>
      </InfoCard> }
    </Container>
  )
}
