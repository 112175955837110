import { toRuble } from './monetary'

const formatter = new Intl.NumberFormat('ru', {
  style: 'currency',
  currency: 'RUB'
})

export default function rub (cost: number): string {
  return formatter.format(toRuble(cost))
}
