import NavigatePanel from 'ui/NavigatePanel'
import './styles.sass'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Button, Stack } from '@mui/material'
import { Save } from '@mui/icons-material'
import Notifications from './Notifications'
import Settings from './Settings'
import { NotificationSettings, userSettingsGet, userSettingsPut } from 'api/notify'
import { useAuthContext } from 'AuthContext'

export enum Tabs {
  notifications = 'notifications',
  settings = 'settings'
}

const isTab = (v: unknown): v is Tabs => {
  return typeof v === 'string' && Object.values<string>(Tabs).includes(v)
}

const TABS_ITEMS = [
  { label: 'Уведомления', value: Tabs.notifications },
  { label: 'Настройки уведомлений', value: Tabs.settings }
]

export enum SEARCH_PARAMS {
  tab = 'tab'
}

export default function SystemNotifies () {
  const [currentTab, setCurrentTab] = useState<Tabs>(Tabs.notifications)
  const [settings, setSettings] = useState<NotificationSettings[]>()

  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { handleResponseSuccess } = useAuthContext()

  const changeTab = useCallback((value: string) => {
    if (isTab(value)) {
      setCurrentTab(value)
      navigate(`?${SEARCH_PARAMS.tab}=${value}`)
    }
  }, [navigate])

  useEffect(() => {
    const tab = searchParams.get(SEARCH_PARAMS.tab)
    if (isTab(tab)) {
      changeTab(tab)
    }
  }, [searchParams, changeTab])

  const initSettings = useCallback(() => userSettingsGet().then(setSettings), [])

  useEffect(() => {
    if (currentTab === Tabs.settings) {
      initSettings()
    }
  }, [currentTab, initSettings])

  const save = () => {
    if (settings === undefined) {
      return
    }

    userSettingsPut(settings).then(result => {
      if (result) {
        handleResponseSuccess('Настройки были сохранены')
        initSettings()
      }
    })
  }

  return (<div className='systemNotifies'>
    <NavigatePanel
      title='Уведомления'
      breadcrumbs={{
        items: [{ title: 'Уведомления' }]
      }}
      tabs={{
        items: TABS_ITEMS,
        value: currentTab,
        onChange: changeTab
      }}
      actions={ currentTab === Tabs.settings
        ? <Stack direction='row' spacing={2} justifyContent='end'>
            <Button variant='outlined' size='small' color='secondary' onClick={() => navigate(-1)} >
              Отменить
            </Button>
            <Button variant='contained' size='small' sx={{ background: '#6DCD45' }} onClick={save} >
              Сохранить <Save sx={{ width: '15px', height: '15px', ml: '10px' }}/>
            </Button>
          </Stack>
        : undefined
      }
    />
    <div className='systemNotifies__body'>
      <div className='systemNotifies__body_content'>
        { currentTab === Tabs.notifications && <Notifications /> }
        { currentTab === Tabs.settings && <Settings data={settings} setData={setSettings} /> }
      </div>
    </div>
  </div>)
}
