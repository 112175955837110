import { ShippingTruckRunList } from 'api/shipping'
import { TRUCK_RUN_XLSX_COLUMNS } from '../constants/truckRunXLSXColumns'
import { personName } from './personName'
import vehicleName from './vehicleName'
import trailerName from './trailerName'
import { toTon } from './weight'
import TsToFormatDate from './TsToFormatDate'
import { truckRunStatusMap } from '../common/TruckRunStatusMap'
import { sentToKisStatusMap } from '../ui/SentToKisStatus'
import { XLSXTableParams, XLSXTableRow, XLSXTableCell } from '../api/media'

function runTruckConvertToXLSXFormat (data: ShippingTruckRunList[]): XLSXTableParams {
  const convertedData: XLSXTableRow[] = data.map((item) => {
    const {
      carrierName,
      num,
      forwarder = '',
      driver,
      vehicle,
      trailer,
      weight,
      billNumber = '',
      finalWeight,
      status,
      sentToKisStatus,
      loadingTs,
      actualLoadingTs,
      unloadingTs
    } = item
    const loadingDate = actualLoadingTs || loadingTs
    const createCell = (value: string | number, title: string): XLSXTableCell => ({
      value,
      title
    })

    const cells: XLSXTableCell[] = [
      createCell(carrierName, TRUCK_RUN_XLSX_COLUMNS.carrierName),
      createCell(num, TRUCK_RUN_XLSX_COLUMNS.num),
      createCell(forwarder, TRUCK_RUN_XLSX_COLUMNS.forwarder),
      createCell(personName(driver), TRUCK_RUN_XLSX_COLUMNS.driver),
      createCell(vehicleName(vehicle), TRUCK_RUN_XLSX_COLUMNS.vehicle),
      createCell(trailerName(trailer), TRUCK_RUN_XLSX_COLUMNS.trailer),
      createCell((weight !== undefined ? `${toTon(weight)}` : ''), TRUCK_RUN_XLSX_COLUMNS.weight),
      createCell(TsToFormatDate(loadingDate, 'dd.MM.yyyy'), TRUCK_RUN_XLSX_COLUMNS.loadingDate),
      createCell(billNumber, TRUCK_RUN_XLSX_COLUMNS.billNumber),
      createCell((finalWeight !== undefined ? `${toTon(finalWeight)}` : ''), TRUCK_RUN_XLSX_COLUMNS.finalWeight),
      createCell((unloadingTs ? TsToFormatDate(unloadingTs, 'dd.MM.yyyy') : ''), TRUCK_RUN_XLSX_COLUMNS.unloadingTs),
      createCell((truckRunStatusMap.get(status) ?? ''), TRUCK_RUN_XLSX_COLUMNS.status),
      createCell(((sentToKisStatus && sentToKisStatusMap.get(sentToKisStatus)) ?? ''), TRUCK_RUN_XLSX_COLUMNS.sentToKisStatus)
    ]

    return { cells }
  })

  return {
    rowsWithTitle: convertedData
  }
}
export default runTruckConvertToXLSXFormat
