import InfoCard from 'ui/InfoCard'
import { Button, Box } from '@mui/material'
import CollapseBox from 'ui/CollapseBox'
import { useCallback, useEffect, useState } from 'react'
import { Stack } from '@mui/system'
import TextField from 'ui/TextField'
import SquareButton from 'ui/SquareButton'
import { Delete, Save, CheckCircle, DoNotDisturb } from '@mui/icons-material'
import { cleanMaskNum, ORGANIZATION_OKVAD_MASK } from 'common/Mask/Organization'
import { Okved, okvedCreate, okvedDelete, okvedList, okvedUpdate } from 'api/okved'
import { useAuthContext } from 'AuthContext'
import useValidate from 'validation/validate'
import { schema } from 'validation/Okved'

const Row = ({ data: okvedData, init }: { data: Okved, init: () => void}) => {
  const { check, errors, cleanErrors } = useValidate(schema)
  const { handleResponseSuccess } = useAuthContext()
  const [data, setData] = useState<Okved>(okvedData)
  useEffect(() => {
    setData(okvedData)
  }, [okvedData])
  const onChange = (field: string, value: string) => {
    cleanErrors()
    setData((prev) => ({
      ...prev,
      [field]: value
    }))
  }
  const onSave = () => {
    if (!check(data)) {
      return
    }
    const okved = {
      ...data,
      ...(data.code ? { code: cleanMaskNum(data.code) } : {})
    }
    const saveOperation = data.id ? okvedUpdate(okved) : okvedCreate({ code: cleanMaskNum(data.code), description: data.description.trim(), isActive: true })

    saveOperation.then(({ success }) => {
      if (success) {
        const operationType = data.id ? 'изменены' : 'сохранены'
        handleResponseSuccess(`Данные ${operationType}`)
        init()
      }
    })
  }

  const toggleActive = () => {
    const updatedData = { ...data, isActive: !data.isActive }
    okvedUpdate(updatedData)
      .then(({ success }) => {
        if (success) {
          handleResponseSuccess(!updatedData.isActive ? 'Данные заархивированы' : 'Данные активированы')
          setData(updatedData)
          init()
        }
      })
  }

  const onDelete = () => {
    if (data.id) {
      okvedDelete(data.id)
        .then(({ success }) => {
          if (success) {
            init()
          }
        })
    }
  }
  return (
    <Stack direction='row' spacing={2} mb='10px' alignItems='center'>
      <TextField
        name='code'
        placeholder='Код'
        value={data.code}
        errors={errors}
        disabled={!data.isActive}
        maskParams={{
          mask: ORGANIZATION_OKVAD_MASK
        }}
        onChange={(e) => onChange('code', e.target.value)}
      />
      <TextField
        name='description'
        placeholder='Описание'
        width='50%'
        errors={errors}
        disabled={!data.isActive}
        value={data.description}
        onChange={(e) => onChange('description', e.target.value)}
      />
      <SquareButton
        variant='contained'
        onClick={() => onSave()}
      >
        <Save sx={{ width: '16px' }} />
      </SquareButton>
      <SquareButton
        onClick={() => toggleActive()}
        variant='contained'
        color={data.isActive ? 'success' : 'secondary'}>
        {
          data.isActive ? <CheckCircle sx={{ width: '16px' }}/> : <DoNotDisturb sx={{ width: '16px' }}/>
        }
      </SquareButton>
      <SquareButton
        onClick={() => onDelete()}
        variant='contained'
        color='secondary'
      >
        <Delete sx={{ width: '16px' }} />
      </SquareButton>
    </Stack>
  )
}
export default function OkvedCodes () {
  const [list, setList] = useState<Okved[]>([])
  const init = useCallback(() => okvedList().then(setList), [])
  useEffect(() => { init() }, [init])

  return (
    <InfoCard
      title='ОКВЭД'
      actions={<Button
       variant='outlined'
       size='small'
       onClick={() => {
         setList(prev => [...prev, { code: '', description: '', isActive: true }])
       }}
     >Добавить</Button>}>
      <CollapseBox title='ОКВЭД'>
        <Box
          sx={{
            maxHeight: '400px',
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
              width: '8px'
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: '#f1f1f1',
              borderRadius: '5px'
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#b4b4b4',
              borderRadius: '5px'
            },
            '&::-webkit-scrollbar-thumb:hover': {
              backgroundColor: '#888',
              borderRadius: '5px'
            }
          } as any}>
          {
             list.map((item, i) => <Row init={init} data={item} key={i}/>)
          }
        </Box>
      </CollapseBox>
    </InfoCard>
  )
}
