import { Box, Button, Stack, Typography } from '@mui/material'
import { Id, isId } from 'api/Id'
import Modal from 'ui/Modal'
import { bidAllUpdate } from 'api/bid'
import { useAuthContext } from 'AuthContext'

export interface Params {
  id?: Id
  onClose: () => void
  onSuccess: () => void
}

export default function UnblockManualFillModal ({ id, onClose, onSuccess }: Params) {
  const { handleResponseFailure } = useAuthContext()

  const handleBlocked = async () => {
    if (!isId(id)) {
      return
    }

    const success = await bidAllUpdate({ id, blockedManualFill: false })

    if (success) {
      onSuccess()
    } else {
      handleResponseFailure('Данные не были сохранены')
    }
  }

  return (<Modal
    maxWidth='lg'
    open={isId(id)}
    onClose={onClose}
    title='Снятие ограничения создания рейсов'
    content={<Box minWidth='500px'>
      <Typography color='#B2B2B2' fontSize='14px'>Вы уверены что хотите снять ограничени?</Typography>
    </Box>}
    actions={<>
      <Stack spacing={2} justifyContent='end' direction='row'>
        <Button variant='outlined' color='secondary' size='small' onClick={onClose}>
          Отменить
        </Button>
        <Button variant='contained' size='small' color='primary' onClick={handleBlocked} >
          Снять
        </Button>
      </Stack>
    </>}
/>)
}
