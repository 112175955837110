import { AddressWorkSchedule, AddressWorkScheduleTime } from 'api/address'

export const timeToStr = ({ hours, minutes }: AddressWorkScheduleTime): string => {
  const hh = hours.toString().padStart(2, '0')
  const mm = minutes.toString().padStart(2, '0')
  return `${hh}:${mm}`
}

export const isTime = (t: AddressWorkScheduleTime): t is AddressWorkScheduleTime => {
  const { hours, minutes } = t

  if (hours > 24) {
    return false
  }

  if (minutes > 59) {
    return false
  }

  return true
}

export default function formatWorkSchedule (schedule?: AddressWorkSchedule): string {
  if (schedule === undefined) {
    return '-'
  }

  const { from, to } = schedule

  return `С ${timeToStr(from)} до ${timeToStr(to)}`
}
