import { MediaZipCreateParams } from 'api/media'

export type Result = MediaZipCreateParams[] | undefined

export default function vehicleAndTrailerPack <In extends { scanPassport?: string, scanRegistration?: string }> (data?: In): Result {
  if (!data) {
    return
  }

  const { scanPassport, scanRegistration } = data

  const pack = []

  scanPassport && pack.push({ filename: scanPassport, toName: 'ПТС' })
  scanRegistration && pack.push({ filename: scanRegistration, toName: 'Свидетельство о гос.регистрации' })

  return pack
}
