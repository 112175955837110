import './styles.sass'
import { Button, Typography } from '@mui/material'
import { MoreHoriz } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'

interface Props {
  headText: string
  linkRef: string
  linkText: string
}
export default function Head ({ headText, linkText, linkRef }: Props) {
  const navigate = useNavigate()

  return (
    <div className='head'>
      <Typography component='div' fontWeight={600} fontSize='18px'>{headText}</Typography>
      <Button
        size='small'
        variant='text'
        sx={{ textTransform: 'none', color: '#0A5DFF', fontSize: '14px', textDecoration: 'underline' }}
        endIcon={<MoreHoriz />}
        onClick={() => navigate(linkRef)}
      >{linkText}</Button>
    </div>
  )
}
