import './styles.sass'
// import { Button } from '@mui/material'
import { Info, CheckCircle, Warning, Error } from '@mui/icons-material'
import { NotificationVariant } from 'pages/SystemNotifies/Notifications'

function typeIcon (t: NotificationVariant) {
  switch (t) {
    case NotificationVariant.success:
      return <CheckCircle className='success' />
    case NotificationVariant.info:
      return <Info className='info' />
    case NotificationVariant.warning:
      return <Warning className='warning' />
    case NotificationVariant.error:
      return <Error className='error' />
  }
}

export interface Notice {
  type: NotificationVariant
  message: string
  date: number
  link: string
}

interface Params {
  notice: Notice
}

export default function Notification ({ notice }: Params) {
  return (
    <div className='notification'>
      {typeIcon(notice.type)}
      <span className='message'>{notice.message}</span>
      {/* <Button variant='contained' size='small' sx={{ maxWidth: '40px', minWidth: '40px' }} ><Visibility/></Button> */}
    </div>
  )
}
