import './styles.sass'
import { Box } from '@mui/material'
import ChipStatus from 'ui/ChipStatus'
import { VehicleStatus } from 'api/vehicle'
import { Id } from 'api/Id'

export function ChipVehicleStatusDispatcher ({ status, truckRuns = [] }: { status: VehicleStatus, truckRuns?: Id[] }) {
  const Chip = () => {
    if (status === VehicleStatus.new) {
      return <ChipStatus color='grey' label='На рассмотрении' />
    } else if (status === VehicleStatus.needsAttention) {
      return <ChipStatus color='red' label='Требует внимания' />
    } else if (status === VehicleStatus.blocked) {
      return <ChipStatus color='red' label='Отказано' />
    } else if (status === VehicleStatus.archive) {
      return <ChipStatus color='lightGrey' label='В архиве' />
    } else if (status === VehicleStatus.active) {
      return <ChipStatus color='green' label='Подтверждено' />
    }
  }

  return <>
  {
    [VehicleStatus.active, VehicleStatus.needsAttention].includes(status)
      ? <Box sx={{ display: 'flex', gap: '0.3em' }}>
    {Chip()}
    { truckRuns.length > 0
      ? <ChipStatus color='yellowBorder' label='Участвует в перевозке' />
      : <></>
    }
  </Box>
      : Chip()
  }
  </>
}
