import './styles.sass'
import clsx from 'clsx'
import { ReactNode } from 'react'

export interface InfoItemParams {
  label: string
  value: string | number | undefined | JSX.Element
}

function InfoItem ({ label, value }: InfoItemParams) {
  return (
    <>
      <div className='InfoCard_body_items_infoItem'>
        <div className='InfoCard_body_items_infoItem_label'>{label}</div>
        <div className='InfoCard_body_items_infoItem_value'>{value}</div>
      </div>
    </>
  )
}

export interface InfoCardParams {
  title?: ReactNode
  actions?: ReactNode
  children?: JSX.Element
  className?: string
  items?: InfoItemParams[]
  style?: React.CSSProperties
  onClick?: () => void
}

export default function InfoCard ({ children, className, items, title, actions, style, onClick }: InfoCardParams) {
  return (
    <div className={clsx('InfoCard', className)} style={style} onClick={onClick}>
      <div className='InfoCard_body'>
        { (title || actions) && <div className='InfoCard_body_header'>
          <div className='InfoCard_body_header_title'>{title}</div>
          <div className='InfoCard_body_header_actions'>{actions}</div>
        </div>}
      { children && <>{children}</> }
        { items && <div className='InfoCard_body_items'>
          { items.map((item, idx) => (<InfoItem key={idx} {...item} />)) }
        </div>}
      </div>
    </div>
  )
}
