import { cleanMaskNum, cleanMaskNumAlpha, ORGANIZATION_BIK_MASK, ORGANIZATION_CHECK_ACC_NUMBER_MASK, ORGANIZATION_CORR_ACC_NUMBER_MASK, ORGANIZATION_KPP_MASK, ORGANIZATION_OGRNIP_MASK, ORGANIZATION_OGRN_MASK, ORGANIZATION_OKPO_MASK } from 'common/Mask/Organization'
import { Meta } from 'entity/Meta'
import { TypeTaxation, Organization, TypeLegalPerson } from 'api/organization'

export function metaOrganization (typeLegalPerson?: TypeLegalPerson) {
  const isLtd = typeLegalPerson !== TypeLegalPerson.st

  return new Meta<Organization>(
    {
      typeLegalPerson: { title: 'Тип организации' },
      typeTaxation: { title: 'Система налогооблажения' },
      inn: { title: 'ИНН', cleanMask: cleanMaskNum },
      shortName: { title: 'Краткое наименование' },
      fullName: { title: '​Полное наименование' },
      ogrnip: { title: '​ОГРНИП', mask: ORGANIZATION_OGRNIP_MASK, cleanMask: cleanMaskNum },
      okpo: { title: '​ОКПО', mask: ORGANIZATION_OKPO_MASK, cleanMask: cleanMaskNum },
      okved: { title: '​Основной ОКВЭД', cleanMask: cleanMaskNum },
      okveds: { title: 'ОКВЭД из раздела "Транспортировка и хранение"', cleanMask: cleanMaskNum },
      ogrn: { title: '​ОГРН', mask: ORGANIZATION_OGRN_MASK, cleanMask: cleanMaskNum },
      correspondentAccountNumber: { title: 'Номер корреспондентского счета', mask: ORGANIZATION_CORR_ACC_NUMBER_MASK, cleanMask: cleanMaskNum },
      kpp: { title: 'КПП', mask: ORGANIZATION_KPP_MASK, cleanMask: cleanMaskNumAlpha },
      checkingAccountNumber: { title: 'Номер р/с для оплаты услуг перевозки', mask: ORGANIZATION_CHECK_ACC_NUMBER_MASK, cleanMask: cleanMaskNum },
      bik: { title: 'БИК', mask: ORGANIZATION_BIK_MASK, cleanMask: cleanMaskNum },
      bankName: { title: '​Наименование банка' },
      organizationHeadName: { title: 'Лицо, имеющие право подписи без доверенности' },
      appointmenDocumentTitle: { title: 'Действует на основании' },
      legalAddress: { title: 'Юридический адрес' },
      registrationAddress: { title: 'Адрес регистрации' },
      actualAddress: { title: 'Фактический адрес' },
      mailingAddress: { title: 'Почтовый адрес' },
      legalEqRegisteredAddress: { title: 'Юридический адрес совпадает с адресом по прописке' },
      mailingEqRegisteredAddress: { title: 'Почтовый адрес совпадает с адресом по прописке' },
      actualEqLegalAddress: { title: 'Фактический адрес совпадает с юридическим' },
      position: { title: 'Должность' },
      // scan:
      scanInn: { title: 'Свидетельство ИНН' },
      scanRegistration: { title: isLtd ? 'Выписка из ЕГРЮЛ' : 'Выписка из ЕГРИП' },
      scanCode: { title: 'Коды ОКПО' },
      scanCertificate: { title: isLtd ? 'Свидетельство ОГРН' : 'Свидетельство ОГРНИП' },
      scanTaxReturnOrFeeDeclaration: { title: 'Копия налоговой декларации/Декларация по НДС' },
      scanConsentTaxpayer: { title: 'Согласие на признание сведений, составляющих налоговую тайну' },
      scanReceiptConsentTaxpayer: { title: 'Квитанция о приеме налоговым органом' },
      scanRegistrationAddress: { title: isLtd ? 'Копии документов, подтверждающих место нахождения юридического лица' : 'Копии документов, подтверждающих место нахождения ИП' },
      scanQuestionnaire: { title: 'Анкета Хартии АПК РФ' },
      scanInformationLetter: { title: 'Письмо о деятельности' },
      scanPatent: { title: 'Патент' },
      scanLicense: { title: 'Лицензия' },
      scanFinancialResults: { title: 'Отчет о финансовых результатах' },
      scanInsuredPersons: { title: 'Сведения о застрахованных лицах' },
      scanReportEFS: { title: 'Копия отчета ЕФС' },
      manningTable: { title: 'Штатная расстановка' },
      accountingBalance: { title: 'Бухгалтерский баланс' },
      scanRequisites: { title: 'Реквизиты' },
      scanBankCard: { title: 'Копия банковской карточки' },
      scanRegulations: { title: 'Устав' },
      scanAdministrationProtocol: { title: 'Протокол собрания участников' }
    }
  )
}

export const TaxationNames = new Map<TypeTaxation, string>()
  .set(TypeTaxation.vat, 'Общая система налогообложения (НДС)')
  .set(TypeTaxation.sts6, 'Упрощенная система налогообложения (6%)')
  .set(TypeTaxation.sts13, 'Упрощенная система налогообложения (доходы минус расходы)')
  .set(TypeTaxation.patent, 'Патент')

export function getTaxationName (role: TypeTaxation | undefined): string {
  if (role === undefined) {
    return ''
  }
  return TaxationNames.get(role) ?? ''
}
