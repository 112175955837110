import './styles.sass'
import { Button, Container, Stack, Typography } from '@mui/material'
import { useState } from 'react'
import { useAuthContext } from 'AuthContext'
import InfoCard from 'ui/InfoCard'
import TextField from 'ui/TextField'
import LinkButton from 'ui/LinkButton'

export function AuthCode () {
  const { phone, activate, sendCode, handleResponseFailure, handleResponseSuccess } = useAuthContext()
  const [code, setCode] = useState<string>('')
  const [coolDownMts, setCoolDownMts] = useState<number>(0)
  const [viewForm, setViewForm] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>()

  const sendSmsHandler = () => {
    sendCode().then(({ success, cooldown_mts: cooldownMts }) => {
      if (success) {
        handleResponseSuccess(`Код отправлен на номер ${phone}`)
      }

      let countDownMts = cooldownMts
      setCoolDownMts(cooldownMts)
      setViewForm(true)

      const interval = setInterval(() => {
        if (countDownMts > 1000) {
          countDownMts -= 1000
        } else {
          countDownMts = 0
          clearInterval(interval)
        }

        setCoolDownMts(countDownMts)
      }, 1000)
    })
  }

  const submitHandler = () => {
    if (code.length > 0) {
      setErrorMessage(undefined)
      activate(code).then(({ success, attempts_left: attempts }) => {
        if (!success) {
          handleResponseFailure('Неверный код')
        }
      })
    } else {
      setErrorMessage('Введите код из СМС')
    }
  }

  return (
    <Container maxWidth='sm' sx={{ height: '30%' }}>
      <InfoCard>
        <>
          <Typography fontWeight={700} fontSize='21px'>Подтверждение номера телефона</Typography>
          { viewForm
            ? <>
            <Stack mt='1.5em'>
              <TextField
                label='Код из СМС'
                value={code}
                placeholder='123456'
                onChange={(e) => setCode(e.target.value)}
                errorMessage={errorMessage}
              />
            </Stack>
            <Stack mt='2em'>
              <Button size='large' color='primary' variant='contained' onClick={ submitHandler }>Подтвердить</Button>
            </Stack>
            <Stack direction='row' mt='1.5em' justifyContent='center' alignItems='center' >
              <LinkButton variant='text' color='inherit' onClick={sendSmsHandler} disabled={coolDownMts > 0} >Отправить код ещё раз</LinkButton>
              { coolDownMts > 0 && <span style={{ color: '#B2B2B2', fontSize: '13px', fontWeight: 500, marginLeft: '5px' }}>(Доступно через {Math.round(coolDownMts / 1000)}с)</span> }
            </Stack>
          </>
            : <Stack mt='2em'>
                <Button fullWidth size='large' color='primary' variant='contained' onClick={ sendSmsHandler }>
                  Отправить код
                </Button>
            </Stack>}
        </>
      </InfoCard>
    </Container>
    // <Container maxWidth="xs">
    //   <Box pb={6} pt={2}>
    //     <Card>
    //       <CardContent>
    //         <Typography variant='h4'>
    //           Подтверждение номера телефона
    //         </Typography>
    //         { !viewForm && <>
    //           <Box mt={4}>
    //             <Button size="large" style={{ width: '100%', height: '4em', textTransform: 'none' }} variant="contained" onClick={ sendSmsHandler }>
    //               <b>Отправить код</b>
    //             </Button>
    //           </Box>
    //         </> }
    //         {viewForm && <>
    //           <Box mt={4}>
    //             <Typography variant='h6'>
    //               Код из СМС:
    //             </Typography>
    //             <OutlinedInput style={{ width: '100%' }} onChange={(e) => setCode(e.target.value)}/>
    //           </Box>
    //           <Box mt={4}>
    //             <Button size="large" style={{ width: '100%', height: '4em' }} variant="contained" onClick={ submitHandler }>Подтвердить</Button>
    //           </Box>
    //           <Box mt={4} style={{ textAlign: 'center' }}>
    //             <Typography>
    //               <Button variant="text" style={{ textTransform: 'none' }} onClick={ sendSmsHandler }>
    //                 <b>Отправить код ещё раз</b>
    //               </Button>
    //               { coolDownMts > 0 && <span style={{ color: 'GrayText' }}>(Доступно через {Math.round(coolDownMts / 1000)}с)</span> }
    //             </Typography>
    //           </Box>
    //         </>}
    //       </CardContent>
    //     </Card>
    //   </Box>
    // </Container>
  )
}
