import './styles.sass'
import { Button, Container, Typography } from '@mui/material'
import { useState } from 'react'
import { useAuthContext } from '../../AuthContext'
import metaLogin from 'entity/Login'
import { schema } from 'validation/Login'
import useValidate from 'validation/validate'
import TextField from 'ui/TextField'
import InfoCard from 'ui/InfoCard'
import { Stack } from '@mui/system'
import LinkButton from 'ui/LinkButton'
import PasswordField from 'ui/PasswordField'

export function Login () {
  const { login, recovery, handleResponseFailure, startRegistration } = useAuthContext()
  const { check, errors, cleanErrors } = useValidate(schema)
  const [phone, setPhone] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [recoveryMode, setRecoveryMode] = useState<boolean>(false)
  const [notFound, setNotFound] = useState(false)
  const [emptyPhone, setEmptyPhone] = useState<string>()

  const submitHandler = () => {
    cleanErrors()
    setEmptyPhone(undefined)

    const cleanPhone = metaLogin.cleanMask('phone', phone)
    const isEmptyPhone = cleanPhone === ''

    const checked = check({ phone: cleanPhone, password })

    if (isEmptyPhone) {
      setEmptyPhone('Введите номер телефона')
      return
    }

    if (recoveryMode) {
      recovery(cleanPhone).then((success) => {
        setNotFound(!success)
      })
      return
    }

    if (!checked) {
      return
    }

    login({ phone: cleanPhone, password }).then((success) => {
      if (!success) {
        handleResponseFailure('Ошибка, телефон или пароль введены не верно')
      }
    })
  }

  const recoveryHandler = () => {
    setRecoveryMode(true)
    setPassword('')
  }

  const toLoginHandler = () => {
    setRecoveryMode(false)
    setNotFound(false)
    setPhone('')
    setPassword('')
  }

  return (
    <Container maxWidth='sm' sx={{ height: '30%' }}>
      { notFound
        ? <InfoCard><>
            <Typography fontWeight={700} fontSize='21px' mb='1em'>Пользователь не найден</Typography>
            <Typography fontWeight={400} fontSize='14px' color='#B2B2B2' mb='2em'>Данный пользователь не найден в системе, пройдите регистрацию</Typography>
            <Stack>
              <Button size='large' color='primary' variant='contained' onClick={ startRegistration }>Зарегистрироваться</Button>
            </Stack>
            <Stack direction='row' justifyContent='center' spacing={2} mt='1em' >
              <LinkButton fullWidth variant='text' color='inherit' onClick={toLoginHandler} >Вернуться ко входу</LinkButton>
            </Stack>
          </></InfoCard>
        : <InfoCard>
        <>
          <Typography fontWeight={700} fontSize='21px'>{recoveryMode ? 'Восстановление пароля' : 'Вход'}</Typography>
          <Stack mt='1.5em'>
            <TextField
              label={metaLogin.title('phone')}
              name='phone'
              placeholder='+7 (999) 999-99-99'
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              maskParams={{
                mask: metaLogin.mask('phone')
              }}
              errors={errors}
              errorMessage={emptyPhone}
            />
          </Stack>
          {!recoveryMode && <Stack mt='1.5em'>
            <PasswordField
              label={metaLogin.title('password')}
              name='password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              errors={errors}
              showPassword={showPassword}
              onShowPassword={() => setShowPassword(prev => !prev)}
              placeholder='Введите пароль'
            />
          </Stack> }
          <Stack mt='2em'>
            <Button size='large' color='primary' variant='contained' onClick={ submitHandler }>{recoveryMode ? 'Далее' : 'Войти'}</Button>
          </Stack>
          { recoveryMode
            ? <Stack direction='row' justifyContent='center' spacing={2} mt='1.5em' >
              <LinkButton fullWidth variant='text' color='inherit' onClick={toLoginHandler} >Вернуться ко входу</LinkButton>
            </Stack>
            : <Stack direction='row' justifyContent='space-between' spacing={2} mt='1.5em' >
            <LinkButton variant='text' color='inherit' onClick={recoveryHandler} >Восстановить пароль</LinkButton>
            <LinkButton variant='text' color='inherit' onClick={startRegistration} >Регистрация</LinkButton>
          </Stack> }
        </>
      </InfoCard>}
    </Container>
  )
}
