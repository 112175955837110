import styled from '@emotion/styled'
import { Stack, Typography, TypographyProps } from '@mui/material'
import { AddressWorkSchedule, AddressWorkScheduleTime } from 'api/address'
import { ChangeEvent, useEffect, useState } from 'react'
import TextField from 'ui/TextField'
import { timeToStr } from 'util/formatWorkSchedule'

export interface Params {
  label: string
  value?: Partial<AddressWorkSchedule>
  onChange: (data: Partial<AddressWorkSchedule>) => void
  errorMessage?: string
}

const TIME_MASK = [/[0, 1, 2]/, /\d/, ':', /[0-5]/, /\d/]
const TIME_REG = /^(\d\d):(\d\d)$/

const P = styled(Typography)<TypographyProps>(({ theme }) => ({
  lineHeight: '16px',
  letterSpacing: '-0.02em',
  whiteSpace: 'nowrap'
}))

const toTime = ([_, hh, mm]: RegExpMatchArray): AddressWorkScheduleTime => {
  return {
    hours: parseInt(hh),
    minutes: parseInt(mm)
  }
}

export default function WorkScheduleField ({ label, value = {}, onChange, errorMessage }: Params) {
  const [from, setFrom] = useState(value.from ? timeToStr(value.from) : '')
  const [to, setTo] = useState(value.to ? timeToStr(value.to) : '')
  const [schedule, setSchedule] = useState<Partial<AddressWorkSchedule>>({})
  const [formError, setFormError] = useState<boolean>(false)
  const [toError, setToError] = useState<boolean>(false)

  useEffect(() => {
    const fm = from.match(TIME_REG)
    if (fm === null) {
      setSchedule(prev => ({ ...prev, from: undefined }))
      return
    }

    setFormError(false)
    setSchedule(prev => ({ ...prev, from: toTime(fm) }))
  }, [from])

  useEffect(() => {
    const tm = to.match(TIME_REG)
    if (tm === null) {
      setSchedule(prev => ({ ...prev, to: undefined }))
      return
    }

    setToError(false)
    setSchedule(prev => ({ ...prev, to: toTime(tm) }))
  }, [to])

  useEffect(() => {
    onChange(schedule)
  }, [schedule, onChange])

  const updateField = (callback: (time: string) => void) => (event: ChangeEvent<HTMLInputElement>) => {
    const { target: { value } } = event
    callback(value)
  }

  return (<Stack direction='column' >
    <P fontSize='13px' fontWeight={500} >{label}</P>
    <Stack direction='row' alignItems='center' gap={1}>
      <P>С</P>
      <TextField
        width='6em'
        placeholder='08:00'
        maskParams={{ mask: TIME_MASK }}
        value={from}
        onChange={updateField(setFrom)}
        style={{ marginBottom: '-10px' }}
        errorMessage={errorMessage !== undefined || formError ? ' ' : undefined}
      />
      <P>до</P>
      <TextField
        width='6em'
        placeholder='17:00'
        maskParams={{ mask: TIME_MASK }}
        value={to}
        onChange={updateField(setTo)}
        style={{ marginBottom: '-10px' }}
        errorMessage={errorMessage !== undefined || toError ? ' ' : undefined}
      />
    </Stack>
    <div style={{ color: 'red', fontSize: '10px', height: '10px' }}>{errorMessage}</div>
  </Stack>)
}
