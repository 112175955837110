import { mainService } from 'api/service'
import { Id } from 'api/Id'

export interface RejectionReason {
  slug: string
  name: string
}

export interface Rejection {
  id: Id
  objectId: string
  reasonSlug: string
  createTs: number

  comment?: string
}

export type RejectionExpand = Omit<Rejection, 'reasonSlug'> & {
  reason: RejectionReason
}

export type RejectionForCreate = Omit<Rejection, 'id' | 'createTs'>
export interface RejectionFilter {
  objectIds?: string[]
}

const request = (method: string, params: object) => mainService.request(`rejection.${method}`, params)

export const rejectionCreate = (params: RejectionForCreate) => request('create', params) as Promise<Id | null>
export const rejectionList = (params: RejectionFilter = {}) => request('list', params) as Promise<Rejection[]>

export const rejectionReasonDelete = (slug: string) => request('reason.delete', { slug }) as Promise<boolean>
export const rejectionReasonList = () => request('reason.list', {}) as Promise<RejectionReason[]>
export const rejectionReasonPut = (name: string, slug?: string) => request('reason.put', { name, slug }) as Promise<{ upserted: boolean, modified: boolean }>
