import { phoneClean, PHONE_MASK } from 'common/Mask/PhoneMask'
import { Meta } from 'entity/Meta'

export interface LoginInterface {
  phone: string
  password: string
}

const login = new Meta<LoginInterface>(
  {
    phone: { title: 'Номер телефона', mask: PHONE_MASK, cleanMask: phoneClean },
    password: { title: 'Пароль' }
  }
)

export default login
