import { mainService } from 'api/service'
import { Id } from 'api/Id'
import { ShippingTruckRun } from './shipping'
import { Bid } from './bid'
import { Organization } from './organization'
import { Pagination } from './pagination'
import { Customer } from './customer'
import { RequireOnly } from 'util/type/RequireOnly'
import { Address } from 'api/address'
import { ConsignorConsignee } from './consignorConsignee'

export enum ActStatus {
  new = 'new',
  waitingSigning = 'waiting_signing',
  dispatcherChecked = 'dispatcher_checked',
  signedByCustomer = 'signed_by_customer',
  refused = 'refused',
  canceled = 'canceled',
  problem = 'problem',
  changedInDiadoc = 'changed_in_diadoc'
}

export interface Act {
  id: Id
  num: string
  dateTs: number
  bid: Bid
  truckRuns: (ShippingTruckRun & { bid: Bid })[]
  status: ActStatus
  filename: string
  mergedFilename: string
  registryFilename?: string
  shortageFilename?: string
  agreementFilename?: string
  invoiceFilename?: string
  finalBidFilename?: string
  registryStatus?: ActStatus
  shortageStatus?: ActStatus
  agreementStatus?: ActStatus
  invoiceStatus?: ActStatus
  finalBidStatus?: ActStatus
  utdXmlBase64?: string
  totalSum: number
  createTs: number
  sendTs?: number
  cancelTs?: number
  problemComment?: string
  unloadingAddress?: Address
  consignee?: ConsignorConsignee
}

export const checkChangedInDiadocStatus = <T extends {
  status: ActStatus,
  registryStatus?: ActStatus,
  shortageStatus?: ActStatus,
  agreementStatus?: ActStatus,
  invoiceStatus?: ActStatus
  finalBidStatus?: ActStatus
}>({ status, registryStatus, shortageStatus, agreementStatus, invoiceStatus, finalBidStatus }: T) => [status, registryStatus, shortageStatus, agreementStatus, invoiceStatus, finalBidStatus].includes(ActStatus.changedInDiadoc)

export type ActAll = Act & { owner: string, organization: Organization }

export interface ActForCreateNumberingItem {
  bidId: Id
  num: string
  dateTs: number
  unloadingAddressId?: Id
  consigneeId?: Id
}

export enum ActForCreateNumberingFailureErrors {
  repeating = 'repeating',
  empty = 'empty',
  usedBefore = 'used_before',
  incorrectDate = 'incorrect_date',
}

export type ActForCreateNumberingFailureItem = ActForCreateNumberingItem & {
  error: ActForCreateNumberingFailureErrors
}

export type ActForCreateParams = {
  truckRunIds: Id[]
  numbering: ActForCreateNumberingItem[]
}

export type ActForCreateResult = {
  success: boolean
  result: Act[]
  conflicts?: {
    truckRunIdsEmpty?: boolean
    numbering?: ActForCreateNumberingFailureItem[]
    customers?: RequireOnly<Customer, 'id' | 'fullName'>[]
  }
}

export type ActListParams = {
  filters?: {
    status?: ActStatus[]
  }
  limit?: number
  offset?: number
}

export enum ActCarrierSignDiadocDocument {
  utd = 'utd',
  registry = 'registry',
  shortage = 'shortage',
  agreement = 'agreement',
  invoice = 'invoice',
  finalBid = 'final_bid',
}

export type ActCarrierSignDiadocParams = {
  fromBoxId: string
  actId: string
  content: string
  signature: string
}

export type ActCarrierSignPack = Record<ActCarrierSignDiadocDocument, {
  content: string
  signature: string
}>

export type ActCarrierSignPackParams = {
  fromBoxId: string
  actId: string
  pack: ActCarrierSignPack
}

export enum ActAllDiadocSyncState {
  notAuth = 'not_auth',
  auth = 'auth',
  sync = 'sync'
}

export type ActAllListResult = Pagination<ActAll> & { syncState?: ActAllDiadocSyncState }

const request = (method: string, params: object) => mainService.request(`act.${method}`, params)

export const actCreate = (params: ActForCreateParams) => request('create', params) as Promise<ActForCreateResult>
export const actGet = (id: Id) => request('get', { id }) as Promise<Act | null>
export const actList = (params: ActListParams = {}) => request('list', params) as Promise<Pagination<Act>>
export const actSign = (ids: Id[]) => request('sign', { ids }) as Promise<void>
export const actCarrierSignDiadoc = (params: ActCarrierSignDiadocParams) => request('sign-diadoc', params) as Promise<void>
export const actCarrierSignPack = (params: ActCarrierSignPackParams) => request('sign-pack', params) as Promise<void>
export const actCancel = (id: Id) => request('cancel', { id }) as Promise<void>
export const actAllGet = (id: Id) => request('all.get', { id }) as Promise<ActAll | null>
export const actAllList = (params: ActListParams = {}) => request('all.list', params) as Promise<ActAllListResult>
export const actAllChangeStatus = (id: Id, status: ActStatus, problemComment?: string) =>
  request('all.change-status', { id, status, problemComment }) as Promise<void>
export const actAllSign = (ids: Id[]) => request('all.sign', { ids }) as Promise<void>
export const actAllRefuse = (ids: Id[]) => request('all.refuse', { ids }) as Promise<void>
