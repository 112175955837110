import './styles.sass'
import { useCallback, useMemo, useState } from 'react'
import { Alert, Box, Button, CircularProgress } from '@mui/material'
import { unquoteCertificateName } from 'ui/Diadoc/CertificateSelect'
import { diadocLogout, Organization } from 'api/diadoc'
import { Certificate } from 'crypto-pro'

interface SignatureDetailsData {
  organizationList: Organization[] | undefined
  certificate: Certificate | undefined
  boxId: string
}

export interface Props {
  data: SignatureDetailsData
  onRenew: () => void
}

export default function SignatureDetails ({ data, onRenew }: Props) {
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const d = data

  const renewCertificate = useCallback(() => {
    setLoading(true)

    diadocLogout()
      .then(() => {
        setLoading(false)
        onRenew()
      })
      .catch((err) => setError(err.message))
  }, [onRenew])

  const organizationName: string = useMemo(() => {
    const found = d.organizationList?.find((o) => o.boxId === d.boxId)
    return found ? found.shortName : ''
  }, [d.organizationList, d.boxId])

  return (<Box className='modal_sign_alert_box'>
      <Alert color='info' action={
        <Button variant="outlined" size="small" sx={{ height: '32px' }} onClick={renewCertificate}>
          { loading ? <CircularProgress size='20px'/> : 'Другой' }
        </Button>
      }>
        Сертификат: { d.certificate && unquoteCertificateName(d.certificate.name) }
      </Alert>
      <Alert color='success'>Организация: { organizationName }</Alert>
      { error && <Alert severity="error">{error}</Alert> }
    </Box>)
}
