import { UserRole } from 'api/user'

export const UserRoleMap = new Map<UserRole | undefined, string>()
  .set(UserRole.root, 'Администратор')
  .set(UserRole.administrator, 'Администратор')
  .set(UserRole.chief_dispatcher, 'Старший диспетчер')
  .set(UserRole.dispatcher, 'Диспетчер')
  // .set(UserRole.driver, 'Водитель')
  .set(UserRole.chief_forwarder, 'Старший экспедитор')
  .set(UserRole.forwarder, 'Экспедитор')
  .set(UserRole.carrier, 'Перевозчик')

export function getNameByUserRole (role: UserRole | undefined): string {
  return UserRoleMap.get(role) ?? ''
}
