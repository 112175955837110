import NavigatePanelOrigin from 'ui/NavigatePanel'
import { useMainRoutes } from 'routes'
import { useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

export default function NavigatePanel () {
  const { links, routesMap } = useMainRoutes()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const breadcrumbsItems = useMemo(
    () => routesMap.getBreadcrumbs(links.CARRIER_BIDS_PAGE),
    [links, routesMap]
  )

  const tabsItems = useMemo(() => [
    { label: 'Заявки на перевозки', value: links.CARRIER_BIDS_PAGE },
    { label: 'Отклики на рассмотрении', value: `${links.CARRIER_BIDS_SHIPPING_PAGE}/new` },
    { label: 'Подтвержденные отклики', value: `${links.CARRIER_BIDS_SHIPPING_PAGE}/confirmed` },
    { label: 'Отклоненные отклики', value: `${links.CARRIER_BIDS_SHIPPING_PAGE}/archive_reject` },
    { label: 'Все отклики', value: `${links.CARRIER_BIDS_SHIPPING_PAGE}/all` }
  ], [links])

  return (
    <NavigatePanelOrigin
      breadcrumbs={{
        items: breadcrumbsItems
      }}
      title='Заявки на перевозки'
      tabs={{
        items: tabsItems,
        value: pathname,
        onChange: to => navigate(to),
        scrollable: true
      }}
    />
  )
}
