import { Chip, Stack, Typography } from '@mui/material'
import { Profile, ProfileAll, ProfileRequest, ProfileRequestSlugs, ProfileRequestStatus } from 'api/profile'
import CollapseList from 'ui/CollapseList'
import InfoCard from 'ui/InfoCard'
import TsToFormatDate from 'util/TsToFormatDate'

export interface Params {
  profile?: Profile | ProfileAll
}

export const PROFILE_REQUESTS_MAP: Map<ProfileRequestSlugs, string> = new Map()
PROFILE_REQUESTS_MAP.set(ProfileRequestSlugs.addDrivers, 'Запрос на разрешение добавления водителей')

const getStatus = ({ status, cancelComment }: ProfileRequest) => {
  switch (status) {
    case ProfileRequestStatus.new:
      return <>
        <Chip variant='outlined' color='primary' size='small' label='На рассмотрении' />
      </>
    case ProfileRequestStatus.accept:
      return <>
        <Chip variant='outlined' color='success' size='small' label='Одобрен' />
      </>
    case ProfileRequestStatus.cancel:
      return <>
        <Chip variant='outlined' color='error' size='small' label='Отклонен' />
        { cancelComment && <Typography>Комментарий диспетчера: {cancelComment}</Typography>}
      </>
    default:
      return <></>
  }
}

export default function ProfileRequests ({ profile }: Params) {
  if (profile === undefined) {
    return (<></>)
  }

  if ((profile?.requests ?? []).length === 0) {
    return (<></>)
  }

  return (<>
    <InfoCard
      title='Запросы'
    >
      <CollapseList
        list={profile.requests}
        previewItems={3}
        element={(item) => {
          const { slug, createTs } = item
          return (<Stack direction='row' justifyItems='center' spacing={1}>
            <Typography fontSize='14px' fontWeight={600}>{PROFILE_REQUESTS_MAP.get(slug)}:</Typography>
            <Typography fontSize='14px' fontWeight={500}>Отправлен {TsToFormatDate(createTs, 'dd.MM.yyyy HH:mm')}</Typography>
            {getStatus(item)}
          </Stack>)
        }}
      />
    </InfoCard>
  </>)
}
