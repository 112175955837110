import { mainService } from 'api/service'
import { Id } from 'api/Id'
import { Bid } from './bid'
import { ConsignorConsignee } from './consignorConsignee'

export enum AddressStatus {
  active = 'active',
  archive = 'archive',
}

export enum AddressPointType {
  grainElevator = 'grain_elevator',
  farming = 'farming',
}

export interface AddressWorkScheduleTime {
  hours: number
  minutes: number
}

export interface AddressWorkSchedule {
  from: AddressWorkScheduleTime
  to: AddressWorkScheduleTime
}

export interface Address {
  id: Id
  status: AddressStatus
  address: string
  coordinates: {
    lat: number
    lon: number
  }
  pointType: AddressPointType
  region?: string
  city?: string
  name?: string
  weighingScaleLength?: number
  weighingScaleMaxLoad?: number
  isPart?: boolean
  district?: string
  street?: string
  building?: string
  externalId?: string
  comment?: string
  consignorConsignee?: ConsignorConsignee[]
  archiveDate?: number
  archiveComment?: string
  bidIds?: Id[]
  routeIds?: Id[]
  rating?: number
  addressOrig?: string
  schedule?: AddressWorkSchedule
  loadingMethod?: string
  unloadingMethod?: string
}

export interface AddressListParams {
  filters?: {
    status?: AddressStatus
    pointType?: AddressPointType
  }
}

export interface AddressArchiveParams {
  id: Id
  date: number
  comment?: string
}

export type AddressForCreate = Omit<Address, 'id' | 'status' | 'archiveDate' | 'archiveComment' | 'bidIds' | 'routeIds' | 'consignorConsignee' | 'pointType' | 'rating' | 'addressOrig'> & {
  consignorConsigneeIds?: Id[]
}
export type AddressForUpdate = Partial<AddressForCreate> & { id: Id }

const request = (method: string, params: object) => mainService.request(`address.${method}`, params)

export const addressArchive = (params: AddressArchiveParams) => request('archive', params) as Promise<{
  success: boolean
  conflicts?: {
    bids: Bid[]
  }
}>
export const addressCreate = (params: AddressForCreate) => request('create', params) as Promise<Id | null>
export const addressUpdate = (params: AddressForUpdate) => request('update', params) as Promise<boolean>
export const addressGet = (id: Id) => request('get', { id }) as Promise<Address | null>
export const addressList = (params: AddressListParams = {}) => request('list', params) as Promise<Address[]>
export const addressDelete = (id: Id) => request('delete', { id }) as Promise<boolean>
export const addressRegions = () => request('regions', {}) as Promise<string[]>
