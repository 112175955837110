import { mainService } from 'api/service'
import { Id } from 'api/Id'
import { Organization, TypeLegalPerson } from './organization'

export enum FocusReportsStatus {
  inWork = 'in_work',
  done = 'done',
  partially = 'partially',
  giveUp = 'give_up',
}

export type FocusReports = {
  status: FocusReportsStatus
  createTs: number

  briefReport?: string
  excerptReport?: string
  consolidatedReport?: string
  finanReport?: string
}

export type FocusFindBranch = {
  kpp: string
  branchName: string
  address?: string
}

export type FocusFindResult = {
  success: boolean
  organization: Partial<Organization & { branches: FocusFindBranch[] }> | null
  conflicts?: {
    requestError?: boolean
    dissolved?: boolean
    reorganizing?: boolean
    double?: boolean
    description?: string
  }
}

const request = (method: string, params: object) => mainService.request(`focus.${method}`, params)

export const focusReports = (organizationId: Id) => request('reports', { organizationId }) as Promise<FocusReports[]>
export const focusSendRequest = (organizationId: Id) => request('send-request', { organizationId }) as Promise<void>
export const focusFind = (typeLegalPerson: TypeLegalPerson, inn: string, ogrn?: string, kpp?: string) => request('find', { typeLegalPerson, inn, ogrn, kpp }) as Promise<FocusFindResult>
