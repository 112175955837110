import { Button, Stack, Typography } from '@mui/material'
import { Id } from 'api/Id'
import { OrganizationAll } from 'api/organization'
import { useCallback, useEffect, useRef, useState } from 'react'
import InfoCard from 'ui/InfoCard'
import { focusSendRequest, focusReports as apiFocusReports, FocusReportsStatus } from 'api/focus'
import TsToFormatDate from 'util/TsToFormatDate'
import download from 'util/download'
import LinkButton from 'ui/LinkButton'
import None from 'ui/None'
import { Interface } from 'api/Interface'
import { useAuthContext } from 'AuthContext'

const INTERVAL = 5 * 1000

export interface Params {
  organization?: OrganizationAll
}

export default function Focus ({ organization }: Params) {
  const [focusReports, setFocusReports] = useState<Awaited<ReturnType<typeof apiFocusReports>>>([])
  const [inWork, setInWork] = useState(false)
  const timer = useRef<NodeJS.Timeout>()

  const { currentInterface } = useAuthContext()

  const isDispatcher = (currentInterface ? [Interface.dispatcher, Interface.chief_dispatcher].includes(currentInterface) : false)

  const getFocusReports = useCallback(async (id: Id) => {
    const result = await apiFocusReports(id)

    setInWork(result.find(item => item.status === FocusReportsStatus.inWork) !== undefined)
    setFocusReports(result)
  }, [])

  useEffect(() => {
    if (organization?.id === undefined) {
      return
    }

    getFocusReports(organization.id)
  }, [organization?.id, getFocusReports])

  useEffect(() => {
    if (inWork && organization?.id !== undefined) {
      timer.current = setInterval(() => getFocusReports(organization.id), INTERVAL)
    } else {
      clearInterval(timer.current)
    }

    return () => { clearInterval(timer.current) }
  }, [organization?.id, inWork, getFocusReports])

  if (organization === undefined) {
    return (<></>)
  }

  return (<InfoCard title='Контур Фокус'>
    <Stack direction='column'>
    {focusReports.length > 0
      ? <Stack direction='column'>
          {focusReports.length > 0 && <Stack direction='column' gap={2} width='100%'>
            { focusReports.map((item, idx) => (<Stack
                direction='row'
                alignItems='top'
                spacing={2}
                key={`focus-reports-${idx}`}
                sx={{ borderBottom: '1px solid #EBEBEB' }}
                pb='0.5em'
              >
              <Typography>Запрос отправлен: {TsToFormatDate(item.createTs, 'dd.MM.yyyy HH:mm')}</Typography>
              {item.status === FocusReportsStatus.inWork
                ? <Typography>Запрос обрабатывается...</Typography>
                : item.status === FocusReportsStatus.giveUp
                  ? <Typography>Данные не были получены</Typography>
                  : <>
                  <Stack flexDirection='column' spacing={1} sx={{ '& button': { fontSize: '1rem', color: '#111111' } }}>
                    {item.briefReport && <Stack direction='row' spacing={1}>
                      <LinkButton onClick={() => download(item.briefReport as string)}>Экспресс-отчет по контрагенту</LinkButton>
                    </Stack>}
                    {item.excerptReport && <Stack direction='row' alignItems='center' spacing={1}>
                      <LinkButton onClick={() => download(item.excerptReport as string)}>Информационная выписка</LinkButton>
                    </Stack>}
                    {item.finanReport && <Stack direction='row' alignItems='center' spacing={1}>
                      <LinkButton onClick={() => download(item.finanReport as string)}>Финансовый анализ</LinkButton>
                    </Stack>}
                    {item.consolidatedReport && <Stack direction='row' alignItems='center' spacing={1}>
                      <LinkButton onClick={() => download(item.consolidatedReport as string)}>Отчет по данным</LinkButton>
                      {item.status === FocusReportsStatus.partially && <Typography color='#a64a42'>Контур фокус предоставил не все данные!</Typography>}
                    </Stack>}
                  </Stack>
                </>}
              </Stack>))}
            </Stack>}
        </Stack>
      : <Stack display='flex' minHeight='100px'>
        <None desc='На данный момент нет успешно выполненных запросов' />
      </Stack>
    }
      <Stack direction='row' spacing={1} justifyContent='space-between' mt='1em'>
        <Stack></Stack>
        <Stack>
          <Button
            size='small'
            variant='contained'
            disabled={!isDispatcher || inWork}
            onClick={() => focusSendRequest(organization.id).then(() => getFocusReports(organization.id))}
          >{focusReports.length > 0 ? 'Запросить данные ещё раз' : 'Запросить данные'}</Button>
        </Stack>
      </Stack>
    </Stack>
  </InfoCard>)
}
