import { Box, BoxProps, ButtonProps, Stack, Typography, styled } from '@mui/material'
import LinkButton from 'ui/LinkButton'
import download from '../../util/download'
import rub from '../../util/formatRub'
import { ReactNode } from 'react'

export const CellBox = styled(Box)<BoxProps>(({ theme }) => ({
  minHeight: '25px',
  display: 'flex',
  alignItems: 'center'
}))

export const CellLinkButton = styled(LinkButton)<ButtonProps>(({ theme }) => ({
  justifyContent: 'left',
  color: '#111111'
}))

export interface CellDocs {
  filename: string
  registryFilename?: string
  shortageFilename?: string
  agreementFilename?: string
  invoiceFilename?: string
  finalBidFilename?: string
}
export const cellDocs = ({
  filename,
  registryFilename,
  shortageFilename,
  agreementFilename,
  invoiceFilename,
  finalBidFilename
}: CellDocs) => {
  return (<Stack direction='column' justifyContent='left' spacing={1} width='12em'>
    { finalBidFilename && <CellBox><CellLinkButton onClick={() => download(finalBidFilename)}>Итоговая заявка</CellLinkButton></CellBox> }
    <CellBox>
      <CellLinkButton onClick={() => download(filename)}>УПД</CellLinkButton>
    </CellBox>
    { shortageFilename && <CellBox><CellLinkButton onClick={() => download(shortageFilename)}>- Отчет о недостаче</CellLinkButton></CellBox> }
    { agreementFilename && <CellBox><CellLinkButton onClick={() => download(agreementFilename)}>- Соглашение</CellLinkButton></CellBox> }
    { invoiceFilename && <CellBox><CellLinkButton onClick={() => download(invoiceFilename)}>- Счет на оплату</CellLinkButton></CellBox> }
    { registryFilename && <CellBox><CellLinkButton onClick={() => download(registryFilename)}>- Реестр оказанных услуг</CellLinkButton></CellBox> }
  </Stack>)
}

export interface CellSum {
  actSum: number
  shortageSum: number
  finalSum?: number
  hasErrors?: boolean
  hasFinalBid?: boolean
}
export const cellSum = ({
  actSum,
  shortageSum,
  finalSum,
  hasErrors = false,
  hasFinalBid = false
}: CellSum) => {
  return <Stack direction='column' justifyContent='left' spacing={1}>
    { hasFinalBid && <CellBox></CellBox>}
    <CellBox><Typography fontSize='14px' fontWeight={400}>{rub(actSum)}</Typography></CellBox>
    { shortageSum > 0 && <>
      { shortageSum && <CellBox><Typography fontSize='14px' fontWeight={400} color='#EE6A5F'>-{rub(shortageSum)}</Typography></CellBox> }
      <Box minHeight='25px' />
      { finalSum && <CellBox><Typography fontSize='14px' fontWeight={600} color='#0A5DFF'>{rub(finalSum)}</Typography></CellBox> }
    </> }
    { hasErrors &&
      <CellBox><Typography fontSize='14px' fontWeight={400} color='#EE6A5F'>Ошибка в данных</Typography></CellBox>
    }
  </Stack>
}

export interface CellStatus <T>{
  status: T
  registryStatus?: T
  shortageStatus?: T
  agreementStatus?: T
  invoiceStatus?: T
  finalBidStatus?: T
}
export const cellStatus = <T, >({
  status,
  registryStatus,
  shortageStatus,
  agreementStatus,
  invoiceStatus,
  finalBidStatus
}: CellStatus<T>) => (getChips: (status: T) => ReactNode) => {
    return <Stack direction='column' justifyContent='left' spacing={1}>
    { finalBidStatus && <Box minHeight='25px' display='flex' alignItems='center'>{getChips(finalBidStatus)}</Box> }
    <Box minHeight='25px' display='flex' alignItems='center'>{ getChips(status) }</Box>
    { agreementStatus && <Box minHeight='25px' display='flex' alignItems='center'>{getChips(agreementStatus)}</Box> }
    { registryStatus && <Box minHeight='25px' display='flex' alignItems='center'>{getChips(registryStatus)}</Box> }
    { shortageStatus && <Box minHeight='25px' display='flex' alignItems='center'>{getChips(shortageStatus)}</Box> }
    { invoiceStatus && <Box minHeight='25px' display='flex' alignItems='center'>{getChips(invoiceStatus)}</Box> }
  </Stack>
  }
