import { Box, Button, CircularProgress, Stack } from '@mui/material'
import { forwarderReportsLoading } from 'api/forwarder'
import { useAuthContext } from 'AuthContext'
import { useState } from 'react'
import DatePicker, { RangeDate } from 'ui/DatePicker'
import Modal from 'ui/Modal'
import download from 'util/download'

export interface Params {
  open?: boolean
  onClose: () => void
}

export default function ForwarderLoadingReportsModel ({ open, onClose }: Params) {
  const { handleResponseFailure } = useAuthContext()
  const [progressDriverList, setProgressDriverList] = useState<boolean>(false)
  const [loadingReportsDateRange, setLoadingReportsDateRange] = useState<RangeDate>()

  const handleLoadingReports = () => {
    if (loadingReportsDateRange !== undefined) {
      setProgressDriverList(true)
      forwarderReportsLoading({ filters: { dateFrom: loadingReportsDateRange.startTs, dateTo: loadingReportsDateRange.endTs } })
        .then(file => {
          setProgressDriverList(false)

          if (file === null) {
            handleResponseFailure('Отчет не был сформирован')
            return
          }

          download(file)
        })
    }
  }

  const handleClose = () => {
    setProgressDriverList(false)
    setLoadingReportsDateRange(undefined)
    onClose()
  }

  return (<>
  <Modal
    maxWidth={false}
    open={!!open}
    onClose={handleClose}
    title={'Отчет о погрузке'}
    content={<Box minWidth='300px'>
      { progressDriverList
        ? <Box display='flex' alignContent='center' justifyContent='center' m='1em'>
          <CircularProgress />
        </Box>
        : <Stack>
          <DatePicker
            label='Выберите даты'
            placeholder='Выберите даты'
            range={loadingReportsDateRange}
            onChange={(startTs, endTs) => {
              if (startTs !== undefined && endTs !== undefined) {
                setLoadingReportsDateRange(startTs < endTs ? { startTs, endTs } : { startTs: endTs, endTs: startTs })
              }
            }}
            rangeDate
          />
        </Stack> }
    </Box>}
    actions={<Stack direction='row' spacing={2}>
      <Button variant='outlined' onClick={handleClose} >Отменить</Button>
      <Button
        variant='contained'
        onClick={handleLoadingReports}
        disabled={loadingReportsDateRange === undefined || progressDriverList}
      >Сформировать</Button>
    </Stack>}
  />
  </>)
}
