import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  OutlinedInput,
  Slider,
  TextField,
  styled,
  InputAdornment,
  Select
} from '@mui/material'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import './styles.sass'
import { useFiltersContext } from '../FiltersContext'
import rub from 'util/formatRub'
import { toKopeck, toRuble } from 'util/monetary'

const minDistance = 100

const CostSlider = styled(Slider)(({ theme }) => ({
  color: '#0A5DFF',
  height: '3px',
  '& .MuiSlider-thumb': {
    height: '16px',
    width: '16px',
    backgroundColor: '#fff',
    border: '3px solid currentColor',
    '&:hover': {
      boxShadow: '0 0 0 8px rgba(58, 133, 137, 0.16)'
    }
  },
  '& .MuiSlider-track': {
    height: 3
  },
  '& .MuiSlider-mark': {
    backgroundColor: '#EBEBEB'
  },
  '& .MuiSlider-rail': {
    color: '#EBEBEB'
  },
  '& .MuiSlider-markLabel': {
    color: '#111111',
    fontSize: '14px',
    fontWeight: 500
  }
}))

export default function CostRange () {
  const {
    setData,
    data,
    filterCostTon: viewCostRange,
    getCostTonRange,
    setFilterCostTon
  } = useFiltersContext()

  const [costMin, setCostMin] = useState<number>(0)
  const [costMax, setCostMax] = useState<number>(0)
  const [selectCostRange, setSelectCostRange] = useState<[number, number]>([0, 0])

  const loaderCostRange = useCallback(() => {
    const range = getCostTonRange()
    if (range) {
      setSelectCostRange(range)
      setCostMin(range[0])
      setCostMax(range[1])
    }
  }, [getCostTonRange])

  useEffect(() => {
    if (viewCostRange === undefined) {
      loaderCostRange()
    }
  }, [loaderCostRange, viewCostRange])

  useEffect(() => {
    if (costMin === 0 && costMax === 0) {
      loaderCostRange()
    }
  }, [costMax, costMin, data, loaderCostRange])

  useEffect(() => {
    if (data === undefined) {
      setCostMin(0)
      setCostMax(0)
    }
  }, [setData, data])

  useEffect(() => {
    if (selectCostRange[0] === costMin && selectCostRange[1] === costMax) {
      setFilterCostTon(null)
    } else {
      setFilterCostTon(selectCostRange)
    }
  }, [costMax, costMin, selectCostRange, setFilterCostTon, setSelectCostRange])

  const handleChangeCostRange = (
    event: Event,
    newValue: number | number[],
    activeThumb: number
  ) => {
    if (!Array.isArray(newValue)) {
      return
    }

    if (activeThumb === 0) {
      setSelectCostRange([Math.min(newValue[0], selectCostRange[1] - minDistance), selectCostRange[1]])
    } else {
      setSelectCostRange([selectCostRange[0], Math.max(newValue[1], selectCostRange[0] + minDistance)])
    }
  }

  const updateNumber = (key: 'min' | 'max') => {
    return (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value
      if (value.match(/^\d*$/)) {
        const n = Number(value)

        if (key === 'min') {
          setSelectCostRange([toKopeck(n), selectCostRange[1]])
        } else {
          setSelectCostRange([selectCostRange[0], toKopeck(n)])
        }
      }
    }
  }

  return (
    <>
      <FormControl sx={{ width: '100%' }} size='small' className='costRange'>
        <InputLabel id='cost-range-label' sx={{ color: '#B2B2B2', fontWeight: 500 }}>Стоимость тн.</InputLabel>
        <Select
          id='cost-range'
          multiple={true}
          value={viewCostRange ?? []}
          input={<OutlinedInput label='Стоимость тн.' />}
          renderValue={(selected) => selected.map(toRuble).join('...')}
        >
          <Box>
            <Grid container spacing={2} padding={2}>
              <Grid item sm={12} textAlign='center'>
                <Box className='costRange__slider'>
                  <div className='costRange__slider_track'>
                    <CostSlider
                      value={selectCostRange}
                      onChange={handleChangeCostRange}
                      valueLabelDisplay='off'
                      disableSwap
                      step={minDistance}
                      min={costMin}
                      max={costMax}
                    />
                  </div>
                  <div className='costRange__slider_marks'>
                    <div className='costRange__slider_marks_left'>{rub(costMin)}</div>
                    <div className='costRange__slider_marks_right'>{rub(costMax)}</div>
                  </div>
                </Box>
              </Grid>
              <Grid item sm={12} mt='16px'>
                <TextField
                  label='Цена от'
                  fullWidth
                  variant='outlined'
                  size='small'
                  value={toRuble(selectCostRange[0])}
                  onChange={updateNumber('min')}
                  placeholder='Цена от, ₽'
                  sx={{
                    background: '#F7F7F7',
                    '& fieldset': {
                      border: '1px solid #EBEBEB',
                      borderRadius: '4px'
                    }
                  }}
                  InputProps={{
                    endAdornment: <InputAdornment position='start' sx={{ color: '#B2B2B2' }} >₽</InputAdornment>
                  }}
                />
              </Grid>
              <Grid item sm={12}>
                <TextField
                  label='Цена до'
                  fullWidth
                  variant='outlined'
                  size='small'
                  value={toRuble(selectCostRange[1])}
                  onChange={updateNumber('max')}
                  placeholder='Цена до, ₽'
                  sx={{
                    background: '#F7F7F7',
                    '& fieldset': {
                      border: '1px solid #EBEBEB',
                      borderRadius: '4px'
                    }
                  }}
                  InputProps={{
                    endAdornment: <InputAdornment position='start' sx={{ color: '#B2B2B2' }} >₽</InputAdornment>
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Select>
      </FormControl>
    </>
  )
}
