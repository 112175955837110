import { SessionStatus } from 'api/SessionStatus'
import { UserStatus } from 'api/UserStatus'

export class AccessDeniedError extends Error {
  constructor (
    public readonly session: SessionStatus | null,
    public readonly user: UserStatus | null
  ) {
    super('Доступ запрещен')
  }
}
