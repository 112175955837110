import { ReactNode, useEffect, useMemo, useState } from 'react'
import { ShippingTruckRunSearchResult, shippingTruckRunSearch } from 'api/shipping'
import { debounce } from '@mui/material/utils'
import Select from 'ui/Select'
import { ValidateError } from 'validation/validate'

interface Params {
  name?: string
  label?: string
  hint?: ReactNode
  placeholder?: string
  value?: string
  onChange?: (value?: string) => void
  hideError?: boolean
  errors?: ValidateError
  errorMessage?: string
  fullWidth?: boolean
  width?: string
  disabled?: boolean
}

interface Option {
  value: string
  name: string
}

export default function SelectBillNumber ({ value, onChange, ...props }: Params) {
  const [options, setOptions] = useState<Option[]>(value ? [{ value, name: value }] : [])
  const [query, setQuery] = useState(value ?? '')

  const fetch = useMemo(() => debounce(
    (query: string, callback: (result: ShippingTruckRunSearchResult[]) => void) =>
      shippingTruckRunSearch({ query }).then(callback)
    , 400), [])

  useEffect(() => {
    let active = true

    if (query === '') {
      setOptions([])
      return undefined
    }

    fetch(query, (result) => {
      const values = result.map(({ billNumber }) => billNumber)
      active && setOptions([...new Set(values)].map((value) => ({ value, name: value })))
    })

    return () => {
      active = false
    }
  }, [query, fetch])

  return <Select
    options={options}
    value={query === value ? value : undefined}
    noOptionsText='Поиск по номеру ТТН'
    {...props}
    onInputChange={setQuery}
    onChange={(id) => onChange && onChange(id)}
  />
}
