import {
  Box,
  Checkbox,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Select,
  TextField
} from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import SearchIcon from '@mui/icons-material/Search'
import './styles.sass'
import { addressList as apiAddressList, Address as AddressOrigin, AddressStatus } from 'api/address'
import shortAddress from 'util/shortAddress'
import { Id } from 'api/Id'
import { ShortAddress } from 'ui/ShortAddress'
import MenuItem from 'ui/Select/MenuItem'

interface Props {
  onChangeFilter: (addressIds: Id[]) => void
  label?: string
  selectAddress: Id[]
}

const DEFAULT_LABEL = 'Элеватор/хозяйство'

export default function AddressFilter ({ onChangeFilter, label = DEFAULT_LABEL, selectAddress }: Props) {
  const [list, setList] = useState<AddressOrigin[]>([])
  const [search, setSearch] = useState<string>()
  const [searchList, setSearchList] = useState<{id: Id, value: string}[]>([])

  useEffect(() => {
    apiAddressList(({ filters: { status: AddressStatus.active } })).then(result => {
      setList(result)
      setSearchList(result.map(item => ({
        id: item.id,
        value: shortAddress(item)
      })))
    })
  }, [])

  const searchIds: Id[] = useMemo(() => {
    return searchList
      .filter(item => search ? item.value.toLocaleLowerCase().includes(search.toLocaleLowerCase()) : true)
      .map(item => item.id)
  }, [search, searchList])

  return (
    <>
      <div className='addressFilter'>
        <FormControl sx={{ width: '100%' }} size='small'>
          <InputLabel id='demo-multiple-checkbox-label' sx={{ color: '#B2B2B2', fontWeight: 500 }}>{label}</InputLabel>
          <Select
            labelId='demo-multiple-checkbox-label'
            id='demo-multiple-checkbox'
            multiple={true}
            value={selectAddress}
            input={<OutlinedInput label={label} />}
            renderValue={(selected) => list.filter(item => selected.includes(item.id)).map(item => shortAddress(item)).join(', ')}
            /*
            paper={{
              minWidth: '450px',
              maxWidth: '450px'
            }}
            */
          >
            <Box sx={{ margin: '16px 12px' }} >
              <TextField
                fullWidth
                variant='outlined'
                size='small'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <SearchIcon style={{ color: '#B2B2B2' }} />
                    </InputAdornment>
                  )
                }}
                value={search ?? ''}
                onChange={(e) => {
                  setSearch(e.target.value)
                }}
                placeholder='Поиск'
                sx={{
                  background: '#F7F7F7',
                  '& fieldset': {
                    border: '1px solid #EBEBEB',
                    borderRadius: '4px'
                  }
                }}
              />
            </Box>
            <div className='addressFilter__menuItems' >
              { list.filter(item => searchIds.includes(item.id)).map(item => (
                <MenuItem
                  className='addressFilter__menuItems_item'
                  key={item.id}
                  value={shortAddress(item)}
                  onClick={v => {
                    if (selectAddress.indexOf(item.id) > -1) {
                      onChangeFilter(selectAddress.filter(v => v !== item.id))
                    } else {
                      onChangeFilter([...selectAddress, item.id])
                    }
                  }}
                >
                  <Checkbox checked={selectAddress.indexOf(item.id) > -1} size='small' sx={{ paddingLeft: 0 }} />
                  <ShortAddress address={item} fontSize='13px' fontWeight={500} />
                </MenuItem>
              ))}
            </div>
          </Select>
        </FormControl>
      </div>
    </>
  )
}
