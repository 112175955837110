import { Address } from 'api/address'
import { memo } from 'react'
import { ShortAddress } from 'ui/ShortAddress'
import './styles.sass'
import addressName from 'util/addressName'

interface RouterInfoParams {
  loadingAddress: Address
  unloadingAddress: Address
  fullAddress?: boolean
}

const RouteInfo = memo(({ loadingAddress, unloadingAddress, fullAddress }: RouterInfoParams) => {
  return (
    <>
      <div className='bidsRouteComponent'>
        <div className='bidsRouteComponent_start'>
          <div className='bidsRouteComponent_content'>
            { fullAddress ? addressName(loadingAddress) : <ShortAddress address={loadingAddress} />}
          </div>
         <span className='bidsRouteComponent_point'></span>
         <div className='bidsRouteComponent_line'></div>
        </div>
        <div className='bidsRouteComponent_end'>
          <div className='bidsRouteComponent_content'>
          { fullAddress ? addressName(unloadingAddress) : <ShortAddress address={unloadingAddress} />}
          </div>
          <span className='bidsRouteComponent_point'></span>
        </div>
      </div>
    </>
  )
})

export default RouteInfo
