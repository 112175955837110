import { DeleteForever } from '@mui/icons-material'
import { Box, Button, Stack, Typography } from '@mui/material'
import { BidSlot } from 'api/bid'
import dayjs from 'dayjs'
import { ChangeEvent, useEffect, useState } from 'react'
import DatePicker from 'ui/DatePicker'
import InfoCard from 'ui/InfoCard'
import InfoField from 'ui/InfoField'
import SquareButton from 'ui/SquareButton'
import TextField from 'ui/TextField'
import { today as getToday } from 'util/date'
import { ValidateError } from 'validation/validate'
import { UpdateParamsBid } from '..'
import { useAuthContext } from 'AuthContext'
import { toTon } from 'util/weight'

export type UpdateParamsSlot = Partial<BidSlot> & { rowId: string }

const today = getToday()

interface Params {
  slotList: UpdateParamsSlot[]
  data: UpdateParamsBid
  addSlot: () => void
  deleteSlot: (current: UpdateParamsSlot) => void
  updateSlotSeats: (current: UpdateParamsSlot) => (e: ChangeEvent<HTMLInputElement>) => void
  updateSlotshipmentTs: (current: UpdateParamsSlot) => (ts: number) => void
  slotErrors: ValidateError
  averageWeight?: number
  maxNewSeats: number
  disableSaveButton: React.Dispatch<React.SetStateAction<boolean>>
}

export default function FreeSpaceEdit ({
  slotList,
  data,
  addSlot,
  deleteSlot,
  updateSlotSeats,
  updateSlotshipmentTs,
  slotErrors,
  averageWeight,
  maxNewSeats,
  disableSaveButton
}: Params) {
  const { handleResponseFailure } = useAuthContext()
  const [disabledAddDay, setDisabledAddDay] = useState(false)

  useEffect(() => {
    const current = slotList.reduce((prev, cur) => {
      const { seats = 0, filledSeats = 0 } = cur
      return prev + (seats - filledSeats)
    }, 0)

    if (current > maxNewSeats) {
      handleResponseFailure('Превышение допустимого планируемого веса к вывозу')
      setDisabledAddDay(true)
      disableSaveButton(true)

      return
    }

    if (current === maxNewSeats) {
      setDisabledAddDay(true)
      disableSaveButton(false)
      return
    }

    setDisabledAddDay(false)
    disableSaveButton(false)
  }, [slotList, maxNewSeats, handleResponseFailure, disableSaveButton])

  return (<div className='dispatcherBid_body_freeSpace'>
    <InfoCard title='Машино-места'>
      <>
        <div className='dispatcherBid_body_freeSpace_slotItems'>
        { slotList.map((slot, idx) => {
          const old = slot.shipmentTs ? slot.shipmentTs < today : false
          const seats = slot.seats !== undefined ? slot.seats - (slot?.filledSeats ?? 0) : undefined

          const weightDay = !!slot.seats && !!averageWeight ? slot.seats * averageWeight : 0
          const weightVehicle = averageWeight ?? 0

          return (<div className='dispatcherBid_body_freeSpace_slotItems_item' key={idx}>
              <Stack direction='row' spacing={2} width='100%' mb={1.5}>
                <DatePicker
                  width='150px'
                  label='Дата:'
                  value={slot.shipmentTs}
                  onChange={updateSlotshipmentTs(slot)}
                  shouldDisableDate={(day => dayjs().isAfter(day, 'day'))}
                  error={old && !!seats ? true : undefined}
                  disabled={old && !!slot?.filledSeats}
                />
                <InfoField
                  width='15%'
                  label='Запланированные рейсы'
                  value={slot.filledSeats}
                  style={{ minWidth: '10em' }}
                />
                <TextField
                  label='Свободные машино-места'
                  width='15%'
                  value={seats}
                  onChange={updateSlotSeats(slot)}
                  errorMessage={
                    (slotErrors && slot.seats === undefined)
                      ? 'Поле обязательно к заполнению'
                      : (seats ?? 0) > maxNewSeats
                          ? 'Значение превышено'
                          : undefined
                  }
                  styleContainer={{
                    minWidth: '11em'
                  }}
                />
                <InfoField
                  width='15%'
                  label='Итого машино-мест'
                  value={slot.seats}
                  style={{ minWidth: '10em' }}
                />
                <Stack direction='row' spacing={4} maxWidth='25%' minWidth='18%' alignItems='flex-end' pb='calc(0.4em + 10px)'>
                  <SquareButton
                    variant='contained'
                    color='error'
                    widthHeight='40px'
                    onClick={() => deleteSlot(slot)}
                    disabled={!!slot.filledSeats}
                  >
                    <DeleteForever style={{ color: '#ffffff' }}/>
                  </SquareButton>
                  <Box height='40px' display='flex' alignItems='center'>
                    <Typography sx={{
                      fontWeight: 500,
                      fontSize: '14px',
                      lineHeight: '17px',
                      color: '#B2B2B2'
                    }}>День: {Math.round(toTon(weightDay))} тн., ТС: {Math.round(toTon(weightVehicle))} тн.</Typography>
                  </Box>
                </Stack>
              </Stack>
            </div>)
        }) }
        <Stack direction='row' spacing={2} alignItems='baseline' width='100%'>
        { slotList.length === 0
          ? <Button
              fullWidth
              variant='contained'
              color='secondary'
              size='large'
              onClick={addSlot}
              sx={{ fontWeight: 500 }}
            >
              Добавить первый день погрузки
            </Button>
          : <>
            <Button
              variant='contained'
              color='secondary'
              size='large'
              onClick={addSlot}
              sx={{ width: '28%', minWidth: '20em', fontWeight: 500 }}
              disabled={disabledAddDay}
            >
              Добавить день погрузки
            </Button>
          </>
        }
        </Stack>
        </div>
      </>
    </InfoCard>
  </div>)
}
