import { Button, Typography } from '@mui/material'
import { Bid, BidFreeSpace } from 'api/bid'
import { useNavigate } from 'react-router-dom'
import formatDate from 'common/CarrierBids/TsToFormatDate'
import './styles.sass'
import FreeSpaceItem from '../../../ui/FreeSpaceItem'
import { useMainRoutes } from 'routes'
import RouteInfo from 'ui/RouteInfo'
import DataTable from 'ui/DataTable'
import { useMemo } from 'react'
import { MoreHoriz } from '@mui/icons-material'
import formatCargo from 'util/formatCargo'
import { toTon } from 'util/weight'

const MAX_ITEMS_FREESPACE = 5

export default function ViewTable ({ data }: {data: Bid[]}) {
  const navigate = useNavigate()
  const { links } = useMainRoutes()

  const rows = useMemo(() => {
    return data.map(item => ({
      ...item,
      cargo: formatCargo(item.cargo),
      truckRun: {
        loadingAddress: item.loadingAddress,
        unloadingAddress: item.unloadingAddress
      },
      totalWeight: toTon(item.totalWeight),
      dataRange: `${formatDate(item.beginTs)} - ${formatDate(item.endTs)}`
    }))
  }, [data])

  return (
    <>
      <div className='carrierBids_viewTable'>
        <DataTable
            columns = { [
              {
                id: 'num',
                label: '№',
                align: 'center',
                format: value => <Typography fontWeight={700} fontSize='14px'>{value as string}</Typography>
              },
              {
                id: 'truckRun',
                label: 'Пункт погрузки и разгрузки',
                minWidth: 170,
                format: value => <RouteInfo loadingAddress={(value as any).loadingAddress} unloadingAddress={(value as any).unloadingAddress} />
              },
              {
                id: 'cargo',
                label: 'Груз',
                format: value => <Typography fontWeight={700} fontSize='14px'>{value as string}</Typography>
              },
              {
                id: 'costTonKm',
                label: 'Ст-ть тн.',
                format: value => <Typography fontWeight={700} fontSize='14px'>{`${value} ₽`}</Typography>
              },
              {
                id: 'totalWeight',
                label: 'К вывозу',
                format: value => <Typography fontWeight={700} fontSize='14px'>{`${value} т`}</Typography>
              },
              {
                id: 'dataRange',
                label: 'Дата',
                format: value => <Typography fontWeight={700} fontSize='14px'>{value as string}</Typography>
              },
              {
                id: 'freeSpace',
                label: 'Свободные машино-места на перевозку',
                minWidth: 300,
                format: value => {
                  const items = value as BidFreeSpace[]
                  const count = items.length - MAX_ITEMS_FREESPACE
                  return (<div className='carrierBids_viewTable_freeSpaceRow'>
                  { items.slice(0, MAX_ITEMS_FREESPACE).map(item => <FreeSpaceItem size='small' data={item} key={item.shipmentTs} className='carrierBids_viewTable_freeSpaceRow_item'/>) }
                  { count > 0 && <div className='carrierBids_viewTable_freeSpaceRow_count'>+{count}</div> }
                  </div>)
                }
              },
              {
                id: 'id',
                label: '',
                align: 'center',
                minWidth: 10,
                format: value => <Button variant='contained' onClick={() => navigate(`${links.CARRIER_BIDS_PAGE}/${value}`)} style={{ background: '#0365F4', borderRadius: '4px', minWidth: '30px', maxWidth: '30px' }}><MoreHoriz style={{ color: '#fff' }} /></Button>
              }
            ] }
            rows = { (start, end) => rows === undefined ? [] : rows.slice(start, end) }
            rowsCount = { rows === undefined ? 0 : rows.length }
          ></DataTable>
        </div>
    </>
  )
}
