import { phoneClean, PHONE_MASK } from 'common/Mask/PhoneMask'
import { Meta } from 'entity/Meta'

export interface PreRegistrationInterface {
  phone: string
  password: string
  password2: string
}

const preReg = new Meta<PreRegistrationInterface>(
  {
    phone: { title: 'Номер телефона', mask: PHONE_MASK, cleanMask: phoneClean },
    password: { title: 'Пароль' },
    password2: { title: 'Подтверждение пароля' }
  }
)

export default preReg
