import { Cancel, CheckCircle, Info, Warning } from '@mui/icons-material'
import { Alert, AlertColor, AlertTitle, styled } from '@mui/material'
import { SnackbarContent, SnackbarKey, useSnackbar } from 'notistack'
import { ReactNode, forwardRef } from 'react'
import { Variant } from 'util/useEnqueueSnackbar'

interface Props {
  id: SnackbarKey;
  title?: string
  content: ReactNode;
  color?: AlertColor
  variant?: Variant
}

const StyledAlert = styled(Alert)(({ theme }) => ({
  fontSize: '13px',
  fontWeight: 400,
  minWidth: '300px',
  maxWidth: '560px',
  borderRadius: '4px',
  '&.MuiAlert-outlinedError': {
    color: '#ffffff',
    background: '#EE6A5F',
    borderColor: '#EE6A5F'
  },
  '&.MuiAlert-outlinedInfo': {
    color: '#ffffff',
    background: '#0A5DFF',
    borderColor: '#0A5DFF'
  },
  '&.MuiAlert-outlinedSuccess': {
    color: '#111111',
    background: '#F9FFED',
    borderColor: '#6DCD45'
  },
  '&.MuiAlert-outlinedWarning': {
    color: '#111111',
    background: '#FFF9EC',
    borderColor: '#FFA800'
  }
}))

const Snackbar = forwardRef<HTMLDivElement, Props>(({ content, id, color, variant, title }, ref) => {
  const { closeSnackbar } = useSnackbar()
  const handleCloseSnackbar = () => closeSnackbar(id)

  return (
    <SnackbarContent ref={ref} role='alert'>
      <StyledAlert
        severity={color}
        variant={variant ?? 'outlined'}
        onClose={handleCloseSnackbar}
        icon={color === 'error'
          ? <Cancel sx={{ color: '#FFFFFF' }} />
          : color === 'success'
            ? <CheckCircle sx={{ color: '#6DCD45' }} />
            : color === 'info'
              ? <Info sx={{ color: '#FFFFFF' }} />
              : color === 'warning'
                ? <Warning sx={{ color: '#FFA800' }} />
                : undefined
        }
      >
        { title && <AlertTitle sx={{ fontSize: '14px', fontWeight: 500 }}>{title}</AlertTitle> }
        {content}
      </StyledAlert>
    </SnackbarContent>
  )
})

export default Snackbar
