import { Box, Button, Stack, Typography } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { NotificationEvent, notificationList, DriverType, pushTest } from 'api/notify'
import TsToFormatDate from 'util/TsToFormatDate'
import TextField from 'ui/TextField'

export default function TestPush () {
  const [message, setMessage] = useState('Текст тестового push уведомления')
  const [list, setList] = useState<NotificationEvent[]>([])

  const init = useCallback(() => {
    notificationList({ filters: { types: [DriverType.push] } }).then(setList)
  }, [])

  useEffect(() => init(), [init])

  const send = () => {
    pushTest(message).then(() => init())
  }

  return (<>
    <Stack direction='row' spacing={1} bgcolor='#FFF'>
      <Box width='500px' height='fit-content' m='1em' p='1em' border='1px solid green'>
        <TextField rows={3} value={message} onChange={e => setMessage(e.target.value)} />
        <Stack direction='row' justifyContent='flex-end'>
          <Button variant='contained' color='success' onClick={send}>SEND</Button>
        </Stack>
      </Box>
      <Box display='flex' flexDirection='column' gap={1} p='1em'>
      {list.length > 0 && <Typography variant='h6'>Список отправленных push пользователю. <span style={{ color: 'red' }}>Обновляется с запозданием так как отправка идет асинхронно!</span></Typography> }
      {list.map(item => (<Stack direction='row' spacing={1} key={item.id} border='1px solid #EBEBEB' p='1em'>
        <Typography>Отправленно: {TsToFormatDate(item.createTs, 'dd.MM.yyyy HH:mm')};</Typography>
        <Typography>Сообщение: {item.body}</Typography>
      </Stack>))}
      </Box>
    </Stack>
  </>)
}
