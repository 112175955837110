import './styles.sass'
import BidCard from 'ui/BidCard'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { Bid, bidAllList, BidStatus, BidType } from 'api/bid'
import { Box, Button, Menu, MenuItem, Stack, Typography } from '@mui/material'
import { useMainRoutes } from 'routes'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Add, ArrowDownward, MoreHoriz, ArrowUpward, Check, CheckCircle, Clear, Edit, VerticalSplit } from '@mui/icons-material'
import NavigatePanel from 'ui/NavigatePanel'
import TabsRow, { Params as TabsRowPArams } from 'ui/NavigatePanel/TabsRow'
import ProgressBar from 'ui/ProgressBar'
import InfoCard from 'ui/InfoCard'
import InfoCell from 'ui/InfoCell'
import CollapseBox from 'ui/CollapseBox'
import TsToFormatDate from 'common/CarrierBids/TsToFormatDate'
import {
  shippingTruckRunList,
  ShippingTruckRunList as ShippingTruckRun,
  shippingTruckRunChangeStatus as truckRunChangeStatus,
  ShippingTruckRunStatus as TruckRunStatus
} from 'api/shipping'
import { personName } from 'util/personName'
import SquareButton from 'ui/SquareButton'
import { isId } from 'api/Id'
import None from 'ui/None'
import clsx from 'clsx'
import vehicleName from 'util/vehicleName'
import { isEnum } from 'util/isEnum'

export enum Tabs {
  all = 'all',
  managed = 'managed',
  relay = 'relay'
}

const TABS_ITEMS = [
  { label: 'Bce', value: Tabs.all },
  { label: 'Управляемые', value: Tabs.managed },
  { label: 'Переданные', value: Tabs.relay }
]

interface TruckRunItemParams {
  data: ShippingTruckRun
  overlimit?: boolean
  onApproved?: () => void
  onCancled?: () => void
  onUp?: () => void
  onDown?: () => void
  onClick?: () => void
}
const TruckRunItem = memo(({ overlimit, data, onApproved, onCancled, onUp, onDown, onClick }: TruckRunItemParams) => {
  const [isApproved, setApproved] = useState<boolean>(false)

  const descriptions = useMemo(() => [
    { label: 'Перевозчик', value: data.carrierName },
    { label: 'Водитель', value: personName(data.driver) },
    { label: 'ТС', value: vehicleName(data.vehicle, 'short') }
  ], [data])

  const approve = () => {
    if (onApproved) {
      onApproved()
      setApproved(true)
    }
  }

  const cancel = () => {
    if (onCancled) {
      onCancled()
    }
  }

  const up = () => {
    if (onUp) {
      onUp()
    }
  }

  const down = () => {
    if (onDown) {
      onDown()
    }
  }

  return (<div className='dispatcherBids_truckRunsPanel_content_items_item'>
    <div className='dispatcherBids_truckRunsPanel_content_items_item_info' onClick={onClick} style={{ cursor: onClick ? 'pointer' : undefined }}>
      <InfoCard className='dispatcherBids_truckRunsPanel_content_items_item_info_card'>
        <div className='dispatcherBids_truckRunsPanel_content_items_item_info_card_descriptions'>
        { descriptions.map((item, idx) => <InfoCell {...item} key={idx} />) }
        </div>
      </InfoCard>
    </div>
    <div className='dispatcherBids_truckRunsPanel_content_items_item_control'>
      { data.status === TruckRunStatus.new
        ? !overlimit
            ? <>
            { onApproved && <Button variant='contained' size='small' color='primary' style={{ minWidth: '32px', maxWidth: '32px' }} onClick={approve} >
              <Check style={{ color: '#ffffff' }}/>
            </Button>}
            { !isApproved && <Button variant='outlined' size='small' style={{ borderColor: '#B2B2B2', minWidth: '32px', maxWidth: '32px' }} onClick={down} >
              <ArrowDownward style={{ color: '#B2B2B2' }}/>
            </Button> }
          </>
            : <>
            <Button variant='outlined' size='small' style={{ borderColor: '#B2B2B2', minWidth: '32px', maxWidth: '32px' }} onClick={up} >
              <ArrowUpward style={{ color: '#B2B2B2' }}/>
            </Button>
            <Button variant='outlined' size='small' style={{ borderColor: '#EE6A5F', minWidth: '32px', maxWidth: '32px' }} onClick={cancel} >
              <Clear style={{ color: '#EE6A5F' }}/>
            </Button>
          </>
        : <></>}
    </div>
  </div>)
})

export enum SEARCH_PARAMS {
  bidId = 'bid_id',
  shipmentTs = 'shipment_ts'
}

export default function DispatcherBids () {
  const [bidList, setBidList] = useState<Bid[]>([])
  const [dayLimit, setDayLimit] = useState<number>(0)
  const [truckRunConfirmedList, setTruckRunConfirmedList] = useState<ShippingTruckRun[]>([])
  const [truckRunLimit, setTruckRunLimit] = useState<ShippingTruckRun[]>([])
  const [truckRunOverLimit, setTruckRunOverLimit] = useState<ShippingTruckRun[]>([])
  const [selectedBid, setSelectedBid] = useState<Bid>()
  const [progress, setProgress] = useState<number>(0)
  const [createMenu, setCreateMenu] = useState<null | HTMLElement>(null)
  const [currentTabTruckRuns, setCurrentTabTruckRuns] = useState<string>('')
  const { links, routesMap } = useMainRoutes()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { tab } = useParams()

  useEffect(() => {
    const bidId = searchParams.get(SEARCH_PARAMS.bidId)
    const shipmentTs = searchParams.get(SEARCH_PARAMS.shipmentTs)

    if (isId(bidId)) {
      setSelectedBid(bidList.find(item => item.id === bidId))
    }

    if (shipmentTs?.match(/\d{10}/)) {
      setCurrentTabTruckRuns(shipmentTs)
    }
  }, [searchParams, bidList])

  useEffect(() => {
    setSelectedBid(prev => prev === undefined ? prev : bidList.find(item => item.id === prev.id))
  }, [bidList])

  const breadcrumbsItems = useMemo(
    () => routesMap.getBreadcrumbs(links.DISPATCHER_BIDS_PAGE),
    [links, routesMap]
  )

  const truckRunTabsItems = useMemo<TabsRowPArams['items']>(() => {
    if (selectedBid !== undefined) {
      const result = selectedBid.freeSpace.map(item => {
        return {
          label: item.seats > 0
            ? <Typography color='#111111' sx={{ '& span': { color: '#B2B2B2' } }}>{TsToFormatDate(item.shipmentTs)} <span>({item.seats})</span></Typography>
            : <Stack direction='row' alignItems='center' spacing={1}><Typography>{ TsToFormatDate(item.shipmentTs) }</Typography><CheckCircle sx={{ color: '#3EAE49', width: '18px' }} /></Stack>,
          value: `${item.shipmentTs}`
        }
      })

      if (result.length > 0) {
        setCurrentTabTruckRuns(prev => prev !== '' ? prev : result[0].value)
      } else {
        setCurrentTabTruckRuns('')
      }

      return result
    }

    return []
  }, [selectedBid])

  const loadBidList = useCallback(() => {
    bidAllList({
      filters: {
        // status: [BidStatus.new, BidStatus.publish]
        type: isEnum(BidType)(tab) ? tab : undefined,
        onlyFreeSpacesAndNew: true
      }
    }).then(setBidList)
  }, [tab])

  useEffect(() => {
    loadBidList()
  }, [loadBidList])

  const loadTruckRunList = useCallback(() => {
    if (selectedBid !== undefined && currentTabTruckRuns !== '') {
      shippingTruckRunList({
        filters: {
          bidId: selectedBid.id,
          loadingTs: Number(currentTabTruckRuns),
          status: [
            TruckRunStatus.new,
            TruckRunStatus.way,
            TruckRunStatus.confirmed,
            TruckRunStatus.arrived,
            TruckRunStatus.completed,
            TruckRunStatus.archiveAct,
            TruckRunStatus.archiveProblem
          ]
        }
      })
        .then(result => {
          const freeSpace = selectedBid.freeSpace.find(item => item.shipmentTs === Number(currentTabTruckRuns))
          const limit = freeSpace !== undefined ? freeSpace.seats : 0
          const totalSeats = freeSpace !== undefined ? freeSpace.totalSeats : 0

          setTruckRunConfirmedList(result.filter(item => item.status !== TruckRunStatus.new))
          const newList = result.filter(item => item.status === TruckRunStatus.new)

          setDayLimit(limit)
          setTruckRunLimit(newList.slice(0, limit))
          setTruckRunOverLimit(newList.slice(limit))

          setProgress(limit === 0 || totalSeats === 0 ? 100 : Math.round((totalSeats - limit) * 100 / totalSeats))
        })
    }
  }, [selectedBid, currentTabTruckRuns])

  useEffect(() => {
    if (selectedBid === undefined || currentTabTruckRuns === '') {
      return
    }

    const searchParamsMap: Map<SEARCH_PARAMS, string> = new Map()
    searchParamsMap.set(SEARCH_PARAMS.bidId, selectedBid.id)
    searchParamsMap.set(SEARCH_PARAMS.shipmentTs, currentTabTruckRuns)

    const paramsStr = [...searchParamsMap].map(([key, value]) => `${key}=${value}`).join('&')

    if (paramsStr !== '') {
      navigate(`?${paramsStr}`)
    }
  }, [selectedBid, currentTabTruckRuns, navigate])

  useEffect(() => {
    loadTruckRunList()
  }, [selectedBid, currentTabTruckRuns, loadTruckRunList])

  const cancleTruckRun = ({ id }: ShippingTruckRun) => {
    return async () => {
      await truckRunChangeStatus({ list: [{ id, status: TruckRunStatus.archiveReject }] })
      loadTruckRunList()
      loadBidList()
    }
  }

  const downTruckRun = (data: ShippingTruckRun) => {
    return () => {
      setTruckRunLimit(prev => prev.filter(item => item.id !== data.id))
      setTruckRunOverLimit(prev => [...prev, data])
    }
  }

  const raiseTruckRun = (data: ShippingTruckRun) => {
    return () => {
      if (truckRunLimit.length < dayLimit) {
        setTruckRunOverLimit(prev => prev.filter(item => item.id !== data.id))
        setTruckRunLimit(prev => [...prev, data])
      }
    }
  }

  const confirmedTakeOut = async () => {
    if (truckRunLimit.length === 0) {
      return
    }

    await truckRunChangeStatus({ list: truckRunLimit.map(({ id }) => ({ id, status: TruckRunStatus.confirmed })) })
    loadTruckRunList()
    loadBidList()
  }

  const toTripPage = ({ id }: ShippingTruckRun) => () => navigate(`${links.CARRIER_TRIPS_PAGE}/${id}`)

  return (
    <div className='dispatcherBids'>
      <div className='dispatcherBids_bids'>
        <NavigatePanel
          breadcrumbs={{
            items: breadcrumbsItems
          }}
          title='Заявки на перевозку'
          actions={
            <>
            <Button
              variant='contained'
              size='small'
              endIcon={<Add />}
              onClick={({ currentTarget }) => setCreateMenu(currentTarget)}
              className='dispatcherBids__btn'
              style={{ background: '#0365F4' }}
            >
              Создать заявку
            </Button>
            <Menu
              anchorEl={createMenu}
              open={!!createMenu}
              onClose={ () => setCreateMenu(null) }
            >
              <MenuItem
                key='managed'
                onClick={() => navigate(links.DISPATCHER_BID_PAGE) }
                sx={{ display: 'flex', alignItems: 'start', justifyContent: 'start' }}
              >
                <Box sx={{ mr: '1em', color: '#B2B2B2' }}><VerticalSplit /></Box>
                <Box>Управляемую</Box>
              </MenuItem>
              <MenuItem
                key='relay'
                onClick={() => navigate(links.DISPATCHER_BID_PAGE + '/relay') }
                sx={{ display: 'flex', alignItems: 'start', justifyContent: 'start' }}
              >
                <Box sx={{ mr: '1em', color: '#B2B2B2' }}><VerticalSplit /></Box>
                <Box>Переданную</Box>
              </MenuItem>
            </Menu>
            </>
          }
          tabs={{
            items: TABS_ITEMS,
            value: tab ?? Tabs.all,
            onChange: (newTab) => navigate(newTab === Tabs.all ? links.DISPATCHER_BIDS_PAGE : `${links.DISPATCHER_BIDS_PAGE}/${newTab}`)
          }}
        />
        <div className='dispatcherBids_bids_content'>
          { bidList.length
            ? <div className='dispatcherBids_bids_content_items'>
          { bidList.map((item) => <BidCard
              className={clsx({ selectBid: selectedBid?.id === item.id })}
              data={item}
              key={item.id}
              controls={<Stack direction='row' spacing={1}>
                <SquareButton size='small' variant='outlined' color='secondary' onClick={() => navigate(`${links.DISPATCHER_BID_PAGE}/${item.id}`)} >
                  <Edit style={{ color: '#94A3B8', width: '16px' }} />
                </SquareButton>
                { item.status === BidStatus.publish && <SquareButton size='small' variant='contained'
                  onClick={() => {
                    setSelectedBid(item)
                    setCurrentTabTruckRuns('')
                  }} >
                  <MoreHoriz style={{ color: '#ffffff', width: '16px' }} />
                </SquareButton>}
              </Stack>}
            />) }
          </div>
            : <Box height='100%' display='flex'><None desc='Добавьте новую заявку на перевозку и даты погрузки для отображения информации' m='1.5em' /></Box> }
        </div>
      </div>
      <div className='dispatcherBids_truckRunsPanel'>
        { selectedBid !== undefined
          ? <div className='dispatcherBids_truckRunsPanel_content'>
              <div className='dispatcherBids_truckRunsPanel_content_tabs'>
                <Stack m='1.5em' mb={0} fontWeight={600} fontSize='16px'>Отклики</Stack>
                <TabsRow
                  items={truckRunTabsItems}
                  value={currentTabTruckRuns}
                  onChange={setCurrentTabTruckRuns}
                  scrollable
                />
              </div>
              <div className='dispatcherBids_truckRunsPanel_content_progress'>
                <div className='dispatcherBids_truckRunsPanel_content_progress_bar'>
                  <ProgressBar info={`${progress}%`} value={progress}/>
                </div>
                <div className='dispatcherBids_truckRunsPanel_content_progress_control'>
                  <Button variant='contained' size='small' color='primary' fullWidth disabled={truckRunLimit.length === 0} onClick={confirmedTakeOut}>Подтвердить лимит</Button>
                </div>
              </div>
              <div className='dispatcherBids_truckRunsPanel_content_items'>
                <CollapseBox title='Подтвержденные' className='dispatcherBids_truckRunsPanel_content_items_collapseBox'>
                  <>
                  { truckRunConfirmedList.map(item => <TruckRunItem data={item} key={item.id} onClick={toTripPage(item)} />) }
                  </>
                </CollapseBox>
                <CollapseBox title='Лимит машино-мест' className='dispatcherBids_truckRunsPanel_content_items_collapseBox'>
                  <>
                  { truckRunLimit.map(item => <TruckRunItem data={item} key={item.id} onDown={downTruckRun(item)} onClick={toTripPage(item)} />) }
                  </>
                </CollapseBox>
                <CollapseBox title='Выше лимита' className='dispatcherBids_truckRunsPanel_content_items_collapseBox'>
                  <>
                  { truckRunOverLimit.map(item => <TruckRunItem data={item} key={item.id} overlimit onUp={raiseTruckRun(item)} onCancled={cancleTruckRun(item)} onClick={toTripPage(item)} />) }
                  </>
                </CollapseBox>
              </div>
            </div>
          : <div className='dispatcherBids_truckRunsPanel_notSelectedBid'>Для просмотра откликов выберите заявку в списке слева</div> }
      </div>
    </div>
  )
}
