import { NotifyEvent } from '.'
import { useEffect } from 'react'

export interface Data {
  carrierName: string
  message: string
}

const SLUGS = ['carriers_new_carrier_event', 'carriers_update_carrier_event']

export default function CarrierNew ({ slug, data, toast } :NotifyEvent) {
  useEffect(() => {
    if (slug && !SLUGS.includes(slug)) {
      return
    }

    if (toast === undefined) {
      return
    }

    if (data === undefined) {
      return
    }

    const { message } = data as Data

    toast(message, 'info')
  }, [slug, data, toast])

  return (<></>)
}
