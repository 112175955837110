import { Box, Button, Stack } from '@mui/material'
import { Id, isId } from 'api/Id'
import Modal from 'ui/Modal'
import { bidAllUpdate } from 'api/bid'
import { useState } from 'react'
import { useAuthContext } from 'AuthContext'
import TextField from 'ui/TextField'

export interface Params {
  id?: Id
  onClose: () => void
  onBlocked: () => void
}

export default function BlockedManualFillModal ({ id, onClose, onBlocked }: Params) {
  const { handleResponseFailure } = useAuthContext()
  const [comment, setComment] = useState('')
  const [errorMessage, setErrorMessage] = useState<string>()

  const handleBlocked = async () => {
    if (!isId(id)) {
      return
    }

    setErrorMessage(undefined)

    if (comment.length === 0) {
      setErrorMessage('Поле обязательно к заполнению')
    }

    const success = await bidAllUpdate({ id, blockedManualFill: true, blockedManualFillComment: comment })

    if (success) {
      onBlocked()
      setComment('')
    } else {
      handleResponseFailure('Данные не были сохранены')
    }
  }

  const handleClose = () => {
    setErrorMessage(undefined)
    setComment('')
    onClose()
  }

  return (<Modal
    maxWidth='lg'
    open={isId(id)}
    onClose={handleClose}
    title='Ограничить создание рейсов'
    content={<Box minWidth='500px'>
      <TextField
        label='Комментарий'
        placeholder='Укажите комментарий для перевозчика'
        rows={3}
        value={comment}
        onChange={({ target: { value } }) => setComment(value)}
        errorMessage={errorMessage}
      />
    </Box>}
    actions={<>
      <Stack spacing={2} justifyContent='end' direction='row'>
        <Button variant='outlined' color='secondary' size='small' onClick={handleClose}>
          Отменить
        </Button>
        <Button variant='contained' size='small' color='primary' onClick={handleBlocked} >
          Ограничить
        </Button>
      </Stack>
    </>}
/>)
}
