import './styles.sass'
import { Dispatch, ReactNode, SetStateAction, useCallback, useEffect, useRef, useState } from 'react'
import { trailerList, TrailerStatus } from 'api/trailer'
import { vehicleList, VehicleStatus } from 'api/vehicle'
import { driverList, DriverStatus, VehicleTrailer } from 'api/driver'
import { personName } from 'util/personName'
import { Stack, Typography } from '@mui/material'
import Select from 'ui/Select'
import { Id, isId } from 'api/Id'
import vehicleName from 'util/vehicleName'
import trailerName from 'util/trailerName'

export interface InvolvedInShippingAndFree {
  loadingTs: number
  bidId: Id
}

export interface TruckRun {
  loadingTs: number
  driverId?: Id
  vehicleId?: Id
  trailerId?: Id
}

export interface RespondTruckRunsParams {
  truckRun?: TruckRun,
  setTruckRun: Dispatch<SetStateAction<TruckRun | undefined>>
  trips: number,
  setTrips: Dispatch<SetStateAction<number>>
  seatCount: number[]
  involvedInShippingAndFree?: InvolvedInShippingAndFree
  loadOptions?: boolean
  title?: ReactNode
}

export interface SelectOption {
  value: Id
  name: string
}

export default function RespondTruckRuns ({ truckRun, setTruckRun, trips, setTrips, title, seatCount, involvedInShippingAndFree, loadOptions }: RespondTruckRunsParams) {
  const [vehicleOptionsList, setVehicleOptionsList] = useState<SelectOption[]>([])
  const [trailersOptionsList, setTrailersOptionsList] = useState<SelectOption[]>([])
  const [driversOptionsList, setDriversOptionsList] = useState<(SelectOption & {vehicleTrailer?: VehicleTrailer[]})[]>([])

  const initOptions = useCallback(async () => {
    if (!loadOptions) {
      setDriversOptionsList([])
      setTrailersOptionsList([])
      setVehicleOptionsList([])
      return
    }

    driverList({ filters: { status: DriverStatus.active, involvedInShippingAndFree } }).then(result => {
      setDriversOptionsList(result.map(({ id, familyName, secondName, firstName, vehicleTrailer }) => {
        return {
          value: id,
          name: personName({ firstName, familyName, secondName }),
          vehicleTrailer
        }
      }))
    })

    vehicleList({ filters: { status: VehicleStatus.active, involvedInShippingAndFree } }).then(result => {
      setVehicleOptionsList(result.map(({ id, brand, model, ...doc }) => {
        return {
          value: id,
          name: `${vehicleName(doc, 'short')} - ${brand} ${model}`
        }
      }))
    })

    trailerList({ filters: { status: TrailerStatus.active, involvedInShippingAndFree } }).then(result => {
      setTrailersOptionsList(result.map(({ id, model, ...doc }) => {
        return {
          value: id,
          name: `${trailerName(doc)} - ${model}`
        }
      }))
    })
  }, [involvedInShippingAndFree, loadOptions])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { initOptions() }, [])

  const driverFilter = useRef<Id>()

  useEffect(() => {
    if (truckRun === undefined) {
      return
    }

    const { driverId } = truckRun

    if (driverId === driverFilter.current) {
      return
    }

    driverFilter.current = driverId
  }, [truckRun])

  const vehicleTrailer = (driverId: Id) => (driversOptionsList.find(({ value }) => value === driverId) ?? {}).vehicleTrailer ?? []
  const trailers = (driverId: Id, id: Id) => {
    const vt = vehicleTrailer(driverId)

    return trailersOptionsList
      .filter(({ value }) =>
        vt.length === 0 || vt.find(({ vehicleId, trailerId }) => id === vehicleId && trailerId === value)
      )
  }

  const vehicles = (id: Id) => {
    const vt = vehicleTrailer(id)

    return vehicleOptionsList
      .filter(({ value }) =>
        vt.length === 0 || vt.find(({ vehicleId }) => vehicleId === value)
      )
  }

  return (
    <div className='bidShippingRespondTruckRuns_items'>
      <div className='bidShippingRespondTruckRuns_items_item'>
        { title && <Typography fontWeight={600} fontSize='18px' mb='1em'>{title}</Typography> }
        <Stack direction='row' spacing={1} >
          <Select
            label='Водитель'
            placeholder='Выберите водителя'
            options={driversOptionsList}
            value={truckRun?.driverId}
            onChange={(driverId) => setTruckRun((data) => {
              const vehicleId = driversOptionsList.length === 1 ? driversOptionsList[0].value : undefined
              const tt = vehicleId ? trailers(driverId, vehicleId) : []
              const trailerId = tt.length === 1 && isId(tt[0].value) ? tt[0].value : undefined

              return data ? { ...data, driverId, vehicleId, trailerId } : data
            })}
            disableClearable={true}
            hideError={!!truckRun?.driverId}
            errorMessage='Обязательно к заполнению'
            width='30%'
          />
          { truckRun?.driverId && <Select
            label='ТС'
            placeholder='Выберите ТС'
            options={vehicles(truckRun?.driverId)}
            value={truckRun?.vehicleId}
            onChange={(vehicleId) => setTruckRun((data) => {
              const tt = data?.driverId ? trailers(data.driverId, vehicleId) : []
              const trailerId = tt.length === 1 && isId(tt[0].value) ? tt[0].value : undefined

              return data ? { ...data, vehicleId, trailerId } : data
            })}
            disableClearable={true}
            hideError={!!truckRun?.vehicleId}
            errorMessage='Обязательно к заполнению'
            width='30%'
          />}
          { truckRun?.driverId && truckRun?.vehicleId && <Select
            label='Прицеп'
            placeholder="Без прицепа"
            options={trailers(truckRun?.driverId, truckRun?.vehicleId)}
            value={truckRun?.trailerId}
            onChange={(trailerId) => setTruckRun((data) => data ? { ...data, trailerId } : data)}
            width='30%'
          />}
          { truckRun?.driverId && truckRun?.vehicleId && <Select
            label='Кол-во рейсов'
            placeholder='Выберите количество рейсов'
            options={seatCount.map((value) => ({ value, name: `${value}` })) }
            value={trips}
            onChange={(value) => setTrips(value === undefined ? 1 : value)}
            width='15%'
          />}
        </Stack>
      </div>
    </div>
  )
}
