import { ReactNode } from 'react'

interface InfoCellValueParams {
  value1: ReactNode
  value2?: ReactNode
  dotColor?: string
}

const InfoCellValue = ({ value1, value2, dotColor }: InfoCellValueParams) => {
  return (<span>
    {value1}
    {value2 && <span style={{ color: '#B2B2B2' }}>| {value2}</span>}
    { dotColor && <span style = {{
      height: '10px',
      width: '10px',
      backgroundColor: dotColor,
      borderRadius: '50%',
      display: 'inline-block',
      marginLeft: '0.5em'
    }}></span> }
  </span>)
}

export default InfoCellValue
