import { mainService } from 'api/service'
import { Id } from 'api/Id'
import { RequireOnly } from 'util/type/RequireOnly'
import { VehicleAxles } from './vehicle'
import { CarNumberType } from './CarNumberType'

export enum TrailerStatus {
  active = 'active',
  archive = 'archive',
}

export interface Trailer {
  id: Id
  brand: string
  model: string
  number: string
  numberRegion: string
  numberType: CarNumberType
  color: string
  axles: VehicleAxles
  status: TrailerStatus
  certificate: string
  certificateIndex: string
  scanPassport: string
  scanRegistration: string
  truckRuns?: Id[]
  archiveDate?: number
  archiveComment?: string
}

export type TrailerAll = Trailer & {
  owner: string
  additionalData?: {
    orgShortName: string
    ownerId: string
  }
}

type Update = Omit<Trailer, 'archiveDate' | 'archiveComment' | 'truckRuns' | 'status' | 'axles'> & { axlesSlug: string }
type UpdateAll = Omit<TrailerAll, 'archiveDate' | 'archiveComment' | 'truckRuns' | 'axles'> & { axlesSlug: string }

export type TrailerForCreate = Omit<Update, 'id'>
export type TrailerForUpdate = RequireOnly<Update, 'id'>
export type TrailerAllForCreate = Omit<UpdateAll, 'id'>
export type TrailerAllForUpdate = RequireOnly<UpdateAll, 'id'>

export interface TrailerAllFilter {
  owner?: string
  status?: TrailerStatus
  loadingTs?: number
  bidId?: Id
  includeAdditionalData?: boolean
}

export interface TrailerArchiveParams {
  id: Id
  date: number
  comment?: string
}

export type TrailerListParams = {
  filters?: {
    status?: TrailerStatus
    involvedInShippingAndFree?: {
      loadingTs: number
      bidId: Id
    },
  }
}

export interface TrailerCreateResult {
  success: boolean
  id: Id | null
  conflicts?: {
    double?: boolean
  }
}
export type TrailerAllCreateResult = TrailerCreateResult

const request = (method: string, params: object) => mainService.request(`trailer.${method}`, params)

export const trailerArchive = (params: TrailerArchiveParams) => request('archive', params) as Promise<boolean>
export const trailerCreate = (params: TrailerForCreate) => request('create', params) as Promise<TrailerCreateResult>
export const trailerUpdate = (params: TrailerForUpdate) => request('update', params) as Promise<boolean>
export const trailerGet = (id: Id) => request('get', { id }) as Promise<Trailer | null>
export const trailerList = (params: TrailerListParams = {}) => request('list', params) as Promise<Trailer[]>

export const trailerAllArchive = (params: TrailerArchiveParams) => request('all.archive', params) as Promise<boolean>
export const trailerAllCreate = (params: TrailerAllForCreate) => request('all.create', params) as Promise<TrailerAllCreateResult>
export const trailerAllUpdate = (params: TrailerAllForUpdate) => request('all.update', params) as Promise<boolean>
export const trailerAllGet = (id: Id) => request('all.get', { id }) as Promise<TrailerAll | null>
export const trailerAllList = (params: TrailerAllFilter = {}) => request('all.list', params) as Promise<TrailerAll[]>
export const trailerAllDelete = (id: Id) => request('all.delete', { id }) as Promise<boolean>
