import { Box, Button, Grid, Menu, MenuItem, Modal as MUIModal, Stack, styled, Typography } from '@mui/material'
import { TruckRunStatus, TruckRuns } from '..'
import { toKilogram, toTon } from 'util/weight'
import InfoCard from 'ui/InfoCard'
import { CheckCircle, Visibility, ArrowForward, ReportProblem } from '@mui/icons-material'
import InfoField from 'ui/InfoField'
import TextField from 'ui/TextField'
import DatePicker from 'ui/DatePicker'
import { shippingTruckRunUpdate } from 'api/shipping'
import TsToFormatDate from 'util/TsToFormatDate'
import SquareButton from 'ui/SquareButton'
import download from 'util/download'
import AntSwitch from 'ui/AntSwitch'
import CloseIcon from '@mui/icons-material/Close'
import { useEffect, useRef, useState } from 'react'
import onlyFields from 'util/onlyFields'
import { useMainRoutes } from 'routes'
import { useNavigate } from 'react-router-dom'
import { useAuthContext } from 'AuthContext'
import useValidate from 'validation/validate'
import schema from 'validation/TruckRunUpdateAtChecked'
import { Id } from 'api/Id'
import Modal from 'ui/Modal'
import PdfViewer from 'ui/PdfViewer'
import None from '../none'

const InputRow = styled(Stack)({
  flexDirection: 'row',
  gap: 2,
  alignItems: 'center',
  paddingTop: '5px',
  paddingLeft: '1em',
  paddingRight: '1em',
  borderBottom: '1px solid #EBEBEB'
})
export interface TruckRunEditParams {
  data: TruckRuns
  setData: React.Dispatch<React.SetStateAction<TruckRuns | undefined>>
  onSave: () => void
  disabled?: boolean
}

const WEIGHT_LIMIT = 50000

const TruckRunEdit = ({ data, disabled, setData, onSave }: TruckRunEditParams) => {
  const { links } = useMainRoutes()
  const navigate = useNavigate()
  const { handleResponseFailure, handleResponseSuccess } = useAuthContext()
  const [open, setOpen] = useState(false)
  const [scanBillMenu, setScanBillMenu] = useState<null | HTMLElement>(null)

  const { check, errors, cleanErrors } = useValidate(schema)

  const {
    id,
    wasEdited,
    isChecked,
    loadingTs,
    billNumber,
    billTs,
    weight = 0,
    finalWeight = 0,
    scanBills,
    weightCorrection,
    finalWeightCorrection
  } = data

  const [numData, setNumData] = useState({
    weight: `${toTon(weight)}`,
    finalWeight: `${toTon(finalWeight)}`
  })

  const clear = (data: TruckRuns) => onlyFields(data, 'billNumber', 'billTs', 'weight', 'finalWeight', 'wasEdited', 'incorrectScanBills')

  useEffect(() => {
    setNumData({
      weight: `${toTon(weight)}`,
      finalWeight: `${toTon(finalWeight)}`
    })
  }, [weight, finalWeight])

  // Этот код решает проблему с зависание errors при переходе с рейса на рейс
  const prevId = useRef<Id>()
  useEffect(() => {
    if (prevId.current === id) {
      return
    }

    prevId.current = id
    cleanErrors()
  }, [cleanErrors, id])

  const diasbled = isChecked

  const handleCancel = () => {
    setData(undefined)
    setOpen(false)
    cleanErrors()
  }

  const updateWeight = (key: 'weight' | 'finalWeight') => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      const { target: { value } } = e
      const val = value.replace(/[^\d.]/, '')

      setData({ ...data, wasEdited: true, [key]: toKilogram(Number(val)) })
      setNumData({ ...numData, [key]: val })
    }
  }

  const updateWeightByKis = (value: number, key: 'weight' | 'finalWeight') => {
    setData({ ...data, wasEdited: true, [key]: value })
    setNumData({ ...numData, [key]: toTon(value) })
  }

  const updateString = (key: keyof TruckRuns) => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      const { target: { value } } = e

      setData({ ...data, wasEdited: true, [key]: value })
    }
  }

  const updateDate = () => {
    return (billTs: number) => {
      setData({ ...data, wasEdited: true, billTs })
    }
  }

  const save = async () => {
    const clean = clear(data)

    if (!check(clean) || clean.weight > WEIGHT_LIMIT) {
      return
    }

    const { success } = data.wasEdited
      ? await shippingTruckRunUpdate({ ...clean, id, isChecked: true })
      : await shippingTruckRunUpdate({ id, isChecked: true })

    if (!success) {
      handleResponseFailure('Невозможно сохранить рейс')
      return
    }

    handleResponseSuccess('Рейс проверен')
    onSave()
    handleCancel()
  }

  const cancelCheck = async () => {
    const { success } = await shippingTruckRunUpdate({ id, isChecked: false })

    if (!success) {
      handleResponseFailure('Невозможно отменить изменения')
      return
    }

    handleResponseSuccess('Статус рейса изменен')
    onSave()
    handleCancel()
  }

  const shortage = toTon(weight - finalWeight)

  return (<>
    <Box width='100%' minWidth='450px' display='flex' justifyContent='center'>
      <InfoCard
        title={<Stack direction='row' spacing={2}><span>Рейс</span> { TruckRunStatus(data) }</Stack>}
        style={{ maxWidth: '500px', margin: '1.5em' }}
        actions={<Stack direction='row' spacing={1}>
          <Button
            size='small'
            variant='outlined'
            color='secondary'
            endIcon={<ArrowForward sx={{ color: '#B2B2B2' }} />}
            onClick={() => navigate(`${links.CARRIER_TRIPS_PAGE}/${id}`)}
          >К рейсу</Button>
          <SquareButton
            size='small'
            variant='text'
            color='inherit'
            onClick={handleCancel}
          ><CloseIcon sx={{ width: '16px', height: '16px' }} /></SquareButton>
        </Stack>}
      >
        <>
          <Box sx={{ '& div:last-child': { border: 'none' } }}>
            <InputRow>
              <Typography fontSize='13px' fontWeight={500} width='50%' mb='10px'>Дата рейса</Typography>
              <InfoField
                label=''
                value={TsToFormatDate(loadingTs, 'dd.MM.yyyy')}
                width='60%'
              />
            </InputRow>
            <InputRow>
              <Typography fontSize='13px' fontWeight={500} width='50%' mb='10px'>Номер ТТН/ТрН</Typography>
              <TextField
                name='billNumber'
                label=''
                value={billNumber}
                width='60%'
                onChange={updateString('billNumber')}
                disabled={diasbled}
                errors={errors}
              />
            </InputRow>
            <InputRow>
              <Typography fontSize='13px' fontWeight={500} width='50%' mb='10px'>Дата ТТН/ТрН</Typography>
              <DatePicker
                name='billTs'
                label=''
                value={billTs}
                onChange={updateDate()}
                width='60%'
                disabled={diasbled}
                errors={errors}
              />
            </InputRow>
            <InputRow sx={{ borderBottom: weightCorrection && weightCorrection !== weight ? 'none' : undefined }} >
              <Typography fontSize='13px' fontWeight={500} width='50%' mb='10px'>Вес погрузки, тн</Typography>
              <TextField
                name='weight'
                label=''
                value={numData.weight}
                width='60%'
                onChange={updateWeight('weight')}
                disabled={diasbled}
                errors={errors}
                errorMessage={data.weight && data.weight > WEIGHT_LIMIT ? `Вес погрузки должен быть не больше ${toTon(WEIGHT_LIMIT)}  тн.` : ''}
              />
            </InputRow>
            { weightCorrection && weightCorrection !== weight && <InputRow sx={{ paddingTop: 0, alignItems: 'center', paddingBottom: '10px' }}>
              <ReportProblem sx={{ color: '#EE6A5F', fontSize: '17px', mt: '0.2em' }}/>
              <Typography
                color='#EE6A5F'
                fontSize='13px'
                fontWeight={500}
                width='100%'
                align='center'
                sx={{ cursor: 'pointer' }}
                onClick={() => updateWeightByKis(weightCorrection, 'weight')}
              >Есть расхождения. Данные из КИС: {toTon(weightCorrection)}т</Typography>
            </InputRow> }
            <InputRow sx={{ borderBottom: finalWeightCorrection && finalWeightCorrection !== finalWeight ? 'none' : undefined }}>
              <Typography fontSize='13px' fontWeight={500} width='50%' mb='10px'>Вес разгрузки, тн</Typography>
              <TextField
                name='finalWeight'
                label=''
                value={numData.finalWeight}
                width='60%'
                onChange={updateWeight('finalWeight')}
                disabled={diasbled}
                errors={errors}
              />
            </InputRow>
            { finalWeightCorrection && finalWeightCorrection !== finalWeight && <InputRow sx={{ paddingTop: 0, alignItems: 'center', paddingBottom: '10px' }}>
              <ReportProblem sx={{ color: '#EE6A5F', fontSize: '17px', mt: '0.2em' }}/>
              <Typography
                color='#EE6A5F'
                fontSize='13px'
                fontWeight={500}
                width='100%'
                align='center'
                sx={{ cursor: 'pointer' }}
                onClick={() => updateWeightByKis(finalWeightCorrection, 'finalWeight')}
              >Есть расхождения. Данные из КИС: {toTon(finalWeightCorrection)}т</Typography>
            </InputRow> }
            <InputRow>
              <Typography fontSize='13px' fontWeight={500} width='50%' mb='15px'>
              { shortage >= 0 ? 'Вес недостачи, тн' : 'Вес излишка, тн' }
              </Typography>
              <InfoField
                label=''
                value={Math.abs(shortage)}
                width='60%'
                color={shortage > 0 ? 'shortage' : shortage < 0 ? 'surplus' : undefined}
              />
            </InputRow>
          </Box>
          <Stack direction='row' spacing={2} alignItems='center' mt='1em'>
            <SquareButton
              variant='outlined'
              color='secondary'
              onClick={({ currentTarget }) => {
                if (!scanBills || scanBills.length === 0) {
                  return
                }

                if (scanBills.length === 1) {
                  download(scanBills[0])
                } else {
                  setScanBillMenu(currentTarget)
                }
              }}
              disabled={(scanBills ?? []).length === 0}
            ><Visibility sx={{ color: '#b2b2b2' }} /></SquareButton>
            {scanBills && scanBills.length > 1 && <Menu
              anchorEl={scanBillMenu}
              open={!!scanBillMenu}
              onClose={ () => setScanBillMenu(null) }
            >
              {scanBills.map((scanBill, idx) =>
                <MenuItem key={idx} onClick={() => download(scanBill)}>Фото {idx + 1}</MenuItem>
              )}
            </Menu>}
            <Typography fontWeight={600} fontSize='14px'>Фото ТТН/ТрН</Typography>
            <Stack direction='row' justifyContent='left' alignItems='center' gap={1}>
              <AntSwitch
                checked={data.incorrectScanBills ?? false}
                onChange={() => {
                  setData({ ...data, incorrectScanBills: !data.incorrectScanBills, wasEdited: true })
                }}
                inputProps={{ 'aria-label': 'ant design' }}
                disabled={diasbled}
              />
              <Typography fontWeight={400} fontSize='14px'>Некорректное фото ТТН</Typography>
            </Stack>
          </Stack>
          { !isChecked && <Stack direction='row' spacing={2} justifyContent='space-between' mt={4} mb={1} >
            <Button
              startIcon={<CloseIcon />}
              variant='outlined'
              sx={{ width: '40%' }}
              onClick={handleCancel}
              disabled={disabled}
            >Отменить</Button>
            <Button
              variant='contained'
              color='success'
              sx={{ width: '60%' }}
              disabled={disabled}
              onClick={() => {
                const clean = clear(data)

                if (!check(clean) || clean.weight > WEIGHT_LIMIT) {
                  return
                }

                if (wasEdited) {
                  setOpen(true)
                  return
                }

                save()
              }}
            >Сохранить и подтвердить</Button>
          </Stack> }
          { isChecked && !wasEdited
            ? <Stack
                direction='row'
                spacing={2}
                justifyContent='space-between'
                alignItems='center'
                mt={4}
                mb={1}
                border='1px solid #6DCD45'
                borderRadius='4px'
                p='1em'
              >
              <Stack direction='row' spacing={1} >
                <CheckCircle color='success' sx={{ width: '24px' }} />
                <Typography fontWeight={500} fontSize='14px' >Рейс проверен</Typography>
              </Stack>
              <Button
                variant='outlined'
                color='secondary'
                sx={{ width: '40%' }}
                onClick={cancelCheck}
                disabled={disabled}
              >Отменить</Button>
            </Stack>
            : <></>
          }
        </>
      </InfoCard>
    </Box>
    <Modal
      title='Изменить данные рейса?'
      open={open}
      onClose={() => setOpen(false)}
      content={<Box minWidth='500px'>
        <Typography color='#B2B2B2' fontWeight={400} fontSize='14px'>При изменении данных рейса, вернуть старые будет нельзя, точно изменить?</Typography>
      </Box>}
      actions={<Stack direction='row' spacing={2}>
        <Button
          startIcon={<CloseIcon />}
          variant='outlined'
          color='secondary'
          onClick={handleCancel}
        >Отменить</Button>
        <Button
          variant='contained'
          onClick={save}
        >Подтвердить</Button>
      </Stack>}
    />
  </>)
}

export interface TruckRunEditModalParams {
  data?: TruckRuns
  setData: React.Dispatch<React.SetStateAction<TruckRuns | undefined>>
  onSave: () => void
  utdFilename?: string
  disabled?: boolean
}

export default function TruckRunEditModal ({ data, utdFilename, disabled, setData, onSave }: TruckRunEditModalParams) {
  return (<>
    <MUIModal
      open={data !== undefined}
    >
      <Box sx={{ width: '100vw', height: '100vh', overflowX: 'auto' }}>
        <Grid container height='100%'>
          <Grid item xs={12} md={6} lg={5} xl={3}>
          { data && <TruckRunEdit data={data} setData={setData} onSave={onSave} disabled={disabled} />}
          </Grid>
          <Grid item xs={12} md={6} lg={7} xl={9} >
            <Box
              width='100%'
              display='flex'
              justifyContent='center'
              height='100vh'
            >
              <PdfViewer
                filename={utdFilename}
                flexGrow={3}
                m='1.5em'
                borderRadius='8px'
                notFound={<None />}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </MUIModal>
  </>)
}
