import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton
} from '@mui/material'
import { Close } from '@mui/icons-material'
import './styles.sass'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useFiltersContext } from '../FiltersContext'
import TextField from 'ui/TextField'
import { Stack } from '@mui/system'
import { toKilogram, toTon } from 'util/weight'
import { toKilometers, toMeters } from 'util/distance'
import { zeroInUndefined } from 'util/valueMethod'

const ID_MODAL = 'modal-bid-filter'

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other} className='bidShippingRespondModal_title'>
      {children}
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 14,
          color: '#94A3B8'
        }}
      >
        <Close />
      </IconButton>
    </DialogTitle>
  )
}

interface ModalParams {
  modalOpen: boolean
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

function FilterModal ({ params }: {params: ModalParams}) {
  const { modalOpen, setModalOpen } = params
  const {
    setData,
    data,
    filterDistanceRange,
    filterTotalWeightRange,
    getDistanceRange,
    getTotalWeightRange,
    setFilterTotalWeightRange,
    setFilterDistanceRange
  } = useFiltersContext()
  const [distanceMin, setDistanceMin] = useState<number>(0)
  const [distanceMax, setDistanceMax] = useState<number>(0)

  const [totalWeightMin, setTotalWeightMin] = useState<number>(0)
  const [totalWeightMax, setTotalWeightMax] = useState<number>(0)

  const loaderDistanceRange = useCallback(() => {
    const r = getDistanceRange()
    if (r) {
      setDistanceMin(r[0])
      setDistanceMax(r[1])
    }
  }, [getDistanceRange])

  const loaderTotalWeightRange = useCallback(() => {
    const r = getTotalWeightRange()
    if (r) {
      const [min, max] = [r[0], r[1]]
      setTotalWeightMin(min)
      setTotalWeightMax(max)
    }
  }, [getTotalWeightRange])

  useEffect(() => {
    if (distanceMin === 0 && distanceMax === 0) {
      loaderDistanceRange()
    }

    if (totalWeightMin === 0 && totalWeightMax === 0) {
      loaderTotalWeightRange()
    }
  }, [distanceMax, distanceMin, loaderDistanceRange, loaderTotalWeightRange, setData, totalWeightMax, totalWeightMin])

  useEffect(() => {
    if (data === undefined) {
      setDistanceMin(0)
      setDistanceMax(0)
      setTotalWeightMin(0)
      setTotalWeightMax(0)
    }
  }, [setData, data])

  const handleReset = (): void => {
    setFilterDistanceRange(undefined)
    setFilterTotalWeightRange(undefined)
  }

  const handleSave = (): void => {
    setModalOpen(false)
  }

  const updateNumber = (exec: (number: number) => void) => {
    return (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value
      if (value.match(/^\d*$/)) {
        exec(Number(value))
      }
    }
  }

  return (
    <>
      <Dialog
        fullWidth
        onClose={() => setModalOpen(false)}
        aria-labelledby={ID_MODAL}
        open={modalOpen}
        className='bidFiltersModal'
      >
        <BootstrapDialogTitle id={ID_MODAL} onClose={() => setModalOpen(false)}>
          <div className='bidFiltersModal_header'>Другие фильтры</div>
        </BootstrapDialogTitle>
        <DialogContent className='bidShippingRespondModal_content'>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <TextField
                type='number'
                label='Мин. плечо перевозки, км'
                value={zeroInUndefined(toKilometers(filterDistanceRange ? filterDistanceRange[0] : distanceMin))}
                onChange={updateNumber(n => setFilterDistanceRange([toMeters(n), filterDistanceRange ? filterDistanceRange[1] : distanceMax]))}
                onBlur={() => {
                  if (filterDistanceRange && filterDistanceRange[0] < distanceMin) {
                    setFilterDistanceRange([distanceMin, filterDistanceRange[1]])
                  }
                }}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                type='number'
                label='Макс. плечо перевозки, км'
                value={zeroInUndefined(toKilometers(filterDistanceRange ? filterDistanceRange[1] : distanceMax))}
                onChange={updateNumber(n => setFilterDistanceRange([filterDistanceRange ? filterDistanceRange[0] : distanceMin, toMeters(n)]))}
                onBlur={() => {
                  if (filterDistanceRange && filterDistanceRange[1] > distanceMax) {
                    setFilterDistanceRange([filterDistanceRange[0], distanceMax])
                  }
                }}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                type='number'
                label='Мин. вес перевозки, т'
                value={zeroInUndefined(toTon(filterTotalWeightRange ? filterTotalWeightRange[0] : totalWeightMin))}
                onChange={updateNumber(n => setFilterTotalWeightRange([toKilogram(n), filterTotalWeightRange ? filterTotalWeightRange[1] : totalWeightMax]))}
                onBlur={() => {
                  if (filterTotalWeightRange && filterTotalWeightRange[0] < totalWeightMin) {
                    setFilterTotalWeightRange([totalWeightMin, filterTotalWeightRange[1]])
                  }
                }}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                type='number'
                label='Макс. вес перевозки, т'
                value={zeroInUndefined(toTon(filterTotalWeightRange ? filterTotalWeightRange[1] : totalWeightMax))}
                onChange={updateNumber(n => setFilterTotalWeightRange([filterTotalWeightRange ? filterTotalWeightRange[0] : totalWeightMin, toKilogram(n)]))}
                onBlur={() => {
                  if (filterTotalWeightRange && filterTotalWeightRange[1] > totalWeightMax) {
                    setFilterTotalWeightRange([filterTotalWeightRange[0], totalWeightMax])
                  }
                }}
              />
            </Grid>
            <Grid item md={12}>
              <Stack flexDirection='row' justifyContent='end' mt='20px' >
                <Button variant='outlined' onClick={handleReset} sx={{ mr: '10px' }}>Сбросить</Button>
                <Button variant='contained' className='bidFiltersModal_saveButton' onClick={handleSave}>Сохранить</Button>
              </Stack>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default function AdditionalFilters () {
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  return (
    <>
      <FilterModal params={{ modalOpen, setModalOpen }} />
      <Button
        variant='contained'
        onClick={() => setModalOpen(true)}
        sx={{ minWidth: '150px' }}
      >
        Другие фильтры
      </Button>
    </>
  )
}
