import { Box, Button, IconButton, Stack, Typography } from '@mui/material'
import { ShippingTruckRunExtBid, shippingGet, shippingAllGet, ShippingTruckRunStatus, shippingRefuse } from 'api/shipping'
import { useEffect, useMemo, useState } from 'react'
import { useParams, useNavigate, Navigate } from 'react-router-dom'
import './styles.sass'
import { isId } from 'api/Id'
import { useMainRoutes } from 'routes'
import NavigatePanel from 'ui/NavigatePanel'
import { Close } from '@mui/icons-material'
import InfoCard from 'ui/InfoCard'
import Map from 'ui/Osm'
import TsToFormatDate from 'util/TsToFormatDate'
import rub from 'util/formatRub'
import { personName } from 'util/personName'
import vehicleName from 'util/vehicleName'
import trailerName from 'util/trailerName'
import { useAuthContext } from 'AuthContext'
import { Interface } from 'api/Interface'
import FullAddress from 'ui/FullAddress'
import ChipTruckRunStatus from 'common/ChipTruckRunStatus'
import formatPhone from 'util/formatPhone'
import formateParties from 'util/formateParties'
import formatCargo from 'util/formatCargo'
import formatScaleLength from 'util/formatScaleLength'
import formatScaleMaxLoad from 'util/formatScaleMaxLoad'
import { toTon } from 'util/weight'
import { toKilometers } from 'util/distance'
import formatHeightRestriction from 'util/formatHeightRestriction'
import Alert from 'ui/Alert'
import InfoIcon from '@mui/icons-material/Info'

export default function CarrierShippingInfo () {
  const { currentInterface } = useAuthContext()
  const [data, setData] = useState<ShippingTruckRunExtBid>()
  const [found, setFound] = useState<boolean>()
  const params = useParams()
  const navigate = useNavigate()
  const { links, routesMap } = useMainRoutes()
  const isAdmin = currentInterface ? [Interface.administrator, Interface.chief_dispatcher, Interface.dispatcher, Interface.forwarder].includes(currentInterface) : false

  const apiShippingGet = isAdmin ? shippingAllGet : shippingGet

  const breadcrumbsItems = useMemo(
    () => routesMap.getBreadcrumbs(links.CARRIER_BIDS_PAGE),
    [links, routesMap]
  )

  const title = useMemo(
    () => `Рейс ${data?.num} по перевозке ${data?.bid.num} от ${TsToFormatDate(data?.loadingTs, 'dd.MM')}`,
    [data]
  )

  useEffect(() => {
    if (found !== undefined) {
      return
    }

    if (!isId(params.id)) {
      setFound(false)
      return
    }

    apiShippingGet(params.id).then(result => {
      if (result === null) {
        setFound(false)
      } else {
        setData(result)
        setFound(true)
      }
    })
  }, [found, params.id, apiShippingGet])

  const handleBack = () => {
    navigate(-1)
  }

  const getRoutesItems = (data: ShippingTruckRunExtBid) => {
    const items = [
      { label: 'Место погрузки:', value: <FullAddress address={data.bid.loadingAddress} /> },
      {
        label: 'Место разгрузки:',
        value: data.unloadingAddress
          ? <>
            <Box><FullAddress address={data.unloadingAddress} /></Box>
            <Box sx={{ color: '#B2B2B2', mt: 1 }}><FullAddress address={data.bid.unloadingAddress} /></Box>
          </>
          : <FullAddress address={data.bid.unloadingAddress} />
      }
    ]

    if (data.reservedUnloadingSlot) {
      const { from, to } = data.reservedUnloadingSlot

      items.push({
        label: 'Дата и время разгрузки:',
        value: <>{TsToFormatDate(from, 'dd.MM.yyyy')} | {TsToFormatDate(from, 'HH:mm')} — {TsToFormatDate(to, 'HH:mm')}</>
      })
    }
    return items
  }

  const handleRfuse = () => {
    isId(params.id) && shippingRefuse(params.id).then(() => navigate(-1))
  }

  if (found === undefined) {
    return <></>
  }

  if (!found) {
    return <Navigate to={links.HOME_PAGE} />
  }

  return (
    <>
      <div className='carrierShippingInfo'>
        <div className='carrierShippingInfo_content'>
          <NavigatePanel
            breadcrumbs={{
              defaultItems: breadcrumbsItems,
              items: [
                { title: 'Отклики на рассмотрении', to: `${links.CARRIER_BIDS_SHIPPING_PAGE}/new` },
                { title }
              ]
            }}
            title={title}
            actions={<IconButton onClick={() => handleBack()}>
              <Close />
            </IconButton>}
          />
          <div className='carrierShippingInfo_content_info'>
            { data && <>
              { data.unloadingAddress && <Alert color='#ffa800' sx={{ margin: '1em 1.5em' }} >
                <>
                  <Typography fontWeight={600} fontSize='18px' color='#ffffff'>Рейс был переадресован</Typography>
                </>
              </Alert> }
              <InfoCard className='carrierShippingInfo_content_infoCard'
                title={<Stack direction='row' alignItems='center'>
                  <Box>Маршрут</Box>
                  { data.unloadingAddress && <Box sx={{ color: '#ffa800', mt: 0.5, ml: 1 }}><InfoIcon /></Box> }
                </Stack>}
                items={getRoutesItems(data)}
              />
              <InfoCard className='carrierShippingInfo_content_infoCard'
                title='Информация о рейсе'
                items={[
                  { label: 'Статус', value: ChipTruckRunStatus(data.status) },
                  { label: 'Тип ТС:', value: data.bid.typeVehicle.length === 0 ? 'Любой' : data.bid.typeVehicle.map(item => item.name).join(', ') },
                  { label: 'Дата погрузки:', value: TsToFormatDate(data.actualLoadingTs ?? data.loadingTs, 'dd MMMM yyyy') },
                  { label: 'Общий вес перевозки, тн:', value: `${toTon(data.bid.totalWeight)} тн` },
                  { label: 'Стоимость груза за 1 тн:', value: rub(data.bid.costCargo) },
                  { label: 'Груз:', value: formatCargo(data.bid.cargo) },
                  { label: 'Расстояние:', value: `${toKilometers(data.bid.distance)} км` },
                  { label: 'Стоимость перевозки тн/км:', value: rub(data.bid.costTonKm) },
                  { label: 'Cтоимость перевозки 1 тн:', value: rub(data.bid.costTon) },
                  { label: 'Заказчик перевозки:', value: data.bid.customer.fullName },
                  { label: 'Грузим +10%:', value: data.bid.plusTen ? 'Да' : 'Нет' },
                  { label: 'ТС:', value: `${vehicleName(data.vehicle)} ${data.vehicle.color}` },
                  { label: 'Прицеп:', value: trailerName(data.trailer, { color: true }) },
                  { label: 'Водитель:', value: personName(data.driver) },
                  { label: 'Номер телефона:', value: formatPhone(data.driver.phone) }
                ]}
                actions={<>
                  { data.status === ShippingTruckRunStatus.new && <Button
                    variant='outlined'
                    size='small'
                    color='secondary'
                    sx={{ ml: '10px', color: '#475569' }}
                    onClick={handleRfuse}
                  >Отозвать отклик</Button>}
                </>}
              />
              <InfoCard className='carrierBid_content_infoCard'
                title='Информация о пункте погрузки'
                items={[
                  { label: 'Способ погрузки:', value: data.bid.loadingMethod },
                  { label: 'Длина весов:', value: formatScaleLength(data.bid.loadingWeighing) },
                  { label: 'Грузоподъемность весов:', value: formatScaleMaxLoad(data.bid.loadingWeighing) },
                  { label: 'Высота ТС:', value: formatHeightRestriction(data.bid.loadingWeighing) },
                  { label: 'Грузоотправитель', value: formateParties(data.bid.consignor) }
                ]} />
              <InfoCard className='carrierBid_content_infoCard'
                title='Информация о пункте разгрузки'
                items={[
                  { label: 'Способ разгрузки:', value: data.bid.unloadingMethod },
                  { label: 'Длина весов:', value: formatScaleLength(data.bid.unloadingWeighing) },
                  { label: 'Грузоподъемность весов:', value: formatScaleMaxLoad(data.bid.unloadingWeighing) },
                  { label: 'Высота ТС:', value: formatHeightRestriction(data.bid.unloadingWeighing) },
                  { label: 'Грузополучатель', value: formateParties(data.consignee ?? data.bid.consignee) }
                ]}
              />
            </>}
          </div>
        </div>
        <div className='carrierShippingInfo_map'>
        { data && <Map route={{ from: data.bid.loadingAddress.coordinates, to: (data.unloadingAddress ?? data.bid.unloadingAddress).coordinates }} /> }
        </div>
      </div>
    </>
  )
}
