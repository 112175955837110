import './styles.sass'
import BidCargoType from './BidCargoType'
import BidCargo from './BidCargo'

export default function AdminCargos () {
  return (
    <div className='adminCargos'>
      <div className='adminCargos__body'>
        <div className='adminCargos__body_content'>
          <BidCargoType />
          <BidCargo />
        </div>
      </div>
    </div>
  )
}
