import { CarNumberType } from 'api/CarNumberType'
import { TrailerNumberMask } from 'common/Mask/TrailerNumberMask'

export const validateTrailerNumber = (type?: CarNumberType, value?: string) => {
  if (!type) {
    return false
  }
  const mask = TrailerNumberMask(type)

  if (!mask || !value || value.length !== mask.length) return false

  for (let i = 0; i < mask.length; i++) {
    const maskElement = mask[i]
    if (maskElement instanceof RegExp) {
      if (!maskElement.test(value[i])) {
        return false
      }
    } else {
      return false
    }
  }
  return true
}
