import './styles.sass'
import { isId } from 'api/Id'
import { useAuthContext } from 'AuthContext'
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import useValidate from 'validation/validate'
import { schemaLtd, schemaSt, clear } from 'validation/ConsignorConsignee'
import { useMainRoutes } from 'routes'
import NavigatePanel from 'ui/NavigatePanel'
import { Button, CircularProgress, Stack } from '@mui/material'
import SaveIcon from '@mui/icons-material/Save'
import InfoCard from 'ui/InfoCard'
import TextField from 'ui/TextField'
import meta from 'entity/ConsignorConsignee'
import onlyFields from 'util/onlyFields'
import {
  ConsignorConsigneeStatus,
  ConsignorConsignee,
  ConsignorConsigneeForUpdate,
  consignorConsigneeGet,
  consignorConsigneeCreate,
  consignorConsigneeUpdate,
  ConsignorConsigneeUpdateHistory
} from 'api/consignorConsignee'
import { TypeLegalPerson } from 'api/organization'
import Select from 'ui/Select'
import {
  ORGANIZATION_INN_MASK,
  ORGANIZATION_INN_ST_MASK,
  ORGANIZATION_KPP_MASK,
  ORGANIZATION_OGRN_MASK,
  ORGANIZATION_OGRNIP_MASK
} from 'common/Mask/Organization'
import { phoneClean, PHONE_MASK } from 'common/Mask/PhoneMask'
import { EmailMask, EMAIL_PATTERN } from 'common/Mask/EmailMask'
import { isPhone } from 'util/isPhone'
import { ClearSharp } from '@mui/icons-material'
import { CBRBank, cbrFindBank } from 'api/cbr'
import validateBik from 'validation/validateBik'
import checkCheckingRusAccount from 'util/checkCheckingRusAccount'
import excludeFields from 'util/excludeFields'
import DataTable from 'ui/DataTable'
import TsToFormatDate from 'util/TsToFormatDate'

type UpdateParams = Omit<ConsignorConsigneeForUpdate, 'id' | 'phones' | 'emails'>
type MiscData = Pick<ConsignorConsignee, 'externalName' | 'externalType' | 'externalPhones' | 'externalEmails'>

type UpdateHistory = ConsignorConsigneeUpdateHistory['data'] & { updateTs: number }

const BIK_ERROR_MSG = 'Указанный БИК не найден в справочнике ЦБ'
const ACCOUNT_NOT_FOUND_MSG = 'Номер к/с не найден для указанного БИК'
const ACCOUNT_NOT_CORRECT_MSG = 'Некорректный к/с'

export default function AdminConsignorConsignee () {
  const { id } = useParams()

  const [data, setData] = useState<UpdateParams>({})
  const [miscData, setMiscData] = useState<MiscData>({})
  const [isNew, setIsNew] = useState(true)
  const [found, setFound] = useState<boolean>()
  const [edit, setEdit] = useState(true)
  const [phones, setPhones] = useState<string[]>([])
  const [phoneErrors, setPhoneErrors] = useState<number[]>([])
  const [emails, setEmails] = useState<string[]>([])
  const [emailErrors, setEmailErrors] = useState<number[]>([])
  const [isLtd, setIsLtd] = useState(false)
  const [bankData, setBankData] = useState<CBRBank>()
  const [disabledBankField, setDisabledBankField] = useState(true)
  const [searchBank, setSearchBank] = useState(false)
  const [bikError, setBikError] = useState<string>()
  const [accountError, setAccountError] = useState<string>()
  const [updateHistory, setUpdateHistory] = useState<UpdateHistory[]>()

  const requestBank = useRef(false)

  const navigate = useNavigate()
  const { check: checkLtd, errors: errorsLtd } = useValidate(schemaLtd)
  const { check: checkSt, errors: errorsSt } = useValidate(schemaSt)
  const { links } = useMainRoutes()

  const { handleResponseFailure, handleResponseSuccess } = useAuthContext()

  const save = async () => {
    const check = isLtd ? checkLtd : checkSt
    let clearData = onlyFields(clear(data), 'typeLegalPerson', 'inn', 'ogrn', 'kpp',
      'fullName', 'shortName', 'okpo', 'legalAddress', 'bankName', 'bik',
      'checkingAccountNumber', 'correspondentAccountNumber')

    if (!isLtd) {
      clearData = excludeFields(clearData, 'kpp')
    }

    const phoneErrors = phones.reduce((result, phone, idx) => (isPhone(phone) ? result : [...result, idx]), [] as number[])
    setPhoneErrors(phoneErrors)

    const emailErrors = emails.reduce((result, email, idx) => (email.match(EMAIL_PATTERN) ? result : [...result, idx]), [] as number[])
    setEmailErrors(emailErrors)

    let vbik = true
    if (bankData && clearData.bik && bankData.bik !== clearData.bik) {
      vbik = false
      setBikError(BIK_ERROR_MSG)
    }

    let vAccaunt = true
    if (!checkCheckingRusAccount(clearData.correspondentAccountNumber)) {
      setAccountError(ACCOUNT_NOT_CORRECT_MSG)
      vAccaunt = false
    }

    if (bankData?.accounts.find(item => item.correspondentAccountNumber === clearData.correspondentAccountNumber) === null) {
      setAccountError(ACCOUNT_NOT_FOUND_MSG)
      vAccaunt = false
    }

    if (!check(clearData) || !vbik || !vAccaunt || phoneErrors.length > 0 || emailErrors.length > 0) {
      console.log({ errorsLtd, errorsSt })
      return
    }

    if (isId(id)) {
      const result = await consignorConsigneeUpdate({ id, ...clearData, phones, emails })

      if (result) {
        handleResponseSuccess('Данные изменены')
        navigate(links.ADMIN_CONSIGNOR_CONSIGNEE_PAGE)
      }
    } else {
      const id = await consignorConsigneeCreate({ ...clearData, phones, emails })

      if (id !== null) {
        handleResponseSuccess('Организация создана')
        navigate(links.ADMIN_CONSIGNOR_CONSIGNEE_PAGE)
      }
    }
  }

  const init = useCallback(async () => {
    if (id === 'add') {
      setIsNew(true)
      setFound(true)
      return
    }

    const result = isId(id) ? await consignorConsigneeGet(id, { includeHistory: true }) : null

    if (result !== null) {
      const { id, phones, emails, status, externalName, externalType, externalPhones, externalEmails, updateHistory, ...data } = result
      setData(data)
      setPhones(phones)
      setEmails(emails)
      setIsLtd(data.typeLegalPerson === TypeLegalPerson.organization)
      setMiscData({ externalName, externalType, externalPhones, externalEmails })
      setIsNew(false)
      setFound(true)
      setEdit(status !== ConsignorConsigneeStatus.archive)
      setUpdateHistory(updateHistory?.map(({ updateTs, data }) => ({ updateTs, ...data })))
    } else {
      setFound(false)
      handleResponseFailure('Орнанизация не найдена')
    }
  }, [handleResponseFailure, id])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { init() }, [])

  const updateString = (key: keyof UpdateParams) => {
    return (e: ChangeEvent<HTMLInputElement>) => {
      setData({ ...data, [key]: e.target.value })
    }
  }

  const updateType = (v: TypeLegalPerson) => {
    setIsLtd(v === TypeLegalPerson.organization)

    if (v === TypeLegalPerson.st) {
      setData({ ...data, kpp: '', inn: '', ogrn: '', typeLegalPerson: v })
      return
    }

    setData({ ...data, inn: '', ogrn: '', typeLegalPerson: v })
  }

  const updateBik = (e: ChangeEvent<HTMLInputElement>) => {
    const bik = e.target.value
    setData({ ...data, bik })

    if (!validateBik(bik)) {
      return
    }

    if (requestBank.current) {
      return
    }

    requestBank.current = true
    setSearchBank(true)
    setDisabledBankField(true)
    cbrFindBank(bik)
      .then(response => {
        const { success, result, conflicts } = response

        setDisabledBankField(false)
        if (!success && conflicts?.bikNotFound) {
          setBankData(undefined)
          setBikError(BIK_ERROR_MSG)
          return
        }

        setBikError(undefined)
        setBankData(result ?? undefined)
      })
      .finally(() => {
        requestBank.current = false
        setSearchBank(false)
      })
  }

  const updateCorrespondentAccountNumber = (e: ChangeEvent<HTMLInputElement>) => {
    const correspondentAccountNumber = e.target.value

    setData({ ...data, correspondentAccountNumber })

    const clean = meta.cleanMask('correspondentAccountNumber', correspondentAccountNumber)

    if (!checkCheckingRusAccount(clean)) {
      setAccountError(ACCOUNT_NOT_CORRECT_MSG)
      return
    }

    setAccountError(undefined)

    if (clean.match(/\d{20}/) === null) {
      return
    }

    if (bankData?.accounts.find(item => item.correspondentAccountNumber === clean) === undefined) {
      setAccountError(ACCOUNT_NOT_FOUND_MSG)
    }
  }

  const columnsHistory = [
    { id: 'NameFull', label: 'Полное ​наименование' },
    { id: 'NameShort', label: 'Краткое ​наименование организации' },
    { id: 'INN', label: 'ИНН' }
  ]

  if (isLtd) {
    columnsHistory.push({ id: 'KPP', label: 'КПП' })
  }

  columnsHistory.push(
    { id: 'OGRN', label: 'ОГРН' },
    { id: 'OKPO', label: 'ОКПО' },
    { id: 'Address', label: 'Юридический адрес' },
    { id: 'Phones', label: 'Телефоны' },
    { id: 'Emails', label: 'Emails' }
  )

  if (found === undefined) {
    return <></>
  }

  if (!found) {
    return <Navigate to={links.HOME_PAGE} />
  }

  return (
    <div className='adminParty'>
      <NavigatePanel
        title={isNew
          ? 'Новая организация'
          : data.shortName
        }
        breadcrumbs={{
          items: [
            { title: 'Организации', to: links.ADMIN_CONSIGNOR_CONSIGNEE_PAGE },
            { title: isNew ? 'Добавить организацию' : data.shortName ?? '' }
          ]
        }}
        actions={ edit
          ? <Stack direction='row' spacing={2} justifyContent='end'>
            <Button variant='outlined' color='secondary' size='small' onClick={() => navigate(-1)}>
            Отменить
            </Button>
            <Button variant='contained' color='success' size='small' onClick={save}>
              Сохранить <SaveIcon sx={{ width: '15px', height: '15px', ml: '10px' }}/>
            </Button>
          </Stack>
          : undefined
        }
      />
      <div className='adminParty__body'>
        <div className='adminParty__content'>
          <InfoCard title='Реквизиты компаний'><>
            <Stack direction='row' justifyContent='space-between' spacing={2}>
              <TextField
                name='shortName'
                label={meta.title('shortName')}
                placeholder='Краткое ​наименование организации'
                value={data.shortName}
                onChange={updateString('shortName')}
                width='50%'
                errors={isLtd ? errorsLtd : errorsSt}
                disabled={!edit}
              />
              <TextField
                name='fullName'
                label={meta.title('fullName')}
                placeholder='Полное ​наименование организации'
                value={data.fullName}
                onChange={updateString('fullName')}
                width='50%'
                errors={isLtd ? errorsLtd : errorsSt}
                disabled={!edit}
              />
            </Stack>
            <Stack direction='row' justifyContent='space-between' spacing={2}>
              <Select
                name='typeLegalPerson'
                label={meta.title('typeLegalPerson')}
                placeholder='Выберите тип организации'
                options={[
                  { value: TypeLegalPerson.st, name: 'ИП' },
                  { value: TypeLegalPerson.organization, name: 'Организация' }
                ]}
                value={data.typeLegalPerson}
                onChange={updateType}
                disableClearable={true}
                errors={isLtd ? errorsLtd : errorsSt}
                width='33%'
              />
              <TextField
                name='inn'
                label={meta.title('inn')}
                value={data.inn}
                onChange={updateString('inn')}
                width='33%'
                placeholder={isLtd ? 'XXXXXXXXXX' : 'XXXXXXXXXXXX'}
                maskParams={{
                  mask: isLtd ? ORGANIZATION_INN_MASK : ORGANIZATION_INN_ST_MASK
                }}
                errors={isLtd ? errorsLtd : errorsSt}
                disabled={!edit}
              />
              <TextField
                name='ogrn'
                label={meta.title('ogrn')}
                value={data.ogrn}
                onChange={updateString('ogrn')}
                width='33%'
                placeholder={isLtd ? 'X-XX-XX-XXXXXXX-X' : 'X-XX-XX-XXXXXXXXX-X' }
                maskParams={{
                  mask: isLtd ? ORGANIZATION_OGRN_MASK : ORGANIZATION_OGRNIP_MASK
                }}
                errors={isLtd ? errorsLtd : errorsSt}
                disabled={!edit}
              />
              { !isLtd && <TextField
                name='okpo'
                width='33%'
                label={meta.title('okpo')}
                value={data.okpo}
                onChange={updateString('okpo')}
                placeholder='XXXXXXXX'
                maskParams={{
                  mask: meta.mask('okpo')
                }}
                errors={isLtd ? errorsLtd : errorsSt}
                disabled={!edit}
              />}
            </Stack>
            { isLtd && <Stack direction='row' justifyContent='space-between' spacing={2}>
              <TextField
                name='kpp'
                label={meta.title('kpp')}
                placeholder='XXXXXXXXX'
                value={data.kpp}
                onChange={updateString('kpp')}
                width='50%'
                errors={isLtd ? errorsLtd : errorsSt}
                maskParams={{
                  mask: ORGANIZATION_KPP_MASK
                }}
                disabled={!edit}
              />
              <TextField
                name='okpo'
                width='50%'
                label={meta.title('okpo')}
                value={data.okpo}
                onChange={updateString('okpo')}
                placeholder={isLtd ? 'XXXXXXXX' : 'XXXXXXXXXX'}
                maskParams={{
                  mask: meta.mask('okpo')
                }}
                errors={isLtd ? errorsLtd : errorsSt}
                disabled={!edit}
              />
            </Stack> }
            <Stack direction='row' justifyContent='space-between' spacing={2}>
              <TextField
                name='bik'
                label={meta.title('bik')}
                placeholder='XX-XX-XX-XXX'
                value={data.bik}
                onChange={updateBik}
                width='25%'
                errors={isLtd ? errorsLtd : errorsSt}
                maskParams={{
                  mask: meta.mask('bik')
                }}
                disabled={!edit || searchBank}
                errorMessage={bikError}
                endAdornment={searchBank && <CircularProgress size='30px' sx={{ mr: '1em' }} />}
              />
              <TextField
                name='correspondentAccountNumber'
                label={meta.title('correspondentAccountNumber')}
                placeholder='XXXXXXXXXXXXXXXXXXXX'
                value={data.correspondentAccountNumber}
                onChange={updateCorrespondentAccountNumber}
                width='25%'
                errors={isLtd ? errorsLtd : errorsSt}
                maskParams={{
                  mask: meta.mask('correspondentAccountNumber')
                }}
                disabled={!edit || disabledBankField}
                errorMessage={accountError}
              />
              <TextField
                name='bankName'
                label={meta.title('bankName')}
                placeholder='Наименование банка'
                value={data.bankName}
                onChange={updateString('bankName')}
                width='25%'
                errors={isLtd ? errorsLtd : errorsSt}
                disabled={!edit || disabledBankField}
              />
              <TextField
                name='checkingAccountNumber'
                label={meta.title('checkingAccountNumber')}
                placeholder='XXX.XX.XXX.X.XXXX.XXXXXXX'
                value={data.checkingAccountNumber}
                onChange={updateString('checkingAccountNumber')}
                width='25%'
                errors={isLtd ? errorsLtd : errorsSt}
                maskParams={{
                  mask: meta.mask('checkingAccountNumber')
                }}
                disabled={!edit}
              />
            </Stack>
            <Stack direction='row' justifyContent='space-between' spacing={2}>
              <TextField
                name='legalAddress'
                label={meta.title('legalAddress')}
                placeholder='Юридический адрес'
                value={data.legalAddress}
                onChange={updateString('legalAddress')}
                width='77%'
                errors={isLtd ? errorsLtd : errorsSt}
                disabled={!edit}
              />
            </Stack>
          </></InfoCard>
          <Stack direction='row' gap={2}>
            <InfoCard title={meta.title('phones')}><>
                {phones.map((phone, idx) => <Stack direction='row' alignItems='center' key={idx}>
                  <TextField
                    placeholder='+7 (999) 999-99-99'
                    value={phone}
                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => setPhones(([...phones]) => {
                      phones[idx] = phoneClean(value)
                      return phones
                    })}
                    width='100%'
                    maskParams={{
                      mask: PHONE_MASK
                    }}
                    errorMessage={phoneErrors.includes(idx) ? 'Некорректно заполнен телефон' : undefined}
                    disabled={!edit}
                  />
                  {edit && <Button
                    variant='outlined'
                    size='small'
                    style={{ borderColor: '#EE6A5F', marginBottom: '5px', height: '32px', minWidth: '32px', maxWidth: '32px' }}
                    onClick={() => setPhones(([...phones]) => {
                      phones.splice(idx, 1)
                      return phones
                    })}
                  >
                    <ClearSharp style={{ color: '#EE6A5F' }}/>
                  </Button>}
                </Stack>)}
                {edit && <Button onClick={() => setPhones((phones) => [...phones, ''])}>Добавить</Button>}
            </></InfoCard>
            <InfoCard title={meta.title('emails')}><>
                {emails.map((email, idx) => <Stack direction='row' alignItems='center' key={idx}>

                  <TextField
                    placeholder='Укажите адрес электронной почты'
                    value={email}
                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => setEmails(([...emails]) => {
                      emails[idx] = value
                      return emails
                    })}
                    width='100%'
                    maskParams={{
                      mask: EmailMask,
                      guide: false
                    }}
                    errorMessage={emailErrors.includes(idx) ? 'Некорректно заполнен email' : undefined}
                    disabled={!edit}
                  />
                  {edit && <Button
                    variant='outlined'
                    size='small'
                    style={{ borderColor: '#EE6A5F', marginBottom: '5px', height: '32px', minWidth: '32px', maxWidth: '32px' }}
                    onClick={() => setEmails(([...emails]) => {
                      emails.splice(idx, 1)
                      return emails
                    })}
                  >
                    <ClearSharp style={{ color: '#EE6A5F' }}/>
                  </Button>}
                </Stack>)}
                {edit && <Button onClick={() => setEmails((emails) => [...emails, ''])}>Добавить</Button>}
            </></InfoCard>
          </Stack>
          <InfoCard title='КИС'><>
            <Stack direction='row' justifyContent='space-between' spacing={2}>
              <TextField
                name='externalId'
                label='ID'
                placeholder='ID'
                value={data.externalId}
                onChange={updateString('externalId')}
                width='100%'
                errors={isLtd ? errorsLtd : errorsSt}
                disabled={true}
              />
            </Stack>
            <Stack direction='row' justifyContent='space-between' spacing={2}>
              <TextField
                name='externalName'
                label='Наименование'
                value={miscData.externalName}
                width='25%'
                disabled={true}
              />
              <TextField
                name='externalType'
                label='Тип'
                value={miscData.externalType}
                width='25%'
                disabled={true}
              />
              <TextField
                name='externalPhones'
                label='Телефоны'
                value={miscData.externalPhones}
                width='25%'
                disabled={true}
              />
              <TextField
                name='externalEmails'
                label='Email'
                value={miscData.externalEmails}
                width='25%'
                disabled={true}
              />
            </Stack>
          </></InfoCard>
          { updateHistory && updateHistory.length > 0 && <InfoCard title='История обновления данных из КИС'>
            <>
            <DataTable
              columns = { [
                {
                  id: 'updateTs',
                  label: 'Время последнего обновления',
                  format: (updateTs) => <>{typeof updateTs === 'number' && TsToFormatDate(updateTs, 'dd.MM.yyyy - HH:mm')}</>
                },
                ...columnsHistory
              ] }
              rows = { (start, end) => updateHistory.slice(start, end) }
              rowsCount = { updateHistory.length }
            />
            </>
          </InfoCard> }
        </div>
      </div>
    </div>
  )
}
