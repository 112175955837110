import { LoginInterface } from 'entity/Login'
import { makeJsonSchema, SimpleSchema } from 'validation/validate'

export const simpleSchema: SimpleSchema<LoginInterface> = {
  type: 'object',
  properties: {
    phone: { type: 'string', pattern: '^\\+[1-9]{2}\\d{9}$', errorMessage: 'Номер телефона введен неверно' },
    password: { type: 'string', pattern: '\\w+', errorMessage: 'Введите пароль' }
  }
}

export const schema = makeJsonSchema<LoginInterface>(simpleSchema)
