import { Tabs, Tab } from '@mui/material'
import { memo, ReactNode } from 'react'
import './styles.sass'

export interface Item {
  label: ReactNode
  value: string
}

export interface Params {
  items: Item[]
  value: string
  onChange: (newValue: string) => void
  onClick?: (value: string) => void
  scrollable?: boolean
  compare?: (items: Item[], value: string) => number
}

const TabsRow = memo(({ items, value, onChange, onClick, scrollable, compare }: Params) => {
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    onChange(items[newValue].value)
  }

  const index = compare ? compare(items, value) : items.findIndex(item => item.value === value)

  return (
    <Tabs
      className='main_tabs'
      value={index !== -1 ? index : false }
      onChange={handleChange}
      variant={scrollable ? 'scrollable' : undefined}
      scrollButtons={scrollable}
      allowScrollButtonsMobile={scrollable}
      sx={{
        '& .MuiTabs-indicator': {
          background: '#0A5DFF'
        }
      }}
    >
    { items.map(({ label, value }, idx) => <Tab onClick={() => onClick && onClick(value) } label={label} tabIndex={idx} key={idx} sx={{
      textTransform: 'none',
      fontSize: '13px',
      fontWeight: 500,
      '& p': {
        textTransform: 'none',
        fontSize: '13px',
        fontWeight: 500
      }
    }}/>) }
    </Tabs>
  )
})

export default TabsRow
