import { mainService } from 'api/service'
import { Id } from 'api/Id'
import { RequireOnly } from 'util/type/RequireOnly'
import { Optional } from 'util/type/Optional'

export enum PassportStatus {
  active = 'active',
  blocked = 'blocked',
  expires = 'expires',
}

export interface Passport {
  status: PassportStatus
  passportSeries: string
  passportNum: string
  issuedDepartment: string
  departmentCode: string
  registrationAddress: string
  dateIssue: number
  dateOfBirth: number

  scan1?: string
  scan2?: string
}

export type PassportAll = Passport & { id: Id, owner: string }

export type PassportForCreate = Omit<Passport, 'status'>
export type PassportForUpdate = Partial<PassportForCreate>

export type PassportAllForCreate = Omit<Optional<PassportAll, 'status'>, 'id'>
export type PassportAllForUpdate = RequireOnly<Omit<PassportAll, 'owner'>, 'id'>
export interface PassportAllFilter {
  owner?: string[]
}

const request = (method: string, params: object) => mainService.request(`passport.${method}`, params)

export const passportCreate = (params: PassportForCreate) => request('create', params) as Promise<boolean>
export const passportUpdate = (params: PassportForUpdate) => request('update', params) as Promise<boolean>
export const passportGet = () => request('get', {}) as Promise<Passport | null>

export const passportAllCreate = (params: PassportAllForCreate) => request('all.create', params) as Promise<Id | null>
export const passportAllUpdate = (params: PassportAllForUpdate) => request('all.update', params) as Promise<boolean>
export const passportAllGet = (id: Id) => request('all.get', { id }) as Promise<PassportAll | null>
export const passportAllList = (params: PassportAllFilter = {}) => request('all.list', params) as Promise<PassportAll[]>
export const passportAllDelete = (id: Id) => request('all.delete', { id }) as Promise<boolean>
