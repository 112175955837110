import { Mask } from 'react-text-mask'

export class Meta<T extends Record<string, any>> {
  constructor (
    protected fields: {
      [K in keyof T]: {
        title: string,
        format?: (value: unknown) => string
        mask?: Mask
        cleanMask?: (value: string) => string
      }
    }
  ) {}

  public isKey (x: unknown): x is keyof T {
    return typeof x === 'string' && Object.keys(this.fields).includes(x)
  }

  public title (key: keyof T): string {
    return this.fields[key].title
  }

  public format <K extends keyof T> (key: K, value: unknown): string {
    const { format } = this.fields[key]
    return format === undefined ? `${value}` : format(value)
  }

  public mask (key: keyof T): Mask {
    return this.fields[key].mask ?? false
  }

  public cleanMask <K extends keyof T> (key: K, value: string): string {
    const { cleanMask } = this.fields[key]
    return cleanMask === undefined ? `${value}` : cleanMask(value)
  }
}
