import { PropsWithChildren, createContext, useContext, useState, useMemo } from 'react'
import { Bid } from 'api/bid'
import { Id } from 'api/Id'

type FilterTypeVehicle = string[]
type FilterCargo = string[]
type CargoList = string[]
type CostTonRange = [number, number] | null
type AddressIds = Id[]
type TotalWeightRange = [number, number] | null
type DistanceRange = [number, number] | null

export type InitialData = Bid[]
interface Context {
  getCostTonRange: () => CostTonRange
  getTotalWeightRange: () => TotalWeightRange
  getDistanceRange: () => DistanceRange
  getCargolist: () => CargoList
  setFilterTypeVehicle: (value: FilterTypeVehicle) => void
  setFilterCargo: (value: FilterCargo) => void
  setFilterCostTon: (value: CostTonRange) => void
  setFilterLoadingAddress: (value: AddressIds) => void
  setFilterUnloadingAddress: (value: AddressIds) => void
  setFilterDistanceRange: (value: DistanceRange | undefined) => void
  setFilterTotalWeightRange: (value: TotalWeightRange | undefined) => void
  setData: (data: InitialData) => void
  clear: () => void
  data: InitialData
  filterLoadingAddress?: Id[]
  filterUnloadingAddress?: Id[]
  filterCargo?: FilterCargo
  filterTypeVehicle?: FilterTypeVehicle
  filterCostTon?: CostTonRange
  filterDistanceRange?: DistanceRange
  filterTotalWeightRange?: TotalWeightRange
}

const FiltersContext = createContext<Context | null>(null)

export function FiltersContextProvider ({ children }: PropsWithChildren<{}>) {
  const [initialData, setInitialData] = useState<InitialData>([])
  const [filterTypeVehicle, initFilterTypeVehicle] = useState<FilterTypeVehicle>()
  const [filterCargo, initFilterCargo] = useState<FilterCargo>()
  const [filterCostTon, initFilterCostTon] = useState<CostTonRange>()
  const [filterLoadingAddress, initFilterLoadingAddress] = useState<AddressIds>()
  const [filterUnloadingAddress, initFilterUnloadingAddress] = useState<AddressIds>()
  const [filterDistanceRange, initFilterDistanceRange] = useState<DistanceRange>()
  const [filterTotalWeightRange, initTotalWeightRange] = useState<TotalWeightRange>()

  const viewData = useMemo(() => {
    return initialData.filter((data: Bid) => {
      let result: boolean = true
      result = true

      if (filterTypeVehicle && filterTypeVehicle.length) {
        // Считаем что пустой массив typeVehicleSlugs === соответствие любому типу ТС
        result &&= data.typeVehicle.length === 0
          ? true
          : data.typeVehicle.filter(v => filterTypeVehicle.includes(v.slug)).length > 0
      }

      if (filterCargo && filterCargo.length) {
        result &&= filterCargo.includes(data.cargo.name)
      }

      if (filterCostTon) {
        const [min, max] = filterCostTon
        result &&= data.costTon >= min && data.costTon <= max
      }

      if (filterLoadingAddress && filterLoadingAddress.length) {
        result &&= filterLoadingAddress.includes(data.loadingAddress.id)
      }

      if (filterUnloadingAddress && filterUnloadingAddress.length) {
        result &&= filterUnloadingAddress.includes(data.unloadingAddress.id)
      }

      if (filterDistanceRange) {
        const [min, max] = filterDistanceRange
        result &&= data.distance >= min && data.distance <= max
      }

      if (filterTotalWeightRange) {
        const [min, max] = filterTotalWeightRange
        result &&= data.totalWeight >= min && data.totalWeight <= max
      }

      return result
    })
  }, [
    initialData,
    filterTypeVehicle,
    filterCargo,
    filterCostTon,
    filterLoadingAddress,
    filterUnloadingAddress,
    filterDistanceRange,
    filterTotalWeightRange
  ])

  const setFilterTypeVehicle = (value: FilterTypeVehicle): void => {
    initFilterTypeVehicle(value)
  }

  const setFilterCargo = (value: FilterCargo): void => {
    initFilterCargo(value)
  }

  const setFilterCostTon = (value: CostTonRange): void => {
    initFilterCostTon(value)
  }

  const setFilterLoadingAddress = (value: AddressIds): void => {
    initFilterLoadingAddress(value)
  }

  const setFilterUnloadingAddress = (value: AddressIds): void => {
    initFilterUnloadingAddress(value)
  }

  const setFilterDistanceRange = (value: DistanceRange | undefined): void => {
    initFilterDistanceRange(value)
  }

  const setFilterTotalWeightRange = (value: TotalWeightRange | undefined): void => {
    initTotalWeightRange(value)
  }

  const setData = (value: InitialData): void => {
    setInitialData(value)
  }

  const clear = () => {
    initFilterLoadingAddress(undefined)
    initFilterUnloadingAddress(undefined)
    initFilterCargo(undefined)
    initFilterTypeVehicle(undefined)
    initFilterCostTon(undefined)
    initFilterDistanceRange(undefined)
    initTotalWeightRange(undefined)
  }

  const getCargolist = (): CargoList => {
    if (initialData) {
      const list = initialData.map(data => {
        return data.cargo.name
      })

      return [...new Set(list)]
    }

    return []
  }

  const getCostTonRange = (): CostTonRange => {
    if (initialData) {
      const list = initialData.map(data => {
        return data.costTon
      })

      return list.length !== 0 ? [Math.min(...list), Math.max(...list)] : null
    }

    return null
  }

  const getTotalWeightRange = (): TotalWeightRange => {
    if (initialData) {
      const list = initialData.map(data => {
        return data.totalWeight
      })

      return list.length !== 0 ? [Math.min(...list), Math.max(...list)] : null
    }

    return null
  }

  const getDistanceRange = (): TotalWeightRange => {
    if (initialData) {
      const list = initialData.map(data => {
        return data.distance
      })

      return list.length !== 0 ? [Math.min(...list), Math.max(...list)] : null
    }

    return null
  }

  const value = {
    getCargolist,
    getCostTonRange,
    getTotalWeightRange,
    getDistanceRange,
    setFilterTypeVehicle,
    setFilterCargo,
    setFilterLoadingAddress,
    setFilterUnloadingAddress,
    setFilterDistanceRange,
    setFilterTotalWeightRange,
    setData,
    setFilterCostTon,
    clear,
    data: viewData,
    filterLoadingAddress,
    filterUnloadingAddress,
    filterCargo,
    filterTypeVehicle,
    filterCostTon,
    filterDistanceRange,
    filterTotalWeightRange
  }

  return (
    <FiltersContext.Provider value={value}>
      {children}
    </FiltersContext.Provider>
  )
}

export function useFiltersContext () {
  const context = useContext(FiltersContext)

  if (context == null) {
    throw new Error('Used outside of "FiltersContextProvider"')
  }

  return context
}
