import { asObject } from 'util/asObject'

export class LocalStorage<T> {
  constructor (
    public readonly key: string,
    public readonly guard: (x: unknown) => x is T,
    protected cacheDeltaMts: number = 1000
  ) {}

  public get (): T | null {
    const serialized = localStorage.getItem(this.key)
    const entry = typeof serialized === 'string' ? JSON.parse(serialized) : undefined

    if (this.isEntry(entry) && this.isValidExp(entry.exp)) {
      return entry.data
    } else {
      this.clear()
      return null
    }
  }

  public set (data: T, exp?: number): void {
    if (this.isValidExp(exp)) {
      localStorage.setItem(this.key, JSON.stringify({ data, exp }))
    } else {
      this.clear()
    }
  }

  public clear (): void {
    localStorage.removeItem(this.key)
  }

  private isValidExp (exp?: number): boolean {
    return exp === undefined || exp > Date.now() + this.cacheDeltaMts
  }

  private isEntry (x: unknown): x is { data: T, exp?: number} {
    const { data, exp } = asObject(x)

    return this.guard(data) && (exp === undefined || typeof exp === 'number')
  }
}
