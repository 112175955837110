import { mainService } from 'api/service'

export interface Information {
  dispatcherPhone?: string
  employeePhone?: string
  sampleStaffing?: string
  sampleInformationLetter?: string
  sampleInformationLetterOrg?: string
  sampleQuestionnaire?: string
}

const request = (method: string, params: object) => mainService.request(`information.${method}`, params)

export const informationPut = (params: Information) => request('put', params) as Promise<{ upserted: boolean, modified: boolean }>
export const informationGet = () => request('get', {}) as Promise<Information>
