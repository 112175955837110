export const TRUCK_RUN_XLSX_COLUMNS = {
  carrierName: 'Перевозчик',
  num: 'Номер рейса',
  forwarder: 'Экспедитор',
  driver: 'Водитель',
  vehicle: 'ТС',
  trailer: 'Прицеп',
  weight: 'Вес погрузки',
  loadingDate: 'Дата погрузки',
  billNumber: 'Номер ТрН/ТТН',
  finalWeight: 'Вес разгрузки',
  unloadingTs: 'Дата разгрузки',
  status: 'Статус',
  sentToKisStatus: 'КИС'
}
