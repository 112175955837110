import './styles.sass'
import { useNavigate } from 'react-router-dom'
import { Button, Typography, Stack } from '@mui/material'
import { StepProps } from 'ui/ModalSigning'
import { useMainRoutes } from 'routes'

export default function Exit ({ isActive, handleCancel }: StepProps) {
  const { links } = useMainRoutes()
  const navigate = useNavigate()

  if (!isActive) {
    return <></>
  }

  return (<>
    <Typography fontSize='14px' fontWeight={400} color='#B2B2B2' mb='2em'>
    Документы были успешно подписаны и отправлены на подпись. Мы отправим вам уведомление после их проверки и подписания на нашей стороне.
    </Typography>
    <Stack direction='row' justifyContent='flex-end' spacing={2}>
      <Button variant='outlined' color='secondary' onClick={() => navigate(links.CARRIER_DOCUMENTS_ACTS_PAGE)}>Просмотреть отправленные</Button>
      <Button variant='contained' onClick={() => handleCancel()}>Закрыть</Button>
    </Stack>
  </>)
}
