import { CarNumberType as Type } from 'api/CarNumberType'

export default function getRegionNumber (type?: Type): string | undefined {
  switch (type) {
    case Type.dpr:
      return 'ДНР'
    case Type.lpr:
      return 'ЛНР'
    default:
  }
}
