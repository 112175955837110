import './styles.sass'
import { FormControl } from '@mui/material'
import { SelectOptions } from 'common/SelectOptionsInterface'
import { SelectValue } from '..'
import Select from 'ui/Select'

const MAX_LENGTH_TAG_STRING = 7

interface Props {
  label: string
  options: SelectOptions[]
  value: string[]
  changeFilter: (select: SelectValue) => void
}

export default function SelectFilter ({ label, value, options, changeFilter }: Props) {
  // minWidth: '12em' фиксируем минимальную длинну чтобы не расползалось по вертикали
  return <FormControl sx={{ width: '100%', minWidth: '12em' }} size='small' className='selectFilter'>
    <Select
      placeholder={label}
      options={[{ value: 'all', name: 'Любой' }, ...options]}
      value={value}
      onChange={(value) => changeFilter(value.includes('all') ? [] : value)}
      multiple={true}
      renderTags={tags => {
        const counter = tags.length > 1 ? `+${tags.length - 1}` : ''
        const str = tags.map(({ label }) => label).join(', ')
        return (<>{str.length > MAX_LENGTH_TAG_STRING ? `${str.slice(0, MAX_LENGTH_TAG_STRING)}... ${counter}` : str}</>)
      }}
      inputSx={{
        '& .MuiOutlinedInput-input': {
          minWidth: 'unset !important'
        }
      }}
    />
  </FormControl>
}
