import './styles.sass'
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import EditIcon from '@mui/icons-material/Edit'
import ArchiveIcon from '@mui/icons-material/Archive'
import VisibilityIcon from '@mui/icons-material/Visibility'

import { useAuthContext } from 'AuthContext'
import { useMainRoutes } from 'routes'

import { ProfileAll, profileAllGet } from 'api/profile'
import { TrailerAll, TrailerStatus, trailerAllList, TrailerArchiveParams, trailerAllArchive, TrailerAllFilter, Trailer } from 'api/trailer'

import DataTable, { Column } from 'ui/DataTable'
import ActionsMenu from 'ui/ActionsMenu'
import { Id, isId } from 'api/Id'
import { UserRole } from 'api/user'
import NavigatePanel from 'ui/NavigatePanel'
import useTabs from 'common/Registry/Carrier/useTabs'
import { VehicleAxles } from 'api/vehicle'
import SquareButton from 'ui/SquareButton'
import useDisabled from 'common/Registry/Carrier/useDisabled'
import { ChipTrailerStatus } from 'common/ChipTrailerStatus'
import Modal from 'ui/Modal'
import { Box, Stack, Button } from '@mui/material'
import DatePicker from 'ui/DatePicker'
import TextField from 'ui/TextField'
import trailerName from 'util/trailerName'

export default function Trailers () {
  const params = useParams()
  const [profile, setProfile] = useState<ProfileAll>()
  const [rows, setRows] = useState<TrailerAll[]>()
  const [searched] = useState<(number|string)[]>()
  const [archiveData, setArchiveData] = useState<TrailerArchiveParams | null>()

  const navigate = useNavigate()
  const location = useLocation()
  const { handleResponseFailure, currentInterface } = useAuthContext()
  const { disabled } = useDisabled(currentInterface)

  const { links, routesMap } = useMainRoutes()
  const { tabs } = useTabs(profile?.id)

  const init = useCallback(async () => {
    const { carrier: id } = params

    const profile = isId(id) ? await profileAllGet(id, { role: UserRole.carrier }) : null

    if (profile === null) {
      handleResponseFailure('Перевозчик не найден')
      return
    }

    const filter: TrailerAllFilter = { owner: profile.phone }

    if (disabled) {
      filter.status = TrailerStatus.active
    }

    const rows = await trailerAllList(filter)

    setProfile(profile)
    setRows(rows)
  }, [params, disabled, handleResponseFailure])

  useEffect(() => { init() }, [init])

  const openArchiveModal = (id?: Id) => {
    if (id === undefined) {
      return
    }

    setArchiveData({ id, date: Math.floor(Date.now() / 1000) })
  }

  const updateArchiveString = (key: keyof Pick<TrailerArchiveParams, 'comment'>) => {
    return (e: ChangeEvent<HTMLElement & {value: string}>) => {
      if (!archiveData) {
        return
      }

      setArchiveData({ ...archiveData, [key]: e.target.value })
    }
  }

  const updateArchiveDate = (key: keyof TrailerArchiveParams) => {
    return (value: number) => {
      if (!archiveData) {
        return
      }

      setArchiveData({ ...archiveData, [key]: value })
    }
  }

  const archive = useCallback(async () => {
    if (!archiveData) {
      return
    }

    await trailerAllArchive(archiveData)
    setArchiveData(undefined)
    init()
  }, [archiveData, init])

  const columns = useMemo(() => {
    const items: Column[] = [
      {
        id: ['number', 'numberRegion', 'numberType'],
        label: 'Гос. номер и регион',
        format: (number, numberRegion, numberType) => <>{trailerName({ number, numberRegion, numberType } as Trailer)}</>
      },
      {
        id: ['brand', 'model'],
        label: 'Марка и модель',
        format: (brand, model) => <>{brand} - {model}</>
      },
      {
        id: ['certificate', 'certificateIndex'],
        label: 'Серия и номер СРТС'
      },
      {
        id: 'axles',
        label: 'Количество осей',
        format: (value) => {
          const axles = value as VehicleAxles
          return <>{axles.name}</>
        }
      }
    ]

    if (disabled) {
      items.push({
        id: 'id',
        label: '',
        align: 'right',
        minWidth: 10,
        format: value => <SquareButton
            onClick={() => navigate(value as string)}
            sx={{
              background: '#B2B2B2',
              color: '#fff',
              padding: 0,
              '&:hover': {
                background: '#B2B2B2',
                border: 'none',
                filter: 'brightness(96%)'
              }
            }}
          >
            <MoreHorizIcon sx={{ fontSize: '16px' }}/>
          </SquareButton>
      })
    } else {
      items.push({
        id: ['status', 'truckRuns'],
        label: 'Статус',
        format: (status, truckRuns) => <ChipTrailerStatus status={status as TrailerStatus} truckRuns={truckRuns as Id[] | undefined}/>
      },
      {
        id: ['id', 'status', 'truckRuns'],
        label: '',
        align: 'right',
        minWidth: 10,
        format: (id, status, truckRuns) => <ActionsMenu key={`${id}`} actions = {
          status === TrailerStatus.archive || (Array.isArray(truckRuns) && truckRuns.length > 0)
            ? [
                {
                  caption: status === TrailerStatus.archive ? 'Посмотреть' : 'Редактировать',
                  onClick: () => navigate(`${location.pathname}/${id}`),
                  icon: status === TrailerStatus.archive ? <VisibilityIcon /> : <EditIcon />
                }
              ]
            : [
                {
                  caption: 'Редактировать',
                  onClick: () => navigate(`${location.pathname}/${id}`),
                  icon: <EditIcon />
                },
                {
                  caption: 'Архивировать',
                  onClick: () => openArchiveModal(id as Id),
                  icon: <ArchiveIcon />
                }
              ]
        } />
      })
    }

    return items
  }, [location.pathname, navigate, disabled])

  if (profile === undefined) {
    return <></>
  }

  return (
    <>
      <Modal
        title='Архивировать прицеп'
        open={archiveData !== undefined}
        onClose={() => setArchiveData(undefined)}
        content={ archiveData
          ? <Stack sx={{ minWidth: '500px' }} >
              <Box sx={{ mb: 2 }}>
                <DatePicker
                  label='Дата архивации'
                  value={archiveData.date}
                  onChange={updateArchiveDate('date')}
                  disabled
                />
              </Box>
              <Box>
                <TextField
                  rows={6}
                  label="Комментарий"
                  width='100%'
                  value={archiveData.comment}
                  onChange={updateArchiveString('comment')}
                  placeholder="Добавьте комментарий, например причину вывода из эксплуатации"
                />
              </Box>
            </Stack>
          : <></>
        }
        actions={ archiveData
          ? <>
            <Button color="secondary" variant="outlined" onClick={() => setArchiveData(undefined)}>Отменить</Button>
            <Button variant="contained" onClick={ () => { archive() } }>Архивировать</Button>
          </>
          : <></>
        }
      />
      <div className='registryCarriers_trailers'>
        <NavigatePanel
          // search={{
          //   rows: rows === undefined ? [] : rows,
          //   fields: [
          //     'brand',
          //     'model',
          //     'number',
          //     'numberRegion',
          //     'certificate',
          //     'certificateIndex'
          //   ],
          //   onChange: setSearched
          // }}
          title={`Перевозчики ${profile.organization?.shortName ?? ''}`}
          breadcrumbs={{
            items: [
              {
                title: profile.organization?.shortName ?? ''
              }
            ],
            defaultItems: routesMap.getBreadcrumbs(links.REGISTRY_CARRIERS_PAGE)
          }}
          tabs={tabs}
        />
        <div className='registryCarriers_drivers__body'>
          <div className='registryCarriers_drivers__body_content'>
            <DataTable
              columns={columns}
              rows = { (start, end) => rows === undefined
                ? []
                : rows
                  .filter(row => searched === undefined ? true : searched.includes(row.id)).slice(start, end) }
              rowsCount = { rows === undefined ? 0 : (searched === undefined ? rows.length : searched.length) }
            />
          </div>
        </div>
      </div>
    </>
  )
}
