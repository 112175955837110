import { mainService } from 'api/service'
import { Id } from 'api/Id'
import { RequireOnly } from 'util/type/RequireOnly'
import { RejectionExpand } from './rejection'
import { BlackListEntry } from './black-list'
import { CarNumberType } from './CarNumberType'

export enum VehicleStatus {
  new = 'new',
  active = 'active',
  blocked = 'blocked',
  archive = 'archive',
  needsAttention = 'needs_attention',
}

export interface VehicleType {
  slug: string
  name: string
}

export interface VehicleAxles {
  slug: string
  name: string
}

export interface Vehicle {
  id: Id
  type: string
  brand: string
  model: string
  number: string
  numberRegion: string
  numberType: CarNumberType
  certificateIndex: string
  certificate: string
  isLising: boolean
  status: VehicleStatus
  color: string
  axles: VehicleAxles
  createTs: number

  scanPassport: string
  scanRegistration: string
  scanLising?: string
  scanAgreement?: string
  archiveDate?: number
  archiveComment?: string
  truckRuns?: Id[]
  blackList?: BlackListEntry[]
}

export type VehicleAll = Vehicle & {
  owner: string
  rejection?: RejectionExpand
  additionalData?: {
    orgShortName: string
    ownerId: string
  }
}

type Update = Omit<Vehicle, 'archiveDate' | 'archiveComment' | 'truckRuns' | 'status' | 'createTs' | 'axles' | 'blackList'> & { axlesSlug: string }
type UpdateAll = Omit<VehicleAll, 'archiveDate' | 'archiveComment' | 'truckRuns' | 'blackList' | 'createTs' | 'axles'> & { axlesSlug: string }

export type VehicleForCreate = Omit<Update, 'id'>
export type VehicleForUpdate = RequireOnly<Update, 'id'>
export type VehicleAllForCreate = Omit<UpdateAll, 'id'>
export type VehicleAllForUpdate = RequireOnly<UpdateAll, 'id'>

export interface VehicleAllFilter {
  owner?: string
  status?: VehicleStatus
  loadingTs?: number
  bidId?: Id
  includeAdditionalData?: boolean
}

export interface VehicleArchiveParams {
  id: Id
  date: number
  comment?: string
}

export type VehicleListParams = {
  filters?: {
    status?: VehicleStatus
    involvedInShippingAndFree?: {
      loadingTs: number
      bidId: Id
    },
  }
}

export interface VehicleCreateResult {
  success: boolean
  id: Id | null
  conflicts?: {
    double?: boolean
  }
}
export type VehicleAllCreateResult = VehicleCreateResult

const request = (method: string, params: object) => mainService.request(`vehicle.${method}`, params)

export const vehicleArchive = (params: VehicleArchiveParams) => request('archive', params) as Promise<boolean>
export const vehicleCreate = (params: VehicleForCreate) => request('create', params) as Promise<VehicleCreateResult>
export const vehicleUpdate = (params: VehicleForUpdate) => request('update', params) as Promise<boolean>
export const vehicleGet = (id: Id) => request('get', { id }) as Promise<Vehicle | null>
export const vehicleList = (params: VehicleListParams = {}) => request('list', params) as Promise<Vehicle[]>

export const vehicleAllArchive = (params: VehicleArchiveParams) => request('all.archive', params) as Promise<boolean>
export const vehicleAllCreate = (params: VehicleAllForCreate) => request('all.create', params) as Promise<VehicleAllCreateResult>
export const vehicleAllUpdate = (params: VehicleAllForUpdate) => request('all.update', params) as Promise<boolean>
export const vehicleAllGet = (id: Id) => request('all.get', { id }) as Promise<VehicleAll | null>
export const vehicleAllList = (params: VehicleAllFilter = {}) => request('all.list', params) as Promise<VehicleAll[]>
export const vehicleAllDelete = (id: Id) => request('all.delete', { id }) as Promise<boolean>

export const vehicleTypePut = (name: string, slug?: string) => request('type.put', { name, slug }) as Promise<{ upserted: boolean, modified: boolean }>
export const vehicleTypeList = () => request('type.list', {}) as Promise<VehicleType[]>
export const vehicleTypeDelete = (slug: string) => request('type.delete', { slug }) as Promise<boolean>

export const vehicleAxlesPut = (name: string, slug?: string) => request('axles.put', { name, slug }) as Promise<{ upserted: boolean, modified: boolean }>
export const vehicleAxlesList = () => request('axles.list', {}) as Promise<VehicleAxles[]>
export const vehicleAxlesDelete = (slug: string) => request('axles.delete', { slug }) as Promise<boolean>
