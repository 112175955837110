import { NotifyEvent } from '.'
import { useEffect } from 'react'

export interface Data {
  message: string
}

const SLUG = 'profile_contract_rejected_event'

export default function ProfileContractRejectedEvent ({ slug, data, toast } :NotifyEvent) {
  useEffect(() => {
    if (slug !== SLUG) {
      return
    }

    if (toast === undefined) {
      return
    }

    if (data === undefined) {
      return
    }

    const { message } = data as Data

    toast(message, 'warning')
  }, [slug, data, toast])

  return (<></>)
}
