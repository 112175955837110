import { ShippingTruckRunStatus } from 'api/shipping'

const statusToOrdering = (status: ShippingTruckRunStatus) => {
  switch (status) {
    case ShippingTruckRunStatus.new:
      return 1
    case ShippingTruckRunStatus.confirmed:
      return 2
    case ShippingTruckRunStatus.way:
      return 3
    case ShippingTruckRunStatus.arrived:
      return 4
    case ShippingTruckRunStatus.completed:
      return 5
    default:
      return 10
  }
}

export default statusToOrdering
