import { Error } from '@mui/icons-material'
import { CircularProgress, Stack, Step, StepLabel, Stepper } from '@mui/material'
import { useEventContext } from 'EventContext'
import { useEffect, useState } from 'react'

enum ProgressStatus {
  waiting = 'waiting',
  success = 'success',
  error = 'error'
}

enum Services {
  auth = 'auth',
  main = 'main',
  notify = 'notify'
}

interface IProgress {
  user: string
  service: Services
  status: ProgressStatus
}

const EVENT_SLUG = 'change_phone'

export interface Params {
  success?: boolean
}

export default function Progress ({ success = false }: Params) {
  const { currentEvent } = useEventContext()
  const [progress, setProgress] = useState<IProgress>()
  const [activeStep, setActiveStep] = useState<number>()

  useEffect(() => {
    if (currentEvent === undefined) {
      return
    }

    if (currentEvent.slug !== EVENT_SLUG) {
      return
    }

    setProgress(currentEvent.data as IProgress)

    const { service, status } = currentEvent.data as IProgress

    if (service === Services.auth && status === ProgressStatus.success) {
      setActiveStep(1)
    }

    if (service === Services.main && status === ProgressStatus.success) {
      setActiveStep(2)
    }

    if (service === Services.notify && status === ProgressStatus.success) {
      setActiveStep(3)
    }
  }, [currentEvent])

  const getIcon = (service: Services) => {
    if (progress === undefined) {
      return
    }

    if (progress.service !== service) {
      return
    }

    switch (progress.status) {
      case ProgressStatus.waiting:
        return <CircularProgress sx={{ maxWidth: '24px', maxHeight: '24px' }}/>
      case ProgressStatus.error:
        return <Error color='error' style={{ width: '24px' }} />
      case ProgressStatus.success:
      default:
    }
  }

  return (<Stack width='100%'>
    <Stepper activeStep={success ? 3 : activeStep} alternativeLabel >
        <Step>
          <StepLabel icon={getIcon(Services.auth)}>auth</StepLabel>
        </Step>
        <Step>
          <StepLabel icon={getIcon(Services.main)} >main</StepLabel>
        </Step>
        <Step>
          <StepLabel icon={getIcon(Services.notify)} >notify</StepLabel>
        </Step>
    </Stepper>
  </Stack>)
}
