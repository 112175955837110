import { Bid } from 'api/bid'
import './styles.sass'
import { Box, Stack, Typography } from '@mui/material'
import ProgressBar from 'ui/ProgressBar'
import { useMemo } from 'react'
import InfoCard from 'ui/InfoCard'
import FullAddress from 'ui/FullAddress'
import formatCargo from 'util/formatCargo'
import { toKilometers } from 'util/distance'
import rub from 'util/formatRub'
import formatScaleLength from 'util/formatScaleLength'
import formatScaleMaxLoad from 'util/formatScaleMaxLoad'
import formateParties from 'util/formateParties'
import { toTon } from 'util/weight'
import TsToFormatDate from 'common/CarrierBids/TsToFormatDate'
import { personName } from 'util/personName'
import formatPhone from 'util/formatPhone'
import SquareButton from 'ui/SquareButton'
import { ArrowForward } from '@mui/icons-material'
import { Forwarder } from 'api/forwarder'
import { Id } from 'api/Id'

interface DescBidProps {
  bid: Bid
  shipmentTs?: number
  onGotoBid: () => void
}

const filterForwarder = (shipmentTs: number, addressId: Id) =>
  ({ forwarderAttach }: Forwarder): boolean => {
    if (forwarderAttach === undefined) {
      return false
    }

    if (forwarderAttach.length === 0) {
      return false
    }

    return forwarderAttach.filter(({ beginPeriodTs, endPeriodTs, loadingAddress }) => {
      return shipmentTs >= beginPeriodTs && shipmentTs <= endPeriodTs && loadingAddress.id === addressId
    }).length > 0
  }

export default function DescBid ({ bid, shipmentTs, onGotoBid }: DescBidProps) {
  const progress = useMemo(() => {
    const { seats, totalSeats } = bid.freeSpace.find(item => item.shipmentTs === shipmentTs) ?? { shipmentTs, seats: 0, totalSeats: 0 }

    return {
      percent: seats === 0 || totalSeats === 0 ? 100 : Math.round((totalSeats - seats) * 100 / totalSeats),
      seats,
      totalSeats,
      reserved: totalSeats - seats
    }
  }, [bid.freeSpace, shipmentTs])

  const forwarders = useMemo(() => {
    if (shipmentTs === undefined) {
      return []
    }

    return bid.forwarders?.filter(filterForwarder(shipmentTs, bid.loadingAddress.id))
      .map(item => ({ label: personName(item), value: formatPhone(item.phone) })) ?? []
  }, [bid.forwarders, bid.loadingAddress.id, shipmentTs])

  return (<div className='descBid'>
    <Box sx={{
      background: '#ffffff',
      borderBottom: '1px solid #E2E8F0',
      padding: '1.5em'
    }} >
      <Typography fontSize='24px' fontWeight={700} >Подробности по заявке {bid.num}</Typography>
      <Stack direction='row' spacing={1} mt='1.9em'>
        <ProgressBar info={`${progress.reserved}/${progress.totalSeats} машин (${progress.percent}%)`} value={progress.percent} color='success' />
        <SquareButton size='small' variant='contained' onClick={onGotoBid}>
          <ArrowForward />
        </SquareButton>
      </Stack>
    </Box>
    <div className='descBid__body'>
      <div className='descBid__body_content'>
        <InfoCard
          title='Информация'
          items={[
            // { label: 'Тип ТС:', value: bid.typeVehicle.length === 0 ? 'Любой' : bid.typeVehicle.map(item => item.name).join(', ') },
            { label: 'Дата погрузки:', value: TsToFormatDate(shipmentTs, 'dd MMMM yyyy') },
            { label: 'Груз:', value: formatCargo(bid.cargo) },
            { label: 'Расстояние:', value: `${toKilometers(bid.distance)} км` },
            { label: 'Стоимость перевозки тн/км:', value: rub(bid.costTonKm) },
            { label: 'Cтоимость перевозки 1 тн:', value: rub(bid.costTon) },
            { label: 'Стоимость груза за 1 тн:', value: rub(bid.costCargo) },
            { label: 'Заказчик перевозки:', value: bid.customer.fullName },
            { label: 'Грузим +10%:', value: bid.plusTen ? 'Да' : 'Нет' },
            { label: 'Общий вес перевозки, тн:', value: `${toTon(bid.totalWeight)} тн` }
          ]}
        />
        <InfoCard
          title='Маршрут'
          items={[
            { label: 'Место погрузки:', value: <FullAddress address={bid.loadingAddress} /> },
            { label: 'Место разгрузки:', value: <FullAddress address={bid.unloadingAddress} /> }
          ]}
        />
        <InfoCard
          title='Информация о пункте погрузки'
          items={[
            { label: 'Способ погрузки:', value: bid.loadingMethod },
            { label: 'Длина весов:', value: formatScaleLength(bid.loadingWeighing) },
            { label: 'Грузоподъемность весов:', value: formatScaleMaxLoad(bid.loadingWeighing) },
            { label: 'Грузоотправитель', value: formateParties(bid.consignor) }
          ]} />
        <InfoCard
          title='Информация о пункте разгрузки'
          items={[
            { label: 'Способ разгрузки:', value: bid.unloadingMethod },
            { label: 'Длина весов:', value: formatScaleLength(bid.unloadingWeighing) },
            { label: 'Грузоподъемность весов:', value: formatScaleMaxLoad(bid.unloadingWeighing) },
            { label: 'Грузополучатель', value: formateParties(bid.consignee) }
          ]}
        />
        { forwarders.length > 0 && <InfoCard title='Экспедиторы' items={forwarders} /> }
      </div>
    </div>
  </div>)
}
