import './styles.sass'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Box, CircularProgress, Typography, Stack } from '@mui/material'
import { StepProps } from 'ui/ModalSigning'
import { Act, actSign, actCarrierSignPack, ActCarrierSignDiadocDocument } from 'api/act'
import { ActWizardData, skipDiadoc } from './index'
import { mediaService } from 'api/media'
import { ArrayBufferToBase64, canAsyncCadesplugin, Signature, Signer } from 'ui/Diadoc/lib/tools'
import Alert from '@mui/material/Alert'
import 'ui/Diadoc/lib/cadesplugin_api'
import { Certificate } from 'crypto-pro'

export default function Signing ({ isActive, data, handleNext }: StepProps) {
  const [sent, setSent] = useState<number>(0)
  const [total, setTotal] = useState<number>(0)
  const [error, setError] = useState('')
  const once = useRef(true)

  const d = data as ActWizardData

  const signDev = useCallback((list: Act[], handleNext: () => void) => {
    const promises = list.map((act) => {
      setSent((sent) => sent + 1)
      return actSign([act.id])
    })

    Promise.all(promises)
      .then(() => handleNext())
      .catch((err) => setError(err.message))
  }, [])

  const signProd = useCallback((list: Act[], handleNext: () => void, boxId: string, cert?: Certificate) => {
    if (!boxId) {
      setError('Компания для подписания документов не выбрана')
      return
    }

    Signer(cert)
      .then(async (signer) => {
        const promises = list.map((act) => {
          setSent((sent) => sent + 1)
          return signPack(signer, boxId, act)
        })

        Promise.all(promises)
          .then(() => handleNext())
          .catch((err) => setError(err.message))
      })
      .catch((err) => setError(err.message))
  }, [])

  useEffect(() => {
    if (!isActive) {
      return
    }

    const list = d.newActList === undefined ? [] : d.newActList
    if (list.length === 0) {
      handleNext()
      return
    }

    if (!once.current) {
      return
    }
    once.current = false

    setTotal(list.length)

    const onSuccess = () => {
      d.onCreateActs(undefined)
      d.onNumbering([])
      handleNext()
    }

    skipDiadoc()
      ? signDev(list, onSuccess)
      : signProd(list, onSuccess, d.boxId, d.certificate)
  }, [isActive, signDev, signProd, handleNext, d])

  if (!isActive) {
    return <></>
  }

  return (<>
    <Typography fontSize='14px' fontWeight={400} color='#B2B2B2' mb='2em'>
    Пожалуйста, не закрывайте это окно и дождитесь окончания отправки документов.
    </Typography>
    <Box sx={{ display: 'flex', minWidth: '500px', minHeight: '250px', alignItems: 'center', justifyContent: 'center' }}>
      <Stack display='flex' flexDirection='column' alignItems='center'>
        <CircularProgress />
        <Typography>Подписываем документы ({sent}/{total})</Typography>
        { error && <Alert severity="error">{error}</Alert> }
      </Stack>
    </Box>
  </>)
}

export async function getContentAndSignature (
  signer: CAdESCOM.CPSignerAsync,
  fileName: string
) {
  // @ts-ignore
  const plugin = window.cadesplugin
  if (!canAsyncCadesplugin(plugin)) {
    throw new Error('async cadesplugin unavailable')
  }

  // get the document content
  const content = await mediaService.download(fileName)
  const ab = await content.arrayBuffer()
  const b64 = ArrayBufferToBase64(ab)

  if (b64 === '') {
    throw new Error('Подписываемое сообщение пустое')
  }

  const sign = await Signature(plugin, signer, b64)
  if (sign === '') {
    throw new Error('Подпись пустая')
  }

  return {
    content: b64,
    signature: sign
  }
}

export async function getContentAndSignatureForUtd (
  signer: CAdESCOM.CPSignerAsync,
  b64: string
) {
  // @ts-ignore
  const plugin = window.cadesplugin
  if (!canAsyncCadesplugin(plugin)) {
    throw new Error('async cadesplugin unavailable')
  }

  const sign = await Signature(plugin, signer, b64)
  if (sign === '') {
    throw new Error('Подпись пустая')
  }

  return {
    content: b64,
    signature: sign
  }
}

async function signPack (
  signer: CAdESCOM.CPSignerAsync,
  fromBoxId: string,
  act: Omit<Act, 'truckRuns'>
) {
  const pack = new Map()

  pack.set(ActCarrierSignDiadocDocument.utd, await getContentAndSignatureForUtd(signer, act.utdXmlBase64 as string))

  if (act.invoiceFilename) {
    pack.set(ActCarrierSignDiadocDocument.invoice, await getContentAndSignature(signer, act.invoiceFilename))
  }

  if (act.registryFilename) {
    pack.set(ActCarrierSignDiadocDocument.registry, await getContentAndSignature(signer, act.registryFilename))
  }

  if (act.shortageFilename) {
    pack.set(ActCarrierSignDiadocDocument.shortage, await getContentAndSignature(signer, act.shortageFilename))
  }

  if (act.agreementFilename) {
    pack.set(ActCarrierSignDiadocDocument.agreement, await getContentAndSignature(signer, act.agreementFilename))
  }

  if (act.finalBidFilename) {
    pack.set(ActCarrierSignDiadocDocument.finalBid, await getContentAndSignature(signer, act.finalBidFilename))
  }

  await actCarrierSignPack({
    fromBoxId,
    actId: act.id,
    pack: Object.fromEntries(pack)
  })
}
