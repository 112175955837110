import { Archive, Edit, ReportProblem, Visibility } from '@mui/icons-material'
import { Alert, Box, Button, Stack, Typography } from '@mui/material'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import NavigatePanel from 'ui/NavigatePanel'
import './styles.sass'
import {
  Customer,
  customerList,
  CustomerStatus,
  CustomerArchiveParams,
  customerArchive,
  CustomerIncomplete as Incomplete
} from 'api/customer'
import DataTable from 'ui/DataTable'
import ActionsMenu from 'ui/ActionsMenu'
import { Id } from 'api/Id'
import ChipActivStatus from 'common/ChipActivStatus'
import Modal from 'ui/Modal'
import DatePicker from 'ui/DatePicker'
import TextField from 'ui/TextField'
import Tooltip from 'ui/Tooltip'
import IncompleteWizard from 'ui/CustomerIncomplete'

export default function AdminCustomers () {
  const [rows, setRows] = useState<Customer[]>([])
  const [archiveData, setArchiveData] = useState<CustomerArchiveParams | null>()
  const [archiveError, setArchiveError] = useState<Awaited<ReturnType<typeof customerArchive>>['conflicts']>()
  const navigate = useNavigate()

  const init = useCallback(() => {
    customerList({ includeCompleteness: true }).then(setRows)
  }, [])

  useEffect(() => {
    init()
  }, [init])

  const updateArchiveString = (key: keyof Pick<CustomerArchiveParams, 'comment'>) => {
    return (e: ChangeEvent<HTMLElement & {value: string}>) => {
      if (!archiveData) {
        return
      }

      setArchiveData({ ...archiveData, [key]: e.target.value })
    }
  }

  const updateArchiveDate = (key: keyof CustomerArchiveParams) => {
    return (value: number) => {
      if (!archiveData) {
        return
      }

      setArchiveData({ ...archiveData, [key]: value })
    }
  }

  const archive = useCallback(async () => {
    if (!archiveData) {
      return
    }

    const { success, conflicts } = await customerArchive(archiveData)

    if (!success) {
      setArchiveError(conflicts)
      return
    }

    setArchiveData(undefined)
    init()
  }, [archiveData, init])

  const openArchiveModal = (id?: Id) => {
    if (id === undefined) {
      return
    }

    setArchiveData({ id, date: Math.floor(Date.now() / 1000) })
  }

  return (<>
    <div className='adminCustomer'>
      <NavigatePanel
        title='Заказчики'
        breadcrumbs={{
          items: [{ title: 'Заказчики' }]
        }}
        /*
        actions={
          <Button variant='contained' size='small' endIcon={<Add />} onClick={() => navigate(`${links.ADMIN_CUSTOMERS_PAGE}/add`)}>
            Добавить заказчика
          </Button>
        }
        */
      />
      <div className='adminCustomer__body'>
        <div className='adminCustomer__body_customer'>
          <DataTable
            columns = { [
              {
                id: 'fullName',
                label: 'Название',
                minWidth: 170
              },
              {
                id: 'inn',
                label: 'ИНН',
                minWidth: 170
              },
              {
                id: 'kpp',
                label: 'КПП',
                minWidth: 170
              },
              {
                id: 'status',
                label: 'Статус',
                format: value => <ChipActivStatus status={value as CustomerStatus} />
              },
              {
                id: ['id', 'status', 'incomplete'],
                label: '',
                align: 'right',
                minWidth: 10,
                format: (id, status, incomplete) => <Stack key={`${id}`} direction='row' gap={1} justifyContent='flex-end'>
                  { incomplete !== undefined && <Tooltip title={<IncompleteWizard data={incomplete as Incomplete} />} arrow placement='left' view>
                    <ReportProblem sx={{ color: '#EE6A5F', fontSize: '32px' }}/>
                  </Tooltip> }
                  <ActionsMenu actions = {
                    status !== CustomerStatus.active
                      ? [
                          {
                            caption: 'Посмотреть',
                            onClick: () => navigate(`${id}`),
                            icon: <Visibility />
                          }
                        ]
                      : [
                          {
                            caption: 'Редактировать',
                            onClick: () => navigate(`${id}`),
                            icon: <Edit />
                          },
                          {
                            caption: 'Архивировать',
                            onClick: () => openArchiveModal(id as Id),
                            icon: <Archive />
                          }
                        ]
                  } />
                </Stack>
              }
            ] }
            rows = { (start, end) => rows === undefined ? [] : rows.slice(start, end) }
            rowsCount = { rows === undefined ? 0 : rows.length }
          />
        </div>
      </div>
    </div>
    <Modal
      title={'Архивировать заказчика'}
      open={archiveData !== undefined}
      onClose={() => setArchiveData(undefined)}
      content={ archiveData
        ? archiveError === undefined
          ? <Stack sx={{ minWidth: '500px' }} >
            <Box sx={{ mb: 2 }}>
              <DatePicker
                label='Дата архивации'
                value={archiveData.date}
                onChange={updateArchiveDate('date')}
                disabled={true}
              />
            </Box>
            <Box>
              <TextField
                rows={6}
                label='Комментарий'
                width='100%'
                value={archiveData.comment}
                onChange={updateArchiveString('comment')}
                placeholder='Добавьте комментарий'
              />
            </Box>
          </Stack>
          : <Stack sx={{ minWidth: '500px' }} >
            <Alert color='error'>
              <Typography>Нельзя заархивировать заказчика!</Typography>
              <Typography>Заказчик используется в перевозках: {archiveError.map(item => item.num).join(', ')}</Typography>
            </Alert>
          </Stack>
        : <></>
      }
      actions={ archiveData
        ? <>
          <Button color='secondary' variant='outlined' onClick={() => setArchiveData(undefined)}>Отменить</Button>
          { archiveError === undefined && <Button variant='contained' onClick={ () => { archive() } }>Архивировать</Button> }
        </>
        : <></>
      }
    />
    </>)
}
