import { makeJsonSchema, SimpleSchema } from 'validation/validate'
import metaCustomer from 'entity/Customer'
import { CustomerForCreate } from 'api/customer'
import { ADDRESS_REGEXP } from './Organization'
import { Optional } from 'util/type/Optional'

type Customer = Optional<Required<CustomerForCreate>, 'diadocBoxId' | 'diadocUser' | 'diadocPassword'>

const simpleSchemaLtd: SimpleSchema<Customer> = {
  type: 'object',
  properties: {
    phone: { type: 'string', pattern: '^\\+[1-9]{2}\\d{9}$', errorMessage: 'Номер телефона введен неверно' },
    inn: { type: 'string', pattern: '^\\d{10}$', errorMessage: 'Некорректно заполнен ИНН' },
    okpo: { type: 'string', pattern: '^\\d{8}$', errorMessage: 'Некорректно заполнен ОКПО' },
    correspondentAccountNumber: { type: 'string', pattern: '^\\d{20}$', errorMessage: 'Некорректно заполнен номер корреспондентского счета' },
    kpp: { type: 'string', pattern: '^\\d{9}$', errorMessage: 'Некорректно заполнен КПП' },
    checkingAccountNumber: { type: 'string', pattern: '^\\d{20}$', errorMessage: 'Некорректно заполнен номер расчетного счета' },
    bik: { type: 'string', pattern: '^\\d{9}$', errorMessage: 'Некорректно заполнен БИК' },
    fullName: { type: 'string', pattern: '^[а-яА-ЯёЁ \\d]+', minLength: 5, errorMessage: 'Некорректно заполнено поле' },
    shortName: { type: 'string', pattern: '^[а-яА-ЯёЁ \\d]+', minLength: 3, errorMessage: 'Некорректно заполнено поле' },
    organizationHeadName: { type: 'string', pattern: '^[а-яА-ЯёЁ" .]+$', minLength: 10, errorMessage: 'Некорректно заполнено поле' },
    legalAddress: { type: 'string', pattern: ADDRESS_REGEXP, minLength: 10, errorMessage: 'Некорректно заполнено поле' },
    bankName: { type: 'string', minLength: 5, errorMessage: 'Некорректно заполнено поле' },
    diadocBoxId: { type: 'string', optional: true, pattern: '^[a-z\\d]{32}@diadoc\\.ru$', errorMessage: 'Некорректно заполнено поле' },
    diadocUser: { type: 'string', optional: true },
    ogrn: { type: 'string', pattern: '^\\d{13}$', errorMessage: 'Некорректно заполнен ОГРН' },
    diadocPassword: { type: 'string', optional: true }
  }
}

export default makeJsonSchema<Customer>(simpleSchemaLtd)

export function clear (data: CustomerForCreate = {}): CustomerForCreate {
  const cleanMask = (key: keyof Customer, value?: string): string | undefined =>
    value !== undefined ? metaCustomer.cleanMask(key, value) : value

  const result = {
    ...data,
    correspondentAccountNumber: cleanMask('correspondentAccountNumber', data.correspondentAccountNumber),
    checkingAccountNumber: cleanMask('checkingAccountNumber', data.checkingAccountNumber),
    phone: cleanMask('phone', data.phone),
    bik: cleanMask('bik', data.bik),
    inn: cleanMask('inn', data.inn),
    okpo: cleanMask('okpo', data.okpo),
    kpp: cleanMask('kpp', data.kpp),
    ogrn: cleanMask('kpp', data.ogrn)
  }

  return result
}
