import './styles.sass'
import { useState } from 'react'
import { Certificate } from 'crypto-pro'
import ModalSigning from 'ui/ModalSigning'
import Exit from './Exit'
import GetOrganizationList from '../ActWizard/GetOrganizationList'
import AuthCertificateSelect from '../ActWizard/AuthCertificateSelect'
import AuthProcess from '../ActWizard/AuthProcess'
import OrganizationSelect from '../ActWizard/OrganizationSelect'
import { Organization } from 'api/diadoc'
import Signing from './Signing'
import { Act } from 'api/act'
import { ActWizardData } from 'ui/ActWizard'
import GenTitle from './GenTitle'
import Info from './Info'
import SendIcon from '@mui/icons-material/Send'

export interface Props {
  open: boolean
  onClose: () => void
  acts: Act[]
}

export interface ActDispatcherWizardData extends ActWizardData {
  acts: Act[]
}

export default function ActDispatcherSigningWizard ({ open, onClose, acts }: Props) {
  const [authorized, setAuthorized] = useState(false) // has a Diadoc token
  const [organizationList, setOrganizationList] = useState<Organization[]>()
  const [certificate, setCertificate] = useState<Certificate>()
  const [boxId, setBoxId] = useState('')

  return (
    <>
      { open && <ModalSigning
        open={open}
        onClose={onClose}
        data={{
          authFirstStep: 2,
          authorized,
          organizationList,
          certificate,
          boxId,
          onAuthorized: (b: boolean) => {
            setAuthorized(b)
            if (!b) {
              setOrganizationList(undefined)
              setCertificate(undefined)
              setBoxId('')
            }
          },
          onOrganizationListLoaded: setOrganizationList,
          onCertificateSelected: setCertificate,
          onBoxIdSelected: setBoxId,
          acts,
          isDispatcher: true
        }}
        steps={[
          {
            title: 'Подтверждение подписания',
            fn: Info,
            cancel: 'Отменить',
            next: <>Подписать <SendIcon sx={{ width: '16px', ml: '10px' }} /></>
          },
          {
            title: 'Загружаем список организаций',
            fn: GetOrganizationList, // тут мы решаем задачу определения валиден ли токен Диадока + получаем список компаний
            cancel: 'Отменить',
            next: 'Далее'
          },
          {
            title: 'Выбор сертификата',
            fn: AuthCertificateSelect,
            cancel: 'Отменить',
            next: certificate ? 'Далее' : null
          },
          {
            title: 'Авторизация в Diadoc',
            fn: AuthProcess
          },
          {
            title: 'Загружаем список организаций',
            fn: GetOrganizationList // если на первом шаге получили список компаний, этот шаг пропускается
          },
          {
            title: 'Выбор организации',
            fn: OrganizationSelect,
            cancel: 'Отменить',
            next: boxId && acts.find(item => item.bid.customer.diadocBoxId === boxId) ? 'Далее' : null
          },
          {
            title: 'Генерация титула покупателя',
            fn: GenTitle,
            cancel: 'Отменить',
            next: boxId ? 'Далее' : null
          },
          {
            title: 'Подписание документов',
            fn: Signing
          },
          {
            title: 'Документы подписаны',
            fn: Exit
          }
        ]}
      /> }
    </>
  )
}
