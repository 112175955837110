import './styles.sass'
import { Box, Stack, Alert, Button } from '@mui/material'
import { StepProps } from 'ui/ModalSigning'
import { CarrierContractWizardType } from '.'
import download from 'util/download'
import PrintIcon from '@mui/icons-material/Print'
import Upload from 'ui/Upload'
import { Send } from '@mui/icons-material'

export default function SignManually ({ isActive, data, handleToStep }: StepProps) {
  const d = data as CarrierContractWizardType

  if (!isActive) {
    return <></>
  }

  return (<>
    <Box sx={{ display: 'flex', minWidth: '500px', minHeight: '250px' }}>
        <Stack direction='column' gap={3} width='100%'>
          <Alert color='info'>
            <ol>
              <li>Распечатайте заявление</li>
              <li>Подпишите</li>
              <li>Отсканируйте или сфотографируйте заявление</li>
              <li>Загрузите файл</li>
            </ol>
          </Alert>
          <Button
            variant='contained'
            color='secondary'
            startIcon={<PrintIcon />}
            onClick={() => download(d.contract)}
          >Распечатать заявление</Button>
          <Upload value={d.scanSignedСontract} onChange={d.onScanSignedСontract}/>
          {d.scanSignedСontract && <Button
            variant='contained'
            endIcon={<Send />}
            onClick={() => handleToStep(8)}
          >Отправить</Button>}
        </Stack>
    </Box>
  </>)
}
