import './styles.sass'
import { Button, Stack, Typography } from '@mui/material'
import { StepProps } from 'ui/ModalSigning'

export default function Exit ({ isActive, handleCancel }: StepProps) {
  if (!isActive) {
    return <></>
  }

  return (<>
    <Typography fontSize='14px' fontWeight={400} color='#B2B2B2' mb='2em'>
      Договор был успешно подписан.
    </Typography>
    <Stack direction='row' justifyContent='flex-end' spacing={2} sx={{ minWidth: '500px' }}>
      <Button variant='contained' onClick={() => handleCancel()}>Закрыть</Button>
    </Stack>
  </>)
}
