import BidMethods from './BidMethods'
import DocumentTpl from './DocumentTpl'
import VehicleAxles from './VehicleAxles'
import './styles.sass'
import VehicleType from './VehicleType'
import Information from './Information'
import DiadocSettings from './DiadocSettings'
import RejectionReason from './RejectionReason'
import OkvedCodes from './OkvedCodes'

export default function SystemSettings () {
  return (
    <div className='systemSettings'>
      <div className='systemSettings__body'>
        <div className='systemSettings__body_content'>
          <Information />
          <DocumentTpl />
          <DiadocSettings />
          <BidMethods />
          <VehicleAxles />
          <VehicleType />
          <RejectionReason />
          <OkvedCodes />
        </div>
      </div>
    </div>
  )
}
