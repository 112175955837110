import './styles.sass'
import { Box, Button, Menu, MenuItem } from '@mui/material'
import { MakeId } from 'util/MakeId'
import { useState } from 'react'
import { MoreVert } from '@mui/icons-material'

export type Action = {
  onClick: () => void,
  caption: string,
  icon: JSX.Element,
  disabled?: boolean,
}

export interface Params {
  actions: Action[]
  dontCollapse?: boolean
  widthHeight?: string
}

export default function ActionsMenu ({ actions, dontCollapse, widthHeight }: Params): JSX.Element {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const id = MakeId(5)

  return (actions.length > 1 || actions.length === 0 || !!dontCollapse
    ? <>
        <Button
          id={`actions-menu-button-${id}`}
          variant='outlined'
          size='small'
          color='secondary'
          onClick={event => { setAnchorEl(event.currentTarget) } }
          aria-controls={open ? `actions-menu-${id}` : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
          sx={{
            minWidth: widthHeight || '32px',
            maxWidth: widthHeight || '32px',
            minHeight: widthHeight || '32px',
            maxHeight: widthHeight || '32px',
            borderRadius: '2px',
            padding: 0,
            color: '#B2B2B2'
          }}
        ><MoreVert /></Button>
        <Menu
          id={`actions-menu-${id}`}
          anchorEl={anchorEl}
          open={open}
          onClose={ () => setAnchorEl(null) }
          MenuListProps={{
            'aria-labelledby': `actions-menu-button-${id}`
          }}
        >
          { actions.map(({ icon, caption, onClick, disabled = false }, idx) => <MenuItem
            key={idx}
            onClick={() => {
              if (!disabled) {
                onClick()
                setAnchorEl(null)
              }
            }}
            sx={{ display: 'flex', alignItems: 'start', justifyContent: 'start' }}
          >
            <Box sx={{ mr: '1em', color: '#B2B2B2' }}>{icon}</Box>
            <Box sx={{ color: disabled ? '#B2B2B2' : undefined }}>{caption}</Box>
          </MenuItem>) }
        </Menu>
      </>
    : <>
      <Button
          variant='outlined'
          size='small'
          color='secondary'
          onClick={actions[0].onClick}
          sx={{
            minWidth: '32px',
            maxWidth: '32px',
            minHeight: '32px',
            maxHeight: '32px',
            borderRadius: '2px',
            padding: 0,
            color: '#B2B2B2'
          }}
        >{actions[0].icon}</Button>
    </>
  )
}
