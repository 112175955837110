import './styles.sass'
import { useState, useEffect, useRef } from 'react'
import { Box, CircularProgress, Typography, Stack } from '@mui/material'
import { StepProps } from 'ui/ModalSigning'
import Alert from '@mui/material/Alert'
import { ActDispatcherWizardData } from './index'
import { diadocGenerateTitles } from 'api/diadoc'
import { DiadocUnauthorized } from 'ui/Diadoc/error/DiadocUnauthorized'

export default function GenTitle ({ isActive, data, handleNext, handleToStep }: StepProps) {
  const [error, setError] = useState('')
  const once = useRef(true)

  const d = data as ActDispatcherWizardData

  useEffect(() => {
    if (!isActive) {
      return
    }

    if (!once.current) {
      return
    }
    once.current = false

    if (!d.boxId) {
      setError('Компания для подписания документов не выбрана')
      return
    }

    if (!d.certificate) {
      setError('Сертификат для подписания документов не выбран')
      return
    }

    diadocGenerateTitles(d.boxId, d.acts.filter(item => item.bid.customer.diadocBoxId === d.boxId).map(item => item.id))
      .then(() => handleNext())
      .catch((err) => {
        if (err instanceof DiadocUnauthorized) {
          d.onAuthorized(false)
          handleToStep(d.authFirstStep)
        } else {
          setError(err.message)
        }
      })
  }, [isActive, d, handleNext, handleToStep])

  if (!isActive) {
    return <></>
  }

  return (<>
    <Typography fontSize='14px' fontWeight={400} color='#B2B2B2' mb='2em'>
    Пожалуйста, не закрывайте это окно и дождитесь окончания.
    </Typography>
    <Box sx={{ display: 'flex', minWidth: '500px', minHeight: '250px', alignItems: 'center', justifyContent: 'center' }}>
      <Stack display='flex' flexDirection='column' alignItems='center'>
        <CircularProgress />
        { error && <Alert severity="error">{error}</Alert> }
      </Stack>
    </Box>
  </>)
}
