import { Button, Checkbox, Typography } from '@mui/material'
import InfoCard from 'ui/InfoCard'
import './styles.sass'
import { BidCargo as Origin, BidCargoForUpdate, bidCargoList, bidCargoPut, BidCargoType, bidCargoTypeList } from 'api/bid'
import { useCallback, useEffect, useState } from 'react'
import { Box, Stack } from '@mui/system'
import SquareButton from 'ui/SquareButton'
import { Save } from '@mui/icons-material'
import TextField from 'ui/TextField'
import CollapseBox from 'ui/CollapseBox'
import { useAuthContext } from 'AuthContext'
import valueMethod from 'util/valueMethod'
import { toKilogram, toTon } from 'util/weight'
import Select from 'ui/Select'

const Row = ({ data: origin, onChange, typeList }: { data: BidCargoForUpdate, typeList: BidCargoType[], onChange: () => void}) => {
  const { handleResponseSuccess } = useAuthContext()
  const [data, setData] = useState<BidCargoForUpdate>(origin)

  const updateString = (key: keyof BidCargoForUpdate) => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      setData(prev => ({ ...prev, [key]: e.target.value }))
    }
  }

  const updateID = (key: keyof BidCargoForUpdate) => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      const { target: { value } } = e
      if (value.match(/^\d*/)) {
        setData(prev => ({ ...prev, [key]: value }))
      }
    }
  }

  const updateWeight = () => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      const { target: { value } } = e
      setData(prev => ({ ...prev, averageWeight: value === '' ? undefined : toKilogram(Number(value)) }))
    }
  }

  return (<>
    <Stack direction='row' spacing={2} mb='10px' alignItems='center'>
      <TextField
        label='ID'
        width='10%'
        value={data.externalId}
        onChange={updateID('externalId')}
      />
      <TextField
        label='Product base ID'
        width='10%'
        value={data.productBaseId}
        onChange={updateID('productBaseId')}
      />
      <TextField
        label='Код ОКПД'
        width='10%'
        value={data.num}
        onChange={updateString('num')}
      />
      <TextField
        label='Наименование груза'
        width='25%'
        value={data.name}
        onChange={updateString('name')}
      />
      <TextField
        label='Краткое название груза'
        width='10%'
        value={data.shortName}
        onChange={updateString('shortName')}
      />
      <TextField
        label='Средний тн по ТС'
        width='10%'
        value={valueMethod(data.averageWeight)(toTon)}
        onChange={updateWeight()}
        typeNumber
      />
      <Select
        name='cargoTypeSlug'
        label='Тип груза'
        placeholder='Выберите тип'
        options={typeList.map(({ slug, name }) => ({ value: slug, name }))}
        value={data.cargoTypeSlug}
        onChange={(cargoTypeSlug) => setData((data) => ({ ...data, cargoTypeSlug })) }
        width='10%'
      />
      <Stack direction='row' alignItems='center'>
        <Checkbox checked={data.hidden ?? false} onChange={(e) => setData(prev => ({ ...prev, hidden: e.target.checked }))} />
        <Typography>Скрытый</Typography>
      </Stack>
      <SquareButton
        variant='contained'
        onClick={() => {
          bidCargoPut(data)
            .then(({ modified, upserted }) => {
              if (modified || upserted) {
                handleResponseSuccess('Данные сохранены')
                onChange()
              }
            })
        }}
      ><Save sx={{ width: '16px' }} /></SquareButton>
      {/* { data.slug !== undefined && <SquareButton
        variant='contained'
        color='secondary'
        onClick={() => {
          if (data.slug) {
            bidCargoDelete(data.slug)
              .then(result => {
                if (result) {
                  onChange()
                }
              })
          }
        }}
      ><Delete sx={{ width: '16px' }} /></SquareButton>} */}
    </Stack>
  </>)
}

const map = (set: (d: BidCargoForUpdate[]) => void) => {
  return (docs: Origin[]) => {
    set(docs.map(({ cargoType, ...doc }) => ({ ...doc, cargoTypeSlug: cargoType?.slug })))
  }
}

export default function BidCargo () {
  const [list, setList] = useState<BidCargoForUpdate[]>([])
  const [typeList, setTypeList] = useState<BidCargoType[]>([])
  const init = useCallback(() => {
    bidCargoList().then(map(setList))
    bidCargoTypeList().then(setTypeList)
  }, [])

  useEffect(() => { init() }, [init])

  return (
    <InfoCard
      title='Груз'
      actions={<Button
        variant='outlined'
        size='small'
        onClick={() => {
          setList(prev => [...prev, {}])
        }}
      >Добавить</Button>}
    ><>
      <CollapseBox title='Названия грузов'><>
      { list.filter(item => item.slug !== undefined).map(item => <Row data={item} key={item.slug} typeList={typeList} onChange={() => init()}/>)}
      </></CollapseBox>
      <Box>
      { list.filter(item => item.slug === undefined).map((item, idx) => <Row data={item} key={idx} typeList={typeList} onChange={() => init()}/>)}
      </Box>
    </></InfoCard>
  )
}
