import { Box, Button, Stack, Typography } from '@mui/material'
import { useAuthContext } from 'AuthContext'
import { Interface } from 'api/Interface'
import { UserStatus } from 'api/UserStatus'
import { ProfileAll, profileAllUpdate } from 'api/profile'
import { UserRole } from 'api/user'
import { getNameByUserInterfaces } from 'entity/Interface'
import { useState } from 'react'
import InfoCard from 'ui/InfoCard'
import Modal from 'ui/Modal'
import TextField from 'ui/TextField'
import { isEnum } from 'util/isEnum'
import { personName } from 'util/personName'

export interface Params {
  profile: ProfileAll
  onRejection: () => void
  onConfirm: () => void
}

const nameRole = (role: unknown) => getNameByUserInterfaces(role as Interface)

export default function Identification ({ profile: { id, identificationEmployee, identificationComment, status }, onRejection, onConfirm }: Params) {
  const [open, setOpen] = useState<boolean>(false)
  const [comment, setComment] = useState<string>('')
  const [error, setError] = useState<string>()

  const { handleResponseSuccess, currentInterface: identificationEmployeeRole } = useAuthContext()

  const handleClose = () => {
    setOpen(false)
    setComment('')
    setError(undefined)
  }

  const handleConfrim = () => {
    if (!isEnum(UserRole)(identificationEmployeeRole)) {
      return
    }

    if (comment.trim() === '') {
      setError('Обязательно к заполнению')
      return
    }

    profileAllUpdate({ id, identifiedComment: comment, identificationEmployeeRole }).then(success => {
      if (success) {
        onConfirm()
        handleClose()
        handleResponseSuccess('Перевозчик идентифицирован')
      }
    })
  }

  return (<>
    <InfoCard>
      <>
        { identificationEmployee === undefined
          ? <Stack direction='row' justifyContent='space-between'>
            <Box>
              <Typography sx={{
                fontWeight: '600',
                fontSize: '18px',
                lineHeight: '22px',
                letterSpacing: '-0.02em',
                mb: 1
              }}>Идентификация перевозчика</Typography>
              <Typography sx={{
                fontWeight: '400',
                fontSize: '14px',
                lineHeight: '24px',
                letterSpacing: '-0.02em'
              }}>
                Перевозчик прошел регистрацию и в данный момент ожидает подтверждения.<br />
                Необходимо произвести идентификацию перевозчика.
              </Typography>
            </Box>
            <Stack direction='row' flexWrap='wrap' alignContent='center' gap={2}>
              {/* <Button size='small' variant='contained' color='error' onClick={onRejection}>Отказать</Button> */}
              { status === UserStatus.registered && <Button size='small' variant='contained' onClick={() => { setOpen(true) }} >Подтвердить идентификацию</Button> }
            </Stack>
          </Stack>
          : <Stack>
              <Typography sx={{
                fontWeight: '600',
                fontSize: '18px',
                lineHeight: '22px',
                letterSpacing: '-0.02em',
                mb: 1
              }}>Перевозчик был идентифицирован</Typography>
              <Typography sx={{
                fontWeight: '400',
                fontSize: '14px',
                lineHeight: '24px',
                letterSpacing: '-0.02em'
              }}>
                Идентификация: {nameRole(identificationEmployee.role)} { personName(identificationEmployee) }<br />
                Как была проведена идентификация: {identificationComment}
              </Typography>
          </Stack>}
      </>
    </InfoCard>
    <Modal
        title='Отклонить запрос'
        open={open}
        onClose={handleClose}
        content={<Stack sx={{ minWidth: '500px' }} >
              <Box>
                <TextField
                  rows={6}
                  label='Как была проведена идентификация'
                  width='100%'
                  value={comment}
                  onChange={e => { setComment(e.target.value) }}
                  errorMessage={error}
                />
              </Box>
            </Stack>
        }
        actions={<>
          <Button color='secondary' variant='outlined' onClick={handleClose}>Отменить</Button>
          <Button variant='contained' onClick={handleConfrim} >Подтвердить</Button>
        </>
        }
      />
  </>)
}
