import Ajv, { ErrorObject, JSONSchemaType } from 'ajv'
import { useState } from 'react'
import ajvErrors from 'ajv-errors'
const ajv = new Ajv({ allErrors: true })
ajvErrors(ajv)

type S<T> = T extends boolean
  ? { type: 'boolean' }
  : T extends number
  ? { type: 'number'; enum?: number[]; exclusiveMinimum?: number, minimum?: number, maximum?: number, errorMessage?: string }
  : T extends string
  ? { type: 'string'; pattern?: string; errorMessage?: string; enum?: string[]; minLength?: number }
  : T extends (infer K)[]
  ? { type: 'array'; items: S<K> }
  : T extends object
  ? {
      type: 'object'
      properties: {
        [P in keyof T]-?: undefined extends T[P] ? SimpleSchema<T[P]> & { optional: true } : SimpleSchema<T[P]>
      }
    }
  : never

export type SimpleSchema<T> = null extends T ? S<T> & { nullable: true } : S<T>

export const makeJsonSchema = <T>(source: SimpleSchema<T>): JSONSchemaType<T> => {
  const { nullable } = source as { nullable?: true }

  if (source.type === 'array') {
    return { type: 'array', items: makeJsonSchema(source.items), nullable } as unknown as JSONSchemaType<T>
  } else if (source.type !== 'object') {
    return source as JSONSchemaType<T>
  }

  const keys = Object.keys(source.properties) as (keyof T)[]
  const required: typeof keys = []
  const properties = keys.reduce((result, key) => {
    const { optional = false, ...schema } = source.properties[key] as { optional?: true }

    if (!optional) {
      required.push(key)
    }

    return { ...result, [key]: makeJsonSchema(schema as SimpleSchema<T[keyof T]>) }
  }, {})

  return { type: 'object', properties, nullable, additionalProperties: false, required } as unknown as JSONSchemaType<T>
}

export type ValidateError = ErrorObject<string, Record<string, any>, unknown>[] | null | undefined

export default function useValidate <T> (schema: JSONSchemaType<T>) {
  const validate = ajv.compile(schema)
  const [errors, setErrors] = useState<ValidateError>()

  const check = (data: unknown): data is T => {
    const valid = validate(data)
    setErrors(validate.errors)

    return valid
  }

  const apiParamsError = (err: ValidateError) => setErrors(err)

  const cleanErrors = () => setErrors(undefined)

  return { check, errors, apiParamsError, cleanErrors }
}
