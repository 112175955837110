import { JSONRPCClient, JSONRPCParams, JSONRPCRequest } from 'json-rpc-2.0'
import { AccessDeniedError } from 'api/AccesDeniedError'
import { SessionStatus } from 'api/SessionStatus'
import { UserStatus } from 'api/UserStatus'
import { isEnum } from 'util/isEnum'
import { LocalStorage } from 'util/LocalStorage'
import { asObject } from 'util/asObject'
import { Interface } from 'api/Interface'

export const LS_AUTH_TOKEN_KEY = 'auth_token'
export const AUTH_SLUG = 'auth'
export const MAIN_SLUG = 'main'
export const NOTIFY_SLUG = 'notify'

export interface GetTokenResult {
  token: string
  phone: string
  status: UserStatus
  interfaces: Interface[]
  session: SessionStatus | null
}

const isGetTokenResult = (x: unknown): x is GetTokenResult => {
  const { token, phone, status } = asObject<keyof GetTokenResult>(x)

  return typeof token == 'string' && typeof phone === 'string' && isEnum(UserStatus)(status)
}

interface JWTClaims {
  sub: string
  iss: string
  permits: string[]
  iat: number
  exp: number
}

export class Service {
  protected localStorage: LocalStorage<GetTokenResult>

  constructor (
    protected slug: string,
    protected prefix: string = '/api',
    protected cacheDeltaMts: number = 1000
  ) {
    this.localStorage = new LocalStorage(`${LS_AUTH_TOKEN_KEY}:${this.slug}`, isGetTokenResult)
  }

  public async request (method: string, params: JSONRPCParams, auth: boolean = true): Promise<unknown> {
    const headers = await this.headers(auth)

    return await this.fetch(this.slug, method, params, headers)
  }

  public async upload (data: FormData, auth: boolean = true): Promise<{
    result?: string
    error?: unknown
  }> {
    const headers = await this.headers(auth)

    const response = await fetch(`${this.prefix}/${this.slug}`, {
      method: 'POST',
      headers,
      body: data
    })

    if (response.status !== 200) {
      throw new Error(response.statusText)
    }

    return await response.json()
  }

  public async download (filename: string, auth: boolean = true): Promise<Blob> {
    const headers = await this.headers(auth)
    const response = await fetch(`${this.prefix}/${this.slug}/${filename}`, { method: 'GET', headers })

    if (response.status !== 200) {
      throw new Error(response.statusText)
    }

    return await response.blob()
  }

  public eventSource (heartbeat: number = 3): EventSource {
    return new EventSource(`${this.prefix}/${this.slug}/event?heartbeat=${Math.round(heartbeat * 1000)}`)
  }

  public clear (): void {
    this.localStorage.clear()
  }

  protected async headers (auth: boolean): Promise<Record<string, string>> {
    const headers: Record<string, string> = {}

    if (auth) {
      const { token } = await this.getToken()
      headers.authorization = `Bearer ${token}`
    }

    return headers
  }

  public async getToken (): Promise<GetTokenResult> {
    const stored = this.localStorage.get()

    if (stored !== null) {
      return stored
    }

    const { token, session, user, interfaces } = await this.fetch(AUTH_SLUG, 'session.get-token', { service: this.slug }) as {
      token: string | null
      session: SessionStatus | null
      user: UserStatus | null
      interfaces: []
    }

    const claims = token === null ? null : this.parseJwt(token)

    if (token === null || claims === null || user === null) {
      throw new AccessDeniedError(session, user)
    }

    const result = { token, phone: claims.sub, status: user, interfaces, session }

    // Коррекция - если вдруг на клиенте сбиты часы
    this.localStorage.set(result, Date.now() + (claims.exp - claims.iat) * 1000)

    return result
  }

  private async fetch (slug: string, method: string, params: JSONRPCParams, headers: Record<string, string> = {}): Promise<unknown> {
    const client: JSONRPCClient = new JSONRPCClient(
      async (jsonRPCRequest: JSONRPCRequest) => {
        const response = await fetch(`${this.prefix}/${slug}:${method}`, {
          method: 'POST',
          headers: { 'content-type': 'application/json', ...headers },
          body: JSON.stringify(jsonRPCRequest)
        })

        if (response.status === 200) {
          const data = await response.json()
          client.receive(data)
        } else if (jsonRPCRequest.id !== undefined) {
          throw new Error(response.statusText)
        }
      }
    )

    return await client.request(method, params)
  }

  private isJWtClaims (x: unknown): x is JWTClaims {
    if (x === null || typeof x !== 'object') {
      return false
    }

    const { sub, iss, permits, iat, exp } = x as { [K in keyof JWTClaims]: unknown }

    return (
      typeof sub === 'string' &&
      typeof iss === 'string' &&
      Array.isArray(permits) && !permits.find(permit => typeof permit !== 'string') &&
      typeof iat === 'number' &&
      typeof exp === 'number'
    )
  }

  private parseJwt (token: string): JWTClaims | null {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const payload = decodeURIComponent(window.atob(base64).split('').map(
      c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    ).join(''))

    const claims = JSON.parse(payload)

    return this.isJWtClaims(claims) ? claims : null
  }
}

export const authService = new Service(AUTH_SLUG)
export const mainService = new Service(MAIN_SLUG)
export const notifyService = new Service(NOTIFY_SLUG)
