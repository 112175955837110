import { Button } from '@mui/material'
import InfoCard from 'ui/InfoCard'
import './styles.sass'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { Stack } from '@mui/system'
import { Save } from '@mui/icons-material'
import TextField from 'ui/TextField'
import { useAuthContext } from 'AuthContext'
import { diadocSettingsGet, diadocSettingsPut, DiadocSettings as DiadocSettingsType } from 'api/diadoc'
import schems from 'validation/diadocSettings'
import useValidate from 'validation/validate'

export default function DiadocSettings () {
  const [data, setData] = useState<DiadocSettingsType>({})
  const init = useCallback(() => diadocSettingsGet().then(setData), [])
  useEffect(() => { init() }, [init])
  const { handleResponseSuccess } = useAuthContext()
  const { check, errors } = useValidate(schems)

  const save = () => {
    console.log(errors)
    if (!check(data)) {
      return
    }

    diadocSettingsPut(data)
      .then(r => {
        if (r.upserted || r.modified) {
          handleResponseSuccess('Данные сохранены')
        }
      })
  }

  const updateString = (key: keyof DiadocSettingsType) =>
    (e: ChangeEvent<HTMLInputElement>) => {
      const { target: { value } } = e

      setData({ ...data, [key]: value })
    }

  return (
    <InfoCard
      title='Диадок настройки'
      actions={<Button
        variant='outlined'
        size='small'
        onClick={save}
      ><Save sx={{ mr: '10px' }}/>Сохранить</Button>}
    ><>
      <Stack direction='row' spacing={2}>
        <TextField
          name='boxId'
          label='ID ящика Астон в Диадок'
          width='25%'
          value={data.boxId}
          errors={errors}
          onChange={updateString('boxId')}
        />
        {/* <TextField
          name='routeId'
          label='ID Маршрута'
          width='25%'
          value={data.routeId}
          errors={errors}
          onChange={updateString('routeId')}
        /> */}
      </Stack>
      <Stack direction='row' spacing={2}>
        <TextField
          name='watermarkText'
          label='Текст водяного знака на документах'
          width='25%'
          value={data.watermarkText}
          errors={errors}
          onChange={updateString('watermarkText')}
          rows={3}
        />
      </Stack>
    </></InfoCard>
  )
}
