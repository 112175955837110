import './styles.sass'
import { Grid } from '@mui/material'
import InfoCard from 'ui/InfoCard'
import { metaOrganization } from 'entity/Organization'
import metaPassport from 'entity/Passport'
import Upload from 'ui/Upload'
import { SectionParams } from '..'
import { Organization, TypeLegalPerson, TypeTaxation } from 'api/organization'
import { PassportForUpdate } from 'api/passport'
import { useMemo } from 'react'

type Params = {
  organization: SectionParams<Organization>
  passport: SectionParams<PassportForUpdate>
}

export default function DocumentsCarrierProfile ({ organization, passport }: Params) {
  const updateUploadOrg = (key: keyof Organization) =>
    (filename?: string) =>
      organization.setData((data) => ({ ...data, [key]: filename || undefined }))

  const updateUploadPassp = (key: keyof PassportForUpdate) =>
    (filename?: string) =>
      passport.setData((data) => ({ ...data, [key]: filename || undefined }))

  const isLtd = useMemo(() =>
    organization.data.typeLegalPerson === TypeLegalPerson.organization
  , [organization.data.typeLegalPerson])

  const metaOrg = useMemo(() =>
    metaOrganization(organization.data.typeLegalPerson)
  , [organization.data.typeLegalPerson])

  return (
    <InfoCard title="Документы">
      <Grid container spacing={4}>
        <Grid item sm={6} md={4}>
          <Upload
            name='scanInn'
            label={metaOrg.title('scanInn')}
            value={organization.data.scanInn}
            onChange={updateUploadOrg('scanInn')}
            disabled={organization.disabled}
            errors={organization.errors}
          />
        </Grid>
        <Grid item sm={6} md={4}>
          <Upload
            name='scanRegistration'
            label={isLtd ? 'Выписка из ЕГРЮЛ' : 'Выписка из ЕГРИП'}
            value={organization.data.scanRegistration}
            onChange={updateUploadOrg('scanRegistration')}
            disabled={organization.disabled}
            errors={organization.errors}
          />
        </Grid>
        <Grid item sm={6} md={4}>
          <Upload
            name="scanCode"
            label={metaOrg.title('scanCode')}
            value={organization.data.scanCode}
            onChange={updateUploadOrg('scanCode')}
            disabled={organization.disabled}
            errors={organization.errors}
          />
        </Grid>
        <Grid item sm={6} md={4}>
          <Upload
            name="scan1"
            label={metaPassport.title('scan1')}
            value={passport.data.scan1}
            onChange={updateUploadPassp('scan1')}
            disabled={passport.disabled}
            errors={passport.errors}
          />
        </Grid>
        <Grid item sm={6} md={4}>
          <Upload
            name="scan2"
            label={metaPassport.title('scan2')}
            value={passport.data.scan2}
            onChange={updateUploadPassp('scan2')}
            disabled={passport.disabled}
            errors={passport.errors}
          />
        </Grid>
        <Grid item sm={6} md={4}>
          <Upload
            name="scanCertificate"
            label={isLtd ? 'Свидетельство ОГРН' : 'Свидетельство ОГРНИП'}
            value={organization.data.scanCertificate}
            onChange={updateUploadOrg('scanCertificate')}
            disabled={organization.disabled}
            errors={organization.errors}
          />
        </Grid>
        { organization.data.typeTaxation === TypeTaxation.patent && <Grid item sm={6} md={4}>
          <Upload
            name="scanPatent"
            label='Патент'
            value={organization.data.scanPatent}
            onChange={updateUploadOrg('scanPatent')}
            disabled={organization.disabled}
            errors={organization.errors}
          />
        </Grid>}
        <Grid item sm={6} md={4}>
          <Upload
            name="scanPowerOfAttorney"
            label="Доверенность"
            value={organization.data.scanPowerOfAttorney}
            onChange={updateUploadOrg('scanPowerOfAttorney')}
            disabled={organization.disabled}
            errors={organization.errors}
          />
        </Grid>
      </Grid>
    </InfoCard>
  )
}
