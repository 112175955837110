import { NotifyEvent } from '.'
import { useEffect } from 'react'
import { Box, Button } from '@mui/material'
import { useMainRoutes } from '../../routes'
import { MoreHoriz } from '@mui/icons-material'

export interface Data {
  truckRunId: string
  message: string
}

const SLUG = 'truck_run_long_confirmed_event'

export default function TruckRunLongConfirmed ({ slug, data, snack, navigate } :NotifyEvent) {
  const { links } = useMainRoutes()
  useEffect(() => {
    if (slug !== SLUG) {
      return
    }

    if (snack === undefined) {
      return
    }

    if (data === undefined) {
      return
    }

    const { message, truckRunId } = data as Data

    const open = () => {
      if (navigate === undefined) {
        return
      }
      navigate(`${links.CARRIER_TRIPS_PAGE}/${truckRunId}`)
    }

    snack({
      title: message,
      color: 'warning',
      content: (<Box>
        <Button variant='outlined' color='inherit' onClick={open}><MoreHoriz/></Button>
      </Box>),
      options: { autoHideDuration: 10000 }
    })
  }, [slug, data, snack, links.CARRIER_TRIPS_PAGE, navigate])

  return (<></>)
}
