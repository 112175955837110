import { PassportForCreate } from 'api/passport'
import { makeJsonSchema, SimpleSchema } from 'validation/validate'
import { ADDRESS_REGEXP } from './Organization'
import metaPassport from 'entity/Passport'

export type Passport = Omit<PassportForCreate, 'scan1' | 'scan2'>
export type PassportUploadForCreateProfile = Required<Pick<PassportForCreate, 'scan1' | 'scan2'>>

const simpleSchema: SimpleSchema<Passport> = {
  type: 'object',
  properties: {
    passportSeries: { type: 'string', pattern: '^\\d{4}$', errorMessage: 'Некорректная серия паспорта' },
    passportNum: { type: 'string', pattern: '^\\d{6}$', errorMessage: 'Некорректный номер паспорта' },
    issuedDepartment: { type: 'string', pattern: '^[а-яА-ЯёЁ \\d]+', minLength: 10, errorMessage: 'Некорректно заполнено поле' },
    departmentCode: { type: 'string', pattern: '^(?:\\d{6}|\\d{3})$', errorMessage: 'Некорректный код подразделения' },
    registrationAddress: { type: 'string', pattern: ADDRESS_REGEXP, minLength: 10, errorMessage: 'Некорректно заполнено поле' },
    dateIssue: { type: 'number' },
    dateOfBirth: { type: 'number' }
  }
}

const simpleUp: SimpleSchema<PassportUploadForCreateProfile> = {
  type: 'object',
  properties: {
    scan1: { type: 'string' },
    scan2: { type: 'string' }
  }
}

const schema = makeJsonSchema<Passport>(simpleSchema)
export const schemaPassportUploadForCreateProfile = makeJsonSchema<PassportUploadForCreateProfile>(simpleUp)

export default schema

export function clear ({
  passportSeries, departmentCode, ...data
}: Partial<Passport>): Partial<Passport> {
  const cleanMask = (key: keyof Passport, value?: string): string | undefined =>
    value !== undefined ? metaPassport.cleanMask(key, value) : value

  const result = {
    ...data,
    passportSeries: cleanMask('passportSeries', passportSeries),
    departmentCode: cleanMask('departmentCode', departmentCode)
  }

  return result
}
