import { Stack } from '@mui/material'
import MapIcon from '@mui/icons-material/Map'
import './styles.sass'
import { Bid as BidInterface } from 'api/bid'
import clsx from 'clsx'
import { useNavigate } from 'react-router-dom'
import BidCardUi from 'ui/BidCard'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { useMainRoutes } from 'routes'
import { Id } from 'api/Id'
import SquareButton from 'ui/SquareButton'

interface Params {
  data: BidInterface
  selectedId?: Id
  viewToMap?: (bid: BidInterface) => void
}

export default function BidCard ({ data, selectedId, viewToMap }: Params) {
  const { links } = useMainRoutes()
  const navigate = useNavigate()

  return (
    <BidCardUi
      data={data}
      className={clsx('carriersBid', { bidSelected: selectedId === data.id })}
      id={data.id}
      controls={<Stack direction='row' spacing={1}>
        <SquareButton
          variant='outlined'
          color='secondary'
          onClick={() => !!viewToMap && viewToMap(data)}
        >
          <MapIcon sx={{ width: '16px', color: '#B2B2B2' }} />
        </SquareButton>
        <SquareButton
          variant='contained'
          color='primary'
          onClick={() => navigate(`${links.CARRIER_BIDS_PAGE}/${data.id}`)}
        >
          <MoreHorizIcon sx={{ width: '16px', height: '16px', color: '#FFFFFF' }} />
        </SquareButton>
      </Stack>}
    />
  )
}
