import { DriverForCreate } from 'api/driver'
import { makeJsonSchema, SimpleSchema } from './validate'
import { EMAIL_PATTERN } from 'common/Mask/EmailMask'
import { Optional } from 'util/type/Optional'

type Driver = Optional<Omit<DriverForCreate, 'vehicleTrailer' | 'passport' | 'isCarrier'>, 'scanHireOrder' | 'scanContract' | 'secondName'>

const simpleSchema: SimpleSchema<Driver> = {
  type: 'object',
  properties: {
    familyName: { type: 'string', pattern: '^[а-яА-ЯёЁ\\- ]+$', minLength: 3, errorMessage: 'Некорректно заполнена фамилия' },
    firstName: { type: 'string', pattern: '^[а-яА-ЯёЁ\\- ]+$', minLength: 3, errorMessage: 'Некорректно заполнено имя' },
    secondName: { type: 'string', optional: true, pattern: '^[а-яА-ЯёЁ\\- ]*$', errorMessage: 'Некорректно заполнено отчество' },
    phone: { type: 'string', pattern: '^\\+[1-9]{2}\\d{9}$', errorMessage: 'Некорректно заполнен номер телефона' },
    email: { type: 'string', pattern: EMAIL_PATTERN, errorMessage: 'Некорректно заполнен Email' },
    permitNumber: { type: 'string', pattern: '^\\d{10}$', errorMessage: 'Некорректно заполнен номер водительского удостоверения' },
    scanPermit: { type: 'string', pattern: '^[a-zA-Z0-9]+\\.[a-zA-Z]{3,4}$', errorMessage: 'Поле обязательно к заполнению' },
    scanPassport1: { type: 'string', pattern: '^[a-zA-Z0-9]+\\.[a-zA-Z]{3,4}$', errorMessage: 'Поле обязательно к заполнению' },
    scanPassport2: { type: 'string', pattern: '^[a-zA-Z0-9]+\\.[a-zA-Z]{3,4}$', errorMessage: 'Поле обязательно к заполнению' },
    scanHireOrder: { type: 'string', pattern: '^[a-zA-Z0-9]+\\.[a-zA-Z]{3,4}$', optional: true },
    scanContract: { type: 'string', pattern: '^[a-zA-Z0-9]+\\.[a-zA-Z]{3,4}$', optional: true },
    consent: { type: 'boolean' }
  }
}

const schema = makeJsonSchema<Driver>(simpleSchema)

export default schema
