import NavigatePanel from 'ui/NavigatePanel'
import { Box, Button, CircularProgress, Stack } from '@mui/material'
import './styles.sass'
import { useMainRoutes } from 'routes'
import InfoCard from 'ui/InfoCard'
import DatePicker, { RangeDate } from 'ui/DatePicker'
import { useEffect, useState } from 'react'
import SelectAddress from 'ui/SelectAddress'
import { Id, isId } from 'api/Id'
import { dispatcherTruckRunsRegisterDownload, dispatcherTruckRunsRegisterHistory, DispatcherTruckRunsRegisterHistoryResult, DispatcherTruckRunsRegisterParams } from 'api/shipping'
import download from 'util/download'
import { useAuthContext } from 'AuthContext'
import InfoCell from 'ui/InfoCell'
import None from 'ui/None'
import TsToDate from 'common/TsToDate'

export default function DispatcherRegisters () {
  const { links, routesMap } = useMainRoutes()
  const [dispatcherRegisterRangeDate, setDispatcherRegisterRangeDate] = useState<RangeDate>()
  const [pointAddressId, setPointAddressId] = useState<Id>()
  const [dateRangeError, setDateRangeError] = useState<string>()
  const [isDownloadingInProgress, setIsDownloadingInProgress] = useState<boolean>(false)
  const [registerHistory, setRegisterHistory] = useState<DispatcherTruckRunsRegisterHistoryResult[]>()
  const { handleResponseFailure } = useAuthContext()

  useEffect(() => {
    if (!registerHistory) {
      dispatcherTruckRunsRegisterHistory({ sort: { createTs: 'desc' } }).then(setRegisterHistory)
    }
  }, [registerHistory])

  const handleRegisterDownload = (params: DispatcherTruckRunsRegisterParams) => {
    setIsDownloadingInProgress(true)

    dispatcherTruckRunsRegisterDownload(params)
      .then(file => {
        setIsDownloadingInProgress(false)

        if (file === null) {
          handleResponseFailure('Реестр не был сформирован')
          return
        }
        download(file).then(() => setRegisterHistory(undefined))
      })
  }

  return (
    <div className='dispatcherRegisters'>
      <div className='dispatcherRegisters__content'>
        <NavigatePanel
          breadcrumbs={{
            items: routesMap.getBreadcrumbs(links.DISPATCHER_REGISTERS_PAGE)
          }}
          title='Реестры'
        />
        <div className='dispatcherRegisters__body'>
          <InfoCard title="Реестр выгрузки">
            <Stack direction="row" alignItems='center' width='100%' gap={2}>
              <DatePicker
                label='Выберите диапазон дат'
                placeholder='Выберите диапазон дат'
                range={dispatcherRegisterRangeDate}
                onChange={(startTs, endTs) => {
                  if (startTs !== undefined && endTs !== undefined) {
                    setDispatcherRegisterRangeDate(startTs < endTs ? { startTs, endTs } : { startTs: endTs, endTs: startTs })
                  }
                }}
                rangeDate
                errorMessage={dateRangeError}
                width='20em'
              />
              <SelectAddress
                itemsDirection='row-reverse'
                label='Пункт погрузки'
                placeholder='Выберите пункт загрузки'
                value={pointAddressId}
                onChange={setPointAddressId}
                itemName
                width='100%'
              />
              <Button
                variant='contained'
                onClick={() => {
                  if (dispatcherRegisterRangeDate === undefined) {
                    setDateRangeError('Поле обязательно к заполнению')
                    return
                  }
                  if (isId(pointAddressId)) {
                    handleRegisterDownload({ pointAddressId, tsFrom: dispatcherRegisterRangeDate.startTs, tsTo: dispatcherRegisterRangeDate.endTs })
                  }
                }}
                disabled={pointAddressId === undefined || dispatcherRegisterRangeDate === undefined || isDownloadingInProgress}
                sx={{ minWidth: '100px', minHeight: '40px', maxHeight: '40px', marginTop: '8px' }}
              >{isDownloadingInProgress ? <CircularProgress size='20px' /> : 'Скачать'}</Button>
            </Stack>
          </InfoCard>
          {registerHistory && <InfoCard title="История">
            <Box
              display='flex'
              flexDirection='column'
              sx={{
                '& .registerHistory_item:last-child': { borderBottom: 'none' },
                '& .registerHistory_item:only-child': { borderBottom: 'none' }
              }}>
              {registerHistory.length === 0 && <None desc='Нет запросов' />}
              {
                registerHistory.length > 0 &&
                registerHistory.map((item) => <Box
                  py={2}
                  key={item.id}
                  display='flex'
                  flexDirection='row'
                  justifyContent='space-between'
                  borderBottom='1px solid #EBEBEB'
                  className='registerHistory_item'
                >
                  <Box display='flex' flexDirection='row' justifyContent='space-between'>
                    <InfoCell label='Дата выгрузки реестра' value={TsToDate(item.createTs).toLocaleDateString()} />
                    <InfoCell label='C' value={TsToDate(item.tsFrom).toLocaleDateString()} />
                    <InfoCell label='По' value={TsToDate(item.tsTo).toLocaleDateString()} />
                    <InfoCell label='Пункт погрузки' value={item.addressName} />
                  </Box>
                  {item.filename && <Button
                    size='small'
                    variant='contained'
                    onClick={() => download(item.filename)}
                  >Скачать</Button>}
                </Box>)
              }
            </Box>
          </InfoCard>}
        </div>
      </div>
    </div>
  )
}
