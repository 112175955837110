import { Visibility } from '@mui/icons-material'
import { Button, Chip, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { ProfileAll, ProfileContractStatus } from 'api/profile'
import InfoCard from 'ui/InfoCard'
import None from 'ui/None'
import SquareButton from 'ui/SquareButton'
import TsToFormatDate from 'util/TsToFormatDate'
import download from 'util/download'

export interface Params {
  profile?: ProfileAll
  onSave: () => void
}

export default function Contracts ({ profile, onSave }: Params) {
  if (profile === undefined) {
    return (<></>)
  }

  return (<>
    <InfoCard title='Договора' >
      <>
      { profile.contracts && profile.contracts.length > 0
        ? <Stack direction='column' spacing={2}>
        { profile.contracts.sort((a, b) => b.createTs - a.createTs).map((item, idx) => (<Stack direction='row' spacing={2} alignItems='center' key={`contract-${idx}`}>
          <Typography>Согласие отправлено {TsToFormatDate(item.createTs, 'dd.MM.yyyy')} в {item.customer.shortName ?? item.customer.fullName}</Typography>
          { item.customer.scanCarrierOffer && <Button
              size='small'
              variant='contained'
              color='secondary'
              onClick={() => download(item.customer.scanCarrierOffer as string)}
              endIcon={<Visibility />}
            >Договор оферты</Button> }
          <>
            { item.scanSignedСontract
              ? <>{ item.scanSignedСontract !== '' && <SquareButton size='small' variant='outlined' color='secondary' onClick={() => download(item.scanSignedСontract as string)} ><Visibility sx={{ color: '#EBEBEB' }} /></SquareButton> }</>
              : <>{ item.filename !== undefined && item.filename !== '' && <SquareButton size='small' variant='outlined' color='secondary' onClick={() => download(item.filename as string)} ><Visibility sx={{ color: '#EBEBEB' }} /></SquareButton> }</>
            }
            { item.status === ProfileContractStatus.new && <Chip label="Не подписан" color="secondary" variant='outlined' size='small' /> }
            { item.status === ProfileContractStatus.signed && <Chip label="Подписан" color="primary" variant='outlined' size='small' />}
            { item.status === ProfileContractStatus.verification && <Chip label="Ожидает проверки" color="primary" variant='outlined' size='small' />}
            { item.status === ProfileContractStatus.rejected && <>
                <Chip label="Отклонено" color="error" variant='outlined' size='small' />
                <Typography>{TsToFormatDate(item.createTs, 'dd.MM.yyyy hh:mm')}</Typography>
                <Typography>{item.rejectionComment}</Typography>
              </>
            }
          </>
        </Stack>))}
      </Stack>
        : <Stack minHeight='50px'>
        <None desc="Договора отсутствуют" />
      </Stack>}
      </>
    </InfoCard>
  </>)
}
