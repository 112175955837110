import { BidFreeSpace } from 'api/bid'
import './styles.sass'
import formatDate from 'common/CarrierBids/TsToFormatDate'
import clsx from 'clsx'

export interface Reserve {
  shipmentTs: number
  reserveSeats: number
}
interface Params {
  data: BidFreeSpace
  size?: 'big' | 'small'
  reserve?: Reserve,
  className?: string
  onReserve?: (params: Reserve) => void
}

export default function FreeSpaceItem ({ data, reserve, onReserve, ...params }: Params) {
  const { shipmentTs } = data

  const small = params.size === 'small'

  const handleOpen = () => {
    if (onReserve === undefined || small) {
      return
    }

    // setOpen(true)
    onReserve({
      shipmentTs,
      reserveSeats: 0
    })
  }

  return (<>
    <div className={clsx(
      'bidsComponentsFreeSpaceItem',
      small ? 'bidsComponentsFreeSpaceItemSmall' : 'bidsComponentsFreeSpaceItemBig',
      { bidsComponentsFreeSpaceItem_selected: reserve?.shipmentTs === shipmentTs },
      params.className ?? ''
    )}>
      <div className='bidsComponentsFreeSpaceItem_date' onClick={handleOpen}><p>{formatDate(data.shipmentTs)}</p></div>
      <div className={clsx('bidsComponentsFreeSpaceItem_seats', { bidsComponentsFreeSpaceItem_seats_border: false })} onClick={handleOpen}><p>{data.seats} { !small && <>из {data.totalSeats}</> }</p></div>
    </div>
  </>)
}
