import { MoreHoriz, LocalShipping, Person } from '@mui/icons-material'
import { Button, Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMainRoutes } from 'routes'
import InfoCard from 'ui/InfoCard'
import './styles.sass'
import { DashboardConfirmationRequests, dashboardConfirmationRequests, isDriver, isCarrier, isVehicle } from 'api/dashboard'
import Row from '../Row'
import Driver from 'ui/icon/Driver'
import { personName } from 'util/personName'
import None from 'ui/None'
import vehicleName from 'util/vehicleName'

export default function ConfirmationRequests () {
  const { links } = useMainRoutes()
  const navigate = useNavigate()
  const [list, setList] = useState<DashboardConfirmationRequests[]>([])

  useEffect(() => {
    dashboardConfirmationRequests().then(setList)
  }, [])

  return (
    <InfoCard
      title='Запросы на подтверждение'
      actions={ <Button
        size='small'
        variant='text'
        sx={{ fontSize: '14px', textDecoration: 'underline' }}
        endIcon={<MoreHoriz />}
        onClick={() => navigate(links.REGISTRY_CARRIERS_PAGE)}
      >Перевозчики</Button>}
      className='dispatcherHome__infoCard'
    >
      <>
      { list.length === 0
        ? <None desc='Нет новых запросов на подтверждение' />
        : list.map((item, idx) =>
          isCarrier(item)
            ? <Row
          onClick={() => navigate(`${links.REGISTRY_CARRIER_PAGE}/${item.carrier?.profileId}`)}
          icon={<Person sx={{ color: '#B2B2B2' }} />}
          info={<Stack direction='row' spacing={5} width='80%'>
            <Typography fontWeight={500} fontSize='14px' width='20%'>Перевозчик</Typography>
            <Typography fontWeight={400} fontSize='14px'>{item.carrier?.shortName}</Typography>
          </Stack>}
          key={idx}
        />
            : isDriver(item)
              ? <Row
          onClick={() => navigate(`${links.REGISTRY_CARRIER_PAGE}/${item.driver?.profileId}/drivers/${item.driver?.id}`)}
          icon={<Driver color='#B2B2B2' fill='#B2B2B2' />}
          info={<Stack direction='row' spacing={5} width='80%'>
            <Typography fontWeight={500} fontSize='14px' width='20%'>Водитель</Typography>
            <Typography fontWeight={400} fontSize='14px'>{personName(item.driver ?? {})}</Typography>
          </Stack>}
          key={idx}
        />
              : isVehicle(item)
                ? <Row
          onClick={() => navigate(`${links.REGISTRY_CARRIER_PAGE}/${item.vehicle?.profileId}/vehicles/${item.vehicle?.id}`)}
          icon={<LocalShipping sx={{ color: '#B2B2B2' }} />}
          info={<Stack direction='row' spacing={5} width='80%'>
            <Typography fontWeight={500} fontSize='14px' width='20%'>Транспорт</Typography>
            <Typography fontWeight={400} fontSize='14px'>{vehicleName(item.vehicle)}</Typography>
          </Stack>}
          key={idx}
        />
                : <></>)
      }
      </>
    </InfoCard>
  )
}
