import 'ui/Diadoc/lib/cadesplugin_api'
import { Certificate } from 'crypto-pro'

export function canAsyncCadesplugin (cadesplugin: CADESPlugin): cadesplugin is CADESPluginAsync {
  return (cadesplugin as CADESPluginAsync).CreateObjectAsync !== undefined
}

export async function NewSigner (plugin: CADESPluginAsync, cert: CAPICOM.CertificateAsync) {
  const oCertificatePrivateKey = await cert.PrivateKey
  await oCertificatePrivateKey.propset_CachePin(true)

  const signer = await plugin.CreateObjectAsync('CAdESCOM.CPSigner')
  await signer.propset_Certificate(cert)
  await signer.propset_CheckCertificate(true)

  return signer
}

export async function Signature (plugin: CADESPluginAsync, signer: CAdESCOM.CPSignerAsync, contentBase64: string) {
  const oSignedData = await plugin.CreateObjectAsync('CAdESCOM.CadesSignedData')
  await oSignedData.propset_ContentEncoding(cadesplugin.CADESCOM_BASE64_TO_BINARY)
  await oSignedData.propset_Content(contentBase64)
  return oSignedData.SignCades(signer, cadesplugin.CADESCOM_CADES_BES, true)
}

export async function Signer (cert: Certificate | undefined) {
  // @ts-ignore
  const plugin = window.cadesplugin
  if (!canAsyncCadesplugin(plugin)) {
    throw new Error('async cadesplugin unavailable')
  }
  if (!cert) {
    throw new Error('Сертификат для подписания документов не выбран')
  }
  return NewSigner(plugin, cert._cadesCertificate)
}

export async function Sign (signer: CAdESCOM.CPSignerAsync, fromBoxId: string, messageBase64: string) {
  // @ts-ignore
  const plugin = window.cadesplugin
  if (!canAsyncCadesplugin(plugin)) {
    throw new Error('async cadesplugin unavailable')
  }
  if (messageBase64 === '') {
    throw new Error('Подписываемое сообщение пустое')
  }
  return Signature(plugin, signer, messageBase64)
}

export function ArrayBufferToBase64 (buffer: ArrayBuffer) {
  let binary = ''
  const bytes = new Uint8Array(buffer)
  const len = bytes.byteLength
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i])
  }
  return window.btoa(binary)
}
