import None from 'ui/None'
import './styles.sass'
import NavigatePanel from 'ui/NavigatePanel'
import { useMainRoutes } from 'routes'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import {
  ShippingAllResponseItem,
  ShippingAllResponseLoadingDaysItem,
  shippingAllResponses,
  shippingTruckRunChangeStatus,
  ShippingTruckRunStatus as TruckRunStatus
} from 'api/shipping'
import { useCallback, useEffect, useState } from 'react'
import ResponseCard from './ResponseCard'
import { Bid } from 'api/bid'
import TsToFormatDate from 'common/CarrierBids/TsToFormatDate'
import DescBid from './DescBid'
import { Id } from 'api/Id'
import { Button, Stack, Typography } from '@mui/material'
import { Check } from '@mui/icons-material'
import { useAuthContext } from 'AuthContext'
import LoadingDayIndicator from 'ui/LoadingDayIndicator'
import TabsRow from 'ui/NavigatePanel/TabsRow'

export type ResponseItem = ShippingAllResponseItem
type LoadingDaysItem = ShippingAllResponseLoadingDaysItem & { date: string }

const updateByBidId = (origin: ResponseItem[], bidId: Id) =>
  (method: (item: ResponseItem) => ResponseItem) => {
    const result = []

    for (let item of origin) {
      if (item.bid.id === bidId) {
        item = method(item)
      }

      result.push(item)
    }

    return result
  }

const truckRunDown = (truckRunId: Id) =>
  (orgin: ResponseItem) => {
    const { limit, overLimit } = orgin
    const tr = limit.find(item => item.id === truckRunId)

    if (tr === undefined) {
      return orgin
    }

    return {
      ...orgin,
      limit: limit.filter(item => item.id !== truckRunId),
      overLimit: [...overLimit, tr]
    }
  }

const truckRunUp = (truckRunId: Id) =>
  (orgin: ResponseItem) => {
    const { limit, overLimit, freeSpace } = orgin
    const { seats } = freeSpace ?? { seats: 0 }

    if (seats <= limit.length) {
      return orgin
    }

    const tr = overLimit.find(item => item.id === truckRunId)

    if (tr === undefined) {
      return orgin
    }

    return {
      ...orgin,
      limit: [...limit, tr],
      overLimit: overLimit.filter(item => item.id !== truckRunId)
    }
  }

export enum SEARCH_PARAMS {
  shipmentTs = 'shipment_ts'
}

const BLACK_LIST_TAB = 'blackList'

export default function DispatcherResponses () {
  const { links, routesMap } = useMainRoutes()
  const navigate = useNavigate()

  const [loadingDays, setLoadingDays] = useState<LoadingDaysItem[]>([])
  const [currentLoadingTs, setCurrentLoadingTs] = useState<number>()
  const [selectBid, setSelectBid] = useState<Bid>()
  const [responses, setResponses] = useState<ResponseItem[]>([])
  const [viewBlackList, setViewBlackList] = useState<boolean>(false)

  const [searchParams] = useSearchParams()
  const { view } = useParams()

  const { handleResponseSuccess, handleResponseFailure } = useAuthContext()

  const changeTab = (shipmentTs: string) => {
    if (loadingDays.length === 0) {
      return
    }

    setSelectBid(undefined)

    if (loadingDays[0].shipmentTs === Number(shipmentTs)) {
      navigate('')
      return
    }

    navigate(`?${SEARCH_PARAMS.shipmentTs}=${shipmentTs}`)
  }

  const openBlackList = (v: string) => {
    if (view === v) {
      navigate(links.DISPATCHER_RESPONSES)
    } else {
      navigate(BLACK_LIST_TAB)
    }
  }

  useEffect(() => {
    if (loadingDays.length === 0) {
      return
    }

    const next = searchParams.get(SEARCH_PARAMS.shipmentTs)
    if (next && loadingDays.find(item => item.shipmentTs === Number(next))) {
      setCurrentLoadingTs(Number(next))
      return
    }

    setCurrentLoadingTs(loadingDays[0].shipmentTs)
    navigate('')
  }, [searchParams, loadingDays, navigate])

  const init = useCallback(() => {
    shippingAllResponses({ filters: { loadingTs: currentLoadingTs, onlyBlackList: !!viewBlackList } })
      .then(({ list, loadingDays }) => {
        setResponses(list)
        setLoadingDays(loadingDays.map(({ shipmentTs, indicator }) => ({ shipmentTs, date: TsToFormatDate(shipmentTs), indicator })))
      })
  }, [currentLoadingTs, viewBlackList])

  useEffect(() => {
    setViewBlackList(view === BLACK_LIST_TAB)
  }, [view])

  useEffect(() => {
    init()
  }, [init])

  const successMsg = () => handleResponseSuccess('Данные изменены')

  const handleDown = (bidId: Id, truckRunId: Id) => {
    setResponses(updateByBidId(responses, bidId)(truckRunDown(truckRunId)))
  }

  const handleUp = (bidId: Id, truckRunId: Id) => {
    setResponses(updateByBidId(responses, bidId)(truckRunUp(truckRunId)))
  }

  const handleCancle = async (truckRunId: Id) => {
    await shippingTruckRunChangeStatus({ list: [{ id: truckRunId, status: TruckRunStatus.archiveReject }] })
    successMsg()
    init()
  }

  const handleApprove = async (truckRunId: Id) => {
    await shippingTruckRunChangeStatus({ list: [{ id: truckRunId, status: TruckRunStatus.confirmed }] })
    successMsg()
    init()
  }

  const handleApproveAll = async (bidId: Id) => {
    const r = responses.find(item => item.bid.id === bidId)

    if (r === undefined) {
      return
    }

    const truckRunStatusList = r.limit.map(item => ({ id: item.id, status: TruckRunStatus.confirmed }))

    await shippingTruckRunChangeStatus({ list: truckRunStatusList })
    successMsg()
    init()
  }

  const handleApproveAllDay = async () => {
    if (responses.length === 0) {
      return
    }

    const truckRunStatusList = responses
      .flatMap(item => item.limit)
      .map(item => ({ id: item.id, status: TruckRunStatus.confirmed }))

    if (truckRunStatusList.length === 0) {
      handleResponseFailure('Нет рейсов для подтверждения')
      return
    }

    await shippingTruckRunChangeStatus({ list: truckRunStatusList })
    successMsg()
    init()
  }

  if (view !== undefined && view !== BLACK_LIST_TAB) {
    navigate(links.DISPATCHER_RESPONSES)
  }

  return (
    <div className='dispatcherResponses'>
      <div className='dispatcherResponses__body'>
        <NavigatePanel
          breadcrumbs={{
            items: routesMap.getBreadcrumbs(links.DISPATCHER_RESPONSES)
          }}
          title='Отклики'
          tabs={{
            items: loadingDays.map(item => ({
              label: <Stack direction='row' spacing={0.5} alignItems='center'>
                <Typography>{item.date}</Typography>
                <LoadingDayIndicator indicator={item.indicator} />
              </Stack>,
              value: item.shipmentTs.toString()
            })),
            value: `${currentLoadingTs}`,
            onChange: changeTab,
            scrollable: true,
            control: responses.length > 0
              ? <TabsRow
                items={[{ label: 'Черный список', value: BLACK_LIST_TAB }]}
                value={viewBlackList ? BLACK_LIST_TAB : ''}
                onChange={() => {}}
                onClick={openBlackList}
              />
              : undefined
          }}
          actions={!viewBlackList && responses.length
            ? <>
              <Button variant='contained' color='primary' size='large' onClick={handleApproveAllDay} disabled={responses.length === 0} >
                <Check style={{ color: '#ffffff', marginRight: '10px' }}/>Подтвердить все отклики на {TsToFormatDate(currentLoadingTs)}
              </Button>
            </>
            : <></>}
        />
        <div className='dispatcherResponses__body_content'>
          { responses.length > 0
            ? <div className='dispatcherResponses__body_content_items'>
              { responses.map(item => <ResponseCard
                data={item}
                key={`response-card-${item.bid.id}`}
                onSelectBid={setSelectBid}
                down={handleDown}
                up={handleUp}
                approve={handleApprove}
                approveAll={handleApproveAll}
                cancel={handleCancle}
                onClickTruckRun={id => navigate(`${links.CARRIER_TRIPS_PAGE}/${id}`)}
                viewBlackList={viewBlackList}
              />)}
            </div>
            : <div className='dispatcherResponses__body_content_none'><None desc='На данный момент нет информации об откликах' border='none' /></div>
          }
        </div>
      </div>
      <div className='dispatcherResponses__desc'>
      { selectBid
        ? <DescBid bid={selectBid} shipmentTs={currentLoadingTs} onGotoBid={() => navigate(`${links.DISPATCHER_BID_PAGE}/${selectBid.id}`)} />
        : <None desc='Для просмотра деталей выберите заявку в списке слева' border='none' />
      }
      </div>
    </div>
  )
}
