import { Interface } from 'api/Interface'
import { IRoute } from './types'

export class RoutesMap {
  constructor (
    protected routes: IRoute[],
    protected fallbackRoute: IRoute,
    protected fallbackRouteDisabled: IRoute
  ) {}

  get (link: string): IRoute | undefined {
    return this.routes.find(({ path }) => path === link)
  }

  getRoutesByUserInterface (current: Interface, disabled = false): Pick<IRoute, 'path' | 'element'>[] {
    const routes = this._getRoutesByInterface(current)
      .filter(({ group }) => !disabled || group === 'bottom')

    routes.push(disabled ? this.fallbackRouteDisabled : this.fallbackRoute)

    return routes.map(({ path, element }) => ({ path, element }))
  }

  getGroupRoutesByUserInterface (current: Interface | undefined): NonNullable<IRoute['group']>[] {
    if (current === undefined) {
      return []
    }

    const routes = this._getRoutesByInterface(current)
    const groups = [...new Set(routes.map(item => item.group).filter(item => item !== undefined))] as NonNullable<IRoute['group']>[]
    return groups.sort()
  }

  getLinksByUserInterface (current: Interface | undefined, disabled = false): IRoute[] {
    if (current === undefined) {
      return []
    }

    return this._getRoutesByInterface(current)
      .filter(({ title }) => title !== undefined)
      .map((item) => ({ ...item, disabled: item.disabled || (disabled && item.group !== 'bottom') }))
  }

  getBreadcrumbs (link: string): NonNullable<IRoute['breadcrumbs']> {
    const route = this.get(link)
    return route?.breadcrumbs ?? []
  }

  private _getRoutesByInterface (current: Interface) {
    return this.routes.filter(item => {
      if (item.interfaces === undefined || item.interfaces.length === 0) {
        return true
      }

      return item.interfaces.includes(current)
    })
  }
}
