import { MenuItem as MUIMenuItem, MenuItemProps } from '@mui/material'
import clsx from 'clsx'
import { forwardRef } from 'react'
import './styles.sass'

const MenuItem = forwardRef<HTMLLIElement, MenuItemProps>(({ className, children, sx, ...props }: MenuItemProps, ref) => {
  return (
    <MUIMenuItem
      ref={ref}
      {...props}
      className={clsx('mainSelectItem', className)}
      sx={{
        borderBottom: '1px solid #F5F5F5',
        padding: '0 12px',
        whiteSpace: 'normal',
        ...sx
      }}
    >
    {children}
    </MUIMenuItem>
  )
})

export default MenuItem
