import './styles.sass'
import { Button, Stack, Typography } from '@mui/material'
import { Box } from '@mui/system'
import OrganizationData from './OrganizationData'
import PassportAndProfileData from './PassportAndProfileData'
import CopiesData from './CopiesData'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Passport, PassportForCreate } from 'api/passport'
import { Profile, ProfileForCreate, profileCreate } from 'api/profile'
import { Organization, TypeLegalPerson } from 'api/organization'
import { useAuthContext } from 'AuthContext'
import { Information, informationGet } from 'api/information'
import formatPhone from 'util/formatPhone'
import { CBRBank } from 'api/cbr'
import onlyFields from 'util/onlyFields'
import { LocalStorage } from 'util/LocalStorage'
import { asObject } from 'util/asObject'
import { FocusFindBranch } from 'api/focus'
import { ParticipantsUpdate, cleanParticipants, validateParticipants } from 'ui/OrganisationParticipants'

export enum Steps {
  editOrganization = 'edit_organization',
  editPassport = 'edit_passport',
  editCopies = 'edit_copies',
  save = 'save'
}

export enum ChangeStepStatus {
  inactive = 'inactive',
  next = 'next',
  prev = 'prev'
}

export interface ComponentParamsInterface {
  currentStep: Steps
  setCurrentStep: React.Dispatch<React.SetStateAction<Steps>>
  changeStep: ChangeStepStatus
  setChangeStep: React.Dispatch<React.SetStateAction<ChangeStepStatus>>
  nextStep: Steps
  prevStep?: Steps
}

const isCreateProfile = (x: unknown): x is ProfileForCreate => {
  const { firstName } = asObject<keyof ProfileForCreate>(x)

  return typeof firstName == 'string'
}

export default function PostRegistration () {
  const [currentStep, setCurrentStep] = useState<Steps>(Steps.editPassport)
  const [changeStep, setChangeStep] = useState<ChangeStepStatus>(ChangeStepStatus.inactive)
  const params = { currentStep, setCurrentStep, changeStep, setChangeStep }

  const [passportData, setPassportData] = useState<Partial<Passport>>({})
  const [profileData, setProfileData] = useState<Partial<Profile>>({})
  const [organizationData, setOrganizationData] = useState<Partial<Organization>>({ typeLegalPerson: TypeLegalPerson.st })

  const { handleResponseFailure, successRegistration, phone } = useAuthContext()

  const [info, setInfo] = useState<Information>({})
  const [checkInn, setCheckInn] = useState(true)
  const [checkKpp, setCheckKpp] = useState(false)
  const [blockedKpp, setBlockedKpp] = useState(true)
  const [checkBik, setCheckBik] = useState(true)
  const [checkAccount, setCheckAccount] = useState(true)
  const [orgBlockedField, setOrgBlockedField] = useState(true)
  const [bankData, setBankData] = useState<CBRBank>()
  const [doubleCarrier, setDoubleCarrier] = useState(false)

  const [branches, setBranches] = useState<FocusFindBranch[]>()
  const [selectBranch, setSelectBranch] = useState(true)
  const [participants, setParticipants] = useState<ParticipantsUpdate[]>()

  const localStorage = useRef<LocalStorage<ProfileForCreate & { doubleCarrier?: boolean, participants?: ParticipantsUpdate[] }>>()

  const init = useCallback(async () => {
    setInfo(await informationGet())
  }, [])

  useEffect(() => { init() }, [init])
  useEffect(() => {
    if (phone) {
      localStorage.current = new LocalStorage(`carrier_registration_${phone}`, isCreateProfile)
      const localProfile = localStorage.current.get()

      if (localProfile !== null) {
        const { passport, organization, doubleCarrier: dc = false, participants: prts, ...profile } = localProfile
        setProfileData(profile)
        setOrganizationData(organization ?? {})
        setPassportData(passport ?? {})
        setDoubleCarrier(dc)
        setParticipants(prts)

        if (!dc && organization && organization.inn) {
          setOrgBlockedField(false)
        }

        if (organization && organization.kpp) {
          setCheckKpp(true)
          setBlockedKpp(false)
        }
      }
    }
  }, [phone])

  useEffect(() => {
    if (localStorage.current === undefined) {
      return
    }

    localStorage.current.set({
      ...onlyFields(profileData, 'email', 'firstName', 'familyName', 'secondName', 'hasHiredDrivers'),
      passport: passportData as PassportForCreate,
      organization: organizationData as Organization,
      doubleCarrier,
      participants
    })
  }, [profileData, passportData, organizationData, doubleCarrier, participants])

  const save = useCallback(() => {
    return profileCreate({
      ...onlyFields(profileData, 'email', 'firstName', 'familyName', 'secondName', 'hasHiredDrivers'),
      passport: passportData as PassportForCreate,
      organization: {
        ...organizationData as Organization,
        participants: participants?.length && validateParticipants(participants, setParticipants) ? cleanParticipants(participants) : undefined
      }
    })
  }, [profileData, passportData, organizationData, participants])

  const saveProcess = useRef(false)
  useEffect(() => {
    if (!saveProcess.current && currentStep === Steps.save) {
      saveProcess.current = true
      setCurrentStep(Steps.editCopies)
      save().then(result => {
        const { success, conflicts } = result

        if (!success && conflicts?.organizationExists) {
          handleResponseFailure('Организация с указанным ИНН уже существует')
          saveProcess.current = false
          return
        }

        if (!success) {
          handleResponseFailure('Данные не были сохранены')
          saveProcess.current = false
          return
        }

        localStorage.current?.clear()
        successRegistration()
      })
    }
  }, [currentStep, profileData, passportData, organizationData, save, handleResponseFailure, successRegistration])

  return (
    <>
      <Box display='flex' flexDirection='column' justifyContent='space-between' flexGrow={3}>
        <Box mb={2} flexGrow={3}>
          { currentStep === Steps.editPassport && <PassportAndProfileData
            params={{ ...params, nextStep: Steps.editOrganization }}
            passportData={passportData}
            setPassportData={setPassportData}
            profileData={profileData}
            setProfileData={setProfileData}
          /> }
          { currentStep === Steps.editOrganization && <OrganizationData
            params={{ ...params, nextStep: Steps.editCopies, prevStep: Steps.editPassport }}
            data={organizationData}
            checkInn={checkInn}
            checkBik={checkBik}
            checkAccount={checkAccount}
            blockedField={orgBlockedField}
            bankData={bankData}
            setData={setOrganizationData}
            setCheckInn={setCheckInn}
            setCheckBik={setCheckBik}
            setBlockedField={setOrgBlockedField}
            setCheckAccount={setCheckAccount}
            setBankData={setBankData}
            profile={profileData}
            setProfile={setProfileData}
            checkKpp={checkKpp}
            setCheckKpp={setCheckKpp}
            doubleCarrier={doubleCarrier}
            setDoubleCarrier={setDoubleCarrier}
            blockedKpp={blockedKpp}
            setBlockedKpp={setBlockedKpp}
            branches={branches}
            setBranches={setBranches}
            selectBranch={selectBranch}
            setSelectBranch={setSelectBranch}
            participants={participants}
            setParticipants={setParticipants}
          /> }
          { [Steps.editCopies, Steps.save].includes(currentStep) && <CopiesData
            params={{ ...params, nextStep: Steps.save, prevStep: Steps.editOrganization }}
            dataOrg={organizationData}
            setDataOrg={setOrganizationData}
            dataPassp={passportData}
            setDataPassp={setPassportData}
            info={info}
            profile={profileData}
            setProfile={setProfileData}
          /> }
        </Box>
        <Stack direction='row' spacing={2} justifyContent='flex-end' mb='10px'>
        { currentStep !== Steps.editPassport && <Button size='large' variant='outlined' style={{ textTransform: 'none' }} onClick={() => { setChangeStep(ChangeStepStatus.prev) }}>Назад</Button> }
          <Button size='large' variant='contained' style={{ textTransform: 'none' }} onClick={() => { setChangeStep(ChangeStepStatus.next) }}>Далее</Button>
        </Stack>
        <Stack direction='row' justifyContent='center' mb='40px'>
          <Stack direction='column'>
            { info.dispatcherPhone && <Typography color='#919191' fontWeight={500}>Номер телефона для связи с Диспетчером: {formatPhone(info.dispatcherPhone)}</Typography> }
            { info.employeePhone && <Typography color='#919191' fontWeight={500}>Добавочный для связи с сотрудником АО Астон: {formatPhone(info.employeePhone)}</Typography> }
          </Stack>
        </Stack>
      </Box>
    </>
  )
}
