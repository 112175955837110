import { makeJsonSchema, SimpleSchema } from 'validation/validate'

export interface PreRegistration {
  password: string
  password2: string
}

const simpleSchema: SimpleSchema<PreRegistration> = {
  type: 'object',
  properties: {
    password: { type: 'string', pattern: '\\w+', errorMessage: 'Введите пароль' },
    password2: { type: 'string', pattern: '\\w+', errorMessage: 'Введите пароль' }
  }
}

const schema = makeJsonSchema<PreRegistration>(simpleSchema)

export default schema
