import { mainService } from 'api/service'
import { RequireOnly } from 'util/type/RequireOnly'
import { Id } from 'api/Id'
import { Bid } from './bid'
import { Address } from './address'
import { Pagination } from './pagination'
import { TypeLegalPerson } from './organization'

export enum ConsignorConsigneeStatus {
  active = 'active',
  archive = 'archive',
}

export type ConsignorConsigneeIncomplete = Partial<{
  innEmpty: boolean
  kppEmpty: boolean
  okpoEmpty: boolean
  fullNameEmpty: boolean
  externalTypeEmpty: boolean
  legalAddressEmpty: boolean
  phonesEmpty: boolean
  checkingAccountNumberEmpty: boolean
  bankNameEmpty: boolean
  bikEmpty: boolean
  correspondentAccountNumberEmpty: boolean
}>

export interface KisOrganization {
    NameFull: string
    NameShort: string
    TypeName: string
    INN: string
    KPP: string
    OGRN: string
    OKPO: string
    Phones: string
    Emails: string
    Address: string
    Name: string
}

export interface ConsignorConsigneeUpdateHistory {
  updateTs: number
  externalId: string
  data: Partial<KisOrganization>
}

export interface ConsignorConsignee {
  id: Id
  typeLegalPerson: TypeLegalPerson
  status: ConsignorConsigneeStatus
  phones: string[]
  emails: string[]
  fullName: string
  shortName: string
  inn: string
  okpo: string
  ogrn: string
  correspondentAccountNumber: string
  checkingAccountNumber: string
  bik: string
  bankName: string
  legalAddress: string

  kpp?: string
  archiveDate?: number
  archiveComment?: string
  addresses?: Omit<Address, 'consignee'>[]
  externalName?: string
  externalType?: string
  externalId?: string
  externalPhones?: string
  externalEmails?: string
  incomplete?: ConsignorConsigneeIncomplete
  updateHistory?: ConsignorConsigneeUpdateHistory[]
}

export interface ConsignorConsigneeListParams {
  filters?: {
    status?: ConsignorConsigneeStatus
  }
  limit?: number
  offset?: number
}

export interface ConsignorConsigneeSearchParams {
  query: string
}

export interface ConsignorConsigneeSearchResult {
  id: Id
  fullName: string
}

export interface ConsignorConsigneeArchiveParams {
  id: Id
  date: number
  comment?: string
}

type ModifyOmited = 'status' | 'addresses' | 'archiveDate' | 'archiveComment' |
  'externalName' | 'externalType' | 'externalPhones' | 'externalEmails' | 'incomplete' | 'updateHistory'
export type ConsignorConsigneeForCreate = Omit<ConsignorConsignee, 'id' | ModifyOmited>
export type ConsignorConsigneeForUpdate = RequireOnly<Omit<ConsignorConsignee, ModifyOmited>, 'id'>

const request = (method: string, params: object) => mainService.request(`consignor-consignee.${method}`, params)

export const consignorConsigneeArchive = (params: ConsignorConsigneeArchiveParams) => request('archive', params) as Promise<{
  success: boolean
  conflicts?: {
    addresses: Address[]
    bids: Bid[]
  }
}>
export const consignorConsigneeCreate = (params: ConsignorConsigneeForCreate) => request('create', params) as Promise<Id | null>
export const consignorConsigneeUpdate = (params: ConsignorConsigneeForUpdate) => request('update', params) as Promise<boolean>
export const consignorConsigneeGet = (id: Id, params: { includeHistory?: boolean } = {}) => request('get', { ...params, id }) as Promise<ConsignorConsignee | null>
export const consignorConsigneeList = (params: ConsignorConsigneeListParams = {}) => request('list', params) as Promise<Pagination<ConsignorConsignee>>
export const consignorConsigneeDelete = (id: Id) => request('delete', { id }) as Promise<boolean>
export const consignorConsigneeSearch = (params: ConsignorConsigneeSearchParams) => request('search', params) as Promise<ConsignorConsigneeSearchResult[]>
