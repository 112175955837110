import { Box, Stack, Typography } from '@mui/material'
import { ActStatus } from 'api/act'
import { Item as Tab } from 'ui/NavigatePanel/TabsRow'
import Tooltip from 'ui/Tooltip'
import { isEnum } from 'util/isEnum'
import HelpIcon from '@mui/icons-material/Help'

export function actStatusTab ({ label, value }: Tab): Tab {
  if (isEnum(ActStatus)(value) && value === ActStatus.problem) {
    return {
      label: (<Stack direction='row' gap={1} alignItems='center'>
        <span>{label}</span>
        <Tooltip title={<Box>
          <Typography>
          Здесь размещаются пакеты документов, в которых информация о переадресации появилась после создания закрывающих документов, а также если по рейсам были сделаны изменения в Диадок в обход системы.
          </Typography>
        </Box>} view><HelpIcon /></Tooltip>
      </Stack>),
      value
    }
  }

  return { label, value }
}
