import { Box, Button, Stack, Typography } from '@mui/material'
import { useAuthContext } from 'AuthContext'
import { UserStatus } from 'api/UserStatus'
import { ProfileAll, profileAllUpdate } from 'api/profile'
import InfoCard from 'ui/InfoCard'
import { personName } from 'util/personName'

export interface Params {
  profile: ProfileAll
  onRejection: () => void
  onConfirm: () => void
  disabled?: boolean
}

export default function Verification ({ profile: { id, verificationEmployee, status }, onRejection, onConfirm, disabled }: Params) {
  const { handleResponseSuccess } = useAuthContext()

  const handleVerified = () => {
    profileAllUpdate({ id, verified: true }).then(success => {
      if (success) {
        onConfirm()
        handleResponseSuccess('Регистрация подтверждена')
      }
    })
  }

  return (<>
    { !disabled && <InfoCard>
      <>
      { verificationEmployee === undefined
        ? <Stack direction='row' justifyContent='space-between'>
          <Box>
            <Typography sx={{
              fontWeight: '600',
              fontSize: '18px',
              lineHeight: '22px',
              letterSpacing: '-0.02em',
              mb: 1
            }}>Подтверждение регистрации</Typography>
            <Typography sx={{
              fontWeight: '400',
              fontSize: '14px',
              lineHeight: '24px',
              letterSpacing: '-0.02em'
            }}>
              Перевозчик прошел регистрацию и в данный момент ожидает подтверждения.<br />
              Проверьте указанную информацию, загруженные фотокопии документов и соответствие данных между ними.
            </Typography>
          </Box>
          { status === UserStatus.registered && <Stack direction='row' flexWrap='wrap' alignContent='center' gap={2}>
            <Button size='small' variant='contained' color='error' onClick={onRejection}>Отказать</Button>
            <Button size='small' variant='contained' onClick={handleVerified}>Подтвердить</Button>
          </Stack>}
        </Stack>
        : <Stack direction='column'>
            <Typography sx={{
              fontWeight: '600',
              fontSize: '18px',
              lineHeight: '22px',
              letterSpacing: '-0.02em',
              mb: 1
            }}>Регистрация подтверждена</Typography>
            <Typography sx={{
              fontWeight: '400',
              fontSize: '14px',
              lineHeight: '24px',
              letterSpacing: '-0.02em'
            }}>
              Сотрудник: { personName(verificationEmployee) }
            </Typography>
        </Stack> }
      </>
    </InfoCard> }
  </>)
}
