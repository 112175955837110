import { notifyService } from 'api/service'
import { Id } from './Id'

export enum NotificationsType {
  load = 'load', // Экспедиторы события для погрузок
  attach = 'attach', // Экспедиторы события связзвные с закрепление экспедитора
  expiresDoc = 'expires_doc', // Событие истечения срока действия документов
  bid = 'bid', // Уведомления о новых откликах перевозчиков, а также изменении статусов текущих
  shipping = 'shipping', // Уведомления об автоматическом создании новых перевозок и рейсов, изменении статусов текущих, а также запросах на завершение от перевозчиков
  documents = 'documents', // События связанные с документами Акты, Счета
  registry = 'registry' // Уведомления о добавлениях перевозчиками новых водителей, транспортных средств и прицепов. Уведомления об истечении документов перевозчиков и водителей
}

export enum DriverType {
  email = 'email',
  sms = 'sms',
  push = 'push',
  system = 'system',
}

export interface NotificationSettings {
  notificationsType: NotificationsType
  notificationMethods: {
    driver: DriverType
    value: boolean
  }[]
}

export interface NotificationEvent {
  id: Id
  slug: string
  driver: DriverType
  data: unknown
  lang: string
  createTs: number
  title?: string
  body?: string
}

export interface NotificationListParams {
  filters?: {
    types?: DriverType[],
    today?: boolean,
  },
  sort?: {
    createTs: 'asc' | 'desc'
  },
  limit?: number
}

const request = (method: string, params: object) => notifyService.request(method, params)

export const userSettingsGet = () => request('user-settings.get', {}) as Promise<NotificationSettings[]>
export const userSettingsPut = (settings: NotificationSettings[]) => request('user-settings.put', { settings }) as Promise<boolean>
export const pushSetClientToken = (token: string) => request('push.set-client-token', { token }) as Promise<{
  success: boolean
  cooldown_mts: number
}>
export const pushTest = (message: string) => request('push.test', { message }) as Promise<boolean>
export const notificationList = (params: NotificationListParams = {}) => request('notification.list', params) as Promise<NotificationEvent[]>
