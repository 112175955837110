export const TRUCK_RUN_DRIVER_XLSX_COLUMNS = {
  date: 'Дата',
  vehicle: 'ТС',
  trailer: 'Прицеп (с указанием региона)',
  driver: 'Водитель ФИО полностью',
  phone: 'Телефон водителя',
  driverPassportData: 'Паспортные данные водителя',
  carrier: 'Наименование перевозчика',
  carrierDetails: 'Реквизиты перевозчика (наименование, юр. адрес, телефон)'
}
