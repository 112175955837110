import { Meta } from './Meta'
import metaAddress from 'entity/Address'
import metaConsignorConsignee from 'entity/ConsignorConsignee'
import metaCustomer from 'entity/Customer'
import { metaOrganization } from 'entity/Organization'
import metaPassport from 'entity/Passport'

export interface MetaParams {
  title: string,
  meta?: Meta<Record<string, unknown>>
  format?: (value: unknown) => string
}

const meta: Record<string, MetaParams> = {
  'main.acts': { title: 'УПД' },
  'main.addresses': { title: 'Элеватор', meta: metaAddress },
  'main.bids': { title: 'Заявка' },
  'main.consignees': { title: 'Организации', meta: metaConsignorConsignee },
  'main.customers': { title: 'Заказчик', meta: metaCustomer },
  'main.drivers': { title: 'Водитель' },
  'main.forwarder_attaches': { title: 'Закрепление экспедитора' },
  'main.invoices': { title: 'Счет' },
  'main.organizations': { title: 'Организация', meta: metaOrganization() },
  'main.passports': { title: 'Паспорт', meta: metaPassport },
  'main.profiles': { title: 'Профиль' },
  'main.routes': { title: 'Маршрут' },
  'main.trailers': { title: 'Прицеп' },
  'main.vehicles': { title: 'ТС' }
} as const

export default meta
