import { MediaZipCreateParams } from 'api/media'
import metaPassport from 'entity/Passport'

export type Result = MediaZipCreateParams[] | undefined

export default function driverPack <In extends {
  scanPassport1?: string,
  scanPassport2?: string,
  scanPermit?: string }
> (data?: In): Result {
  if (!data) {
    return
  }

  const { scanPassport1, scanPassport2, scanPermit } = data

  const pack = []

  scanPermit && pack.push({ filename: scanPermit, toName: 'Водительское удостоверение' })
  scanPassport1 && pack.push({ filename: scanPassport1, toName: metaPassport.title('scan1') })
  scanPassport2 && pack.push({ filename: scanPassport2, toName: metaPassport.title('scan2') })

  return pack
}
