import { authService } from 'api/service'
import { Interface } from 'api/Interface'
import { UserStatus } from 'api/UserStatus'
import { SessionStatus } from 'api/SessionStatus'

export interface SessionForCreate {
  phone: string
  password?: string
  register: boolean
  recovery?: boolean
}

export interface SessionCreateResult {
  success: boolean
  session: SessionStatus | null
  user: UserStatus | null
}

export interface SessionSendCodeResult {
  success: boolean
  cooldown_mts: number
  session: SessionStatus | null
}

export interface SessionActivateResult {
  success: boolean
  attempts_left: number
  interfaces: Interface[]
  session: SessionStatus | null
  user: UserStatus | null
}

export interface SessionListItem {
  id: string
  sub: string,
  status: SessionStatus,
  sms_code: string
}

const request = (method: string, params: object, auth = false) => authService.request(`session.${method}`, params, auth)

export const sessionCreate = (params: SessionForCreate) => request('create', params) as Promise<SessionCreateResult>
export const sessionSendCode = () => request('send-code', {}) as Promise<SessionSendCodeResult>
export const sessionActivate = (code: string) => request('activate', { code }) as Promise<SessionActivateResult>
export const sessionDelete = () => request('delete', {}) as Promise<boolean>
export const sessionAllList = () => request('all.list', {}, true) as Promise<SessionListItem[]>
export const sessionAllDelete = (id: string) => request('all.delete', { id }, true) as Promise<boolean>
