import './styles.sass'
import { ReactNode } from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from '@mui/material'
import { Close } from '@mui/icons-material'
import { Breakpoint, styled } from '@mui/material/styles'

export interface Props {
  title: string
  content: ReactNode
  actions: ReactNode
  open: boolean
  onClose: () => void
  style?: React.CSSProperties
  maxWidth?: false | Breakpoint
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    // padding: theme.spacing(2)
    paddingLeft: '2em',
    paddingRight: '2em'
  },
  '& .MuiDialogTitle-root': {
    // padding: theme.spacing(2)
    paddingLeft: '1.5em',
    paddingRight: '1.5em',
    paddingTop: '2em',
    paddingBottom: '1em'
  },
  '& .MuiDialogActions-root': {
    paddingLeft: '2em',
    paddingRight: '2em',
    paddingBottom: '2em'
  }
}))

export default function Modal ({ title, content, actions, open, onClose, style, maxWidth }: Props) {
  return (
    <BootstrapDialog
      onClose={() => onClose()}
      aria-labelledby="customized-dialog-title"
      open={open}
      style={style}
      maxWidth={maxWidth}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        <Typography fontWeight={700} fontSize='24px' sx={{ mr: '1em' }}>{ title }</Typography>
        <IconButton
          aria-label="close"
          onClick={() => onClose()}
          sx={{
            position: 'absolute',
            right: '1em',
            top: '1.5em',
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>{ content }</DialogContent>
      <DialogActions>{ actions }</DialogActions>
    </BootstrapDialog>
  )
}
