import { Stack } from '@mui/material'
import { Box } from '@mui/system'
import { ReactNode } from 'react'
import './styles.sass'

export interface Params {
  icon: ReactNode
  actions?: ReactNode
  info: ReactNode
  onClick?: () => void
}

export default function Row ({ icon, actions, info, onClick }: Params) {
  return (<div onClick={onClick} className='dispatcherHome__row'><Stack
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      spacing={2}
      sx={{
        padding: '13px 16px',
        background: '#F7F7F7',
        borderRadius: '4px',
        cursor: onClick ? 'pointer' : undefined,
        '&:hover': {
          background: '#F1F1F1'
        }
      }}
    >
    <Box width='20px' height='20px'>{icon}</Box>
    <Stack direction='row' spacing={2} flexGrow={3}>{info}</Stack>
    <Box>{actions}</Box>
  </Stack></div>)
}
