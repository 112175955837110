import './styles.sass'
import { Box, Typography } from '@mui/material'
import { ReactNode } from 'react'

const sx = {
  red: {
    background: '#EE6A5F',
    color: '#fff'
  },
  gray: {
    background: '#EBEBEB',
    color: '#111'
  }
} as const

type Params = {
  color: keyof typeof sx
  title?: ReactNode
  children?: ReactNode
}

export default function Alert ({ color, title, children }: Params) {
  return <Box sx={{
    borderRadius: '8px',
    padding: '32px 24px',
    mb: 2,
    ...sx[color]
  }}>
    { title && <Typography sx={{
      fontWeight: '600',
      fontSize: '18px',
      lineHeight: '22px',
      letterSpacing: '-0.02em',
      mb: children ? 2 : 0
    }}>{ title }</Typography> }
    {children && <Typography sx={{
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '24px',
      letterSpacing: '-0.02em'
    }}>{children}</Typography>}
  </Box>
}
