import { Add, Archive, Edit, Visibility } from '@mui/icons-material'
import { Box, Button, Stack, Typography } from '@mui/material'
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMainRoutes } from 'routes'
import DataTable, { Row } from 'ui/DataTable'
import NavigatePanel from 'ui/NavigatePanel'
import { Route, routeArchive, RouteArchiveParams, routeList as apiRouteList, RouteStatus } from 'api/route'
import './styles.sass'
import ChipActivStatus from 'common/ChipActivStatus'
import Modal from 'ui/Modal'
import DatePicker from 'ui/DatePicker'
import TextField from 'ui/TextField'
import ActionsMenu from 'ui/ActionsMenu'
import { Id } from 'api/Id'
import addressName from 'util/addressName'
import { toKilometers } from 'util/distance'

export default function Routes () {
  const [searched, setSearched] = useState<(number|string)[]>()
  const [list, setList] = useState<Route[]>([])
  const [archiveData, setArchiveData] = useState<RouteArchiveParams | null>()
  const navigate = useNavigate()
  const { links, routesMap } = useMainRoutes()

  const breadcrumbsItems = useMemo(
    () => routesMap.getBreadcrumbs(links.REGISTRY_ROUTES_PAGE),
    [links, routesMap]
  )

  const rows: Row[] = useMemo(() => {
    return list.map(({ loadingAddress, unloadingAddress, distance, ...doc }) => ({
      ...doc,
      distance: toKilometers(distance),
      loadingAddress: addressName(loadingAddress),
      unloadingAddress: addressName(unloadingAddress)
    }))
  }, [list])

  const init = useCallback(() => apiRouteList().then(setList), [])

  useEffect(() => { init() }, [init])

  const openArchiveModal = (id?: Id) => {
    if (id === undefined) {
      return
    }

    setArchiveData({ id, date: Math.floor(Date.now() / 1000) })
  }

  const updateArchiveString = (key: keyof Pick<RouteArchiveParams, 'comment'>) => {
    return (e: ChangeEvent<HTMLElement & {value: string}>) => {
      if (!archiveData) {
        return
      }

      setArchiveData({ ...archiveData, [key]: e.target.value })
    }
  }

  const updateArchiveDate = (key: keyof RouteArchiveParams) => {
    return (value: number) => {
      if (!archiveData) {
        return
      }

      setArchiveData({ ...archiveData, [key]: value })
    }
  }

  const archive = useCallback(async () => {
    if (!archiveData) {
      return
    }

    await routeArchive(archiveData)
    setArchiveData(undefined)
    init()
  }, [archiveData, init])

  return (<>
    <div className='registryRoutes'>
      <NavigatePanel
        search={{
          rows: rows ?? [],
          fields: [
            'loadingAddress',
            'unloadingAddress',
            'distance',
            'comment'
          ],
          onChange: setSearched
        }}
        title='Маршруты'
        breadcrumbs={{
          items: breadcrumbsItems
        }}
        actions={
          <Button variant='contained' size='small' endIcon={<Add />} onClick={() => navigate(`${links.REGISTRY_ROUTES_PAGE}/add`)} sx={{ background: '#0365F4', textTransform: 'none', borderRadius: '4px' }}>
            Добавить маршрут
          </Button>
        }
      />
      <div className='registryRoutes__content'>
      <DataTable
          columns={[
            {
              id: 'loadingAddress',
              label: 'Пункт погрузки',
              format: value => <Typography fontWeight={700} fontSize='14px'>{`${value}`}</Typography>
            },
            {
              id: 'unloadingAddress',
              label: 'Пункт разгрузки',
              format: value => <Typography fontWeight={700} fontSize='14px'>{`${value}`}</Typography>
            },
            {
              id: 'distance',
              label: 'Расстояние',
              format: value => <>{value} км</>
            },
            {
              id: 'comment',
              label: 'Описание',
              format: value => <>{value || '–'}</>
            },
            {
              id: 'status',
              label: 'Статус',
              format: value => <ChipActivStatus status={value as RouteStatus} />
            },
            {
              id: ['id', 'status'],
              label: '',
              align: 'right',
              minWidth: 10,
              format: (id, status) => <ActionsMenu key={`${id}`} actions = {
                status !== RouteStatus.active
                  ? [
                      {
                        caption: 'Посмотреть',
                        onClick: () => navigate(`${id}`),
                        icon: <Visibility />
                      }
                    ]
                  : [
                      {
                        caption: 'Редактировать',
                        onClick: () => navigate(`${id}`),
                        icon: <Edit />
                      },
                      {
                        caption: 'Архивировать',
                        onClick: () => openArchiveModal(id as Id),
                        icon: <Archive />
                      }
                    ]
              } />
            }
          ]}
          rows = { (start, end) => rows.filter(row => searched === undefined ? true : searched.includes(row.id)).slice(start, end) }
          rowsCount = { searched === undefined ? rows.length : searched.length }
        />
      </div>
    </div>
    <Modal
      title='Архивировать маршрут'
      open={archiveData !== undefined}
      onClose={() => setArchiveData(undefined)}
      content={ archiveData
        ? <Stack sx={{ minWidth: '500px' }} >
            <Box sx={{ mb: 2 }}>
              <DatePicker
                label='Дата архивации'
                value={archiveData.date}
                onChange={updateArchiveDate('date')}
                disabled
              />
            </Box>
            <Box>
              <TextField
                rows={6}
                label='Комментарий'
                width='100%'
                value={archiveData.comment}
                onChange={updateArchiveString('comment')}
                placeholder='Добавьте комментарий'
              />
            </Box>
          </Stack>
        : <></>
      }
      actions={ archiveData
        ? <>
          <Button color='secondary' variant='outlined' onClick={() => setArchiveData(undefined)}>Отменить</Button>
          <Button variant='contained' onClick={ () => { archive() } }>Архивировать</Button>
        </>
        : <></>
      }
    />
  </>)
}
