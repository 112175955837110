import { Passport } from 'api/passport'
import TsToFormatDate from 'util/TsToFormatDate'
import { passportSeriesMaskClean, PASSPORT_ID_MASK, PASSPORT_SERIES_MASK } from 'common/Mask/Passport'
import { Meta } from 'entity/Meta'

const passport = new Meta<Passport>(
  {
    passportSeries: { title: 'Серия паспорта', mask: PASSPORT_SERIES_MASK, cleanMask: passportSeriesMaskClean },
    passportNum: { title: 'Номер паспорта', mask: PASSPORT_ID_MASK },
    issuedDepartment: { title: 'Выдан' },
    departmentCode: { title: 'Код подразделения', cleanMask: passportSeriesMaskClean },
    registrationAddress: { title: 'Адрес регистрации (прописка)' },
    dateIssue: { title: 'Дата выдачи', format: (value) => typeof value === 'number' ? TsToFormatDate(value, 'dd.MM.yyy') : `${value}` },
    dateOfBirth: { title: 'Дата рождения', format: (value) => typeof value === 'number' ? TsToFormatDate(value, 'dd.MM.yyyy') : `${value}` },
    status: { title: 'Статус' },
    scan1: { title: 'Паспорт (разворот с фото)' },
    scan2: { title: 'Паспорт (прописка)' }
  }
)

export default passport
