import { mainService } from 'api/service'

export type LogData = { [K in string]: string | number | boolean | null | LogData | LogData[] }

export interface Log {
  date: number
  user: string
  userPresentation: string
  action: string
  data: LogData
}

export interface LogFilter {
  dateFrom?: number
  dateTo?: number
}

const request = (method: string, params: object) => mainService.request(`log.${method}`, params)

export const logList = (filter: LogFilter = {}) => request('list', { filter }) as Promise<Log[]>
