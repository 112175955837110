import './styles.sass'
import { Alert, Box, Button, Stack, Typography } from '@mui/material'
import { addressList, Address, AddressStatus, AddressArchiveParams, addressArchive, AddressPointType } from 'api/address'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import DataTable from 'ui/DataTable'
import { Archive, CheckCircle, Edit, Visibility } from '@mui/icons-material'
import ActionsMenu from 'ui/ActionsMenu'
import { useNavigate, useSearchParams } from 'react-router-dom'
import NavigatePanel from 'ui/NavigatePanel'
import { useMainRoutes } from 'routes'
// import { useAuthContext } from 'AuthContext'
// import { Interface } from 'api/Interface'
import ChipActivStatus from 'common/ChipActivStatus'
import Modal from 'ui/Modal'
import DatePicker from 'ui/DatePicker'
import TextField from 'ui/TextField'
import { Id } from 'api/Id'
import { Bid } from 'api/bid'
import metaAddress from 'entity/Address'
import { isEnum } from 'util/isEnum'
import { now } from 'util/date'

const TABS: Map<AddressPointType, string> = new Map()
TABS.set(AddressPointType.grainElevator, 'Элеваторы')
TABS.set(AddressPointType.farming, 'Франко-хозяйства')

const DEFAULT_TAB = AddressPointType.grainElevator

export enum SEARCH_PARAMS {
  tab = 'tab'
}

export default function PointsAddress () {
  const [rows, setRows] = useState<Address[]>([])
  const [searched, setSearched] = useState<(number|string)[]>()
  const [archiveData, setArchiveData] = useState<AddressArchiveParams | null>()
  const [archiveError, setArchiveError] = useState<Bid[]>()
  const navigate = useNavigate()
  const { links, routesMap } = useMainRoutes()
  const [currentTab, setCurrentTab] = useState<AddressPointType>(DEFAULT_TAB)
  const [searchParams] = useSearchParams()

  // const { currentInterface } = useAuthContext()
  // const disabled = currentInterface ? [Interface.chief_forwarder, Interface.forwarder].includes(currentInterface) : false
  const path = links.REGISTRY_POINTS_ADDRESS_PAGE

  const init = useCallback(() => {
    addressList({ filters: { pointType: currentTab } }).then(setRows)
  }, [currentTab])

  useEffect(() => { init() }, [init])

  useEffect(() => {
    const tab = searchParams.get(SEARCH_PARAMS.tab)

    if (!isEnum(AddressPointType)(tab)) {
      return
    }

    if (tab === DEFAULT_TAB) {
      navigate('')
    }

    setCurrentTab(tab)
  }, [searchParams, navigate])

  const openArchiveModal = (id?: Id) => {
    if (id === undefined) {
      return
    }

    setArchiveData({ id, date: now() })
  }

  const updateArchiveString = (key: keyof Pick<AddressArchiveParams, 'comment'>) => {
    return (e: ChangeEvent<HTMLElement & {value: string}>) => {
      if (!archiveData) {
        return
      }

      setArchiveData({ ...archiveData, [key]: e.target.value })
    }
  }

  const updateArchiveDate = (key: keyof AddressArchiveParams) => {
    return (value: number) => {
      if (!archiveData) {
        return
      }

      setArchiveData({ ...archiveData, [key]: value })
    }
  }

  const archive = useCallback(async () => {
    if (!archiveData) {
      return
    }

    const { success, conflicts } = await addressArchive(archiveData)

    if (!success) {
      setArchiveError(conflicts?.bids)
      return
    }

    setArchiveData(undefined)
    init()
  }, [archiveData, init])

  const changeTab = (value: string) => {
    navigate(`?${SEARCH_PARAMS.tab}=${value}`)
  }

  return (<>
    <div className='pointsAddress'>
      <NavigatePanel
        search={{
          rows: rows ?? [],
          fields: [
            'name',
            'addressOrig'
          ],
          onChange: setSearched
        }}
        title={'Элеваторы и хозяйства'}
        breadcrumbs={{
          items: routesMap.getBreadcrumbs(path)
        }}
        // actions={ disabled
        //   ? <></>
        //   : <Button variant='contained' size='small' endIcon={<Add />} onClick={() => navigate(path + '/add')} sx={{ textTransform: 'none' }}>
        //     Добавить элеватор/хозяйство
        //   </Button>
        // }
        tabs={{
          items: [...TABS].map(([value, label]) => ({ value, label })),
          value: currentTab,
          onChange: changeTab
        }}
      />
      <div className='pointsAddress__content'>
        <div className='pointsAddress__content_table'>
          <DataTable
            columns={[
              {
                id: 'name',
                label: metaAddress.title('name'),
                minWidth: 100
              },
              {
                id: 'addressOrig',
                label: metaAddress.title('addressOrig'),
                minWidth: 100,
                format: value => <Typography fontWeight={700} fontSize='14px'>{`${value}`}</Typography>
              },
              {
                id: 'status',
                label: metaAddress.title('status'),
                format: value => <ChipActivStatus status={value as AddressStatus} />
              },
              {
                id: 'isPart',
                label: metaAddress.title('isPart'),
                align: 'center',
                format: value => value ? <CheckCircle sx={{ color: '#3EAE49' }} /> : <></>
              },
              {
                id: ['id', 'status'],
                label: '',
                align: 'right',
                minWidth: 10,
                format: (id, status) => <ActionsMenu key={`${id}`} actions = {
                  status !== AddressStatus.active
                    ? [
                        {
                          caption: 'Посмотреть',
                          onClick: () => navigate(`${id}`),
                          icon: <Visibility />
                        }
                      ]
                    : [
                        {
                          caption: 'Редактировать',
                          onClick: () => navigate(`${id}`),
                          icon: <Edit />
                        },
                        {
                          caption: 'Архивировать',
                          onClick: () => openArchiveModal(id as Id),
                          icon: <Archive />
                        }
                      ]
                } />
              }
            ]}
            rows = { (start, end) => rows === undefined ? [] : rows.filter(row => searched === undefined ? true : searched.includes(row.id)).slice(start, end) }
            rowsCount = { rows === undefined ? 0 : (searched === undefined ? rows.length : searched.length) }
          />
        </div>
      </div>
    </div>
    <Modal
      title='Архивировать маршрут'
      open={archiveData !== undefined}
      onClose={() => {
        setArchiveData(undefined)
        setArchiveError(undefined)
      }}
      content={ archiveError === undefined
        ? archiveData
          ? <Stack sx={{ minWidth: '500px' }} >
              <Box sx={{ mb: 2 }}>
                <DatePicker
                  label={metaAddress.title('archiveDate')}
                  value={archiveData.date}
                  onChange={updateArchiveDate('date')}
                  disabled
                />
              </Box>
              <Box>
                <TextField
                  rows={6}
                  label='Комментарий'
                  width='100%'
                  value={archiveData.comment}
                  onChange={updateArchiveString('comment')}
                  placeholder='Добавьте комментарий'
                />
              </Box>
            </Stack>
          : <></>
        : <Stack sx={{ minWidth: '500px' }} >
          <Alert severity='error'>
            <Typography>Нельзя заархивировать адрес!</Typography>
            <Typography>Адрес используется в перевозках: {archiveError.map(item => item.num).join(', ')}</Typography>
          </Alert>
        </Stack>
      }
      actions={ (archiveData && archiveError === undefined)
        ? <>
          <Button color='secondary' variant='outlined' onClick={() => setArchiveData(undefined)}>Отменить</Button>
          <Button variant='contained' onClick={ () => { archive() } }>Архивировать</Button>
        </>
        : <></>
      }
    />
  </>)
}
