import { ShippingTruckRunStatus } from 'api/shipping'

export default function getNameStatusTruckRun (status: ShippingTruckRunStatus): string {
  switch (status) {
    case ShippingTruckRunStatus.new:
      return 'На рассмотрении'
    case ShippingTruckRunStatus.confirmed:
      return 'Запланирован'
    case ShippingTruckRunStatus.way:
      return 'В пути'
    case ShippingTruckRunStatus.arrived:
      return 'Выгружен'
    case ShippingTruckRunStatus.completed:
      return 'Завершен'
    case ShippingTruckRunStatus.archiveReject:
      return 'Отклонен'
    case ShippingTruckRunStatus.archiveAct:
      return 'Архив'
    case ShippingTruckRunStatus.archiveRefuse:
      return 'Отозван'
    case ShippingTruckRunStatus.archiveOutdated:
      return 'Просрочен'
    case ShippingTruckRunStatus.archiveProblem:
      return 'Завершен диспетчером'
    default:
      return ''
  }
}
