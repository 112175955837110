import { Save } from '@mui/icons-material'
import { Box, Button, Stack, Typography } from '@mui/material'
import { Id, isId } from 'api/Id'
import { useMemo, useState, useEffect, useCallback, ChangeEvent } from 'react'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Address } from 'api/address'
import { RouteForUpdate, routeGet as apiRouteGet, routeCreate as apiRouteCreate, routeUpdate as apiRouteUpdate, RouteStatus } from 'api/route'
import { useMainRoutes } from 'routes'
import InfoCard from 'ui/InfoCard'
import NavigatePanel from 'ui/NavigatePanel'
import TextField from 'ui/TextField'
import './styles.sass'
import schema from 'validation/route'
import useValidate from 'validation/validate'
import { useAuthContext } from 'AuthContext'
import SelectAddress from 'ui/SelectAddress'
import addressName from 'util/addressName'
import InfoField from 'ui/InfoField'
import { toKilometers, toMeters } from 'util/distance'
import valueMethod from 'util/valueMethod'
import History from 'ui/History'

type UpdateParams = Omit<RouteForUpdate, 'id'>

export enum SEARCH_PARAMS {
  loadingAddressId = 'lo_ad',
  unloadingAddressId = 'unlo_ad',
  distance = 'distance'
}

export default function Route () {
  const navigate = useNavigate()
  const { links, routesMap } = useMainRoutes()
  const { routeId } = useParams()
  const [data, setData] = useState<UpdateParams>({})
  const { check, errors } = useValidate(schema)
  const { handleResponseFailure, handleResponseSuccess } = useAuthContext()
  const [title, setTitle] = useState<string>()
  const [edit, setEdit] = useState(true)
  const [loadingAddress, setLoadingAddress] = useState<Address>()
  const [unloadingAddress, setUnloadingAddress] = useState<Address>()

  // const { state } = useLocation()
  // Не работает если страница открывается в новой вкладке
  const { state } = useLocation()
  const [searchParams] = useSearchParams()

  const breadcrumbsItems = useMemo(
    () => routesMap.getBreadcrumbs(links.REGISTRY_ROUTES_PAGE),
    [links, routesMap]
  )

  const init = useCallback(async () => {
    const result = isId(routeId) ? await apiRouteGet(routeId) : null

    if (result !== null) {
      setData({
        loadingAddressId: result.loadingAddress.id,
        unloadingAddressId: result.unloadingAddress.id,
        distance: result.distance,
        comment: result.comment
      })
      setEdit(!(result.status === RouteStatus.archive))
      setTitle(`${addressName(result.loadingAddress)} - ${addressName(result.unloadingAddress)}`)
    } else if (state) {
      setData(state)
    }
  }, [routeId, state])

  useEffect(() => { init() }, [init])

  useEffect(() => {
    const loadingAddressId = searchParams.get(SEARCH_PARAMS.loadingAddressId)
    const unloadingAddressId = searchParams.get(SEARCH_PARAMS.unloadingAddressId)
    const distance = Number(searchParams.get(SEARCH_PARAMS.distance))

    if (!isId(loadingAddressId)) {
      return
    }

    if (!isId(unloadingAddressId)) {
      return
    }

    setData({ loadingAddressId, unloadingAddressId, distance })
    navigate('')
  }, [searchParams, navigate])

  const updateString = (key: keyof UpdateParams) => {
    return (event: ChangeEvent<HTMLInputElement>) => {
      const { target: { value } } = event
      setData({ ...data, [key]: value === '' ? undefined : value })
    }
  }

  const updateAddress = (key: keyof Pick<UpdateParams, 'loadingAddressId' | 'unloadingAddressId'>) => {
    return (id: Id) => {
      setData({ ...data, [key]: id })
    }
  }

  const updateDistance = () => {
    return (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value
      setData({ ...data, distance: value === '' ? undefined : toMeters(Number(value)) })
    }
  }

  const saveData = useCallback(async () => {
    if (!check(data)) {
      handleResponseFailure('Заполните обязательные поля')
      return
    }

    if (isId(routeId)) {
      const result = await apiRouteUpdate({ id: routeId, ...data })

      if (result) {
        handleResponseSuccess('Данные маршрута изменены')
      }
    } else {
      const id = await apiRouteCreate(data)

      if (id !== null) {
        handleResponseSuccess('Маршрут создана')
        navigate(-1)
      }
    }
  }, [routeId, data, handleResponseFailure, handleResponseSuccess, navigate, check])

  return (
    <div className='registryRoute'>
      <NavigatePanel
        title={title || 'Новый маршрут'}
        breadcrumbs={{
          items: [{ title: title || 'Добавить маршрут' }],
          defaultItems: breadcrumbsItems
        }}
        actions={ edit
          ? <Stack direction='row' spacing={2} justifyContent='end'>
            <Button variant='outlined' size='small' sx={{ textTransform: 'none', borderRadius: '4px' }} onClick={() => navigate(-1)} >
              Отменить
            </Button>
            <Button variant='contained' size='small' sx={{ background: '#6DCD45', textTransform: 'none', borderRadius: '4px' }} onClick={saveData} >
              Сохранить <Save sx={{ width: '15px', height: '15px', ml: '10px' }}/>
            </Button>
          </Stack>
          : <></>
        }
      />
      <div className='registryRoute__content'>
        <InfoCard title='Пункт погрузки' >
          <>
            <Stack direction='row' spacing={2}>
              <SelectAddress
                name='loadingAddressId'
                label='Название пункта'
                placeholder='Выберите пункт погрузки'
                value={data.loadingAddressId}
                onChange={updateAddress('loadingAddressId')}
                setCurrentAddress={setLoadingAddress}
                errors={errors}
                disabled={!edit}
                width='50%'
                itemName
              />
              <Box width='50%' />
            </Stack>
            <Stack direction='row' spacing={2}>
              <InfoField
                width='33%'
                label='Населенный пункт'
                placeholder='Выберите пункт погрузки'
                value={loadingAddress?.city}
              />
              <InfoField
                width='33%'
                label='Район'
                placeholder='Выберите пункт погрузки'
                value={loadingAddress?.district}
              />
              <InfoField
                width='33%'
                label='Область'
                placeholder='Выберите пункт погрузки'
                value={loadingAddress?.region}
              />
            </Stack>
            <Stack direction='row' spacing={2} >
              <InfoField
                width='33%'
                label='Улица'
                placeholder='Выберите пункт погрузки'
                value={loadingAddress?.street}
              />
              <InfoField
                width='33%'
                label='Дом'
                placeholder='Выберите пункт погрузки'
                value={loadingAddress?.building}
              />
              <InfoField
                width='33%'
                label='Комментарий'
                placeholder='Выберите пункт погрузки'
                value={loadingAddress?.comment}
              />
            </Stack>
          </>
        </InfoCard>
        <InfoCard title='Пункт разгрузки'>
          <>
            <Stack direction='row' spacing={2}>
              <SelectAddress
                name='unloadingAddressId'
                label='Название пункта'
                placeholder='Выберите пункт разгрузки'
                value={data.unloadingAddressId}
                onChange={updateAddress('unloadingAddressId')}
                setCurrentAddress={setUnloadingAddress}
                errors={errors}
                disabled={!edit}
                width='50%'
                itemName
              />
            </Stack>
            <Stack direction='row' spacing={2} >
              <InfoField
                width='33%'
                label='Населенный пункт'
                placeholder='Выберите пункт разгрузки'
                value={unloadingAddress?.city}
              />
              <InfoField
                width='33%'
                label='Район'
                placeholder='Выберите пункт разгрузки'
                value={unloadingAddress?.district}
              />
              <InfoField
                width='33%'
                label='Область'
                placeholder='Выберите пункт разгрузки'
                value={unloadingAddress?.region}
              />
            </Stack>
            <Stack direction='row' spacing={2} >
              <InfoField
                width='33%'
                label='Улица'
                placeholder='Выберите пункт разгрузки'
                value={unloadingAddress?.street}
              />
              <InfoField
                width='33%'
                label='Дом'
                placeholder='Выберите пункт разгрузки'
                value={unloadingAddress?.building}
              />
              <InfoField
                width='33%'
                label='Комментарий'
                placeholder='Выберите пункт разгрузки'
                value={unloadingAddress?.comment}
              />
            </Stack>
          </>
        </InfoCard>
        <InfoCard title='Общая информация'>
          <Stack direction='row' spacing={2}>
            <TextField
              label='Расстояние'
              endAdornment={<Typography sx={{ margin: '10px', color: '#B2B2B2' }}>км</Typography>}
              value={valueMethod(data.distance)(toKilometers)}
              onChange={updateDistance()}
              errorMessage={!!errors && data.distance === undefined ? 'Обязательное к заполнению' : undefined }
              disabled={!edit}
              width='25%'
              typeNumber
              precision={0}
            />
            <TextField
              name='comment'
              label='Описание'
              value={data.comment}
              onChange={updateString('comment')}
              errors={errors}
              disabled={!edit}
              width='75%'
            />
          </Stack>
        </InfoCard>
        { isId(routeId) && <InfoCard
          title='История изменений'
        >
          <History filter={{
            col: ['main.routes'],
            id: [routeId]
          }} onUndo={init}/>
        </InfoCard>
        }
      </div>
    </div>
  )
}
