import { Box, Typography, BoxProps } from '@mui/material'
import './styles.sass'

export default function Policy (props: BoxProps) {
  return (
    <Box {...props} sx={{ overflowX: 'auto', overflowY: 'hidden', background: '#ffffff' }}>
      <Box m='1.5em'>
        <Typography fontSize='21px' mb='1em'>Согласие на обработку персональных данных</Typography>
        <Typography mb='1em'>АО «АСТОН» (далее именуется «Контрагент») на обработку (включая получение от меня и/или от любых третьих лиц, с учетом требований действующего законодательства Российской Федерации) моих персональных данных и подтверждаю, что, давая такое согласие, я действую своей волей и в своем интересе.</Typography>
        <Typography mb='1em'>Согласие дается мною для целей заключения с Контрагентом любых договоров и их дальнейшего исполнения, а также в целях принятия решений или совершения иных действий, порождающих юридические последствия и распространяется в том числе, но не ограничиваясь, на следующую информацию: мои фамилия, имя, отчество, дата и место рождения, реквизиты документа, удостоверяющего личность, и содержащуюся в нем фотографию, адрес места жительства и места пребывания, номера контактных телефонов, адрес электронной почты, паспортные данные, идентификационный номер налогоплательщика,  номер и серия страхового свидетельства; банковские реквизиты, адрес регистрации по месту жительства и иная информация, относящаяся к моей личности, переданная мною Контрагенту (далее - «персональные данные»).</Typography>
        <Typography mb='1em'>Настоящее согласие предоставляется на осуществление любых действий по обработке персональных данных, которые необходимы для достижения указанных выше целей, как с использованием средств автоматизации, так и без таковых, включая без ограничения: сбор, систематизацию, накопление, хранение, уточнение (обновление, изменение), получение от третьих лиц, использование, распространение (в том числе передача), обезличивание, блокирование, уничтожение, трансграничную передачу персональных данных, а также осуществление любых иных действий с моими персональными данными с учетом действующего законодательства.</Typography>
        <Typography>Настоящее согласие предоставляется на осуществление любых действий в отношении моих персональных данных, которые необходимы или желаемы для достижения указанных выше целей. Настоящее согласие вступает в силу с момента подписания и может быть отозвано путем направления мною Контрагенту соответствующего письменного заявления в произвольной форме.</Typography>
      </Box>
    </Box>
  )
}
