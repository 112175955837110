import L from 'leaflet'

const OSRM_SERVICE = 'https://routing.openstreetmap.de/routed-car/route/v1'
const TIMEOUT = 6000000

let router: L.Routing.OSRMv1 | undefined
export default function useRouterOSM () {
  if (router === undefined) {
    router = L.Routing.osrmv1({ timeout: TIMEOUT, serviceUrl: OSRM_SERVICE })
  }

  return router
}
