import { KisRequestHistoryStatus, KisRequestHistoryType } from 'api/shipping'

export const kisRequestHistoryTypeMap: { [k in KisRequestHistoryType]: string } = {
  [KisRequestHistoryType.unloading]: 'Запрос данных по разгрузке в КИС'
}

export const kisRequestHistoryStatusMap: { [k in KisRequestHistoryStatus]: string } = {
  [KisRequestHistoryStatus.noAnswer]: 'Данные не были получены',
  [KisRequestHistoryStatus.success]: 'Данные получены'
}
