import { Box, Button, CircularProgress, Stack, Typography } from '@mui/material'
import { Id, isId } from 'api/Id'
import { shippingTruckRunDriverList, ShippingTruckRunDriverListParams } from 'api/shipping'
import { useAuthContext } from 'AuthContext'
import { useEffect, useState } from 'react'
import AntSwitch from 'ui/AntSwitch'
import DatePicker, { RangeDate } from 'ui/DatePicker'
import Modal from 'ui/Modal'
import SelectAddress from 'ui/SelectAddress'
import runTruckDriversConvertToXLSXFormat from 'util/runTruckDriversConvertToXLSXFormat'
import generateXLSXFile from 'util/generateXLSXFile'

export interface Params {
  open?: boolean
  onClose: () => void
  curPointAddressId?: Id
}

export default function ForwarderDriverListModel ({ open, onClose, curPointAddressId }: Params) {
  const { handleResponseFailure } = useAuthContext()
  const [progressDriverList, setProgressDriverList] = useState<boolean>(false)
  const [registerDateRange, setRegisterDateRange] = useState<RangeDate>()
  const [dateRangeError, setDateRangeError] = useState<string>()
  const [pointAddressId, setPointAddressId] = useState<Id>()
  const [registerByAllPoints, setRegisterByAllPoints] = useState<boolean>(false)

  useEffect(() => {
    if (!isId(curPointAddressId)) {
      return
    }

    setPointAddressId(curPointAddressId)
  }, [curPointAddressId])

  const handleDriverListDownload = async () => {
    setDateRangeError(registerDateRange === undefined ? 'Поле обязательно к заполнению' : undefined)
    if ((registerByAllPoints || (!registerByAllPoints && isId(pointAddressId))) && registerDateRange !== undefined) {
      setProgressDriverList(true)

      const params: ShippingTruckRunDriverListParams = {
        loadingTsFrom: registerDateRange.startTs,
        loadingTsTo: registerDateRange.endTs
      }

      if (!registerByAllPoints) {
        params.loadingAddressId = pointAddressId
      }

      const result = await shippingTruckRunDriverList(params)

      if (!result) {
        handleResponseFailure('Реестр не был сформирован')
        return
      }

      const convertedData = runTruckDriversConvertToXLSXFormat(result)
      await generateXLSXFile(convertedData, 'export_truck_runs_drivers')
      setProgressDriverList(false)
    }
  }

  const handleClose = () => {
    setProgressDriverList(false)
    setProgressDriverList(false)
    setRegisterDateRange(undefined)
    setPointAddressId(undefined)
    setRegisterByAllPoints(false)

    onClose()
  }

  return (<Modal
    maxWidth={false}
    open={!!open}
    onClose={handleClose}
    title={'Скачать реестр'}
    content={<Box minWidth='300px'>
      { progressDriverList
        ? <Box display='flex' alignContent='center' justifyContent='center' m='1em'>
          <CircularProgress />
        </Box>
        : <Stack>
          <Box pb={2}>
            <DatePicker
              label='Выберите диапазон дат'
              placeholder='Выберите даты погрузки'
              range={registerDateRange}
              onChange={(startTs, endTs) => {
                if (startTs !== undefined && endTs !== undefined) {
                  setRegisterDateRange(startTs < endTs ? { startTs, endTs } : { startTs: endTs, endTs: startTs })
                }
              }}
              rangeDate
              errorMessage={dateRangeError}
            />
          </Box>
          <SelectAddress
            showIsPartSwitch={false}
            disabled={registerByAllPoints}
            label='Пункт погрузки'
            placeholder='Выберите пункт загрузки'
            value={pointAddressId}
            onChange={setPointAddressId}
            itemName
          />
          <Stack direction="row" alignItems="flex-end" gap={1}>
            <AntSwitch checked={registerByAllPoints} onChange={(_event, checked) => setRegisterByAllPoints(checked)} />
            <Typography sx={{ whiteSpace: 'nowrap' }}>Все пункты погрузки</Typography>
          </Stack>
        </Stack>}
    </Box>}
    actions={<Stack direction='row' spacing={2}>
      <Button variant='outlined' onClick={handleClose} >Отменить</Button>
      <Button
        variant='contained'
        onClick={handleDriverListDownload}
        disabled={pointAddressId === undefined && (!registerByAllPoints || progressDriverList || registerDateRange === undefined)}
      >Скачать</Button>
    </Stack>}
  />)
}
