export enum Indicator {
  onlyOk = 'only_ok',
  onlyNeedsAttention = 'only_needs_attention',
  all = 'all',
}

export default function LoadingDayIndicator ({ indicator }: { indicator?: Indicator }) {
  if (indicator === undefined) {
    return <></>
  }

  if (indicator === Indicator.onlyOk) {
    return <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='6' cy='6' r='6' fill='#0A5DFF'/>
    </svg>
  }

  if (indicator === Indicator.onlyNeedsAttention) {
    return <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='6' cy='6' r='6' fill='#EE6A5F'/>
    </svg>
  }

  if (indicator === Indicator.all) {
    return <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='6' cy='6' r='6' fill='#0A5DFF'/>
    <path fillRule='evenodd' clipRule='evenodd' d='M1.75742 10.2426C4.10057 12.5857 7.89956 12.5857 10.2427 10.2426C12.5858 7.89942 12.5858 4.10043 10.2427 1.75729L1.75742 10.2426Z' fill='#EE6A5F'/>
    </svg>
  }

  return <>{indicator}</>
}
