import './styles.sass'
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Alert, Box, Button, Checkbox, Stack, Typography } from '@mui/material'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import SaveIcon from '@mui/icons-material/Save'

import { useAuthContext } from 'AuthContext'
import { useMainRoutes } from 'routes'
import { metaOrganization, TaxationNames } from 'entity/Organization'
import metaPassport from 'entity/Passport'
import useValidate from 'validation/validate'
import schemaPassport, { schemaPassportUploadForCreateProfile } from 'validation/Passport'
import {
  schemaLtd,
  schemaSt,
  schemaUploadForCreateLtdProfile,
  schemaUploadForCreateStProfile,
  clear as clearOrg
} from 'validation/Organization'
import schemaProfile from 'validation/Profile'

import { Id, isId } from 'api/Id'
import {
  ProfileAll,
  ProfileAllFilter,
  ProfileRequest,
  ProfileRequestSlugs,
  ProfileRequestStatus,
  profileAllGet,
  profileAllUpdate,
  profileRequestAccept as apiProfileRequestAccept,
  profileRequestCancel as apiProfileRequestCancel
} from 'api/profile'
import { CarrierKisStatus, KisRequestHistory, Organization, OrganizationAll, organizationAllList, organizationAllUpdate, TypeLegalPerson, TypeTaxation } from 'api/organization'
import { Passport, PassportAll, passportAllList, passportAllUpdate, PassportStatus } from 'api/passport'
import { UserRole, userUpdate } from 'api/user'
import { UserStatus } from 'api/UserStatus'
import { rejectionCreate } from 'api/rejection'

import TextField from 'ui/TextField'
import InfoCard from 'ui/InfoCard'
import RejectionModal from 'ui/RejectionModal'
import formatDate from 'util/FormatDate'

import NavigatePanel from 'ui/NavigatePanel'
import Upload from 'ui/Upload'
import useTabs from 'common/Registry/Carrier/useTabs'
import DatePicker from 'ui/DatePicker'
import useDisabled from 'common/Registry/Carrier/useDisabled'
import AntSwitch from 'ui/AntSwitch'
import ChipUserStatus from 'common/ChipUserStatus'
import dayjs from 'dayjs'
import { PHONE_MASK } from 'common/Mask/PhoneMask'
import Select from 'ui/Select'
import {
  formatToOkved,
  ORGANIZATION_INN_MASK,
  ORGANIZATION_INN_ST_MASK, ORGANIZATION_KPP_MASK,
  ORGANIZATION_OKVAD_MASK
} from 'common/Mask/Organization'
import History from 'ui/History'
import { EmailMask } from 'common/Mask/EmailMask'
import Focus from './Focus'
// import CrrierContracts from 'ui/CrrierContracts'
import download from 'util/download'
import { Information, informationGet } from 'api/information'
import Modal from 'ui/Modal'
import ProfileRequests from 'ui/ProfileRequests'
import Verification from './Verification'
import Identification from './Identification'
import onlyFields from 'util/onlyFields'
import Contracts from './Contracts'
import DownloadZipButton, { slug } from 'ui/DownloadZipButton'
import { okvedList } from 'api/okved'
import { enableNewOkved } from '../../../PostRegistration/OrganizationData'
import OrganisationParticipants, { cleanParticipants, ParticipantsCreate, participantsMap, ParticipantsUpdate, validateParticipants } from 'ui/OrganisationParticipants'
import { carrierKisStatusMap } from 'constants/carrierKisStatusMap'
import ContractsVerification from './ContractsVerification'
import CollapseList from 'ui/CollapseList'
import KisRequestHistoryWizard from 'ui/KisRequestHistoryWizard'
import { kisRequestHistoryTypeMap } from 'common/organizations/kisRequestHistoryMap'
import CollapseBox from 'ui/CollapseBox'

type RequestAddDrivers = Omit<ProfileRequest, 'data'> & {
  data: {
    scanInsuredPersons?: string
    scanReportEFS?: string
    manningTable?: string
  }
}

export default function Carrier () {
  const { id } = useParams()
  const { handleResponseFailure, handleResponseSuccess, currentInterface } = useAuthContext()

  const [profile, setProfile] = useState<ProfileAll>()
  const [organization, setOrganization] = useState<OrganizationAll>()
  const [passport, setPassport] = useState<PassportAll>()

  const [rejectionModalOpen, setRejectionModalOpen] = useState<boolean>(false)
  const [loadReportEFS, setLoadReportEFS] = useState(false)
  const [disabled, setDisabled] = useState<boolean>(useDisabled(currentInterface).disabled)
  const [scanReportEFSError, setScanReportEFSError] = useState<string>()
  const [accountingBalanceError, setAccountingBalanceError] = useState<string>()
  const [info, setInfo] = useState<Information>({})

  const [scanInformationLetterError, setScanInformationLetterError] = useState<string>()
  const [scanPatentError, setScanPatentError] = useState<string>()
  const [scanInsuredPersonsError, setScanInsuredPersonsError] = useState<string>()
  const [manningTableError, setManningTableError] = useState<string>()
  const [requestAddDrivers, setRequestAddDrivers] = useState<RequestAddDrivers>()
  const [profileRequestCancleId, setProfileRequestCancleId] = useState<Id>()
  const [profileRequestCancleComment, setProfileRequestCancleComment] = useState<string>()
  const [errorScanRequisites, setErrorScanRequisites] = useState<string>()
  const [scanLicenseError, setScanLicenseError] = useState<string>()
  const [okvedOptionList, setOkvedOptionList] = useState<{name: string, value: string, isActive: boolean}[]>([])
  const [participants, setParticipants] = useState<ParticipantsUpdate[]>()
  const [carrierKisStatus, setCarrierKisStatus] = useState<CarrierKisStatus>()
  const [kisRequestHistory, setKisRequestHistory] = useState<KisRequestHistory[]>([])

  const { tabs } = useTabs(profile?.id)

  const navigate = useNavigate()
  const { links, routesMap } = useMainRoutes()

  const profileValidate = useValidate(schemaProfile)
  const passportValidate = useValidate(schemaPassport)
  const passportUpValidate = useValidate(schemaPassportUploadForCreateProfile)
  const ltdValidate = useValidate(schemaLtd)
  const stValidate = useValidate(schemaSt)
  const ltdUpValidate = useValidate(schemaUploadForCreateLtdProfile)
  const stUpValidate = useValidate(schemaUploadForCreateStProfile)

  const isLtd = useMemo(() =>
    organization?.typeLegalPerson === TypeLegalPerson.organization
  , [organization?.typeLegalPerson])
  const isVat = useMemo(() => organization?.typeTaxation === TypeTaxation.vat, [organization?.typeTaxation])
  const metaOrg = useMemo(() =>
    metaOrganization(organization?.typeLegalPerson)
  , [organization?.typeLegalPerson])

  useEffect(() => {
    if (isLtd) {
      okvedList()
        .then((list) => {
          const result = list.map((item) => ({
            name: `${formatToOkved(item.code)} ${item.description}`,
            value: formatToOkved(item.code),
            isActive: item.isActive
          }))
          setOkvedOptionList(result)
        })
    }
  }, [isLtd])

  const init = useCallback(async (): Promise<void> => {
    const profileFilter: ProfileAllFilter = { role: UserRole.carrier }

    const profile = isId(id) ? await profileAllGet(id, profileFilter) : null

    if (profile === null) {
      handleResponseFailure('Перевозчик не найден')
      return
    }

    setDisabled((disabled) => disabled || [UserStatus.archive, UserStatus.new].includes(profile.status))

    informationGet().then(setInfo)
    setProfile(profile)
    setRequestAddDrivers(profile.requests?.find(item => item.slug === ProfileRequestSlugs.addDrivers && item.status === ProfileRequestStatus.new) as RequestAddDrivers)

    const organizations = await organizationAllList({ owner: [profile.phone] })

    if (organizations.length > 0) {
      const { emails, phones, participants: prts = [], carrierKisStatus: cks, kisQueuedId, kisRequestHistory, ...org } = organizations[0]
      prts.length > 0 && setParticipants(participantsMap(prts))
      setOrganization(org)
      setLoadReportEFS(org.scanReportEFS !== undefined)
      setCarrierKisStatus(cks)
      setKisRequestHistory(kisRequestHistory ?? [])
    }

    const passports = await passportAllList({ owner: [profile.phone] })

    if (passports.length > 0) {
      setPassport(passports[0])
    }
  }, [id, handleResponseFailure])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { init() }, [])

  const updateProfileString = (key: keyof ProfileAll) =>
    (e: ChangeEvent<HTMLInputElement>) =>
      setProfile((data) => {
        if (data !== undefined) {
          return { ...data, [key]: e.target.value }
        }
      })

  const updatePassportString = (key: keyof PassportAll) =>
    (e: ChangeEvent<HTMLInputElement>) =>
      setPassport((data) => {
        if (data !== undefined) {
          return { ...data, [key]: e.target.value }
        }
      })

  const updatePassportDate = (key: keyof PassportAll) =>
    (value: number) =>
      setPassport((data) => {
        if (data !== undefined) {
          return { ...data, [key]: value }
        }
      })

  const uploadPassport = (key: keyof PassportAll) =>
    (filename: string | undefined) =>
      setPassport((data) => {
        if (data !== undefined) {
          return { ...data, [key]: filename }
        }
      })

  const updateOrgString = (key: keyof OrganizationAll) =>
    (e: ChangeEvent<HTMLInputElement>) =>
      setOrganization((data) => {
        if (data !== undefined) {
          return { ...data, [key]: e.target.value }
        }
      })
  const updateOrgOkved = (key: keyof OrganizationAll) =>
    (value: string[]) =>
      setOrganization((data) => {
        if (data !== undefined) {
          return { ...data, [key]: value }
        }
      })
  const updateOrgCheckbox = (key: keyof OrganizationAll) =>
    (e: ChangeEvent<HTMLInputElement>, checked: boolean) =>
      setOrganization((data) => {
        if (data !== undefined) {
          return { ...data, [key]: checked }
        }
      })

  const uploadOrg = (key: keyof OrganizationAll) =>
    (filename: string | undefined) =>
      setOrganization((data) => {
        if (data !== undefined) {
          return { ...data, [key]: filename }
        }
      })

  const clearScans = (scans: Record<string, string | undefined>): Record<string, string> => {
    return Object.entries(scans).reduce(
      (result, [key, value]) => (value === undefined || value === '' ? result : { ...result, [key]: value }),
      {}
    )
  }

  const saveProfile = useCallback(() => {
    if (!profile) {
      return null
    }

    const { id, ...fields } = profile

    const { contracts, ...clearData } = onlyFields(fields, 'email', 'firstName', 'familyName', 'secondName', 'hasHiredDrivers')

    if (!profileValidate.check(clearData)) {
      return null
    }

    return () => profileAllUpdate({ id, ...clearData })
  }, [profile, profileValidate])

  const savePassport = useCallback(() => {
    if (!passport) {
      return null
    }

    const { id, status, owner, passportSeries, scan1, scan2, ...data } = passport
    const fields = {
      ...data,
      passportSeries: metaPassport.cleanMask('passportSeries', passportSeries)
    }
    const scans = clearScans({ scan1, scan2 })

    if (!passportValidate.check(fields) || !passportUpValidate.check(scans)) {
      return null
    }

    return () => passportAllUpdate({ id, ...fields, ...scans })
  }, [passport, passportValidate, passportUpValidate])

  const saveOrg = useCallback(() => {
    if (!organization) {
      return null
    }

    if (!profile) {
      return null
    }

    const {
      id,
      owner,
      scanTaxReturnOrFeeDeclaration,
      manningTable,
      calcPersonalIncomeTax,
      scanInn,
      scanRegistration,
      scanCode,
      scanCertificate,
      accountingBalance,
      scanInsuredPersons,
      scanReportEFS,
      scanConsentTaxpayer,
      scanReceiptConsentTaxpayer,
      scanRegistrationAddress,
      scanRequisites,
      scanBankCard,
      scanRegulations,
      scanAdministrationProtocol,
      scanQuestionnaire,
      scanInformationLetter,
      scanPatent,
      scanLicense,
      scanFinancialResults,
      scanPowerOfAttorney,
      ...data
    } = organization

    const fields = clearOrg(data)
    const scans = clearScans({
      scanTaxReturnOrFeeDeclaration,
      manningTable,
      // calcPersonalIncomeTax,
      scanInn,
      scanRegistration,
      scanCode,
      scanCertificate,
      accountingBalance,
      scanInsuredPersons,
      scanReportEFS,
      scanConsentTaxpayer,
      scanReceiptConsentTaxpayer,
      scanRegistrationAddress,
      scanRequisites,
      scanBankCard,
      scanRegulations,
      scanAdministrationProtocol,
      scanQuestionnaire,
      scanInformationLetter,
      scanPatent,
      scanLicense,
      scanFinancialResults,
      scanPowerOfAttorney
    })

    let valid = organization.typeLegalPerson === TypeLegalPerson.organization
      ? ltdValidate.check(fields) && ltdUpValidate.check(scans)
      : stValidate.check(fields) && stUpValidate.check(scans)

    if (!organization.scanRequisites) {
      setErrorScanRequisites('Поле обязательно к заполнению')
      valid = false
    } else {
      setErrorScanRequisites(undefined)
    }

    if (profile.hasHiredDrivers && loadReportEFS && !organization.scanReportEFS) {
      setScanReportEFSError('Поле обязательно к заполнению')
      valid = false
    } else {
      setScanReportEFSError(undefined)
    }

    if (organization.typeTaxation === TypeTaxation.patent && !organization.scanPatent) {
      setScanPatentError('Поле обязательно к заполнению')
      valid = false
    } else {
      setScanPatentError(undefined)
    }

    if (!organization.scanInformationLetter) {
      setScanInformationLetterError('Поле обязательно к заполнению')
      valid = false
    } else {
      setScanInformationLetterError(undefined)
    }

    if (profile.hasHiredDrivers && !organization.scanInsuredPersons) {
      setScanInsuredPersonsError('Поле обязательно к заполнению')
      valid = false
    } else {
      setScanInsuredPersonsError(undefined)
    }

    if (profile.hasHiredDrivers && !organization.manningTable) {
      setManningTableError('Поле обязательно к заполнению')
      valid = false
    } else {
      setManningTableError(undefined)
    }

    if (organization.hasLicense && !organization.scanLicense) {
      setScanLicenseError('Поле обязательно к заполнению')
      valid = false
    } else {
      setScanLicenseError(undefined)
    }

    if (organization.hasAccounting && !organization.accountingBalance) {
      setAccountingBalanceError('Поле обязательно к заполнению')
      valid = false
    } else {
      setAccountingBalanceError(undefined)
    }

    if (participants?.length && !validateParticipants(participants, setParticipants)) {
      valid = false
    }

    if (!valid) {
      console.log({
        ltdValidate: ltdValidate.errors,
        ltdUpValidate: ltdUpValidate.errors,
        stValidate: stValidate.errors,
        stUpValidate: stUpValidate.errors
      })

      return null
    }

    return () => organizationAllUpdate({
      id,
      ...fields,
      ...scans,
      participants: participants?.length ? cleanParticipants(participants as ParticipantsCreate[]) : undefined
    })
  }, [organization, ltdValidate, ltdUpValidate, stValidate, stUpValidate, loadReportEFS, profile, participants, setParticipants])

  const save = useCallback(async () => {
    if (disabled) {
      return
    }

    const promises = [
      saveProfile(),
      savePassport(),
      saveOrg()
    ]

    if (promises.find((x) => x === null) !== undefined) {
      return
    }

    const result = await Promise.all(promises.map((promise) => promise === null ? false : promise()))

    if (result.filter((x) => x).length > 0) {
      handleResponseSuccess('Данные изменены')
      navigate(-1)
    }
  }, [disabled, handleResponseSuccess, navigate, saveOrg, savePassport, saveProfile])

  const changeStatus = (status: UserStatus): void => {
    if (!disabled && profile) {
      userUpdate({ phone: profile.phone, status }).then(result => {
        if (result) {
          handleResponseSuccess('Статус Перевозчика изменен')
        }

        navigate(-1)
      })
    }
  }

  const handleRejection = (reasonSlug: string, comment?: string) => {
    if (profile) {
      rejectionCreate({ objectId: profile.phone, reasonSlug, comment })
        .then(result => {
          if (result) {
            changeStatus(UserStatus.rejected)
          }
        })
    }
  }

  const downloadJsonFile = () => {
    if (disabled) {
      return
    }

    const filename = `export_carrier_${formatDate(new Date(), 'ddMMyyyy')}.json`
    const element = document.createElement('a')
    const data = { organization, passport }
    const file = new Blob([JSON.stringify(data)], { type: 'text/json' })
    element.href = URL.createObjectURL(file)
    element.download = filename
    element.click()
  }

  const updateHasHiredDrivers = ({ target: { checked } }: React.ChangeEvent<HTMLInputElement>) => {
    if (!profile) {
      return
    }

    if (!organization) {
      return
    }

    setProfile({ ...profile, hasHiredDrivers: checked })
    const cleaner = checked
      ? { scanInformationLetter: undefined }
      : {
          scanInsuredPersons: undefined,
          scanReportEFS: undefined,
          manningTable: undefined
        }

    setOrganization({ ...organization, ...cleaner })

    if (!checked) {
      setLoadReportEFS(false)
    }
  }

  const profileRequestAccept = (id: Id) => {
    apiProfileRequestAccept(id).then(({ success }) => {
      if (success) {
        init()
      }
    })
  }

  const profileRequestCancle = () => {
    if (profileRequestCancleId && profileRequestCancleComment) {
      apiProfileRequestCancel(profileRequestCancleId, profileRequestCancleComment)
        .then(({ success }) => {
          if (success) {
            init()
          }
        })
        .finally(profileRequestModalCancle)
    }
  }

  const profileRequestModalCancle = () => {
    setProfileRequestCancleId(undefined)
    setProfileRequestCancleComment(undefined)
  }

  const pack = useMemo(() => {
    if (!organization) {
      return
    }

    if (!passport) {
      return
    }

    const filesOrgs = onlyFields(organization,
      'scanInn', 'scanRegistration', 'scanCode',
      'scanCertificate', 'scanTaxReturnOrFeeDeclaration', 'scanRequisites',
      'scanConsentTaxpayer', 'scanReceiptConsentTaxpayer',
      'scanRegistrationAddress', 'scanQuestionnaire',
      'scanInformationLetter', 'scanPatent', 'scanInsuredPersons',
      'scanLicense', 'scanFinancialResults',
      'scanBankCard', 'scanRegulations', 'scanAdministrationProtocol',
      'scanReportEFS', 'manningTable', 'accountingBalance')

    const filesPassport = onlyFields(passport, 'scan1', 'scan2')

    const pack = [...Object.entries(filesOrgs)]
      .map(([key, filename]) => ({ filename, toName: metaOrg.title(key as keyof Organization) }))

    pack.push(...[...Object.entries(filesPassport)]
      .map(([key, filename]) => ({ filename, toName: metaPassport.title(key as keyof Passport), inFolder: 'Паспорт' })))

    return pack.filter(item => item.filename)
  }, [metaOrg, organization, passport])

  if (profile === undefined) {
    return <></>
  }

  return (
    <>
      <RejectionModal
        open={rejectionModalOpen}
        onCancel={() => setRejectionModalOpen(false)}
        onReject={handleRejection}
      />
      <div className='registryCarrier'>
        <NavigatePanel
          title={<Box sx={{ display: 'flex', gap: '1em', alignItems: 'center' }}>
            <Box>Перевозчик {organization?.shortName ?? profile.phone}</Box>
            <ChipUserStatus status={profile.status as UserStatus} truckRuns={profile.truckRuns} />
          </Box>}
          breadcrumbs={{
            items: [
              {
                title: organization?.shortName ?? profile.phone
              }
            ],
            defaultItems: routesMap.getBreadcrumbs(links.REGISTRY_CARRIERS_PAGE)
          }}
          actions={
            <Stack direction='row' spacing={2} justifyContent='end'>
              <DownloadZipButton pack={pack} archiveName={slug(`перевозчик_${organization?.shortName}`)} />
              <Button variant='outlined' color="secondary" size='small' endIcon={<FileUploadIcon />} onClick={() => downloadJsonFile()}>
                Экспортировать
              </Button>
              <Button variant='outlined' color='secondary' size='small' onClick={() => navigate(-1)}>
                { disabled ? 'Закрыть' : 'Отменить' }
              </Button>
              { disabled
                ? <></>
                : <Button variant='contained' color='success' size='small' onClick={save}>
                  Сохранить <SaveIcon sx={{ width: '15px', height: '15px', ml: '10px' }}/>
                </Button>
              }
            </Stack>
          }
          tabs={tabs}
        />
        <div className='registryCarrier__body'>
          <div className='registryCarrier__content'>
            { ![UserStatus.rejected, UserStatus.new].includes(profile.status) && <>
              <Verification
                profile={profile}
                onRejection={() => setRejectionModalOpen(true)}
                onConfirm={init}
                disabled={disabled}
              />
              <Identification
                profile={profile}
                onRejection={() => setRejectionModalOpen(true)}
                onConfirm={init}
              />
            </> }
            { !disabled && requestAddDrivers && <InfoCard>
              <Stack direction='row' justifyContent='space-between'>
                <Box>
                  <Typography sx={{
                    fontWeight: '600',
                    fontSize: '18px',
                    lineHeight: '22px',
                    letterSpacing: '-0.02em',
                    mb: 1
                  }}>Запрос на добавление водителей</Typography>
                  <Typography sx={{
                    fontWeight: '400',
                    fontSize: '14px',
                    lineHeight: '24px',
                    letterSpacing: '-0.02em'
                  }}>
                    Перевозчик отправил запрос, необходимо проверить приложенные документы в блоке "Бухгалтерские документы".
                  </Typography>
                </Box>
                <Stack direction='row' flexWrap='wrap' alignContent='center' gap={2}>
                  <Button size='small' variant='contained' color='error' onClick={() => setProfileRequestCancleId(requestAddDrivers.id)}>Отказать</Button>
                  <Button size='small' variant='contained' onClick={() => { profileRequestAccept(requestAddDrivers.id) }}>Подтвердить</Button>
                </Stack>
              </Stack>
            </InfoCard> }
            { carrierKisStatus && <InfoCard title="Перевозчик передан в КИС">
                <Stack direction='column' gap={1}>
                  <Typography><span style={{ fontWeight: 600 }}>Статус</span>: {carrierKisStatusMap[carrierKisStatus]}</Typography>
                  { kisRequestHistory.length > 0 && <CollapseBox title='История запросов' collapse>
                    <CollapseList
                      list={kisRequestHistory.sort((a, b) => b.requestTs - a.requestTs)}
                      previewItems={3}
                      element={data => <KisRequestHistoryWizard data={data} typeMap={kisRequestHistoryTypeMap} statusMap={carrierKisStatusMap} />}
                    />
                  </CollapseBox> }
                </Stack>
              </InfoCard>}
            <Focus organization={organization} />
            <ProfileRequests profile={profile} />
            { profile.status === UserStatus.rejected && <Box sx={{
              background: '#EE6A5F',
              borderRadius: '8px',
              padding: '32px 24px',
              color: '#fff',
              mb: 2
            }}>
              <Typography sx={{
                fontWeight: '600',
                fontSize: '18px',
                lineHeight: '22px',
                letterSpacing: '-0.02em'
              }}>Перевозчику отказано в регистрации</Typography>
            </Box>}
            { profile.status === UserStatus.blocked && <Box sx={{
              background: '#EE6A5F',
              borderRadius: '8px',
              padding: '32px 24px',
              color: '#fff',
              mb: 2
            }}>
              <Typography sx={{
                fontWeight: '600',
                fontSize: '18px',
                lineHeight: '22px',
                letterSpacing: '-0.02em'
              }}>Профиль перевозчика заблокирован</Typography>
            </Box>}
            { profile.status === UserStatus.new && <Box sx={{
              background: '#ebebeb',
              borderRadius: '8px',
              padding: '32px 24px',
              color: '#111',
              mb: 2
            }}>
              <Typography sx={{
                fontWeight: '600',
                fontSize: '18px',
                lineHeight: '22px',
                letterSpacing: '-0.02em'
              }}>Перевозчик в процессе регистрации</Typography>
            </Box>}
            { profile.status === UserStatus.archive && <Box sx={{
              mt: '10px',
              background: '#EBEBEB',
              borderRadius: '8px',
              padding: '32px 24px',
              color: '#111'
            }}>
              <Typography sx={{
                fontWeight: '600',
                fontSize: '18px',
                lineHeight: '22px',
                letterSpacing: '-0.02em'
              }}>
                Перевозчик был архивирован {profile.archiveTs ? formatDate(new Date(profile.archiveTs * 1000)) : ''}
              </Typography>
              {profile.archiveComment && <Typography sx={{ mt: 2, whiteSpace: 'pre-wrap' }}>
                {profile.archiveComment}
              </Typography>}
            </Box>}
            { !disabled && passport?.status === PassportStatus.expires && <Alert
                severity='warning'
                sx={{ mt: '10px', mb: '10px' }}
              >
                Истекает срок действия паспорта.
              </Alert>}
            { profile.status !== UserStatus.new && <ContractsVerification profile={profile} onSuccess={init} />}
            <InfoCard
              title='Данные профиля'
            >
              <>
                <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} >
                  <TextField
                    name='email'
                    label='Email'
                    value={profile.email}
                    onChange={updateProfileString('email')}
                    disabled={disabled}
                    errors={profileValidate.errors}
                    width='25%'
                    maskParams={{
                      mask: EmailMask,
                      guide: false
                    }}
                  />
                  <TextField
                    name='familyName'
                    label='Фамилия'
                    value={profile.familyName}
                    onChange={updateProfileString('familyName')}
                    disabled={disabled}
                    errors={profileValidate.errors}
                    width='16%'
                  />
                  <TextField
                    name='firstName'
                    label='Имя'
                    value={profile.firstName}
                    onChange={updateProfileString('firstName')}
                    disabled={disabled}
                    errors={profileValidate.errors}
                    width='16%'
                  />
                  <TextField
                    name='secondName'
                    label='Отчество'
                    value={profile.secondName}
                    onChange={updateProfileString('secondName')}
                    disabled={disabled}
                    errors={profileValidate.errors}
                    width='16%'
                  />
                  <TextField
                    label='Телефон'
                    value={profile.phone}
                    disabled={true}
                    maskParams={{
                      mask: PHONE_MASK
                    }}
                    width='25%'
                  />
                </Stack>
              </>
            </InfoCard>
            {/* <CrrierContracts profile={profile} /> */}
            { profile.status !== UserStatus.new && <Contracts profile={profile} onSave={init} />}
            { organization && <InfoCard
              title='Общая информация'
            >
              <>
                { !isLtd && <>
                  <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} sx={{ mb: 2 }}>
                    <TextField
                      name='inn'
                      label='ИНН'
                      value={organization.inn}
                      onChange={updateOrgString('inn')}
                      disabled={disabled}
                      errors={stValidate.errors}
                      placeholder={isLtd ? 'XXXXXXXXXX' : 'XXXXXXXXXXXX'}
                      maskParams={{
                        mask: isLtd ? ORGANIZATION_INN_MASK : ORGANIZATION_INN_ST_MASK
                      }}
                      width='25%'
                    />
                    <TextField
                      name='shortName'
                      label='Краткое наименование'
                      value={organization.shortName}
                      onChange={updateOrgString('shortName')}
                      disabled={disabled}
                      errors={stValidate.errors}
                      width='25%'
                    />
                    <TextField
                      name='fullName'
                      label='Полное наименование'
                      value={organization.fullName}
                      onChange={updateOrgString('fullName')}
                      disabled={disabled}
                      errors={stValidate.errors}
                      width='51%'
                    />
                  </Stack>
                  <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} sx={{ mb: 2 }}>
                    <TextField
                      name='ogrnip'
                      label='ОГРНИП'
                      value={organization.ogrnip}
                      onChange={updateOrgString('ogrnip')}
                      disabled={disabled}
                      errors={stValidate.errors}
                      placeholder='X-XX-XX-XXXXXXXXX-X'
                      maskParams={{
                        mask: metaOrg.mask('ogrnip')
                      }}
                      width='50%'
                    />
                    <TextField
                      name='okpo'
                      label='ОКПО'
                      value={organization.okpo}
                      onChange={updateOrgString('okpo')}
                      disabled={disabled}
                      errors={stValidate.errors}
                      placeholder='XXXXXXXX'
                      maskParams={{
                        mask: metaOrg.mask('okpo')
                      }}
                      width='50%'
                    />
                    <Select
                      name='typeTaxation'
                      label='Система налогооблажения'
                      options={Array.from(TaxationNames, ([value, name]) => ({ value, name }))}
                      value={organization.typeTaxation}
                      onChange={(typeTaxation) => setOrganization((data) => data ? { ...data, typeTaxation } : data)}
                      disableClearable={true}
                      disabled={disabled}
                      errors={stValidate.errors}
                      width='50%'
                    />
                  </Stack>
                  <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} >
                    <TextField
                      name='checkingAccountNumber'
                      label='Номер расчетного счета'
                      value={organization.checkingAccountNumber}
                      onChange={updateOrgString('checkingAccountNumber')}
                      disabled={disabled}
                      errors={stValidate.errors}
                      placeholder='XXX.XX.XXX.X.XXXX.XXXXXXX'
                      maskParams={{
                        mask: metaOrg.mask('checkingAccountNumber')
                      }}
                      width='25%'
                    />
                    <TextField
                      name='bik'
                      label='БИК'
                      value={organization.bik}
                      onChange={updateOrgString('bik')}
                      disabled={disabled}
                      errors={stValidate.errors}
                      placeholder='XXXXXXXXX'
                      maskParams={{
                        mask: metaOrg.mask('bik')
                      }}
                      width='25%'
                    />
                    <TextField
                      name='bankName'
                      label='Наименование банка'
                      value={organization.bankName}
                      onChange={updateOrgString('bankName')}
                      disabled={disabled}
                      errors={stValidate.errors}
                      width='25%'
                    />
                    <TextField
                      name='correspondentAccountNumber'
                      label='Номер корреспондентсвого счета'
                      value={organization.correspondentAccountNumber}
                      onChange={updateOrgString('correspondentAccountNumber')}
                      disabled={disabled}
                      errors={stValidate.errors}
                      placeholder='XXXXXXXXXXXXXXXXXXXX'
                      maskParams={{
                        mask: metaOrg.mask('correspondentAccountNumber')
                      }}
                      width='25%'
                    />
                  </Stack>
                </> }
                { isLtd && <>
                  <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} sx={{ mb: 2 }}>
                    <TextField
                      name='inn'
                      label='ИНН'
                      value={organization.inn}
                      onChange={updateOrgString('inn')}
                      disabled={disabled}
                      errors={ltdValidate.errors}
                      placeholder='XXXXXXXXXX'
                      maskParams={{
                        mask: ORGANIZATION_INN_MASK
                      }}
                      width='20%'
                    />
                    <TextField
                      name='shortName'
                      label='Краткое наименование'
                      value={organization.shortName}
                      onChange={updateOrgString('shortName')}
                      disabled={disabled}
                      errors={ltdValidate.errors}
                      width='30%'
                    />
                    <TextField
                      name='fullName'
                      label='Полное наименование'
                      value={organization.fullName}
                      onChange={updateOrgString('fullName')}
                      disabled={disabled}
                      errors={ltdValidate.errors}
                      width='50%'
                    />
                  </Stack>
                  { enableNewOkved() && <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} sx={{ mb: 2 }}>
                    <Select
                      name='okveds'
                      label='ОКВЭД из раздела "Транспортировка и хранение"'
                      value={organization.okveds?.map((v:string) => formatToOkved(v))}
                      disabled={disabled}
                      width='100%'
                      errors={ltdValidate.errors}
                      options={okvedOptionList}
                      multiple={true}
                      onChange={updateOrgOkved('okveds')}
                      errorMessage={!organization.okveds?.length ? 'Поле обязательно к заполнению' : ''}
                      inputScroll={true}
                    />
                  </Stack> }
                  <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} sx={{ mb: 2 }}>
                    { !enableNewOkved() && <TextField
                      name='okved'
                      label='Основной ОКВЭД'
                      value={organization.okved}
                      onChange={updateOrgString('okved')}
                      disabled={disabled}
                      errors={ltdValidate.errors}
                      placeholder='XX.XX.XX'
                      maskParams={{
                        mask: ORGANIZATION_OKVAD_MASK
                      }}
                      width='50%'
                    /> }
                    <TextField
                      name='ogrn'
                      label='ОГРН'
                      value={organization.ogrn}
                      onChange={updateOrgString('ogrn')}
                      disabled={disabled}
                      errors={ltdValidate.errors}
                      placeholder='X-XX-XX-XXXXXXX-X'
                      maskParams={{
                        mask: metaOrg.mask('ogrn')
                      }}
                      width='50%'
                    />
                    <Select
                      name='typeTaxation'
                      label='Система налогооблажения'
                      options={Array.from(TaxationNames, ([value, name]) => ({ value, name }))}
                      value={organization.typeTaxation}
                      onChange={(typeTaxation) => setOrganization((data) => data ? { ...data, typeTaxation } : data)}
                      disableClearable={true}
                      disabled={disabled}
                      errors={stValidate.errors}
                      width='50%'
                    />
                  </Stack>
                  <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} sx={{ mb: 2 }}>
                    <TextField
                      name='checkingAccountNumber'
                      label='Номер расчетного счета'
                      value={organization.checkingAccountNumber}
                      onChange={updateOrgString('checkingAccountNumber')}
                      disabled={disabled}
                      errors={ltdValidate.errors}
                      placeholder='XXX.XX.XXX.X.XXXX.XXXXXXX'
                      maskParams={{
                        mask: metaOrg.mask('checkingAccountNumber')
                      }}
                      width='50%'
                    />
                    <TextField
                      name='kpp'
                      label='Номер КПП организации'
                      value={organization.kpp}
                      onChange={updateOrgString('kpp')}
                      disabled={disabled}
                      errors={ltdValidate.errors}
                      placeholder='XXXXXXXXX'
                      maskParams={{
                        mask: ORGANIZATION_KPP_MASK
                      }}
                      width='30%'
                    />
                    <TextField
                      name='bik'
                      label='БИК'
                      value={organization.bik}
                      onChange={updateOrgString('bik')}
                      disabled={disabled}
                      errors={ltdValidate.errors}
                      placeholder='XXXXXXXXX'
                      maskParams={{
                        mask: metaOrg.mask('bik')
                      }}
                      width='30%'
                    />
                    <TextField
                      name='bankName'
                      label='Наименование банка'
                      value={organization.bankName}
                      onChange={updateOrgString('bankName')}
                      disabled={disabled}
                      errors={ltdValidate.errors}
                      width='30%'
                    />
                    <TextField
                      name='correspondentAccountNumber'
                      label='Номер корреспондентского счета'
                      value={organization.correspondentAccountNumber}
                      onChange={updateOrgString('correspondentAccountNumber')}
                      disabled={disabled}
                      errors={ltdValidate.errors}
                      placeholder='XXXXXXXXXXXXXXXXXXXX'
                      maskParams={{
                        mask: metaOrg.mask('correspondentAccountNumber')
                      }}
                      width='50%'
                    />
                  </Stack>
                  <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} >
                    <TextField
                      name='organizationHeadName'
                      label='Лицо, имеющие право подписи без доверенности'
                      value={organization.organizationHeadName}
                      onChange={updateOrgString('organizationHeadName')}
                      disabled={disabled}
                      errors={ltdValidate.errors}
                      width='50%'
                    />
                    <TextField
                      name='position'
                      label='Должность'
                      value={organization.position}
                      onChange={updateOrgString('position')}
                      disabled={disabled}
                      errors={ltdValidate.errors}
                      width='50%'
                    />
                    <TextField
                      name='appointmenDocumentTitle'
                      label='Действует на основании'
                      value={organization.appointmenDocumentTitle}
                      onChange={updateOrgString('appointmenDocumentTitle')}
                      disabled={disabled}
                      errors={ltdValidate.errors}
                      width='50%'
                    />
                  </Stack>
                  <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} >
                    <TextField
                      name='legalAddress'
                      label='Юридический адрес организации'
                      value={organization.legalAddress}
                      onChange={updateOrgString('legalAddress')}
                      disabled={disabled}
                      errors={ltdValidate.errors}
                      width='100%'
                    />
                  </Stack>
                  <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} >
                    <TextField
                      name='mailingAddress'
                      label='Почтовый адрес организации'
                      value={organization.mailingAddress}
                      onChange={updateOrgString('mailingAddress')}
                      disabled={disabled}
                      errors={ltdValidate.errors}
                      width='100%'
                    />
                  </Stack>
                </> }
                <Stack direction='row' spacing={2}>
                  <Upload
                    label='Справка с реквизитами, предоставленная банком'
                    name="scanRequisites"
                    value={organization.scanRequisites}
                    onChange={uploadOrg('scanRequisites')}
                    disabled={disabled}
                    errorMessage={errorScanRequisites}
                    sx={{ width: '33%' }}
                  />
                </Stack>
                { isLtd && <OrganisationParticipants rows={participants} setRows={setParticipants} disabled={disabled} /> }
              </>
            </InfoCard>}
            { passport && <InfoCard
              title='Паспортные данные'
            >
              <>
                <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} sx={{ mb: 2 }}>
                  <DatePicker
                    name='dateOfBirth'
                    label='Дата рождения'
                    value={passport.dateOfBirth}
                    onChange={updatePassportDate('dateOfBirth')}
                    disabled={disabled}
                    errors={passportValidate.errors}
                    shouldDisableDate={(day => dayjs().isBefore(day, 'day'))}
                    width='14em'
                  />
                  <TextField
                    name='passportSeries'
                    label='Серия паспорта'
                    value={passport.passportSeries}
                    onChange={updatePassportString('passportSeries')}
                    disabled={disabled}
                    errors={passportValidate.errors}
                    placeholder='11 22'
                    maskParams={{
                      mask: metaPassport.mask('passportSeries')
                    }}
                    width='12%'
                  />
                  <TextField
                    name='passportNum'
                    label='Номер паспорта'
                    value={passport.passportNum}
                    onChange={updatePassportString('passportNum')}
                    disabled={disabled}
                    errors={passportValidate.errors}
                    placeholder='123456'
                    maskParams={{
                      mask: metaPassport.mask('passportNum')
                    }}
                    width='12%'
                  />
                  <TextField
                    name='issuedDepartment'
                    label='Выдан'
                    value={passport.issuedDepartment}
                    onChange={updatePassportString('issuedDepartment')}
                    disabled={disabled}
                    errors={passportValidate.errors}
                    width='76%'
                  />
                </Stack>
                <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} >
                  <DatePicker
                    name='dateIssue'
                    label='Дата выдачи'
                    value={passport.dateIssue}
                    onChange={updatePassportDate('dateIssue')}
                    disabled={disabled}
                    errors={passportValidate.errors}
                    shouldDisableDate={(day => dayjs().isBefore(day, 'day'))}
                    width='14em'
                  />
                  <TextField
                    name='departmentCode'
                    label='Код подразделения'
                    value={passport.departmentCode}
                    onChange={updatePassportString('departmentCode')}
                    disabled={disabled}
                    errors={passportValidate.errors}
                    placeholder='123'
                    maskParams={{
                      mask: metaPassport.mask('departmentCode')
                    }}
                    width='12%'
                  />
                  <TextField
                    name='registrationAddress'
                    label='Адрес регистрации (прописка)'
                    value={passport.registrationAddress}
                    onChange={updatePassportString('registrationAddress')}
                    disabled={disabled}
                    errors={passportValidate.errors}
                    width='88%'
                  />
                </Stack>
              </>
            </InfoCard>}
            { organization && passport && <InfoCard
              title='Документы'
            >
              <>
                <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} sx={{ mb: 2 }}>
                  <Upload
                    name='scanInn'
                    label='Свидетельство ИНН'
                    value={organization.scanInn}
                    onChange={uploadOrg('scanInn')}
                    disabled={disabled}
                    errors={isLtd ? ltdUpValidate.errors : stUpValidate.errors}
                    sx={{ width: '33.33%' }}
                  />
                  <Upload
                    name='scanRegistration'
                    label={isLtd ? 'Выписка из ЕГРЮЛ' : 'Выписка из ЕГРИП'}
                    value={organization.scanRegistration}
                    onChange={uploadOrg('scanRegistration')}
                    disabled={disabled}
                    errors={isLtd ? ltdUpValidate.errors : stUpValidate.errors}
                    sx={{ width: '33.33%' }}
                  />
                  <Upload
                    name='scanCode'
                    label='Коды ОКПО'
                    value={organization.scanCode}
                    onChange={uploadOrg('scanCode')}
                    disabled={disabled}
                    errors={isLtd ? ltdUpValidate.errors : stUpValidate.errors}
                    sx={{ width: '33.33%' }}
                  />
                </Stack>
                <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} >
                  <Upload
                    name='scan1'
                    label='Паспорт (разворот с фото)'
                    value={passport.scan1}
                    onChange={uploadPassport('scan1')}
                    disabled={disabled}
                    errors={passportUpValidate.errors}
                    sx={{ width: '33.33%' }}
                  />
                  <Upload
                    name='scan2'
                    label='Паспорт (прописка)'
                    value={passport.scan2}
                    onChange={uploadPassport('scan2')}
                    disabled={disabled}
                    errors={passportUpValidate.errors}
                    sx={{ width: '33.33%' }}
                  />
                  <Upload
                    name='scanCertificate'
                    label={isLtd ? 'Свидетельство ОГРН' : 'Свидетельство ОГРНИП'}
                    value={organization.scanCertificate}
                    onChange={uploadOrg('scanCertificate')}
                    disabled={disabled}
                    errors={isLtd ? ltdUpValidate.errors : stUpValidate.errors}
                    sx={{ width: '33.33%' }}
                  />
                </Stack>
                <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} >
                  <Upload
                    name='scanPowerOfAttorney'
                    label={'Доверенность'}
                    value={organization.scanPowerOfAttorney}
                    onChange={uploadOrg('scanPowerOfAttorney')}
                    disabled={disabled}
                    errors={isLtd ? ltdUpValidate.errors : stUpValidate.errors}
                    sx={{ width: '33.33%' }}
                  />
                </Stack>
                { organization.typeTaxation === TypeTaxation.patent && <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} >
                  <Upload
                    name='scanPatent'
                    label='Патент'
                    value={organization?.scanPatent}
                    onChange={uploadOrg('scanPatent')}
                    disabled={disabled}
                    sx={{ width: '33%' }}
                    errors={isLtd ? ltdUpValidate.errors : stUpValidate.errors}
                    errorMessage={scanPatentError}
                  />
                </Stack> }
              </>
            </InfoCard>}
            <InfoCard>
              <>
                <Stack direction='row' justifyContent='left' alignItems='center' sx={{ gap: '10px' }}>
                  <AntSwitch
                    checked={organization?.hasLicense ?? false}
                    onChange={updateOrgCheckbox('hasLicense')}
                    disabled={disabled}
                    inputProps={{ 'aria-label': 'ant design' }}
                  />
                  <Typography>Лицензия</Typography>
                </Stack>
                { organization?.hasLicense && <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} mt={2} >
                  <Typography width='67%' >Лицензия (разрешение) на осуществление деятельности (предмет договора лицензируемый вид деятельности)</Typography>
                  <Upload
                    name='scanLicense'
                    value={organization?.scanLicense}
                    onChange={uploadOrg('scanLicense')}
                    disabled={disabled}
                    errors={stUpValidate.errors}
                    errorMessage={scanLicenseError}
                    sx={{ width: '33%' }}
                  />
                </Stack> }
              </>
            </InfoCard>
            { organization && <InfoCard title='Бухгалтерские документы'>
              <>
                { isLtd && <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} sx={{ mb: 2 }}>
                  <Typography width='67%' >Копия банковской карточки с образцами подписей</Typography>
                  <Upload
                    name='scanBankCard'
                    value={organization.scanBankCard}
                    onChange={uploadOrg('scanBankCard')}
                    sx={{ width: '33%' }}
                    errors={ltdUpValidate.errors}
                  />
                </Stack> }
                { isLtd && <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} sx={{ mb: 2 }}>
                  <Typography width='67%' >Устав (все страницы), действующие изменения к Уставу</Typography>
                  <Upload
                    name='scanRegulations'
                    value={organization.scanRegulations}
                    onChange={uploadOrg('scanRegulations')}
                    sx={{ width: '33%' }}
                    errors={ltdUpValidate.errors}
                  />
                </Stack> }
                { isLtd && <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} sx={{ mb: 2 }}>
                  <Typography width='67%' >
                      Протокол собрания участников (акционеров) или Решение единств. участника/акционера о назначении ЕИО/Передаче полномочий ЕИО управляющей компании.
                      В случае передачи полномочий ЕИО Управляющей компании - Протокол собрания участников (акционеров) или Решение единств. участника/акционера Управляющей компании о назначении ЕИО УК
                  </Typography>
                  <Upload
                    name='scanAdministrationProtocol'
                    value={organization.scanAdministrationProtocol}
                    onChange={uploadOrg('scanAdministrationProtocol')}
                    sx={{ width: '33%' }}
                    errors={ltdUpValidate.errors}
                  />
                </Stack> }
                <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} sx={{ mb: 2 }}>
                  <Typography width='67%'>Копии документов, подтверждающих место нахождения {isLtd ? 'юридического лица' : 'ИП' } по месту регистрации (договор аренды, свидетельство о праве собственности{isLtd ? '' : ', штамп регистрации по месту жительства'})</Typography>
                  <Upload
                    name='scanRegistrationAddress'
                    value={organization.scanRegistrationAddress}
                    onChange={uploadOrg('scanRegistrationAddress')}
                    disabled={disabled}
                    errors={stUpValidate.errors}
                    sx={{ width: '33%' }}
                  />
                </Stack>
                { (isLtd || isVat) && <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} sx={{ mb: 2 }}>
                  <Typography width='67%' >Отчет о финансовых результатах за последний отчетный период с отметкой ИФНС или квитанцией о приеме ИФНС (в электр.виде)</Typography>
                  <Upload
                    name='scanFinancialResults'
                    value={organization.scanFinancialResults}
                    onChange={uploadOrg('scanFinancialResults')}
                    disabled={disabled}
                    errors={stUpValidate.errors}
                    sx={{ width: '33%' }}
                  />
                </Stack> }
                <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} sx={{ mb: 2 }}>
                  <Typography width='67%' >Копия налоговой декларации за {isLtd ? 'предыдущий' : 'последний' } отчетный период (УСН, ЕНВД, ЕСХН) с отметками налоговой инспекции о принятии данной декларации{isVat ? ' либо Декларация по НДС за последний отчетный период и подтверждение ее получения ИФНС (квитанция о приеме налоговой декларации в электр.виде)' : ''}</Typography>
                  <Upload
                    name='scanTaxReturnOrFeeDeclaration'
                    value={organization.scanTaxReturnOrFeeDeclaration}
                    onChange={uploadOrg('scanTaxReturnOrFeeDeclaration')}
                    disabled={disabled}
                    errors={isLtd ? ltdUpValidate.errors : stUpValidate.errors}
                    sx={{ width: '33%' }}
                  />
                </Stack>
                {/*
                <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} sx={{ mb: 2 }}>
                  <Typography width='67%' >Расчет сумм налога на доходы физических лиц, исчисленных и удержанных налоговым агентом (Форма 6-НДФЛ) за предыдущий отчетный период (при аттестации/заключении договора,+ не позже 10 календарных дней с даты истечение срока сдачи отчета по Форме 6-НДФЛ за отчетный период, в течение которого выполнялась перевозка</Typography>
                  <Upload
                    name='calcPersonalIncomeTax'
                    value={organization.calcPersonalIncomeTax}
                    onChange={uploadOrg('calcPersonalIncomeTax')}
                    disabled={disabled}
                    errors={isLtd ? ltdUpValidate.errors : stUpValidate.errors}
                    sx={{ width: '33%' }}
                  />
                </Stack>
                */}
                <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} sx={{ mb: 2 }}>
                  <Typography width='67%' >Согласие на признание сведений, составляющих налоговую тайну, общедоступными по форме, утвержденной приказом ФНС России от 14.11.2022 N ЕД-7-19/1085@ «Об утверждении документов, предусмотренных подпунктом 1 пункта 1 и пунктом 2.3 статьи 102 Налогового кодекса Российской Федерации» (Код комплекта сведений 20013), или (при предоставлении Согласия до 13.12.2022 г) по форме, утвержденной Приказом ФНС России от 15.11.2016 № ММВ-7-17/615@, с соблюдением порядка заполнения, установленного Письмом ФНС России от 19.03.2020 г №СД-4-2/4748</Typography>
                  <Upload
                    name='scanConsentTaxpayer'
                    value={organization.scanConsentTaxpayer}
                    onChange={uploadOrg('scanConsentTaxpayer')}
                    disabled={disabled}
                    errors={stUpValidate.errors}
                    sx={{ width: '33%' }}
                  />
                </Stack>
                { isVat && <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} sx={{ mb: 2 }}>
                  <Typography width='67%' >Квитанция о приеме налоговым органом по телекоммуникационным каналам связи Согласия налогоплательщика (плательщика страховых взносов) на признание сведений, составляющих налоговую тайну, общедоступными</Typography>
                  <Upload
                    name='scanReceiptConsentTaxpayer'
                    value={organization.scanReceiptConsentTaxpayer}
                    onChange={uploadOrg('scanReceiptConsentTaxpayer')}
                    disabled={disabled}
                    errors={stUpValidate.errors}
                    sx={{ width: '33%' }}
                  />
                </Stack> }
                <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} sx={{ mb: 2 }}>
                  <Typography width='67%'>Анкета по требованиям Хартии АПК РФ {info.sampleQuestionnaire !== undefined && <span
                        style={{ textDecoration: 'underline', cursor: 'pointer' }}
                        onClick={() => download(info.sampleQuestionnaire as string)}>(скачать образец)</span>}</Typography>
                  <Upload
                    name='scanQuestionnaire'
                    value={organization?.scanQuestionnaire}
                    onChange={uploadOrg('scanQuestionnaire')}
                    sx={{ width: '33%' }}
                    disabled={disabled}
                    errors={stUpValidate.errors}
                  />
                </Stack>
                <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} sx={{ mb: 2 }}>
                  <Typography width='67%' >
                    Информационное письмо о деятельности {(isLtd ? info.sampleInformationLetterOrg : info.sampleInformationLetter) !== undefined && <span
                      style={{ textDecoration: 'underline', cursor: 'pointer' }}
                      onClick={() => download((isLtd ? info.sampleInformationLetterOrg : info.sampleInformationLetter) as string)}>(скачать образец)</span>}
                  </Typography>
                  <Upload
                    name='scanInformationLetter'
                    value={organization?.scanInformationLetter}
                    onChange={uploadOrg('scanInformationLetter')}
                    disabled={disabled}
                    sx={{ width: '33%' }}
                    errors={isLtd ? ltdUpValidate.errors : stUpValidate.errors}
                    errorMessage={scanInformationLetterError}
                  />
                </Stack>
                <Stack direction='row' alignItems='center' width='67%'>
                  <Checkbox
                    checked={profile.hasHiredDrivers}
                    onChange={updateHasHiredDrivers}
                    sx={{ ml: 0, pl: 0 }}
                  />
                  <Typography >Предусмотрены наемные водители в штате</Typography>
                </Stack>
                  { (profile.hasHiredDrivers || requestAddDrivers) && <Box bgcolor={requestAddDrivers ? '#fff4e5' : undefined}>
                    <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} sx={{ mb: 2 }}>
                      <Typography width='67%' >Сведения о застрахованных лицах Формы КНД 1151162 «Персонифицированные сведения о физических лицах», утвержденный Приказом ФНС России от 29.09.2022 N ЕД-7-11/878@ (в части сведений о водителях, направляемых для оказания услуги по Договору, с закрытыми на копии/скан-копии данными СНИЛС, ИНН и Сведениями о сумме выплат и иных вознаграждений, начисленных в пользу физического лица его Работодателем)</Typography>
                      <Upload
                        name='scanInsuredPersons'
                        value={organization.scanInsuredPersons ?? requestAddDrivers?.data.scanInsuredPersons}
                        onChange={uploadOrg('scanInsuredPersons')}
                        disabled={disabled || !!requestAddDrivers}
                        errors={stUpValidate.errors}
                        sx={{ width: '33%' }}
                        errorMessage={scanInsuredPersonsError}
                      />
                    </Stack>
                    <Box>
                      <Stack direction='row' alignItems='center' width='67%' spacing={2}>
                        <AntSwitch
                          checked={loadReportEFS || !!requestAddDrivers?.data.scanReportEFS}
                          onChange={(_e, checked) => setLoadReportEFS(checked)}
                          disabled={disabled}
                          inputProps={{ 'aria-label': 'ant design' }}
                        />
                        <Typography >Данные о водителе в Форме КНД 1151162 отсутствуют</Typography>
                      </Stack>
                      { loadReportEFS && <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} mt={2} >
                        <Typography width='67%'>Удостоверенная уполномоченным лицом копия отчета ЕФС-1, подраздел 1.1 с титульным листом утвержденная Постановлением Правления ПФ РФ от 31.10.2022 N 245п (в части соответствующего физического лица) с отметкой о его принятии Фондом пенсионного и социального страхования РФ.)</Typography>
                        <Upload
                          name='scanReportEFS'
                          value={organization.scanReportEFS ?? requestAddDrivers?.data.scanReportEFS}
                          onChange={uploadOrg('scanReportEFS')}
                          disabled={disabled || !loadReportEFS || !!requestAddDrivers}
                          errors={stUpValidate.errors}
                          errorMessage={scanReportEFSError}
                          sx={{ width: '33%' }}
                        />
                      </Stack> }
                    </Box>
                    <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} sx={{ mb: 2 }}>
                      <Typography width='67%' >Выписка из штатной расстановки</Typography>
                      <Upload
                        name='manningTable'
                        value={organization.manningTable || requestAddDrivers?.data.manningTable}
                        onChange={uploadOrg('manningTable')}
                        disabled={disabled || !!requestAddDrivers}
                        errors={isLtd ? ltdUpValidate.errors : stUpValidate.errors}
                        sx={{ width: '33%' }}
                        errorMessage={manningTableError}
                      />
                    </Stack>
                  </Box>}
              </>
            </InfoCard> }
            <InfoCard>
              <>
                <Stack direction='row' justifyContent='left' alignItems='center' sx={{ gap: '10px' }}>
                  <AntSwitch
                    checked={organization?.hasAccounting ?? false}
                    onChange={updateOrgCheckbox('hasAccounting')}
                    disabled={isLtd || disabled}
                    inputProps={{ 'aria-label': 'ant design' }}
                  />
                  <Typography>Ведется бухгалтерский учет</Typography>
                </Stack>
                { organization?.hasAccounting && <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} mt={2} >
                  <Typography width='67%' >Бухгалтерский баланс за последний отчетный период с отметкой ИФНС или квитанцией о приеме ИФНС (в электр.виде)</Typography>
                  <Upload
                    name='accountingBalance'
                    value={organization?.accountingBalance}
                    onChange={uploadOrg('accountingBalance')}
                    disabled={disabled}
                    errors={stUpValidate.errors}
                    errorMessage={accountingBalanceError}
                    sx={{ width: '33%' }}
                  />
                </Stack> }
              </>
            </InfoCard>
            { !disabled && organization && passport && <InfoCard
              title='История изменений'
            >
              <History filter={{
                col: ['main.organizations', 'main.passports', 'main.profiles'],
                id: [profile.id, organization?.id, passport?.id]
              }} onUndo={init}/>
            </InfoCard> }
          </div>
        </div>
      </div>
      <Modal
        title='Отклонить запрос'
        open={profileRequestCancleId !== undefined}
        onClose={profileRequestModalCancle}
        content={<Stack sx={{ minWidth: '500px' }} >
              <Box>
                <TextField
                  rows={6}
                  label="Комментарий"
                  width='100%'
                  value={profileRequestCancleComment}
                  onChange={e => { setProfileRequestCancleComment(e.target.value) }}
                  placeholder="Добавьте комментарий"
                />
              </Box>
            </Stack>
        }
        actions={<>
          <Button color="secondary" variant="outlined" onClick={profileRequestModalCancle}>Отменить</Button>
          <Button variant="contained" onClick={profileRequestCancle} disabled={!profileRequestCancleComment}>Отклонить</Button>
        </>
        }
      />
    </>
  )
}
