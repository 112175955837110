import './styles.sass'
import { useEffect, useState } from 'react'
import InfoCard from 'ui/InfoCard'
import { Grid, Stack } from '@mui/material'
import Notification, { Notice } from './Notification'
import Head from 'pages/CarrierHome/Head'
import { NotificationEvent, notificationList } from 'api/notify'
import { getNotifyVariant } from 'pages/SystemNotifies/Notifications'
import { useMainRoutes } from 'routes'
import None from 'ui/None'

export const map = (origin: NotificationEvent[]): Notice[] => {
  return origin.map(item => ({
    type: getNotifyVariant(item.slug),
    date: item.createTs,
    message: item.body ?? item.title ?? item.slug,
    link: ''
  }))
}

export interface Params {
  viewList?: boolean
}

export default function DashBoardNotifications ({ viewList }: Params) {
  const [list, setList] = useState<Notice[]>([])
  const { links } = useMainRoutes()

  useEffect(() => {
    notificationList({ limit: 8 })
      .then(map)
      .then(setList)
  }, [])

  return (
      <InfoCard className='notifications'>
        <>
          <Head headText='Недавние уведомления' linkText='Все уведомления' linkRef={links.NOTIFICATIONS} />

          {list.length === 0 && <Stack height='100%' direction='column'><None desc='Нет новых уведомлений' /></Stack>}
          {list.length > 0 && <Grid container spacing={2}>
            {list.map((n, i) => <Grid item sm={viewList ? 12 : 6} key={i}><Notification notice={n}/></Grid>)}
          </Grid>}
        </>
      </InfoCard>
  )
}
