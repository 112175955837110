import { PropsWithChildren, createContext, useContext, useState, useEffect, useCallback, ReactNode } from 'react'
import { authService, mainService, notifyService } from 'api/service'
import {
  sessionCreate, SessionForCreate,
  sessionSendCode, SessionSendCodeResult,
  sessionActivate, SessionActivateResult,
  sessionDelete
} from 'api/session'
import { SessionStatus } from 'api/SessionStatus'
import { UserStatus } from 'api/UserStatus'
import { AccessDeniedError } from 'api/AccesDeniedError'
import { Profile, profileGet } from 'api/profile'
import { Interface } from 'api/Interface'
import { useEnqueueSnackbar } from 'util/useEnqueueSnackbar'
import { IRoute } from 'routes/types'
import { useMainRoutes } from 'routes'

export enum AuthContextStatus {
  waitLogin = 'waitLogin',
  registration = 'registration',
  waitCode = 'waitCode',
  waitPostRegistration = 'waitPostRegistration',
  postRegistration = 'postRegistration',
  successRegistration = 'successRegistration',
  active = 'active',
  recovery = 'recovery',
}

type LoginParams = Omit<SessionForCreate, 'register' | 'recovery'>
type Route = Pick<IRoute, 'path' | 'element'>

interface Context {
  status?: AuthContextStatus
  userStatus?: UserStatus
  phone?: string
  profile?: Profile
  currentInterface?: Interface
  routes?: Route[]

  recovery: (phone: string) => Promise<boolean>
  register: (params: LoginParams) => Promise<boolean>
  login: (params: LoginParams) => Promise<boolean>
  sendCode: () => Promise<SessionSendCodeResult>
  activate: (code: string) => Promise<SessionActivateResult>
  logout: () => Promise<boolean>

  startRegistration: () => void
  endRegistration: () => void
  startPostRegistration: () => void
  successRegistration: () => void

  profileRefresh: () => void

  handleResponseFailure: (error: ReactNode) => void
  handleResponseSuccess: (message?: ReactNode) => void
}

const AuthContext = createContext<Context | null>(null)

export function AuthContextProvider ({ children }: PropsWithChildren<{}>) {
  const [status, setStatus] = useState<AuthContextStatus>()
  const [userStatus, setUserStatus] = useState<UserStatus>()
  const [phone, setPhone] = useState<string>()
  const [profile, setProfile] = useState<Profile>()
  const [currentInterface, setCurrentInterface] = useState<Interface>()
  const [routes, setRoutes] = useState<Route[]>()

  const { snack } = useEnqueueSnackbar()
  const { routesMap } = useMainRoutes()

  const clearCache = useCallback(() => {
    authService.clear()
    mainService.clear()
    notifyService.clear()
  }, [])

  const init = useCallback(() => {
    clearCache()
    mainService.getToken().then(({ phone, status, interfaces, session }) => {
      profileGet().then(setProfile)
      setPhone(phone)
      setUserStatus(status)
      setCurrentInterface(interfaces[0])
      setStatus(session === SessionStatus.recovery ? AuthContextStatus.recovery : status === UserStatus.new ? AuthContextStatus.waitPostRegistration : AuthContextStatus.active)
      setRoutes(routesMap.getRoutesByUserInterface(interfaces[0], status !== UserStatus.active))
    }).catch((error) => {
      if (error instanceof AccessDeniedError) {
        setProfile(undefined)
        setPhone(undefined)
        setUserStatus(undefined)
        setRoutes(undefined)
        setCurrentInterface(undefined)
        setStatus(error.session === SessionStatus.recovery ? AuthContextStatus.recovery : error.session === SessionStatus.new ? AuthContextStatus.waitCode : AuthContextStatus.waitLogin)
      } else {
        throw error
      }
    })
  }, [clearCache, routesMap])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { init() }, [])

  const createSession = async (params: SessionForCreate): Promise<boolean> => {
    const { success, session, user } = await sessionCreate(params)
    setPhone(params.phone)
    setUserStatus(user === null ? UserStatus.blocked : user)

    if (success && session === SessionStatus.new) {
      setStatus(AuthContextStatus.waitCode)
    }

    if (success && session === SessionStatus.active) {
      init()
    }

    return success
  }

  const value = {
    status,
    userStatus,
    phone,
    profile,
    currentInterface,
    routes,

    register: (params: LoginParams) => createSession({ ...params, register: true }),
    login: (params: LoginParams) => createSession({ ...params, register: false }),
    recovery: (phone: string) => createSession({ phone, register: false, recovery: true }),
    sendCode: sessionSendCode,

    activate: async (code: string): Promise<SessionActivateResult> => {
      const result = await sessionActivate(code)

      if (result.success) {
        init()
      }

      return result
    },

    logout: async (): Promise<boolean> => {
      const success = await sessionDelete()

      if (success) {
        setProfile(undefined)
        setPhone(undefined)
        setUserStatus(undefined)
        setCurrentInterface(undefined)
        setRoutes(undefined)
        setStatus(AuthContextStatus.waitLogin)
        clearCache()
      }

      return success
    },

    startRegistration: () => setStatus(AuthContextStatus.registration),
    endRegistration: () => setStatus(AuthContextStatus.waitLogin),
    startPostRegistration: () => setStatus(AuthContextStatus.postRegistration),
    successRegistration: () => setStatus(AuthContextStatus.successRegistration),

    handleResponseFailure: (error: ReactNode) => snack({ content: error, color: 'error' }),
    handleResponseSuccess: (message?: ReactNode) => snack({ content: message, color: 'success' }),

    profileRefresh: init
  } as const

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  )
}

export function useAuthContext () {
  const context = useContext(AuthContext)

  if (context == null) {
    throw new Error('Used outside of "AuthContextProvider"')
  }

  return context
}
