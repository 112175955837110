import { Box, Stack } from '@mui/material'
import { ShippingTruckRun } from 'api/shipping'
import Modal from 'ui/Modal'

export interface Params {
  truckRun?: ShippingTruckRun
  onClose: () => void
}

export default function QRCodeModel ({ truckRun, onClose }: Params) {
  if (truckRun?.reservedUnloadingSlot === undefined) {
    return (<></>)
  }

  return (<>
    <Modal
      maxWidth='lg'
      open={truckRun?.reservedUnloadingSlot !== undefined}
      onClose={onClose}
      title='QR-код для разгрузки'
      content={<>
        <Box minWidth='500px'>
          <Stack direction='row' justifyContent='center'>
            <img
              src={`data:image/jpg;base64,${truckRun.reservedUnloadingSlot.QRCode}`}
              width='300px'
              height='300px'
              alt='QRCode'
            />
          </Stack>
        </Box>
      </>}
      actions={<></>}
    />
  </>)
}
