import ChipStatus from 'ui/ChipStatus'
import { Id } from 'api/Id'
import { Stack } from '@mui/material'

export default function ChipAprovedStatus <T = 'new' | 'registered' | 'confirmed' | 'active' | 'blocked' | 'archive'> ({ status, truckRuns = [] }: { status: T, truckRuns?: Id[] }) {
  switch (status) {
    case 'new':
      return <ChipStatus color='grey' label='В процессе регистрации' />
    case 'registered':
      return <ChipStatus color='grey' label='На рассмотрении' />
    case 'confirmed':
      return <ChipStatus color='grey' label='На рассмотрении' />
    case 'active':
      return <Stack gap={1} direction="row" flexWrap="wrap">
        <ChipStatus color='green' label='Подтвержден' />
        { truckRuns.length > 0
          ? <ChipStatus color='yellowBorder' label='Участвует в перевозке' />
          : <></>
        }
      </Stack>
    case 'rejected':
      return <ChipStatus color='red' label='Отказано' />
    case 'blocked':
      return <ChipStatus color='red' label='Заблокирован' />
    case 'archive':
      return <ChipStatus color='lightGrey' label='В архиве' />
    default:
      return <></>
  }
}
