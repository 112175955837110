import { Address } from 'api/address'

export default function shortAddress ({ name, addressOrig = '-' }: Address): string {
  // let result = name ? `${name}, ${address.city}, ` : `${address.city}, `
  // result += address.district ? `${address.district}, ` : ''
  // result += `${address.region}.`
  // return result

  return `${name}, ${addressOrig}`
}
