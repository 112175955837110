import { NotifyEvent } from '.'
import { useEffect } from 'react'

export interface Data {
  driverName: string
  carrierName?: string
  message: string
}

const SLUGS = ['drivers_dispatcher_blacklisted_driver_event', 'drivers_carrier_blacklisted_driver_event']

export default function DriverBlacklisted ({ slug, data, toast } :NotifyEvent) {
  useEffect(() => {
    if (slug === undefined) {
      return
    }

    if (!SLUGS.includes(slug)) {
      return
    }

    if (toast === undefined) {
      return
    }

    if (data === undefined) {
      return
    }

    const { message } = data as Data

    toast(message, 'error')
  }, [slug, data, toast])

  return (<></>)
}
