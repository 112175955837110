import './styles.sass'
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import EditIcon from '@mui/icons-material/Edit'
import ArchiveIcon from '@mui/icons-material/Archive'
import VisibilityIcon from '@mui/icons-material/Visibility'

import { useAuthContext } from 'AuthContext'
import { useMainRoutes } from 'routes'

import { Id, isId } from 'api/Id'
import { ProfileAll, profileAllGet } from 'api/profile'
import { DriverAll, driverAllList, DriverStatus, DriverArchiveParams, driverAllArchive, DriverAllFilter } from 'api/driver'
import { Rejection, rejectionList } from 'api/rejection'

import DataTable, { Column } from 'ui/DataTable'
import ActionsMenu from 'ui/ActionsMenu'
import { UserRole } from 'api/user'
import useTabs from 'common/Registry/Carrier/useTabs'
import NavigatePanel from 'ui/NavigatePanel'
import SquareButton from 'ui/SquareButton'
import useDisabled from 'common/Registry/Carrier/useDisabled'
import { ChipDriverStatusDispatcher } from 'common/ChipDriverStatusDispatcher'
import Modal from 'ui/Modal'
import DatePicker from 'ui/DatePicker'
import TextField from 'ui/TextField'
import { Box, Stack, Button } from '@mui/material'
import CreateRegistryDriversAndVehicleButton from 'ui/CreateRegistryDriversAndVehicleButton'

export default function Drivers () {
  const params = useParams()
  const [profile, setProfile] = useState<ProfileAll>()
  const [rows, setRows] = useState<DriverAll[]>([])
  const [/* rejections */, setRejections] = useState<Rejection[]>()
  const [searched] = useState<(number|string)[]>()
  const [archiveData, setArchiveData] = useState<DriverArchiveParams | null>()

  const navigate = useNavigate()
  const location = useLocation()
  const { tabs } = useTabs(profile?.id)
  const { handleResponseFailure, currentInterface } = useAuthContext()
  const { disabled } = useDisabled(currentInterface)

  const { links, routesMap } = useMainRoutes()

  const init = useCallback(async () => {
    const { carrier: id } = params

    const profile = isId(id) ? await profileAllGet(id, { role: UserRole.carrier }) : null

    if (profile === null) {
      handleResponseFailure('Перевозчик не найден')
      return
    }

    const filter: DriverAllFilter = { owner: profile.phone }

    if (disabled) {
      filter.status = DriverStatus.active
    }

    const rows = await driverAllList(filter)
    const ids = rows.filter(({ status }) => status === DriverStatus.blocked).map(({ id }) => id)
    const rejections = await rejectionList({ objectIds: ids })

    setProfile(profile)
    setRows(rows)
    setRejections(rejections)
  }, [params, disabled, handleResponseFailure])

  useEffect(() => { init() }, [init])

  const openArchiveModal = (id?: Id) => {
    if (id === undefined) {
      return
    }

    setArchiveData({ id, date: Math.floor(Date.now() / 1000) })
  }

  const updateArchiveString = (key: keyof Pick<DriverArchiveParams, 'comment'>) => {
    return (e: ChangeEvent<HTMLElement & {value: string}>) => {
      if (!archiveData) {
        return
      }

      setArchiveData({ ...archiveData, [key]: e.target.value })
    }
  }

  const updateArchiveDate = (key: keyof DriverArchiveParams) => {
    return (value: number) => {
      if (!archiveData) {
        return
      }

      setArchiveData({ ...archiveData, [key]: value })
    }
  }

  const archive = useCallback(async () => {
    if (!archiveData) {
      return
    }

    await driverAllArchive(archiveData)
    setArchiveData(undefined)
    init()
  }, [archiveData, init])

  const columns = useMemo(() => {
    const items: Column[] = [
      {
        id: ['firstName', 'secondName', 'familyName'],
        label: 'ФИО',
        format: (firstName: unknown, secondName: unknown, familyName: unknown) => {
          if (typeof firstName === 'string' && typeof secondName === 'string' && typeof familyName === 'string') {
            const family = familyName.charAt(0).toUpperCase() + familyName.slice(1)
            return <>{`${family} ${firstName.charAt(0).toUpperCase()}. ${secondName ? `${secondName.charAt(0).toUpperCase()}.` : ''}`}</>
          }

          return <></>
        }
      },
      {
        id: 'phone',
        label: 'Номер телефона'
      }
    ]

    if (disabled) {
      items.push({
        id: 'id',
        label: '',
        align: 'right',
        minWidth: 10,
        format: value => <SquareButton
            onClick={() => navigate(value as string)}
            sx={{
              background: '#B2B2B2',
              color: '#fff',
              padding: 0,
              '&:hover': {
                background: '#B2B2B2',
                border: 'none',
                filter: 'brightness(96%)'
              }
            }}
          >
            <MoreHorizIcon sx={{ fontSize: '16px' }}/>
          </SquareButton>
      })
    } else {
      items.push({
        id: ['status', 'truckRuns'],
        label: 'Статус',
        format: (status, truckRuns) => ChipDriverStatusDispatcher({ status: status as DriverStatus, truckRuns: truckRuns as Id[] | undefined })
      },
      {
        id: ['id', 'status', 'truckRuns'],
        label: '',
        align: 'right',
        minWidth: 10,
        format: (id, status, truckRuns) => <ActionsMenu key={`${id}`} actions = {
          status === DriverStatus.archive || (Array.isArray(truckRuns) && truckRuns.length > 0)
            ? [
                {
                  caption: status === DriverStatus.archive ? 'Посмотреть' : 'Редактировать',
                  onClick: () => navigate(`${location.pathname}/${id}`),
                  icon: status === DriverStatus.archive ? <VisibilityIcon /> : <EditIcon />
                }
              ]
            : [
                {
                  caption: 'Редактировать',
                  onClick: () => navigate(`${location.pathname}/${id}`),
                  icon: <EditIcon />
                },
                {
                  caption: 'Архивировать',
                  onClick: () => openArchiveModal(id as Id),
                  icon: <ArchiveIcon />
                }
              ]
        } />
      })
    }

    return items
  }, [location.pathname, navigate, disabled])

  if (profile === undefined) {
    return <></>
  }

  return (
    <>
      <Modal
        title='Архивировать водителя'
        open={archiveData !== undefined}
        onClose={() => setArchiveData(undefined)}
        content={archiveData
          ? <Stack sx={{ minWidth: '500px' }} >
              <Box sx={{ mb: 2 }}>
                <DatePicker
                  label='Дата архивации'
                  value={archiveData.date}
                  onChange={updateArchiveDate('date')}
                  disabled
                />
              </Box>
              <Box>
                <TextField
                  rows={6}
                  label="Комментарий"
                  width='100%'
                  value={archiveData.comment}
                  onChange={updateArchiveString('comment')}
                  placeholder="Добавьте комментарий, например причину увольнения"
                />
              </Box>
            </Stack>
          : <></>
        }
        actions={ archiveData
          ? <>
            <Button color="secondary" variant="outlined" onClick={() => setArchiveData(undefined)}>Отменить</Button>
            <Button variant="contained" onClick={ () => { archive() } }>Архивировать</Button>
          </>
          : <></>
        }
      />
      <div className='registryCarriers_drivers'>
        <NavigatePanel
          // search={{
          //   rows: rows === undefined ? [] : rows,
          //   fields: [
          //      'name',
          //      'phone'
          //   ],
          //   onChange: setSearched
          // }}
          title={`Перевозчики ${profile.organization?.shortName ?? ''}`}
          breadcrumbs={{
            items: [
              {
                title: profile.organization?.shortName ?? ''
              }
            ],
            defaultItems: routesMap.getBreadcrumbs(links.REGISTRY_CARRIERS_PAGE)
          }}
          tabs={tabs}
          actions={<Stack direction='row' spacing={2} justifyContent='end'>
            {rows.length > 0 && <CreateRegistryDriversAndVehicleButton profile={profile}>
              Сформировать реестр Водителей и ТС
            </CreateRegistryDriversAndVehicleButton> }
          </Stack>}
        />
        <div className='registryCarriers_drivers__body'>
          <div className='registryCarriers_drivers__body_content'>
            <DataTable
              columns={columns}
              rows = { (start, end) => rows.filter(row => searched === undefined ? true : searched.includes(row.id)).slice(start, end) }
              rowsCount = { searched === undefined ? rows.length : searched.length }
            />
          </div>
        </div>
      </div>
    </>
  )
}
