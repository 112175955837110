import './styles.sass'
import NavigatePanel from 'ui/NavigatePanel'
import DataTable, { Column } from 'ui/DataTable'
import { useMainRoutes } from 'routes'
import { Person, personName } from 'util/personName'
import { Box } from '@mui/material'
import formatPhone from 'util/formatPhone'
import { useCallback, useEffect, useState } from 'react'
import { DriverAll, DriverAllFilter, driverAllList, DriverStatus } from 'api/driver'
import { Link, useNavigate, useParams } from 'react-router-dom'
import VisibilityIcon from '@mui/icons-material/Visibility'
import ActionsMenu from 'ui/ActionsMenu'
import { isEnum } from 'util/isEnum'

const statusMap = new Map<DriverStatus | '', string>()
  .set('', 'Все')
  .set(DriverStatus.new, 'Новые')
  .set(DriverStatus.active, 'Подтвержденные')
  .set(DriverStatus.blocked, 'Заблокированные')
  .set(DriverStatus.archive, 'В архиве')
  .set(DriverStatus.needsAttention, 'Требуют внимания')

const RegistryDrivers = () => {
  const { links, routesMap } = useMainRoutes()
  const [rows, setRows] = useState<DriverAll[]>()
  const [searched, setSearched] = useState<(number|string)[]>()
  const navigate = useNavigate()
  const { status } = useParams()
  const filterStatus = isEnum(DriverStatus)(status) ? status : ''

  const init = useCallback(async () => {
    const filterQuery : DriverAllFilter = { includeAdditionalData: true }
    if (filterStatus.length !== 0) {
      filterQuery.status = filterStatus !== '' ? filterStatus : undefined
    }
    const rows = await driverAllList(filterQuery)

    setRows(rows.map(({ additionalData, ...item }) => ({ ...item, ...additionalData })))
  }, [filterStatus])

  useEffect(() => { init() }, [init])

  const columns: Column[] = []

  columns.push({
    id: ['firstName', 'secondName', 'familyName'],
    label: 'ФИО',
    format: (firstName, secondName, familyName) => <>{personName({ firstName, secondName, familyName } as Person)}</>
  })

  columns.push({
    id: 'permitNumber',
    label: 'ВУ',
    format: (permitNumber) => {
      return <>{permitNumber}</>
    }
  })

  columns.push({
    id: 'phone',
    label: 'Номер телефона',
    format: (phone) => <Box sx={{ whiteSpace: 'nowrap' }}>{ formatPhone(phone as string) }</Box>
  })

  columns.push({
    id: 'passportSeriesAndNum',
    label: 'Серия и номер паспорта',
    format: (passportSeriesAndNum) => {
      return <>{passportSeriesAndNum}</>
    }
  })

  columns.push({
    id: ['orgShortName', 'ownerId'],
    label: 'Перевозчик',
    format: (orgShortName, ownerId) => {
      return <Link to={`${links.REGISTRY_CARRIER_PAGE}/${ownerId}`}>{orgShortName as string}</Link>
    }
  })

  columns.push({
    id: ['id', 'ownerId'],
    label: '',
    align: 'right',
    format: (id, ownerId) => <ActionsMenu key={`${id}`} actions={
      [
        {
          caption: 'Посмотреть',
          onClick: () => navigate(`${links.REGISTRY_CARRIER_PAGE}/${ownerId}/drivers/${id}`),
          icon: <VisibilityIcon />
        }
      ]
    } />
  })
  return <>
    <div className='registryDrivers'>
      <NavigatePanel
        search={{
          rows: rows === undefined ? [] : rows,
          fields: [
            'phone',
            'firstName',
            'secondName',
            'familyName',
            'orgShortName',
            'passportSeriesAndNum',
            'permitNumber'
          ],
          onChange: setSearched
        }}
        title='Водители'
        breadcrumbs={{
          items: routesMap.getBreadcrumbs(links.REGISTRY_DRIVERS_PAGE)
        }}
        tabs={{
          items: [...statusMap].map(([value, label]) => ({ label, value })),
          value: filterStatus,
          onChange: value => navigate(links.REGISTRY_DRIVERS_PAGE + (value === '' ? '' : `/${value}`)),
          scrollable: true
        }}
      />
      <div className='registryDrivers__content'>
        <div className='registryDrivers__content_table'>
          <DataTable
            columns={columns}
            rows = { (start, end) => rows === undefined
              ? []
              : rows
                .filter(row => searched === undefined ? true : searched.includes(row.id)).slice(start, end) }
            rowsCount = { rows === undefined ? 0 : (searched === undefined ? rows.length : searched.length) }
          />
        </div>
      </div>
    </div>
  </>
}

export default RegistryDrivers
