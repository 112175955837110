import { TS_IN_SECONDS } from 'constants/Time'
import FormatDate from 'util/FormatDate'
import { getOffsetTZ } from './date'

export default function TsToFormatDate (ts: number | undefined, formate: string): string {
  if (ts === undefined) {
    return ''
  }

  const date = new Date((ts + getOffsetTZ()) * TS_IN_SECONDS)
  return FormatDate(date, formate)
}
