import { Button, Stack } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useMainRoutes } from 'routes'
import Modal from 'ui/Modal'

interface Params {
  open: boolean
  onClose: () => void
}

export default function SignSuccessModal ({ open, onClose }: Params) {
  const { links } = useMainRoutes()
  const navigate = useNavigate()

  return <Modal
    title='Подписание завершено'
    open={open}
    onClose = {onClose}
    content= {<Stack sx={{ minWidth: '500px' }}>
      Документы были успешно подписаны и отправлены перевозчикам.
    </Stack>}
    actions={<>
      <Button
        color="secondary"
        variant="outlined"
        onClick={() => {
          navigate(`${links.DISPATCHER_DOCUMENTS_ACTS_PAGE}/signed_by_customer`)
          onClose()
        }}
      >Посмотреть подписанные УПД</Button>
      <Button variant="contained" onClick={onClose}>Закрыть</Button>
    </>}
  />
}
