import './styles.sass'
import { useEffect, useMemo, useRef } from 'react'
import { Paper, Stack, Table, TableBody, TableCell, TableContainer, TableRow, Typography, Box } from '@mui/material'
import { Row } from 'ui/DataTable'
import { StepProps } from 'ui/ModalSigning'
import formatWeight from 'util/formatWeight'
import TsToFormatDate from 'common/CarrierBids/TsToFormatDate'
import { shippingList, ShippingTruckRunStatus } from 'api/shipping'
import { ActWizardData } from './index'
import addressName from 'util/addressName'
import { useAuthContext } from 'AuthContext'
import { TypeTaxation } from 'api/organization'

export default function ShippingList ({ isActive, data }: StepProps) {
  const once = useRef(true)
  const { profile } = useAuthContext()
  const fee = profile?.organization?.typeTaxation === TypeTaxation.vat ? 20 : 0

  useEffect(() => {
    if (isActive && once.current) {
      once.current = false
      const d = data as ActWizardData

      const filters = {
        truckRunIds: d.onlyTruckRunIds && d.onlyTruckRunIds?.length > 0 ? d.onlyTruckRunIds : undefined,
        truckRunStatus: [ShippingTruckRunStatus.completed]
      }

      shippingList({ filters, includeNewActs: true })
        .then(({ list }) => d.onLoad(list))
    }
  }, [data, isActive])

  const completedRows: Row[] = useMemo(() => {
    const d = data as ActWizardData

    if (d === undefined || d.shippingList === undefined) {
      return []
    }

    return d.shippingList.flatMap((item, i) => {
      const truckRuns = item.truckRuns.map(truckRun => ({
        id: truckRun.id,
        num: truckRun.num,
        loadingTs: truckRun.loadingTs,
        weight: truckRun.weight ?? 0,
        finalWeight: truckRun.finalWeight ?? 0,
        status: truckRun.status,
        shortageCost: truckRun.shortage.cost,
        shortageWeight: truckRun.shortage.weight,
        cost: truckRun.cost === undefined ? 0 : (truckRun.cost - truckRun.shortage.cost) * (100 + fee) / 100,
        costCorrection: truckRun.costCorrection ?? 0,
        consignee: truckRun.consignee,
        unloadingAddress: truckRun.unloadingAddress
      }))

      const groups = [...new Map(item.truckRuns.map(({ unloadingAddress, consignee }) =>
        [`${unloadingAddress?.id}:${consignee?.id}`, {
          unloadingAddress,
          consignee
        }])).values()]

      return groups.map(({ unloadingAddress, consignee }, j) => {
        const truckRunsFiltered = truckRuns.filter((item) =>
          item.unloadingAddress?.id === unloadingAddress?.id && item.consignee?.id === consignee?.id)

        return {
          id: `shipping_${i}:${j}`,
          bidDesc: item.bid.num,
          bidId: item.bid.id,
          customer: item.bid.customer.fullName,
          unloadingAddress,
          consignee,
          // totalCost: truckRunsFiltered.reduce((result, { cost }) => result + cost, 0),
          truckRuns: truckRunsFiltered as Row[],
          totalFinalWeight: truckRunsFiltered.reduce((result, { finalWeight }) => result + finalWeight, 0),
          totalShortageWeight: truckRunsFiltered.reduce((result, { shortageWeight }) => result + shortageWeight, 0)
        }
      })
    })
  }, [data, fee])

  if (!isActive) {
    return <></>
  }

  if (completedRows.length === 0) {
    return <Typography fontSize='14px' fontWeight={400} color='#B2B2B2' mb='2em'>
    В настоящий момент отсутствуют рейсы в статусе “Завершен”, которые можно добавить в УПД.
    </Typography>
  }

  return (
    <>
      <Typography fontSize='14px' fontWeight={400} color='#B2B2B2' mb='2em'>
      По данным рейсам будут сфомированы документы, подпишите их своей ЭЦП и отправьте  на подпись Заказчику Перевозки. Чтобы продолжить, нажмите “Сформировать документы”.
      </Typography>
      <Typography fontWeight={700}>Добавить в УПД:</Typography>
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table aria-label='caption table' className='modal_act_step_1'>
          {completedRows.map((row) => (<TableBody key={row.id}>
            <TableRow key='head1' className='modal_act_step_1_desc_row'>
              <TableCell colSpan={5} sx={{ border: 'none' }}>
                <Stack direction='row' gap={2}>
                  <Typography fontWeight={600} fontSize='14px'>Перевозка {row.bidDesc}</Typography>
                  <Typography fontWeight={400} fontSize='14px' color='#B2B2B2'>{row.customer}</Typography>
                  {row.unloadingAddress &&
                    <Typography fontWeight={400} fontSize='14px' color='#B2B2B2'>Переадресация: {addressName(row.unloadingAddress)}</Typography>
                  }
                </Stack>
              </TableCell>
            </TableRow>
            <TableRow key='head2'>
              <TableCell><Typography fontWeight={400} fontSize='14px' color='#B2B2B2'>Номер рейса</Typography></TableCell>
              <TableCell><Typography fontWeight={400} fontSize='14px' color='#B2B2B2'>Вес погрузки</Typography></TableCell>
              <TableCell><Typography fontWeight={400} fontSize='14px' color='#B2B2B2'>Вес разгрузки</Typography></TableCell>
              <TableCell><Typography fontWeight={400} fontSize='14px' color='#B2B2B2'>Недостача</Typography></TableCell>
              {/* <TableCell><Typography fontWeight={400} fontSize='14px' color='#B2B2B2'>Сумма</Typography></TableCell> */}
            </TableRow>
            { row.truckRuns.map((item: Row) => (<TableRow key={item.id}>
              <TableCell>{item.num} от {TsToFormatDate(item.loadingTs, 'dd.MM.yyyy')}</TableCell>
              <TableCell>{formatWeight(item.weight)}</TableCell>
              <TableCell>{formatWeight(item.finalWeight)}</TableCell>
              <TableCell>{formatWeight(item.shortageWeight)}</TableCell>
              {/* <TableCell>{rub(item.cost)}</TableCell> */}
            </TableRow>)) }
            {/* <TableRow key='total'>
              <TableCell colSpan={3} sx={{ borderBottom: 'none' }}><Box sx={{ display: 'flex', justifyContent: 'right', mr: 1 }}>Итого</Box></TableCell>
              <TableCell sx={{ borderBottom: 'none' }}>{rub(row.totalCost)}</TableCell>
            </TableRow> */}
            <TableRow>
              <TableCell colSpan={3} sx={{ borderBottom: 'none' }}><Box sx={{ display: 'flex', justifyContent: 'right', mr: 1 }}>Итого вывезено тон</Box></TableCell>
              <TableCell sx={{ borderBottom: 'none' }}>{formatWeight(row.totalFinalWeight)}</TableCell>
            </TableRow>
            { row.totalShortageWeight > 0 && <TableRow>
              <TableCell colSpan={3} sx={{ borderBottom: 'none' }}><Box sx={{ display: 'flex', justifyContent: 'right', mr: 1 }}>Общая недостача</Box></TableCell>
              <TableCell sx={{ borderBottom: 'none' }}>{formatWeight(row.totalShortageWeight)}</TableCell>
            </TableRow>}
          </TableBody>))}
        </Table>
      </TableContainer>
    </>
  )
}
