import './styles.sass'
import { Box } from '@mui/material'
import { useEventContext } from 'EventContext'
import Notify from 'ui/Notify'

export default function Connection () {
  const { sessionId, heartbeat, currentEvent } = useEventContext()

  return <Box sx={{ position: 'absolute', top: '5px' }} key={`connection_${heartbeat}`}>
    <>
    {sessionId
      ? <svg width='16' height='16' viewBox='0 0 16 16'>
        <circle cx='10' cy='6' fill='none' r='8' stroke='#C5E1A5' strokeWidth='1'>
          <animate attributeName='r' from='3' to='8' dur='0.5s' begin='0s' repeatCount='1' fill='freeze'/>
          <animate attributeName='opacity' from='1' to='0' dur='0.5s' begin='0s' repeatCount='1' fill='freeze'/>
        </circle>
        <circle cx='10' cy='6' fill='#C5E1A5' r='3'>
          <animate attributeName='r' from='0' to='3' dur='0.5s' begin='0s' repeatCount='1' fill='freeze'/>
        </circle>
      </svg>
      : <svg key='disconnected' width='16' height='16' viewBox='0 0 16 16'>
        <circle cx='10' cy='6' fill='#F48FB1' r='3'/>
      </svg>
    }
    <Notify event={currentEvent} />
    </>
  </Box>
}
