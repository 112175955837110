import { Box, Button, IconButton, Stack, Typography } from '@mui/material'
import { Bid, bidGet } from 'api/bid'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams, useNavigate, Navigate, useSearchParams } from 'react-router-dom'
import SendIcon from '@mui/icons-material/Send'
import './styles.sass'
// import formatDate from 'common/CarrierBids/TsToFormatDate'
import FreeSpaceItem, { Reserve } from '../../ui/FreeSpaceItem'
import RespondModal from './RespondModal'
import { isId } from 'api/Id'
import { useMainRoutes } from 'routes'
import NavigatePanel from 'ui/NavigatePanel'
import { Params as TabsRowParams } from 'ui/NavigatePanel/TabsRow'
import { Close } from '@mui/icons-material'
import InfoCard from 'ui/InfoCard'
import Map from 'ui/Osm'
import rub from 'util/formatRub'
import FullAddress from 'ui/FullAddress'
import formateParties from 'util/formateParties'
import formatCargo from 'util/formatCargo'
import formatScaleLength from 'util/formatScaleLength'
import formatScaleMaxLoad from 'util/formatScaleMaxLoad'
import { toKilometers } from 'util/distance'
import { toTon } from 'util/weight'
import CarrierContractWizard, { Customer } from 'ui/CarrierContractWizard'
import { informationGet, Information } from 'api/information'
// import Modal from 'ui/Modal'
// import formatPhone from 'util/formatPhone'
import formatWorkSchedule from 'util/formatWorkSchedule'
import formatHeightRestriction from 'util/formatHeightRestriction'
import { isEnum } from 'util/isEnum'
import TruckRunsList from 'pages/CarrierBid/TruckRunsList'

export enum Tabs {
  basicInfo = 'basic_info',
  truckRuns = 'truck_runs',
  truckRunsRedirected = 'truck_runs_redirected'
}

const TABS_ITEMS = [
  { label: 'О перевозке', value: Tabs.basicInfo },
  { label: 'Рейсы', value: Tabs.truckRuns }
]
// const TAB_TRUCK_RUNS_REDIRECTED = { label: 'Переадресованные рейсы', value: Tabs.truckRunsRedirected }

export default function CarrierBid () {
  const [data, setData] = useState<Bid>()
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [found, setFound] = useState<boolean>()
  const { id } = useParams()
  const navigate = useNavigate()
  const { links, routesMap } = useMainRoutes()
  const [reserveSeat, setReserveSeat] = useState<Reserve>()
  const [contractModal, setContractModal] = useState<Customer>()

  const [/* information */, setInformation] = useState<Information>({})
  const [tabs, setTabs] = useState<TabsRowParams['items']>()
  const [searchParams] = useSearchParams()
  const [currentTab, setCurrentTab] = useState<Tabs>(Tabs.basicInfo)

  const isRelay = useMemo(() => !!data?.carrier, [data])

  const breadcrumbsItems = useMemo(
    () => routesMap.getBreadcrumbs(isRelay ? links.CARRIER_TRIPS_BID_PAGE : links.CARRIER_BIDS_PAGE),
    [links, routesMap, isRelay]
  )

  /*
  const currentTab = useMemo(() => {
    const tab = searchParams.get('tab')
    return isEnum(Tabs)(tab) ? tab : Tabs.basicInfo
  }, [searchParams])
  */

  const title = useMemo(
    () => `Перевозка ${data?.num}`,
    [data]
  )

  const changeTab = useCallback(async (value?: string | null) => {
    const tab = isEnum(Tabs)(value) ? value : Tabs.basicInfo
    navigate(tab === Tabs.basicInfo ? `${links.CARRIER_TRIPS_BID_PAGE}/${id}` : `?tab=${value}`)
    setCurrentTab(tab)
  }, [id, links.CARRIER_TRIPS_BID_PAGE, navigate])

  useEffect(() => { changeTab(searchParams.get('tab')) }, [searchParams, changeTab])

  useEffect(() => {
    if (found !== undefined) {
      return
    }

    if (!isId(id)) {
      setFound(false)
      return
    }

    bidGet(id).then(result => {
      if (result === null) {
        setFound(false)
      } else {
        setData(result)
        setTabs(result.carrier ? TABS_ITEMS : undefined)
        setFound(true)
      }
    })
  }, [found, id, changeTab, searchParams, currentTab])

  useEffect(() => {
    informationGet().then(setInformation)
  }, [])

  const handleBack = () => {
    navigate(links.CARRIER_BIDS_PAGE)
  }

  const handleReserve = useCallback((reserve: Reserve) => setReserveSeat(reserve), [])

  if (found === undefined) {
    return <></>
  }

  if (!found) {
    return <Navigate to={links.HOME_PAGE} />
  }

  return (
    <>
      <RespondModal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false)
          setReserveSeat(undefined)
        }}
        openContractModal={setContractModal}
        bid={data}
        reserveSeat={reserveSeat}
      />
      <CarrierContractWizard
        open={contractModal !== undefined}
        customer={contractModal}
        onClose={() => setContractModal(undefined)}
      />
      {/* <Modal
        maxWidth='sm'
        title='Необходимо заключить договор!'
        onClose={() => setContractModal(undefined)}
        open={contractModal !== undefined}
        content={
          <Box >
            <Typography>Для того, чтобы откликнуться на данную заявку, вам необходимо заключить договор с {contractModal?.fullName}.</Typography>
            { information.dispatcherPhone
              ? <Typography>Обратитесь к диспетчеру по телефону: {formatPhone(information.dispatcherPhone)}</Typography>
              : <Typography>Обратитесь к диспетчеру</Typography>}
          </Box>
        }
        actions={<></>}
      /> */}
      <div className='carrierBid'>
        <div className='carrierBid_content' style={ currentTab === Tabs.basicInfo ? undefined : { width: '100%' }}>
          <NavigatePanel
            breadcrumbs={{
              defaultItems: breadcrumbsItems,
              items: [{ title }]
            }}
            title={title}
            actions={<IconButton onClick={() => handleBack()}>
              <Close />
            </IconButton>}
            info ={ isRelay
              ? <Stack direction='column' m='0 1em' gap={1}>
                  <Box bgcolor='#624CE0' borderRadius='4px' padding={1} color='#FFFFFF'>
                    Заявка передана перевозчику
                  </Box>
                  { data?.blockedManualFill && <Box bgcolor='#FFA800' borderRadius='4px' padding={1} color='#FFFFFF'>
                  <Typography fontWeight={600} fontSize='16px' >Ограничено создание рейсов</Typography>
                  Комментарий диспетчера: {data.blockedManualFillComment ?? ''}
                </Box>}
              </Stack>
              : undefined
            }
            tabs={ tabs ? { items: tabs, value: currentTab, onChange: changeTab } : undefined }
          />
          <div className='carrierBid_content_info'>
            { currentTab === Tabs.basicInfo && data && <>
              { data.freeSpace.length > 0 && <InfoCard className='carrierBid_content_infoCard freeSpaceCard'>
                  <>
                    <Typography fontSize='18px' fontWeight={650} mb='1em'>Выберите машино-места</Typography>
                    <div className='freeSpaceCard_row'>
                    { data.freeSpace.map((v, i) => <FreeSpaceItem data={v} key={i} className='freeSpaceCard_row_item' onReserve={handleReserve} reserve={reserveSeat} />) }
                    </div>
                    { !!reserveSeat && <Button variant='contained' fullWidth className='carrierBid_content_buttonResponse' sx={{ mt: '1em' }} onClick={() => setModalOpen(true)} >
                      Откликнуться
                      <SendIcon style={{ marginLeft: '10px' }}/>
                    </Button>}
                  </>
              </InfoCard> }
              <InfoCard className='carrierBid_content_infoCard'
                title='Маршрут'
                items={[
                  { label: 'Место погрузки:', value: <FullAddress address={data.loadingAddress} /> },
                  { label: 'Место разгрузки:', value: <FullAddress address={data.unloadingAddress} /> }
                ]}
              />
              <InfoCard className='carrierBid_content_infoCard'
                title='Информация'
                items={[
                  { label: 'Тип ТС:', value: data.typeVehicle.length === 0 ? 'Любой' : data.typeVehicle.map(item => item.name).join(', ') },
                  // { label: 'Дата погрузки:', value: formatDate(data.beginTs, 'dd MMMM yyyy') },
                  { label: 'Общий вес перевозки, тн:', value: `${toTon(data.totalWeight)} тн` },
                  { label: 'Стоимость груза за 1 тн:', value: rub(data.costCargo) },
                  { label: 'Груз:', value: formatCargo(data.cargo) },
                  { label: 'Расстояние:', value: `${toKilometers(data.distance)} км` },
                  { label: 'Стоимость перевозки тн/км:', value: rub(data.costTonKm) },
                  { label: 'Cтоимость перевозки 1 тн:', value: rub(data.costTon) },
                  { label: 'Заказчик перевозки:', value: data.customer.fullName },
                  { label: 'Грузим +10%:', value: data.plusTen ? 'Да' : 'Нет' }
                ]}
              />
              <InfoCard className='carrierBid_content_infoCard'
                title='Информация о пункте погрузки'
                items={[
                  { label: 'Режим работы:', value: formatWorkSchedule(data.loadingAddress.schedule) },
                  { label: 'Способ погрузки:', value: data.loadingMethod },
                  { label: 'Длина весов:', value: formatScaleLength(data.loadingWeighing) },
                  { label: 'Грузоподъемность весов:', value: formatScaleMaxLoad(data.loadingWeighing) },
                  { label: 'Высота ТС:', value: formatHeightRestriction(data.loadingWeighing) },
                  { label: 'Грузоотправитель', value: formateParties(data.consignor) }
                ]} />
              <InfoCard className='carrierBid_content_infoCard'
                title='Информация о пункте разгрузки'
                items={[
                  { label: 'Режим работы:', value: formatWorkSchedule(data.unloadingAddress.schedule) },
                  { label: 'Способ разгрузки:', value: data.unloadingMethod },
                  { label: 'Длина весов:', value: formatScaleLength(data.unloadingWeighing) },
                  { label: 'Грузоподъемность весов:', value: formatScaleMaxLoad(data.unloadingWeighing) },
                  { label: 'Высота ТС:', value: formatHeightRestriction(data.unloadingWeighing) },
                  { label: 'Грузополучатель', value: formateParties(data.consignee) }
                ]}
              />
            </>}
            { [Tabs.truckRuns, Tabs.truckRunsRedirected].includes(currentTab) && <TruckRunsList
              bid={data}
              onlyRedirected={currentTab === Tabs.truckRunsRedirected}
              openContractModal={setContractModal}
            /> }
          </div>
        </div>
        { currentTab === Tabs.basicInfo && <div className='carrierBid_map'>
        { data && <Map route={{ from: data.loadingAddress.coordinates, to: data.unloadingAddress.coordinates }} /> }
        </div> }
      </div>
    </>
  )
}
