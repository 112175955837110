import { useEffect, useRef, useState } from 'react'
import './styles.sass'
import { StepProps } from 'ui/ModalSigning'
import { ActDispatcherRejectionWizardData } from './index'
import Alert from '@mui/material/Alert'
import { Act, actAllGet } from 'api/act'
import ActPreview from 'pages/ActPreview'

export default function ActInfo ({ isActive, data }: StepProps) {
  const [error, setError] = useState('')
  const [act, setAct] = useState<Act>()
  const once = useRef(true)

  const d = data as ActDispatcherRejectionWizardData

  useEffect(() => {
    if (!isActive) {
      return
    }
    if (!d.actId) {
      setError('Идентификатор акта не определен')
    }
    if (d.actId && once.current) {
      once.current = false
      actAllGet(d.actId)
        .then(a => setAct(a ?? undefined))
    }
  }, [isActive, d.actId])

  if (!isActive) {
    return <></>
  }

  return (<>
    <ActPreview acts={act ? [act] : undefined}/>
    { error && <Alert severity="error">{error}</Alert> }
  </>)
}
