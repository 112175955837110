import './styles.sass'
import clsx from 'clsx'
import CollapseBox, { CollapseBoxParams } from 'ui/CollapseBox'

export default function CollapseBoxTruckRun ({ className, children, ...props }: CollapseBoxParams) {
  return (
    <CollapseBox className={clsx('collapseBoxTruckRun', className)} {...props} >
        {children}
    </CollapseBox>
  )
}
