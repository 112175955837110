import './styles.sass'
import { shippingAllList, ShippingTruckRunStatus, ShippingAll as Shipping } from 'api/shipping'
import { ReactNode, useCallback, useEffect, useState } from 'react'
import NavigatePanel from 'ui/NavigatePanel'
import { useMainRoutes } from 'routes'
import { Box, Button, Stack, Typography } from '@mui/material'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import Osm from 'ui/Osm'
import InfoCell, { InfoCellProps } from 'ui/InfoCell'
import SquareButton from 'ui/SquareButton'
import { useNavigate } from 'react-router-dom'
import formatDate from 'common/CarrierBids/TsToFormatDate'
import formatCargo from 'util/formatCargo'
import InfoCellValue from 'ui/InfoCell/InfoCellValue'
import formatWeight from 'util/formatWeight'
import { toKilometers } from 'util/distance'
import { toTon } from 'util/weight'
import RouteInfo from 'ui/RouteInfo'
import { personName } from 'util/personName'
import Badge from 'ui/Badge'
import InfoCard from 'ui/InfoCard'
import DatePicker, { RangeDate } from 'ui/DatePicker'
import { Clear } from '@mui/icons-material'
import SelectBillNumber from 'ui/SelectBillNumber'
import bidAutoFill from 'common/bidAutoFillStatus'

enum Filter {
  active = 'active',
  blackList = 'black_list',
  archive = 'archive'
}

function getNameByFilter (filter: Filter, count?: number): ReactNode {
  if (filter === Filter.active) {
    return 'Активные'
  } else if (filter === Filter.archive) {
    return 'Архив'
  } else if (filter === Filter.blackList) {
    return <Stack direction='row' spacing={1}><span>Черный список</span><Badge count={count} /></Stack>
  }

  return ''
}

const getDescriptions = (data: Shipping): InfoCellProps[] => {
  const { bid, resultsByStatus } = data

  const remainingWeight = resultsByStatus?.remainingWeight ?? 0

  return [
    { label: 'Груз', value: formatCargo(bid.cargo) },
    { label: 'Общий вес перевозки, тн', value: `${toTon(bid.totalWeight)} тн` },
    { label: 'Осталось перевезти', value: `${formatWeight(remainingWeight)}` },
    { label: 'Расстояние', value: `${toKilometers(bid.distance)} км` },
    { label: 'Дата перевозки', value: `${formatDate(bid.beginTs)} - ${formatDate(bid.endTs)}` }
  ]
}

const getInfoData = ({ resultsByStatus }: Shipping): InfoCellProps[] => {
  const getStatData = (key: ShippingTruckRunStatus) => {
    if (resultsByStatus && resultsByStatus[key]) {
      return resultsByStatus[key]
    }

    return { totalWeight: 0, count: 0 }
  }

  const plan = getStatData(ShippingTruckRunStatus.confirmed)
  const way = getStatData(ShippingTruckRunStatus.way)
  const arrived = getStatData(ShippingTruckRunStatus.arrived)
  const completed = getStatData(ShippingTruckRunStatus.completed)
  const outdated = getStatData(ShippingTruckRunStatus.archiveOutdated)
  const problem = getStatData(ShippingTruckRunStatus.archiveProblem)
  const archive = getStatData(ShippingTruckRunStatus.archiveAct)

  return [
    { label: 'Запланировано', value: <InfoCellValue value1={plan.count} value2={formatWeight(plan.totalWeight)} /> },
    {
      label: 'В пути',
      value: <InfoCellValue value1={way.count} value2={formatWeight(way.totalWeight)} dotColor={
        way.count ? '#ffa800' : undefined
      }/>
    },
    {
      label: 'Выгружено',
      value: <InfoCellValue value1={arrived.count} value2={formatWeight(arrived.totalWeight)} dotColor={
        arrived.count ? '#ffa800' : undefined
      }
      />
    },
    { label: 'Завершено', value: <InfoCellValue value1={completed.count} value2={formatWeight(completed.totalWeight)} /> },
    { label: 'Архив', value: <InfoCellValue value1={archive.count} value2={formatWeight(archive.totalWeight)} /> },
    { label: 'Просрочено', value: <InfoCellValue value1={outdated.count} value2={formatWeight(outdated.totalWeight)} /> },
    { label: 'Завершено диспетчером', value: <InfoCellValue value1={problem.count} value2={formatWeight(problem.totalWeight)} /> }
  ]
}

const DispatcherTripsItems = ({ data }: { data: Shipping }) => {
  const { links } = useMainRoutes()
  const navigate = useNavigate()

  return (<div className='dispatcherTrips__card'>
    <div className='dispatcherTrips__card_body'>
      { data.hasBlackList &&
        <Box bgcolor='#EE6A5F' borderRadius='4px' m='1em 1.5em' mb={0} padding={1} color='#FFFFFF'>
          Перевозка содержит рейс(ы) в которых участвуют Водитель / ТС из черного списка
        </Box>}
      { data.truckRuns.some(({ unloadingAddress }) => unloadingAddress) &&
        <Box bgcolor='#ffa800' borderRadius='4px' m='1em 1.5em' mb={0} padding={1} color='#FFFFFF'>
          Перевозка содержит рейс(ы), которые были переадресованы
        </Box>}
      { data.bid.carrier && <Box bgcolor='#624CE0' borderRadius='4px' m='1em 1.5em' mb={0} padding={1} color='#FFFFFF'>
        Заявка передана перевозчику <b>{data.bid.carrier.organization?.shortName}</b> ({bidAutoFill(data.bid.autoFill)})
      </Box>}
      <div className='dispatcherTrips__card_body_title'>
        <Stack direction='row' justifyContent='space-between'>
          <Box>
            <Typography fontWeight={600} fontSize='16px'>Перевозка {data.bid.num}</Typography>
            <RouteInfo loadingAddress={data.bid.loadingAddress} unloadingAddress={data.bid.unloadingAddress} fullAddress />
          </Box>
          <SquareButton
            size='small'
            variant='contained'
            onClick={() => navigate(`${links.DISPATCHER_BID_PAGE}/${data.bid.id}`)}
          >
            <MoreHorizIcon style={{ color: '#ffffff', width: '16px' }} />
          </SquareButton>
        </Stack>
        { data.bid.forwarders && data.bid.forwarders.length > 0 && <Stack direction='row' spacing={1} mt='6px'>
          <Typography fontSize='13px' fontWeight={600} >Экспедитор на сегодня</Typography>
          <Typography fontSize='13px' fontWeight={400} color='#B2B2B2' >{personName(data.bid.forwarders[0])}</Typography>
          <Typography fontSize='13px' fontWeight={400} color='#B2B2B2' >|</Typography>
          <Typography fontSize='13px' fontWeight={400} color='#B2B2B2' >{data.bid.forwarders[0].phone}</Typography>
          { data.bid.forwarders.length > 1 && <Typography fontSize='13px' fontWeight={400} color='#B2B2B2' >+{data.bid.forwarders.length - 1}</Typography> }
        </Stack> }
      </div>
      <div className='dispatcherTrips__card_body_info'>
        { getDescriptions(data).map((item, idx) => <InfoCell {...item} key={idx} />)}
      </div>
      <div className='dispatcherTrips__card_body_info'>
        { getInfoData(data).map((item, idx) => <InfoCell {...item} key={idx} />)}
      </div>
    </div>
    <div className='dispatcherTrips__card_map'>
      <Osm zoom={10} route={{ from: data.bid.loadingAddress.coordinates, to: data.bid.unloadingAddress.coordinates }} staticMap/>
    </div>
  </div>)
}

export default function DispatcherTrips () {
  const [list, setList] = useState<Shipping[]>()
  const [filter, setFilter] = useState<Filter>(Filter.active)
  const { links, routesMap } = useMainRoutes()
  const [blackListCount, setBlackListCount] = useState<number>(0)
  const [filterBillTs, setFilterBillTs] = useState<RangeDate>()
  const [filterBillNumber, setFilterBillNumber] = useState<string>()
  const [refresh, setRefresh] = useState(0)

  const loadList = useCallback((filter: Filter) => {
    const active = [
      ShippingTruckRunStatus.confirmed,
      ShippingTruckRunStatus.way,
      ShippingTruckRunStatus.arrived,
      ShippingTruckRunStatus.completed,
      ShippingTruckRunStatus.archiveOutdated,
      ShippingTruckRunStatus.archiveProblem
    ]

    const archive = [
      ShippingTruckRunStatus.archiveRefuse,
      ShippingTruckRunStatus.archiveReject,
      ShippingTruckRunStatus.archiveAct,
      ShippingTruckRunStatus.archiveProblem
    ]

    // необходимо фильтровать на бэке по статусам
    // нельзя просто запросить весь список так как он может быть просто огромным
    const truckRunStatus = [Filter.active, Filter.blackList].includes(filter) ? active : archive

    shippingAllList({
      filters: {
        truckRunStatus,
        billNumber: filterBillNumber,
        billTsFrom: filterBillTs?.startTs,
        billTsTo: filterBillTs?.endTs
      },
      freeSpace: true,
      includeBlackList: true,
      includeExtStatData: true
    }).then(
      (result) => {
        if ([Filter.active, Filter.blackList].includes(filter)) {
          setBlackListCount(
            result.reduce((prev, cur) => {
              if (cur.hasBlackList) {
                return prev + 1
              }

              return prev
            }, 0)
          )
        }

        setList(
          filter === Filter.blackList
            ? result.filter(({ hasBlackList }) => hasBlackList)
            : result
        )
      }
    )
  }, [filterBillNumber, filterBillTs])

  useEffect(() => setRefresh((prev) => prev + 1), [filterBillTs, filterBillNumber, filter])

  useEffect(() => {
    loadList(filter)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh])

  if (list === undefined) {
    return <></>
  }

  return (
    <>
      <div className='dispatcherTrips'>
        <NavigatePanel
          title='Перевозки'
          breadcrumbs={{
            items: routesMap.getBreadcrumbs(links.DISPATCHER_SHIPPING_PAGE)
          }}
          tabs={{
            items: Object.values(Filter).map(item => ({ label: getNameByFilter(item, blackListCount), value: `${item}` })),
            value: filter,
            onChange: value => setFilter(value as Filter)
          }}
        />
        <div className='dispatcherTrips__content'>
          <div className='dispatcherTrips__content__items'>
            <InfoCard title="Фильтр по рейсам" style={{ marginBottom: '1em' }}>
              <Stack direction="row" alignItems="center" width="100%" gap={2}>
                <Stack direction="row" alignItems="center">
                  <DatePicker
                    label='Укажите диапазон для даты ТТН'
                    placeholder='Укажите диапазон для даты ТТН'
                    range={filterBillTs}
                    onChange={(startTs, endTs) => {
                      if (startTs !== undefined && endTs !== undefined) {
                        setFilterBillTs(startTs < endTs ? { startTs, endTs } : { startTs: endTs, endTs: startTs })
                      }
                    }}
                    rangeDate
                    width='22em'
                  />
                  <Button
                    variant='outlined'
                    color='secondary'
                    onClick={() => setFilterBillTs(undefined)}
                    sx={{ minWidth: '40px', minHeight: '40px', maxHeight: '40px', marginTop: '8px' }}
                  ><Clear /></Button>
                </Stack>
                <SelectBillNumber
                  label='Укажите номер ТТН'
                  placeholder='Укажите номер ТТН'
                  value={filterBillNumber}
                  onChange={setFilterBillNumber}
                  width="100%"
                />
                <Button
                  variant='outlined'
                  color='secondary'
                  onClick={() => {
                    setFilterBillTs(undefined)
                    setFilterBillNumber(undefined)
                  }}
                  sx={{ minWidth: '110px', minHeight: '40px', maxHeight: '40px', marginTop: '8px' }}
                  endIcon={<Clear />}
                >Сбросить</Button>
              </Stack>
            </InfoCard>
            { list.length > 0
              ? list.map((data) => <DispatcherTripsItems data={data} key={data.bid.id} />)
              : <Box sx={{
                border: '#EBEBEB 1px solid',
                borderRadius: '8px',
                padding: '32px 24px',
                background: '#fff'
              }}>
                { filter === Filter.active ? 'Активных' : 'Архивных' } перевозок не найдено
              </Box>
            }
          </div>
        </div>
      </div>
    </>
  )
}
