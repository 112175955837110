import { Button, CircularProgress } from '@mui/material'
import { driverAllList, DriverStatus } from 'api/driver'
import { XLSXTableRow } from 'api/media'
import { ProfileAll } from 'api/profile'
import { ReactNode, useState } from 'react'
import { slug } from 'ui/DownloadZipButton'
import downloadXLSXFile from 'util/generateXLSXFile'
import { personName } from 'util/personName'
import trailerName from 'util/trailerName'
import vehicleName from 'util/vehicleName'

export interface Params {
  profile?: ProfileAll
  children: ReactNode
}

interface RegistryEntrie {
  driverFio: string
  phone: string
  permitNumber: string

  vehicleBrand?: string
  vehicleModel?: string
  vehicleNumber?: string
  vehicleCertificate?: string
  vehicleCertificateIndex?: string
  trailerBrand?: string
  trailerModel?: string
  trailerNumber?: string
  trailerCertificate?: string
  trailerCertificateIndex?: string
}

export default function CreateRegistryDriversAndVehicleButton ({ profile, children }: Params) {
  const [createRegistryProgress, setCreateRegistryProgress] = useState(false)

  const createRegistryDriversAndVehicle = async () => {
    if (!profile) {
      return
    }

    setCreateRegistryProgress(false)
    try {
      const list = await driverAllList({
        owner: profile.phone,
        status: DriverStatus.active,
        includeVehicleTrailerData: true
      })

      const entries = list.reduce((prev, cur) => {
        const { vehicleTrailer, permitNumber, phone, ...driver } = cur

        const driverFio = personName(driver)

        const res: RegistryEntrie[] = vehicleTrailer?.map(item => {
          return {
            driverFio,
            phone,
            permitNumber,

            vehicleBrand: item.vehicle?.brand,
            vehicleModel: item.vehicle?.model,
            vehicleNumber: vehicleName(item.vehicle, 'short'),
            vehicleCertificate: item.vehicle?.certificate,
            vehicleCertificateIndex: item.vehicle?.certificate,

            trailerBrand: item.trailer?.brand,
            trailerModel: item.trailer?.model,
            trailerNumber: trailerName(item.trailer, { placeholder: '' }),
            trailerCertificate: item.trailer?.certificate,
            trailerCertificateIndex: item.trailer?.certificate
          }
        }) ?? []

        if (res.length === 0) {
          return [...prev, {
            driverFio,
            phone,
            permitNumber
          }]
        }

        return [...prev, ...res]
      }, [] as RegistryEntrie[])

      const rowsWithTitle: XLSXTableRow[] = entries.map(({
        driverFio, phone, permitNumber,
        vehicleBrand, vehicleModel, vehicleNumber,
        vehicleCertificate, vehicleCertificateIndex,
        trailerBrand, trailerModel, trailerNumber,
        trailerCertificate, trailerCertificateIndex
      }) => {
        return {
          cells: [
            { title: 'ФИО водителя', value: driverFio },
            { title: 'Телефон водителя', value: phone },
            { title: 'Номер ВУ', value: permitNumber },

            { title: 'Марка авто', value: vehicleBrand ?? '' },
            { title: 'Модель авто', value: vehicleModel ?? '' },
            { title: 'Гос номер авто', value: vehicleNumber ?? '' },
            { title: 'Серия, номер СРТС', value: `${vehicleCertificate ?? ''} ${vehicleCertificateIndex ?? ''}` },

            { title: 'Марка прицепа', value: trailerBrand ?? '' },
            { title: 'Модель прицепа', value: trailerModel ?? '' },
            { title: 'Гос номер прицеп', value: trailerNumber ?? '' },
            { title: 'Серия, номер СРТС', value: `${trailerCertificate ?? ''} ${trailerCertificateIndex ?? ''}` }
          ]
        }
      })

      await downloadXLSXFile({ rowsWithTitle }, slug(`реестр водителей и тс перевозчик ${profile.organization?.shortName}`))
    } finally {
      setCreateRegistryProgress(false)
    }
  }

  return (<Button
    variant='contained'
    color='primary'
    size='small'
    onClick={createRegistryDriversAndVehicle}
    disabled={createRegistryProgress}
    endIcon={createRegistryProgress && <CircularProgress sx={{ width: '24px' }}/>}
  >
  {children}
  </Button>)
}
