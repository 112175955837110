import { AlertColor } from '@mui/material'
import { useSnackbar as useDefaultSnackbar, OptionsObject, TransitionCloseHandler } from 'notistack'
import { ReactNode } from 'react'
import Snackbar from 'ui/Snackbar'

export type Variant = 'standard' | 'filled' | 'outlined'

export interface SnackParams {
  content: ReactNode
  title?: string
  color?: AlertColor
  variant?: Variant
  options?: OptionsObject
}

export const useEnqueueSnackbar = () => {
  const { enqueueSnackbar } = useDefaultSnackbar()

  const pushSnackbar = (
    content: ReactNode,
    options?: OptionsObject & {
      title?: string
      color?: AlertColor
      variantalert?: Variant
      onClose?: TransitionCloseHandler
    }
  ) => {
    enqueueSnackbar(options?.key, {
      ...options,
      content: (key) => {
        const { variantalert, color, title } = options ?? {}
        return (<Snackbar id={key} content={content} title={title} variant={variantalert} color={color} />)
      }
    })
  }

  const toast = (message: string | undefined | null, color: AlertColor = 'success', onClose?: TransitionCloseHandler) => pushSnackbar(message, { color, onClose })

  const snack = ({ content, title, color, variant, options }: SnackParams) => pushSnackbar(content, { ...options, title, color, variantalert: variant })

  return { toast, snack }
}

// Примеры:
// toast('error message', 'error')
// toast('info message', 'info')
// toast('success message', 'success')
// toast('warning message', 'warning')
// snack({
//   title: 'Новая запланированная погрузка на 12.12.2023',
//   color: 'info',
//   content: (<Box>
//     <Typography>Перевозчик Михайлов И.В.</Typography>
//     <Button variant='outlined' color='inherit'>Просмотреть</Button>
//   </Box>)
// })
