import { ForwarderForCreate, ForwarderPosition } from 'api/forwarder'
import { makeJsonSchema, SimpleSchema } from './validate'
import { EMAIL_PATTERN } from 'common/Mask/EmailMask'

const simpleSchema: SimpleSchema<ForwarderForCreate> = {
  type: 'object',
  properties: {
    familyName: { type: 'string', pattern: '^[а-яА-ЯёЁ\\- ]+$', minLength: 3, errorMessage: 'Некорректно заполнена фамилия' },
    firstName: { type: 'string', pattern: '^[а-яА-ЯёЁ\\- ]+$', minLength: 3, errorMessage: 'Некорректно заполнено имя' },
    secondName: { type: 'string', pattern: '^[а-яА-ЯёЁ\\- ]+$', minLength: 3, errorMessage: 'Некорректно заполнено отчество' },
    position: { type: 'string', enum: Object.values(ForwarderPosition), errorMessage: 'Некорректно заполнена должность' },
    email: { type: 'string', pattern: EMAIL_PATTERN, errorMessage: 'Некорректно заполнен Email' },
    phone: { type: 'string', pattern: '^\\+[1-9]{2}\\d{9}$', errorMessage: 'Некорректно заполнен номер телефона' }
  }
}

const schema = makeJsonSchema<ForwarderForCreate>(simpleSchema)

export default schema
