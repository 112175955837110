import { toTon } from './weight'

export const WEIGHING_SCALE_MIN_LOAD = 10000
export const WEIGHING_SCALE_MAX_LOAD = 60000

export const SCALE_LENGTH_MIN = 10
export const SCALE_LENGTH_MAX = 22

const maxLoad = (weight?: number) => weight !== undefined
  ? weight >= WEIGHING_SCALE_MIN_LOAD && weight <= WEIGHING_SCALE_MAX_LOAD
  : true

const scaleLength = (length?: number) => length !== undefined
  ? length >= SCALE_LENGTH_MIN && length <= SCALE_LENGTH_MAX
  : true

export const SCALE_LENGTH_MSG = `Значение должно находиться в диапазоне от ${SCALE_LENGTH_MIN} до ${SCALE_LENGTH_MAX} м`
export const MAX_LOAD_MSG = `Значение должно находиться в диапазоне от ${toTon(WEIGHING_SCALE_MIN_LOAD)} до ${toTon(WEIGHING_SCALE_MAX_LOAD)} тн`

export const checkScaleLength = (length?: number) => !scaleLength(length)
  ? SCALE_LENGTH_MSG
  : undefined

export const checkMaxLoad = (weight?: number) => !maxLoad(weight)
  ? MAX_LOAD_MSG
  : undefined
