import './styles.sass'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import ruLocale from '@fullcalendar/core/locales/ru'
import NavigatePanel from 'ui/NavigatePanel'
import { useMainRoutes } from 'routes'
import { RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { CalendarApi } from '@fullcalendar/core'
import { Stack, Typography } from '@mui/material'
import SquareButton from 'ui/SquareButton'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import FormatDate from 'util/FormatDate'
import { shippingList as shippingListApi, ShippingTruckRunStatus as Status } from 'api/shipping'
import TsToFormatDate from 'util/TsToFormatDate'
import { getFirstDayOfMonth, getLastDayOfMonth, clear as clearTS, dayStart } from 'util/date'
import LimitString from 'ui/LimitString'
import DayModal from './DayModal'
import { DAY } from 'constants/Time'

const TITLE = 'Календарь'

const getCalendarApi = ({ current }: RefObject<FullCalendar | null>) => {
  if (current === null) {
    return undefined
  }

  return current.getApi()
}

const exec = (api?: CalendarApi) =>
  (callback: (api: CalendarApi) => void) => {
    if (api === undefined) {
      return
    }

    return callback(api)
  }

const nextMounth = (setter: (date: Date) => void) =>
  (api: CalendarApi) => {
    api.next()
    setter(api.getDate())
  }

const prevMounth = (setter: (date: Date) => void) =>
  (api: CalendarApi) => {
    api.prev()
    setter(api.getDate())
  }

const EXTRA_DAYS = DAY * 10

const from = (d: Date) => getFirstDayOfMonth(clearTS(d.getTime())) - EXTRA_DAYS
const to = (d: Date) => getLastDayOfMonth(clearTS(d.getTime())) + EXTRA_DAYS

type CalendarEventsItem = {
  id?: string
  title: string
  date: Date | string
  truckRunsDesc: string
  loadingTs?: number
}

export type Shippings = Awaited<ReturnType<typeof shippingListApi>>['list']

const startDay = (date: Date) => dayStart(clearTS(date.getTime()))
const formatMoreLink = (num: number) => `Ещё ${num}`

export default function CarrierCalendar () {
  const { links, routesMap } = useMainRoutes()
  const calendarRef = useRef<FullCalendar>(null)

  const [currentDate, setCurrentDate] = useState<Date>(new Date())
  const [shippings, setShippings] = useState<Shippings>([])
  const [selectDay, setSelectDay] = useState<number>()

  const events: CalendarEventsItem[] = useMemo(() => {
    return shippings.map(item => {
      const { bid, loadingTs, truckRuns } = item
      return {
        title: `Перевозка ${bid.num}`,
        date: TsToFormatDate(loadingTs as number, 'yyy-MM-dd'),
        truckRunsDesc: truckRuns.map(item => item.num).join(', '),
        loadingTs
      }
    })
  }, [shippings])

  const dayData = useMemo(() => {
    if (selectDay === undefined) {
      return undefined
    }

    if (shippings === undefined) {
      return undefined
    }

    const result = shippings.filter(item => item.loadingTs === selectDay)

    if (result.length === 0) {
      return undefined
    }

    return result
  }, [shippings, selectDay])

  const calendar = (callback: (api: CalendarApi) => void) =>
    exec(getCalendarApi(calendarRef))(callback)

  const init = useCallback(() => {
    shippingListApi({
      filters: {
        truckRunStatus: [Status.confirmed],
        loadingTsFrom: from(currentDate),
        loadingTsTo: to(currentDate)
      },
      groupBy: { day: true }
    })
      .then(item => item.list)
      .then(setShippings)
  }, [currentDate])

  useEffect(() => {
    init()
  }, [init])

  const handleNext = () => calendar(nextMounth(setCurrentDate))
  const handlePrev = () => calendar(prevMounth(setCurrentDate))
  const handleDay = <T extends { date: Date }, > ({ date }: T) =>
    setSelectDay(startDay(date))
  const handleEvent = <T extends { event: { extendedProps: { loadingTs?: number } } }, > ({ event }: T) =>
    setSelectDay(event.extendedProps.loadingTs)
  const handleCloseModal = () => setSelectDay(undefined)

  return (<>
  <div className='carrierCalendar'>
    <NavigatePanel
      breadcrumbs={{
        items: routesMap.getBreadcrumbs(links.CARRIER_CALENDAR_PAGE)
      }}
      title={TITLE}
      actions={<Stack direction='row' spacing={1} justifyContent='flex-end' alignItems='center'>
        <Typography fontSize='20px' fontWeight={500} sx={{ '&::first-letter': { textTransform: 'uppercase' } }}>{FormatDate(currentDate, 'MMMM yyyy')}</Typography>
        <SquareButton variant='outlined' size='small' onClick={handlePrev} color='secondary' ><ChevronLeft sx={{ color: '#B2B2B2' }} /></SquareButton>
        <SquareButton variant='outlined' size='small' onClick={handleNext} color='secondary' ><ChevronRight sx={{ color: '#B2B2B2' }} /></SquareButton>
      </Stack>}
    />
    <div className='carrierCalendar_content'>
      <FullCalendar
        ref={calendarRef}
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView='dayGridMonth'
        locale={ruLocale}
        height='100%'
        headerToolbar={false}
        dayMaxEvents={3}
        events={events}
        moreLinkClick={handleDay}
        moreLinkText={formatMoreLink}
        eventClick={handleEvent}
        dateClick={handleDay}
        eventColor='#0A5DFF'
        eventContent={e => {
          const { event: { title, extendedProps } } = e
          return (<Stack direction='column' p='4px' height='43px'>
            <Typography component='p' fontSize='11px' fontWeight={500} color='rgba(255, 255, 255, 0.60)' >{title}</Typography>
            <Typography component='p' fontSize='12px' fontWeight={500} color='#FFFFFF' ><LimitString text={extendedProps.truckRunsDesc} limit={25} /></Typography>
          </Stack>)
        }}
      />
    </div>
  </div>
  <DayModal
    day={selectDay}
    onClose={handleCloseModal}
    data={dayData}
  />
  </>
  )
}
