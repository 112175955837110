import { useMainRoutes } from 'routes'
import { NotifyEvent } from '.'
import { useEffect } from 'react'
import { Box, Button } from '@mui/material'

export interface Data {
  truckRunId: string
  truckRunNum: string
  carrierName: string
  comment: string
  createDate: string
  message: string
}

const SLUG = 'truck_run_new_carrier_comment_event'

export default function TruckRunNewCarrierComment ({ slug, data, snack, navigate }: NotifyEvent) {
  const { links } = useMainRoutes()

  useEffect(() => {
    if (slug !== SLUG) {
      return
    }

    if (snack === undefined) {
      return
    }

    if (data === undefined) {
      return
    }

    const { message, truckRunId } = data as Data

    const open = () => {
      if (navigate === undefined) {
        return
      }

      navigate(`${links.CARRIER_TRIPS_PAGE}/${truckRunId}`)
    }

    snack({
      title: message,
      color: 'info',
      content: (<Box>
        <Button variant='outlined' color='inherit' onClick={open}>Просмотреть</Button>
      </Box>),
      options: { autoHideDuration: 10000 }
    })
  }, [slug, data, snack, links.CARRIER_TRIPS_PAGE, navigate])

  return (<></>)
}
