import { Alert, Button, Stack, Typography } from '@mui/material'
import { User, userChangePhone } from 'api/user'
import { PHONE_MASK, phoneClean } from 'common/Mask/PhoneMask'
import { useState } from 'react'
import Modal from 'ui/Modal'
import TextField from 'ui/TextField'
import { isPhone } from 'util/isPhone'
import Progress from './Progress'

export interface Params {
  user?: User
  onClose: () => void
  onSuccess: () => void
}

const SYSTEM_ERROR_MSG = 'При смене номера телефона произошли проблемы. Попробуйте позже или используйте другой номер телефона'

export default function ChangePhoneModal ({ user, onClose, onSuccess }:Params) {
  const [systemError, setSystemError] = useState<string>()
  const [viewDisclaimer, setViewDisclaimer] = useState(true)
  const [newPhone, setNewPhone] = useState<string>()
  const [viewProgress, setViewProgress] = useState(false)
  const [success, setSuccess] = useState(false)

  const handleClose = () => {
    setViewDisclaimer(true)
    setSystemError(undefined)
    setNewPhone(undefined)
    setViewProgress(false)
    setSuccess(false)
    onClose()
  }

  const handleChange = () => {
    if (user === undefined || !isPhone(newPhone)) {
      return
    }

    const { phone } = user

    setViewProgress(true)
    userChangePhone(phone, newPhone)
      .then(success => {
        if (success) {
          onSuccess()
          setSuccess(true)
          return
        }

        setSystemError(SYSTEM_ERROR_MSG)
      })
      .catch(() => {
        setSystemError(SYSTEM_ERROR_MSG)
      })
  }

  return (<>
    <Modal
      maxWidth='lg'
      title='Изменить номер телефона пользователя'
      open={!!user}
      onClose={handleClose}
      content={<Stack direction='column' minWidth='40em' maxWidth='40em' gap={2}>
        {systemError
          ? <Alert color='error'>{systemError}</Alert>
          : <>
          {viewDisclaimer && <Alert color='warning'>
            <Typography>Операция изменения номера телефона пользователя, является необратимой!</Typography>
            <Typography>Если вы готовы изменить номер телефона нажмите 'Продолжить'</Typography>
          </Alert>}
          {!viewDisclaimer && <>
              {success
                ? <Alert color='success'>Номер телефона пользователя, успешно изменен</Alert>
                : <Alert color='info'>Изменить номер телефона пользователя: {user?.phone}</Alert>
              }
              <TextField
                label='Новый номер телефона'
                value={newPhone}
                placeholder='+7 (999) 999-99-99'
                maskParams={{
                  mask: PHONE_MASK
                }}
                width='100%'
                onChange={({ target: { value } }) => setNewPhone(phoneClean(value))}
                disabled={viewProgress}
                errorMessage={newPhone && newPhone === user?.phone ? 'Новый и текущий номер совпадают' : undefined}
              />
          </>}
        </>}
        {viewProgress && <Stack mt='1em'><Progress success={success} /></Stack>}
      </Stack>}
      actions={<Stack direction='row' justifyContent='space-between' width='100%'>
        {!viewProgress && <>
          <Button variant='outlined' color='secondary' onClick={handleClose}>Отмена</Button>
          {!systemError && <>
            {viewDisclaimer
              ? <Button variant='contained' onClick={() => setViewDisclaimer(false)}>Продолжить</Button>
              : <Button variant='contained'
                disabled={!isPhone(newPhone) || newPhone === user?.phone}
                onClick={handleChange}
              >Изменить</Button>}
          </>}
        </>}
        {(!!systemError || success) && <>
          <div></div>
          <Button variant='contained' color='primary' onClick={handleClose}>Закрыть</Button>
        </>}
      </Stack>}
    />
  </>)
}
