import './styles.sass'
import { Id, isId } from 'api/Id'
import { VehicleAllForUpdate, vehicleAllGet, vehicleAllUpdate, vehicleTypeList as apiVehicleTypeList, VehicleStatus, VehicleType, VehicleAxles, vehicleAxlesList as apiVehicleAxlesList, VehicleForCreate } from 'api/vehicle'
import { useAuthContext } from 'AuthContext'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import useValidate from 'validation/validate'
import schema, { clear } from 'validation/Vehicle'
import { useMainRoutes } from 'routes'
import NavigatePanel from 'ui/NavigatePanel'
import { Button, Stack, Typography, Box } from '@mui/material'
import SaveIcon from '@mui/icons-material/Save'
import InfoCard from 'ui/InfoCard'
import TextField from 'ui/TextField'
import AntSwitch from 'ui/AntSwitch'
import Upload from 'ui/Upload'
import Select from 'ui/Select'
import formatDate from 'util/FormatDate'
import { ChipVehicleStatusDispatcher } from 'common/ChipVehicleStatusDispatcher'
import useTabs from 'common/Registry/Carrier/useTabs'
import { ProfileAll, profileAllGet } from 'api/profile'
import { UserRole } from 'api/user'
import useDisabled from 'common/Registry/Carrier/useDisabled'
import { personName } from 'util/personName'
import { rejectionCreate } from 'api/rejection'
import RejectionModal from 'ui/RejectionModal'
import Confirm from 'ui/Confirm'
import Alert from 'ui/Alert'
import { BlackListEntry, BlackListStatus } from 'api/black-list'
import InfoCell, { InfoCellProps } from 'ui/InfoCell'
import History from 'ui/History'
import { asObject } from 'util/asObject'
import onlyFields from 'util/onlyFields'
import { CarNumberType } from 'api/CarNumberType'
import { CarNumberIsRegionFirst, CarNumberRegionMask } from 'common/Mask/CarNumberRegionMask'
import { VehicleNumberMask } from 'common/Mask/VehicleNumberMask'
import getRegionNumber from 'common/getRegionNumber'
import DownloadZipButton, { slug } from 'ui/DownloadZipButton'
import vehicleAndTrailerPack, { Result as Pack } from 'common/map/vehicleAndTrailerPack'
import RegistryCarrierLink from 'ui/RegistryCarrierLink'
import vehicleName from 'util/vehicleName'
import { regionCodesUA } from 'constants/regionCodesUA'
import { validateVehicleNumber } from 'util/validateVehicleNumber'

type UpdateParams = Omit<VehicleAllForUpdate, 'id'>

interface DataMisc {
  status: VehicleStatus
  archiveDate?: number
  archiveComment?: string
  truckRuns?: Id[]
  blackList?: BlackListEntry[]
}

const BlackListStatusText = ({ status }: {status: BlackListStatus}) => {
  if (status === BlackListStatus.ban) {
    return <>Полный запрет на въезд</>
  } else if (status === BlackListStatus.control) {
    return <>На особом контроле (без запрета на въезд)</>
  }

  return <></>
}

const BlackListItem = ({ data }: { data: BlackListEntry }) => {
  const cells: InfoCellProps[] = [
    { label: 'Гос.номер', value: data.object },
    { label: 'Статус', value: <BlackListStatusText status={data.status} /> },
    { label: 'Описание', value: data.description }
  ]

  return <InfoCard>
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      color: '#111'
    }}>
      { cells.map((item, idx) => <InfoCell {...item} key={idx} />) }
    </Box>
  </InfoCard>
}

export default function CarrierVehicle () {
  const { carrier: profileId, vehicleId: id } = useParams()
  const { handleResponseFailure, handleResponseSuccess, currentInterface } = useAuthContext()

  const [data, setData] = useState<UpdateParams>()
  const [dataMisc, setDataMisc] = useState<DataMisc>()
  const [disabled, setDisabled] = useState<boolean>(useDisabled(currentInterface).disabled)
  const [disabledRegion, setDisabledRegion] = useState<boolean>(false)
  const [vehicleTypeList, setVehicleTypeList] = useState<VehicleType[]>([])
  const [vehicleAxlesList, setVehicleAxlesList] = useState<VehicleAxles[]>([])
  const [jointOwnership, setJointOwnership] = useState<boolean>(false)
  const [profile, setProfile] = useState<ProfileAll>()
  const [rejectionModalOpen, setRejectionModalOpen] = useState<boolean>(false)
  const [scanAgreementError, setScanAgreementError] = useState<string>()
  const [scanLisingError, setScanLisingError] = useState<string>()
  const [pack, setPack] = useState<Pack>()
  const [numberError, setNumberError] = useState(false)
  const navigate = useNavigate()
  const { check: checkSchema, errors } = useValidate(schema)
  const { links, routesMap } = useMainRoutes()
  const { tabs } = useTabs(isId(profileId) ? profileId : undefined, isId(id) ? id : undefined)

  const check = useCallback((x: unknown): x is VehicleForCreate => {
    const { scanAgreement, isLising, scanLising } = asObject(x)
    const validScanAgreement = !jointOwnership || !!scanAgreement
    const validScanLising = !isLising || !!scanLising
    setScanAgreementError(validScanAgreement ? undefined : 'Поле обязательно к заполнению')
    setScanLisingError(validScanLising ? undefined : 'Поле обязательно к заполнению')
    return checkSchema(x) && validScanAgreement && validScanLising
  }, [checkSchema, jointOwnership])

  const save = useCallback(async () => {
    const clearData = clear(data)

    if (!check(clearData)) {
      return
    }
    if (!validateVehicleNumber(data?.numberType, data?.number)) {
      setNumberError(true)
      return
    }
    if (isId(id)) {
      const result = await vehicleAllUpdate({ id, ...clearData })

      if (result) {
        handleResponseSuccess('Данные ТС изменены')
        navigate(-1)
      }
    }
  }, [data, check, id, handleResponseSuccess, navigate])

  const init = useCallback(async () => {
    const profile = isId(profileId) ? await profileAllGet(profileId, { role: UserRole.carrier }) : null

    if (profile === null) {
      handleResponseFailure('Перевозчик не найден')
      return
    }

    setProfile(profile)
    apiVehicleTypeList().then(setVehicleTypeList)
    apiVehicleAxlesList().then(setVehicleAxlesList)

    const result = isId(id) ? await vehicleAllGet(id) : null

    if (result !== null) {
      const { id, owner, status, archiveDate, axles, createTs, archiveComment, truckRuns = [], blackList = [], ...data } = result
      const onlyChangeableFields = onlyFields(data,
        'type', 'brand', 'model', 'number', 'numberRegion',
        'certificateIndex', 'certificate', 'isLising',
        'color', 'scanPassport', 'scanRegistration',
        'scanLising', 'scanAgreement', 'numberType')
      setDataMisc({ status, truckRuns, blackList, archiveDate, archiveComment })
      setDisabled((disabled) => disabled || status === VehicleStatus.archive)
      setData({ ...onlyChangeableFields, axlesSlug: axles.slug !== '' ? axles.slug : undefined })
      setJointOwnership(data.scanAgreement !== undefined)
      setDisabledRegion([CarNumberType.dpr, CarNumberType.lpr].includes(onlyChangeableFields.numberType))
      setPack(vehicleAndTrailerPack(data))
    } else {
      handleResponseFailure('ТС не найдено')
    }
  }, [handleResponseFailure, id, profileId])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { init() }, [])

  const updateString = (key: keyof UpdateParams) => {
    return (event: ChangeEvent<HTMLInputElement>) => {
      const { target: { value } } = event
      setData({ ...data, [key]: value === '' ? undefined : value })
    }
  }
  const updateUARegion = (value?:string) => {
    setData({ ...data, numberRegion: value || undefined })
  }

  const updateCheckbox = (key: keyof UpdateParams) => {
    return (e: ChangeEvent<HTMLInputElement>) => {
      setData({ ...data, [key]: e.target.checked })
    }
  }

  const updateUpload = (key: keyof UpdateParams) => {
    return (filename: string | undefined) => {
      setData({ ...data, [key]: filename })
    }
  }

  const changeVehicleStatus = (status: VehicleStatus): void => {
    if (isId(id)) {
      vehicleAllUpdate({ id, status }).then(result => {
        if (result === null) {
          handleResponseFailure('Произошла ошибка')
        } else {
          handleResponseSuccess('Статус ТС изменен')
          navigate(-1)
        }
      })
    }
  }

  const handleRejection = (reasonSlug: string, comment?: string) => {
    if (isId(id)) {
      rejectionCreate({ objectId: id, reasonSlug, comment })
        .then(result => {
          if (result) {
            changeVehicleStatus(VehicleStatus.blocked)
          }
        })
    }
  }

  const presentation = ({ brand = '', model = '', ...doc }: UpdateParams) => `${vehicleName(doc, 'short')} - ${brand} ${model}`

  const changeNumberType = (numberType?: CarNumberType) => {
    const numberRegion = getRegionNumber(numberType)

    setDisabledRegion(!!numberRegion)
    setData((data) => ({
      ...data,
      numberType,
      numberRegion: numberRegion ?? ''
    }))
  }

  if (profile === undefined || data === undefined) {
    return <></>
  }

  return (<>
    <RejectionModal
      open={rejectionModalOpen}
      onCancel={() => setRejectionModalOpen(false)}
      onReject={handleRejection}
    />
    <div className='registryCarriers_vehicle'>
      <NavigatePanel
        title={
          <Box sx={{ display: 'flex', gap: '1em', alignItems: 'center' }}>
              <Box>{presentation(data)}</Box>
              { dataMisc
                ? <ChipVehicleStatusDispatcher status={dataMisc.status} truckRuns={dataMisc.truckRuns}/>
                : <></>
              }
          </Box>
        }
        tabs={tabs}
        breadcrumbs={{
          items: [
            {
              title: profile.organization?.shortName || personName(profile, profile.phone),
              to: `${links.REGISTRY_CARRIER_PAGE}/${profile.id}`
            },
            {
              title: 'Транспортные средства',
              to: `${links.REGISTRY_CARRIER_PAGE}/${profile.id}/vehicles`
            },
            {
              title: `${data.brand} ${data.model}`
            }
          ],
          defaultItems: routesMap.getBreadcrumbs(links.REGISTRY_CARRIERS_PAGE)
        }}
        actions={
          <Stack direction='row' spacing={2} justifyContent='end'>
            { pack && <DownloadZipButton pack={pack} archiveName={slug(`тс_${vehicleName(data, 'short').replaceAll(' ', '_')}`)} /> }
            <Button variant='outlined' color='secondary' size='small' onClick={() => navigate(-1)}>
              { disabled ? 'Закрыть' : 'Отменить' }
            </Button>
            { disabled
              ? <></>
              : <Button variant='contained' color='success' size='small' onClick={save}>
                Сохранить <SaveIcon sx={{ width: '15px', height: '15px', ml: '10px' }}/>
              </Button>
            }
          </Stack>
        }
      />
      <div className='registryCarriers_vehicle__body'>
        <div className='registryCarriers_vehicle__content'>
            <RegistryCarrierLink profile={profile} navigate={navigate} />
            { !disabled && dataMisc?.status === VehicleStatus.new && <Confirm
              title='Подтверждение информации'
              text={<>
                Перевозчик добавил новое транспортное средство и ожидает его подтверждения.<br/>
                Проверьте указанную информацию, загруженные фотокопии документов и соответствие данных между ними.
              </>}
              onAllow={() => changeVehicleStatus(VehicleStatus.active)}
              onReject={() => setRejectionModalOpen(true)}
            />}
            { !disabled && dataMisc?.status === VehicleStatus.blocked && <Alert
                color='#EE6A5F'
                controls={<Button
                  variant='contained'
                  color='inherit'
                  sx={{
                    background: '#F49E97',
                    color: '#FFFFFF',
                    '&:hover': {
                      background: '#F49E97',
                      opacity: '0.9'
                    }
                  }}
                  onClick={() => changeVehicleStatus(VehicleStatus.new)}
                >Пересмотреть отказ</Button>}
              >
              <>
                <Typography fontWeight={600} fontSize='18px' color='#ffffff' sx={{ mb: 1 }}>Отказано в добавлении транспортного средства</Typography>
                <Typography fontWeight={400} fontSize='14px' color='#ffffff'>Причина отказа: {data?.rejection?.reason.name}</Typography>
              </>
            </Alert>}
          { disabled && dataMisc?.status === VehicleStatus.blocked && <Box sx={{
            mt: '10px',
            background: '#EE6A5F',
            borderRadius: '8px',
            padding: '32px 24px',
            color: '#fff'
          }}>
            <Typography sx={{
              fontWeight: '600',
              fontSize: '18px',
              lineHeight: '22px',
              letterSpacing: '-0.02em',
              marginBottom: '0.5em'
            }}>
              Отказано в добавлении ТС
            </Typography>
            <Typography>
            К сожалению, транспортное средство с указанным вами данным невозможно добавить в систему.
            </Typography>
            <Typography>
              Дополнительную информацию вы можете получить по адресу <a href='mailto: info@aston.ru' style={{ color: '#fff' }}>info@aston.ru</a>
            </Typography>
          </Box>}
          { dataMisc && dataMisc.blackList && dataMisc.blackList.length > 0 && <Box sx={{
            mt: '10px',
            background: '#EE6A5F',
            borderRadius: '8px',
            padding: '32px 24px',
            color: '#fff'
          }}>
            <Typography sx={{
              fontWeight: '600',
              fontSize: '18px',
              lineHeight: '22px',
              letterSpacing: '-0.02em',
              mb: 1
            }}>
              Найденные записи в блек-листе
            </Typography>
            <Stack>
              {dataMisc.blackList.map((data) => <BlackListItem data={data} />)}
            </Stack>
          </Box>}
          {dataMisc?.status === VehicleStatus.archive && <Box sx={{
            mt: '10px',
            background: '#EBEBEB',
            borderRadius: '8px',
            padding: '32px 24px',
            color: '#111'
          }}>
            <Typography sx={{
              fontWeight: '600',
              fontSize: '18px',
              lineHeight: '22px',
              letterSpacing: '-0.02em'
            }}>
              ТС было архивировано {dataMisc.archiveDate ? formatDate(new Date(dataMisc.archiveDate * 1000)) : ''}
            </Typography>
            {dataMisc.archiveComment && <Typography sx={{ marginTop: '0.5em', whiteSpace: 'pre-wrap' }}>
              {dataMisc.archiveComment}
            </Typography>}
          </Box>}
          <InfoCard
            title='Общая информация'
          >
            <>
              <Stack direction='row' justifyContent='space-between' spacing={2} sx={{ mb: 2 }}>
                <Select
                  name='type'
                  label='Тип ТС'
                  placeholder='Укажите тип ТС из СТС'
                  options={vehicleTypeList.map(({ slug, name }) => ({ value: slug, name }))}
                  value={data.type}
                  onChange={(type) => setData((data) => ({ ...data, type }))}
                  disabled={disabled}
                  errors={errors}
                  width='25%'
                />
                <TextField name='brand' label='Марка' placeholder='Укажите марку транспортного средства' disabled={disabled} width='25%' value={data.brand} onChange={updateString('brand')} errors={errors} />
                <TextField name='model' label='Модель' placeholder='Укажите модель транспортного средства' disabled={disabled} width='25%' value={data.model} onChange={updateString('model')} errors={errors} />
                <TextField name='color' label='Цвет' placeholder='Укажите цвет транспортного средства' disabled={disabled} width='25%' value={data.color} onChange={updateString('color')} errors={errors} />
              </Stack>

              <Stack direction='row' justifyContent='space-between' spacing={2}>
                <Select
                  name='numberType'
                  label='Тип номера'
                  placeholder='Выбирите тип номера'
                  options={Object.values(CarNumberType).map((value) => ({ value, name: value }))}
                  value={data.numberType}
                  onChange={changeNumberType}
                  disabled={disabled}
                  errors={errors}
                  width='20%'
                />
                <Stack direction={CarNumberIsRegionFirst(data.numberType) ? 'row-reverse' : 'row'} width='20%' spacing={2}>
                  <TextField
                    name='number'
                    label='Гос.номер'
                    placeholder='Укажите гос. номер регистрации'
                    disabled={disabled}
                    width='50%'
                    value={data.number}
                    onChange={updateString('number')}
                    errors={errors}
                    maskParams={{
                      mask: VehicleNumberMask(data.numberType)
                    }}
                    errorMessage={numberError ? 'Неправильно заполненное поле ' : undefined}
                    tabIndex={CarNumberIsRegionFirst(data.numberType) ? 2 : 1}
                  />
                  {
                    data.numberType === CarNumberType.ua
                      ? <Select
                  options={regionCodesUA}
                  name='numberRegion'
                  label='Регион' placeholder='Укажите регион регистрации'
                  disabled={disabled || disabledRegion}
                  width='50%'
                  value={data.numberRegion || ''}
                  onChange={updateUARegion}
                  errors={errors}
                  tabIndex={CarNumberIsRegionFirst(data.numberType) ? 1 : 2}
                />
                      : <TextField
                    name='numberRegion'
                    label='Регион' placeholder='Укажите регион регистрации'
                    disabled={disabled || disabledRegion}
                    width='50%'
                    value={data.numberRegion}
                    onChange={updateString('numberRegion')}
                    errors={errors}
                    maskParams={{
                      mask: CarNumberRegionMask(data.numberType)
                    }}
                    tabIndex={CarNumberIsRegionFirst(data.numberType) ? 1 : 2}
                  />
                  }
                </Stack>
                <TextField
                  name='certificateIndex'
                  label='Серия СРТС'
                  placeholder='Укажите серию СРТС'
                  disabled={disabled}
                  width='20%'
                  value={data.certificateIndex}
                  onChange={updateString('certificateIndex')}
                  errors={errors}
                  maskParams={{
                    mask: [/\d/, /\d/, ' ', /[\dА-Я]/i, /[\dА-Я]/i]
                  }}
                />
                <TextField
                  name='certificate'
                  label='Номер СРТС'
                  placeholder='Укажите номер СРТС'
                  disabled={disabled}
                  width='20%'
                  value={data.certificate}
                  onChange={updateString('certificate')}
                  errors={errors}
                  maskParams={{
                    mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
                  }}
                />
                <Select
                  name='axlesSlug'
                  label='Количество осей'
                  placeholder='Укажите количество осей'
                  options={vehicleAxlesList.map(({ slug, name }) => ({ value: slug, name }))}
                  value={data.axlesSlug}
                  onChange={(axlesSlug) => setData((data) => ({ ...data, axlesSlug })) }
                  disabled={disabled}
                  errors={errors}
                  width='20%'
                />
              </Stack>
            </>
          </InfoCard>
          <InfoCard
            title='Документы'
          >
            <>
              <Stack direction='row' justifyContent='space-between' spacing={2} sx={{ mb: 2 }}>
                <Upload
                  name='scanPassport'
                  disabled={disabled}
                  sx={{ width: '50%' }}
                  label='ПТС'
                  hint='В цветном формате'
                  value={data.scanPassport}
                  onChange={updateUpload('scanPassport')}
                  errors={errors}
                />
                <Upload
                  name='scanRegistration'
                  disabled={disabled}
                  sx={{ width: '50%' }}
                  label='Свидетельство о гос.регистрации'
                  hint='В цветном формате'
                  value={data.scanRegistration}
                  onChange={updateUpload('scanRegistration')}
                  errors={errors}
                />
              </Stack>
              <Stack direction='row' justifyContent='left' alignItems='center' sx={{ gap: '10px' }}>
                <AntSwitch disabled={disabled} checked={jointOwnership} onChange={e => setJointOwnership(e.target.checked)} inputProps={{ 'aria-label': 'ant design' }} />
                <Typography>ТС в совместной собственности</Typography>
              </Stack>
              { jointOwnership && <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} mt={2} >
                <Typography width='67%' >Нотариально заверенное согласие основного собственника на использование в предпринимательской деятельности</Typography>
                <Upload
                  disabled={disabled}
                  value={data.scanAgreement}
                  onChange={updateUpload('scanAgreement')}
                  sx={{ width: '33%' }}
                  errorMessage={scanAgreementError}
                />
              </Stack> }
            </>
          </InfoCard>
          <InfoCard title='Лизинг'>
            <>
              <Stack direction='row' justifyContent='left' alignItems='center' sx={{ gap: '10px' }}>
                <AntSwitch disabled={disabled} checked={data.isLising} onChange={updateCheckbox('isLising')} inputProps={{ 'aria-label': 'ant design' }} />
                <Typography>ТС находится в лизинге</Typography>
              </Stack>
              {data.isLising
                ? <Stack direction='row' justifyContent='space-between' spacing={2} sx={{ mt: 2 }}>
                  <Upload
                    name='scanLising'
                    disabled={disabled}
                    sx={{ width: '50%' }}
                    label='Договор лизинга'
                    value={data.scanLising}
                    onChange={updateUpload('scanLising')}
                    errors={errors}
                    errorMessage={scanLisingError}
                  />
                </Stack>
                : <></>
              }
            </>
          </InfoCard>
          { !disabled && isId(id) && <InfoCard
            title='История изменений'
          >
            <History filter={{
              col: ['main.vehicles'],
              id: [id]
            }} onUndo={init}/>
          </InfoCard>
          }
        </div>
      </div>
    </div>
  </>)
}
