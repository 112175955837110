import './styles.sass'
import Box from '@mui/material/Box'
import Modal from 'ui/Modal'
import { ReactNode, useState } from 'react'
import { Button, Stack } from '@mui/material'

export interface StepProps {
  isActive: boolean
  data: unknown
  handleCancel: () => void
  handleNext: () => void
  handleToStep: (i: number) => void
}

export type StepComponent = ((p: StepProps) => JSX.Element)

interface Step {
  title: string
  fn: StepComponent
  cancel?: ReactNode
  next?: ReactNode
}

export interface Props {
  steps: Step[]
  data: unknown
  open: boolean
  onClose: () => void
  style?: React.CSSProperties
}

export default function ModalSigning ({ steps, data, open, onClose }: Props) {
  const [activeStepNumber, setActiveStepNumber] = useState(0)

  const handleNext = () => {
    setActiveStepNumber((prevActiveStep) => prevActiveStep + 1)
  }

  const handleToStep = (i: number) => {
    setActiveStepNumber(i)
  }

  const handleCancel = () => {
    onClose()
    setActiveStepNumber(0)
  }

  const getTitle = (idx: number): string => {
    const s = steps.at(idx)
    return s ? s.title : ''
  }
  const getActions = (idx: number): ReactNode => {
    const { cancel, next } = steps.at(idx) ?? {}

    return <Stack direction='row' justifyContent='space-between' spacing={2} sx={{ width: '100%' }}>
      {cancel && <Button variant='outlined' color='secondary' size='small' onClick={handleCancel}>{cancel}</Button>}
      {next && <Button variant='contained' size='small' onClick={handleNext}>{next}</Button>}
    </Stack>
  }

  return (
    <Modal
      maxWidth='sm'
      title={getTitle(activeStepNumber)}
      onClose={() => onClose()}
      open={open}
      content={
        <Box className='modal_signing_box'>
          {steps.map(({ fn: StepComponent }, index) =>
            <div key={index.toString()}>
              <StepComponent
                isActive={index === activeStepNumber}
                data={data}
                handleCancel={handleCancel}
                handleNext={handleNext}
                handleToStep={handleToStep}
              />
            </div>
          )}
        </Box>
      }
      actions={ getActions(activeStepNumber) }
    />
  )
}
