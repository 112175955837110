import { Box, BoxProps } from '@mui/material'
import { ReactNode } from 'react'

export type Params = BoxProps & {
  color?: string
  controls?: ReactNode
}

export default function Alert ({ children, sx, color, controls }: Params) {
  return (<Box
    display='flex'
    sx={{
      ...sx,
      borderRadius: '4px',
      backgroundColor: color
    }}
  >
    <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center' m='24px 32px' width='100%'>
      <Box>{children}</Box>
      { controls && <Box>{controls}</Box> }
    </Box>
  </Box>)
}
