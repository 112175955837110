import { NotifyEvent } from '.'
import { useEffect } from 'react'

export interface Data {
  truckRunNum: string
  message: string
}

const SLUG = 'truck_run_load_carrier_event'

export default function TruckRunLoad ({ slug, data, toast } :NotifyEvent) {
  useEffect(() => {
    if (slug !== SLUG) {
      return
    }

    if (toast === undefined) {
      return
    }

    if (data === undefined) {
      return
    }

    const { message } = data as Data

    toast(message, 'info')
  }, [slug, data, toast])

  return (<></>)
}
