import { mainService } from 'api/service'
import { Id } from 'api/Id'

export interface Okved {
  id?: Id
  code: string
  description: string
  isActive: boolean
}

export type OkvedForCreate = Omit<Okved, 'id'>

const request = (method: string, params: object) => mainService.request(`okved.${method}`, params)

export const okvedCreate = (params: OkvedForCreate) => request('create', params) as Promise<{success: boolean}>
export const okvedGet = (id: Id) => request('get', { id }) as Promise<Okved>
export const okvedUpdate = (params: Partial<Okved>) => request('update', params) as Promise<{success: boolean}>
export const okvedDelete = (id: Id) => request('delete', { id }) as Promise<{success: boolean}>
export const okvedList = () => request('list', {}) as Promise<Okved[]>
