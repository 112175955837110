import InfoCard from 'ui/InfoCard'
import { documentTplList, DocumentType, documentTplEnsure } from 'api/document'
import { useCallback, useEffect, useState } from 'react'
import { Stack } from '@mui/system'
import Upload from 'ui/Upload'
import { Box, Typography } from '@mui/material'
import Tooltip from 'ui/Tooltip'
import { Help } from '@mui/icons-material'

export default function DocumentTpl () {
  const [act, setAct] = useState<string>()
  const [actRegistry, setActRegistry] = useState<string>()
  const [actShortage, setActShortage] = useState<string>()
  const [actAgreement, setActAgreement] = useState<string>()
  const [invoices, setInvoices] = useState<string>()
  const [forwarderLoadingReports, setForwarderLoadingReports] = useState<string>()
  const [dispatcherTruckRunsRegister, setDispatcherTruckRunsRegister] = useState<string>()
  const [konturFocusReport, setKonturFocusReport] = useState<string>()
  const [carrierContract, setCarrierContract] = useState<string>()
  const [carrierOffer, setCarrierOffer] = useState<string>()
  const [finalBid, setFinalBid] = useState<string>()

  const init = useCallback(() => {
    documentTplList().then(result => {
      if (result.length > 0) {
        result.forEach(item => {
          switch (item.documentType) {
            case DocumentType.act:
              setAct(item.filename)
              break
            case DocumentType.actRegistry:
              setActRegistry(item.filename)
              break
            case DocumentType.actShortage:
              setActShortage(item.filename)
              break
            case DocumentType.invoices:
              setInvoices(item.filename)
              break
            case DocumentType.forwarderLoadingReports:
              setForwarderLoadingReports(item.filename)
              break
            case DocumentType.actAgreement:
              setActAgreement(item.filename)
              break
            case DocumentType.dispatcherTruckRunsRegister:
              setDispatcherTruckRunsRegister(item.filename)
              break
            case DocumentType.konturFocusReport:
              setKonturFocusReport(item.filename)
              break
            case DocumentType.carrierContract:
              setCarrierContract(item.filename)
              break
            case DocumentType.carrierOffer:
              setCarrierOffer(item.filename)
              break
            case DocumentType.finalBid:
              setFinalBid(item.filename)
              break
            default:
          }
        })
      }
    })
  }, [])

  useEffect(() => {
    init()
  }, [init])

  const updateUpload = (type: DocumentType) => {
    return (filename: string) => {
      documentTplEnsure({ documentType: type, filename }).then(_ => {
        init()
      })
    }
  }

  return (
    <InfoCard title='Шаблоны документов'>
      <>
      <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
        <Upload
          sx={{ width: '20%' }}
          label='УПД шаблон'
          value={act}
          onChange={updateUpload(DocumentType.act)}
        />
        <Upload
          sx={{ width: '20%' }}
          label='Реестр шаблон'
          value={actRegistry}
          onChange={updateUpload(DocumentType.actRegistry)}
        />
        <Upload
          sx={{ width: '20%' }}
          label='Отчёт по недостачам шаблон'
          value={actShortage}
          onChange={updateUpload(DocumentType.actShortage)}
        />
        <Upload
          sx={{ width: '20%' }}
          label='Соглашение шаблон'
          value={actAgreement}
          onChange={updateUpload(DocumentType.actAgreement)}
        />
        <Upload
          sx={{ width: '20%' }}
          label='Итоговая заявка шаблон'
          value={finalBid}
          onChange={updateUpload(DocumentType.finalBid)}
        />
        <Stack width='20%' direction='row' justifyContent='space-between' spacing={1} alignItems='center'>
          <Upload
            sx={{ width: '100%' }}
            label='Счет (Основной шаблон)'
            value={invoices}
            onChange={updateUpload(DocumentType.invoices)}
          />
          <Tooltip
            view arrow placement='right'
            title={<Stack direction='column'>
              <Typography fontSize='14px' fontWeight={600} >Основной шаблон счета</Typography>
              <Typography fontSize='14px' fontWeight={400} >Шаблон используется для всех типов грузов, для которых не добавлен "специальный" шаблон.</Typography>
            </Stack>}
          ><Help sx={{ color: '#EBEBEB', width: '30px', height: '30px' }}/></Tooltip>
        </Stack>
      </Stack>
      <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
        <Upload
          sx={{ width: '50%' }}
          label='Отчет о погрузке (Экспедитор)'
          value={forwarderLoadingReports}
          onChange={updateUpload(DocumentType.forwarderLoadingReports)}
        />
        <Box width='50%'></Box>
      </Stack>
      <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
        <Upload
          sx={{ width: '50%' }}
          label='Реестр погрузки (Диспетчер)'
          value={dispatcherTruckRunsRegister}
          onChange={updateUpload(DocumentType.dispatcherTruckRunsRegister)}
        />
        <Upload
          sx={{ width: '50%' }}
          label='Контур фокус отчет (Диспетчер)'
          value={konturFocusReport}
          onChange={updateUpload(DocumentType.konturFocusReport)}
        />
        <Box width='50%'></Box>
      </Stack>
      <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
        <Upload
          sx={{ width: '50%' }}
          label='Заявление о присоединении перевозчика'
          value={carrierContract}
          onChange={updateUpload(DocumentType.carrierContract)}
        />
        <Upload
          sx={{ width: '50%' }}
          label='Договор оферта перевозчика'
          value={carrierOffer}
          onChange={updateUpload(DocumentType.carrierOffer)}
        />
        <Box width='50%'></Box>
      </Stack>
      </>
    </InfoCard>
  )
}
