import { PropsWithChildren, createContext, useContext, useState, useEffect, useCallback, useRef, ReactNode } from 'react'
import { notifyService } from 'api/service'
import { useEvents } from 'event'

type Listener = (data: unknown) => void | ReactNode
export type SSIEventType = { slug: string, data: unknown }

interface Context {
  sessionId?: string
  heartbeat: number
  currentEvent?: SSIEventType
  setListener: <Params>(event: string, listener: (params: Params) => void) => void
  removeListener: (event: string) => void
}

const EventContext = createContext<Context | null>(null)

const parseJson = (payload: string) => {
  try {
    return { parsed: JSON.parse(payload) }
  } catch ({ message }) {
    return { parseError: message }
  }
}

interface Props {
  reconnectInterval?: number
  heartbeatInterval?: number
}

export function EventContextProvider ({ reconnectInterval = 3, heartbeatInterval = 3, children }: PropsWithChildren<Props>) {
  const [sessionId, setSessionId] = useState<string>()
  const [heartbeat, setHeartbeat] = useState<number>(0)
  const [online, setOnline] = useState<boolean>(window.navigator.onLine)
  const [listeners, setListeners] = useState<Record<string, Listener>>(useEvents() as Record<string, Listener>)
  const [currentEvent, setCurrentEvent] = useState<SSIEventType>()
  const source = useRef<EventSource>()

  const onError = useCallback(() => {
    setOnline(false)
    setTimeout(() => setOnline(window.navigator.onLine), Math.round(reconnectInterval * 1000))
  }, [reconnectInterval])

  const onMessage = useCallback(({ data }: MessageEvent<string>) => {
    setHeartbeat((heartbeat) => (heartbeat + 1) % 2)

    const { parsed } = parseJson(data)

    if (parsed !== null && typeof parsed === 'object') {
      const { event, data } = parsed as Record<string, unknown>

      if (
        event === 'connected' &&
        data !== null && typeof data === 'object' &&
        'session_id' in data && typeof data.session_id === 'string'
      ) {
        const { session_id: sessionId } = data
        notifyService.request('event.activate', { sessionId }).then(
          (success) => success ? setSessionId(sessionId) : onError()
        )
      } else if (typeof event === 'string' && event in listeners) {
        listeners[event](data)
        setCurrentEvent({ slug: event, data })
      } else if (typeof event === 'string') {
        setCurrentEvent({ slug: event, data })
      }
    }
  }, [listeners, onError])

  useEffect(() => {
    if (online && source.current === undefined) {
      source.current = notifyService.eventSource(heartbeatInterval)
      source.current.onerror = onError
      source.current.onmessage = onMessage
      console.info('connected')
    }

    if (!online && source.current !== undefined) {
      source.current.close()
      source.current = undefined
      setSessionId(undefined)
      console.info('disconnected')
    }
  }, [heartbeatInterval, onError, onMessage, online])

  useEffect(() => {
    window.addEventListener('online', () => setOnline(true))
    window.addEventListener('offline', () => setOnline(false))
    window.addEventListener('beforeunload', () => setOnline(false))
  }, [])

  function setListener <Params> (event: string, listener: (params: Params) => void): void {
    setListeners((listeners) => ({ ...listeners, [event]: listener as Listener }))
  }

  function removeListener (event: string) {
    setListeners(({ [event]: listener, ...listeners }) => ({ ...listeners }))
  }

  return <EventContext.Provider value={{
    sessionId,
    heartbeat,
    currentEvent,
    setListener,
    removeListener
  }}>
    {children}
  </EventContext.Provider>
}

export function useEventContext () {
  const context = useContext(EventContext)

  if (context === null) {
    throw new Error('Used outside of "EventContextProvider"')
  }

  return context
}
