import './styles.sass'
import { shippingTruckRunLoads, ShippingTruckRunLoad, ShippingTruckRunStatus } from 'api/shipping'
import { useCallback, useEffect, useState } from 'react'
import { useMainRoutes } from 'routes'
import NavigatePanel from 'ui/NavigatePanel'
import InfoCard from 'ui/InfoCard'
import InfoCell, { InfoCellProps } from 'ui/InfoCell'
import { DAY } from 'constants/Time'
import { Box, Button, Stack, Typography } from '@mui/material'
import ChipStatus from 'ui/ChipStatus'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { Id } from 'api/Id'
import { personName } from 'util/personName'
import { useNavigate, useSearchParams } from 'react-router-dom'
import vehicleName from 'util/vehicleName'
import trailerName from 'util/trailerName'
import { SortOrder } from 'api/SortOrder'
import { Pieces } from 'util/Pieces'
import AddressFilter from 'ui/AddressFilter'
import { useAuthContext } from 'AuthContext'
import ForwarderLoadConfirmModal from './ForwarderLoadConfirmModal'
import TsToFormatDate from 'util/TsToFormatDate'
import addressName from 'util/addressName'
import { toTon } from 'util/weight'
import { today as getToday } from 'util/date'
import Badge from 'ui/Badge'
import TruckRunEditModel from 'ui/TruckRunEditModal'
import SquareButton from 'ui/SquareButton'
import { Edit } from '@mui/icons-material'
import { Interface } from 'api/Interface'
import { BidCargo, bidCargoList } from 'api/bid'
import LimitString from 'ui/LimitString'
import ForwarderLoadingReportsModel from 'ui/ForwarderLoadingReportsModel'
import ForwarderDriverListModel from 'ui/ForwarderDriverListModel'

const today = getToday()

interface ItemParams {
  data: ShippingTruckRunLoad
  onConfirm: (id: ShippingTruckRunLoad) => void
  onEdit?: (id: Id) => void
}

enum Tabs {
  confirmed = 'confirmed',
  loaded = 'loaded',
  outdated = 'outdated'
}

const TruckRunItem = ({ data, onConfirm, onEdit }: ItemParams) => {
  const navigate = useNavigate()
  const { links } = useMainRoutes()

  const left: InfoCellProps[] = [
    // { label: 'Дата погрузки', value: formatDate(new Date((data.loadingTs + tzOffset) * TS_IN_SECONDS), 'dd.MM') },
    { label: 'Водитель', value: `${personName(data.driver)}` },
    { label: 'ТС', value: vehicleName(data.vehicle, 'short') }
  ]

  const right: InfoCellProps[] = []

  if (data.status === ShippingTruckRunStatus.confirmed) {
    left.push({ label: 'Прицеп', value: trailerName(data.trailer) })

    // TODO
    // Раскомментировать  перед презентацией связки ролей Диспетчер, Перевозчик, Экспедитор
    // const { loadingTs } = data
    // const day = loadingTs - (loadingTs % DAY)
    const day = today

    if (day === today) {
      // right.push({
      //   value: <ForwarderLoadConfirm onConfirm={(weight: number, billNumber: string) => onConfirm(data.id, weight, billNumber)}/>,
      //   separator: false
      // })
      right.push({
        value: <Button variant='contained' onClick={() => onConfirm(data)} >Отправить</Button>,
        separator: false
      })
    }

    right.push({ value: <ChipStatus label='Ожидаемый' color='grey' />, separator: false })
  } else if (data.status === ShippingTruckRunStatus.way) {
    left.push({ label: 'Прицеп', value: trailerName(data.trailer) })
    left.push({ label: 'Дата ТрН/ТТН', value: data.billTs ? TsToFormatDate(data.billTs, 'dd.MM') : '-' })
    left.push({ label: 'Номер ТрН/ТТН', value: data.billNumber })
    left.push({ label: 'Вес погрузки, тн', value: <>{data.weight ? toTon(data.weight) : 0}</> })

    onEdit && right.push({
      value: <SquareButton size='small' variant='outlined' color='secondary' onClick={() => onEdit(data.id)} >
        <Edit style={{ color: '#94A3B8', width: '16px' }} />
      </SquareButton>,
      separator: false
    })
    right.push({ value: <ChipStatus label='Погружено' color='blue' />, separator: false })
  } else if (data.status === ShippingTruckRunStatus.archiveOutdated) {
    left.push({ label: 'Прицеп', value: trailerName(data.trailer) })

    right.push({
      value: <Button variant='contained' onClick={() => onConfirm(data)} >Отправить</Button>,
      separator: false
    })
    right.push({ value: <ChipStatus label='Просрочен' color='red' />, separator: false })
  } else {
    left.push({ label: 'Прицеп', value: trailerName(data.trailer) })
    left.push({ label: 'Дата ТрН/ТТН', value: data.billTs ? TsToFormatDate(data.billTs, 'dd.MM') : '-' })
    left.push({ label: 'Номер ТрН/ТТН', value: data.billNumber })
    left.push({ label: 'Вес погрузки, тн', value: <>{data.weight ? toTon(data.weight) : 0}</> })

    right.push({ value: <ChipStatus label='Завершенный' color='green' />, separator: false })
  }

  left.push({ label: 'Перевозчик', value: data.carrierName })
  left.push({ label: 'Заказчик', value: <LimitString text={data.customer.fullName} limit={20} /> })

  right.push({
    value: <Button
      size='small'
      variant="contained"
      onClick={() => navigate(`${links.FORWARDER_LOADS_PAGE}/${data.id}`)}
      sx={{
        minWidth: '32px',
        height: '32px',
        background: '#B2B2B2',
        borderRadius: '4px',
        color: '#fff',
        border: 'none',
        padding: 0,
        '&:hover': {
          background: '#B2B2B2',
          border: 'none',
          filter: 'brightness(96%)'
        }
      }}
    >
      <MoreHorizIcon sx={{ fontSize: '16px' }}/>
    </Button>,
    separator: false
  })

  return <InfoCard>
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between'
    }}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row'
      }}>
      { left.map((item, idx) => <InfoCell {...item} key={idx} />) }
      </Box>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row'
      }}>
      { right.map((item, idx) => <InfoCell {...item} key={idx} />) }
      </Box>
    </Box>
  </InfoCard>
}

const getStatInfo = (
  tabs: Tabs,
  data: { totalWeight: number, totalTruckRuns: number }) => {
  const { totalTruckRuns, totalWeight } = data
  const weight = toTon(totalWeight)
  const count = `${totalTruckRuns} ${Pieces(totalTruckRuns, 'рейс', 'рейса', 'рейсов')}`

  switch (tabs) {
    case Tabs.loaded:
      return <>
        <InfoCell label='Всего' value={count} />
        <InfoCell label='Погружено' value={`${weight} тн`} />
      </>
    case Tabs.confirmed:
    case Tabs.outdated:
      return <>
      <InfoCell label='Всего' value={count} />
      <InfoCell label='На вывоз' value={`${weight} тн`} />
    </>
    default:
      return <></>
  }
}

export enum SEARCH_PARAMS {
  tab = 'tab'
}

const isTab = (v: unknown): v is Tabs => {
  return typeof v === 'string' && Object.values<string>(Tabs).includes(v)
}

export default function ForwarderLoads () {
  const [list, setList] = useState<Map<number, Map<string, ShippingTruckRunLoad[]>>>()
  const { links, routesMap } = useMainRoutes()
  const [currentTab, setCurrentTab] = useState<Tabs>(Tabs.confirmed)
  const [statistics, setStatistics] = useState({ totalWeight: 0, totalTruckRuns: 0 })
  const [filterAddress, setFilterAddress] = useState<Id[]>()
  const [driverListModel, setDriverListModel] = useState<boolean>(false)
  const [loadingReportsModel, setLoadingReportsModel] = useState<boolean>(false)
  const [pointAddressId, setPointAddressId] = useState<Id>()
  const [loadConfrim, setLoadConfrim] = useState<ShippingTruckRunLoad>()
  const [outdated, setOutdated] = useState(0)
  const [editId, setEditId] = useState<Id>()
  const [cargoList, setCargoList] = useState<BidCargo[]>([])

  const { currentInterface } = useAuthContext()

  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const init = useCallback(() => {
    bidCargoList().then(setCargoList)
    shippingTruckRunLoads(
      currentTab === Tabs.confirmed
        ? {
            filters: {
              loadingTsFrom: today - 360 * DAY,
              loadingTsTo: today + 360 * DAY,
              status: [ShippingTruckRunStatus.confirmed],
              loadingAddressIds: filterAddress
            },
            sort: {
              loadingTs: SortOrder.asc
            }
          }
        : currentTab === Tabs.outdated
          ? {
              filters: {
                loadingTsFrom: today - 360 * DAY,
                loadingTsTo: today + 360 * DAY,
                status: [ShippingTruckRunStatus.archiveOutdated],
                loadingAddressIds: filterAddress
              },
              sort: {
                loadingTs: SortOrder.asc
              }
            }
          : {
              filters: {
                loadingTsFrom: today - 360 * DAY,
                loadingTsTo: today + 360 * DAY,
                status: [ShippingTruckRunStatus.way],
                loadingAddressIds: filterAddress
              },
              sort: {
                billTs: SortOrder.desc,
                loadingTs: SortOrder.desc
              }
            }
    ).then(({ count: totalTruckRuns, list, totalWeight, outdated }) => {
      const byDay = new Map<number, Map<string, ShippingTruckRunLoad[]>>()

      setStatistics({ totalTruckRuns, totalWeight })
      setOutdated(outdated)

      for (const item of list) {
        const { loadingTs, billTs = loadingTs } = item
        const day = currentTab === Tabs.confirmed ? loadingTs - (loadingTs % DAY) : billTs - (billTs % DAY)
        const address = `${item.loadingAddress.name}; ${addressName(item.loadingAddress)}`
        const dayItems = byDay.get(day) ?? new Map<string, ShippingTruckRunLoad[]>()
        const addressItems = dayItems.get(address) ?? []
        addressItems.push(item)
        dayItems.set(address, addressItems)
        byDay.set(day, dayItems)
      }

      setList(byDay)
    })
  }, [currentTab, filterAddress])

  useEffect(() => init(), [init])

  useEffect(() => {
    const tab = searchParams.get(SEARCH_PARAMS.tab)
    if (isTab(tab)) {
      setCurrentTab(tab)
    }
  }, [searchParams])

  useEffect(() => {
    if (filterAddress && filterAddress.length > 0) {
      setPointAddressId(filterAddress[0])
    }
  }, [filterAddress])

  const changeTab = (value: string) => {
    const tab = value as Tabs
    setCurrentTab(tab)
    navigate(`?tab=${tab}`)
  }

  const handleCloseConfrimModel = () => {
    setLoadConfrim(undefined)
  }

  const handleEdit = ({ status }: ShippingTruckRunLoad, ui?: Interface) => {
    return (cb: (id: Id) => void) => {
      if (ui !== Interface.chief_forwarder) {
        return
      }

      if (status !== ShippingTruckRunStatus.way) {
        return
      }

      return cb
    }
  }

  if (list === undefined) {
    return <></>
  }

  return (
    <>
      <div className='forwarderLoads'>
        <NavigatePanel
          title='Мои погрузки'
          breadcrumbs={{
            items: routesMap.getBreadcrumbs(links.FORWARDER_LOADS_PAGE)
          }}
          tabs={{
            items: [
              { label: 'Ожидаемые', value: Tabs.confirmed },
              { label: 'Погруженные', value: Tabs.loaded },
              { label: <Stack direction='row' spacing={1}><span>Просроченные</span><Badge count={outdated} /></Stack>, value: Tabs.outdated }
            ],
            value: currentTab,
            onChange: changeTab
          }}
          actions={<Stack direction='row' spacing={1} justifyContent='flex-end'>
            <Button variant='contained' onClick={() => setLoadingReportsModel(true)}>Отчет о погрузке</Button>
            <Button variant='contained' onClick={() => setDriverListModel(true)}>Скачать реестр ТС</Button>
          </Stack>}
        />
        <Stack direction='row' spacing={2} p='15px 1.5em' borderBottom='1px solid #E2E8F0' sx={{ background: '#FFFFFF' }}>
          <Stack width='20%'>
            <AddressFilter onChangeFilter={ids => {
              setFilterAddress(ids.length > 0 ? ids : undefined)
            }} selectAddress={filterAddress ?? []} />
          </Stack>
        </Stack>
        <div className='forwarderLoads__content'>
          <div className='forwarderLoads__content__items'>
          <InfoCard style={{ marginBottom: '1em' }} >
            <Stack direction='row' spacing={2}>{getStatInfo(currentTab, statistics)}</Stack>
          </InfoCard>
            {
              [...list].length > 0
                ? [...list].map(([day, list]) => <Box key={day} sx={{ marginBottom: '1em' }}>
                  <Typography sx={{
                    fontWeight: '500',
                    fontSize: '13px',
                    lineHeight: '16px',
                    letterSpacing: '-0.01em',
                    color: '#B2B2B2'
                  }}>
                    {day === today ? 'Сегодня' : day === today + DAY ? 'Завтра' : TsToFormatDate(day, 'd MMMM')}
                  </Typography>
                  {[...list].map(([address, list]) => <Box key={address}>
                    <Typography sx={{
                      fontWeight: '600',
                      fontSize: '15px',
                      lineHeight: '18px',
                      letterSpacing: '-0.01em',
                      color: '#111111',
                      pt: 1
                    }}>
                      {address}
                    </Typography>
                    {list.map((item, idx) => <TruckRunItem
                      key={idx}
                      data={item}
                      onConfirm={setLoadConfrim}
                      onEdit={handleEdit(item, currentInterface)(setEditId)}
                    />)}
                  </Box>)}
                </Box>)
                : <Box sx={{
                  mt: '10px',
                  background: '#EBEBEB',
                  borderRadius: '8px',
                  padding: '32px 24px',
                  color: '#111'
                }}>
                  <Typography sx={{
                    lineHeight: '22px',
                    letterSpacing: '-0.02em'
                  }}>
                    В настоящий момент отсутствуют погрузки в статусе “{currentTab === Tabs.confirmed ? 'Ожидаемый' : currentTab === Tabs.outdated ? 'Просроченные' : 'Погружено'}“
                  </Typography>
                </Box>
            }
          </div>
        </div>
      </div>
      <ForwarderDriverListModel
        open={driverListModel}
        onClose={() => setDriverListModel(false)}
        curPointAddressId={pointAddressId}
      />
      <ForwarderLoadingReportsModel
        open={loadingReportsModel}
        onClose={() => setLoadingReportsModel(false)}
      />
      <ForwarderLoadConfirmModal
        truckRun={loadConfrim}
        cargoList={cargoList}
        onClose={handleCloseConfrimModel}
        onSuccess={() => {
          init()
          handleCloseConfrimModel()
        }}
      />
      <TruckRunEditModel
        cargoList={cargoList}
        id={editId}
        onClose={() => setEditId(undefined)}
        onSave={init}
      />
    </>
  )
}
