import './styles.sass'
import { Box, Button, Card, CardContent, Container, Stack, Typography } from '@mui/material'
import { useAuthContext } from 'AuthContext'

export default function SuccessPreRegistration () {
  const { startPostRegistration, logout } = useAuthContext()

  return (
    <Container maxWidth='sm'>
      <Box pb={6} pt={2}>
        <Card sx={{ border: '1px solid #EBEBEB' }}>
          <CardContent sx={{ m: '1em' }}>
            <Typography fontSize='21px' fontWeight={700}>
              Номер телефона подтвержден в системе
            </Typography>
            <Typography fontSize='14px' fontWeight={500} color='#B2B2B2'>
              Прежде чем начать работу в системе АО “Астон, создайте и заполните свой профиль.
            </Typography>
            <Stack mt={4} direction='column' spacing={2}>
              <Button size='large' variant='contained' fullWidth onClick={ () => { startPostRegistration() } }>Создать профиль</Button>
              <Button size='large' variant='text' color='inherit' fullWidth onClick={ () => { logout() } }>Выйти</Button>
            </Stack>
          </CardContent>
        </Card>
      </Box>
    </Container>
  )
}
