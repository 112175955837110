import { mainService } from 'api/service'
import { DriverAll } from './driver'
import { OrganizationAll } from './organization'
import { VehicleAll } from './vehicle'

export interface DashboardConfirmationRequests {
  driver?: DriverAll & { profileId: string }
  vehicle?: VehicleAll & { profileId: string }
  carrier?: OrganizationAll & { profileId: string }
}

export const isDriver = (x: DashboardConfirmationRequests): boolean => x.driver !== undefined
export const isVehicle = (x: DashboardConfirmationRequests): boolean => x.vehicle !== undefined
export const isCarrier = (x: DashboardConfirmationRequests): boolean => x.carrier !== undefined

const request = (method: string, params: object) => mainService.request(`dashboard.${method}`, params)

export const dashboardConfirmationRequests = () => request('confirmation-requests', {}) as Promise<DashboardConfirmationRequests[]>
