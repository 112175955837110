import { Visibility } from '@mui/icons-material'
import { Box, Button, Stack, Typography } from '@mui/material'
import { ProfileAll, profileAllContractVerify, ProfileContract, ProfileContractStatus } from 'api/profile'
import { useAuthContext } from 'AuthContext'
import { useMemo, useState } from 'react'
import InfoCard from 'ui/InfoCard'
import Modal from 'ui/Modal'
import TextField from 'ui/TextField'
import download from 'util/download'
import TsToFormatDate from 'util/TsToFormatDate'

export interface Params {
  profile?: ProfileAll
  onSuccess: () => void
}

export default function ContractsVerification ({ profile, onSuccess }: Params) {
  const { handleResponseSuccess } = useAuthContext()
  const [open, setOpen] = useState<ProfileContract>()
  const [comment, setComment] = useState<string>()
  const [error, setError] = useState<string>()

  const list = useMemo(() => {
    if (profile?.contracts === undefined) {
      return []
    }

    return profile.contracts.filter(({ status }) => status === ProfileContractStatus.verification)
  }, [profile?.contracts])

  const handleApprove = ({ customer }: ProfileContract) => {
    if (profile === undefined) {
      return
    }

    profileAllContractVerify({
      id: profile.id,
      customerId: customer.id,
      status: ProfileContractStatus.signed
    }).then(success => {
      if (success) {
        handleResponseSuccess('Заявление одобрено')
        onSuccess()
      }
    })
  }

  const handleClose = () => {
    setOpen(undefined)
    setComment(undefined)
    setError(undefined)
  }

  const handleConfrim = () => {
    if (profile === undefined || open === undefined) {
      return
    }

    if (comment === undefined || comment.length === 0) {
      setError('Обязательно для заполнения')
      return
    }

    profileAllContractVerify({
      id: profile.id,
      customerId: open.customer.id,
      status: ProfileContractStatus.rejected,
      comment
    }).then(success => {
      if (success) {
        handleResponseSuccess('Заявление отклонено')
        handleClose()
        onSuccess()
      }
    })
  }

  return (<>
    {list.map(item => (<InfoCard key={item.customer.id}>
      <Stack direction='row' justifyContent='space-between'>
        <Box>
          <Typography sx={{
            fontWeight: '600',
            fontSize: '18px',
            lineHeight: '22px',
            letterSpacing: '-0.02em',
            mb: 1
          }}>Заявление от {TsToFormatDate(item.createTs, 'dd.MM.yyyy')}</Typography>
          <Typography sx={{
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '24px',
            letterSpacing: '-0.02em'
          }}>
            Перевозчик отправил заявление, к договору оферты для {item.customer.fullName}
          </Typography>
          <Stack direction='row' gap={1}>
            { item.customer.scanCarrierOffer && <Button
              variant='contained'
              color='secondary'
              onClick={() => download(item.customer.scanCarrierOffer as string)}
              endIcon={<Visibility />}
            >Договор оферты</Button> }
            { item.filename && <Button
              variant='contained'
              color='secondary'
              onClick={() => download(item.scanSignedСontract as string)}
              endIcon={<Visibility />}
            >Просмотреть заявление</Button> }
          </Stack>
        </Box>
        <Stack direction='row' flexWrap='wrap' alignContent='center' gap={2}>
          <Button size='small' variant='contained' color='error' onClick={() => setOpen(item)}>Отказать</Button>
          <Button size='small' variant='contained' onClick={() => handleApprove(item)}>Подтвердить</Button>
        </Stack>
      </Stack>
    </InfoCard>))}
    <Modal
        title='Отклонить заявление'
        open={!!open}
        onClose={handleClose}
        content={<Stack sx={{ minWidth: '500px' }} >
          <TextField
            rows={6}
            label='Укажите причину'
            width='100%'
            value={comment}
            onChange={e => { setComment(e.target.value) }}
            errorMessage={error}
          />
        </Stack>
        }
        actions={<>
          <Button color='secondary' variant='outlined' onClick={handleClose}>Отменить</Button>
          <Button variant='contained' onClick={handleConfrim} >Подтвердить</Button>
        </>
        }
      />
  </>)
}
