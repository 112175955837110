import './styles.sass'
import { useEffect, useMemo, useState } from 'react'
import { addressList as apiAddressList, Address as AddressOrigin, AddressStatus, AddressPointType } from 'api/address'
import { Id, isId } from 'api/Id'
import { ShortAddress } from 'ui/ShortAddress'
import Select from 'ui/Select'
import { Stack, Typography } from '@mui/material'
import { ValidateError } from 'validation/validate'
import AntSwitch from 'ui/AntSwitch'

interface Props {
  label?: string
  name?: string
  placeholder?: string
  value?: Id
  onChange: (addressId: Id, address?: AddressOrigin) => void
  width?: string
  disabled?: boolean
  errors?: ValidateError
  errorMessage?: string
  itemName?: boolean
  setCurrentAddress?: React.Dispatch<React.SetStateAction<AddressOrigin | undefined>>
  excludeIds?: Id[]
  showIsPartSwitch?: boolean
  itemsDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse' | 'inherit' | 'initial'
  pointType?: AddressPointType
  partOfParent?: boolean
}

export default function SelectAddress ({
  name,
  label = 'Элеватор/хозяйство',
  value,
  width,
  itemName,
  onChange,
  disabled,
  placeholder,
  errors,
  errorMessage,
  setCurrentAddress,
  excludeIds = [],
  showIsPartSwitch = true,
  itemsDirection = 'row',
  pointType,
  partOfParent = false
}: Props) {
  const [list, setList] = useState<AddressOrigin[]>([])
  const [isPart, setIsPart] = useState<boolean>(partOfParent)

  const options = useMemo(() => list
    .filter((item) => {
      const part = !showIsPartSwitch || pointType === AddressPointType.farming || (isPart === (item.isPart ?? false))
      const excl = !excludeIds.includes(item.id)
      const pt = !pointType || item.pointType === pointType

      return part && excl && pt
    })
    .map((item) => ({
      value: item.id,
      name: (itemName && item.name) ? item.name : [item.name, item.city, item.district, item.region].filter(Boolean).join(', '),
      optionName: (itemName && item.name) ? undefined : <ShortAddress address={item} fontSize='13px' fontWeight={500} key={item.id}/>
    })),
  [list, showIsPartSwitch, isPart, pointType, excludeIds, itemName])

  useEffect(() => {
    apiAddressList({ filters: { status: AddressStatus.active } }).then(setList)
  }, [])

  useEffect(() => {
    if (setCurrentAddress && isId(value)) {
      setCurrentAddress(list.find(({ id }) => id === value))
    }
  }, [value, setCurrentAddress, list])

  return <div className='selectAddress' style={{ width, flexDirection: itemsDirection }}>
    {showIsPartSwitch && <Stack direction="row" alignItems="center" gap={1}>
      <AntSwitch value={isPart} checked={isPart} onChange={(_event, checked) => setIsPart(checked)} disabled={pointType === AddressPointType.farming || disabled} />
      <Typography sx={{ whiteSpace: 'nowrap', color: pointType === AddressPointType.farming ? '#EBEBEB' : undefined }}>В ГК "Астон"</Typography>
    </Stack>}
    <Stack sx={{ width: '100%' }}>
      <Select
        name={name}
        label={label}
        placeholder={placeholder}
        options={options}
        value={value}
        onChange={(value) => onChange(value, list.find(({ id }) => id === value))}
        disableClearable={true}
        disabled={disabled}
        errors={errors}
        errorMessage={errorMessage}
        paper={{ maxHeight: '400px' }}
      />
    </Stack>
  </div>
}
