import './styles.sass'
import NavigatePanel from 'ui/NavigatePanel'
import DataTable, { Column } from 'ui/DataTable'
import { useMainRoutes } from 'routes'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import VisibilityIcon from '@mui/icons-material/Visibility'
import ActionsMenu from 'ui/ActionsMenu'
import { isEnum } from 'util/isEnum'
import { ShippingSentToKisStatus, ShippingTruckRun, shippingTruckRunList, ShippingTruckRunListParams, ShippingTruckRunStatus } from 'api/shipping'
import { SortOrder } from 'api/SortOrder'
import { toTon } from 'util/weight'
import ChipTruckRunStatus from 'common/ChipTruckRunStatusForBid'
import SentToKisStatus from 'ui/SentToKisStatus'
import TsToFormatDate from 'util/TsToFormatDate'
import { Button } from '@mui/material'
import { FileUpload } from '@mui/icons-material'
import vehicleName from 'util/vehicleName'
import trailerName from 'util/trailerName'
import runTruckConvertToXLSXFormat from 'util/runTruckConvertToXLSXFormat'
import generateXLSXFile from 'util/generateXLSXFile'

const statusMap = new Map<ShippingTruckRunStatus | '', string>()
  .set('', 'Все')
  .set(ShippingTruckRunStatus.confirmed, 'Запланировано')
  .set(ShippingTruckRunStatus.way, 'В пути')
  .set(ShippingTruckRunStatus.arrived, 'Выгружено')
  .set(ShippingTruckRunStatus.completed, 'Завершено')
  .set(ShippingTruckRunStatus.archiveAct, 'Архив')
  .set(ShippingTruckRunStatus.archiveOutdated, 'Просрочено')
  .set(ShippingTruckRunStatus.archiveProblem, 'Завершено диспетчером')

const downloadXlsxFile = async () => {
  try {
    const data = await shippingTruckRunList({ sort: { createTs: SortOrder.asc } })
    const convertedData = runTruckConvertToXLSXFormat(data)
    await generateXLSXFile(convertedData, 'export_truck_runs')
  } catch (error) {
    console.error('Error generating XLSX file:', error)
  }
}

const RegistryTruckRuns = () => {
  const { links, routesMap } = useMainRoutes()
  const [rows, setRows] = useState<ShippingTruckRun[]>()
  const [searched, setSearched] = useState<(number|string)[]>()
  const navigate = useNavigate()

  const { status } = useParams()
  const filterStatus = isEnum(ShippingTruckRunStatus)(status) ? status : ''

  const init = useCallback(async () => {
    const filters: ShippingTruckRunListParams['filters'] = {}

    if (filterStatus.length !== 0) {
      filters.status = filterStatus !== '' ? filterStatus !== ShippingTruckRunStatus.archiveAct ? [filterStatus] : [ShippingTruckRunStatus.archiveAct, ShippingTruckRunStatus.archiveRefuse, ShippingTruckRunStatus.archiveReject] : undefined
    }

    shippingTruckRunList({ filters, sort: { createTs: SortOrder.asc } }).then(setRows)
  }, [filterStatus])

  useEffect(() => { init() }, [init])

  const columns: Column[] = []

  columns.push({
    id: ['carrierName'],
    label: 'Перевозчик',
    format: (carrierName) => <>{carrierName}</>
  })

  columns.push({
    id: ['num'],
    label: 'Номер рейса',
    format: (num) => <>{num}</>
  })

  columns.push({
    id: ['forwarder'],
    label: 'Экспедитор',
    format: (forwarder) => <>{forwarder}</>
  })

  columns.push({
    id: ['vehicle', 'trailer'],
    label: 'ТС,Прицеп',
    format: (_vehicle, _trailer) => {
      const vehicle = _vehicle as ShippingTruckRun['vehicle']
      const trailer = _trailer as ShippingTruckRun['trailer']
      return <>

         <>{vehicleName(vehicle)}</>
        {
          trailer ? <>, {trailerName(trailer)}</> : null
        }
      </>
    }
  })

  columns.push({
    id: ['weight'],
    label: 'Вес погрузки',
    format: (weight) => weight ? <>{toTon(weight as number)} тн</> : <></>
  })

  columns.push({
    id: ['loadingTs', 'actualLoadingTs'],
    label: 'Дата погрузки',
    format: (loadingTs, actualLoadingTs) => {
      const date = (actualLoadingTs || loadingTs) as number
      return <>{TsToFormatDate(date, 'dd.MM.yyyy')}</>
    }
  })

  columns.push({
    id: ['billNumber'],
    label: 'Номер ТрН/ТТН',
    format: (billNumber) => <>{billNumber}</>
  })

  columns.push({
    id: ['finalWeight'],
    label: 'Вес разгрузки',
    format: (finalWeight) => finalWeight ? <>{toTon(finalWeight as number)} тн</> : <></>
  })
  columns.push({
    id: ['unloadingTs'],
    label: 'Дата разгрузки',
    format: (unloadingTs) => <>{unloadingTs ? TsToFormatDate(unloadingTs as number, 'dd.MM.yyyy') : ''}</>
  })

  if (['', ShippingTruckRunStatus.archiveAct].includes(filterStatus)) {
    columns.push(
      {
        id: ['status'],
        label: 'Статус',
        format: (item) => ChipTruckRunStatus(item as ShippingTruckRunStatus)
      },
      {
        id: ['sentToKisStatus'],
        label: 'КИС',
        format: (item) => (<SentToKisStatus status={item as ShippingSentToKisStatus} />)
      }
    )
  }

  columns.push({
    id: ['id'],
    label: '',
    align: 'right',
    format: (id) => <ActionsMenu key={`${id}`} actions={
      [
        {
          caption: 'Посмотреть',
          onClick: () => navigate(`${links.CARRIER_TRIPS_PAGE}/${id}`),
          icon: <VisibilityIcon />
        }
      ]
    } />
  })

  return <>
    <div className='registryTruckRuns'>
      <NavigatePanel
        search={{
          rows: rows === undefined ? [] : rows,
          fields: [
            'carrierName',
            'num',
            'forwarder',
            'numberRegion',
            'weight',
            'billNumber',
            'finalWeight'
          ],
          onChange: setSearched
        }}
        title='Рейсы'
        breadcrumbs={{
          items: routesMap.getBreadcrumbs(links.REGISTRY_TRUCK_RUNS_PAGE)
        }}
        tabs={{
          items: [...statusMap].map(([value, label]) => ({ label, value })),
          value: filterStatus,
          onChange: value => navigate(links.REGISTRY_TRUCK_RUNS_PAGE + (value === '' ? '' : `/${value}`)),
          scrollable: true
        }}
        actions={<>
          { filterStatus === '' && <Button variant='outlined' color="secondary" size='small' endIcon={<FileUpload />} onClick={downloadXlsxFile}>
            Экспортировать
          </Button> }
        </>}
      />
      <div className='registryTruckRuns__content'>
        <div className='registryTruckRuns__content_table'>
          <DataTable
            columns={columns}
            rows = { (start, end) => rows === undefined
              ? []
              : rows
                .filter(row => searched === undefined ? true : searched.includes(row.id)).slice(start, end) }
            rowsCount = { rows === undefined ? 0 : (searched === undefined ? rows.length : searched.length) }
          />
        </div>
      </div>
    </div>
  </>
}

export default RegistryTruckRuns
