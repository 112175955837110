import { Stack, Typography } from '@mui/material'
import { ConsignorConsigneeIncomplete as Incomplete } from 'api/consignorConsignee'

export const incompleteMap: Map<keyof Incomplete, string> = new Map()
incompleteMap.set('innEmpty', 'ИНН')
incompleteMap.set('kppEmpty', 'КПП')
incompleteMap.set('okpoEmpty', 'ОКПО')
incompleteMap.set('fullNameEmpty', 'Полное ​наименование организации')
incompleteMap.set('externalTypeEmpty', 'Тип в КИС')
incompleteMap.set('legalAddressEmpty', 'Юридический адрес')
incompleteMap.set('phonesEmpty', 'Телефон')
incompleteMap.set('checkingAccountNumberEmpty', 'Номер расчетного счета')
incompleteMap.set('bankNameEmpty', '​Наименование банка')
incompleteMap.set('bikEmpty', 'БИК')
incompleteMap.set('correspondentAccountNumberEmpty', 'Номер корреспондентского счета')

export default function ConsignorConsigneeIncomplete ({ data }: { data: Incomplete }) {
  const keys = Object.keys(data) as (keyof Incomplete)[]
  return (<>
    <Stack direction='column'>
    <Typography fontSize='14px' fontWeight={600}>Необходимо заполнить следующие поля:</Typography>
    { keys.map((key, idx) => (data[key] && <Typography fontSize='14px' fontWeight={400} key={idx}>{incompleteMap.get(key)}</Typography>)) }
    </Stack>
  </>)
}
