import './styles.sass'
import clsx from 'clsx'
import { ReactNode } from 'react'

export interface Props {
  variant?: 'filled' | 'outlined'
  color?: 'grey' | 'lightGrey' | 'blue' | 'red' | 'yellow' | 'green' | 'yellowBorder'
  label: ReactNode
  style?: React.CSSProperties
}

export default function ChipStatus ({ variant, color, label, style }: Props) {
  return (
    <div className={clsx('chipStatus', {
      chipStatus__outlined: variant === 'outlined',
      chipStatus__color_lightGrey: color === 'lightGrey',
      chipStatus__color_red: color === 'red',
      chipStatus__color_blue: color === 'blue',
      chipStatus__color_yellow: color === 'yellow',
      chipStatus__color_green: color === 'green',
      chipStatus__color_yellowBorder: color === 'yellowBorder'
    })} style={style}>{label}</div>
  )
}
