import ChipStatus from 'ui/ChipStatus'

export default function ChipActivStatus <T = 'active' | 'archive'> ({ status }: { status: T }) {
  switch (status) {
    case 'archive':
      return <ChipStatus color='lightGrey' label='В архиве' />
    case 'active':
      return <ChipStatus color='green' label='Активный' />
    default:
      return <></>
  }
}
