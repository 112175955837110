import './styles.sass'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { Paper, Stack, Typography, Box } from '@mui/material'
import { Row } from 'ui/DataTable'
import { StepProps } from 'ui/ModalSigning'
import { ActWizardData } from './index'
import { Id } from 'api/Id'
import { ActForCreateNumberingFailureErrors as Errors, ActForCreateNumberingFailureItem } from 'api/act'
import { Numbering } from '.'
import addressName from 'util/addressName'
import TextField from 'ui/TextField'
import DatePicker from 'ui/DatePicker'
import { clear, dayStart, now } from 'util/date'

export const STEP = 6

const EMPTY_ERROR_MESSAGE = 'Необходимо ввести номер'
const COINCIDENCES_ERROR_MESSAGE = 'Номера не должны повторяться'
const USED_BEFORE_ERROR_MESSAGE = 'Введенный номер использовался раньше, укажите другой'
const INCORRECT_DATE_MESSAGE = 'Некорректная дата'

export const failureMap = (numbering: ActForCreateNumberingFailureItem[]): Numbering[] => {
  return numbering.map(({ error, ...obj }) => {
    let errorMessage: string | undefined

    if (error === Errors.empty) {
      errorMessage = EMPTY_ERROR_MESSAGE
    }

    if (error === Errors.repeating) {
      errorMessage = COINCIDENCES_ERROR_MESSAGE
    }

    if (error === Errors.usedBefore) {
      errorMessage = USED_BEFORE_ERROR_MESSAGE
    }

    return {
      ...obj,
      error,
      errorMessage
    }
  })
}

export const clearNumbering = (numbering: Numbering[]): Numbering[] => {
  return numbering.map(({ errorMessage, disabled, error, ...fields }) => fields)
}

export default function SetNumbering ({ isActive, data }: StepProps) {
  const [completedRows, setCompletedRows] = useState<Row[]>([])
  const once = useRef(true)

  useEffect(() => {
    if (isActive && once.current) {
      once.current = false
      const d = data as ActWizardData
      if (d.shippingList && d.numbering.length === 0) {
        d.onNumbering(d.shippingList.flatMap(item => {
          const groups = [...new Map(item.truckRuns.map(({ unloadingAddress, consignee }) =>
            [`${unloadingAddress?.id}:${consignee?.id}`, {
              unloadingAddressId: unloadingAddress?.id,
              consigneeId: consignee?.id
            }])).values()]

          const lastUnloadingTs = Math.max(...item.truckRuns.map(({ unloadingTs }) => unloadingTs ?? 0))

          return groups.map((group) => {
            const act = item.newActs?.find(({ unloadingAddressId, consigneeId }) =>
              group.unloadingAddressId === unloadingAddressId && group.consigneeId === consigneeId)
            return {
              bidId: item.bid.id,
              unloadingAddressId: group.unloadingAddressId,
              consigneeId: group.consigneeId,
              num: act ? act.num : '',
              dateTs: act ? act.dateTs : lastUnloadingTs ?? now(),
              disabled: act !== undefined
            }
          })
        }))
      }
    }
  }, [data, isActive])

  useEffect(() => {
    const d = data as ActWizardData

    if (d === undefined || d.shippingList === undefined) {
      return
    }

    setCompletedRows(d.shippingList.flatMap((item, i) => {
      const groups = [...new Map(item.truckRuns.map(({ unloadingAddress, consignee }) =>
        [`${unloadingAddress?.id}:${consignee?.id}`, {
          unloadingAddress,
          consignee
        }])).values()]
      const lastUnloadingTs = Math.max(...item.truckRuns.map(({ unloadingTs }) => unloadingTs ?? 0))

      return groups.map((group, j) => {
        const numbering = d.numbering.find(({ bidId, unloadingAddressId, consigneeId }) =>
          bidId === item.bid.id && unloadingAddressId === group.unloadingAddress?.id && consigneeId === group.consignee?.id)

        return {
          id: `shipping_${i}:${j}`,
          bidDesc: item.bid.num,
          bidId: item.bid.id,
          customer: item.bid.customer.fullName,
          unloadingAddress: group.unloadingAddress,
          consignee: group.consignee,
          error: numbering?.error,
          errorMessage: numbering?.errorMessage ?? '',
          lastUnloadingTs
        }
      })
    }))
  }, [data])

  const updateNum = (id: Id, unloadingAddressId?: Id, consigneeId?: Id) => {
    const { numbering, onNumbering } = data as ActWizardData
    return ({ target: { value: num } }: ChangeEvent<HTMLInputElement>) => {
      if (num.match(/^[ .А-Яа-я0-9-]+$/g) || num === '') {
        const prev = numbering.find(item => item.bidId === id && item.unloadingAddressId === unloadingAddressId && item.consigneeId === consigneeId)
        const dateTs = prev?.dateTs ?? now()
        const result = numbering.filter(item => item.bidId !== id || item.unloadingAddressId !== unloadingAddressId || item.consigneeId !== consigneeId)
        result.push({ bidId: id, unloadingAddressId, consigneeId, dateTs, num })

        onNumbering(result)
      }
    }
  }

  const updateDate = (id: Id, unloadingAddressId?: Id, consigneeId?: Id) => {
    const { numbering, onNumbering } = data as ActWizardData
    return (dateTs: number) => {
      const prev = numbering.find(item => item.bidId === id && item.unloadingAddressId === unloadingAddressId && item.consigneeId === consigneeId)
      const num = prev?.num ?? ''
      const result = numbering.filter(item => item.bidId !== id || item.unloadingAddressId !== unloadingAddressId || item.consigneeId !== consigneeId)
      result.push({ bidId: id, unloadingAddressId, consigneeId, dateTs, num })

      onNumbering(result)
    }
  }

  const getValue = (id: Id, unloadingAddressId?: Id, consigneeId?: Id) => {
    const d = data as ActWizardData
    const row = d.numbering.find(item => item.bidId === id && item.unloadingAddressId === unloadingAddressId && item.consigneeId === consigneeId)
    return {
      num: row ? row.num : '',
      dateTs: row ? row.dateTs : now()
    }
  }

  const isDisabled = (id: Id, unloadingAddressId?: Id, consigneeId?: Id) => {
    const d = data as ActWizardData
    const row = d.numbering.find(item => item.bidId === id && item.unloadingAddressId === unloadingAddressId && item.consigneeId === consigneeId)
    return row ? row.disabled : false
  }

  const shouldDisableDate = (lastUnloadingTs: number) => {
    return (day: Date) => {
      const d = clear(day.getTime())
      return d < dayStart(lastUnloadingTs) || d > now()
    }
  }

  if (!isActive) {
    return <></>
  }

  return (
    <Stack direction='column'>
      <Box flexGrow={3}>
        <Typography fontSize='14px' fontWeight={400} color='#B2B2B2' mb='2em'>
        Для формирования документов необходимо ввести номера документов.
        </Typography>
        <Box component={Paper} sx={{ mt: 2 }}>
          <Stack direction='column' gap={2}>
            {completedRows.map((row) => (<Stack key={row.id} direction='column'>
              <Stack direction='row' gap={2}>
                <Typography fontWeight={600} fontSize='14px'>Перевозка {row.bidDesc}</Typography>
                <Typography fontWeight={400} fontSize='14px' color='#B2B2B2'>{row.customer}</Typography>
                {row.unloadingAddress &&
                  <Typography fontWeight={400} fontSize='14px' color='#B2B2B2'>Переадресован: {addressName(row.unloadingAddress)}</Typography>
                }
              </Stack>
              <Stack direction='row' gap={1}>
                <TextField
                  label='Номер УПД'
                  value={getValue(row.bidId, row.unloadingAddress?.id, row.consignee?.id).num}
                  onChange={updateNum(row.bidId, row.unloadingAddress?.id, row.consignee?.id)}
                  disabled={isDisabled(row.bidId, row.unloadingAddress?.id, row.consignee?.id)}
                  errorMessage={row.errorMessage}
                />
                <DatePicker
                  label='Дата УПД'
                  value={getValue(row.bidId, row.unloadingAddress?.id, row.consignee?.id).dateTs}
                  onChange={updateDate(row.bidId, row.unloadingAddress?.id, row.consignee?.id)}
                  disabled={isDisabled(row.bidId, row.unloadingAddress?.id, row.consignee?.id)}
                  shouldDisableDate={shouldDisableDate(row.lastUnloadingTs)}
                  errorMessage={row.error === Errors.incorrectDate ? INCORRECT_DATE_MESSAGE : undefined}
                />
              </Stack>
            </Stack>))}
          </Stack>
        </Box>
      </Box>
    </Stack>
  )
}
