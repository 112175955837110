import { UserRole } from 'api/user'
import { makeJsonSchema, SimpleSchema } from 'validation/validate'

export interface AuthUserCreate {
  phone: string
  password: string
  role: UserRole
}

export const simpleSchema: SimpleSchema<AuthUserCreate> = {
  type: 'object',
  properties: {
    phone: { type: 'string', pattern: '^\\+[1-9]{2}\\d{9}$', errorMessage: 'Номер телефона введен неверно' },
    password: { type: 'string', pattern: '\\w+', errorMessage: 'Введите пароль' },
    role: { type: 'string', enum: Object.values(UserRole) }
  }
}

const schema = makeJsonSchema<AuthUserCreate>(simpleSchema)
export default schema
