import { Stack, Typography } from '@mui/material'
import { Profile, ProfilePosition } from 'api/profile'
import { useAuthContext } from 'AuthContext'
import { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useMainRoutes } from 'routes'

export const checkCompletenessProfile = (p: unknown): p is Profile => {
  return typeof p === 'object' &&
    p !== null &&
    'email' in p &&
    'firstName' in p &&
    'familyName' in p &&
    typeof p.email === 'string' &&
    typeof p.familyName === 'string' &&
    typeof p.firstName === 'string'
}

export interface CheckPosition {
  isEmpty?: boolean
  willExpired?: ProfilePosition[]
  expired?: ProfilePosition[]
}

export const checkPosition = ({ positions = [] }: Profile) => {
  if (positions.length === 0) {
    return {}
  }

  const isEmpty = positions.filter(item => item.isEmpty).length > 0
  const willExpired = positions.filter(item => item.willExpired)
  const expired = positions.filter(item => item.expired)

  return { isEmpty, willExpired, expired }
}

export default function DispatcherProfileCompletenessAlert () {
  const { links } = useMainRoutes()

  const { profile } = useAuthContext()
  const [emptyProfile, setEmptyProfile] = useState(false)
  const [emptyPositions, setEmptyPositions] = useState(false)
  const [willExpired, setWillExpired] = useState<ProfilePosition>()
  const [expired, setExpired] = useState<ProfilePosition>()

  const show = emptyProfile || emptyPositions || !!willExpired || !!expired
  const onlyEmpty = emptyProfile || emptyPositions

  useEffect(() => {
    if (profile === undefined) {
      return
    }

    const isEmpty = !checkCompletenessProfile(profile)

    setEmptyProfile(isEmpty)

    const { isEmpty: emptyPositions = false, willExpired = [], expired = [] } = checkPosition(profile)

    setEmptyPositions(emptyPositions)
    setWillExpired(willExpired[0])
    setExpired(expired[0])
  }, [profile])

  if (profile === undefined || !show) {
    return (<></>)
  }

  return (<>
    <Stack
      bgcolor='#ee6a5f'
      p={1}
    >
      <Stack direction='row' gap={1} justifyContent='center'>
        { onlyEmpty
          ? <>
            { emptyProfile && <Typography fontWeight={500}>Для подписания документов необходимо заполнить профиль пользователя!</Typography> }
            { !(emptyProfile && emptyPositions) && <Typography fontWeight={500}>Заполните информацию о должностях в компаниях заказчиков!</Typography> }
          </>
          : <>
          { expired && <Typography fontWeight={500} >Истек срок действия доверенности для {expired.customer.shortName ?? expired.customer.fullName }!</Typography> }
          { (!expired && willExpired) && <Typography fontWeight={500} >Истекает срок действия доверенности для {willExpired.customer.shortName ?? willExpired.customer.fullName }!</Typography> }
          </>
        }
      <NavLink to={links.PROFILE_PAGE} style={{ color: '#111111', fontWeight: 200 }}>Перейти в профиль</NavLink>
      </Stack>
    </Stack>
  </>)
}
