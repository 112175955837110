import './styles.sass'
import { useEffect, useState } from 'react'
import { Box, CircularProgress, Typography, Stack } from '@mui/material'
import { StepProps } from 'ui/ModalSigning'
import { actCreate } from 'api/act'
import { ActWizardData } from './index'
import { STEP as NUMBERING_STEP, failureMap, clearNumbering } from './SetNumbering'
import { RequireOnly } from 'util/type/RequireOnly'
import { Customer } from 'api/customer'
import CarrierContractWizard from 'ui/CarrierContractWizard'

export default function Creating ({ isActive, data, handleNext, handleToStep }: StepProps) {
  const [creating, setCreating] = useState(false)
  const [customers, setCustomers] = useState<RequireOnly<Customer, 'id' | 'fullName'>[]>()
  const [contractModal, setContractModal] = useState<RequireOnly<Customer, 'id' | 'fullName'>>()

  useEffect(() => {
    if (isActive) {
      const d = data as ActWizardData

      setCreating(false)
      setCustomers(undefined)

      if (d.newActList !== undefined) {
        handleNext()
        return
      }

      const list = d.shippingList === undefined ? [] : d.shippingList
      const truckRunIds = list.flatMap(({ truckRuns }) => truckRuns).map(({ id }) => id)

      if (truckRunIds.length === 0) {
        handleNext()
        return
      }

      actCreate({ truckRunIds, numbering: clearNumbering(d.numbering) })
        .then(({ success, result, conflicts }) => {
          setCreating(true)
          if (success) {
            d.onCreateActs(result)
            handleNext()
            return
          }

          const { numbering, customers = [] } = conflicts ?? {}

          if (numbering) {
            d.onNumbering(failureMap(numbering))
            handleToStep(NUMBERING_STEP)
            return
          }

          if (customers.length > 0) {
            setCustomers(customers)
          }
        })
    }
  }, [isActive, data, handleNext, handleToStep])

  if (!isActive) {
    return <></>
  }

  return (<>
    <CarrierContractWizard
      open={contractModal !== undefined}
      customer={contractModal}
      onClose={(success) => {
        if (success) {
          const id = contractModal?.id

          setCustomers(prev => prev?.filter(item => item.id !== id))
          setContractModal(undefined)
        }
      }}
    />
    <Box sx={{ display: 'flex', minWidth: '500px', minHeight: '250px', alignItems: 'center', justifyContent: 'center' }}>
      <Stack direction='column' >
        { !creating && <Stack display='flex' flexDirection='column' alignItems='center'>
          <CircularProgress />
          <Typography>Создаем документы</Typography>
        </Stack> }
        { customers && <>
          { customers.length > 0
            ? <Stack display='flex' flexDirection='column' alignItems='center' width='100%' spacing={1}>
              <Typography>Для некоторых заявок не были созданы УПД. Для их создания, необходимо подписать договора со следующими заказчиками:</Typography>
              {customers.map(item => (<Stack direction='row' alignItems='center' justifyContent='flex-start' spacing={2} key={item.id} width='100%'>
                <Typography fontWeight={500}>{item.fullName}</Typography>
                <Typography fontWeight={500}>Обратитесь к диспетчеру</Typography>
                {/* <Button onClick={() => setContractModal(item)}>Подписать</Button> */}
              </Stack>))}
            </Stack>
            : <>
            <Typography>Повторите операцию создания УПД</Typography>
          </>}
        </> }
        </Stack>
    </Box>
  </>)
}
