import './styles.sass'
import { Box } from '@mui/material'
import ChipStatus from 'ui/ChipStatus'
import { DriverStatus } from 'api/driver'
import { Id } from 'api/Id'

export function ChipDriverStatusDispatcher ({ status, truckRuns = [] }: { status: DriverStatus, truckRuns?: Id[] }) {
  const Chip = () => {
    if (status === DriverStatus.new) {
      return <ChipStatus color='grey' label='На рассмотрении' />
    } else if (status === DriverStatus.needsAttention) {
      return <ChipStatus color='red' label='Требует внимания' />
    } else if (status === DriverStatus.blocked) {
      return <ChipStatus color='red' label='Отказано' />
    } else if (status === DriverStatus.archive) {
      return <ChipStatus color='lightGrey' label='В архиве' />
    } else if (status === DriverStatus.active) {
      return <ChipStatus color='green' label='Подтверждено' />
    }
  }

  return <>
  {
    [DriverStatus.active, DriverStatus.needsAttention].includes(status)
      ? <Box sx={{ display: 'flex', gap: '0.3em' }}>
    {Chip()}
    { truckRuns.length > 0
      ? <ChipStatus color='yellowBorder' label='Участвует в перевозке' />
      : <></>
    }
  </Box>
      : Chip()
  }
  </>
}
