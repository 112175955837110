import './styles.sass'
import { useEffect, useState } from 'react'
import { Alert, Box, Button, Stack, Typography } from '@mui/material'
import Modal from 'ui/Modal'
import { Id, isId } from 'api/Id'
import { ShippingTruckRunList, shippingConfirmRedirect } from 'api/shipping'
import TextField from 'ui/TextField'
import SelectOrganization from 'ui/SelectOrganization'
import FullAddress from 'ui/FullAddress'
import { AstonTrafficReservedSlot, astonTrafficReserveCancel } from 'api/aston-traffic'
import TsToFormatDate from 'util/TsToFormatDate'
import { useEnqueueSnackbar } from 'util/useEnqueueSnackbar'
import { toKopeck, toRuble } from 'util/monetary'
import valueMethod from 'util/valueMethod'

interface Params {
  data?: ShippingTruckRunList
  onClose: (done?: boolean) => void
  onDone: () => void
}

export default function RedirectModal ({ data, onClose, onDone }: Params) {
  const { id, unloadingAddress, num } = data ?? {}
  const [done, setDone] = useState<boolean>(false)
  const [consigneeId, setConsigneeId] = useState<Id>()
  const [costCorrection, setCostCorrection] = useState<number>()
  const [reservedSlot, setReservedSlot] = useState<AstonTrafficReservedSlot>()
  const [cancelReservationError, setCancelReservationError] = useState<string>()

  const { toast } = useEnqueueSnackbar()

  useEffect(() => {
    setConsigneeId(undefined)
    setCostCorrection(undefined)
    setDone(false)
  }, [])

  const save = () => {
    if (id === undefined || consigneeId === undefined) {
      return
    }

    shippingConfirmRedirect(id, consigneeId, costCorrection ?? 0)
      .then(({ success, conflicts }) => {
        if (!success) {
          if (conflicts && conflicts.reservedSlot) {
            setReservedSlot(conflicts.reservedSlot)
          }

          return
        }

        setConsigneeId(undefined)
        setCostCorrection(undefined)
        setDone(true)
      })
  }

  const close = () => {
    setConsigneeId(undefined)
    setCostCorrection(undefined)
    const d = done
    onClose(d)
    setDone(false)
  }

  const cancelReservation = (truckRunId: Id | undefined, slot: AstonTrafficReservedSlot) => {
    if (!isId(truckRunId)) {
      return
    }

    const { reservedId, from, to } = slot
    astonTrafficReserveCancel(truckRunId, reservedId)
      .then(result => {
        const { success, conflicts } = result

        if (!success) {
          if (conflicts && conflicts.astonMessage) {
            setCancelReservationError(`Астон-трафик: ${conflicts.astonMessage}`)
            return
          }

          setCancelReservationError('В данный момент невозможно отменить запись, попробуйте позже')
          return
        }

        toast(`Слот на ${TsToFormatDate(from, 'HH:mm')}-${TsToFormatDate(to, 'HH:mm')} ${TsToFormatDate(from, 'dd.MM')} отменен`, 'success')
        setCancelReservationError(undefined)
        setReservedSlot(undefined)
      })
  }

  return <Modal
    title={done ? 'Переадресация рейса подтверждена!' : `Укажите данные о переадресации рейса ${num}`}
    open={id !== undefined}
    onClose={close}
    content={<Stack sx={{ minWidth: '500px' }}>
      { done
        ? <></>
        : <Stack>
          <Box sx={{ mb: 2 }}>
            <Box sx={{
              fontWeight: '500',
              fontSize: '13px',
              lineHeight: '16px',
              letterSpacing: '-0.02em',
              whiteSpace: 'nowrap',
              color: '#111111',
              mb: 1
            }}>Фактический пункт разгрузки</Box>
            { unloadingAddress && <FullAddress address={unloadingAddress} /> }
          </Box>
          <SelectOrganization
            label='Грузополучатель'
            placeholder='Выберете грузополучателя'
            value={consigneeId}
            onChange={setConsigneeId}
          />
          <TextField
            label='Добавочная стоимость, руб.'
            value={valueMethod(costCorrection)(toRuble)}
            typeNumber
            onChange={({ target: { value } }) => setCostCorrection(value === '' ? undefined : toKopeck(Number(value))) }
            precision={0}
          />
        </Stack>}
        { reservedSlot && <Stack>
          <Alert severity='error'>
            <Typography>
            Есть запись в пункт выгрузки, слот {TsToFormatDate(reservedSlot.from, 'HH:mm')}-{TsToFormatDate(reservedSlot.to, 'HH:mm')} на {TsToFormatDate(reservedSlot.from, 'dd.MM.yyyy')}.<br />
            Необходимо отменить запись, для возможности переадресации.
            </Typography>
            { cancelReservationError && <Typography>{cancelReservationError}</Typography> }
          </Alert>
        </Stack>}
    </Stack>}
    actions={ done
      ? <>
        <Button variant='contained' onClick={() => {
          setConsigneeId(undefined)
          setCostCorrection(undefined)
          onDone()
          setDone(false)
        }}>К списку рейсов</Button>
      </>
      : <>
        <Button color='secondary' variant='outlined' onClick={close}>Отменить</Button>
        { !reservedSlot
          ? <Button variant='contained' onClick={save} disabled={!consigneeId}>Отправить</Button>
          : <Button variant='contained' onClick={() => cancelReservation(id, reservedSlot as AstonTrafficReservedSlot)} >Отменить запись</Button>
        }
      </>
    }
  />
}
