import { Button, Stack } from '@mui/material'
import { rejectionReasonList, RejectionReason } from 'api/rejection'
import { useEffect, useState } from 'react'
import Modal from 'ui/Modal'
import Select from 'ui/Select'
import './styles.sass'
import { useAuthContext } from 'AuthContext'
import TextField from 'ui/TextField'

interface Props {
  open: boolean
  onReject: (reasonsId: string, comment?: string) => void
  onCancel: () => void
}

export default function RejectionModal ({ open, onReject, onCancel }: Props) {
  const { handleResponseFailure } = useAuthContext()
  const [reasonsId, setReasonsId] = useState<string>()
  const [reasonsList, setReasonsList] = useState<RejectionReason[]>()
  const [comment, setComment] = useState<string>('')

  useEffect(() => {
    if (reasonsList === undefined) {
      rejectionReasonList().then(result => setReasonsList(result))
    }
  }, [reasonsList, setReasonsList])

  const handleCancel = () => {
    setReasonsId(undefined)
    setComment('')
    onCancel()
  }

  const handleReject = (): void => {
    if (reasonsId === undefined) {
      handleResponseFailure('Причина отказа не выбрана.')
    } else {
      onReject(reasonsId, comment)
    }
  }

  return (
    <>
        <Modal
          title='Отказать в регистрации'
          open={open}
          onClose={handleCancel}
          content={ <>
              <Stack sx={{ minWidth: '500px', mb: 2 }} >
                <Select
                  label='Выберите причину отказа'
                  fullWidth
                  options={ reasonsList?.map(({ slug, name }) => ({ value: slug, name })) ?? [] }
                  value={reasonsId}
                  onChange={setReasonsId}
                />
              </Stack>
              <Stack>
                <TextField
                  label='Комментарий'
                  hint='Не обязательно'
                  rows={3}
                  value={comment}
                  onChange={e => setComment(e.target.value)}
                />
              </Stack>
          </>}
          actions={<>
            <Button color='secondary' variant="outlined" onClick={handleCancel}>Отменить</Button>
            <Button color='error' variant="contained" onClick={handleReject}>
              Отклонить
            </Button>
          </>}
        />
    </>
  )
}
