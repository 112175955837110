import { useEffect, useMemo, useRef, useState } from 'react'
import { MapContainer, Marker, TileLayer } from 'react-leaflet'
import L from 'leaflet'
import 'leaflet-routing-machine'
import BidMarker from './BidMarker'
import { Id } from 'api/Id'
import { Bid } from 'api/bid'
import { DEFAULT_COORDINATES } from 'constants/map'
import { Address } from 'api/address'
import useRouterOSM from 'util/useRoterOSM'

export type Point = Address['coordinates']
const isEmptyPoint = (p: Point) => p.lat === 0 && p.lat === 0
export interface Params {
  route?: {
    from: Point
    to: Point
  }
  markersBid?: Bid[]
  markers?: { point: Point }[]
  zoom?: number
  viewBidById?: Id
  onOpenPopup?: (id: Id) => void
  onClosePopup?: (id: Id) => void
  staticMap?: boolean
}

const Osm = ({ route, markers, markersBid, zoom, viewBidById, onOpenPopup, onClosePopup, staticMap = false }: Params) => {
  const [routingMachine, setRoutingMachine] = useState<L.Routing.Control>()
  const mapRef = useRef<L.Map>(null)
  const router = useRouterOSM()

  const bounds: L.LatLngBoundsLiteral = useMemo(() => {
    return markersBid?.map(({ loadingAddress: { coordinates: point } }) => [point.lat, point.lon]) ?? []
  }, [markersBid])

  useEffect(() => {
    if (route !== undefined) {
      setRoutingMachine(L.Routing.control({
        show: false,
        router,
        addWaypoints: false,
        routeWhileDragging: false,
        // @ts-ignore
        draggableWaypoints: false,
        fitSelectedRoutes: true,
        showAlternatives: false
      }))
    }
  }, [router, route])

  useEffect(() => {
    if (route === undefined) {
      return
    }

    const { from, to } = route
    if (isEmptyPoint(from) || isEmptyPoint(to)) {
      return
    }

    if (routingMachine === undefined) {
      return
    }

    if (mapRef.current !== null) {
      routingMachine.addTo(mapRef.current)
      routingMachine.setWaypoints([L.latLng(from.lat, from.lon), L.latLng(to.lat, to.lon)])
    }
  }, [route, routingMachine])

  useEffect(() => {
    if (markersBid !== undefined && mapRef.current !== null) {
      bounds.length && mapRef.current.fitBounds(bounds)
    }
  }, [markersBid, bounds])

  useEffect(() => {
    if (markers !== undefined && mapRef.current !== null) {
      const bounds: L.LatLngBoundsLiteral = markers.map(({ point }) => [point.lat, point.lon])
      bounds.length && mapRef.current.fitBounds(bounds)
    }
  }, [markers])

  useEffect(() => {
    if (viewBidById !== undefined) {
      const point = markersBid?.find(item => item.id === viewBidById)?.loadingAddress.coordinates
      if (point !== undefined) {
        mapRef.current?.flyTo(L.latLng(point.lat, point.lon))
      }
    } else {
      bounds.length && mapRef.current?.flyToBounds(bounds)
    }
  }, [viewBidById, bounds, markersBid])

  return (
    <MapContainer
      center={[DEFAULT_COORDINATES.lat, DEFAULT_COORDINATES.lon]} zoom={zoom ?? 15} scrollWheelZoom={false}
      style={{
        height: '100%',
        width: '100%'
      }}
      ref={mapRef}
      attributionControl={false}
      zoomControl={!staticMap}
      boxZoom={!staticMap}
      dragging={!staticMap}
    >
      <TileLayer
        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
      />
      { markers?.map(({ point }, idx) => <Marker position={[point.lat, point.lon]} key={idx} />) }
      { markersBid?.map(item => (<BidMarker data={item} key={item.id} onOpen={onOpenPopup} onClose={onClosePopup} selectId={viewBidById} />)) }
    </MapContainer>
  )
}

export default Osm
