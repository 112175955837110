import { mainService } from 'api/service'
import { Id } from 'api/Id'
import { RequireOnly } from 'util/type/RequireOnly'

export enum TypeLegalPerson {
  st = 'st',
  organization = 'organization',
}

export enum TypeTaxation {
  vat = 'vat',
  sts6 = 'sts6',
  sts13 = 'sts13',
  patent = 'patent',
}

export enum CarrierKisStatus {
  waiting = 'waiting',
  outdated = 'outdated',
  completed = 'completed',
  error = 'error',
}

export interface OrganizationParticipant {
  position: string
  employeeName: string
  appointmentDocument: string
  scanAppointmentDocument: string
}

export enum KisRequestHistoryType {
  send = 'send',
  requestStatus = 'request_status',
}

export interface KisRequestHistory {
  type: KisRequestHistoryType
  status: CarrierKisStatus
  requestTs: number
}

export interface Organization {
  typeLegalPerson: TypeLegalPerson
  typeTaxation: TypeTaxation

  inn?: string
  shortName?: string
  fullName?: string
  ogrnip?: string
  okpo?: string
  okved?: string
  okveds?: string[]
  ogrn?: string
  correspondentAccountNumber?: string
  kpp?: string
  checkingAccountNumber?: string
  bik?: string
  bankName?: string
  organizationHeadName?: string
  appointmenDocumentTitle?: string
  legalAddress?: string,
  registrationAddress?: string,
  actualAddress?: string,
  mailingAddress?: string,
  position?: string
  legalEqRegisteredAddress?: boolean
  mailingEqRegisteredAddress?: boolean
  actualEqLegalAddress?: boolean
  scanInn?: string
  scanRegistration?: string
  scanCode?: string
  scanCertificate?: string
  scanTaxReturnOrFeeDeclaration?: string
  scanInsuredPersons?: string
  scanReportEFS?: string
  scanConsentTaxpayer?: string
  scanReceiptConsentTaxpayer?: string
  scanRegistrationAddress?: string
  scanInformationLetter?: string
  scanPatent?: string
  scanLicense?: string
  scanFinancialResults?: string
  scanRequisites?: string
  scanBankCard?: string
  scanRegulations?: string
  scanAdministrationProtocol?: string
  manningTable?: string
  calcPersonalIncomeTax?: string
  hasAccounting?: boolean
  hasLicense?: boolean
  accountingBalance?: string
  scanQuestionnaire?: string
  phones?: string[]
  emails?: string[]
  scanPowerOfAttorney?: string
  hasPowerOfAttorney?: boolean
  participants?: OrganizationParticipant[]
  carrierKisStatus?: CarrierKisStatus
  kisQueuedId?: string
  kisRequestHistory?: KisRequestHistory[]
}

export type OrganizationAll = Organization & { id: Id, owner: string }

export type OrganizationAllForCreate = Omit<OrganizationAll, 'id' | 'phones' | 'emails' | 'carrierKisStatus' | 'kisQueuedId' | 'kisRequestHistory'>
export type OrganizationAllForUpdate = RequireOnly<Omit<OrganizationAll, 'owner' | 'phones' | 'emails' | 'carrierKisStatus' | 'kisQueuedId' | 'kisRequestHistory'>, 'id'>
export interface OrganizationAllFilter {
  owner?: string[]
}

const request = (method: string, params: object) => mainService.request(`organization.${method}`, params)

export const organizationCreate = (params: Organization) => request('create', params) as Promise<boolean>
export const organizationUpdate = (params: Partial<Organization>) => request('update', params) as Promise<boolean>
export const organizationGet = () => request('get', {}) as Promise<Organization | null>

export const organizationAllCreate = (params: OrganizationAllForCreate) => request('all.create', params) as Promise<Id | null>
export const organizationAllUpdate = (params: OrganizationAllForUpdate) => request('all.update', params) as Promise<boolean>
export const organizationAllGet = (id: Id) => request('all.get', { id }) as Promise<OrganizationAll | null>
export const organizationAllList = (params: OrganizationAllFilter = {}) => request('all.list', params) as Promise<OrganizationAll[]>
export const organizationAllDelete = (id: Id) => request('all.delete', { id }) as Promise<boolean>
