import { NotifyEvent } from '.'
import { useEffect } from 'react'
import { useMainRoutes } from 'routes'
import { Box } from '@mui/material'
import SquareButton from 'ui/SquareButton'
import { MoreHoriz } from '@mui/icons-material'

export interface Data {
  message: string
  bidId: string
}

const SLUG = 'bids_bid_published_in_region'

export default function BidPublishedInRegion ({ slug, data, snack, navigate } :NotifyEvent) {
  const { links } = useMainRoutes()
  useEffect(() => {
    if (slug !== SLUG) {
      return
    }

    if (snack === undefined) {
      return
    }

    if (data === undefined) {
      return
    }

    const { message, bidId } = data as Data
    const open = () => {
      if (navigate === undefined) {
        return
      }

      navigate(`${links.CARRIER_BIDS_PAGE}/${bidId}`)
    }
    snack({
      title: message,
      color: 'info',
      content: (<Box>
        <SquareButton variant='outlined' color='inherit' onClick={open}><MoreHoriz/></SquareButton>
      </Box>),
      options: { autoHideDuration: 10000, preventDuplicate: true }
    })
  }, [data, links.CARRIER_BIDS_PAGE, navigate, slug, snack])

  return (<></>)
}
