import './styles.sass'
import clsx from 'clsx'
import { Collapse, IconButton } from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { ReactNode, useState } from 'react'

export interface CollapseBoxParams {
  title: ReactNode
  children: JSX.Element
  className?: string
  collapse?: boolean
  disabled?: boolean
}

export default function CollapseBox ({ children, className, title, collapse = false, disabled = false }: CollapseBoxParams) {
  const [isCollapse, setCollapse] = useState<boolean>(collapse)
  return (
    <div className={clsx('collapseBox', className)} >
      <div className='collapseBox_header'>
        <div className='collapseBox_header_title' style={{ opacity: disabled ? 0.2 : undefined }} >{title}</div>
        <div className='collapseBox_header_line'></div>
        <div className='collapseBox_header_control'>
          <IconButton onClick={() => setCollapse(prev => !prev)} style={{ padding: 0 }} disabled={disabled} >
            { isCollapse ? <ExpandMore /> : <ExpandLess sx={{ color: '#0A5DFF' }} /> }
          </IconButton>
        </div>
      </div>
      <Collapse in={!isCollapse} timeout='auto' unmountOnExit className='collapseBox_content'>
        {children}
      </Collapse>
    </div>
  )
}
