import './styles.sass'
import { useEffect, useMemo, useState } from 'react'
import { Box, Button, Stack, Typography } from '@mui/material'
import Modal from 'ui/Modal'
import { Id, isId } from 'api/Id'
import Upload from 'ui/Upload'
import { shippingComplete } from 'api/shipping'
import { Driver, driverList, DriverStatus } from 'api/driver'
import Select from 'ui/Select'
import { personName } from 'util/personName'
import vehicleName from 'util/vehicleName'
import { Vehicle } from 'api/vehicle'

export interface Params {
  data?: { id: Id, bidAutoFill?: boolean, vehicle?: Vehicle }
  onClose: (done?: boolean) => void
  onDone: () => void
}

export default function CompleteModal ({ data, onClose, onDone }: Params) {
  const [done, setDone] = useState<boolean>(false)
  const [scanBills, setScanBills] = useState<string[]>()

  const [drivers, setDrivers] = useState<Driver[]>([])
  const [driverId, setDriverId] = useState<Id>()
  const [driverError, setDriverError] = useState<string>()

  const driversOptionsList = useMemo(() => {
    if (!data?.bidAutoFill) {
      return []
    }

    const vehicleId = data.vehicle?.id
    const vehicleNum = vehicleName(data.vehicle, 'short')

    return drivers.map(({ id, familyName, secondName, firstName, vehicleTrailer }) => {
      const myVehicle = isId(vehicleId) ? !!vehicleTrailer?.map((item) => item.vehicleId).includes(vehicleId) : false

      return {
        value: id,
        name: personName({ firstName, familyName, secondName }),
        optionName: <Stack direction='row' justifyContent='space-between' width='100%'>
          <Typography>{personName({ firstName, familyName, secondName })}</Typography>
          {myVehicle && <Typography>{vehicleNum}</Typography>}
        </Stack>,
        myVehicle
      }
    })
      .sort((a, b) => {
        // Сортируем по алфавиту и привязке авто
        if (a.myVehicle !== b.myVehicle) {
          return a.myVehicle ? 0 : 1
        }

        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1
        }

        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1
        }

        return 0
      })
  }, [drivers, data?.vehicle, data?.bidAutoFill])

  useEffect(() => {
    setScanBills(undefined)
    setDone(false)

    driverList({ filters: { status: DriverStatus.active } }).then(setDrivers)
  }, [])

  const save = () => {
    if (data === undefined || scanBills === undefined || scanBills.length === 0) {
      return
    }

    if (data.bidAutoFill && !isId(driverId)) {
      setDriverError('Обязательно к заполнению')
      return
    }

    shippingComplete(data.id, scanBills, data.bidAutoFill ? driverId : undefined).then(() => {
      setScanBills(undefined)
      setDone(true)
    })
  }

  const close = () => {
    setScanBills(undefined)
    setDriverId(undefined)
    setDriverError(undefined)
    const d = done
    onClose(d)
    setDone(false)
  }

  return <Modal
    title={done ? 'Рейс Завершен!' : 'Загрузка фото ТрН/ТТН'}
    open={data !== undefined}
    onClose={close}
    content={<Stack sx={{ minWidth: '500px' }}>
      { done
        ? <>
          <Box sx={{
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '21px',
            color: '#B2B2B2'
          }}>Фото ТрН/ТТН отправлено, рейс переведен в статус «Завершен»</Box>
        </>
        : <>
          {data?.bidAutoFill && <Select
            label='Водитель'
            placeholder='Выберите водителя'
            options={driversOptionsList}
            value={driverId}
            onChange={setDriverId}
            disableClearable={true}
            errorMessage={driverError}
            width='100%'
          />}
          <Box sx={{
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '21px',
            color: '#B2B2B2',
            mb: 2
          }}>После загрузки фото ТрН/ТТН, рейс будет переведен в статус «Завершен»</Box>
          <Stack>
            {scanBills && scanBills.map((scanBill, idx) =>
              <Upload key={idx} value={scanBill} onChange={() => setScanBills((prev) => {
                const scanBills = prev ? [...prev] : []
                scanBills.splice(idx, 1)
                return scanBills.length > 0 ? scanBills : undefined
              })} />
            )}
            <Upload key='new' onChange={(value) => setScanBills((prev) => [...(prev ?? []), value])} />
          </Stack>
        </>}
    </Stack>}
    actions={ done
      ? <>
        <Button variant='contained' onClick={() => {
          setScanBills(undefined)
          onDone()
          setDone(false)
        }}>К списку рейсов</Button>
      </>
      : <>
        <Button color='secondary' variant='outlined' onClick={close}>Отменить</Button>
        <Button variant='contained' onClick={save} disabled={scanBills === undefined || scanBills.length === 0}>Отправить</Button>
      </>
    }
  />
}
