import { UserStatus } from 'api/UserStatus'
import { mainService } from 'api/service'
import { Id } from 'api/Id'
import { RequireOnly } from 'util/type/RequireOnly'
import { Address } from 'api/address'

export enum ForwarderAttachStatus {
  active = 'active',
  planned = 'planned',
  archival = 'archival',
}

export interface ForwarderAttach {
  id: Id
  forwarderId: Id
  status: ForwarderAttachStatus
  loadingAddress: Address
  beginPeriodTs: number
  endPeriodTs: number
}

export enum ForwarderPosition {
  forwarder = 'forwarder',
  chief_forwarder = 'chief_forwarder',
}

export interface Forwarder {
  id: Id
  firstName: string
  familyName: string
  secondName: string
  position: ForwarderPosition
  email: string
  phone: string
  status: UserStatus
  archiveTs?: number
  archiveComment?: string
  truckRuns: Id[]
  forwarderAttach?: Omit<ForwarderAttach, 'forwarderId'>[]
}

export type ForwarderList = Forwarder & {
  attaches?: Omit<ForwarderAttach, 'forwarderId'>[]
}

export type ForwarderAttachConflict = Omit<ForwarderAttach, 'forwarderId'> & {
  forwarder: Forwarder
}

export type ForwarderForCreate = Omit<Forwarder, 'id' | 'user' | 'status' | 'archiveTs' | 'archiveComment' | 'truckRuns' | 'forwarderAttach'>
export type ForwarderForUpdate = RequireOnly<Omit<Forwarder, 'user' | 'status' | 'archiveTs' | 'archiveComment' | 'truckRuns' | 'forwarderAttach'>, 'id'>

type AttachForUpdate = Omit<ForwarderAttach, 'status' | 'loadingAddress'> & { loadingAddressId: Id }

export type ForwarderAttachForCreate = Omit<AttachForUpdate, 'id'>
export type ForwarderAttachForUpdate = RequireOnly<Omit<AttachForUpdate, 'forwarderId'>, 'id'>
export interface ForwarderAttachFilter {
  forwarderId?: Id
}

export interface ForwarderArchiveParams {
  id: Id
  date: number
  comment?: string
}

export interface ForwarderReportsLoadingParams {
  filters?: {
    dateFrom?: number
    dateTo?: number
  }
}

export interface ForwarderListFilter {
  status?: UserStatus[]
}

const request = (method: string, params: object) => mainService.request(`forwarder.${method}`, params)

export const forwarderCreate = (params: ForwarderForCreate) => request('create', params) as Promise<Id | null>
export const forwarderUpdate = (params: ForwarderForUpdate) => request('update', params) as Promise<boolean>
export const forwarderGet = (id: Id) => request('get', { id }) as Promise<ForwarderList | null>
export const forwarderList = (filter: ForwarderListFilter = {}) => request('list', filter) as Promise<ForwarderList[]>
export const forwarderDelete = (id: Id) => request('delete', { id }) as Promise<boolean>
export const forwarderArchive = (params: ForwarderArchiveParams) => request('archive', params) as Promise<boolean>

export const forwarderAttachCreate = (params: ForwarderAttachForCreate) => request('attach.create', params) as Promise<{
  id: Id | null
  conflicts: ForwarderAttachConflict[]
}>
export const forwarderAttachUpdate = (params: ForwarderAttachForUpdate) => request('attach.update', params) as Promise<{
  updated: boolean
  conflicts: ForwarderAttachConflict[]
}>
export const forwarderAttachGet = (id: Id) => request('attach.get', { id }) as Promise<ForwarderAttach | null>
export const forwarderAttachMyList = () => request('attach.my-list', {}) as Promise<ForwarderAttach[]>
export const forwarderAttachList = (params: ForwarderAttachFilter = {}) => request('attach.list', params) as Promise<ForwarderAttach[]>
export const forwarderAttachDelete = (id: Id) => request('attach.delete', { id }) as Promise<boolean>
export const forwarderReportsLoading = (params: ForwarderReportsLoadingParams) => request('reports.loading', params) as Promise<string | null>
