import { Button } from '@mui/material'
import { Id } from 'api/Id'
import { ShippingAll, shippingAllList, ShippingTruckRunStatus, shippingTruckRunChangeStatus as apiChangeStatus } from 'api/shipping'
import { useCallback, useEffect, useMemo, useState } from 'react'
import DataTable, { Row } from 'ui/DataTable'
import TextField from 'ui/TextField'
import { personName } from 'util/personName'
import trailerName from 'util/trailerName'
import TsToFormatDate from 'util/TsToFormatDate'
import vehicleName from 'util/vehicleName'
import './styles.sass'
import addressName from 'util/addressName'
import { toKilogram, toTon } from 'util/weight'

export default function AdminTruckRuns () {
  const [list, setList] = useState<ShippingAll[]>([])
  const [finalWeightMap, setFinalWeightMap] = useState<Record<Id, number>>({})

  const init = useCallback(() => {
    shippingAllList({ filters: { truckRunStatus: [ShippingTruckRunStatus.way] } }).then(result => setList(result))
  }, [])

  useEffect(() => init(), [init])

  const rows: Row = useMemo(() => {
    return list.flatMap(({ bid, truckRuns }) => {
      return truckRuns.map(truckRun => ({
        id: truckRun.id,
        bid: `${addressName(bid.loadingAddress)} - ${addressName(truckRun.unloadingAddress ?? bid.unloadingAddress)}`,
        date: TsToFormatDate(truckRun.loadingTs, 'dd.MM.yyyy'),
        driver: personName(truckRun.driver),
        vehicle: vehicleName(truckRun.vehicle),
        trailer: trailerName(truckRun.trailer),
        weight: truckRun.weight
      }))
    })
  }, [list])

  const handleChangeStatus = (id: Id) => {
    apiChangeStatus({
      list: [{
        id,
        status: ShippingTruckRunStatus.arrived,
        finalWeight: finalWeightMap[id]
      }]
    }).then(_ => {
      init()
    })
  }

  return (
    <div className='adminTruckRuns'>
      <div className='adminTruckRuns__body'>
        <DataTable
            columns = { [
              {
                id: 'bid',
                label: 'Заявка',
                minWidth: 170
              },
              {
                id: 'date',
                label: 'Дата вывоза',
                minWidth: 170
              },
              {
                id: 'driver',
                label: 'Водитель',
                minWidth: 170
              },
              {
                id: 'vehicle',
                label: 'ТС',
                minWidth: 170
              },
              {
                id: 'trailer',
                label: 'Прицеп',
                minWidth: 170
              },
              {
                id: 'weight',
                label: 'Погружено',
                minWidth: 170,
                format: value => <>{toTon(value as number) }</>
              },
              {
                id: 'id',
                label: 'Выгружено',
                minWidth: 170,
                format: value => {
                  const id = value as Id
                  return <TextField
                    typeNumber
                    value={toTon(finalWeightMap[id] ?? 0)}
                    onChange={(e) => {
                      const val = e.target.value
                      setFinalWeightMap(prev => ({ ...prev, [id]: toKilogram(parseFloat(val)) }))
                    }}/>
                }
              },
              {
                id: 'id',
                label: '',
                minWidth: 170,
                format: (value) => <>
                  <Button onClick={() => handleChangeStatus(value as Id)}>Выгрузить</Button>
                </>
              }
            ] }
            rows = { (start, end) => rows === undefined ? [] : rows.slice(start, end) }
            rowsCount = { rows === undefined ? 0 : rows.length }
          ></DataTable>
        </div>
    </div>
  )
}
