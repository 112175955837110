import { Button, ButtonProps, styled } from '@mui/material'

const LinkButton = styled(Button)<ButtonProps>(({ theme }) => ({
  fontSize: '13px',
  fontWeight: 500,
  textDecoration: 'underline',
  padding: 0
}))

export default LinkButton
