import { Box, Button, Stack } from '@mui/material'
import { profileAllPositionPut, ProfileAllPositionPutParams, ProfilePosition } from 'api/profile'
import { useAuthContext } from 'AuthContext'
import { useEffect, useState } from 'react'
import DatePicker from 'ui/DatePicker'
import Modal from 'ui/Modal'
import TextField from 'ui/TextField'
import onlyFields from 'util/onlyFields'
import schema from 'validation/ProfilePosition'
import useValidate from 'validation/validate'

export type PositionUpdate = Partial<Omit<ProfileAllPositionPutParams, 'customerId'>>

export interface Params {
  position?: ProfilePosition
  onClose: () => void
  onSuccess: () => void
}

export default function EditPositionsModal ({ position, onClose, onSuccess }: Params) {
  const { handleResponseSuccess, handleResponseFailure } = useAuthContext()

  const { isEmpty = false, customer } = position ?? {}
  const [data, setData] = useState<PositionUpdate>({})

  const { check, errors, cleanErrors } = useValidate(schema)

  const customerName = position?.customer.shortName ?? position?.customer.fullName ?? ''

  useEffect(() => {
    if (position === undefined) {
      return
    }

    setData(onlyFields(position, 'position', 'startTs', 'endTs'))
  }, [position])

  const handleClose = () => {
    setData({})
    cleanErrors()
    onClose()
  }

  const handleSave = () => {
    const newPosition = { ...data, customerId: customer?.id }
    if (!check(newPosition)) {
      console.error(errors)
      return
    }

    profileAllPositionPut(newPosition)
      .then(success => {
        if (success) {
          handleResponseSuccess('Данные сохранены')
          onSuccess()
          return
        }

        handleResponseFailure('Данные небыли сохранены')
      })
  }

  return (<>
    <Modal
      maxWidth='sm'
      title={isEmpty ? 'Добавить информацию' : 'Редактировать информацию'}
      onClose={handleClose}
      open={!!position}
      content={
        <Box minWidth='500px'>
          <Stack direction='column' gap={1}>
            <TextField
              name='position'
              label={`Должность в ${customerName}`}
              value={data.position}
              onChange={({ target: { value: position } }) => setData(prev => ({ ...prev, position }))}
              errors={errors}
            />
            <DatePicker
              name='startTs'
              label='Дата выдачи доверенности'
              value={data.startTs}
              onChange={(startTs) => setData((prev) => ({ ...prev, startTs }))}
              errors={errors}
            />
            <DatePicker
              name='endTs'
              label='Дата окончания действия доверенности'
              value={data.endTs}
              onChange={(endTs) => setData((prev) => ({ ...prev, endTs }))}
              errors={errors}
            />
          </Stack>
        </Box>
      }
      actions={<>
        <Stack direction='row' justifyContent='space-between' spacing={2} sx={{ width: '100%' }}>
          <Button variant='outlined' color='secondary' size='small' onClick={handleClose}>Отмена</Button>
          <Button variant='contained' size='small' onClick={handleSave} >Сохранить</Button>
        </Stack>
      </>}
    />
  </>)
}
