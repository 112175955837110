const checkDigit = function (inn: string, coefficients: number[]) {
  let n = 0
  for (const i in coefficients) {
    n += coefficients[i] * parseInt(inn[i])
  }
  return n % 11 % 10
}

export default function validateInn (inn: string | undefined) {
  let result = false

  if (inn === undefined) {
    return result
  }

  if (inn.length === 10) {
    const n10 = checkDigit(inn, [2, 4, 10, 3, 5, 9, 4, 6, 8])
    if (n10 === parseInt(inn[9])) {
      result = true
    }
  }

  if (inn.length === 12) {
    const n11 = checkDigit(inn, [7, 2, 4, 10, 3, 5, 9, 4, 6, 8])
    const n12 = checkDigit(inn, [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8])
    if ((n11 === parseInt(inn[10])) && (n12 === parseInt(inn[11]))) {
      result = true
    }
  }

  return result
}
