import { TrailerForCreate } from 'api/trailer'
import { makeJsonSchema, SimpleSchema } from './validate'
import { CarNumberType } from 'api/CarNumberType'

const simpleSchema: SimpleSchema<TrailerForCreate> = {
  type: 'object',
  properties: {
    brand: { type: 'string', pattern: '^[a-zA-Zа-яА-ЯёЁ\\- ]+$', errorMessage: 'Некорректно заполнена марка' },
    model: { type: 'string', pattern: '^[a-zA-Zа-яА-ЯёЁ0-9- ]+$', errorMessage: 'Некорректно заполнена модель' },
    number: { type: 'string' },
    numberRegion: { type: 'string' },
    numberType: { type: 'string', enum: Object.values(CarNumberType), errorMessage: 'Некорректно заполнен тип гос. номера' },
    color: { type: 'string', pattern: '^[а-яА-ЯёЁ\\-]+$', errorMessage: 'Некорректно заполнен цвет' },
    axlesSlug: { type: 'string' },
    certificateIndex: { type: 'string', pattern: '^\\d{2}[\\dА-Я]{2}$', errorMessage: 'Некорректно заполнена серия СРТС' },
    certificate: { type: 'string', pattern: '^\\d{6}$', errorMessage: 'Некорректно заполнен номер СРТС' },
    scanPassport: { type: 'string', pattern: '^[a-zA-Z0-9]+\\.[a-zA-Z]{3,4}$', errorMessage: 'Поле обязательно к заполнению' },
    scanRegistration: { type: 'string', pattern: '^[a-zA-Z0-9]+\\.[a-zA-Z]{3,4}$', errorMessage: 'Поле обязательно к заполнению' }
  }
}

const schema = makeJsonSchema<TrailerForCreate>(simpleSchema)

export default schema

export const clear = (data?: Partial<TrailerForCreate>): Partial<TrailerForCreate> | undefined => {
  if (data === undefined) {
    return undefined
  }
  const { numberRegion, number, certificateIndex } = data
  return {
    ...data,
    number: number === undefined ? undefined : number.replace(/_/g, '').toLocaleUpperCase(),
    numberRegion: numberRegion === undefined ? undefined : numberRegion.replace(/_/g, '').toLocaleUpperCase(),
    certificateIndex: certificateIndex === undefined ? undefined : certificateIndex.replace(/\s+/g, '').toUpperCase()
  }
}
