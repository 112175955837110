import InfoCard from 'ui/InfoCard'
import { Box, Stack, Typography } from '@mui/material'
import { Interface } from 'api/Interface'
import AntSwitch from 'ui/AntSwitch'
import { DriverType, NotificationSettings, NotificationsType } from 'api/notify'
import { useAuthContext } from 'AuthContext'

const notificationsTypeName: Map<NotificationsType, string> = new Map()
notificationsTypeName.set(NotificationsType.load, 'Погрузки')
notificationsTypeName.set(NotificationsType.attach, 'Закрепления')
notificationsTypeName.set(NotificationsType.expiresDoc, 'Истечение срока действия документов')
notificationsTypeName.set(NotificationsType.bid, 'Заявки')
notificationsTypeName.set(NotificationsType.shipping, 'Перевозки')
notificationsTypeName.set(NotificationsType.documents, 'Документы')
notificationsTypeName.set(NotificationsType.registry, 'Реестры')

const eventDriverTypeName: Map<DriverType, string> = new Map()
eventDriverTypeName.set(DriverType.email, 'Email')
eventDriverTypeName.set(DriverType.sms, 'СМС')
eventDriverTypeName.set(DriverType.push, 'Push')
eventDriverTypeName.set(DriverType.system, 'Системные')

const isDispatcher = (userInterface?: Interface) =>
  userInterface && [Interface.chief_dispatcher, Interface.dispatcher].includes(userInterface)

const getDesc = (type: NotificationsType, userInterface?: Interface) => {
  if (type === NotificationsType.load) {
    return 'Уведомления о создании новых погрузок, изменении и завершении существующих погрузок, а также опоздании перевозчика на погрузку'
  }

  if (type === NotificationsType.attach) {
    return 'Уведомления о новых закреплениях, а также изменении существующих закреплений'
  }

  if (type === NotificationsType.expiresDoc) {
    return 'Уведомления об истечении срока действия паспорта, в/у водителей'
  }

  if (type === NotificationsType.bid && isDispatcher(userInterface)) {
    return 'Уведомления о новых откликах перевозчиков, а также изменении статусов текущих'
  }

  if (type === NotificationsType.shipping && isDispatcher(userInterface)) {
    return 'Уведомления об автоматическом создании новых перевозок и рейсов, изменении статусов текущих, а также запросах на завершение от перевозчиков'
  }

  if (type === NotificationsType.documents && isDispatcher(userInterface)) {
    return 'Уведомления об добавлении авансовых счетов, актов и счетов на оплату, а также о новых запросах перевозчиков на просмотр, подписание или аннуляцию документов'
  }
  if (type === NotificationsType.registry && isDispatcher(userInterface)) {
    return 'Уведомления о добавлениях перевозчиками новых водителей, транспортных средств и прицепов. Уведомления об истечении документов перевозчиков и водителей'
  }

  if (type === NotificationsType.bid && userInterface === Interface.carrier) {
    return 'Уведомления о публикациях новых заявок на перевозки, а также изменениях статусов перевозок, на которые вы уже откликнулись'
  }

  if (type === NotificationsType.shipping && userInterface === Interface.carrier) {
    return 'Уведомления об автоматическом создании новых рейсов, изменении статусов активных, а также подтверждении завершенных рейсов заказчиком'
  }

  if (type === NotificationsType.documents && userInterface === Interface.carrier) {
    return 'Уведомления об изменении актов и счетов, а также уведомления о добавлении и создании новых документов или аннуляции уже созданных'
  }
  if (type === NotificationsType.registry && userInterface === Interface.carrier) {
    return 'Уведомления о подтверждении или изменении статусов в реестрах водителей, транспорта и прицепов'
  }

  return ''
}

interface RowParams {
  data: NotificationSettings
  userInterface?: Interface
  onChange: (data: NotificationSettings) => void
}

const Row = ({ data, userInterface, onChange }: RowParams) => {
  const handleChange = (driver: DriverType) => {
    const { notificationMethods: origin, ...fields } = data

    return (e: React.ChangeEvent<HTMLInputElement>) => {
      const notificationMethods = []
      for (const item of origin) {
        if (item.driver === driver) {
          notificationMethods.push({ driver, value: e.target.checked })
          continue
        }

        notificationMethods.push(item)
      }

      onChange({
        ...fields,
        notificationMethods
      })
    }
  }
  return (<Box borderBottom='1px solid #EBEBEB' mb='1em' p='1em' pl={0} pt={0} className='settingsRow'>
    <Stack direction='row' justifyContent='space-between' spacing={2} width='50%'>
      <Box flexGrow={3}>
        <Typography fontWeight={600} fontSize='14px' mb='0.2em'>{notificationsTypeName.get(data.notificationsType) ?? ''}</Typography>
        <Typography fontWeight={500} fontSize='13px' color='#B2B2B2'>{getDesc(data.notificationsType, userInterface)}</Typography>
      </Box>
      <Box flexDirection='column'>
        { data.notificationMethods.map(item => (<Stack
            direction='row'
            justifyContent='right'
            alignItems='center'
            gap='10px'
            mb='0.2em'
            key={item.driver}
          >
            <Typography fontWeight={400} fontSize='13px'>{eventDriverTypeName.get(item.driver) ?? ''}</Typography>
            <AntSwitch checked={item.value} onChange={handleChange(item.driver)} inputProps={{ 'aria-label': 'ant design' }} />
          </Stack>))}
      </Box>
    </Stack>
  </Box>)
}

interface Params {
  data: NotificationSettings[] | undefined
  setData: React.Dispatch<React.SetStateAction<NotificationSettings[] | undefined>>
}

export default function Settings ({ data, setData }: Params) {
  const { currentInterface } = useAuthContext()

  const changeSattings = (data: NotificationSettings) => {
    setData(prev => {
      const result = []
      for (const item of prev ?? []) {
        if (item.notificationsType === data.notificationsType) {
          result.push(data)
          continue
        }
        result.push(item)
      }

      return result
    })
  }

  if (data === undefined) {
    return <></>
  }

  return (<InfoCard title='Настройки уведомлений'>
    <Box sx={{ '& .settingsRow:last-child': { border: 'none', mb: 0, pb: 0 } }} m={0} p={0}>
    { data.map((item, idx) => <Row data={item} key={idx} userInterface={currentInterface} onChange={changeSattings} />) }
    </Box>
  </InfoCard>)
}
