import './styles.sass'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Box, CircularProgress, Typography, Stack } from '@mui/material'
import { StepProps } from 'ui/ModalSigning'
import { CarrierContractWizardType } from './index'
import { skipDiadoc } from 'ui/ActWizard'
import Alert from '@mui/material/Alert'
import 'ui/Diadoc/lib/cadesplugin_api'
import { Certificate } from 'crypto-pro'
import { Id } from 'api/Id'
import { profileContractSign, profileContractSignDiadoc } from 'api/profile'
import { Signer } from 'ui/Diadoc/lib/tools'
import { getContentAndSignature } from 'ui/ActWizard/Signing'

export default function Signing ({ isActive, data, handleNext }: StepProps) {
  const [error, setError] = useState('')
  const once = useRef(true)

  const d = data as CarrierContractWizardType

  const signDev = useCallback((customerId: Id, scanSignedСontract: string | undefined, handleNext: (success: true) => void) => {
    profileContractSign(customerId, scanSignedСontract).then(() => handleNext(true))
  }, [])

  const signProd = useCallback((customerId: Id, filename: string, handleNext: (success: true) => void, boxId: string, cert?: Certificate) => {
    if (!boxId) {
      setError('Компания для подписания документов не выбрана')
      return
    }

    Signer(cert)
      .then(signer => getContentAndSignature(signer, filename))
      .then(signature => profileContractSignDiadoc({ ...signature, fromBoxId: boxId, customerId }))
      .then(() => {
        handleNext(true)
      })
      .catch((err) => setError(err.message))
  }, [])

  useEffect(() => {
    if (!isActive) {
      return
    }

    if (!once.current) {
      return
    }
    once.current = false

    const next = (s: boolean) => {
      d.onSuccess(s)
      handleNext()
    }

    skipDiadoc() || (!!d.isManually && d.scanSignedСontract)
      ? signDev(d.customer.id, d.scanSignedСontract, next)
      : signProd(d.customer.id, d.contract, next, d.boxId, d.certificate)
  }, [isActive, signDev, signProd, handleNext, d.newActList, d.boxId, d.certificate, d.customer.id, d.contract, d.isManually, d.scanSignedСontract, d])

  if (!isActive) {
    return <></>
  }

  return (<>
    <Typography fontSize='14px' fontWeight={400} color='#B2B2B2' mb='2em'>
    Пожалуйста, не закрывайте это окно и дождитесь окончания отправки документов.
    </Typography>
    <Box sx={{ display: 'flex', minWidth: '500px', minHeight: '250px', alignItems: 'center', justifyContent: 'center' }}>
      <Stack display='flex' flexDirection='column' alignItems='center'>
        <CircularProgress />
        { !d.isManually && <Typography>Подписываем договор</Typography> }
        { error && <Alert severity="error">{error}</Alert> }
      </Stack>
    </Box>
  </>)
}
