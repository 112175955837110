import { NotifyEvent } from '.'
import { useEffect } from 'react'

export interface Data {
  truckRunNum: string
  truckRunId: string
  oldWeight: number
  newWeight: number
  message: string
}

const SLUG = 'truck_run_weight_correction_event'

export default function TruckRunWeightCorrection ({ slug, data, toast } :NotifyEvent) {
  useEffect(() => {
    if (slug !== SLUG) {
      return
    }

    if (toast === undefined) {
      return
    }

    if (data === undefined) {
      return
    }

    const { message } = data as Data

    toast(message, 'info')
  }, [slug, data, toast])

  return (<></>)
}
