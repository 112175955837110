import './styles.sass'
import { ChangeEvent, memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAuthContext } from 'AuthContext'
import InfoCard from 'ui/InfoCard'
import { Box, Button, ButtonGroup, Stack, Typography } from '@mui/material'
import TextField from 'ui/TextField'
import clsx from 'clsx'
import Modal from 'ui/Modal'
import AddIcon from '@mui/icons-material/Add'
import SaveIcon from '@mui/icons-material/Save'
import EditIcon from '@mui/icons-material/Edit'
import WarningIcon from '@mui/icons-material/Warning'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import {
  ForwarderAttach,
  forwarderAttachCreate,
  forwarderAttachDelete,
  forwarderAttachGet,
  forwarderAttachList,
  ForwarderAttachStatus,
  forwarderAttachUpdate,
  forwarderCreate,
  ForwarderForUpdate,
  forwarderGet,
  forwarderUpdate,
  ForwarderPosition,
  ForwarderAttachConflict
} from 'api/forwarder'
import TsToFormatDate from 'util/TsToFormatDate'
import { DEFAULT_COORDINATES } from 'constants/map'
import NavigatePanel from 'ui/NavigatePanel'
import { useMainRoutes } from 'routes'
import { Address } from 'api/address'
import { Id, isId } from 'api/Id'
import { personName } from 'util/personName'
import schema from 'validation/Forwarder'
import useValidate from 'validation/validate'
import OsmBid from 'ui/Osm'
import DatePicker, { RangeDate } from 'ui/DatePicker'
import SelectAddress from 'ui/SelectAddress'
import shortAddress from 'util/shortAddress'
import Select from 'ui/Select'
import { phoneClean, PHONE_MASK } from 'common/Mask/PhoneMask'
import dayjs from 'dayjs'
import { UserStatus } from 'api/UserStatus'
import formatDate from 'util/FormatDate'
import ChipUserStatus from 'common/ChipUserStatus/forwarder'
import { EmailMask } from 'common/Mask/EmailMask'

interface AttachModalProps {
  open: boolean
  onClose: () => void
  forwarderId: Id
  attachId: Id | null
  onSuccess?: () => void
}

const positionList = [
  { slug: ForwarderPosition.forwarder, name: 'Экспедитор' },
  { slug: ForwarderPosition.chief_forwarder, name: 'Старший экспедитор' }
]

const AttachModal = ({ open, onClose, forwarderId, attachId, onSuccess }: AttachModalProps) => {
  const { handleResponseFailure } = useAuthContext()
  const [addressId, setAddressId] = useState<Id>()
  const [dateRange, setDateRange] = useState<RangeDate>()
  const [conflicts, setConflicts] = useState<ForwarderAttachConflict[]>()
  const [addressError, setAddressError] = useState<string>()
  const [dateRangeError, setDateRangeError] = useState<string>()

  const close = useCallback(() => {
    setAddressId(undefined)
    setDateRange(undefined)
    onClose()
  }, [onClose])

  const save = useCallback(async () => {
    setAddressError(!isId(addressId) ? 'Поле обязательно к заполнению' : undefined)
    setDateRangeError(dateRange === undefined ? 'Поле обязательно к заполнению' : undefined)

    if (!isId(addressId) || dateRange === undefined) {
      return
    }

    const beginPeriodTs = dateRange.startTs
    const endPeriodTs = dateRange.endTs

    if (attachId === null) {
      const { id, conflicts } = await forwarderAttachCreate({
        forwarderId,
        loadingAddressId: addressId,
        beginPeriodTs,
        endPeriodTs
      })

      if (conflicts.length > 0) {
        setConflicts(conflicts)
      } else {
        setConflicts(undefined)

        if (!id) {
          handleResponseFailure('Произошла ошибка, закрепление не было добавленно')
        } else {
          onSuccess && onSuccess()
          close()
        }
      }
    } else {
      const { updated, conflicts } = await forwarderAttachUpdate({
        id: attachId,
        loadingAddressId: addressId,
        beginPeriodTs,
        endPeriodTs
      })

      if (conflicts.length > 0) {
        setConflicts(conflicts)
      } else {
        setConflicts(undefined)

        if (updated) {
          onSuccess && onSuccess()
          close()
        }
      }
    }
  }, [forwarderId, addressId, dateRange, attachId, handleResponseFailure, onSuccess, close])

  const init = useCallback(async () => {
    setConflicts(undefined)
    setAddressError(undefined)
    setDateRangeError(undefined)

    if (attachId === null) {
      setAddressId(undefined)
      setDateRange(undefined)
      return
    }

    const result = await forwarderAttachGet(attachId)

    if (result === null) {
      handleResponseFailure('Закрепление не найдено')
      close()
      return
    }

    setAddressId(result.loadingAddress.id)
    setDateRange({ startTs: result.beginPeriodTs, endTs: result.endPeriodTs })
  }, [attachId, close, handleResponseFailure])

  useEffect(() => { init() }, [init, attachId])

  return (<Modal
    open={open}
    onClose={close}
    title={attachId === null ? 'Добавить закрепление' : 'Редактировать закрепление'}
    content={<>
      <div className='forwarderAttachModal'>
        { conflicts && <Box sx={{
          background: '#FFA800',
          borderRadius: '4px',
          color: '#FFFFFF',
          mb: 2,
          display: 'flex',
          gap: 2,
          alignItems: 'start',
          width: '28em',
          p: '1em'
        }}>
          <WarningIcon/>
          <Typography sx={{
            fontWeight: '400',
            fontSize: '16px',
            lineHeight: '140%',
            letterSpacing: '-0.02em'
          }}>У пункта погрузки уже существует закрепление на выбранный период. Для сохранения необходимо выбрать другие параметры закрепления.</Typography>
        </Box>}
        <Stack width='30em' mt='12px'>
          <SelectAddress
            label='Пункт погрузки'
            placeholder='Выберите пункт закрепления экспедитора'
            value={addressId}
            onChange={id => setAddressId(id)}
            errorMessage={addressError}
            itemName
          />
        </Stack>
        <Stack width='30em' mt='12px'>
          <DatePicker
            label='Период закрепления'
            placeholder='Выберите даты закрепления'
            range={dateRange}
            onChange={(startTs, endTs) => {
              if (startTs !== undefined && endTs !== undefined) {
                setDateRange(startTs < endTs ? { startTs, endTs } : { startTs: endTs, endTs: startTs })
              }
            }}
            rangeDate
            errorMessage={dateRangeError}
            shouldDisableDate={(day) => dayjs().isAfter(day, 'day')}
          />
        </Stack>
      </div>
    </>}
    actions={<>
      <Button variant='outlined' color="secondary" size='small' onClick={close}>
        Отменить
      </Button>
      <Button variant='contained' size='small' onClick={() => save()}>
        { attachId === null ? 'Добавить' : 'Cохранить' }
      </Button>
    </>}
  />)
}

interface AttachItemProps {
  status: ForwarderAttachStatus
  period: string
  address: string
  coordinates: Address['coordinates']
  disabled?: boolean
  onEdit?: () => void
  onDelete?: () => void
}

const AttachItem = memo(({ status, period, address, onEdit, onDelete, coordinates, disabled }: AttachItemProps) => {
  return (
    <div className={clsx('forwarderAttachItem', {
      forwarderAttachItem__status_active: status === ForwarderAttachStatus.active,
      forwarderAttachItem__status_archival: status === ForwarderAttachStatus.archival,
      forwarderAttachItem__status_planned: status === ForwarderAttachStatus.planned
    })}>
      <div className='forwarderAttachItem__info'>
        <span className='forwarderAttachItem__info_period'>{period}</span>
        <span className='forwarderAttachItem__info_dot'>•</span>
        <span className='forwarderAttachItem__info_address'>{address}</span>
      </div>
      { !disabled && status !== ForwarderAttachStatus.archival && <div className='forwarderAttachItem__controls'>
        <Button variant='outlined' size='small' className='forwarder__btn' onClick={onEdit}>
          <EditIcon sx={{ color: '#B2B2B2', width: '16px' }} />
        </Button>
        <Button variant='outlined' size='small' className='forwarder__btn' onClick={onDelete}>
          <DeleteForeverIcon sx={{ color: '#B2B2B2', width: '16px' }} />
        </Button>
      </div>}
      <div className='forwarderAttachItem__map'>
        <OsmBid markers={[{ point: coordinates }]} staticMap />
      </div>
    </div>
  )
})

type UpdateParams = Omit<ForwarderForUpdate, 'id'>

interface DataMisc {
  status: UserStatus
  archiveTs?: number
  archiveComment?: string
  truckRuns?: Id[]
}

export default function Forwarder () {
  const { id } = useParams()

  const [data, setData] = useState<UpdateParams>()
  const [dataMisc, setDataMisc] = useState<DataMisc>()
  const [isNew, setIsNew] = useState(true)

  const [viewArchive, setViewArchive] = useState(false)
  const [editAttachId, setEditAttachId] = useState<Id | null>()
  const [attachList, setAttachList] = useState<ForwarderAttach[]>([])

  const { links, routesMap } = useMainRoutes()
  const { handleResponseFailure, handleResponseSuccess } = useAuthContext()
  const navigate = useNavigate()
  const { check, errors } = useValidate(schema)

  const fetchForwrderAttachList = useCallback(async () => {
    if (isId(id)) {
      const attachList = await forwarderAttachList({ forwarderId: id })
      setAttachList(attachList)
    } else {
      setAttachList([])
    }
  }, [id])

  const init = useCallback(async () => {
    if (id === 'add') {
      setData({})
      return
    }

    const result = isId(id) ? await forwarderGet(id) : null

    if (result !== null) {
      fetchForwrderAttachList()
      const { id, status, archiveTs, archiveComment, truckRuns, attaches, ...data } = result
      setData(data)
      setDataMisc({ status, archiveTs, archiveComment, truckRuns })
      setIsNew(false)
      if (status === UserStatus.archive) {
        setViewArchive(true)
      }
    } else {
      handleResponseFailure('Экспедитор не найден')
    }
  }, [fetchForwrderAttachList, id, handleResponseFailure])

  const isEdit = useMemo(
    () => dataMisc?.status !== UserStatus.archive,
    [dataMisc?.status]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { init() }, [])

  const updateString = (key: keyof UpdateParams) => {
    return (event: ChangeEvent<HTMLInputElement>) => {
      const { target: { value } } = event
      setData({ ...data, [key]: value === '' ? undefined : value })
    }
  }

  const clear = (data?: UpdateParams): UpdateParams | undefined => {
    if (data === undefined) {
      return undefined
    }
    const { phone } = data
    return {
      ...data,
      phone: phone === undefined ? undefined : phoneClean(phone)
    }
  }

  const save = useCallback(async () => {
    if (!isEdit) {
      return
    }

    const clearData = clear(data)

    if (!check(clearData)) {
      return
    }

    if (isId(id)) {
      const result = await forwarderUpdate({ id, ...clearData })

      if (result) {
        handleResponseSuccess('Данные экспедитора изменены')
        navigate(links.REGISTRY_FORWARDERS_PAGE)
      }
    } else {
      const id = await forwarderCreate(clearData)

      if (id !== null) {
        handleResponseSuccess('Экспедитор создан')
        navigate(`${links.REGISTRY_FORWARDER_PAGE}/${id}`)
        setIsNew(false)
      }
    }
  }, [isEdit, data, check, id, handleResponseSuccess, navigate, links.REGISTRY_FORWARDERS_PAGE, links.REGISTRY_FORWARDER_PAGE])

  const attachItems = useMemo(() => {
    const list = attachList.map(item => {
      return {
        status: item.status,
        period: `${TsToFormatDate(item.beginPeriodTs, 'dd.MM.yyyy')} - ${TsToFormatDate(item.endPeriodTs, 'dd.MM.yyyy')}`,
        address: shortAddress(item.loadingAddress),
        coordinates: item.loadingAddress.coordinates ?? DEFAULT_COORDINATES,
        onEdit: () => setEditAttachId(item.id),
        onDelete: () => {
          forwarderAttachDelete(item.id).then(deleted => {
            if (deleted) {
              fetchForwrderAttachList()
            }
          })
        }
      }
    })

    return {
      active: list.filter(({ status }) => status === ForwarderAttachStatus.active),
      planned: list.filter(({ status }) => status === ForwarderAttachStatus.planned),
      archival: list.filter(({ status }) => status === ForwarderAttachStatus.archival)
    }
  }, [attachList, fetchForwrderAttachList])

  if (data === undefined) {
    return <></>
  }

  return (
    <>
      {
        isId(id) && isEdit
          ? <AttachModal
            open={editAttachId !== undefined}
            onClose={() => { setEditAttachId(undefined) }}
            forwarderId={id}
            attachId={editAttachId === undefined ? null : editAttachId}
            onSuccess={() => { fetchForwrderAttachList() }}
          />
          : <></>
      }
      <div className='forwarder'>
        <NavigatePanel
          title={<Box sx={{ display: 'flex', gap: '1em', alignItems: 'center' }}>
            <Box>{isNew ? 'Новый экспедитор' : personName(data)}</Box>
            { dataMisc && <ChipUserStatus status={dataMisc.status} truckRuns={dataMisc.truckRuns} /> }
          </Box>}
          breadcrumbs={{
            items: [
              { title: isNew ? 'Добавить экспедитора' : personName(data) }
            ],
            defaultItems: routesMap.getBreadcrumbs(links.REGISTRY_FORWARDERS_PAGE)
          }}
          actions={
            <Stack direction='row' spacing={2} justifyContent='end'>
              <Button variant='outlined' color='secondary' size='small' onClick={() => navigate(-1)}>
                Отменить
              </Button>
              { isEdit && <Button variant='contained' color='success' size='small' onClick={save}>
                Сохранить <SaveIcon sx={{ width: '15px', height: '15px', ml: '10px' }}/>
              </Button> }
            </Stack>
          }
        />
        <div className='forwarder__body'>
          <div className='forwarder__content'>
            { dataMisc?.status === UserStatus.archive && <Box sx={{
              mt: '10px',
              background: '#EBEBEB',
              borderRadius: '8px',
              padding: '32px 24px',
              color: '#111'
            }}>
              <Typography sx={{
                fontWeight: '600',
                fontSize: '18px',
                lineHeight: '22px',
                letterSpacing: '-0.02em'
              }}>
                Экспедитор был архивирован {dataMisc?.archiveTs ? formatDate(new Date(dataMisc.archiveTs * 1000)) : ''}
              </Typography>
              {dataMisc?.archiveComment && <Typography sx={{ mt: 2, whiteSpace: 'pre-wrap' }}>
                {dataMisc?.archiveComment}
              </Typography>}
            </Box>}
            <InfoCard
              title='Общая информация'
            >
              <>
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} sx={{ mb: 2 }}>
                  <TextField name='familyName' label="Фамилия" placeholder='Укажите фамилию экспедитора' width='33.33%' value={data.familyName} onChange={updateString('familyName')} errors={errors} disabled={!isEdit} />
                  <TextField name='firstName' label="Имя" placeholder='Укажите имя экспедитора' width='33.33%' value={data.firstName} onChange={updateString('firstName')} errors={errors} disabled={!isEdit} />
                  <TextField name='secondName' label="Отчество" placeholder='Укажите отчество экспедитора' width='33.33%' value={data.secondName} onChange={updateString('secondName')} errors={errors} disabled={!isEdit} />
                </Stack>

                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                  <Select
                    name='position'
                    label='Должность'
                    placeholder='Выберите должность экспедитора'
                    options={positionList.map(({ slug, name }) => ({ value: slug, name }))}
                    value={data.position}
                    onChange={(position) => setData((data) => ({ ...data, position }))}
                    errors={errors}
                    disabled={!isEdit}
                    width='33.33%'
                  />
                  <TextField
                    name='email'
                    label="Email"
                    placeholder='Укажите адрес электронной почты экспедитора'
                    width='33.33%'
                    value={data.email}
                    onChange={updateString('email')}
                    errors={errors}
                    disabled={!isEdit}
                    maskParams={{
                      mask: EmailMask,
                      guide: false
                    }}
                  />
                  <TextField
                    name='phone'
                    label="Номер телефона"
                    placeholder='Укажите номер телефона экспедитора'
                    width='33.33%'
                    value={data.phone}
                    onChange={updateString('phone')}
                    errors={errors}
                    maskParams={{
                      mask: PHONE_MASK
                    }}
                    disabled={!isNew || !isEdit}
                  />
                </Stack>
              </>
            </InfoCard>
            { !isNew && <InfoCard
              title='Закрепления'
              actions={isEdit &&
                <Button variant='contained' size='small' endIcon={<AddIcon />} onClick={() => setEditAttachId(null)}>
                  Добавить закрепление
                </Button>
              }
            >
              { attachList.length === 0
                ? <Box sx={{
                  height: '90px',
                  background: '#FAFAFA',
                  border: '1px solid #EBEBEB',
                  borderRadius: '4px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '20px',
                  fontWeight: '500',
                  fontSize: '14px',
                  lineHeight: '17px',
                  color: '#B2B2B2'
                }}>
                  { isEdit
                    ? 'Добавьте первое закрепление, нажав кнопку “Добавить закрепление”'
                    : 'Закреплений нет'
                  }
                </Box>
                : <Stack direction='column' gap={1}>
                  <ButtonGroup size='small' sx={{ mb: 3 }}>
                    <Button variant={!viewArchive ? 'contained' : undefined} sx={{ px: 5 }} onClick={() => setViewArchive(false)}>Все</Button>
                    <Button variant={viewArchive ? 'contained' : undefined} sx={{ px: 5 }} onClick={() => setViewArchive(true)} disabled={ dataMisc?.status !== UserStatus.archive && attachItems.archival.length === 0}>Архив</Button>
                  </ButtonGroup>
                  { !viewArchive && <>
                    { attachItems.active.length > 0 && <Box className='forwarder__attachTitle'>Активные</Box> }
                    { attachItems.active.map((props, idx) => <AttachItem {...props} key={idx} disabled={!isEdit}/>) }
                    { attachItems.planned.length > 0 && <Box className='forwarder__attachTitle' sx={{ pt: attachItems.active.length > 0 ? 3 : 0 }}>Запланированные</Box> }
                    { attachItems.planned.map((props, idx) => <AttachItem {...props} key={idx} disabled={!isEdit}/>) }
                  </>}
                  { viewArchive && <>
                    { attachItems.archival.map((props, idx) => <AttachItem {...props} key={idx} disabled={!isEdit}/>) }
                  </>}
                </Stack>
              }
            </InfoCard>}
          </div>
        </div>
      </div>
    </>
  )
}
