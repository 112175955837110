import './styles.sass'
import { MoreHoriz, Edit, Warning, DeleteForever } from '@mui/icons-material'
import { Box, Button, Stack, Typography } from '@mui/material'
import { Id, isId } from 'api/Id'
import {
  shippingCreate,
  shippingTruckRunList,
  ShippingTruckRunList,
  ShippingTruckRunListParams,
  ShippingTruckRunStatus as Status
} from 'api/shipping'
import { DAY } from 'constants/Time'
import { useCallback, useEffect, useState } from 'react'
import InfoCard from 'ui/InfoCard'
import InfoCell, { InfoCellProps } from 'ui/InfoCell'
import SquareButton from 'ui/SquareButton'
import { personName } from 'util/personName'
import trailerName from 'util/trailerName'
import TsToFormatDate from 'util/TsToFormatDate'
import vehicleName from 'util/vehicleName'
import ChipTruckRunStatus from 'common/ChipTruckRunStatusForBid'
import { SortOrder } from 'api/SortOrder'
import None from 'ui/None'
import { useMainRoutes } from 'routes'
import { useNavigate } from 'react-router-dom'
import { toTon } from 'util/weight'
import Tooltip from 'ui/Tooltip'
import ChipStatus from 'ui/ChipStatus'
import { Bid } from 'api/bid'
import { dayStart, today } from 'util/date'
import RespondTruckRuns, { TruckRun } from 'pages/CarrierBid/RespondTruckRuns'
import SaveIcon from '@mui/icons-material/Save'
import { v4 as uuid } from 'uuid'
import useValidate from 'validation/validate'
import schemaShipping from 'validation/Shipping'
import { useAuthContext } from 'AuthContext'
import { RequireOnly } from 'util/type/RequireOnly'
import { Customer } from 'api/customer'

interface ItemParams {
  data: ShippingTruckRunList
  viewTruckRunId?: Id
}

interface NewItem {
  id: string
  truckRun: TruckRun
  trips: number
}

const TruckRunItem = ({ data, viewTruckRunId }: ItemParams) => {
  const { links } = useMainRoutes()
  const navigate = useNavigate()

  const highlight = viewTruckRunId === data.id
  const isRedirect = data.unloadingAddress !== undefined

  const cells: InfoCellProps[] = [
    { label: 'Номер рейса', value: data.num }
  ]

  data.forwarder !== undefined && cells.push({ label: 'Экспедитор', value: data.forwarder })

  cells.push(
    { label: 'Водитель', value: `${personName(data.driver)}` },
    { label: 'Транспортное средство', value: vehicleName(data.vehicle) },
    { label: 'Прицеп', value: trailerName(data.trailer) }
  )

  data.weight !== undefined && cells.push({ label: 'Вес погрузки', value: <>{toTon(data.weight)} тн</> })
  data.billNumber !== undefined && cells.push({ label: 'Номер ТрН/ТТН', value: data.billNumber })
  data.finalWeight !== undefined && cells.push({ label: 'Вес разгрузки', value: <>{toTon(data.finalWeight)} тн</> })

  const edit = false

  return (
    <InfoCard
      style={{
        marginBottom: '4px',
        background: highlight ? '#fff4e5' : undefined,
        borderColor: highlight ? '#ff9800' : isRedirect ? '#ffa800' : undefined
      }}>
      <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
        <Stack direction='row' gap={1}>
        { cells.map((item, idx) => <InfoCell {...item} key={idx} />) }
        </Stack>
        <Stack direction='row' spacing={2} alignItems='center'>
          <Box>
          { data.status === Status.way && data.hasLongToWay && <Tooltip
              title={<Typography fontSize='14px' fontWeight={600} >Рейс находится в пути более двух суток</Typography>}
              arrow
              placement='right'
              view
            >
              <Warning sx={{ color: '#FFA800', display: 'block' }} />
            </Tooltip> }
          </Box>
          { isRedirect && <ChipStatus color='yellowBorder' label='Переадресация' style={{ opacity: data.consignee ? 0.7 : 1 }}/> }
          {ChipTruckRunStatus(data.status)}
          { edit && <SquareButton
            size='small'
            variant='outlined'
            color='secondary'
            onClick={() => {
              // data.id
            }} >
            <Edit style={{ color: '#94A3B8', width: '16px' }}
          />
          </SquareButton> }
          <SquareButton
            variant='contained'
            color='primary'
            onClick={() => navigate(`${links.CARRIER_TRIPS_PAGE}/${data.id}`)}
          >
            <MoreHoriz sx={{ color: '#ffffff', width: '16px' }} />
          </SquareButton>
        </Stack>
      </Stack>
    </InfoCard>
  )
}

const TruckRunItems = ({ list, viewTruckRunId, bid, refresh, openContractModal }: {
  list: [number, ShippingTruckRunList[]][],
  openContractModal: (customer: RequireOnly<Customer, 'id' | 'fullName'>) => void
  bid?: Bid,
  refresh?: () => void
  viewTruckRunId?: Id
}) => {
  const todayTs = today()
  const [newItems, setNewItems] = useState<NewItem[]>([])
  const { check } = useValidate(schemaShipping)
  const newDayItems = useCallback((day: number) => {
    return newItems.filter(({ truckRun: { loadingTs } }) => loadingTs === day)
  }, [newItems])
  const { handleResponseFailure, handleResponseSuccess } = useAuthContext()
  const addNewItem = (loadingTs: number) => setNewItems((items) => [...items, { truckRun: { loadingTs }, trips: 1, id: uuid() }])
  const clearNewItems = (day: number) => setNewItems((items) => items.filter(({ truckRun: { loadingTs } }) => loadingTs !== day))
  const saveNewItems = (day: number) => {
    if (!bid) {
      return
    }

    const shipping = {
      bidId: bid.id,
      truckRuns: newDayItems(day).flatMap(({ truckRun, trips }) => [...Array(trips).keys()].map(() => truckRun)),
      relay: true
    }

    if (!check(shipping)) {
      handleResponseFailure('Необходимо заполнить все поля')
      return
    }

    shippingCreate(shipping).then(({ success, conflicts }) => {
      if (success) {
        handleResponseSuccess('Рейсы сохранены')
        clearNewItems(day)
        refresh && refresh()
      } else if (conflicts?.customer) {
        openContractModal(conflicts.customer)
      }
    })
  }

  return (<>
    { list.map(([day, list]) => {
      const addable = !bid?.autoFill && !bid?.blockedManualFill && day >= todayTs
      const newItems = newDayItems(day)

      return <InfoCard
        key={day}
      >
        <Stack gap={2}>
          <Stack direction="row" justifyContent="space-between">
            <Typography sx={{
              fontWeight: '600',
              fontSize: '18px',
              lineHeight: '21.78px',
              letterSpacing: '-0.01em',
              color: '#111'
            }}>
              {/* {day === today ? 'Сегодня' : day === today + DAY ? 'Завтра' : TsToFormatDate(day, 'd MMMM')} */}
              {TsToFormatDate(day, 'd MMMM')}
            </Typography>
            <Stack direction="row" gap={1}>
              { addable && newItems.length === 0 && <Button
                color='inherit'
                variant='contained'
                onClick={() => addNewItem(day)}
              >Добавить рейсы</Button> }
              { addable && newItems.length > 0 && <>
                <Button variant='outlined' color='secondary' size='small' onClick={() => clearNewItems(day)}>
                  Отменить
                </Button>
                <Button variant='contained' color='success' size='small' onClick={() => saveNewItems(day)}>
                  Сохранить <SaveIcon sx={{ width: '15px', height: '15px', ml: '10px' }}/>
                </Button>
              </>}
            </Stack>
          </Stack>
          { addable && newItems.length > 0 && <Stack direction='column' sx={{ mt: 2 }}>
            {newItems.map(({ truckRun, trips, id }) => <Stack direction="row" gap={1}>
              <Box sx={{ width: '100%' }}>
                <RespondTruckRuns
                  key={id}
                  truckRun={truckRun}
                  setTruckRun={(value) => setNewItems((items) => items.map((item) => {
                    const truckRun = value !== undefined && item.id === id
                      ? typeof value === 'object' ? value : value(item.truckRun)
                      : undefined

                    return truckRun === undefined ? item : { truckRun, trips: item.trips, id }
                  }))}
                  trips={trips}
                  setTrips={(value) => setNewItems((items) => items.map((item) =>
                    item.id === id
                      ? { truckRun: item.truckRun, trips: typeof value === 'number' ? value : value(item.trips), id }
                      : item
                  ))}
                  seatCount={[...Array(10).keys()].map((key) => key + 1)}
                  loadOptions={true}
                />
              </Box>
              <SquareButton
                variant='contained'
                color='error'
                widthHeight='40px'
                sx={{ mt: 3 }}
                onClick={() => setNewItems((items) => items.filter((item) => item.id !== id))}
              >
                <DeleteForever style={{ color: '#ffffff' }}/>
              </SquareButton>
            </Stack>)}
            <Button
              color='inherit'
              variant='contained'
              sx={{ width: '25%' }}
              onClick={() => addNewItem(day)}
            >Добавить водителя</Button>
          </Stack> }
          { list.length > 0 && <Stack direction='column'>
            {list.map((item, idx) => <TruckRunItem
              data={item}
              key={idx}
              viewTruckRunId={viewTruckRunId}
            />)}
          </Stack> }
        </Stack>
      </InfoCard>
    }) }
  </>)
}

interface Params {
  bid?: Bid
  onlyRedirected?: boolean
  viewTruckRunId?: Id
  openContractModal: (customer: RequireOnly<Customer, 'id' | 'fullName'>) => void
}

export default function TruckRunsList ({ bid, onlyRedirected = false, viewTruckRunId, openContractModal }: Params) {
  const [list, setList] = useState<Map<number, ShippingTruckRunList[]>>()

  const initTruckRuns = useCallback(() => {
    if (!bid || !isId(bid.id)) {
      return
    }

    const filters: ShippingTruckRunListParams['filters'] = {
      bidId: bid.id,
      status: [
        Status.confirmed,
        Status.way,
        Status.arrived,
        Status.completed,
        Status.archiveAct,
        Status.archiveOutdated,
        Status.archiveProblem
      ]
    }

    shippingTruckRunList({ filters, sort: { loadingTs: SortOrder.asc } }).then((result) => {
      const { beginTs, endTs, autoFill, blockedManualFill } = bid
      const startTs = beginTs === undefined ? undefined : Math.max(dayStart(beginTs), today())
      const byDay = new Map<number, ShippingTruckRunList[]>(
        onlyRedirected || startTs === undefined || endTs === undefined || endTs < startTs
          ? []
          : Array.from(
            { length: Math.floor((endTs - startTs) / DAY) + 1 },
            (_, day) => [dayStart(startTs) + day * DAY, []]
          )
      )

      for (const item of result) {
        const { loadingTs, unloadingAddress } = item

        if (onlyRedirected && !unloadingAddress) {
          continue
        }

        const day = dayStart(loadingTs)
        const dayItems = byDay.get(day) ?? []
        dayItems.push(item)
        byDay.set(day, dayItems)
      }

      const byDayList = autoFill || blockedManualFill ? [...byDay].filter(([_, tr]) => tr.length > 0) : [...byDay]

      setList(new Map(byDayList.sort(([a], [b]) => a - b)))
    })
  }, [bid, onlyRedirected])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => initTruckRuns(), [])

  if (list === undefined) {
    return <></>
  }

  return (<>
    <div className='dispatcherBids__truckRunsList'>
      <div className='dispatcherBids__truckRunsList_content'>
        { list.size > 0
          ? <div className='dispatcherBids__truckRunsList_content_items'>
            { <TruckRunItems
              list={[...list]}
              bid={bid}
              refresh={initTruckRuns}
              viewTruckRunId={viewTruckRunId}
              openContractModal={openContractModal}
            />}
          </div>
          : <None desc='Нет рейсов.' m='1.5em' />}
      </div>
    </div>
  </>)
}
