import './styles.sass'
import { useEffect, useState } from 'react'
import { Box, CircularProgress, Typography, Stack } from '@mui/material'
import { StepProps } from 'ui/ModalSigning'
import { Signer, Sign } from 'ui/Diadoc/lib/tools'
import Alert from '@mui/material/Alert'
import { skipDiadoc } from 'ui/ActWizard'
import { ActRevocationWizardData } from './index'

export default function Signing ({ isActive, data, handleNext }: StepProps) {
  const [error, setError] = useState('')

  const d = data as ActRevocationWizardData

  useEffect(() => {
    if (!isActive) {
      return
    }

    if (skipDiadoc() || d.signature !== '') {
      handleNext()
      return
    }

    if (!d.boxId) {
      setError('Компания для подписания документов не выбрана')
      return
    }

    if (!d.xmlBase64) {
      setError('XML не найден')
      return
    }

    Signer(d.certificate)
      .then((signer) => {
        return Sign(signer, d.boxId, d.xmlBase64)
      })
      .then((signature) => {
        d.onSignatureLoaded(signature)
        handleNext()
      })
      .catch((err) => setError(err.message))
  }, [isActive, d, handleNext])

  if (!isActive) {
    return <></>
  }

  return (<>
    <Typography fontSize='14px' fontWeight={400} color='#B2B2B2' mb='2em'>
      Пожалуйста, не закрывайте это окно и дождитесь окончания отправки документов.
    </Typography>
    <Box sx={{ display: 'flex', minWidth: '500px', minHeight: '250px', alignItems: 'center', justifyContent: 'center' }}>
      <Stack display='flex' flexDirection='column' alignItems='center'>
        <CircularProgress />
        <Typography>Подписываем запрос</Typography>
        { error && <Alert severity="error">{error}</Alert> }
      </Stack>
    </Box>
  </>)
}
