import { makeJsonSchema, SimpleSchema } from './validate'

export type LoadConfrimData = {
  actualCargoSlug: string
  weight: number
  billNumber: string
  billTs: number
}

const simpleSchema: SimpleSchema<LoadConfrimData> = {
  type: 'object',
  properties: {
    actualCargoSlug: { type: 'string' },
    billNumber: { type: 'string' },
    billTs: { type: 'number' },
    weight: { type: 'number' }
  }
}

const schema = makeJsonSchema<LoadConfrimData>(simpleSchema)

export default schema
