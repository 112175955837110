import { idPattern } from 'api/Id'
import { ShippingForCreate } from 'api/shipping'
import { makeJsonSchema, SimpleSchema } from './validate'

const simpleSchema: SimpleSchema<ShippingForCreate> = {
  type: 'object',
  properties: {
    bidId: { type: 'string', pattern: idPattern },
    truckRuns: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          driverId: { type: 'string', pattern: idPattern },
          vehicleId: { type: 'string', pattern: idPattern },
          trailerId: { type: 'string', pattern: idPattern, optional: true },
          loadingTs: { type: 'number' }
        }
      }
    },
    relay: { type: 'boolean', optional: true }
  }
}

const schema = makeJsonSchema<ShippingForCreate>(simpleSchema)

export default schema
