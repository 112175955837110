import { Box, Button } from '@mui/material'
import { NotifyEvent } from '.'
import { useEffect } from 'react'
import { Id } from 'api/Id'
import { useMainRoutes } from 'routes'

export interface Data {
  carrierId: Id
  carrierName: string
  message: string
}

const SLUG = 'kontur_focus_success_response_for_dispatcher_event'

export default function FocusSuccessResponse ({ slug, data, snack, navigate } :NotifyEvent) {
  const { links } = useMainRoutes()

  useEffect(() => {
    if (slug !== SLUG) {
      return
    }

    if (snack === undefined) {
      return
    }

    if (data === undefined) {
      return
    }

    const { message, carrierId } = data as Data

    const open = () => {
      if (navigate === undefined) {
        return
      }

      navigate(`${links.REGISTRY_CARRIER_PAGE}/${carrierId}`)
    }

    snack({
      title: message,
      color: 'info',
      content: (<Box>
        <Button variant='outlined' color='inherit' onClick={open}>Просмотреть</Button>
      </Box>),
      options: { autoHideDuration: 10000 }
    })
  }, [slug, data, snack, links.REGISTRY_CARRIER_PAGE, navigate])

  return (<></>)
}
