import { useState, useEffect } from 'react'
import 'ui/Diadoc/lib/cadesplugin_api'
import { Certificate, getUserCertificates } from 'crypto-pro'
import { LocalStorage } from 'util/LocalStorage'
import Select from 'ui/Select'

interface CertificateSelectParams {
  onSelect: (cert: Certificate | undefined) => void
  onError: (message: string) => void
}

const thumbprintStorage: LocalStorage<string> = new LocalStorage('thumbprint', (x: unknown): x is string => {
  return typeof x == 'string'
})

export default function CertificateSelect ({ onSelect, onError }: CertificateSelectParams) {
  const [thumbprint, setThumbprint] = useState<string>()
  const [certificateList, setCertificateList] = useState<Certificate[]>([])

  useEffect(() => {
    if (certificateList.length === 0) {
      getUserCertificates()
        .then((cc) => {
          setCertificateList(cc)

          let thumb = thumbprintStorage.get()
          if (!thumb && cc.length !== 0) {
            thumb = cc[0].thumbprint
          }

          if (thumb) {
            const c = cc.find((c) => c.thumbprint === thumb)
            if (c) {
              setThumbprint(thumb)
            }
          }
        })
        .catch((err) => onError(err.message))
    }
  }, [certificateList, onError])

  useEffect(() => {
    if (thumbprint) {
      const c = certificateList.find((c) => c.thumbprint === thumbprint)
      if (c) {
        thumbprintStorage.set(thumbprint)
        onSelect(c)
      }
    }
  }, [thumbprint, certificateList, onSelect])

  return <Select
    label='Сертификат'
    placeholder='Выберите сертификат'
    options={certificateList.map(({ thumbprint, name }) => ({ value: thumbprint, name }))}
    value={thumbprint}
    onChange={setThumbprint}
    width='100%'
  />
}

export function unquoteCertificateName (s: string) {
  return s.replace(/^"/, '').replace(/"$/, '').replaceAll(/""/g, '"')
}
