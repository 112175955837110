import './styles.sass'
import { Box } from '@mui/material'
import { StepProps } from 'ui/ModalSigning'
import { skipDiadoc } from 'ui/ActWizard'
import SignatureDetails from 'ui/ActWizard/SignatureDetails'
import { useEffect } from 'react'
import { ActRevocationWizardData } from './index'

export default function RevocationConfirm ({ isActive, data, handleNext, handleToStep }: StepProps) {
  const d = data as ActRevocationWizardData

  useEffect(() => {
    if (isActive) {
      if (skipDiadoc()) {
        handleNext()
      }
    }
  }, [isActive, handleNext])

  if (!isActive) {
    return <></>
  }

  return (
    <Box sx={{ minWidth: '500px', minHeight: '250px' }}>

      <SignatureDetails
        data={d}
        onRenew={() => {
          d.onAuthorized(false)
          handleToStep(d.authFirstStep)
        }}/>

    </Box>
  )
}
