import { mainService } from 'api/service'
import { Id } from 'api/Id'

export enum DiffType {
  NEW = 'NEW',
  MODIFIED = 'MODIFIED',
  REMOVED = 'REMOVED',
  ARRAY = 'ARRAY'
}

export interface Diff {
  key: string
  from?: string | number | boolean | object | null
  to?: string | number | boolean | object | null
  details?: Diff[]
  type?: DiffType
}

export interface History {
  col: string
  id: Id
  user: string
  userPresentation: string
  date: number
  diff: Diff
  current?: Record<string, unknown>
}

export interface HistoryForUpdate {
  col: string
  id: Id
  key: string
  value: unknown
}

export interface HistoryFilter {
  col?: string[]
  id?: Id[]
}

export interface HistoryResult {
  list: History[],
  count: number
}

const request = (method: string, params: object) => mainService.request(`history.${method}`, params)

export const historyList = (filter: HistoryFilter = {}, limit?: number, offset?: number) => request('list', { filter, limit, offset }) as Promise<HistoryResult>
export const historyUpdate = (params: HistoryForUpdate) => request('update', params) as Promise<boolean>
