import InfoCard from 'ui/InfoCard'
import Select from 'ui/Select'
import { useCallback, useEffect, useState } from 'react'
import { Button, Grid, List, ListItem } from '@mui/material'
import { SectionParams } from '../index'
import { addressRegions } from 'api/address'

export interface PreferredRegionsParams extends SectionParams<string[]>{
  addPreferredRegions: (value:string[]) => void
  isRegionsChanged: boolean
}
export default function PreferredRegions ({ data, setData, addPreferredRegions, isRegionsChanged }: PreferredRegionsParams) {
  const [regionOptions, setRegionOptions] = useState<{value: string, name:string }[]>([])
  const onChange = useCallback((value: string[]) => {
    setData(() => (value))
  }, [setData])

  useEffect(() => {
    addressRegions()
      .then((res) => {
        const options = res.map((item) => ({
          value: item,
          name: item
        }))
        setRegionOptions(options)
      })
      .catch(() => {
        console.log('Ошибка при получении регионов ')
      })
  }, [])

  return (
    <InfoCard title="Предпочтительные регионы">
      <Grid container spacing={2}>
        <Grid item sm={10}>
          <Select options={regionOptions} multiple={true} value={data} onChange={onChange} disableClearable={false} inputScroll={true}/>
          <List>
            {
              data.map(item => <ListItem key={item}>{item}</ListItem>)
            }
          </List>
        </Grid>
        <Grid item sm={2}>
          <Button
            variant='contained'
            sx={{ background: '#6DCD45' }}
            onClick={() => addPreferredRegions(data)}
            disabled={isRegionsChanged}
          >Сохранить</Button>
        </Grid>
      </Grid>
    </InfoCard>
  )
}
