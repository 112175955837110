import './styles.sass'
import { Button, Stack, Box, Typography } from '@mui/material'
import { ReactNode } from 'react'
import InfoCard from 'ui/InfoCard'

interface Props {
  title: ReactNode
  text: ReactNode
  onAllow: () => void
  onReject: () => void
}

export default function Confirm ({ title, text, onAllow, onReject }: Props) {
  return (
    <InfoCard>
      <Stack direction='row' justifyContent='space-between'>
        <Box>
          <Typography sx={{
            fontWeight: '600',
            fontSize: '18px',
            lineHeight: '22px',
            letterSpacing: '-0.02em',
            mb: 1
          }}>{ title }</Typography>
          <Typography sx={{
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '24px',
            letterSpacing: '-0.02em'
          }}>{text}</Typography>
        </Box>
        <Stack direction='row' flexWrap='wrap' alignContent='center' gap={2}>
          <Button size='small' variant='contained' color='error' onClick={onReject}>Отказать</Button>
          <Button size='small' variant='contained' onClick={onAllow}>Подтвердить</Button>
        </Stack>
      </Stack>
    </InfoCard>
  )
}
