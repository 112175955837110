import { Box, BoxProps } from '@mui/material'
import { mediaService } from 'api/media'
import { ReactNode, useEffect, useState } from 'react'

export type Params = Omit<BoxProps, 'children'> & {
  filename?: string
  notFound?: ReactNode
}

export default function PdfViewer ({ filename, borderRadius, notFound, ...boxProps }: Params) {
  const [data, setData] = useState<string>()

  useEffect(() => {
    if (filename === undefined) {
      return
    }

    mediaService.download(filename)
      .then(window.URL.createObjectURL)
      .then(setData)
  }, [filename])

  return (<Box {...boxProps} >
    { data !== undefined
      ? <object
        data={data}
        type='application/pdf'
        width='100%'
        height='100%'
        style={{ borderRadius: borderRadius as string | number | undefined }}
        >
        {notFound ?? <></>}
        </object>
      : <>{notFound}</> }
  </Box>)
}
