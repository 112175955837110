import { SyncRounded } from '@mui/icons-material'
import { Stack } from '@mui/material'
import { ShippingSentToKisStatus as Status } from 'api/shipping'
import MainChipStatus, { Props as MainChipStatusParams } from 'ui/ChipStatus'

export const sentToKisStatusMap: Map<Status, string> = new Map()
sentToKisStatusMap.set(Status.new, 'Данные готовы к отправке')
sentToKisStatusMap.set(Status.sent, 'Данные переданы')
sentToKisStatusMap.set(Status.completed, 'Данные приняты')

export interface Params {
  status: Status
}

const ChipStatus = ({ label, ...props }: MainChipStatusParams) => {
  return (<MainChipStatus {...props}
    label={<Stack direction='row' gap={1} alignItems='center'>
      <SyncRounded style={{ height: '20px' }}/>
      {label}
    </Stack>}
  />)
}

export default function SentToKisStatus ({ status }: Params) {
  const statusText = sentToKisStatusMap.get(status)

  if (statusText === undefined) {
    return (<></>)
  }

  switch (status) {
    case Status.new:
      return (<ChipStatus color='lightGrey' label={statusText} />)
    case Status.sent:
      return (<ChipStatus color='yellow' label={statusText} />)
    case Status.completed:
      return (<ChipStatus color='green' label={statusText} />)
    default:
      return (<></>)
  }
}
