import { useMainRoutes } from 'routes'
import { NotifyEvent } from '.'
import { useEffect } from 'react'
import { Box, Button } from '@mui/material'
import { SEARCH_PARAMS, Tabs } from 'pages/DispatcherBid'

export interface Data {
  truckRunId?: string
  truckRunNum: string
  bidId?: string
  bidNum: string
  date: string
  route: string
  message: string
}

const SLUG = 'truck_run_refuse_event'

export default function TruckRunRefuse ({ slug, data, snack, navigate } :NotifyEvent) {
  const { links } = useMainRoutes()

  useEffect(() => {
    if (slug !== SLUG) {
      return
    }

    if (snack === undefined) {
      return
    }

    if (data === undefined) {
      return
    }

    const { message, bidId, truckRunId } = data as Data

    const open = () => {
      if (navigate === undefined) {
        return
      }

      navigate(`${links.DISPATCHER_BID_PAGE}/${bidId}?${SEARCH_PARAMS.tab}=${Tabs.truckRunsRefused}&${SEARCH_PARAMS.truckRunId}=${truckRunId}`)
    }

    snack({
      title: message,
      color: 'error',
      content: (<Box>
        <Button variant='outlined' color='inherit' onClick={open}>Перейти</Button>
      </Box>),
      options: { autoHideDuration: 10000 }
    })
  }, [slug, data, snack, links.DISPATCHER_BID_PAGE, navigate])

  return (<></>)
}
