import { RequireOnly } from 'util/type/RequireOnly'
import { EMAIL_PATTERN } from 'common/Mask/EmailMask'
import { makeJsonSchema, SimpleSchema } from './validate'
import { ProfileForCreate } from 'api/profile'

type Update = RequireOnly<Omit<ProfileForCreate, 'passport' | 'organization'>, 'familyName' | 'firstName' | 'email'>

const simpleSchema: SimpleSchema<Update> = {
  type: 'object',
  properties: {
    familyName: { type: 'string', pattern: '^[а-яА-ЯёЁ\\- ]+$', minLength: 3, errorMessage: 'Некорректно заполнена фамилия' },
    firstName: { type: 'string', pattern: '^[а-яА-ЯёЁ\\- ]+$', minLength: 3, errorMessage: 'Некорректно заполнено имя' },
    secondName: { type: 'string', optional: true, pattern: '^[а-яА-ЯёЁ\\- ]*$', errorMessage: 'Некорректно заполнено отчество' },
    email: { type: 'string', pattern: EMAIL_PATTERN, errorMessage: 'Некорректно заполнен Email' },
    hasHiredDrivers: { type: 'boolean', optional: true },
    preferredRegions: { type: 'array', optional: true, items: { type: 'string' } }
  }
}

const schema = makeJsonSchema<Update>(simpleSchema)

export default schema
