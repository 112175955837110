export const regionCodesUA = [
  { value: 'АА', name: 'АА' },
  { value: 'КА', name: 'KA' },
  { value: 'АВ', name: 'АВ' },
  { value: 'КВ', name: 'КВ' },
  { value: 'АС', name: 'АС' },
  { value: 'КС', name: 'КС' },
  { value: 'АЕ', name: 'АЕ' },
  { value: 'КЕ', name: 'КЕ' },
  { value: 'АН', name: 'АН' },
  { value: 'КН', name: 'КН' },
  { value: 'АР', name: 'АР' },
  { value: 'КР', name: 'КР' },
  { value: 'АТ', name: 'АТ' },
  { value: 'КТ', name: 'КТ' },
  { value: 'АІ', name: 'АІ' },
  { value: 'КІ', name: 'КІ' },
  { value: 'ВА', name: 'ВА' },
  { value: 'НА', name: 'НА' },
  { value: 'ВВ', name: 'ВВ' },
  { value: 'НВ', name: 'НВ' },
  { value: 'ВЕ', name: 'ВЕ' },
  { value: 'НЕ', name: 'НЕ' },
  { value: 'ВН', name: 'ВН' },
  { value: 'НН', name: 'НН' },
  { value: 'ВО', name: 'ВО' },
  { value: 'НО', name: 'НО' },
  { value: 'АХ', name: 'АХ' },
  { value: 'КХ', name: 'КХ' },
  { value: 'ВТ', name: 'ВТ' },
  { value: 'НТ', name: 'НТ' },
  { value: 'ВХ', name: 'ВХ' },
  { value: 'НХ', name: 'НХ' },
  { value: 'ВІ', name: 'ВІ' },
  { value: 'НІ', name: 'НІ' },
  { value: 'СА', name: 'СА' },
  { value: 'ІА', name: 'ІА' },
  { value: 'СВ', name: 'СВ' },
  { value: 'ІВ', name: 'ІВ' },
  { value: 'СЕ', name: 'СЕ' },
  { value: 'ІЕ', name: 'ІЕ' },
  { value: 'СН', name: 'СН' },
  { value: 'ІН', name: 'ІН' },
  { value: 'СО', name: 'СО' },
  { value: 'ІО', name: 'ІО' },
  { value: 'ВК', name: 'ВК' },
  { value: 'НК', name: 'НК' },
  { value: 'СР', name: 'СР' },
  { value: 'ІР', name: 'ІР' }
]
