import './styles.sass'
import { StepProps } from 'ui/ModalSigning'
import { ActDispatcherWizardData } from './index'
import { Typography } from '@mui/material'
import ActPreview from 'pages/ActPreview'

export default function Info ({ isActive, data }: StepProps) {
  const d = data as ActDispatcherWizardData

  if (!isActive) {
    return <></>
  }

  return (<>
    {d.acts.length > 1 && <Typography mb={2}>
      Список УПД для подписания:
    </Typography>}
    <ActPreview acts={d.acts} />
  </>)
}
