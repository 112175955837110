import { Box, Typography } from '@mui/material'

export default function None () {
  return (<Box
    display='flex'
    flexGrow={3}
    alignItems='center'
    justifyContent='center'
    flexDirection='column'
    height='100%'
  >
    <Box>
      <svg width='60' height='60' viewBox='0 0 60 60' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path fillRule='evenodd' clipRule='evenodd' d='M29.9576 40.9263C34.1374 40.9263 37.4527 39.516 40.0958 37.6412C41.51 36.6381 42.4646 35.6442 43.5154 34.3499C44.1933 33.5147 45.7481 31.0802 45.9782 30.101C46.1297 29.4564 44.0813 26.3894 43.658 25.8345C40.7089 21.9689 35.7223 19.0787 30.2932 19.0787H29.6779C24.2699 19.0787 19.2813 21.9493 16.3427 25.809C15.8501 26.4562 13.8484 29.4733 13.9992 30.0974C14.2708 31.2211 15.9805 33.8147 16.8129 34.7746C17.7748 35.8838 18.5782 36.6971 19.8469 37.6142C22.4316 39.4826 25.8404 40.9263 29.9576 40.9263ZM29.4268 22.7464C25.5873 23.0843 22.3603 26.3347 22.7288 30.6053C23.0565 34.4033 26.3818 37.6021 30.583 37.2571C34.3992 36.9437 37.6085 33.5869 37.2478 29.3947C36.9209 25.5955 33.5925 22.3798 29.4268 22.7464Z' fill='#B2B2B2'/>
        <path fillRule='evenodd' clipRule='evenodd' d='M7.50614 20.7644L12.4995 20.7637C12.4995 19.4457 12.4995 18.1376 12.4995 16.8294C12.4995 15.481 12.3221 14.397 13.0491 13.4582C13.2755 13.1661 13.6973 12.8264 14.202 12.6536C14.9296 12.4044 18.7781 12.5734 19.9949 12.5187V7.53727C18.8283 7.4597 15.0741 7.52728 13.6243 7.52728C11.1298 7.52728 10.1116 7.80073 8.82015 9.17754C7.43943 10.6496 7.50601 11.9122 7.50601 14.3136C7.50601 16.4639 7.50614 18.6141 7.50614 20.7644Z' fill='#B2B2B2'/>
        <path fillRule='evenodd' clipRule='evenodd' d='M39.9895 47.4862L39.9768 52.4681C42.0468 52.5262 44.178 52.4716 46.2344 52.4716C48.166 52.4716 49.5227 52.4743 51.0052 51.0049C52.4729 49.55 52.4725 48.0953 52.4725 46.2365C52.4725 44.7633 52.5396 41.2266 52.4651 40.0084L47.4847 39.9876C47.3794 41.0447 47.4712 42.6147 47.4712 43.7206C47.4712 45.0675 47.5207 45.9916 46.7523 46.7539C45.9618 47.5381 45.0872 47.4794 43.7174 47.4794C42.5109 47.4794 41.1809 47.4268 39.9895 47.4862Z' fill='#B2B2B2'/>
        <path fillRule='evenodd' clipRule='evenodd' d='M39.9756 12.5186C40.9768 12.5636 44.9543 12.4573 45.5092 12.5831C46.4668 12.8003 47.1783 13.4817 47.4014 14.43C47.5503 15.0628 47.4266 18.9226 47.4779 20.0029L52.4693 20.009C52.5222 17.9536 52.4725 15.8509 52.4725 13.8105C52.4725 11.4403 52.329 10.3765 51.0254 9.02212C49.5826 7.52324 48.0912 7.52739 46.2343 7.52739C44.8336 7.52739 41.0894 7.46149 39.9756 7.53763V12.5186Z' fill='#B2B2B2'/>
        <path fillRule='evenodd' clipRule='evenodd' d='M7.50636 47.5224C7.50636 49.9968 9.34754 51.8853 11.4218 52.3511C12.4743 52.5875 14.4634 52.4717 15.6383 52.4717C17.0682 52.4717 18.5475 52.5086 19.9711 52.4681L19.9954 47.4827C18.8262 47.4558 17.6393 47.4795 16.4773 47.4795C15.1051 47.4795 14.4305 47.6326 13.4344 46.929C12.5972 46.3375 12.4994 45.3406 12.4999 44.3358C12.5004 43.1396 12.4999 41.9432 12.4999 40.7468C11.4768 40.687 8.70046 40.7356 7.5 40.7362L7.50636 47.5224Z' fill='#B2B2B2'/>
        <path fillRule='evenodd' clipRule='evenodd' d='M29.3175 25.6839C27.1247 26.0298 25.2826 28.0395 25.6612 30.631C25.9812 32.8216 28.0264 34.7032 30.6215 34.3251C32.8084 34.0064 34.6526 31.9857 34.322 29.4112C34.037 27.192 31.9347 25.271 29.3175 25.6839Z' fill='#B2B2B2'/>
      </svg>
    </Box>
    <Typography fontWeight={400} fontSize='15px' color='#B2B2B2' >Файл УПД не найден</Typography>
  </Box>)
}
