import { InputBaseProps as OrginProps, InputBase as MUIInputBase } from '@mui/material'
import { ChangeEvent, forwardRef, useEffect, useState } from 'react'

export type InputBaseProps = OrginProps & { typeNumber?: boolean, precision?: number }

const InputBase = forwardRef<HTMLInputElement, InputBaseProps>((props, ref) => {
  const { value, onChange, typeNumber = false, precision = 3, ...params } = props

  const [currentValue, setCurrentValue] = useState<string>('')

  useEffect(() => {
    if (!typeNumber) {
      return
    }

    if (currentValue === '') {
      setCurrentValue(`${value}`)
    }
  }, [value, typeNumber, currentValue])

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (onChange === undefined) {
      return
    }

    if (!typeNumber) {
      onChange(e)
    }

    const regex = precision === 0 ? /^\d*$/ : new RegExp(`^\\d*[.,]{0,1}\\d{0,${precision}}$`)

    const set = e.target.value
    if (set.match(regex)) {
      setCurrentValue(set.replace(',', '.'))
      onChange(e)
    }
  }

  return (<MUIInputBase
    {...params}
    onChange={handleChange}
    value={typeNumber ? currentValue : value}
    ref={ref}
  />)
})

export default InputBase
