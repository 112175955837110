import { Id } from 'api/Id'
import { useEffect, useMemo, useRef } from 'react'
import { Marker, Popup } from 'react-leaflet'
import L from 'leaflet'
import { Bid } from 'api/bid'
import TsToFormatDate from 'common/CarrierBids/TsToFormatDate'
import './styles.sass'
import RouteInfo from 'ui/RouteInfo'
import formaRub from 'util/formatRub'
import { Typography } from '@mui/material'
import { toTon } from 'util/weight'
import { toKilometers } from 'util/distance'

export interface Params {
  data: Bid
  selectId?: Id
  onOpen?: (id: Id) => void
  onClose?: (id: Id) => void
}

export default function BidMarker ({ onOpen, onClose, selectId, data }: Params) {
  const markerRef = useRef<L.Marker>(null)
  const { id, loadingAddress: { coordinates: point } } = data

  useEffect(() => {
    if (selectId === id) {
      markerRef.current?.openPopup([point.lat, point.lon])
    }
  }, [selectId, id, point])

  const eventHandlers = useMemo(
    () => ({
      popupopen () {
        if (onOpen !== undefined) {
          onOpen(id)
        }
      },
      popupclose () {
        if (onClose !== undefined) {
          onClose(id)
        }
      }
    }),
    [id, onOpen, onClose]
  )

  return (<Marker position={[point.lat, point.lon]} ref={markerRef} eventHandlers={eventHandlers}>
    <Popup>
      <div className='bidsMapBalloon'>
          <div className='bidsMapBalloon_row' style={{ marginTop: '2.7em' }}>
            <Typography fontWeight={600} fontSize='16px' m={0} >Перевозка {data.num}</Typography>
          </div>
        <div className='bidsMapBalloon_row'>
          <div className='bidsMapBalloon_item'>
            {formaRub(data.costCargo)}
            <div className='bidsMapBalloon_item_label'>Стоимость</div>
          </div>
          <div className='bidsMapBalloon_item'>
            {toTon(data.totalWeight)} т
            <div className='bidsMapBalloon_item_label'>К вывозу</div>
          </div>
        </div>
        <div className='bidsMapBalloon_row'>
          <div className='bidsMapBalloon_item'>
            {toKilometers(data.distance)} км
            <div className='bidsMapBalloon_item_label'>Расстояние</div>
          </div>
          <div className='bidsMapBalloon_item'>
            {TsToFormatDate(data.beginTs)} - {TsToFormatDate(data.endTs)}
            <div className='bidsMapBalloon_item_label'>Дата перевозки</div>
          </div>
        </div>
        <div className='bidsMapBalloon_row' style={{ marginBottom: '2.6em' }} >
          <RouteInfo loadingAddress={data.loadingAddress} unloadingAddress={data.unloadingAddress} />
        </div>
      </div>
    </Popup>
  </Marker>)
}
